import { BaseCard } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';

function TransactionFeeToPaidCard(): JSX.Element {
  return (
    <>
      <BaseCard.Body>
        <Stack spacing="tight" column align="flex-start">
          <Text.Skeleton width="large" />
          <Text.Skeleton width="large" />
          <Text.Skeleton size="featured" />
        </Stack>
      </BaseCard.Body>
      <BaseCard.Footer>
        <Text.Skeleton width="small" />
      </BaseCard.Footer>
    </>
  );
}

export default TransactionFeeToPaidCard;
