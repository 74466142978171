import { useState } from 'react';
import { Alert, Box, Button } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CategoryCreateRequestDto } from '@tiendanube/common';
import { Domain } from '@tiendanube/common/src/enums';
import {
  Input,
  InteractiveListPriv,
  InterfaceNameValue,
  Text,
} from '@tiendanube/components';
import { useToastProgress } from 'App/components/ToastProgressContext';
import { Stack } from 'commons/components';
import { useAutoFocus, useToast } from 'commons/hooks';
import { useGetLanguage } from 'domains/Auth/hooks';
import { HiddenCategoryTag } from 'domains/Catalog/Categories/components';
import {
  useCategories,
  useGetMaxAllowedCategories,
} from 'domains/Catalog/Categories/hooks';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import { trackingProductDetailCreateCategorySuccess } from 'domains/Catalog/Products/tracking';
import { isCategoryVisible } from 'domains/Catalog/Products/utils';
import ModalConfirmationCreateCategory, {
  NewCategoryInterface,
} from '../ModalConfirmationCreateCategory';

import './SelectCategories.scss';

interface SelectCategoriesProps {
  onChange: (s: string[]) => void;
  selecteds: string[];
}

const optionsMock = [...Array(7)].map(() => ({
  title: '',
  name: '',
  fixed: {
    name: '',
    prefix: '',
  },
  active: false,
  visibility: 'visible',
}));

// eslint-disable-next-line max-statements
function SelectCategories({
  onChange,
  selecteds,
}: SelectCategoriesProps): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  const language = useGetLanguage();
  const MAX_ALLOWED_CATEGORIES = useGetMaxAllowedCategories();
  const [isShowModal, setIsShowModal] = useState(false);
  const { categories, isLoading, createCategory } = useCategories();
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();
  const [search, setSearch] = useState('');

  const { ref } = useAutoFocus();

  const sender = useTrackFullCatalog();
  const searchLowerCase = search.toLowerCase();
  const options = isLoading
    ? optionsMock
    : categories
        .filter(
          ({ title }) =>
            !search || title.toLowerCase().includes(searchLowerCase),
        )
        .map(({ title, fixed, id, visibility }) => ({
          title,
          fixed,
          name: id,
          active: selecteds.includes(id),
          visibility,
        }));

  const handleOnCloseModal = () => setIsShowModal(false);
  const handleOnOpenModal = () => setIsShowModal(true);

  const onCreateCategory = async (newCategory) => {
    addToastProgress({ label: t('products.detail.creatingCategory') });
    try {
      const categoryResponse = await createCategory(newCategory);
      if (categoryResponse) {
        sender(trackingProductDetailCreateCategorySuccess);
        addToast({
          label: t('products.detail.createCategorySuccess'),
          appearance: 'success',
        });
        onChange([...selecteds, categoryResponse.id]);
      }
    } catch (error) {
      addToast({
        label: t('products.detail.createCategoryError'),
        appearance: 'danger',
      });
    }
    closeToastProgress();
  };

  const handleCreateCategory = ({
    name,
    parent,
    googleShoppingCategory,
    seoTitle,
    seoDescription,
    handle,
    visibility,
  }: NewCategoryInterface): void => {
    const payload: CategoryCreateRequestDto = {
      name: {
        [language]: name,
      },
      google_shopping_category: googleShoppingCategory,
      parent: parent || undefined,
      seo_title: seoTitle[language]
        ? seoTitle
        : { ...seoTitle, [language]: '' },
      seo_description: seoDescription[language]
        ? seoDescription
        : { ...seoDescription, [language]: '' },
      handle: handle[language] ? handle : { ...handle, [language]: '' },
      visibility,
    };
    onCreateCategory(payload);
  };

  const handleOnChange = (categoryId: string) => {
    const newSelecteds = [...selecteds];
    const categoryIdIndex = newSelecteds.findIndex((c) => c === categoryId);
    if (categoryIdIndex >= 0) newSelecteds.splice(categoryIdIndex, 1);
    else newSelecteds.push(categoryId);

    onChange(newSelecteds);
  };

  const handleOnChangeSearch = (e: InterfaceNameValue) => {
    setSearch(e.value);
  };

  const categoriesLimitReached = options.length >= MAX_ALLOWED_CATEGORIES;

  return (
    <>
      {categories.length === 0 ? (
        <Box mt="1">
          <EmptyMessage
            icon={<PlusCircleIcon size={32} />}
            actions={
              <Button appearance="primary" onClick={handleOnOpenModal}>
                {t('categories.action')}
              </Button>
            }
            text={t('categories.emptyResults.body')}
            title={t('categories.emptyResults.txitle')}
          />
        </Box>
      ) : (
        <Stack innerRef={ref} column align="stretch">
          <Input
            name="categorySearch"
            placeholder={t('products.detail.searchCategory')}
            value={search}
            onChange={handleOnChangeSearch}
          />
          <Alert
            show={categoriesLimitReached}
            appearance="warning"
            title={t('categories.categoriesLimitReached.alert.title', {
              n: MAX_ALLOWED_CATEGORIES,
            })}
          >
            {t('categories.categoriesLimitReached.alert.text')}
          </Alert>
          <div
            className={classNames({
              'stratus--add-category-disabled': categoriesLimitReached,
            })}
          >
            <InteractiveListPriv
              skeleton={isLoading}
              onClickAddItem={handleOnOpenModal}
              addItemLabel={t('products.detail.newCategory')}
            >
              {!isLoading &&
                options.map(({ fixed, name, active, visibility }) => (
                  <InteractiveListPriv.CheckItem
                    key={`option-${name}`}
                    name={name}
                    title=""
                    blockLeft={
                      <Box display="flex" gap="4">
                        <Text>
                          <Text.Span appearance="light">
                            {fixed.prefix}
                          </Text.Span>
                          {fixed.title}
                        </Text>
                        {!isCategoryVisible({ visibility }) && (
                          <HiddenCategoryTag />
                        )}
                      </Box>
                    }
                    checked={active}
                    onChange={() => handleOnChange(name)}
                  />
                ))}
            </InteractiveListPriv>
          </div>
        </Stack>
      )}
      <ModalConfirmationCreateCategory
        isShow={isShowModal}
        onClose={handleOnCloseModal}
        onCreateCategory={handleCreateCategory}
      />
    </>
  );
}

export default SelectCategories;
