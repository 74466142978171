import { useEffect, useState } from 'react';
import { useNavegate } from 'App/hooks';
import { HeaderContent, HeaderTop, IonPageStratus } from 'commons/components';
import { useForm } from 'commons/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import CouponsAlert from './components/CouponsAlert';
import CouponsForm, { CouponsFormInterface } from './components/CouponsForm';
import { useCoupons } from './hooks';
import { CouponsDataSchema } from '../../couponsSlice/types';

function shouldSetShow(data: CouponsDataSchema) {
  return !!data.errors?.affiliateDiscount || !!data.coupon?.type;
}

const initialValues = {
  couponCode: '',
};

function BillingCouponsPage() {
  const t = useTranslationBilling();
  const { goBack } = useNavegate();
  const { applyCoupon, data } = useCoupons();
  const { values, setFieldValue, handleOnSubmit } =
    useForm<CouponsFormInterface>({
      initialValues,
      onSubmit: applyCoupon,
    });

  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(shouldSetShow(data));
  }, [setShow, data]);

  const handleOnChange = ({ name, value }) => setFieldValue(name, value);
  const handleDismiss = () => setShow(false);

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={{ onClick: goBack }} />}
      headerContent={
        <HeaderContent
          title={t('couponsPage.title')}
          subtitle={t('couponsPage.subtitle')}
        />
      }
      width="small"
    >
      <CouponsAlert show={show} onDismiss={handleDismiss} />
      <CouponsForm
        values={values}
        onSave={handleOnSubmit}
        onChange={handleOnChange}
      />
    </IonPageStratus>
  );
}

export default BillingCouponsPage;
