import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import HelpLink from 'App/HelpLink';
import {
  ErrorScreen,
  HeaderContent,
  IonPageStratus,
  RenderByStatus,
} from 'commons/components';
import { useGetTags } from 'domains/Auth/hooks';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import { InfoMessageForm } from './components';
import { useGetInfoMessageQuery } from './infoMessageApi';

export function InfoMessagePage() {
  const t = useTranslationOnline('infoMessage');
  const { isError, isLoading, refetch } = useGetInfoMessageQuery();
  const isRevmapMenu = useGetTags().includes(REVAMP_MENU_V1);

  return (
    <IonPageStratus
      width="small"
      headerContent={
        <HeaderContent
          title={t(isRevmapMenu ? 'revampTitle' : 'title')}
          subtitle={t('text')}
        />
      }
    >
      <RenderByStatus
        isLoading={isLoading}
        isError={isError}
        success={<InfoMessageForm />}
        loading={<InfoMessageForm.Skeleton />}
        error={<ErrorScreen onRetry={refetch} />}
      />
      <HelpLink
        title={t('helpLink.title')}
        linkURL={t('helpLink.link')}
        currentViewTracking=""
        previousValue=""
        icon="both"
      />
    </IonPageStratus>
  );
}
