import { useCallback, useMemo } from 'react';
import { CheckoutPaymentMethod } from '@tiendanube/common';
import { StatusType } from 'commons/types';
import { CombinedStatusType, combineStatus } from 'commons/utils/combineStatus';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import {
  usePaymentMethods,
  usePaymentSetup,
  useRedirectOnSimilarCharge,
} from 'domains/Billing/Checkout/hooks';
import useSetupPayorder from './useSetupPayorder';

interface UseContinuePaymentReturn {
  setupStatus: CombinedStatusType;
  retrySetup: () => void;
  onContinue: (method: CheckoutPaymentMethod) => void;
  status: StatusType;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

function useContinuePayment(): UseContinuePaymentReturn {
  const { setupStatus, retrySetup, setupPayorder } = useSetupPayorder();
  const { paymentMethodSelected } = usePaymentMethods();
  const { status, handleFunctionBehaviour } = useRedirectOnSimilarCharge(
    setupPayorder,
    CHECKOUT_ROUTES.plansAndSubscriptions,
  );

  const { payPayOrder: payPayOrderPerMethod, extraStatus } = usePaymentSetup();
  const payPayOrder = useMemo(
    () =>
      paymentMethodSelected
        ? payPayOrderPerMethod[paymentMethodSelected.method]
        : () => null,
    [paymentMethodSelected, payPayOrderPerMethod],
  );

  const onContinue = useCallback(
    (method: CheckoutPaymentMethod) => {
      handleFunctionBehaviour().then((payOrder) => {
        if (payOrder) {
          payPayOrder(method, payOrder);
        }
      });
    },
    [handleFunctionBehaviour, payPayOrder],
  );

  const statusObject = combineStatus(status, extraStatus);

  return {
    setupStatus,
    retrySetup,
    ...statusObject,
    ...convertStatusTypeToObject(status),
    onContinue,
  };
}

export default useContinuePayment;
