import { memo, CSSProperties, forwardRef, MouseEvent } from 'react';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { Transform } from '@dnd-kit/utilities';
import classNames from 'classnames';
import { Thumbnail } from '@tiendanube/components';
import { useResponsive } from 'commons/components';
import { useGetLanguage } from 'domains/Auth/hooks';
import { ImageGalleryState } from '../../../ImageGallery';
import { VIDEO_UPLOAD_THUMBNAIL } from '../../constants';

import './Item.css';

export interface Props {
  dragging?: boolean;
  height?: number;
  index?: number;

  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: CSSProperties;
  transition?: string | null;
  image: ImageGalleryState;
  isSelectMode: boolean;
  isSelected: boolean;
  allowDragAndDrop: boolean;
  onSelected: (idSelected: string) => void;
  onError: () => void;
  ariaLabelError: string;
  onClickImage?: (i: ImageGalleryState) => void;
}

export const Item = memo(
  forwardRef<HTMLLIElement, Props>(
    (
      {
        dragging,
        listeners,
        transition,
        transform,
        image,
        isSelectMode,
        isSelected,
        allowDragAndDrop,
        onSelected,
        onError,
        ariaLabelError,
        onClickImage,
      },
      ref,
    ) => {
      const language = useGetLanguage();
      const { isDesktop } = useResponsive(672); // full width modal

      if (!image) return null;

      const handleOnClickImage = () => {
        onClickImage?.(image);
      };

      const margin = 32;
      const padding = 16;
      // the value 540 is the width of the modal sidesheet
      const widthParent = isDesktop ? 540 : window.innerWidth;
      const witdh = (widthParent - margin - padding) / 3;

      const { isLoading, isError, src, mediaType } = image;
      const alt = image?.alt?.[language] || '';
      const disabledContextMenu = (event: MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
      };
      const stortListeners = allowDragAndDrop ? listeners : null;
      const classNamesLi = classNames('stratus--image-gallery-item__wrapper', {
        'stratus--image-gallery-item--dragging': dragging,
      });

      return (
        <li
          className={classNamesLi}
          style={
            {
              width: witdh,
              height: witdh,
              transition,
              '--translate-x': transform
                ? `${Math.round(transform.x)}px`
                : undefined,
              '--translate-y': transform
                ? `${Math.round(transform.y)}px`
                : undefined,
            } as CSSProperties
          }
          ref={ref}
        >
          <div
            className="stratus--image-gallery-item"
            onContextMenu={disabledContextMenu}
            {...stortListeners}
          >
            {isSelectMode && !isLoading && (
              <Thumbnail.Select
                id={image.id}
                src={src}
                altText={alt}
                selected={isSelected}
                onSelect={onSelected}
              />
            )}
            {!isSelectMode && isError && !image.isLoading && (
              <Thumbnail.Error
                onClick={onError}
                ariaLabel={ariaLabelError}
                src={src}
                altText={alt}
              />
            )}
            {((!isSelectMode && !isError) || (isSelectMode && isLoading)) && (
              <div
                className="stratus--image-gallery-item--thumbnail"
                onClick={handleOnClickImage}
              >
                {mediaType === 'image' && (
                  <Thumbnail loading={isLoading} src={src} altText={alt} />
                )}
                {mediaType === 'video' && (
                  <Thumbnail
                    loading={isLoading}
                    src={src?.startsWith('http') ? src : VIDEO_UPLOAD_THUMBNAIL}
                    altText={alt}
                  />
                )}
              </div>
            )}
          </div>
        </li>
      );
    },
  ),
);
