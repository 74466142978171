import { Box } from '@nimbus-ds/components';
import { RewardCard } from './RewardCard';
import { TriggerCard } from './TriggerCard';
import { PromotionFormProps } from '../../PromotionForm';

export function CrossSelling({
  values,
  errors,
  onChangeRewards,
}: Readonly<
  Pick<PromotionFormProps, 'values' | 'errors' | 'onChangeRewards'>
>) {
  return (
    <Box display="flex" flexDirection="column" gap="4">
      <TriggerCard
        values={values}
        errors={errors}
        onChangeRewards={onChangeRewards}
      />
      <RewardCard
        values={values}
        errors={errors}
        onChangeRewards={onChangeRewards}
      />
    </Box>
  );
}
