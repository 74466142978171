import { useState } from 'react';
import { useModal } from 'commons/hooks';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import {
  AttributesModal,
  CardVariants,
  VariantDetailsModal,
  VariantImageModal,
} from './components';
import { useBrokenVariantsAlert } from './hooks';
import {
  Attributes,
  Variant,
  OnChangeToAllVariants,
  OnChangeToAllMetafieldsVariants,
} from './types';
import { ImageGalleryState } from '../ImageGallery/ImageGallery';

export interface VariantsProps {
  variants: Variant[];
  attributes: Attributes;
  productImages: ImageGalleryState[];
  isDigital: boolean;
  defaultImage?: string;
  isEdit?: boolean;
  productId: string;
  onChange: (i: number, v: Partial<Variant>) => void;
  onChangeAttributes: (attributes: Attributes) => void;
  onChangeToAllVariants: OnChangeToAllVariants;
  onChangeToAllMetafieldsVariants: OnChangeToAllMetafieldsVariants;
}

function Variants({
  variants,
  attributes,
  productImages,
  isDigital,
  defaultImage = '',
  isEdit,
  productId,
  onChange,
  onChangeAttributes,
  onChangeToAllVariants,
  onChangeToAllMetafieldsVariants,
}: VariantsProps): JSX.Element {
  const [isShowAttributes, openAttributes, closeAttributes] = useModal();
  const [isShowVariantDetails, openVariantDetails, closeVariantDetails] =
    useModal();
  const [isShowVariantImage, openVariantImage, closeVariantImage] = useModal();
  const [variantSelected, setVariantSelected] = useState(0);
  const brokenVariantsAlert = useBrokenVariantsAlert(variants, attributes);

  const { locationsMainId } = useLocations();

  const disabledHideVariant =
    variants.filter((variant) => !variant.visible).length ===
    variants.length - 1;

  const handleOnClickVariant = (variantIndex: number) => {
    setVariantSelected(variantIndex);
    openVariantDetails();
  };
  const handleOnClickImage = (variantIndex: number) => {
    setVariantSelected(variantIndex);
    openVariantImage();
  };

  return (
    <>
      <CardVariants
        onClickVariant={handleOnClickVariant}
        onClickImage={handleOnClickImage}
        onClickAttributes={openAttributes}
        onChange={onChange}
        variants={variants.map((variant) => ({
          stock: variant.stock,
          imageUrl: variant.imageUrl || defaultImage,
          id: variant.id,
          name: variant.name,
          isInfinite: variant.isInfinite,
          productId: variant.productId,
          price: variant.price,
          weight: variant.weight,
          depth: variant.depth,
          width: variant.width,
          height: variant.height,
          locationId: variant.locationId || locationsMainId,
          inventoryLevels: variant.inventory_levels,
          visible: variant.visible ?? true,
        }))}
        attributes={attributes}
        onChangeAttributes={onChangeAttributes}
        isEdit={isEdit}
        onChangeToAllVariants={onChangeToAllVariants}
        brokenVariantsAlert={brokenVariantsAlert}
        isDigital={isDigital}
      />
      <AttributesModal
        attributes={attributes}
        isShow={isShowAttributes}
        onChange={onChangeAttributes}
        onClose={closeAttributes}
      />
      <VariantDetailsModal
        productId={productId}
        isShow={isShowVariantDetails}
        onClose={closeVariantDetails}
        variants={variants}
        variantSelected={variantSelected}
        isDigital={isDigital}
        disabledHideVariant={disabledHideVariant}
        onChangeVariantSelected={setVariantSelected}
        onChangeVariant={onChange}
        onChangeToAllVariants={onChangeToAllVariants}
        onChangeToAllMetafieldsVariants={onChangeToAllMetafieldsVariants}
      />
      <VariantImageModal
        isShow={isShowVariantImage}
        onClose={closeVariantImage}
        images={productImages}
        variants={variants}
        variantSelected={variantSelected}
        onChangeVariantSelected={setVariantSelected}
        onChangeVariant={onChange}
      />
    </>
  );
}

export default Variants;
