import { DataList } from '@tiendanube/components';
import { MetafieldItem } from 'domains/Metafields/components/MetafieldInput';
import { MetafieldInterface } from 'domains/Metafields/types';

export default function MetafieldsDataRows({
  metafields,
  selectedMetafields,
  source,
  onChange,
}) {
  const filterVisibleMetafields = (metafield: MetafieldInterface) =>
    !metafield.formFieldConfig ||
    (metafield.formFieldConfig && !metafield.formFieldConfig.hidden);

  return (
    <div>
      {metafields?.filter(filterVisibleMetafields).map((metafield, index) => {
        const found = selectedMetafields.find(
          (selectedMetafield) => selectedMetafield.id === metafield.id,
        );
        const value = found?.value || '';

        return (
          <div
            className="data-list-row-wrapper data-list-row-wrapper-metafields"
            key={`${metafield.id}-${index}`}
          >
            <DataList.Row id={`${index}`}>
              <DataList.Cell width={100}>
                <MetafieldItem
                  value={value}
                  metafield={metafield}
                  source={source}
                  showDescription={!!metafield.description}
                  onChange={onChange}
                />
              </DataList.Cell>
            </DataList.Row>
          </div>
        );
      })}
    </div>
  );
}
