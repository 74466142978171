import { useCallback, useState } from 'react';
import { useInterval } from 'commons/hooks';
import ordersService from '../../../ordersService';

interface Result {
  isLoading: boolean;
  isError: boolean;
  hasFinalized: boolean;
  polling: (refundAttemptId: string) => void;
}

const MAX_REQUEST_COUNT = 6;
const REQUEST_INTERVAL_IN_MILLISECONDS = 1500;

function useRefundEventListener(id: string): Result {
  const { setInterval, clearInterval } = useInterval();
  const [hasFinalized, setHasFinalized] = useState(false);
  const [isError, setIsError] = useState(false);
  const [hasPollingStarted, setHasPollingStarted] = useState(false);
  const isLoading = hasPollingStarted && !hasFinalized;

  const polling = useCallback(
    (refundAttemptId: string) => {
      let hasFinalizedPolling = false;
      setHasPollingStarted(true);
      setInterval(
        async (index) => {
          if (hasFinalizedPolling) {
            clearInterval();
            return;
          }
          const hasReachedMaxRequest = index === MAX_REQUEST_COUNT;
          try {
            const { status: refundAttemptStatus } =
              await ordersService.fetchRefundAttemptStatus(id, refundAttemptId);
            hasFinalizedPolling =
              ['success', 'error'].includes(refundAttemptStatus) ||
              hasReachedMaxRequest;
          } catch (e) {
            hasFinalizedPolling = true;
            setIsError(true);
          }
          setHasFinalized(hasFinalizedPolling);
        },
        REQUEST_INTERVAL_IN_MILLISECONDS,
        MAX_REQUEST_COUNT,
      );
    },
    [setInterval, clearInterval, id],
  );

  return {
    polling,
    hasFinalized,
    isError,
    isLoading,
  };
}

export default useRefundEventListener;
