import { Box } from '@nimbus-ds/components';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { useQuery } from 'App/hooks';
import { Layout } from 'commons/components';
import { useGetTags } from 'domains/Auth/hooks';
import { StoreStatusAlert } from 'domains/Billing/Checkout/components';
import {
  NoFiscalDataAlert,
  AccountData,
  PlansAndApps,
  Summary,
  ApplyDiscount,
} from '../';

function PlansAndSubscriptionsV1(): JSX.Element {
  const hideAdBars = useQuery().get('hideAdBars') === 'true';
  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);

  return (
    <>
      {!hideAdBars && (
        <>
          <StoreStatusAlert includePaymentAlert />
          <NoFiscalDataAlert />
        </>
      )}
      <Layout
        mainContent
        left={
          <Box display="flex" flexDirection="column" gap="4">
            <PlansAndApps />
            <Summary />
          </Box>
        }
        right={
          <Box display="flex" flexDirection="column" gap="4">
            <AccountData />
            {isRevampMenu && <ApplyDiscount />}
          </Box>
        }
        orderMobile="reverse"
      />
    </>
  );
}

export default PlansAndSubscriptionsV1;
