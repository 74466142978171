import { Route, Switch } from 'react-router-dom';
import { HideNavTabs } from 'App/components/Navtabs';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { CUSTOMERS_SECTION_ROUTES } from './customersRoutes';
import {
  CustomerListPage,
  CustomerDetailsPage,
  CustomerFormPage,
  CustomersImportPage,
} from './pages';

function Customers(): JSX.Element {
  const isMobileDevice = useIsMobileDevice();

  return (
    <>
      <Route exact path={CUSTOMERS_SECTION_ROUTES.customerList}>
        <CustomerListPage />
      </Route>
      <Switch>
        <Route exact path={CUSTOMERS_SECTION_ROUTES.customerNew}>
          <CustomerFormPage />
        </Route>
        <Route exact path={CUSTOMERS_SECTION_ROUTES.customerEdit}>
          <CustomerFormPage />
        </Route>
        <Route path={CUSTOMERS_SECTION_ROUTES.customerImport} exact>
          {isMobileDevice ? (
            <>
              <HideNavTabs />
              <CustomersImportPage showUpdateOptions />
            </>
          ) : (
            <CustomersImportPage showUpdateOptions />
          )}
        </Route>
        <Route exact path={CUSTOMERS_SECTION_ROUTES.customerDetails}>
          <CustomerDetailsPage />
        </Route>
        <Route path={CUSTOMERS_SECTION_ROUTES.customerEmptyImport} exact>
          {isMobileDevice ? (
            <>
              <HideNavTabs />
              <CustomersImportPage />
            </>
          ) : (
            <CustomersImportPage />
          )}
        </Route>
      </Switch>
    </>
  );
}

export default Customers;
