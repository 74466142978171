import { PlansListResponseDto } from '@tiendanube/common';
import {
  Grid,
  InterfaceNameValue,
  InterfaceSelectOptionGroup,
  Select,
} from '@tiendanube/components';
import { useIsInAppBrowser } from 'App/hooks';
import { CurrencyPrice } from 'commons/components';
import Link from 'commons/components/LegacyLink';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { useTranslationBilling } from 'domains/Billing';
import { SelectedPlan } from 'domains/Billing/BillingPlans/pages/BillingPlansPage/components/ComparisonPlansCards/components/PlanCard/types';
import './ComparisonSelect.scss';

interface ComparisonSelectProps {
  plansList: PlansListResponseDto[];
  currentPlanId: number;
  firstSelectedPlan: PlansListResponseDto;
  secondSelectedPlan: PlansListResponseDto;
  onSelectedFirstPlan: (firstPlanToCompare: PlansListResponseDto) => void;
  onSelectedSecondPlan: (secondPlanToCompare: PlansListResponseDto) => void;
  onSelectPlan: (plan: SelectedPlan) => void;
}

function ComparisonSelect({
  plansList,
  currentPlanId,
  firstSelectedPlan,
  secondSelectedPlan,
  onSelectedFirstPlan,
  onSelectedSecondPlan,
  onSelectPlan,
}: ComparisonSelectProps): JSX.Element {
  const t = useTranslationBilling();
  const isMobile = useIsMobileDevice();
  const isInAppBrowserOpen = useIsInAppBrowser();
  const isWebVersion = !isMobile && !isInAppBrowserOpen;

  const planOptions: InterfaceSelectOptionGroup[] = plansList.map(
    (plan: PlansListResponseDto) => ({
      label: plan.name,
      value: `${plan.id}`,
      disabled: [firstSelectedPlan.name, secondSelectedPlan.name].includes(
        plan.name,
      ),
    }),
  );

  const handleOnPlanSelectChange = (e: InterfaceNameValue) => {
    const plan = plansList.find((plan) => plan.id === Number(e.value));
    if (!plan) return;

    if (e.name === 'planToCompare1') {
      onSelectedFirstPlan(plan);
    } else {
      onSelectedSecondPlan(plan);
    }
  };

  return (
    <div className="stratus--comparison__plan__select">
      <Grid fluid>
        <Grid.Row>
          {[firstSelectedPlan, secondSelectedPlan].map(
            (selectedPlan, index) => (
              <Grid.Column key={index}>
                <Stack column align="stretch">
                  <Stack.Item>
                    <Select
                      name={`planToCompare${index + 1}`}
                      label={t(
                        `billingPlansPage.comparisonTable.plan${index + 1}`,
                      )}
                      options={planOptions}
                      value={`${selectedPlan.id}`}
                      onChange={handleOnPlanSelectChange}
                    />
                  </Stack.Item>
                  <Stack.Item>
                    <CurrencyPrice
                      price={selectedPlan.price.value}
                      currency={selectedPlan.price.currency}
                      withoutDecimal
                      bold
                    />{' '}
                    <Text>/ {t('billingPlansPage.month')}</Text>
                  </Stack.Item>
                  {isWebVersion &&
                    (selectedPlan.id !== currentPlanId ||
                      selectedPlan.price.value > 0) && (
                      <Stack.Item>
                        <Link
                          appearance="primary"
                          underline
                          onClick={() => onSelectPlan(selectedPlan)}
                        >
                          {selectedPlan.id === currentPlanId
                            ? t('billingPlansPage.payPlan')
                            : t('billingPlansPage.selectPlan')}
                        </Link>
                      </Stack.Item>
                    )}
                </Stack>
              </Grid.Column>
            ),
          )}
        </Grid.Row>
      </Grid>
    </div>
  );
}

export default ComparisonSelect;
