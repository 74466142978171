import { OwnerResourceType } from '@tiendanube/common';
import useGetIsUserFullRole from 'domains/Auth/hooks/useGetIsUserFullRole';
import {
  trackingCustomerEditCustomFieldChange,
  trackingCustomerNewCustomFieldChange,
} from 'domains/Customers/tracking';
import {
  CardMetafieldsList,
  EmptyAlert,
  UpsellAlertCard,
} from 'domains/Metafields/components';
import EmptyOnCard from 'domains/Metafields/components/EmptyCard';
import LinkToMetafieldsBasedOnUser from 'domains/Metafields/components/LinkToMetafieldsBasedOnUser';
import MetafieldsCustomerDataList from 'domains/Metafields/components/MetafieldsCustomerDataList';
import { RenderCustomerMetafieldWhen } from 'domains/Metafields/components/RenderMetafieldWhen';
import useGetCustomerMetafields from 'domains/Metafields/hooks/useGetCustomerMetafields';
import {
  MetafieldSelectedInterface,
  MetafieldToBeChanged,
  SourceType,
} from 'domains/Metafields/types';
import { MetafieldSelectedsOrErrorType, MetafieldsSelected } from '../../types';

interface SectionCustomerMetafieldsProps {
  selecteds: MetafieldSelectedsOrErrorType;
  apiSelecteds: MetafieldSelectedsOrErrorType;
  onChange: (metafield: MetafieldsSelected) => void;
  showError: boolean;
  showSuccess: boolean;
  showLoading: boolean;
  mode?: 'edit' | 'add';
}

function SectionCustomerMetafields({
  selecteds,
  apiSelecteds,
  onChange,
  showError,
  showLoading,
  showSuccess,
  mode,
}: SectionCustomerMetafieldsProps) {
  const { hasInternals, metafields, apiMetafields, status, fetchMetafields } =
    useGetCustomerMetafields();
  const isFullUser = useGetIsUserFullRole();

  const handleChange = (
    id: string,
    value: string | null,
    source: SourceType,
    resource?: OwnerResourceType,
    name?: string,
  ) => {
    const selectedArray: MetafieldSelectedsOrErrorType = [];
    const apiSelectedArray: MetafieldSelectedsOrErrorType = [];

    const array =
      source === SourceType.ADMIN ? selectedArray : apiSelectedArray;

    array.push({
      id,
      value: value,
    });

    const trackingFn =
      mode === 'add'
        ? trackingCustomerNewCustomFieldChange
        : trackingCustomerEditCustomFieldChange;
    trackingFn(id, name, value);

    onChange?.({
      internals: selectedArray,
      fromApi: apiSelectedArray,
    });
  };

  const handleMultipleChange = (metafields: MetafieldToBeChanged[]) => {
    const selectedArray: MetafieldSelectedsOrErrorType = [];
    const apiSelectedArray: MetafieldSelectedsOrErrorType = [];

    for (const metafield of metafields) {
      const array =
        metafield.source === SourceType.ADMIN
          ? selectedArray
          : apiSelectedArray;

      array.push({
        id: metafield.id,
        value: metafield.value,
      });
    }

    onChange?.({
      internals: selectedArray,
      fromApi: apiSelectedArray,
    });
  };

  const isSelectedsError = selecteds === 'error' || apiSelecteds === 'error';

  const apiMetafieldsList = apiMetafields || [];
  const metafieldsList = metafields || [];

  return (
    <>
      {(showError || (status.isSuccess && isSelectedsError)) && (
        <CardMetafieldsList.ErrorState onRetry={fetchMetafields} />
      )}
      {showLoading && <CardMetafieldsList.Skeleton />}

      {showSuccess && (
        <>
          <RenderCustomerMetafieldWhen
            conditions={[
              {
                hasInternals: false,
                hasExternals: true,
                isAvailable: false,
              },
              {
                hasInternals: true,
                hasExternals: true,
                isAvailable: false,
              },
            ]}
          >
            {isFullUser ? (
              <CardMetafieldsList
                metafieldsTotal={apiMetafieldsList.length}
                metafieldSlot={
                  <UpsellAlertCard
                    owner="customer"
                    hasInternals={hasInternals}
                  />
                }
                apiMetafieldSlot={
                  <MetafieldsCustomerDataList
                    source={SourceType.APP}
                    metafields={apiMetafieldsList}
                    selectedMetafields={
                      apiSelecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                    onMultipleChange={handleMultipleChange}
                  />
                }
              />
            ) : (
              <CardMetafieldsList
                metafieldsTotal={apiMetafieldsList.length}
                metafieldSlot={
                  <MetafieldsCustomerDataList
                    source={SourceType.APP}
                    metafields={apiMetafieldsList}
                    selectedMetafields={
                      apiSelecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                    onMultipleChange={handleMultipleChange}
                  />
                }
              />
            )}
          </RenderCustomerMetafieldWhen>

          <RenderCustomerMetafieldWhen
            conditions={[
              {
                hasExternals: false,
                hasInternals: false,
                isAvailable: true,
              },
            ]}
          >
            {isFullUser ? <EmptyAlert ownerResource="customer" /> : null}
          </RenderCustomerMetafieldWhen>

          <RenderCustomerMetafieldWhen
            conditions={[
              {
                hasInternals: true,
                hasExternals: false,
                isAvailable: true,
              },
            ]}
          >
            <CardMetafieldsList
              metafieldSlot={
                <>
                  <MetafieldsCustomerDataList
                    source={SourceType.ADMIN}
                    metafields={metafieldsList}
                    selectedMetafields={
                      selecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                    onMultipleChange={handleMultipleChange}
                  />
                  <LinkToMetafieldsBasedOnUser isFullUser={isFullUser} />
                </>
              }
              metafieldsTotal={metafieldsList.length}
            />
          </RenderCustomerMetafieldWhen>

          <RenderCustomerMetafieldWhen
            conditions={[
              {
                hasInternals: true,
                hasExternals: true,
                isAvailable: true,
              },
            ]}
          >
            <CardMetafieldsList
              metafieldSlot={
                <>
                  <MetafieldsCustomerDataList
                    source={SourceType.ADMIN}
                    metafields={metafieldsList}
                    selectedMetafields={
                      selecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                    onMultipleChange={handleMultipleChange}
                  />
                  <LinkToMetafieldsBasedOnUser
                    isFullUser={isFullUser}
                    hasPaddingBottom
                  />
                </>
              }
              metafieldsTotal={metafieldsList.length + apiMetafieldsList.length}
              apiMetafieldSlot={
                <MetafieldsCustomerDataList
                  source={SourceType.APP}
                  metafields={apiMetafieldsList}
                  selectedMetafields={
                    apiSelecteds as MetafieldSelectedInterface[]
                  }
                  onChange={handleChange}
                  onMultipleChange={handleMultipleChange}
                />
              }
            />
          </RenderCustomerMetafieldWhen>

          <RenderCustomerMetafieldWhen
            conditions={[
              {
                hasInternals: false,
                hasExternals: true,
                isAvailable: true,
              },
            ]}
          >
            {isFullUser ? (
              <CardMetafieldsList
                metafieldSlot={<EmptyOnCard />}
                apiMetafieldSlot={
                  <MetafieldsCustomerDataList
                    source={SourceType.APP}
                    metafields={apiMetafieldsList}
                    selectedMetafields={
                      apiSelecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                    onMultipleChange={handleMultipleChange}
                  />
                }
                metafieldsTotal={apiMetafieldsList.length}
              />
            ) : (
              <CardMetafieldsList
                metafieldSlot={
                  <MetafieldsCustomerDataList
                    source={SourceType.APP}
                    metafields={apiMetafieldsList}
                    selectedMetafields={
                      apiSelecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                    onMultipleChange={handleMultipleChange}
                  />
                }
                metafieldsTotal={apiMetafieldsList.length}
              />
            )}
          </RenderCustomerMetafieldWhen>
        </>
      )}
    </>
  );
}

export default SectionCustomerMetafields;
