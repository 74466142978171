import { ChangeEvent } from 'react';
import { Card, Checkbox } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';
import { useAutoFocus } from 'commons/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import Skeleton from './Skeleton';
import { DeliveryCostType } from '../../DeliveryMethodsPage/components/CustomShippingForm/CustomShippingForm';

interface CustomShippingNameCardProps {
  name: string;
  deliveryCost?: DeliveryCostType;
  allowedForFreeShipping?: boolean;
  error?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function CustomShippingNameCard({
  name,
  deliveryCost,
  allowedForFreeShipping = false,
  error,
  onChange,
}: Readonly<CustomShippingNameCardProps>): JSX.Element {
  const t = useTranslationShipping();
  const { ref } = useAutoFocus();

  const optionDeliveryFree =
    deliveryCost === 'to-be-arranged' || deliveryCost === 'with-cost';

  return (
    <Card>
      <Card.Header
        title={t(
          'deliveryMethods.customShipping.informationCard.shippingData.name',
        )}
      />
      <Card.Body>
        <Stack column align="stretch" innerRef={ref}>
          <Stack.Item>
            <FormField.Input
              name="name"
              value={name}
              onChange={onChange}
              showHelpText={!!error && !name}
              helpText={error ? t(error) : undefined}
              appearance={!!error && !name ? 'danger' : 'none'}
            />
          </Stack.Item>
          <Stack.Item>
            <Checkbox
              checked={allowedForFreeShipping || !optionDeliveryFree}
              name="allowedForFreeShipping"
              label={t(
                'deliveryMethods.customShipping.informationCard.productShippingFree.description',
              )}
              onChange={onChange}
              disabled={!optionDeliveryFree}
            />
          </Stack.Item>
        </Stack>
      </Card.Body>
    </Card>
  );
}

CustomShippingNameCard.Skeleton = Skeleton;

export default CustomShippingNameCard;
