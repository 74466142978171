import { useState, useEffect } from 'react';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import { HeaderContent, HeaderTop, IonPageStratus } from 'commons/components';
import { HELP_LINKS_TO_CANCELLATION } from 'commons/constants';
import { useToast, useTranslationLanguage } from 'commons/hooks';
import { useTranslationAccount } from 'domains/Account/hooks';
import useShopCancellation from 'domains/Account/hooks/useShopCancellation';
import { useStoreName } from 'domains/Auth/hooks';
import useRefreshInfoStoreUser from 'domains/Auth/hooks/useRefreshInfoToSync';
import ShopCancellationStepOne from './components/ShopCancellationStepOne';
import ShopCancellationSteptwo from './components/ShopCancellationSteptwo';
import ShopCancellationThirdStep from './components/ShopCancellationThirdStep';

function ShopCancellationPage(): JSX.Element {
  const { goBack, goTo } = useNavegate();
  const storeName = useStoreName();
  const [step, setStep] = useState<number>(1);
  const { refreshInfo } = useRefreshInfoStoreUser();

  const t = useTranslationAccount();
  const language = useTranslationLanguage();

  const headerNavigation = { onClick: goBack };

  const nextStep = () => setStep((step) => step + 1);
  const backStep = () => setStep((step) => step - 1);

  const { addToast } = useToast();

  const {
    fetchShopCancellation,
    deleteAccount,
    subReasons,
    deleteStatus,
    getStatus,
    reasons,
    suggestions,
    setReason,
    setSubReason,
  } = useShopCancellation();

  useEffect(() => {
    if (deleteStatus === 'success') {
      refreshInfo(true);
      goTo('/');
    }
    if (deleteStatus === 'error') {
      addToast({
        label: t('shopCancellationPage.error'),
        appearance: 'danger',
      });
    }
  }, [deleteStatus, addToast, t, goTo, refreshInfo]);

  const handleDeletAccount = (extra?: string) => {
    const selectedReasons: {
      reasonCode: string;
      optionCode?: string;
      extra?: string;
    } = { reasonCode: '', optionCode: undefined, extra: undefined };

    if (reasons?.selected) {
      selectedReasons.reasonCode = reasons?.selected?.value;
    }
    if (subReasons?.selected) {
      selectedReasons.optionCode = subReasons?.selected?.value;
    }
    if (extra) {
      selectedReasons.extra = extra;
    }

    deleteAccount(selectedReasons);
  };

  useEffect(() => {
    fetchShopCancellation();
  }, [fetchShopCancellation]);

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={headerNavigation} />}
      headerContent={
        <HeaderContent
          title={t('shopCancellationPage.title', { storeName })}
          subtitle={t('shopCancellationPage.subtitle')}
        />
      }
      width="small"
    >
      {step === 1 && <ShopCancellationStepOne onContinue={nextStep} />}
      {step === 2 && (
        <ShopCancellationSteptwo
          onContinue={nextStep}
          onCancel={backStep}
          reasons={reasons}
          suggestions={suggestions}
          isLoading={getStatus === 'loading'}
          setReason={setReason}
          setSubReason={setSubReason}
          subReasons={subReasons}
        />
      )}
      {step === 3 && (
        <ShopCancellationThirdStep
          isLoading={deleteStatus === 'loading'}
          onCancel={backStep}
          onDeleteAccount={handleDeletAccount}
        />
      )}

      <HelpLink
        title={t('shopCancellationPage.helpLink.title')}
        previousValue=""
        currentViewTracking={t('shopCancellationPage.helpLink.amplitudeName')}
        linkURL={HELP_LINKS_TO_CANCELLATION[language]}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default ShopCancellationPage;
