import { Box } from '@nimbus-ds/components';
import { GlassesIcon, ToolsIcon, AppsIcon, StoreIcon } from '@nimbus-ds/icons';
import { FullHeightCardContainer, BlockWithTitle } from 'commons/components';
import { useAdminLinks, useGetCountry } from 'domains/Auth/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { trackingBoostStoreClick } from 'domains/Dashboard/tracking';
import { useAppStoreTracker } from 'domains/PartnersApps/hooks';
import ImproveYourStoreCard from './ImproveYourStoreCard';

// Temporarily disable the "Tools" card as an experiment to drive more engagement towards the POS.
const HIDE_TOOLS_CARD = true;

function ImproveYourStore() {
  const t = useTranslationDashboard();
  const country = useGetCountry();
  const { getNewAdminLink } = useAdminLinks();
  const { clickToAppStoreHomePage } = useAppStoreTracker();

  const handleClick = (card: string) => () => {
    trackingBoostStoreClick({ card });
  };

  const handleClickStoreCard = () => {
    handleClick('apps');
    clickToAppStoreHomePage('admin/v2/dashboard');
  };
  return (
    <BlockWithTitle title={t('improveStore.title')}>
      <FullHeightCardContainer>
        <Box
          display="grid"
          gridTemplateColumns={{
            lg: '1fr 1fr 1fr',
            md: '1fr',
            xs: '1fr',
          }}
          gridGap="4"
        >
          <ImproveYourStoreCard
            title={t('improveStore.pos.title')}
            description={t('improveStore.pos.description')}
            link={getNewAdminLink(t('improveStore.pos.link'))}
            linkText={t('improveStore.pos.linkText')}
            icon={<StoreIcon />}
            onClick={handleClick('pos')}
          />
          <ImproveYourStoreCard
            title={t('improveStore.appStore.title')}
            description={t('improveStore.appStore.description')}
            link={t('improveStore.appStore.link')}
            linkText={t('improveStore.appStore.linkText')}
            icon={<AppsIcon />}
            onClick={handleClickStoreCard}
          />
          <ImproveYourStoreCard
            title={t('improveStore.specialists.title')}
            description={t('improveStore.specialists.description', {
              country: t(`improveStore.countries.${country}`),
            })}
            link={t('improveStore.specialists.link')}
            linkText={t('improveStore.specialists.linkText')}
            icon={<GlassesIcon />}
            onClick={handleClick('specialists')}
          />
          {!HIDE_TOOLS_CARD && (
            <ImproveYourStoreCard
              title={t('improveStore.tools.title')}
              description={t('improveStore.tools.description')}
              link={t('improveStore.tools.link')}
              linkText={t('improveStore.tools.linkText')}
              icon={<ToolsIcon />}
              onClick={handleClick('tools')}
            />
          )}
        </Box>
      </FullHeightCardContainer>
    </BlockWithTitle>
  );
}

export default ImproveYourStore;
