import { combineReducers } from 'redux';
import { reducer as customerMetafieldsReducer } from './Customers/CustomerMetafields/customerMetafieldsSlice';
import { reducer as customersReducer } from './Customers/customersSlice';
import { reducer as customerTablePricesReducer } from './CustomerTablePrices/customerTablePricesSlice';
import { reducer as messagesReducer } from './Messages/messagesSlice';
import { reducer as tablePricesReducer } from './TablePrices/tablePricesSlice';

const customerReducers = combineReducers({
  customers: customersReducer,
  messages: messagesReducer,
  customerMetafields: customerMetafieldsReducer,
  customerTablePrices: customerTablePricesReducer,
  tablePrices: tablePricesReducer,
});

export default customerReducers;
