import { useCallback } from 'react';
import { Badge, Box, Button } from '@nimbus-ds/components';
import classNames from 'classnames';
import { SAVED_SEARCHES_COUNT_LIMIT } from 'domains/Orders/Orders/pages/constants';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { SavedSearchInterface } from '../types';
import './SavedSearch.scss';

interface SavedSearchProperties {
  savedSearch: SavedSearchInterface;
  isSelected: boolean;
  count?: number;
  extraClassName?: string;
  onSavedSearchClick: (savedSearch: SavedSearchInterface) => void;
}

export function SavedSearch({
  savedSearch,
  isSelected,
  count,
  extraClassName,
  onSavedSearchClick,
}: SavedSearchProperties) {
  const t = useTranslationOrders();

  const onClick = useCallback(() => {
    onSavedSearchClick(savedSearch);
  }, [onSavedSearchClick, savedSearch]);

  const className = classNames(
    'stratus--saved-search-box',
    {
      'stratus--selected-saved-search': isSelected,
    },
    extraClassName,
  );

  const classNameBadge = classNames('stratus--saved-search-badge', {
    'stratus--selected-saved-search-badge': isSelected,
  });

  const countToShow =
    count && count > SAVED_SEARCHES_COUNT_LIMIT && !isSelected
      ? `+${SAVED_SEARCHES_COUNT_LIMIT}`
      : count;

  return (
    <div className={className}>
      <Button
        onClick={onClick}
        appearance={isSelected ? 'primary' : 'transparent'}
      >
        <Box
          paddingY="1-5"
          paddingX="2-5"
          flexDirection="row"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box fontSize="3" fontWeight="regular" lineHeight="4">
            {t(`savedSearches.default.${savedSearch.code}`)}
          </Box>
          {countToShow !== undefined && (
            <Box paddingLeft="1">
              <div className={classNameBadge}>
                <Badge
                  count={countToShow}
                  theme={isSelected ? 'light' : 'surface'}
                />
              </div>
            </Box>
          )}
        </Box>
      </Button>
    </div>
  );
}
