import { Box, Text, Title } from '@nimbus-ds/components';
import { Locale } from '@tiendanube/common/src/enums';
import { AppItemCard } from 'commons/components';
import { APP_STORE_LINKS } from 'commons/constants';
import goToAdmin from 'commons/utils/gotToAdmin';
import {
  useAppStoreTracker,
  useTranslationPartnerApps,
} from 'domains/PartnersApps/hooks';
import { BASE_IMAGE_URL, InterfaceApp, data as sectionApps } from './apps/apps';
import icon from './icon.svg';

interface SectionProps {
  title: string;
  description: string;
}

export const SectionAppCardDomains = {
  THEMES: 'THEMES',
  ABANDONED_CARTS: 'ABANDONED_CARTS',
  MESSAGES: 'MESSAGES',
  DISCOUNT: 'DISCOUNT',
  COUPONS: 'COUPONS',
  FREE_SHIPPING: 'FREE_SHIPPING',
  PROMOTIONS: 'PROMOTIONS',
  PRODUCTS: 'PRODUCTS',
  CUSTOMERS: 'CUSTOMERS',
  ORDERS: 'ORDERS',
  DRAFT_ORDERS: 'DRAFT_ORDERS',
  EMAILS: 'EMAILS',
  INFO_MESSAGES: 'INFO_MESSAGES',
} as const;

interface SectionAppCardProps {
  source: string;
  appCategory: string;
  domain: keyof typeof SectionAppCardDomains;
  section?: SectionProps;
  height?: string;
}

function SectionAppCard({
  section,
  source,
  appCategory,
  domain,
  height,
}: SectionAppCardProps) {
  const { language } = useTranslationPartnerApps();
  const {
    clickToAppStoreAppInstall,
    clickToAppStoreAppDetails,
    clickToAppStoreCategory,
  } = useAppStoreTracker();

  const app = (sectionApps?.[language]?.[domain] ??
    sectionApps?.[Locale.BRAZIL]?.[domain]) as InterfaceApp;

  const BASE_URL = APP_STORE_LINKS[language as Locale];

  const handleInstall = () => {
    clickToAppStoreAppInstall(source, {
      name: app.name,
      handle: app.handle,
      category: appCategory,
    });
    goToAdmin(`/apps/${app.id}/authorize`)();
  };

  const handleOnClick = () => {
    clickToAppStoreCategory(source, appCategory);
  };

  const handleOnClickTitle = () => {
    if (!app.handle) {
      clickToAppStoreAppDetails(source, {
        name: app.name,
        handle: app.handle,
        category: appCategory,
      });
    }
  };

  if (!app) return <Box />;

  return (
    <Box>
      {section && (
        <Box>
          <Title as="h5">{section.title}</Title>
          <Text>{section.description}</Text>
        </Box>
      )}
      <Box paddingTop={section ? '3' : undefined}>
        <AppItemCard
          height={height}
          app={{
            name: app.name,
            handle: app.handle,
            description: app.description,
            image: !app.image ? icon : `${BASE_IMAGE_URL}/apps/${app.image}`,
            url: app.categoryUri
              ? `${BASE_URL}/categorias/${app.categoryUri}`
              : undefined,
            profileUrl: `${BASE_URL}/${app.handle}`,
          }}
          urlText={app.urlText}
          onClickInstall={handleInstall}
          onClick={handleOnClick}
          onClickTitle={handleOnClickTitle}
        />
      </Box>
    </Box>
  );
}

export default SectionAppCard;
