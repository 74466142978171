import { useMemo } from 'react';
import { Box, Text as NimbusText } from '@nimbus-ds/components';
import { useSelector } from 'react-redux';
import {
  FulfillmentPreferenceType,
  Status,
} from '@tiendanube/common/src/enums';
import {
  DataList,
  IconButton,
  Input,
  Label,
  Stack,
  Text,
} from '@tiendanube/components';
import { LocationIcon, TrashIcon } from '@tiendanube/icons';
import { fulfillment } from 'domains/Orders/Orders/pages/OrderListPage/components/commons/Status/status';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { getLocationsEntities } from 'domains/Shipping/Locations/locationSlice/locationSelectors';
import { FulfillOrdersValue } from '../FulfillOrdersModal';

interface FulfillOrdersListProps {
  fulfillOrders: FulfillOrdersValue[];
  onRemoveFulfillOrders: (
    orderId: string,
    fulfillmentId: string | undefined,
  ) => void;
  onChangeShippingCode: (
    orderId: string,
    fulfillmentId: string | undefined,
    value: string,
  ) => void;
  isSingleOrder: boolean;
}

// Helper function to check fulfillment status
const isFulfilled = (order: FulfillOrdersValue) =>
  order.fulfillment?.status === Status.MARKED_AS_FULFILLED ||
  order.fulfillment?.status === Status.DISPATCHED ||
  order.fulfillment?.status === Status.DELIVERED;

// Helper function to check shipping type
const isNonShippable = (order: FulfillOrdersValue) =>
  order.fulfillment?.shipping_info?.shipping_type ===
  FulfillmentPreferenceType.NON_SHIPPABLE;

function FulfillOrdersList({
  fulfillOrders,
  onRemoveFulfillOrders,
  onChangeShippingCode,
  isSingleOrder,
}: FulfillOrdersListProps): JSX.Element {
  const t = useTranslationOrders();
  const locations = useSelector(getLocationsEntities);

  const filteredFulfillOrders = useMemo(
    () => fulfillOrders.filter((order) => !isFulfilled(order) || isSingleOrder),
    [fulfillOrders, isSingleOrder],
  );

  return (
    <DataList>
      {filteredFulfillOrders.map((order) => {
        let icon, status, appearance, textLabel;

        if (isFulfilled(order)) {
          const shippingType = order.fulfillment?.shipping_info
            ?.shipping_type as FulfillmentPreferenceType;

          ({ icon, status, appearance } = fulfillment(
            order.fulfillment?.status as Status,
            shippingType,
          ));
          textLabel = status === Status.PICKUPED ? Status.SHIPPED : status;
          textLabel = `status.${textLabel}`;
        }

        const placeholder = isNonShippable(order)
          ? t('fulfillModal.shippingUrlPlaceholder')
          : t('fulfillModal.shippingCodePlaceholder');

        return (
          <DataList.Row
            key={order.id + order.fulfillment?.id}
            id={order.id + order.fulfillment?.id}
          >
            <DataList.Cell width={80}>
              <Stack column align="flex-start" spacing="tight">
                <Stack.Item>
                  <Text bold>{`${t('fulfillModal.order')} #${
                    order.number
                  }`}</Text>
                  {!!order.fulfillment?.number && locations.length > 1 && (
                    <Text>{` - ${t('fulfillModal.fulfillment', {
                      packageNumber: order.fulfillment.number
                        .toString()
                        .padStart(2, '0'),
                    })}`}</Text>
                  )}

                  {isNonShippable(order) && (
                    <DataList.Cell width="fill" alignRight>
                      <Label
                        id="orders-bulk-location-label"
                        label={t('fulfillModal.digital')}
                        appearance="primary"
                      />
                    </DataList.Cell>
                  )}
                </Stack.Item>

                <Stack.Item>
                  {order.fulfillment && (
                    <Label
                      id="orders-bulk-location-label"
                      label={`${t('fulfillModal.fromLocation')} ${
                        order.fulfillment.location.name
                      }`}
                      icon={LocationIcon}
                    />
                  )}

                  {isFulfilled(order) && (
                    <Box marginLeft="2" display="inline-grid">
                      <Label
                        id={status}
                        label={t(textLabel)}
                        icon={icon}
                        appearance={appearance}
                      />
                    </Box>
                  )}
                </Stack.Item>
                <Stack.Item>
                  <NimbusText fontSize="caption">{order.consumer}</NimbusText>
                </Stack.Item>
                {!!order.zipcode && (
                  <Stack.Item>
                    <NimbusText fontSize="caption">
                      {t('fulfillModal.zipcode', { zipcode: order.zipcode })}
                    </NimbusText>
                  </Stack.Item>
                )}
              </Stack>
            </DataList.Cell>

            {!isFulfilled(order) && (
              <>
                <DataList.Cell width={20} alignRight>
                  <IconButton
                    icon={TrashIcon}
                    ariaLabel={t('fulfillModal.remove')}
                    onClick={() =>
                      onRemoveFulfillOrders(order.id, order.fulfillment?.id)
                    }
                  />
                </DataList.Cell>
                <DataList.Cell width={100} extraPadding>
                  <Input
                    name={`shippingCode-${order.id}-${order.fulfillment?.id}`}
                    placeholder={placeholder}
                    value={order.shippingCode}
                    onChange={(e) =>
                      onChangeShippingCode(
                        order.id,
                        order.fulfillment?.id,
                        e.value,
                      )
                    }
                  />
                </DataList.Cell>
              </>
            )}
          </DataList.Row>
        );
      })}
    </DataList>
  );
}

export default FulfillOrdersList;
