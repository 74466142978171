import { ReactNode } from 'react';
import { LockIcon } from '@tiendanube/icons';
import Stack from 'commons/components/Stack';
import './ScopesItemHeaderLayout.scss';

interface ScopesItemHeaderLayoutProps {
  title: ReactNode;
  rigthIcon: ReactNode;
  onClickRigthIcon?: () => void;
}

function ScopesItemHeaderLayout({
  onClickRigthIcon,
  rigthIcon,
  title,
}: ScopesItemHeaderLayoutProps) {
  return (
    <div
      className="stratus--scopes-item-header-layout"
      onClick={onClickRigthIcon}
    >
      <Stack justify="space-between" spacing="none">
        <Stack.Item>
          <Stack wrap align="center" justify="flex-start" spacing="tight">
            <LockIcon />
            {title}
          </Stack>
        </Stack.Item>
        <Stack.Item>{rigthIcon}</Stack.Item>
      </Stack>
    </div>
  );
}

export default ScopesItemHeaderLayout;
