import { TaxInformationResponseDto } from '@tiendanube/common';
import { BaseCard } from '@tiendanube/components';
import { EditIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import { CancelAndSaveButtons } from 'commons/components';
import Link from 'commons/components/LegacyLink';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
import { formatVatCondition } from 'domains/Billing/Invoices/utils';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface ConfirmTaxDataProps {
  loading: boolean;
  onSave?: () => void;
  onEdit: () => void;
  taxData: TaxInformationResponseDto;
}

export function CuitFiscalData({
  loading,
  onSave,
  taxData,
  onEdit,
}: ConfirmTaxDataProps) {
  const t = useTranslationBilling();
  const { goBack } = useNavegate();
  const { address, name, numberId, numberIdType, type, vatCondition } = taxData;
  return (
    <BaseCard>
      <BaseCard.Body>
        <Stack column align="stretch">
          <Stack spacing="tight" column align="flex-start">
            <Text>{t('invoicesInfoPage.confirmTaxData.idType')}</Text>
            <Text bold>{numberIdType}</Text>
          </Stack>
          <Stack spacing="tight" column align="flex-start">
            <Text>{t('invoicesInfoPage.confirmTaxData.idNumber')}</Text>
            <Text bold>{numberId}</Text>
          </Stack>
          <Link appearance="primary" onClick={onEdit} icon={EditIcon}>
            {t('invoicesInfoPage.confirmTaxData.edit')}{' '}
          </Link>
        </Stack>
        <hr />
        <Stack column align="stretch">
          <Stack spacing="tight" column align="flex-start">
            <Text>{t('invoicesInfoPage.confirmTaxData.taxCondition')}</Text>
            <Text bold>
              {formatVatCondition(vatCondition?.toString() ?? '')}
            </Text>
          </Stack>
          <Stack spacing="tight" column align="flex-start">
            <Text>{t('invoicesInfoPage.confirmTaxData.invoiceType')}</Text>
            <Text bold>{type}</Text>
          </Stack>
          <Stack spacing="tight" column align="flex-start">
            <Text>{t('invoicesInfoPage.confirmTaxData.name')}</Text>
            <Text bold>{name}</Text>
          </Stack>
          <Stack spacing="tight" column align="flex-start">
            <Text>{t('invoicesInfoPage.confirmTaxData.address')}</Text>
            <Text bold>{address}</Text>
          </Stack>
        </Stack>
      </BaseCard.Body>
      {onSave && (
        <BaseCard.Footer>
          <Stack align="flex-start">
            <CancelAndSaveButtons
              isLoading={loading}
              onCancel={goBack}
              onSave={onSave}
            />
          </Stack>
        </BaseCard.Footer>
      )}
    </BaseCard>
  );
}
