import { ReactNode, createContext, useContext, useRef } from 'react';
import { useNavegate } from 'App/hooks';
import { useModal } from 'commons/hooks';

const noop = () => undefined;

type TypeGoToInventoryProvider = {
  handleGoToInventory: (productId: string, variantId: string) => void;
  handleSaveAndGoInventory: (handleSave: () => void) => void;
  isShowGotoInventory: boolean;
  goToInventory: () => void;
  handleDismissGoToInventory: () => void;
  cleanGoToInventory: () => void;
  setDirty: (dirty: boolean) => void;
};

export const GoToInventoryContext = createContext<TypeGoToInventoryProvider>(
  {} as TypeGoToInventoryProvider,
);

export const useGoToInventory = () => useContext(GoToInventoryContext);

function GoToInventoryProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const { goTo } = useNavegate();
  const [isShowGotoInventory, showModal, noShowModal] = useModal(false);
  const isDirty = useRef(false);
  const setDirty = (dirty: boolean) => (isDirty.current = dirty);

  const goToInventoryRef = useRef<() => void>(noop);

  const handleGoToInventory = (productId: string, variantId: string) => {
    goToInventoryRef.current = () =>
      goTo(`/inventory/${productId}/${variantId}`);

    if (isDirty.current) {
      showModal();
      return;
    }

    goToInventoryRef.current();
  };

  const handleSaveAndGoInventory = (handleSave: () => void) => {
    noShowModal();
    handleSave();
  };

  const cleanGoToInventory = () => {
    goToInventoryRef.current = noop;
  };

  return (
    <GoToInventoryContext.Provider
      value={{
        handleGoToInventory,
        handleSaveAndGoInventory,
        isShowGotoInventory,
        handleDismissGoToInventory: noShowModal,
        goToInventory: goToInventoryRef.current,
        setDirty: setDirty,
        cleanGoToInventory,
      }}
    >
      {children}
    </GoToInventoryContext.Provider>
  );
}

export default GoToInventoryProvider;
