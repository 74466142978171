import { Box, Table, Tag, Text } from '@nimbus-ds/components';
import { BrowserIcon, DesktopIcon, MobileIcon } from '@nimbus-ds/icons';
import { UserSessionDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import { useTranslationAccount } from '../../../../../hooks';
import { useGetLocationDescription } from '../../../hooks';
import { ICON_SIZE } from '../../../settings';
import { DeleteUserSessionAction } from '../../DeleteUserSessionAction';
import { LastActivityText } from '../../LastActivityText';

interface UserSessionsListDesktopRowProps {
  userSession: UserSessionDto;
}

export function UserSessionsListDesktopRow({
  userSession: { id, userAgent, location, user, lastActivity, current },
}: Readonly<UserSessionsListDesktopRowProps>) {
  const t = useTranslationAccount('sessionManagementPage.table.body');
  const locationFullName = useGetLocationDescription(location);

  return (
    <Table.Row>
      <Table.Cell width="40%">
        <Box display="flex" alignItems="center" gap="none" minHeight="45px">
          <Text>{locationFullName}</Text>
        </Box>
      </Table.Cell>
      <Table.Cell width="35%">
        <Box display="flex" alignItems="center" gap="none" minHeight="45px">
          <Text wordBreak="break-word">{user.email}</Text>
        </Box>
      </Table.Cell>
      <Table.Cell width="25%">
        <Box display="flex" alignItems="center" gap="none" minHeight="45px">
          {userAgent ? (
            <Stack spacing="tight">
              {userAgent.isMobile ? (
                <MobileIcon size={ICON_SIZE} />
              ) : (
                <DesktopIcon size={ICON_SIZE} />
              )}{' '}
              <Text>
                {userAgent.operatingSystem} - {userAgent.browser}
              </Text>
            </Stack>
          ) : (
            <Text>{t('withoutUserAgent.text')}</Text>
          )}
        </Box>
      </Table.Cell>
      <Table.Cell width={{ md: '200px', xl: '230px' }}>
        <Box display="flex" alignItems="center" gap="none" minHeight="45px">
          <Stack spacing="tight">
            {current ? (
              <Tag appearance="primary">
                <BrowserIcon size={ICON_SIZE} />{' '}
                <Text fontSize="caption" color="primary-textLow" lineClamp={1}>
                  {t('currentSessionTag')}
                </Text>
              </Tag>
            ) : (
              <LastActivityText lastActivity={lastActivity} />
            )}
          </Stack>
        </Box>
      </Table.Cell>
      <Table.Cell width={{ md: '50px', xl: '65px' }}>
        <Box
          display="flex"
          alignItems="center"
          gap="none"
          minHeight="45px"
          justifyContent="center"
        >
          {current ? <Text>-</Text> : <DeleteUserSessionAction id={id} />}
        </Box>
      </Table.Cell>
    </Table.Row>
  );
}
