import { Accordion, Box, Text } from '@nimbus-ds/components';
import { capitalizeFirstLetter } from 'commons/utils/capitalizeFirstLetter';
import {
  useGetLastPayment,
  useFrequencyTranslation,
  useGetSubscriptions,
} from 'domains/Billing/Checkout/hooks';
import useTranslatePaymentMethod from 'domains/Billing/Checkout/hooks/useTranslatePaymentMethod';
import useTranslatePaymentType from 'domains/Billing/Checkout/hooks/useTranslatePaymentType';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { formatDate } from 'domains/Billing/utils';

function LastPaymentDetail() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.plansAndApps.paymentDetails',
  );

  const { lastSuccessfulPayment } = useGetLastPayment('plan-cost');
  const { subscriptionForPlan } = useGetSubscriptions();

  const { translateGatewayPaymentMethod } = useTranslatePaymentMethod();
  const translatePaymentType = useTranslatePaymentType();
  const { getFrequencyDescriptor } = useFrequencyTranslation();
  const subscriptionText = subscriptionForPlan
    ? capitalizeFirstLetter(getFrequencyDescriptor(subscriptionForPlan))
    : undefined;

  const paymentType = lastSuccessfulPayment?.paymentMethod?.type
    ? translatePaymentType(lastSuccessfulPayment.paymentMethod.type)
    : undefined;

  const paymentMethod = lastSuccessfulPayment?.gatewayDetail.paymentMethod
    ? translateGatewayPaymentMethod(
        lastSuccessfulPayment.gatewayDetail.paymentMethod,
      )
    : undefined;

  const onlySubscriptionAndNextPayment = () =>
    subscriptionText !== undefined &&
    subscriptionForPlan &&
    fields.every((field) => {
      if (
        (field.name === t('subscription') &&
          field.value === subscriptionText) ||
        (field.name === t('nextPayment') &&
          field.value === formatDate(subscriptionForPlan.nextExecutionDate))
      ) {
        return true;
      }
      return field.value === undefined;
    });

  const fields = [
    {
      name: t('lastPayment'),
      value: lastSuccessfulPayment && formatDate(lastSuccessfulPayment.paidAt),
    },
    {
      name: t('subscription'),
      value: subscriptionText,
    },
    {
      name: t('paymentType'),
      value: paymentType,
    },
    {
      name: t('paymentMethod'),
      value: paymentMethod,
    },
    {
      name: t('nextPayment'),
      value:
        subscriptionForPlan &&
        formatDate(subscriptionForPlan.nextExecutionDate),
    },
  ];

  if (fields.every((field) => field.value === undefined)) return null;

  return (
    <Accordion selectedDefault="0">
      <Accordion.Item index="0">
        <Accordion.Header borderTop="none">
          <Box width="100%">
            <Text>{t('title')}</Text>
          </Box>
        </Accordion.Header>
        <Accordion.Body>
          <Box
            display="flex"
            flexDirection="column"
            gap={onlySubscriptionAndNextPayment() ? 'none' : '4'}
            flexGrow="1"
            marginTop="6"
          >
            {/* 
              Using slice to segment the rendering of fields based on visual grouping requirements.
              - fields.slice(0, 2) renders the 'lastPayment' and 'subscription' fields.
              - fields.slice(2, 4) specifically groups the 'paymentType' and 'paymentMethod' fields without any gap between them.
              This approach offers flexibility for potential future changes in field order or additional fields. 
              */}
            {/* Render lastPayment date and subscription type */}
            {fields.slice(0, 2).map(
              (field, index) =>
                field.value && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    key={index}
                  >
                    <Text fontSize="caption">{field.name}:</Text>
                    <Text fontSize="caption">{field.value}</Text>
                  </Box>
                ),
            )}
            <Box>
              {/* Render paymentType and paymentMethod */}
              {fields.slice(2, 4).map(
                (field, index) =>
                  field.value && (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      key={index}
                    >
                      <Text fontSize="caption">{field.name}:</Text>
                      <Text fontSize="caption">{field.value}</Text>
                    </Box>
                  ),
              )}
            </Box>
            {/* Render nextPayment field */}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              {!!fields[4].value && (
                <>
                  <Text fontSize="caption">{fields[4].name}:</Text>
                  <Text fontSize="caption">{fields[4].value}</Text>
                </>
              )}
            </Box>
          </Box>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default LastPaymentDetail;
