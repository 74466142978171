import { Tooltip } from '@tiendanube/components';
import { InfoCircleIcon } from '@tiendanube/icons';
import { CardWithTitle } from 'commons/components';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
import {
  useGetFormattedValueSummary,
  useTranslationStatistics,
} from 'domains/Statistics/hooks';
import { SummaryType } from 'domains/Statistics/types';
import Skeleton from './Skeleton';
import TrendIndicator from '../TrendIndicator';

export interface SummaryValues {
  currentValue: number;
  previousValue: number;
  percentageDiff: number;
}

interface SummaryValuesCardProps extends SummaryValues {
  item: SummaryType;
  textTooltip?: string;
}

function SummaryValuesCard({
  item,
  currentValue,
  previousValue,
  percentageDiff,
  textTooltip,
}: SummaryValuesCardProps) {
  const t = useTranslationStatistics();
  const getFormattedValueSummary = useGetFormattedValueSummary();

  return (
    <CardWithTitle>
      <Stack column align="stretch" spacing="tight">
        <Stack wrap justify="space-between">
          <Stack wrap align="center" spacing="tight">
            <Text>{t(`statistics.globalInfoCard.${item}.title`)}</Text>
            {!!textTooltip && (
              <Tooltip ariaLabel={textTooltip} labelIcon={InfoCircleIcon}>
                <Text>{t(textTooltip)}</Text>
              </Tooltip>
            )}
          </Stack>
          <Text>{getFormattedValueSummary(currentValue, item)}</Text>
        </Stack>
        <Stack wrap justify="space-between">
          <Text appearance="light" size="caption">
            {t('statistics.filters.samePeriod')}
          </Text>
          <Text appearance="light" size="caption">
            {getFormattedValueSummary(previousValue, item)}
          </Text>
        </Stack>
        <TrendIndicator
          value={percentageDiff}
          text={t('statistics.summaryTable.variation')}
        />
      </Stack>
    </CardWithTitle>
  );
}

SummaryValuesCard.Skeleton = Skeleton;

export default SummaryValuesCard;
