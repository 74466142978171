import { useEffect } from 'react';
import { Box } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import {
  CurrencyType,
  OrderDetailsAmountsDto,
  RefundAttemptDto,
} from '@tiendanube/common';
import { OrderDetailsTaxesResponseDto } from '@tiendanube/common/src/domains/orders/orders/OrderDetailsResponse';
import { Domain } from '@tiendanube/common/src/enums';
import { DataList, Text } from '@tiendanube/components';
import FeatureFlag from 'App/components/FeatureFlag';
import { TOTAL_PAID_ORDER_DETAIL } from 'App/featuresFlags';
import { CurrencyPrice } from 'commons/components';
import { useBusinessData } from 'domains/Account/hooks';
import { useOrderDiscounts } from 'domains/Orders/Orders/pages/hooks';
import { OrderTotalAmountRow } from '../index';

interface OrderSummaryAmountsProps {
  amounts: OrderDetailsAmountsDto;
  currency: CurrencyType;
  totalProducts: number;
  billingAddressCountry?: string;
  taxes?: OrderDetailsTaxesResponseDto;
  paymentMethod?: string;
  refundAttempts: RefundAttemptDto[];
}

function OrderSummaryAmounts({
  amounts,
  taxes,
  billingAddressCountry,
  refundAttempts,
  currency,
  totalProducts,
  paymentMethod,
}: OrderSummaryAmountsProps): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);
  const discounts = useOrderDiscounts(amounts, paymentMethod);

  const {
    subtotal,
    total,
    weight,
    shipping,
    shippingMethod,
    shippingDetail,
    totalPaid,
    otherCosts,
  } = amounts;

  const hasWeight = !!(weight && weight > 0);
  const shippingMethodWeight = shippingMethod || hasWeight;
  const { fetchBusinessData, businessDataValues } = useBusinessData();

  useEffect(() => {
    fetchBusinessData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DataList.Row grayed id="subtotal">
        <DataList.Cell width={70}>
          <Text>{`${t('orderSummaryCard.tableAmounts.subtotal', {
            count: totalProducts,
          })}`}</Text>
        </DataList.Cell>
        <DataList.Cell width={30}>
          <CurrencyPrice
            price={subtotal}
            currency={currency}
            block
            textAlign="right"
          />
        </DataList.Cell>
      </DataList.Row>
      {!!shipping && shipping > 0 && (
        <DataList.Row grayed id="shippingCost">
          <DataList.Cell width={70}>
            <Text>
              {shippingDetail
                ? `${t('orderSummaryCard.tableAmounts.shippingDetail', {
                    shippingDetail,
                  })}`
                : `${t('orderSummaryCard.tableAmounts.shipping', {
                    shippingMethod,
                  })}`}
            </Text>
          </DataList.Cell>
          <DataList.Cell width={30}>
            <CurrencyPrice
              price={shipping}
              currency={currency}
              block
              textAlign="right"
            />
          </DataList.Cell>
        </DataList.Row>
      )}
      {discounts.map((discount) => (
        <DataList.Row id={discount.id} key={discount.id}>
          <DataList.Cell width={70}>
            <Text>{discount.name}</Text>
          </DataList.Cell>
          <DataList.Cell width={30}>
            <CurrencyPrice
              price={discount.value}
              currency={currency}
              block
              textAlign="right"
              preFix={discount.prefix}
              appearance={discount.appearance}
            />
          </DataList.Cell>
        </DataList.Row>
      ))}
      <OrderTotalAmountRow
        isEdited={false}
        isTotalUpdated={false}
        total={total}
        refundAttempts={refundAttempts}
        currency={currency}
        billingAddressCountry={billingAddressCountry}
        businessDataValues={businessDataValues}
        taxes={taxes}
      />

      <FeatureFlag flag={TOTAL_PAID_ORDER_DETAIL}>
        {!!totalPaid && !!otherCosts && (
          <>
            <DataList.Row id="otherCosts">
              <DataList.Cell width={70}>
                <Text>{`${t(
                  'orderSummaryCard.tableAmounts.otherCosts',
                )}`}</Text>
              </DataList.Cell>
              <DataList.Cell width={30}>
                <CurrencyPrice price={otherCosts} block textAlign="right" />
              </DataList.Cell>
            </DataList.Row>

            <DataList.Row id="totalPaid" grayed>
              <DataList.Cell width={70}>
                <Text bold>{`${t(
                  'orderSummaryCard.tableAmounts.totalPaid',
                )}`}</Text>
              </DataList.Cell>
              <DataList.Cell width={30}>
                <CurrencyPrice price={totalPaid} block textAlign="right" bold />
              </DataList.Cell>
            </DataList.Row>
          </>
        )}
      </FeatureFlag>

      {!!shippingMethodWeight && (
        <DataList.Row id="weight">
          <Box
            gap="none"
            display="flex"
            paddingLeft="2"
            flexDirection="column"
            paddingTop="1"
            paddingBottom="2"
          >
            {!!shippingMethod && <Text bold>{shippingMethod}</Text>}
            {hasWeight && (
              <Text>
                {`${t('orderSummaryCard.tableAmounts.weightTotal', {
                  weight,
                })}`}
              </Text>
            )}
          </Box>
        </DataList.Row>
      )}
    </>
  );
}
export default OrderSummaryAmounts;
