import { Status as StatusEnum } from '@tiendanube/common/src/enums';
import { StoreIcon, MailIcon, IconProps } from '@tiendanube/icons';

export default function getFulfillmentIconStatusInfo(
  isNonShippablePickup: boolean,
  isNonShippableOrDigital: boolean,
  defaultIcon: React.FC<IconProps>,
): { fulfillmentIcon: React.FC<IconProps>; fulfillmentStatus: StatusEnum } {
  if (isNonShippablePickup) {
    return {
      fulfillmentIcon: StoreIcon,
      fulfillmentStatus: StatusEnum.PICKUPED,
    };
  }
  if (isNonShippableOrDigital) {
    return {
      fulfillmentIcon: MailIcon,
      fulfillmentStatus: StatusEnum.SHIPPED_DIGITAL,
    };
  }
  return {
    fulfillmentIcon: defaultIcon,
    fulfillmentStatus: StatusEnum.DELIVERED,
  };
}
