import { OrderDetailsResponseDto } from '@tiendanube/common';
import { InterfaceNameBooleanValue, InterfaceNameValue } from 'commons/types';
import { RefundAttributes } from '../../../hooks/useRefundOrder/useRefundOrder';
import { Steps, UseRefundStepsResult } from '../../hooks/useSteps';
import ConfirmRefundDetailsPage from '../ConfirmRefundDetailsPage';
import SetUpRefundDetailsPage from '../SetUpRefundDetailsPage';

interface Attributes {
  orderDetails: OrderDetailsResponseDto;
  values: RefundAttributes;
  onDetailsChange: (
    data: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => void;
  onConfirm: () => void;
  useSteps: UseRefundStepsResult;
  disableButton: () => boolean;
}

function CurrentPage({
  orderDetails,
  values,
  onDetailsChange,
  onConfirm,
  useSteps,
  disableButton,
}: Attributes): JSX.Element | null {
  switch (useSteps.step) {
    case Steps.SetUpRefundDetails:
      return (
        <SetUpRefundDetailsPage
          orderDetails={orderDetails}
          values={values}
          useSteps={useSteps}
          onChange={onDetailsChange}
          disableButton={disableButton}
        />
      );
    case Steps.ConfirmRefundDetails:
      return (
        <ConfirmRefundDetailsPage
          values={values}
          orderDetails={orderDetails}
          onConfirm={onConfirm}
          disableButton={disableButton}
          useSteps={useSteps}
        />
      );
    default:
      return null;
  }
}

export default CurrentPage;
