/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from 'react';
import { Card, Checkbox, Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { EstimateDeliveryTimeResponseDto } from '@tiendanube/common';
import { Stack, InputNumberNimbus } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import PickupReadyTimeSkeleton from './Skeleton';

interface PickupReadyTimeProps {
  time: EstimateDeliveryTimeResponseDto;
  errors: Partial<Record<string, string>>;
  setFieldValue: (name: string, value: any) => void;
}

function PickupReadyTime({
  time,
  errors,
  setFieldValue,
}: Readonly<PickupReadyTimeProps>): JSX.Element {
  const t = useTranslationShipping(
    'deliveryMethods.pickupPoints.form.secondStep.estimatedDeliveryTime',
  );

  const [ready, setReady] = useState(time.minDays === 0 && time.maxDays === 0);
  const messageTimeMax =
    errors['estimateDeliveryTime.maxDays'] && !ready
      ? t(errors['estimateDeliveryTime.maxDays'])
      : t('days');
  const messageTimeMin =
    errors['estimateDeliveryTime.minDays'] && !ready
      ? t(errors['estimateDeliveryTime.minDays'])
      : t('days');

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newTime = {
      ...time,
      [e.target.name]:
        e.target.value === '' ? e.target.value : parseFloat(e.target.value),
    };

    setReady(newTime.minDays === 0 && newTime.maxDays === 0);
    setFieldValue('estimateDeliveryTime', newTime);
  };

  const handleOnReady = (e: ChangeEvent<HTMLInputElement>) => {
    setReady(e.target.checked);
    const newTime: EstimateDeliveryTimeResponseDto = {
      minDays: 0,
      maxDays: 0,
    };
    setFieldValue('estimateDeliveryTime', newTime);
  };

  useEffect(() => {
    setReady(time.minDays === 0 && time.maxDays === 0);
  }, [time.maxDays]);

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <Stack column align="stretch">
          <Stack align="stretch">
            <FormField label={t('from')}>
              <InputNumberNimbus
                type="integer"
                disabled={ready}
                onChange={onChange}
                value={time.minDays}
                name="minDays"
                appearance={
                  errors['estimateDeliveryTime.minDays'] && !ready
                    ? 'danger'
                    : 'neutral'
                }
              />
              {!!messageTimeMin && (
                <Text
                  fontSize="caption"
                  color={
                    errors['estimateDeliveryTime.minDays'] && !ready
                      ? 'danger-textLow'
                      : 'neutral-textLow'
                  }
                >
                  {messageTimeMin}
                </Text>
              )}
            </FormField>
            <FormField label={t('to')}>
              <InputNumberNimbus
                type="integer"
                disabled={ready}
                onChange={onChange}
                value={time.maxDays}
                name="maxDays"
                appearance={
                  errors['estimateDeliveryTime.maxDays'] && !ready
                    ? 'danger'
                    : 'neutral'
                }
              />
              {!!messageTimeMax && (
                <Text
                  fontSize="caption"
                  color={
                    errors['estimateDeliveryTime.maxDays'] && !ready
                      ? 'danger-textLow'
                      : 'neutral-textLow'
                  }
                >
                  {messageTimeMax}
                </Text>
              )}
            </FormField>
          </Stack>
          <Checkbox
            name="ready"
            checked={ready}
            label={t('readyForPickup')}
            onChange={handleOnReady}
          />
        </Stack>
      </Card.Body>
    </Card>
  );
}

PickupReadyTime.Skeleton = PickupReadyTimeSkeleton;
export default PickupReadyTime;
