import { Input, Select } from '@tiendanube/components';
import { Stack } from 'commons/components';
import Text from 'commons/components/LegacyText';
import { useAutoFocus } from 'commons/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { AddressProps } from '..';
import Skeleton from '../Skeleton';

function GenericAddress({
  values,
  errors,
  setFieldValue,
  provinces,
  autoFocus,
}: AddressProps): JSX.Element {
  const t = useTranslationShipping();
  const { ref } = useAutoFocus();

  const onChange = ({ name, value }): void => {
    if (name === 'provinceCode') {
      const province = provinces?.find(({ code }) => code === value);
      setFieldValue('province', province?.name || '');
    }
    setFieldValue(name, value);
  };

  const neighborhoodHelpText = t(errors.neighborhood || '');
  const streetHelpText = t(errors.street || '');
  const numberHelpText = t(errors.number || '');
  const zipcodeHelpText = t(errors.zipcode || '');
  const cityHelpText = t(errors.city || '');

  return (
    <Stack innerRef={autoFocus && ref} column align="stretch">
      <Input
        type="text"
        label={t('address.street')}
        onChange={onChange}
        name="street"
        value={values.street}
        helpText={streetHelpText}
        appearance={streetHelpText ? 'validation_error' : 'default'}
      />

      <Input
        type="text"
        label={t('address.number')}
        onChange={onChange}
        name="number"
        value={values.number}
        helpText={numberHelpText}
        appearance={numberHelpText ? 'validation_error' : 'default'}
      />

      <Input
        type="text"
        label={t('address.complement')}
        onChange={onChange}
        name="complement"
        value={values.complement}
      />

      <Input
        type="text"
        label={t('address.zipcode')}
        onChange={onChange}
        name="zipcode"
        value={values.zipcode}
        helpText={zipcodeHelpText}
        appearance={zipcodeHelpText ? 'validation_error' : 'default'}
      />

      <Input
        type="text"
        label={t('address.neighborhood')}
        onChange={onChange}
        name="neighborhood"
        value={values.neighborhood}
        helpText={neighborhoodHelpText}
        appearance={neighborhoodHelpText ? 'validation_error' : 'default'}
      />

      <Input
        type="text"
        label={t('address.city')}
        name="city"
        onChange={onChange}
        value={values.city}
        helpText={cityHelpText}
        appearance={cityHelpText ? 'validation_error' : 'default'}
      />

      <Select
        placeholder={t('address.selectProvince')}
        value={values.provinceCode}
        name="provinceCode"
        label={t('address.province')}
        options={
          provinces?.map(({ name, code }) => ({
            label: name,
            value: code,
          })) || []
        }
        onChange={onChange}
        helpText={
          !!errors.provinceCode && (
            <Text size="caption" appearance="danger">
              {t(errors.provinceCode)}
            </Text>
          )
        }
      />
    </Stack>
  );
}

GenericAddress.Skeleton = Skeleton;

export default GenericAddress;
