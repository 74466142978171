import { Box, Text } from '@nimbus-ds/components';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { Trans, useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { MESSAGES_ROUTES } from 'domains/Customers/Messages/messagesRoutes';
import {
  SectionAppCard,
  SectionAppCardDomains,
} from 'domains/PartnersApps/components';
import emptyMessagesImage from './empty-messages.png';

function MessagesListEmpty(): JSX.Element {
  const { t } = useTranslation(Domain.CUSTOMERS);

  return (
    <EmptyMessage
      title={t('messages.emptyResults.title')}
      actions={
        <Box display="flex" flex="1" flexDirection="column" width="100%">
          <Text>
            <Trans
              t={t}
              i18nKey="messages.emptyResults.body"
              components={{
                br: <br />,
              }}
            />
          </Text>
          <Box paddingTop="8">
            <SectionAppCard
              source={MESSAGES_ROUTES.messages}
              appCategory="communication"
              domain={SectionAppCardDomains.MESSAGES}
            />
          </Box>
        </Box>
      }
      illustration={<img width="100%" alt="" src={emptyMessagesImage} />}
    />
  );
}

export default MessagesListEmpty;
