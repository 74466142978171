import { useCallback } from 'react';
import useIsLogged from '../useIsLogged';
import useRefreshInfoToSync from '../useRefreshInfoToSync';

type RefreshOrdersType = (noCache?: boolean) => void;

function useRefreshInfoToSyncInAppActive(): RefreshOrdersType {
  const { refreshInfo } = useRefreshInfoToSync();
  const { isLogin } = useIsLogged();

  const refreshInfoToSyncInAppActive = useCallback(
    (noCache = false) => {
      if (isLogin) {
        refreshInfo(noCache);
      }
    },
    [isLogin, refreshInfo],
  );

  return refreshInfoToSyncInAppActive;
}

export default useRefreshInfoToSyncInAppActive;
