import { Icon, Tag, Text, Title } from '@nimbus-ds/components';
import { StoreIcon } from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';
import {
  LocationResponseDto,
  PickupPointResponseDto,
} from '@tiendanube/common';
import { Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { thousandSeparator } from 'domains/Statistics/utils';
import PickupPointItemActions from './PickupPointItemActions';
import PickupPointItemSkeleton from './Skeleton';
import { groupBusinessHours } from './utils';

export interface GroupedBusinessHours {
  start: string;
  end: string;
  days: string[];
}

interface PickupPointItemProps {
  pickupPoint: PickupPointResponseDto;
  locations: LocationResponseDto[];
  handleUpdateStatus: (pickupPoint: PickupPointResponseDto) => void;
  handleDelete?: (id: string) => void;
}

function PickupPointItem({
  pickupPoint,
  locations,
  handleUpdateStatus,
  handleDelete,
}: Readonly<PickupPointItemProps>) {
  const t = useTranslationShipping('deliveryMethods.pickupPoints.list');
  const {
    address,
    businessHours,
    cost,
    estimateDeliveryTime,
    id,
    isLocation,
    locations: vinculedLocations,
    name,
    status,
  } = pickupPoint;

  const vinculedLocationsNames = locations
    .filter((location) => vinculedLocations.includes(location.id))
    .map(({ name }) => name);

  const estimatedTimeText =
    estimateDeliveryTime.maxDays === 0
      ? t('estimatedDeliveryTime.withoutDays')
      : estimateDeliveryTime.minDays === 0
      ? t('estimatedDeliveryTime.withJustMaxDay', {
          maxDays: estimateDeliveryTime.maxDays,
        })
      : t('estimatedDeliveryTime.withDays', {
          minDays: estimateDeliveryTime.minDays,
          maxDays: estimateDeliveryTime.maxDays,
        });

  const costKey = cost.type === 'to_agree' ? 'toAgree' : 'free';

  const costText =
    cost.type === 'fixed'
      ? t('cost.fixed', {
          value: thousandSeparator(parseFloat(cost.value), false),
        })
      : t(`cost.${costKey}`);

  const groupedHours = groupBusinessHours(businessHours ?? []);
  const formattedBusinessHours = groupedHours.map(({ days, start, end }) => {
    if (days.length === 1) {
      return t('businessHours.diffHours', {
        day: t(`daysOfWeek.${days[0]}`),
        start,
        end,
      });
    } else {
      const startDay = days[0];
      const endDay = days[days.length - 1];
      return t('businessHours.sameHours', {
        startDay: t(`daysOfWeek.${startDay}`),
        endDay: t(`daysOfWeek.${endDay}`),
        start,
        end,
      });
    }
  });

  return (
    <DataList.Row id={id}>
      <Stack justify="space-between">
        <Stack column align="flex-start">
          <Stack spacing="tight">
            <Title as="h6">{isLocation ? locations[0].name : name}</Title>
            <Tag appearance={status ? 'success' : 'neutral'}>
              {t(status ? 'status.active' : 'status.inactive')}
            </Tag>
          </Stack>
          <Stack column align="flex-start" spacing="none">
            <Text>{isLocation ? locations[0].address : address}</Text>
            <Text>{formattedBusinessHours.join(' - ')}</Text>
            <Text>
              {costText} - {estimatedTimeText}
            </Text>
          </Stack>
          <Stack align="flex-start" spacing="tight" wrap>
            {!isLocation &&
              vinculedLocationsNames.length > 0 &&
              vinculedLocationsNames.map((location) => (
                <Tag key={location} appearance="neutral">
                  <Icon source={<StoreIcon />} />
                  {location}
                </Tag>
              ))}
          </Stack>
        </Stack>
        <PickupPointItemActions
          handleUpdateStatus={handleUpdateStatus}
          handleDelete={handleDelete}
          pickupPoint={pickupPoint}
        />
      </Stack>
    </DataList.Row>
  );
}

PickupPointItem.Skeleton = PickupPointItemSkeleton;

export default PickupPointItem;
