import { OrderDetailsResponseDto } from '@tiendanube/common';
import { AlertSessionStorage } from '../../../../../../../App/components';
import useTranslationOrders from '../../../../../useTranslationOrders';

interface Attributes {
  orderDetails: OrderDetailsResponseDto;
}

function RefundStatusBanner({ orderDetails }: Attributes): JSX.Element | null {
  const t = useTranslationOrders();
  const refundAttempts = [...(orderDetails.payment?.refundAttempts || [])];
  const attempt = refundAttempts
    .sort(
      (a, b) =>
        // get latest refund attempt
        new Date(a.updated_at).getTime() - new Date(b.updated_at).getTime(),
    )
    .at(-1);

  if (!attempt) return null;
  const code = attempt.status.code;
  const appearance =
    code === 'success' ? 'success' : code === 'failed' ? 'danger' : 'primary';

  return (
    <AlertSessionStorage
      title={t(`refund.bannerMessages.${attempt.status.code}.title`)}
      appearance={appearance}
      keyName={`refund-attempt-banner-${attempt.id}`}
    >
      {t(`refund.bannerMessages.${attempt.status.code}.description`)}
    </AlertSessionStorage>
  );
}

export default RefundStatusBanner;
