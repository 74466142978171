/* eslint-disable max-statements */
import { useCallback, useMemo } from 'react';
import { Box } from '@nimbus-ds/components';
import {
  OrderDetailsResponseDto,
  OrderFulfillRequestDto,
  OrderFulfillmentUpdateRequestDto,
  StatusEnum,
} from '@tiendanube/common';
import { FulfillmentPreferenceType } from '@tiendanube/common/src/enums';
import { Card, InterfaceLabel, Text, Title } from '@tiendanube/components';
import FeatureFlag from 'App/components/FeatureFlag';
import { NEW_ADMIN_ORDER_DETAILS } from 'App/featuresFlags';
import { useAppDispatch } from 'App/store';
import { Layout, RemarksCard, useResponsive } from 'commons/components';
import { useAsyncFunc, useDocumentTitle } from 'commons/hooks';
import { PICKUP_TYPE } from 'domains/FulfillmentOrders/pages/PrintDocumentPage/constants';
import {
  CustomerDataCard,
  DateAndDevice,
  DetailsHeader,
  OrderHelpLink,
  OrderSummaryCard,
} from 'domains/Orders/components';
import { cleanUpdateOrderStatus } from 'domains/Orders/Orders/ordersSlice';
import getShippingOrderStatus from 'domains/Orders/Orders/utils/getShippingOrderStatus';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import useActionsToast from './useActionsToast';
import { fulfillment } from '../../../OrderListPage/components/commons/Status/status';
import {
  CardBillingAddress,
  CardDeliveryAddress,
  CardPickupAddress,
  CardPayment,
  CardFulfillment,
  CardTrackingPage,
  SectionOrdersMetafields,
} from '../../components';
import CardCustomerCustomization from '../CardCustomerCustomization';
import CardFulfillmentOrdersList from '../CardFulfillmentOrdersList';
import CardOrderAndFulfillment from '../CardOrderAndFulfillment';
import { DeliveryAddressValuesType } from '../EditDeliveryAdressModal/EditDeliveryAdressModal';
import TimelineSection from '../TimelineSection';

interface OrderDetailsProps {
  orderDetails: OrderDetailsResponseDto;
  onRemarkOrder: (text: string) => void;
  onPaidOrder: (
    gateway?: string,
    gatewayMethod?: string,
    gatewayName?: string,
  ) => Promise<OrderDetailsResponseDto>;
  onPartiallyPaidOrder: (
    method: string,
    name?: string,
  ) => Promise<OrderDetailsResponseDto>;
  onPackOrder: (
    fulfillments?: OrderFulfillmentUpdateRequestDto[],
  ) => Promise<OrderDetailsResponseDto>;
  onFulfillOrder: (
    orderFulfillRequest: OrderFulfillRequestDto,
  ) => Promise<OrderDetailsResponseDto>;
  onEditDeliveryAddress: (deliveryAddress: DeliveryAddressValuesType) => void;
}

function OrderDetailsPage({
  orderDetails,
  onRemarkOrder,
  onPaidOrder,
  onPartiallyPaidOrder,
  onPackOrder,
  onFulfillOrder,
  onEditDeliveryAddress,
}: OrderDetailsProps): JSX.Element {
  const t = useTranslationOrders();
  const dispatch = useAppDispatch();
  const preferenceFulfillmentType = orderDetails.fulfillment.preference.type;
  const billingAddressCountry = orderDetails.billingAddress?.country;
  const { isDesktop } = useResponsive();
  const { onError, onPaidSuccess, onPackSuccess, onFulfillSuccess, onLoading } =
    useActionsToast(preferenceFulfillmentType);

  const { name } = useStoreInfo();

  const titleDocument = t('orderSummaryCard.title');
  useDocumentTitle(titleDocument);

  const onActionResult = useCallback(
    (callback: (args?: any) => void, args?: any) => {
      callback(args ?? undefined);
      dispatch(cleanUpdateOrderStatus());
    },
    [dispatch],
  );

  const [handlePaidOrder, isMarkingAsPaid] = useAsyncFunc(
    async (gateway?: string, gatewayMethod?: string, gatewayName?: string) =>
      await onPaidOrder(gateway, gatewayMethod, gatewayName),
    () => onActionResult(onPaidSuccess),
    () => onActionResult(onError),
  );

  const [handlePartialPayment, isMakingPartialPayment] = useAsyncFunc(
    async (method?: string, name?: string) => {
      if (method) {
        await onPartiallyPaidOrder(method, name);
      }
    },
    () => onActionResult(onPaidSuccess),
    () => onActionResult(onError),
  );

  const [handlePackOrder, isPacking] = useAsyncFunc(
    async (fulfillments?: OrderFulfillmentUpdateRequestDto[]) => {
      // Fix: avoids issues when fulfillments is undefined that arises from the use of
      // ...args destructuring inside of the useAsyncFunc callback. That creates an object
      // that is not undefined and breaks down the line validations.
      const fixedFulfillments = Array.isArray(fulfillments)
        ? fulfillments
        : undefined;
      await onPackOrder(fixedFulfillments);
    },
    () => onActionResult(onPackSuccess),
    () => onActionResult(onError),
  );

  const [handleFulfilledOrder, isFulfilling] = useAsyncFunc(
    async (fulfilled?: OrderFulfillRequestDto) => {
      if (fulfilled) {
        fulfilled.isEdit && onLoading(true);
        await onFulfillOrder(fulfilled);
        onActionResult(onFulfillSuccess, fulfilled?.isEdit);
      }
    },
    undefined,
    () => onActionResult(onError),
  );

  const handleRemark = async (value: string) => {
    await onRemarkOrder(value);
  };

  const fufillmentStatusLabel: InterfaceLabel = useMemo<InterfaceLabel>(() => {
    const fulfillmentLabel = fulfillment(
      orderDetails.fulfillment.status as StatusEnum,
      preferenceFulfillmentType,
    );

    return {
      ...fulfillmentLabel,
      id: fulfillmentLabel.status,
      label: `${t(
        `status.${getShippingOrderStatus(
          fulfillmentLabel.status,
          orderDetails.isShippablePickup,
        )}`,
      )}`,
    };
  }, [
    orderDetails.fulfillment.status,
    preferenceFulfillmentType,
    orderDetails.isShippablePickup,
    t,
  ]);

  const subject = t('customerDataCard.mailSubject', {
    numberOrder: orderDetails.number,
    storeName: name,
  });
  const body = t('customerDataCard.mailBody', {
    clientName: orderDetails.consumer.name,
  });
  const waText = t('customerDataCard.waText', {
    numberOrder: orderDetails.number,
    storeName: name,
    clientName: orderDetails.consumer.name,
    interpolation: { escapeValue: false },
  });

  const hasFulfillmentOrders: boolean =
    !!orderDetails.fulfillmentOrders &&
    orderDetails.fulfillmentOrders.length > 0;

  const totalProducts = orderDetails.products.reduce(
    (total, product) => total + product.quantity,
    0,
  );
  const cardOrderAndFulfillmentTitle = `${totalProducts} 
  ${t('orderSummaryCard.productInOrder', {
    count: totalProducts,
  })}`;

  const hideNotInformedEmail: boolean = orderDetails.origin === 'pos';

  const isPickup = useMemo(
    () =>
      orderDetails.fulfillment.preference.type === PICKUP_TYPE ||
      orderDetails.isShippablePickup,
    [orderDetails.fulfillment.preference.type, orderDetails.isShippablePickup],
  );

  const isDigital = useMemo(
    () =>
      orderDetails.fulfillment.preference.type ===
      FulfillmentPreferenceType.DELIVERY_DIGITAL,
    [orderDetails.fulfillment.preference.type],
  );

  const contact = useMemo(() => {
    const originalEmail = orderDetails.contact_email;
    const currentEmail = orderDetails.consumer.email;
    const hasEmailChanged = originalEmail !== currentEmail;
    return {
      name: orderDetails.contact_name ?? orderDetails.consumer.name,
      email: originalEmail ?? currentEmail,
      phone: orderDetails.consumer.phoneNumber ?? orderDetails.contact_phone,
      identification: orderDetails.contact_identification,
      emailChanged: hasEmailChanged,
    };
  }, [orderDetails]);
  return (
    <>
      <Layout
        mainContent
        right={
          <>
            {isDesktop && (
              <Title type="h2">{`${t('detail.subTitleAside')}`}</Title>
            )}
            <CustomerDataCard
              id={orderDetails.consumer.id}
              name={contact.name}
              hasEmailChanged={contact.emailChanged}
              email={{
                to: contact.email,
                subject,
                body,
              }}
              whatsapp={
                contact.phone
                  ? {
                      phoneNumber: contact.phone,
                      message: waText,
                    }
                  : undefined
              }
              identification={contact.identification}
              businessName={
                orderDetails.billingAddress?.businessName ?? undefined
              }
              tradeName={orderDetails.billingAddress?.tradeName ?? undefined}
              fiscalRegime={
                orderDetails.billingAddress?.fiscalRegime ?? undefined
              }
              invoiceUse={orderDetails.billingAddress?.invoiceUse ?? undefined}
              country={orderDetails.billingAddress?.country ?? undefined}
              stateRegistration={
                orderDetails.billingAddress?.stateRegistration ?? undefined
              }
              businessActivity={
                orderDetails.billingAddress?.businessActivity ?? undefined
              }
              hideNotInformedEmail={hideNotInformedEmail}
              isAnonymized={orderDetails.consumer.isAnonymized}
            />
            {isPickup ? (
              <CardPickupAddress order={orderDetails} />
            ) : (
              orderDetails.fulfillment.preference.deliveryAddress?.street && (
                <CardDeliveryAddress
                  order={orderDetails}
                  onEditDeliveryAddress={onEditDeliveryAddress}
                />
              )
            )}
            {!!orderDetails.billingAddress?.street &&
              (isDigital ||
                isPickup ||
                !orderDetails?.sameBillingAndShippingAddress) && (
                <CardBillingAddress order={orderDetails} />
              )}
            <TimelineSection order={orderDetails} />
            <CardTrackingPage
              trackingPageLink={orderDetails.trackingPageLink}
            />
          </>
        }
        left={
          <>
            <DetailsHeader
              title={t('detail.subTitleMain')}
              right={
                <DateAndDevice
                  date={orderDetails.date}
                  origin={orderDetails.origin}
                  orderOrigin={orderDetails.orderOrigin}
                  initiatedBy={orderDetails.initiatedBy}
                />
              }
            />
            <FeatureFlag
              flag={NEW_ADMIN_ORDER_DETAILS}
              renderElse={
                <OrderSummaryCard
                  billingAddressCountry={billingAddressCountry}
                  refundAttempts={orderDetails.payment.refundAttempts}
                  products={orderDetails.products}
                  amounts={orderDetails.amounts}
                  taxes={orderDetails.taxes}
                  currency={orderDetails.currency}
                  receiptId={orderDetails.id}
                />
              }
            >
              {hasFulfillmentOrders ? (
                <CardFulfillmentOrdersList
                  order={orderDetails}
                  packOrder={handlePackOrder}
                  fulfillOrder={handleFulfilledOrder}
                />
              ) : (
                <CardOrderAndFulfillment
                  headerLabel={fufillmentStatusLabel}
                  order={orderDetails}
                  packOrder={handlePackOrder}
                  fulfillOrder={handleFulfilledOrder}
                  isLoading={isPacking || isFulfilling}
                  products={orderDetails.products}
                  title={cardOrderAndFulfillmentTitle}
                />
              )}
            </FeatureFlag>

            <CardPayment
              order={orderDetails}
              paidOrder={handlePaidOrder}
              isMarkingAsPaid={isMarkingAsPaid}
              partiallyPaidOrder={handlePartialPayment}
              isMakingPartialPayment={isMakingPartialPayment}
            />

            <FeatureFlag
              flag={NEW_ADMIN_ORDER_DETAILS}
              renderElse={
                <CardFulfillment
                  order={orderDetails}
                  packOrder={handlePackOrder}
                  fulfillOrder={handleFulfilledOrder}
                  urlTrackingCode={orderDetails.urlTrackingCode}
                  isLoading={isPacking || isFulfilling}
                  headerLabel={fufillmentStatusLabel}
                />
              }
            />
            <SectionOrdersMetafields orderId={orderDetails.id} />
            {!!orderDetails.consumer?.remarks && (
              <Card title={t('cardCustomerRemark.title')}>
                <Text background>{orderDetails.consumer.remarks}</Text>
              </Card>
            )}
            {orderDetails.customerCustomfields && (
              <CardCustomerCustomization
                customerCustomfields={orderDetails.customerCustomfields}
              />
            )}
            <RemarksCard
              title={t('remarksCard.title')}
              errorMessage={t('remarksCard.error')}
              remarks={orderDetails.remarks}
              onRemark={handleRemark}
            />
          </>
        }
      />
      <Box paddingTop="4">
        <OrderHelpLink />
      </Box>
    </>
  );
}

export default OrderDetailsPage;
