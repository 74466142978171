import React from 'react';
import { Checkbox } from '@nimbus-ds/components';
import { Layout } from '@nimbus-ds/patterns';
import { UpdateOptionsType, useTranslationCommon } from 'commons/hooks';

export interface ExtraOptionsProps {
  disabled: boolean;
  options: UpdateOptionsType;
  showUpdateOptions: boolean;
  onChange: (prop: string, value: string | boolean) => void;
}

interface UpdateOptionsProps {
  disabled: boolean;
  options: UpdateOptionsType;
  onChange: (prop: string, value: string | boolean) => void;
  entitiesName: string;
  showUpdateOptions: boolean;
  extraOptions?: (props: ExtraOptionsProps) => React.ReactNode;
}

export function UpdateOptions({
  disabled,
  options,
  onChange,
  entitiesName,
  showUpdateOptions,
  extraOptions,
}: UpdateOptionsProps) {
  const { updateExisting } = options;
  const t = useTranslationCommon('importCsv.import');

  return (
    <Layout columns="1">
      {showUpdateOptions && (
        <Layout.Section>
          <Checkbox
            label={t('existing.checkText', {
              entities: entitiesName,
            })}
            name="importCardUpdateExistingCheckbox"
            checked={updateExisting}
            disabled={disabled}
            onChange={(e) => onChange('updateExisting', e.target.checked)}
          />
        </Layout.Section>
      )}
      {extraOptions && (
        <Layout.Section>
          {extraOptions?.({ disabled, options, onChange, showUpdateOptions })}
        </Layout.Section>
      )}
    </Layout>
  );
}
