import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { PathsType } from '../types';

const settingOptionByRole: Record<string, PathsType> = {
  full: 'storeInfo',
  payments_and_subscriptions: 'paymentsAndSubscriptions',
  invoice_history: 'invoiceHistory',
  payments: 'payments',
  shipping: 'shipping',
  locations: 'locations',
  emails: 'emails',
  preferences_checkout: 'checkoutOptions',
  preferences_advanced: 'externalCodes',
  domains: 'domains',
  i18n: 'i18n',
  seo_redirects: 'redirects301',
};

export function useGetDefaultSettingsOptionRevamp(): PathsType {
  const { validateRoles } = useRoles();

  const option = Object.entries(settingOptionByRole).find(([role]) =>
    validateRoles(role),
  );

  return option?.[1] ?? 'locations';
}
