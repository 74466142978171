import { Box, Title, Card, Text, Link } from '@nimbus-ds/components';
import { useIsInAppBrowser } from 'App/hooks';
import { useGoToPlans } from 'commons/hooks/useGoToPlans';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { useStoreStatusTitle } from 'domains/Billing/Checkout/pages/PlansAndSubscriptions/hooks';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

const FREE_PLAN_COST = 0;

function FreePlanDetail() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.plansAndApps',
  );
  const { handleGoToPlans: goToPlans } = useGoToPlans();
  const isMobileDevice = useIsMobileDevice();
  const title = useStoreStatusTitle();
  const isInAppBrowserOpen = useIsInAppBrowser();
  const isWebVersion = !isMobileDevice && !isInAppBrowserOpen;

  const formatCurrency = useFormatCurrency();

  return (
    <Card padding="base">
      <Card.Header>
        <Title as="h3">{title}</Title>
      </Card.Header>
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="0-5">
          <Box
            display="flex"
            flexDirection="row"
            gap="14"
            justifyContent="space-between"
          >
            <Text>{t('freePlanName')}</Text>
            <Text fontSize="highlight">{`${formatCurrency(
              FREE_PLAN_COST,
            )}`}</Text>
          </Box>
          {isWebVersion && (
            <Link
              textDecoration="none"
              appearance="primary"
              onClick={goToPlans}
            >
              {t('changePlan')}
            </Link>
          )}
        </Box>
      </Card.Body>
    </Card>
  );
}

export default FreePlanDetail;
