import { logEvent } from 'commons/utils/tracking';
import {
  APPSTORE_APP_CARD_CLICK,
  APPSTORE_APP_INSTALL_CLICK,
  APPSTORE_HOMEPAGE_CLICK,
  APPSTORE_CATEGORY_CLICK,
  APPSTORE_APP_UNINSTALL_CLICK,
} from 'config/trackingEvents';
import useStoreInfo from '../useStoreInfo';

export interface ITrackApp {
  name: string;
  handle?: string;
  category: string;
  position?: number;
}

const categoryParser = {
  accounting: 'management',
  chat: 'communication',
  shipping: 'shipping',
  payments: 'payments',
  tools: 'marketing',
  recommendations: 'security',
  reports: 'statistics',
  support: 'extra resources',
  sales_channels: 'sales channels',
  official: 'official',
  dropshipping: 'dropshipping',
  all: 'all',
};

const useAppStoreTracker = () => {
  const { id } = useStoreInfo();

  const clickToAppStoreHomePage = (source: string) => {
    logEvent(APPSTORE_HOMEPAGE_CLICK, {
      source_page: window?.location?.href ?? source,
      has_user_logged_in: 'true',
      user_id: id,
    });
  };

  const clickToAppStoreCategory = (source: string, category: string) => {
    logEvent(APPSTORE_CATEGORY_CLICK, {
      source_page: window?.location?.href ?? source,
      category_name: categoryParser[category] ?? category,
      has_user_logged_in: 'true',
      user_id: id,
    });
  };

  const clickToAppStoreAppInstall = (source: string, app: ITrackApp) => {
    logEvent(APPSTORE_APP_INSTALL_CLICK, {
      app_name: app.name,
      handle: app.handle ?? '',
      source_page: window?.location?.href ?? source,
      has_user_logged_in: 'true',
      user_id: id,
    });
  };

  const clickToAppStoreAppUninstall = (source: string, app: ITrackApp) => {
    logEvent(APPSTORE_APP_UNINSTALL_CLICK, {
      app_name: app.handle ?? app.name,
      source_page: window?.location?.href ?? source,
      has_user_logged_in: 'true',
      user_id: id,
    });
  };

  const clickToAppStoreAppDetails = (source: string, app: ITrackApp) => {
    logEvent(APPSTORE_APP_CARD_CLICK, {
      referral: document.referrer ?? 'new admin',
      source_page: window?.location?.href ?? source,
      has_user_logged_in: 'true',
      user_id: id,
      app_name: app.name,
      handle: app.handle ?? '',
      category_name: categoryParser[app.category] ?? app.category,
      block_name: source,
      position: app.position ? `${app.position}` : '0',
      partner_program_level: '',
    });
  };

  return {
    clickToAppStoreHomePage,
    clickToAppStoreAppDetails,
    clickToAppStoreAppInstall,
    clickToAppStoreAppUninstall,
    clickToAppStoreCategory,
  };
};

export default useAppStoreTracker;
