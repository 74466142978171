import {
  CheckoutPaymentMethod,
  GatewayPaymentMethodType,
} from '@tiendanube/common';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

const useTranslatePaymentMethod = () => {
  const t = useTranslationBilling('checkout.paymentMethods');

  const translateGatewayPaymentMethod = (method: GatewayPaymentMethodType) => {
    // When the payment method is "BANK_TRANSFER", there's no need to render its payment type.
    if (method === 'BANK_TRANSFER') return undefined;

    const translations = {
      CREDIT_CARD: t('gateway.creditCard'),
      DEBIT_CARD: t('gateway.debitCard'),
      BALANCE: t('gateway.balance'),
      TICKET: t('gateway.ticket'),
    };
    return translations[method] || method;
  };

  const translateCheckoutPaymentMethod = (
    method: CheckoutPaymentMethod,
    isForRecurringPayment = false,
  ) =>
    t(
      `checkout.${
        method === 'cc' && isForRecurringPayment ? 'ccWithRp' : method
      }`,
    );

  return {
    translateCheckoutPaymentMethod,
    translateGatewayPaymentMethod,
  };
};

export default useTranslatePaymentMethod;
