import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CheckoutPaymentsResponseDto,
  CheckoutSavePaymentOrdersDto,
} from '@tiendanube/common';
import checkoutPaymentsService from '../checkoutPaymentsService';

export const fetchCheckoutPaymentOptionsAction =
  createAsyncThunk<CheckoutPaymentsResponseDto>(
    'checkoutPayments/paymentOptions',
    async () => {
      const data = await checkoutPaymentsService.getCheckoutPaymentOptions();
      return data;
    },
  );

export const saveCheckoutPaymentOptionsAction = createAsyncThunk<
  void,
  CheckoutSavePaymentOrdersDto
>('checkoutPayments/savePaymentOptions', async (payload) => {
  await checkoutPaymentsService.saveCheckoutPaymentOptions(payload);
});
