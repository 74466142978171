import { DataTable } from '@tiendanube/components';
import { Repeater } from 'commons/components';
import Text from 'commons/components/LegacyText';
import { PagoNubeDataTableRow } from './components';

const { Cell, Header } = DataTable;

function PagoNubeInvoiceHistoryDataTable(): JSX.Element {
  return (
    <>
      <Header>
        <Cell grow={4}>
          <Text.Skeleton />
        </Cell>
        <Cell grow={1}>
          <Text.Skeleton />
        </Cell>
      </Header>
      <Repeater times={9} item={PagoNubeDataTableRow.Skeleton} />
    </>
  );
}

export default PagoNubeInvoiceHistoryDataTable;
