import { useMemo } from 'react';
import { Alert, Box, Skeleton, Text } from '@nimbus-ds/components';
import { Layout } from '@nimbus-ds/patterns';
import { CancelAndConfirmButtons } from 'commons/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

export function OrderEditPageSkeleton() {
  const t = useTranslationOrders();

  const alertSubtitle = useMemo(() => t('editOrders.notice.subtitleApps'), [t]);

  return (
    <Box>
      <Alert appearance="warning">
        <Text color="warning-textLow">{alertSubtitle}</Text>
      </Alert>
      <Box paddingTop="4" width="100%">
        <Layout columns="2 - asymmetric">
          <Layout.Section>
            <Skeleton height="275px" width="100%" borderRadius="10px" />
            <Box paddingY="2">
              <Skeleton height="80px" width="100%" borderRadius="10px" />
            </Box>
          </Layout.Section>
          <Layout.Section>
            <Box display="flex" flexDirection="column" width="100%" gap="2">
              <Skeleton height="250px" width="100%" borderRadius="10px" />
              <CancelAndConfirmButtons.Skeleton />
            </Box>
          </Layout.Section>
        </Layout>
      </Box>
    </Box>
  );
}
