import { DataTable } from '@tiendanube/components';
import { InvoiceHistoryDataTable } from './components';

function InvoiceHistoryWeb(): JSX.Element {
  return (
    <div className="invoice-history-web">
      <DataTable>
        <InvoiceHistoryDataTable.Skeleton />
      </DataTable>
    </div>
  );
}
export default InvoiceHistoryWeb;
