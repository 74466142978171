import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CustomersTablePricesDto } from '@tiendanube/common';
import customerTablePricesService from 'domains/Customers/CustomerTablePrices/customerTablePricesService/customerTablePricesService';
import { statusType, CustomerTablePricesInterface } from './types';

const initialState: CustomerTablePricesInterface = {
  tablePrices: {
    data: [],
    status: statusType.idle,
  },
};

export const getCustomerTablePrices = createAsyncThunk<
  CustomersTablePricesDto,
  string
>(
  'customerTablePrices/getCustomerTablePrices',
  customerTablePricesService.getCustomerTablePrices,
);

const customerTablePrices = createSlice({
  name: 'customerTablePrices',
  initialState,
  reducers: {
    clearCustomerTablePrices(state) {
      state.tablePrices.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerTablePrices.pending, (state) => {
        state.tablePrices.status = statusType.loading;
      })
      .addCase(getCustomerTablePrices.fulfilled, (state, action) => {
        state.tablePrices.data = action.payload.tablePricesIds;
        state.tablePrices.status = statusType.success;
      })
      .addCase(getCustomerTablePrices.rejected, (state) => {
        state.tablePrices.status = statusType.error;
      });
  },
});

export const { reducer } = customerTablePrices;
export const { clearCustomerTablePrices } = customerTablePrices.actions;
