import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router';
import { ChargeDto, ConceptCode } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { useAppDispatch } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import {
  getChargesToPaySelected,
  setChargesToPaySelected as setChargesToPaySelectedAction,
} from 'domains/Billing/Checkout/CheckoutSlice';
import {
  useChargesToPay,
  usePayorderToPay,
  GO_TO_CHECKOUT_AFTER_PAYMENT,
  useAfterPaymentRedirection,
} from 'domains/Billing/Checkout/hooks';

const APP_CONCEPTS: ConceptCode[] = ['app-cost', 'app-extra-fee'];

function getChargesOfSameApp(charge: ChargeDto, charges: ChargeDto[]) {
  if (!APP_CONCEPTS.includes(charge.conceptCode)) return [];
  return charges.filter(
    ({ conceptCode, id, metadata, status }) =>
      id !== charge.id &&
      status !== 'IN_PROCESS' &&
      APP_CONCEPTS.includes(conceptCode) &&
      metadata?.appId === charge.metadata?.appId,
  );
}

export default function useSelectCharges(init = false) {
  const { goTo } = useNavegate();

  const {
    chargesToSelect,
    chargeTotalWithoutTaxes,
    status: chargesStatus,
    refreshChargesToPay,
  } = useChargesToPay();

  const dispatch = useAppDispatch();
  const setSelectedCharges = useCallback(
    (charges: ChargeDto[]) => dispatch(setChargesToPaySelectedAction(charges)),
    [dispatch],
  );
  const selectedCharges = useSelector(getChargesToPaySelected);

  useEffect(() => {
    init &&
      chargesToSelect &&
      setSelectedCharges(
        chargesToSelect.filter((charge) => charge.status !== 'IN_PROCESS'),
      );
  }, [chargesToSelect, setSelectedCharges, init]);

  const totalOfSelectedCharges = useMemo(
    () =>
      selectedCharges.reduce(
        (acc, charge) => acc + chargeTotalWithoutTaxes(charge),
        0,
      ),
    [chargeTotalWithoutTaxes, selectedCharges],
  );
  const { createPayorder, payOrderCreationStatus } = usePayorderToPay();

  const { addAfterPayActionToPath } = useAfterPaymentRedirection();

  const onPay = useCallback(() => {
    createPayorder(selectedCharges.map(({ id }) => id))
      .unwrap()
      .then((payOrder) => {
        if (payOrder)
          goTo(
            addAfterPayActionToPath(
              generatePath(CHECKOUT_ROUTES.choosePaymentMethod, {
                payOrderId: payOrder.id,
              }),
              GO_TO_CHECKOUT_AFTER_PAYMENT,
            ),
          );
      });
  }, [createPayorder, selectedCharges, goTo, addAfterPayActionToPath]);

  const planIsListed = useMemo(
    () =>
      chargesToSelect?.some(({ conceptCode }) => conceptCode === 'plan-cost'),
    [chargesToSelect],
  );

  const selectCharge = useCallback(
    (checked: boolean, charge: ChargeDto) => {
      if (!chargesToSelect) return;
      if (checked) {
        setSelectedCharges([
          ...selectedCharges,
          charge,
          ...getChargesOfSameApp(charge, chargesToSelect),
        ]);
      } else {
        setSelectedCharges(
          selectedCharges.filter(
            (ch) =>
              ch.id !== charge.id &&
              !getChargesOfSameApp(charge, selectedCharges).includes(ch),
          ),
        );
      }
    },
    [setSelectedCharges, chargesToSelect, selectedCharges],
  );

  return {
    chargesStatus,
    refreshChargesToPay,
    totalOfSelectedCharges,
    setSelectedCharges,
    chargesToSelect,
    onPay,
    payOrderCreationStatus,
    isCreatingPayOrder: payOrderCreationStatus === 'loading',
    selectedCharges,
    planIsListed,
    selectCharge,
    ...convertStatusTypeToObject(chargesStatus),
  };
}
