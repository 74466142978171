import { ChangeEvent } from 'react';
import { Card, Radio } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { Stack, InputNumberNimbus } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import Skeleton from './Skeleton';

interface CustomShippingCostCardProps {
  deliveryCost?: string;
  cost?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function CustomShippingCostCard({
  deliveryCost,
  cost = '',
  onChange,
}: Readonly<CustomShippingCostCardProps>): JSX.Element {
  const t = useTranslationShipping(
    'deliveryMethods.customShipping.informationCard',
  );

  const options = [
    {
      label: t('withdrawal.optionWithCost'),
      value: 'with-cost',
      checked: deliveryCost === 'with-cost' || !deliveryCost,
    },
    {
      label: t('withdrawal.optionFree'),
      value: 'free',
      checked: deliveryCost === 'free',
    },
    {
      label: t('withdrawal.optionToCombine'),
      value: 'to-be-arranged',
      checked: deliveryCost === 'to-be-arranged',
    },
  ];

  return (
    <Card>
      <Card.Header title={t('withdrawal.label')} />
      <Card.Body>
        <Stack column align="stretch">
          <Stack.Item>
            <FormField>
              <Stack spacing="tight">
                {options.map((option) => (
                  <Radio
                    key={option.value}
                    id={option.value}
                    label={option.label}
                    name="deliveryCost"
                    value={option.value}
                    onChange={onChange}
                    as="button"
                    checked={option.checked}
                  />
                ))}
              </Stack>
            </FormField>
          </Stack.Item>
          {deliveryCost === 'with-cost' && (
            <Stack.Item>
              <FormField label={t('defineValue.label')}>
                <InputNumberNimbus
                  type="float"
                  name="cost"
                  value={cost}
                  onChange={onChange}
                  append={t('defineValue.currency')}
                />
              </FormField>
            </Stack.Item>
          )}
        </Stack>
      </Card.Body>
    </Card>
  );
}

CustomShippingCostCard.Skeleton = Skeleton;

export default CustomShippingCostCard;
