import { useEffect } from 'react';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { FEATURE_USER_PERMISSIONS } from 'App/features';
import { useNavegate } from 'App/hooks';
import { toStatusType } from 'App/rtk';
import { useForm, useToastStatus } from 'commons/hooks';
import { trackingEmailNotificationConfiguration } from 'domains/Account/tracking';
import { useStatusFeatureByKey } from 'domains/Auth/hooks';
import {
  useCreateUserMutation,
  useGetEmailTypesQuery,
  useGetRolesQuery,
} from 'domains/Configurations/Users/usersApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { validationSchema } from './validationSchema';
import { NEW_USER_INITIAL_VALUES } from '../../constants';
import UserForm from '../UserForm';

function NewUserForm() {
  const t = useTranslationConfigurations('userFormPage');
  const { goBack } = useNavegate();
  const [createUser, { status, error: requestError }] = useCreateUserMutation();
  const { isAvailable: isRolesEditAvailable } = useStatusFeatureByKey(
    FEATURE_USER_PERMISSIONS,
  );
  const { scrollToTop } = useLayoutScroll();

  const {
    data: storeRoles,
    isLoading: isRolesLoading,
    isError: isRolesError,
    refetch: refetchRoles,
  } = useGetRolesQuery();

  const {
    data: response,
    isLoading: isEmailNotificationLoading,
    isError: isEmailNotificationError,
    refetch: refetchEmailTypes,
  } = useGetEmailTypesQuery();

  const refetch = () => {
    refetchEmailTypes();
    refetchRoles();
  };

  const {
    values,
    errors,
    isDirty,
    setFieldValue,
    handleChange,
    handleValidations,
    handleOnSubmit,
  } = useForm({
    initialValues:
      NEW_USER_INITIAL_VALUES[
        isRolesEditAvailable ? 'editAvailable' : 'editUnavailable'
      ],
    validationSchema,
    onSubmit: createUser,
  });

  const createStatus = toStatusType(status);
  const requestErrors =
    (requestError as any)?.data?.response?.description || {};

  const handleEventsAndOnSubmit = () => {
    if (values.emailTypes.length > 0) {
      const newEmailTypes = values.emailTypes;
      newEmailTypes.map((emailType) => {
        trackingEmailNotificationConfiguration(true, emailType);
      });
    }
    handleOnSubmit();
  };

  useToastStatus({
    status: createStatus,
    success: t('toast.success'),
    error: requestErrors?.description?.email
      ? t(`errors.emailAlreadyTaken`)
      : t('toast.editError'),
    onSuccess: goBack,
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToTop();
    }
  }, [errors, scrollToTop]);

  return (
    <UserForm
      isLoading={isRolesLoading}
      isError={isRolesError}
      isDirty={isDirty}
      values={values}
      errors={errors}
      requestErrors={requestErrors}
      submitStatus={createStatus}
      storeRoles={storeRoles}
      emailTypes={response}
      onChange={handleChange}
      onChangeRole={setFieldValue}
      onChangeEmailType={setFieldValue}
      onRefetch={refetch}
      onSubmit={handleEventsAndOnSubmit}
      onValidate={handleValidations}
      isEmailNotificationError={isEmailNotificationError}
      isEmailNotificationLoading={isEmailNotificationLoading}
    />
  );
}

export default NewUserForm;
