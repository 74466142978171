import { Card, Text, Title } from '@nimbus-ds/components';
import { OrderDetailsResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import useTranslationOrders from '../../../../../useTranslationOrders';

interface Attributes {
  orderDetails: OrderDetailsResponseDto;
}

function RefundDetailsCard({ orderDetails }: Attributes): JSX.Element {
  const t = useTranslationOrders();
  const prefix = 'refund.confirmRefundDetails.details';
  const installmentsCount = orderDetails.payment.installments;

  return (
    <Card>
      <Card.Header>
        <Title as="h3">{t(`${prefix}.title`)}</Title>
      </Card.Header>
      <Card.Body>
        <Stack column align="stretch">
          <Text fontWeight="bold">{orderDetails.payment.gateway}</Text>
          <Text fontWeight="regular">{orderDetails.consumer.name}</Text>
          {!!orderDetails.contact_identification && (
            <Text fontWeight="regular">{`${t(`${prefix}.identification`)}: ${
              orderDetails.contact_identification
            }`}</Text>
          )}
          <Text fontWeight="regular">{orderDetails.payment.method}</Text>
          {!!installmentsCount && (
            <Text fontWeight="regular">
              {t(`${prefix}.installments`, {
                count: Number(installmentsCount),
              })}
            </Text>
          )}
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default RefundDetailsCard;
