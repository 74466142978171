import { useEffect, useState } from 'react';
import { TablePricesDto } from '@tiendanube/common';
import { Card } from '@tiendanube/components';
import PriceTablesAddState from 'domains/Customers/Customers/pages/CustomerFormPage/components/CustomerPriceTablesCard/components/PriceTablesAddState';
import PriceTablesEditState from 'domains/Customers/Customers/pages/CustomerFormPage/components/CustomerPriceTablesCard/components/PriceTablesEditState';
import PriceTablesErrorState from 'domains/Customers/Customers/pages/CustomerFormPage/components/CustomerPriceTablesCard/components/PriceTablesErrorState';
import PriceTablesLoadingState from 'domains/Customers/Customers/pages/CustomerFormPage/components/CustomerPriceTablesCard/components/PriceTablesLoadingState';
import PriceTablesModal from 'domains/Customers/Customers/pages/CustomerFormPage/components/CustomerPriceTablesCard/components/PriceTablesModal';
import useCustomerTablePrices from 'domains/Customers/CustomerTablePrices/hooks/useCustomerTablePrices';
import { useTranslationCustomers } from 'domains/Customers/hooks';
import useTablePrices from 'domains/Customers/TablePrices/hooks/useTablePrices';

export type PriceTableInCard = Pick<
  TablePricesDto,
  'id' | 'name' | 'defaultValue'
>;

export interface CustomerPriceTablesCardProps {
  customerId?: string;
  formStateTablePrices: string[];
  onChangeTablePrices: (tablePricesIds: string[]) => void;
}

export default function CustomerPriceTablesCard({
  customerId,
  formStateTablePrices,
  onChangeTablePrices,
}: Readonly<CustomerPriceTablesCardProps>) {
  const t = useTranslationCustomers();
  const { tablePricesStatus, fetchTablePrices, tablePricesData } =
    useTablePrices();
  const {
    customerTablePricesStatus,
    customerTablePricesData,
    fetchCustomerTablePrices,
    resetCustomerTablePrices,
  } = useCustomerTablePrices(customerId);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPriceTables, setSelectedPriceTables] = useState<
    PriceTableInCard[]
  >([]);

  useEffect(() => {
    const selectedData: PriceTableInCard[] = [];
    for (const id of formStateTablePrices) {
      const data = tablePricesData.find((priceTable) => priceTable.id === id);
      if (data) {
        selectedData.push({
          id,
          name: data.name,
          defaultValue: data.defaultValue,
        });
      }
    }

    setSelectedPriceTables(selectedData);
  }, [formStateTablePrices, tablePricesData]);

  useEffect(() => {
    fetchTablePrices();
    fetchCustomerTablePrices();

    return () => resetCustomerTablePrices();
  }, [fetchTablePrices, fetchCustomerTablePrices, resetCustomerTablePrices]);

  useEffect(() => {
    if (customerTablePricesStatus.isSuccess && customerTablePricesData.length) {
      onChangeTablePrices([...customerTablePricesData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerTablePricesStatus.isSuccess, customerTablePricesData]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const addPriceTable = (priceTable: PriceTableInCard) => {
    onChangeTablePrices([...formStateTablePrices, priceTable.id]);
  };

  const removePriceTable = (id: string) => {
    const filteredPriceTables = formStateTablePrices.filter(
      (priceTable) => priceTable !== id,
    );
    onChangeTablePrices(filteredPriceTables);
  };

  if (tablePricesStatus.isLoading || customerTablePricesStatus.isLoading) {
    return <PriceTablesLoadingState />;
  }

  if (tablePricesStatus.isError) {
    return <PriceTablesErrorState onRetry={fetchTablePrices} />;
  }

  if (customerTablePricesStatus.isError) {
    return <PriceTablesErrorState onRetry={fetchCustomerTablePrices} />;
  }

  return (
    <Card title={t('customerForm.priceTablesInformation.title')}>
      {selectedPriceTables.length === 0 ? (
        <PriceTablesAddState openModal={openModal} />
      ) : (
        <PriceTablesEditState
          openModal={openModal}
          priceTables={selectedPriceTables}
          removePriceTable={removePriceTable}
        />
      )}

      <PriceTablesModal
        isOpen={isModalOpen}
        close={closeModal}
        priceTables={tablePricesData}
        selectedPriceTables={selectedPriceTables}
        addPriceTable={addPriceTable}
        removePriceTable={removePriceTable}
      />
    </Card>
  );
}
