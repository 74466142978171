export const initialInfo = {
  name: {},
  description: {},
  useStoreInfoForSeo: false,
  ownerPhoneType: '',
  ownerPhoneCountry: '',
  ownerPhoneArea: '',
  phoneNumber: '',
  timeZone: '',
  brickAndMortars: '',
  businessSize: '',
  type: '',
  orderTaxesEnabled: false,
  orderTaxesPercent: 0,
};

export const initialOptions = {
  timeZoneOptions: [],
  brickAndMortarsOptions: [],
  businessSizeOptions: [],
  typeOptions: [],
  ownerPhoneTypeOptions: [],
};
