import { OrderDetailsResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import CancelAndSaveButtons from 'commons/components/CancelAndSaveButtons';
import { InterfaceNameBooleanValue, InterfaceNameValue } from 'commons/types';
import { RefundAmountSelectorCard, RefundRestoreStockCard } from '../';
import useTranslationOrders from '../../../../../useTranslationOrders';
import { RefundAttributes } from '../../../hooks/useRefundOrder/useRefundOrder';
import { UseRefundStepsResult } from '../../hooks/useSteps';

interface Attributes {
  orderDetails: OrderDetailsResponseDto;
  values: RefundAttributes;
  onChange: (data: InterfaceNameValue | InterfaceNameBooleanValue) => void;
  useSteps: UseRefundStepsResult;
  disableButton: () => boolean;
}

function SetUpRefundDetailsPage({
  orderDetails,
  values,
  onChange,
  useSteps,
  disableButton,
}: Attributes): JSX.Element {
  const t = useTranslationOrders();

  return (
    <Stack column align="stretch">
      <RefundAmountSelectorCard
        order={orderDetails}
        values={values}
        onChange={onChange}
      />
      <RefundRestoreStockCard values={values} onChange={onChange} />
      <Stack justify="flex-end">
        <CancelAndSaveButtons
          saveText={t('refund.setUpRefundDetails.buttons.save')}
          cancelText={t('refund.setUpRefundDetails.buttons.cancel')}
          onSave={useSteps.nextStep}
          onCancel={useSteps.backStep}
          isDisabled={disableButton()}
        />
      </Stack>
    </Stack>
  );
}

export default SetUpRefundDetailsPage;
