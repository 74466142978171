import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ImportColumnsResponseDto } from '@tiendanube/common';
import {
  InterfaceNameValue,
  InterfaceSelectOption,
} from '@tiendanube/components';
import { useToast, useTranslationCommon } from 'commons/hooks';
import readHeaderTextFromCsvFile from 'commons/utils/readHeaderTextFromCsvFile';
import { importMaxLines } from 'domains/Catalog/Products/productsSlice/productSelectors';
import {
  isExcelTypeWithFirefox,
  isCsvFileType,
  processFile,
  getNumberOfLinesFromFile,
} from './utils';
import { useImportColumnOptions } from '../useImportColumnOptions';

const MAX_SIZE_FILE = 20 * 1024 * 1024; // 20MB

interface useFileImportResult {
  onChangeFile: (file: File) => void;
  onRemoveFile: () => void;
  onRetry: () => void;
  fileName: string;
  badColumns: string[];
  fileColumns: string[];
  matchColumns: Record<string, string>;
  setMatchingColumns: ({ name, value }: InterfaceNameValue) => void;
  ignoreAllColumns: () => void;
  undoIgnoreAllColumns: () => void;
  file?: File;
  status: CsvStatus;
  columnOptions: InterfaceSelectOption[];
  isLoading: boolean;
  isError: boolean;
}

export type CsvStatus =
  | 'Pending'
  | 'Loading'
  | 'FormatError'
  | 'SizeError'
  | 'LinesExceededError'
  | 'ReadingError'
  | 'Complete';

type ImportHandler = () => Promise<ImportColumnsResponseDto>;

export function useFileImport(
  importHandler: ImportHandler,
  trackingIgnoreAllColumns?: () => void,
): useFileImportResult {
  const t = useTranslationCommon();
  const { addToast } = useToast();
  const [file, setFile] = useState<File>();
  const [status, setStatus] = useState<CsvStatus>('Pending');
  const [fileName, setFileName] = useState<string>('');
  const [badColumns, setBadColumns] = useState<string[]>([]);
  const [fileColumns, setFileColumns] = useState<string[]>([]);
  const [matchColumns, setMatchColumns] = useState<Record<string, string>>({});
  const [matchColumnsBackup, setMatchColumnsBackup] = useState<
    Record<string, string>
  >({});
  const [columnOptionsBackup, setColumnOptionsBackup] = useState<
    InterfaceSelectOption[]
  >([]);
  const {
    getColumnOptions,
    columnOptions,
    isLoading,
    isError,
    clearError,
    setColumnOptions,
  } = useImportColumnOptions(importHandler);
  const maxLines = useSelector(importMaxLines);

  const onChangeFile = async (file: File) => {
    setFileName(file.name);
    setStatus('Loading');

    if (file.size > MAX_SIZE_FILE) {
      setStatus('SizeError');
      return;
    }

    if (!(isCsvFileType(file.type) || isExcelTypeWithFirefox(file))) {
      setStatus('FormatError');
      return;
    }

    try {
      const lines = await getNumberOfLinesFromFile(file, maxLines);
      if (lines > maxLines) {
        setStatus('LinesExceededError');
        return;
      }
    } catch (error) {
      setStatus('ReadingError');
      return;
    }

    const fileCols = await readHeaderTextFromCsvFile(file);
    const colsOptions = (await getColumnOptions(fileCols)) || [];
    const { badCols, matchCols } = await processFile(fileCols, colsOptions);

    setFile(file);
    setFileColumns(fileCols);
    setMatchColumns(matchCols);
    setBadColumns(badCols);
    setStatus('Complete');

    if (colsOptions.length) {
      addToast({
        label: t('importCsv.import.uploadFile.success'),
        appearance: 'success',
      });
    }
  };

  const onRetry = () => {
    if (file) {
      clearError();
      onChangeFile(file);
    }
  };

  const onRemoveFile = () => {
    setFile(undefined);
    setFileName('');
    setStatus('Pending');
  };

  const setMatchingColumns = ({ name, value }) => {
    const matchCols = { ...matchColumns, ...{ [name]: value } };
    const updateOptions = columnOptions.map((option) => ({
      label: option.label,
      value: option.value,
      disabled:
        option.value !== 'ignore' &&
        (option.value === value ||
          Object.values(matchCols).includes(option.value) ||
          fileColumns.includes(value)),
    })) as InterfaceSelectOption[];
    setMatchColumns(matchCols);
    setColumnOptions(updateOptions);
  };

  const ignoreAllColumns = () => {
    trackingIgnoreAllColumns?.();
    const matchCols = Object.keys(matchColumns).reduce(
      (columns, key) => ({ ...columns, [key]: 'ignore' }),
      {},
    );
    const updateOptions = columnOptions.map((option) => ({
      label: option.label,
      value: option.value,
      disabled: false,
    }));
    setMatchColumnsBackup(matchColumns);
    setColumnOptionsBackup(columnOptions);
    setMatchColumns(matchCols);
    setColumnOptions(updateOptions);
  };

  const undoIgnoreAllColumns = () => {
    setMatchColumns(matchColumnsBackup);
    setColumnOptions(columnOptionsBackup);
  };

  return {
    onChangeFile,
    onRemoveFile,
    onRetry,
    setMatchingColumns,
    ignoreAllColumns,
    undoIgnoreAllColumns,
    fileName,
    badColumns,
    fileColumns,
    matchColumns,
    file,
    status,
    columnOptions,
    isLoading,
    isError,
  };
}
