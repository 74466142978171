import { useEffect } from 'react';
import useGetCustomerMetafields from 'domains/Metafields/hooks/useGetCustomerMetafields';

export default function useFetchCustomerMetafields() {
  const { fetchMetafields, isLoading, isSuccess, isError } =
    useGetCustomerMetafields();

  useEffect(() => {
    fetchMetafields();
  }, [fetchMetafields]);

  return {
    isLoading,
    isSuccess,
    isError,
  };
}
