import { EditIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import { CardWithTitle, HeaderContent, HeaderTop } from 'commons/components';
import IonPageSratus from 'commons/components/IonPageStratus/IonPageStratus';
import Link from 'commons/components/LegacyLink';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { DELIVERY_METHODS_ROUTES } from '../../shippingRoutes';

function EditSelectorOcaPage() {
  const t = useTranslationShipping();
  const { goBack, goTo } = useNavegate();
  const title = t('oca.config.title');
  const backNavigation = {
    onClick: goBack,
  };

  const goToOptionals = () => goTo(DELIVERY_METHODS_ROUTES.ocaEditOptionals);
  const goToOca = () => goTo(DELIVERY_METHODS_ROUTES.oca);

  return (
    <IonPageSratus
      headerTop={<HeaderTop navigation={backNavigation} />}
      headerContent={<HeaderContent title={title} />}
      width="small"
    >
      <CardWithTitle title={t('oca.editSelector.optionals.title')}>
        <Stack column align="stretch">
          <Text>{t('oca.editSelector.optionals.body')}</Text>
          <Link appearance="primary" icon={EditIcon} onClick={goToOptionals}>
            {t('oca.editSelector.optionals.edit')}
          </Link>
        </Stack>
      </CardWithTitle>
      <CardWithTitle title={t('oca.editSelector.account.title')}>
        <Stack column align="stretch">
          <Text>{t('oca.editSelector.account.body')}</Text>
          <Link appearance="primary" icon={EditIcon} onClick={goToOca}>
            {t('oca.editSelector.account.edit')}
          </Link>
        </Stack>
      </CardWithTitle>
    </IonPageSratus>
  );
}

export default EditSelectorOcaPage;
