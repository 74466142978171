import { Box, Card, Spinner, Text, Title } from '@nimbus-ds/components';
import {
  GetDocumentSettingsResponseDto,
  InformationForContentDeclarationResponseDto,
  InformationForDocumentResponseDto,
  OcaLabelResponseDto,
} from '@tiendanube/common';
import { Stack, useResponsive } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import BoxContentDeclarationPreview from './components/BoxContentDeclarationPreview';
import BoxLabelDocumentPreview from './components/BoxLabelDocumentPreview';
import BoxOcaLabelDocumentPreview from './components/BoxOcaLabelDocumentPreview';
import BoxReceiptDocumentPreview from './components/BoxReceiptDocumentPreview';
import { DocumentType } from '../../constants';
import './PrintDocumentPreview.scss';

interface PrintDocumentPreviewProps {
  type: DocumentType;
  hasOca: boolean;
  information?:
    | InformationForContentDeclarationResponseDto[]
    | InformationForDocumentResponseDto[];
  ocaLabels: OcaLabelResponseDto[];
  isLoading: boolean;
  progressOca: string | null;
  settings: GetDocumentSettingsResponseDto;
  locationId: string;
}

function PrintDocumentPreview({
  type,
  hasOca,
  information,
  isLoading,
  settings,
  locationId,
  ocaLabels,
  progressOca,
}: Readonly<PrintDocumentPreviewProps>) {
  const t = useTranslationFulfillmentOrders('printManager');
  const { isMobile } = useResponsive();

  const width = isMobile ? '100%' : '45vw';
  const alignItems = isLoading ? 'center' : 'flex-start';
  const ocaInformation = information
    ? ((information as InformationForDocumentResponseDto[])
        .filter((item) =>
          ocaLabels.find((ocaLabel) => ocaLabel.externalId === item.order.id),
        )
        .map((item) => ({
          ...item,
          ocaLabel: ocaLabels.find(
            (ocaLabel) => ocaLabel.externalId === item.order.id,
          ),
        })) as (InformationForDocumentResponseDto & {
        ocaLabel: OcaLabelResponseDto;
      })[])
    : [];

  const labelsInformation = information
    ? (information as InformationForDocumentResponseDto[]).filter(
        (item) =>
          !ocaLabels.find((ocaLabel) => ocaLabel.externalId === item.order.id),
      )
    : [];

  return (
    <Card>
      <Card.Header>
        <Title as="h3">{t('viewTitle')}</Title>
      </Card.Header>
      <Card.Body>
        <Box
          id="document-preview"
          backgroundColor="neutral-surfaceDisabled"
          borderColor="neutral-surfaceDisabled"
          borderRadius="2"
          width={width}
          minHeight="55vh"
          maxHeight="65vh"
          overflowX="auto"
          display="flex"
          alignItems={alignItems}
          justifyContent="center"
          flexWrap="wrap"
        >
          {isLoading && !hasOca && <Spinner size="large" />}
          {isLoading && hasOca && (
            <Stack column justify="center">
              <Spinner size="large" />
              {!!progressOca && (
                <Text>
                  {t('oca.progress')} {progressOca}
                </Text>
              )}
            </Stack>
          )}

          {!!ocaInformation && !isMobile && (
            <BoxOcaLabelDocumentPreview
              ocaInformation={ocaInformation}
              isLoading={isLoading}
              settings={settings}
              locationId={locationId}
            />
          )}

          {type === DocumentType.LABEL && !!labelsInformation && (
            <BoxLabelDocumentPreview
              information={labelsInformation}
              isLoading={isLoading}
              settings={settings}
              locationId={locationId}
            />
          )}

          {type === DocumentType.RECEIPT && !!information && (
            <BoxReceiptDocumentPreview
              information={information}
              isLoading={isLoading}
              settings={settings}
              locationId={locationId}
            />
          )}

          {type === DocumentType.CONTENT_DECLARATION && !!information && (
            <BoxContentDeclarationPreview
              information={
                information as InformationForContentDeclarationResponseDto[]
              }
              isLoading={isLoading}
              locationId={locationId}
            />
          )}
        </Box>
      </Card.Body>
    </Card>
  );
}

export default PrintDocumentPreview;
