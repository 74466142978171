import { useEffect } from 'react';
import { Box, IconButton, Tag } from '@nimbus-ds/components';
import {
  CogIcon,
  ExternalLinkIcon,
  IconProps,
  UniversityIcon,
} from '@nimbus-ds/icons';
import { Menu, MenuButton } from '@nimbus-ds/patterns';
import tokens from '@nimbus-ds/tokens/dist/js/tokens';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link as LinkRoute } from 'react-router-dom';
import { Country } from '@tiendanube/common/src/enums';
import Logo from 'App/components/Logo';
import ShowByCountry from 'App/components/ShowByCountry';
import ShowByRole from 'App/components/ShowByRole';
import { Avatar } from 'App/components/Topbar/components';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { useHandleMenuClick, useNavegate } from 'App/hooks';
import { useDevMode } from 'App/settings/devMode/hooks';
import { useMenu } from 'App/settings/menu/hooks';
import {
  trackingMenuConfigurationClick,
  trackingMenuStoreVisit,
} from 'App/tracking';
import { useResponsive } from 'commons/components';
import goToAdmin from 'commons/utils/gotToAdmin';
import { openWindow } from 'commons/utils/window';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import {
  useGetCountry,
  useGetIsEvolucion,
  useGetIsNext,
  useGetTags,
  useHeaderMenuData,
  useIsPlanLojinha,
} from 'domains/Auth/hooks';
import { useGetHasAccessToCustomers } from 'domains/Customers/hooks';
import { DashboardMenu } from 'domains/Dashboard';
import useOnboardingTasks from 'domains/Dashboard/pages/DashboardPage/components/OnboardingTasks/useOnboardingTasks';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import { useNuvemApps } from 'domains/PartnersApps/pages/NuvemAppPage/hooks';
import { useHasAccessToStats } from 'domains/Statistics';
import {
  AppsMenu,
  CustomersMenu,
  CustomizeMenu,
  ExternalAppsEnhanceMenu,
  KeyboardMenu,
  MainMenuDesktopHeader,
  MainMenuMobileHeader,
  MarketingAutomationMenu,
  MarketingMenu,
  MarketplacesMenu,
  MenuWithSpotlight,
  NuvemEnvioMenu,
  NuvemPagoMenu,
  OrdersMenu,
  PosMenu,
  ProductsMenu,
  SalesChannelsMenu,
  StatsMenu,
} from './components';
import NuvemChatMenu from './components/NuvemChatMenu';
import {
  useGetDefaultSettingsOption,
  useGetDefaultSettingsOptionRevamp,
} from './hooks';
import { MENU_PATHS } from './types';
import { useMenuScrollShadow } from '../../hooks';
import './MainMenu.scss';

interface MainMenuProps {
  device: 'mobile' | 'desktop';
  onClickSupport: () => void;
}

const rolesForSettings = [
  'full',
  'shipping',
  'locations',
  'payments',
  'emails',
  'i18n',
  'preferences_checkout',
  'preferences_advanced',
  'seo_redirects',
  'domains',
];

export const rolesForSettingsRevamp = [
  ...rolesForSettings,
  'payments_and_subscriptions',
  'invoice_history',
];

// eslint-disable-next-line max-statements
function MainMenu({ device, onClickSupport }: Readonly<MainMenuProps>) {
  const {
    t,
    i18n: { language },
  } = useTranslation('common');
  const { handleClickAccordion } = useHandleMenuClick();
  const { fetchNuvemApps } = useNuvemApps();
  const { validateRoles } = useRoles();
  const { goTo } = useNavegate();
  const isMobile = device === 'mobile';
  const { isMobile: isMobileResponsive } = useResponsive();
  const hasAccessToStats = useHasAccessToStats();
  const hasAccessToCustomers = useGetHasAccessToCustomers();
  const country = useGetCountry();
  const shouldShowSalesChannelsSection =
    validateRoles(['full', 'pos']) || validateRoles(['design', 'meta']);
  const shouldShowMaximizeSection =
    (validateRoles('marketing') && country === Country.BR) ||
    validateRoles(['full', 'apps']);
  const isPlanLojinha = useIsPlanLojinha();
  const { name, url } = useHeaderMenuData();

  const { partnerId } = useStoreInfo();
  const { fetchMenu, menu } = useMenu();
  const { fetchDevMode } = useDevMode();
  const menuBodyRef = useMenuScrollShadow();
  const tags = useGetTags();
  const { isPos } = useOnboardingTasks();

  const isRevampMenu = tags.includes(REVAMP_MENU_V1);

  const hasEnhanceApps =
    Object.keys(menu?.Main?.Enhance?.options || {}).length > 0;

  useEffect(() => {
    fetchMenu();
  }, [fetchMenu]);

  useEffect(() => {
    fetchDevMode(partnerId);
  }, [fetchDevMode, partnerId]);

  const optionSettingsDefault = useGetDefaultSettingsOption();
  const optionSettingsDefaultRevamp = useGetDefaultSettingsOptionRevamp();

  const handleClickSettings = () => {
    handleClickAccordion();
    goTo(
      MENU_PATHS[
        isRevampMenu ? optionSettingsDefaultRevamp : optionSettingsDefault
      ],
    );
    trackingMenuConfigurationClick();
  };

  useEffect(() => {
    fetchNuvemApps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function AvatarIcon({ ...props }: IconProps) {
    return <Avatar name={name} {...props} />;
  }

  const handleClickAccount = () => {
    handleClickAccordion();
    goTo(
      isRevampMenu
        ? MENU_PATHS.authenticationFactor
        : validateRoles('full')
        ? MENU_PATHS.businessData
        : MENU_PATHS.authenticationFactor,
    );
  };

  const acccountMenuMobile = {
    label: name,
    icon: AvatarIcon,
    onClick: handleClickAccount,
  };

  const settingsMenuDesktop = {
    label: t('menu.config'),
    icon: CogIcon,
    onClick: handleClickSettings,
  };

  const isValidateFullAndDesktop = validateRoles(
    isRevampMenu ? rolesForSettingsRevamp : rolesForSettings,
  )
    ? settingsMenuDesktop
    : undefined;

  const footer = isMobileResponsive
    ? acccountMenuMobile
    : isValidateFullAndDesktop;

  const isNext = useGetIsNext();
  const isEvolucion = useGetIsEvolucion();

  let headerLogo;
  let headerLogoColor;
  if (isNext) {
    headerLogo = 'Next';
    headerLogoColor = tokens.color.light.neutral['text-high'].value;
  } else if (isEvolucion) {
    headerLogo = 'Evolucion';
    headerLogoColor = tokens.color.light.neutral['text-high'].value;
  } else {
    headerLogo = language === 'pt-BR' ? 'Nuvemshop' : 'Tiendanube';
    headerLogoColor = tokens.color.light.primary.interactive.value;
  }

  useEffect(() => {
    if (isNext) {
      document.body.classList.add('stratus--plan-next');
    }
  }, [isNext]);

  const menuContainerClassName = classNames('stratus--menu-container', {
    'stratus--menu-container--next': isNext || isEvolucion,
  });

  const handleClickNews = () => {
    openWindow(t('topbar.whatsNewLink'), true);
  };

  const handleMenuStoreVisitClick = () => {
    trackingMenuStoreVisit();
    openWindow(url, true);
  };

  const menuContent = (
    <>
      {isMobileResponsive ? (
        <MainMenuMobileHeader
          onClickSupport={onClickSupport}
          onClickNews={handleClickNews}
          onClickProfile={handleClickAccount}
          name={name}
        />
      ) : (
        <MainMenuDesktopHeader
          headerLogo={headerLogo}
          headerLogoColor={headerLogoColor}
        />
      )}
      <div className="stratus--menu-body" ref={menuBodyRef}>
        <Menu.Body>
          {isMobileResponsive && (
            <Box>
              <Box
                paddingBottom="3"
                paddingTop="3"
                paddingLeft="2"
                paddingRight="2"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Logo src={headerLogo} color={headerLogoColor} />
                <IconButton
                  source={<ExternalLinkIcon />}
                  size="2rem"
                  aria-label={t('menu.visitStore')}
                  onClick={handleMenuStoreVisitClick}
                  tabIndex={-1}
                />
              </Box>
            </Box>
          )}

          <Menu.Section>
            <DashboardMenu />
            {hasAccessToStats && <StatsMenu />}
          </Menu.Section>
          {/* Administrar */}
          <ShowByRole
            includeRoles={[
              'orders',
              'draft_orders',
              'abandoned_carts',
              'products',
              'customers',
              'marketing',
              'nuvem_envio',
              'nuvem_pago',
              'nuvem_chat',
            ]}
          >
            <Menu.Section
              title={t(`menu.manage.${isRevampMenu ? 'revampTitle' : 'title'}`)}
            >
              <OrdersMenu isMobile={isMobile} />
              <ProductsMenu isMobile={isMobile} />
              <NuvemPagoMenu />
              <NuvemEnvioMenu />
              <NuvemChatMenu />
              {hasAccessToCustomers && <CustomersMenu />}
              <MarketingMenu isMobile={isMobile} />
              <MarketingAutomationMenu />
            </Menu.Section>
          </ShowByRole>

          {/* Canales de venta */}
          {shouldShowSalesChannelsSection && (
            <Menu.Section title={`${t('menu.salesChannels.title')}`}>
              {isPos && (
                <>
                  <ShowByRole includeRoles={['full', 'pos']}>
                    <PosMenu />
                  </ShowByRole>
                  <ShowByRole includeRoles="design">
                    <CustomizeMenu />
                  </ShowByRole>
                </>
              )}
              {!isPos && (
                <>
                  <ShowByRole includeRoles="design">
                    <CustomizeMenu />
                  </ShowByRole>
                  <ShowByRole includeRoles={['full', 'pos']}>
                    <PosMenu />
                  </ShowByRole>
                </>
              )}

              {!isPlanLojinha && !isMobile && (
                <ShowByRole
                  includeRoles={['meta', 'google_shopping', 'tiktok']}
                >
                  <SalesChannelsMenu />
                </ShowByRole>
              )}
              {!isPlanLojinha && (
                <ShowByRole includeRoles={['full']}>
                  <MarketplacesMenu />
                </ShowByRole>
              )}
            </Menu.Section>
          )}

          {/* Potenciar */}
          {shouldShowMaximizeSection && (
            <Menu.Section title={`${t('menu.maximize.title')}`}>
              <ShowByRole includeRoles="full">
                {!isMobile && !isRevampMenu && (
                  <ShowByCountry allowedCountries={[Country.BR]}>
                    <MenuButton
                      as={LinkRoute}
                      target="_blank"
                      startIcon={UniversityIcon}
                      to={`/admin${t('menu.maximize.enp_url')}`}
                      onClick={goToAdmin(t('menu.maximize.enp_url'))}
                      label={`${t('menu.maximize.enp')}`}
                    >
                      <Tag appearance="primary">{t('menu.free')}</Tag>
                    </MenuButton>
                  </ShowByCountry>
                )}
              </ShowByRole>
              <ShowByRole includeRoles={['full', 'apps']}>
                <AppsMenu isMobile={isMobile} />
              </ShowByRole>
              {isMobile && <KeyboardMenu />}
            </Menu.Section>
          )}
          {!isMobile && hasEnhanceApps && (
            <Menu.Section
              title={
                shouldShowMaximizeSection
                  ? undefined
                  : `${t('menu.maximize.title')}`
              }
            >
              <ExternalAppsEnhanceMenu isMobile={isMobile} />
            </Menu.Section>
          )}
        </Menu.Body>
      </div>
      {footer && (
        <>
          {/* Configuración Mobile */}
          {isMobileResponsive ? (
            <ShowByRole includeRoles={rolesForSettings}>
              <div className="stratus--configurations-footer-mobile">
                <Menu.Footer
                  label={t('menu.config')}
                  startIcon={CogIcon}
                  onClick={handleClickSettings}
                />
              </div>
            </ShowByRole>
          ) : (
            <Menu.Footer
              label={footer?.label || ''}
              startIcon={footer?.icon}
              onClick={footer?.onClick}
            />
          )}
        </>
      )}
    </>
  );

  return (
    <div className={menuContainerClassName}>
      {isNext || isEvolucion ? (
        <MenuWithSpotlight>{menuContent}</MenuWithSpotlight>
      ) : (
        <Menu>{menuContent}</Menu>
      )}
    </div>
  );
}

export default MainMenu;
