import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { CATALOG_HIDING_VARIANTS } from 'App/featuresFlags';
import { useHasTags, useNavegate } from 'App/hooks';
import { useCloneProductJobs } from 'App/settings/jobs/hooks';
import { useClipboard, useModal } from 'commons/hooks';
import { CATALOG_ROUTES } from 'domains/Catalog/catalogRoutes';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import ModalConfirmationCloneProduct from 'domains/Catalog/Products/pages/components/ModalConfirmationCloneProduct';
import ModalConfirmationRemoveProduct from 'domains/Catalog/Products/pages/components/ModalConfirmationRemoveproduct';
import useDuplicateAttributes from 'domains/Catalog/Products/pages/components/Variants/hooks/useDuplicateAttributes';
import { cloneProductDigest } from 'domains/Catalog/Products/pages/EditProductPage/digests';
import { useProductActions } from 'domains/Catalog/Products/pages/hooks';
import useProductItemList from 'domains/Catalog/Products/pages/hooks/useProductItemList';
import { ProductToCloneInterface } from 'domains/Catalog/Products/productsServices';
import {
  trackingProductListActionsCopyClick,
  trackingProductListDeleteIconClick,
  trackingProductListDuplicateIconClick,
} from 'domains/Catalog/Products/tracking';
import ActionsResponsive from './ActionsResponsive';

interface ActionRowProductsProps {
  id: string;
}

function ActionRowProducts({
  id,
}: Readonly<ActionRowProductsProps>): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  const { product } = useProductItemList(id);
  const { cloneProduct, deleteProduct } = useProductActions();
  const { setJob } = useCloneProductJobs();
  const { goTo } = useNavegate();
  const [showClone, openClone, closeClone] = useModal();
  const [showRemove, openRemove, closeRemove] = useModal();
  const [actionInProgress, setActionInProgress] = useState(false);
  const copyToClipboard = useClipboard();

  const sender = useTrackFullCatalog();

  const { withDuplicateAttributesMainLanguage } = useDuplicateAttributes(
    product.id,
  );

  const [hasHidingVariants] = useHasTags([CATALOG_HIDING_VARIANTS]);

  const handleClickCopy = () => {
    trackingProductListActionsCopyClick(product.canonical_url);
    copyToClipboard(product.canonical_url, t('products.copyLinkProduct'));
  };

  const handleOpenCloneModal = () => {
    sender(trackingProductListDuplicateIconClick);
    openClone();
  };

  const handleOpenRemoveModal = () => {
    sender(trackingProductListDeleteIconClick);
    openRemove();
  };

  const handleCloneProduct = async (data: ProductToCloneInterface) => {
    const params = cloneProductDigest(data, id, hasHidingVariants ? '3' : '2');
    closeClone();
    setActionInProgress(true);
    try {
      const { product, token } = await cloneProduct(params);
      goTo(CATALOG_ROUTES.editProduct.replace(':id', product.id));
      if (token) {
        setJob(product.id, token);
      }
    } finally {
      setActionInProgress(false);
    }
  };

  const handleRemoveProduct = async () => {
    closeRemove();
    setActionInProgress(true);
    try {
      await deleteProduct(product.id);
    } finally {
      setActionInProgress(false);
    }
  };

  return (
    <>
      <ActionsResponsive
        onCloneProduct={!actionInProgress ? handleOpenCloneModal : () => null}
        onRemoveProduct={!actionInProgress ? handleOpenRemoveModal : () => null}
        onClipBoard={handleClickCopy}
        disableClone={withDuplicateAttributesMainLanguage}
      />

      {showClone && (
        <ModalConfirmationCloneProduct
          onCloneProduct={handleCloneProduct}
          onClose={closeClone}
          hasImages={product.images.length > 0}
          productName={product.name}
          variants={product.variants}
        />
      )}
      {showRemove && (
        <ModalConfirmationRemoveProduct
          onClose={closeRemove}
          onRemoveProduct={handleRemoveProduct}
        />
      )}
    </>
  );
}

export default ActionRowProducts;
