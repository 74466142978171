import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  PaymentProvidersCode,
  muvemPagoOrPagoNubeIds,
} from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { Country } from '@tiendanube/common/src/enums';
import { useNavegate } from 'App/hooks';
import { useForm, useToastStatus } from 'commons/hooks';
import { authenticationFactorsStatus } from 'domains/Account';
import { useGetCountry } from 'domains/Auth/hooks';
import { PAYMENTS_BASE_ROUTE } from 'domains/Payments/paymentsRoutes';
import { invokeEventPayments } from 'domains/Payments/tracking';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import { getValidationSchema } from './validationSchema';
import {
  useInstallPaymentProvider,
  useInstalledPaymentProviders,
} from '../../hooks';

const TRANSLATE_PREFIX = 'nuvempagoSettings';

type authenticationFactorValue = { document: string } | false;

const initialValues = {
  document: '',
};

function useNuvempagoInitSettingsPage() {
  const t = useTranslationPayments(TRANSLATE_PREFIX);
  const [authenticationFactor, setAuthenticationFactor] =
    useState<authenticationFactorValue>(false);
  const { status: authenticationStatus } = useSelector(
    authenticationFactorsStatus,
  );
  const { install, updateStatus } = useInstallPaymentProvider();
  const country = useGetCountry();
  const { goTo } = useNavegate();
  const {
    isSuccess,
    paymentProviders,
    isIdle,
    fetchInstalledPaymentProviders,
    isLoading,
  } = useInstalledPaymentProviders();

  const paymentProvider = useMemo(
    () =>
      paymentProviders?.find(({ id }) => muvemPagoOrPagoNubeIds.includes(id)),
    [paymentProviders],
  );

  const goToPaymentsList = useCallback(() => {
    goTo(PAYMENTS_BASE_ROUTE);
  }, [goTo]);

  useEffect(() => {
    if (isIdle) {
      fetchInstalledPaymentProviders();
    }
  }, [isIdle, fetchInstalledPaymentProviders]);

  useEffect(() => {
    if (isSuccess) {
      if (!paymentProvider || paymentProvider.mustCompleteInstallation) {
        goToPaymentsList();
      }
    }
  }, [isSuccess, paymentProvider, goToPaymentsList]);

  const closeAuthenticationFactor = () => {
    setAuthenticationFactor(false);
  };

  const { values, errors, handleOnChange, handleOnSubmit } = useForm({
    initialValues,
    validationSchema: getValidationSchema(country),
    onSubmit: (values) => {
      setAuthenticationFactor(values);
    },
  });

  const handleConfirmAction = () => {
    closeAuthenticationFactor();

    const provider =
      country === Country.BR
        ? PaymentProvidersCode.nuvemPago
        : PaymentProvidersCode.pagoNube;

    invokeEventPayments('install', provider);
    install(provider, values);
  };

  useToastStatus({
    error: t('errors.save'),
    success: t('save'),
    status: updateStatus,
    onSuccess: goToPaymentsList,
  });

  return {
    authenticationFactor,
    closeAuthenticationFactor,
    updateLoading:
      updateStatus === 'loading' || authenticationStatus === 'loading',
    paymentProvider,
    country,
    isLoading,
    values,
    errors,
    handleOnChange,
    handleOnSubmit,
    handleConfirmAction,
  };
}

export default useNuvempagoInitSettingsPage;
