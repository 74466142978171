import {
  Alert,
  Card,
  Icon,
  Label,
  Link,
  Tag,
  Text,
} from '@nimbus-ds/components';
import {
  CheckIcon,
  StarIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@nimbus-ds/icons';
import { Trans } from 'react-i18next';
import { PaymentProviderDto } from '@tiendanube/common';
import {
  PaymentProvidersCode,
  muvemPagoOrPagoNubeIds,
} from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { MethodNameAndImage, ShowMoreContent, Stack } from 'commons/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import {
  PaymentMethodsEmptyTable,
  PaymentMethodsTable,
  ProvidersActions,
} from './components';
import Skeleton from './Skeleton';
import DeprecatedPaymentProviderWarning from '../DeprecatedPaymentProviderWarning';
import { OnPaymentProviderActionType } from '../PaymentProviderActionModal';
import { PaymentProviderStatusLabel } from '../PaymentProviderStatusLabel';
import './PaymentProvider.scss';

type PaymentProviderDesktopProps = {
  paymentProvider: PaymentProviderDto;
  onAction: OnPaymentProviderActionType;
};

function PaymentProviderDesktop({
  paymentProvider,
  onAction,
}: Readonly<PaymentProviderDesktopProps>) {
  const t = useTranslationPayments();
  const {
    id,
    logo,
    features,
    rates,
    isActive,
    mustCompleteInstallation,
    processingInstallation,
    isDeprecated,
    isNew,
  } = paymentProvider;

  const isPagoNube = muvemPagoOrPagoNubeIds.includes(id);
  const hasPaymentMethods = rates.length > 0;

  return (
    <div className="stratus--payment-provider-card">
      <Card>
        <Card.Header>
          <Stack justify="space-between">
            <MethodNameAndImage image={logo} isPagoNube={isPagoNube} />
            <Stack>
              {isPagoNube && (
                <Tag appearance="primary">
                  <StarIcon size={12} />
                  {t('paymentProvider.recommended')}
                </Tag>
              )}
              <PaymentProviderStatusLabel
                isActive={isActive}
                mustCompleteInstallation={mustCompleteInstallation}
                processingInstallation={processingInstallation}
                isNew={isNew}
                isDeprecated={isDeprecated}
              />
            </Stack>
          </Stack>
        </Card.Header>
        <Card.Body>
          <Stack column align="flex-start" spacing="base">
            <Stack>
              {features.map((feature) => (
                <Label key={feature}>
                  <Icon color="primary-textHigh" source={<CheckIcon />} />
                  <Text>{t(`paymentFeaturesCard.features.${feature}`)}</Text>
                </Label>
              ))}
            </Stack>
            {paymentProvider.id === PaymentProvidersCode.mercadopago &&
              paymentProvider.isDeprecated && (
                <Alert appearance="warning">
                  <Text>
                    <Trans
                      t={t}
                      i18nKey="paymentProvider.settings.mercadopago.deprecatedAlert"
                      components={{
                        TyCLink: (
                          <Link
                            as="a"
                            appearance="neutral"
                            href={t(
                              'paymentProvider.settings.mercadopago.tycLink',
                            )}
                            target="_blank"
                          >
                            {' '}
                          </Link>
                        ),
                      }}
                    />
                  </Text>
                </Alert>
              )}
            {paymentProvider.id === PaymentProvidersCode.paypal &&
              paymentProvider.isDeprecated && (
                <DeprecatedPaymentProviderWarning
                  id={PaymentProvidersCode.paypal}
                  name="PayPal"
                />
              )}
            {hasPaymentMethods ? (
              <ShowMoreContent
                maxHeight={isPagoNube ? 1000 : 150}
                buttonStyles={{ textDecoration: 'none', appearance: 'primary' }}
                buttonIconOpen={
                  <Icon
                    color="primary-interactive"
                    source={<ChevronUpIcon />}
                  />
                }
                buttonIconClose={
                  <Icon
                    color="primary-interactive"
                    source={<ChevronDownIcon />}
                  />
                }
              >
                <PaymentMethodsTable providerId={id} rates={rates} />
              </ShowMoreContent>
            ) : (
              <PaymentMethodsEmptyTable provider={paymentProvider} />
            )}
          </Stack>
        </Card.Body>
        <ProvidersActions
          paymentProvider={paymentProvider}
          onAction={onAction}
        />
      </Card>
    </div>
  );
}

PaymentProviderDesktop.Skeleton = Skeleton;

export default PaymentProviderDesktop;
