import { ChangeEvent, useEffect } from 'react';
import {
  CustomShippingResponseDto,
  LocationResponseDto,
} from '@tiendanube/common';
import { Stack } from 'commons/components';
import {
  useHasNewPickupPoints,
  useHasShippingCustomRanges,
} from 'domains/Auth/hooks';
import Skeleton from './Skeleton';
import {
  CustomShippingCostCard,
  CustomShippingNameCard,
  ShippingConditionsCard,
  ShippingInformationCard,
  ShippingLocationCard,
  ShippingTermCard,
} from '../../../components';
import CustomShippingRangeOptionsCard from '../../../components/CustomShippingRangeOptionsCard';
import { DeliveryZoneCard } from '../../../NewCustomShippingPage/components';

interface CustomShippingFormProps {
  values: CustomShippingResponseDto;
  locations: LocationResponseDto[];
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (name: string, value: any) => void;
  fetchLocations: () => Promise<void>;
  errors?: Partial<Record<string, string>>;
}

export type DeliveryCostType = 'with-cost' | 'free' | 'to-be-arranged';

function CustomShippingForm({
  values,
  locations,
  handleChange,
  setFieldValue,
  fetchLocations,
  errors,
}: CustomShippingFormProps) {
  const hasNewPickupPoint = useHasNewPickupPoints();
  const hasCustomRanges = useHasShippingCustomRanges();

  useEffect(() => {
    if (!locations.length) {
      fetchLocations();
    }
    if (locations.length === 1 && !values.locationId) {
      setFieldValue('locationId', locations[0]?.id || '');
    }
  }, [fetchLocations, setFieldValue, locations, values]);

  return (
    <Stack column>
      {!hasCustomRanges && (
        <ShippingInformationCard
          name={values.name}
          deliveryCost={values.deliveryCost}
          cost={values.cost}
          showAsPickUpPoint={values.showAsPickUpPoint}
          allowedForFreeShipping={values.allowedForFreeShipping}
          onChange={handleChange}
          error={errors && errors.name}
          isShowAsPickUpPoint={
            !hasNewPickupPoint || (values.showAsPickUpPoint && !!values.id)
          }
        />
      )}

      {hasCustomRanges && (
        <CustomShippingNameCard
          name={values.name}
          deliveryCost={values.deliveryCost}
          allowedForFreeShipping={values.allowedForFreeShipping}
          onChange={handleChange}
          error={errors?.name}
        />
      )}

      {locations.length > 1 && (
        <ShippingLocationCard
          locations={locations}
          locationId={values.locationId}
          error={errors && errors.locationId}
          onChange={handleChange}
        />
      )}

      {hasCustomRanges && (
        <CustomShippingRangeOptionsCard
          rangeType={values.rangeType}
          ranges={values.ranges}
          selectedZones={values.deliveryZones}
          setFieldValue={setFieldValue}
        />
      )}

      {hasCustomRanges && values.rangeType !== 'ranges' && (
        <CustomShippingCostCard
          cost={values.cost}
          deliveryCost={values.deliveryCost}
          onChange={handleChange}
        />
      )}

      {!hasCustomRanges && (
        <DeliveryZoneCard
          setFieldValue={setFieldValue}
          selectedZones={values.deliveryZones}
        />
      )}

      {values.rangeType !== 'ranges' && (
        <ShippingTermCard
          timeMin={values.deliveryTimeMin}
          timeMax={values.deliveryTimeMax}
          onChange={handleChange}
          error={errors}
        />
      )}

      <ShippingConditionsCard
        onChange={handleChange}
        weightMin={values.cartWeightMin}
        weightMax={values.cartWeightMax}
        valueMin={values.cartValueMin}
        valueMax={values.cartValueMax}
        error={errors}
      />
    </Stack>
  );
}

CustomShippingForm.Skeleton = Skeleton;

export default CustomShippingForm;
