import { useImportCsv } from 'commons/hooks/useImportCsv';
import productsServices from 'domains/Catalog/Products/productsServices';
import { trackingIgnoreAllColumns } from 'domains/Catalog/Products/tracking';

export function useImportProducts() {
  return useImportCsv(
    'productsCsvImport',
    productsServices.importFile,
    productsServices.fetchImportColumns,
    trackingIgnoreAllColumns,
  );
}
