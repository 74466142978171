import { Box, Text, Title, TitleColorProperties } from '@nimbus-ds/components';
import {
  getSkeletonWidth,
  skeletonSizeMap,
  textColorMap,
  textSizeMap,
} from './mappings';
import { BaseLegacyTextProps, LegacyTextSkeletonProps } from './types';
import './legacyText.scss';

/**
 * @deprecated Base component which handles both Text and Text.Span, depending on the 'as' prop.
 */
function BaseLegacyText({
  children,
  as,
  appearance = 'default',
  bold,
  trimText,
  trimLines,
  textAlign,
  background,
  block,
  // For block display, use boxPadding and boxRadius to better fit your needs. They have default values which represent the
  // general usage, but for specific use cases, they can be modified.
  boxPadding = '2',
  boxRadius = '2',
  fontWeight = 'regular',
  size,
}: Readonly<BaseLegacyTextProps>): JSX.Element {
  // Use the defined appearance text color, or the default one if not specified
  const textColor = appearance
    ? textColorMap[appearance].text
    : textColorMap.default.text;

  const textComponent = (
    <Text
      as={as}
      color={background ? textColorMap.default.text : textColor}
      fontWeight={bold ? 'bold' : fontWeight}
      lineClamp={trimText ? trimLines : 0}
      textAlign={textAlign}
      fontSize={textSizeMap[size ?? 'base']}
    >
      {children}
    </Text>
  );

  // If any of these props are passed, we need to wrap the Text in a Box
  return background ? (
    <Box
      // Old nimbus text uses 'inline-block' actually, this is the closest we can go
      display={block ? 'block' : 'inline-flex'}
      // If background is set, we use the textColorMap to define its color. If not, we set it to transparent.
      backgroundColor={
        background ? textColorMap[appearance].background : 'transparent'
      }
      // Settings to match old nimbus text
      borderRadius={boxRadius}
      padding={boxPadding}
      // To make background fill the entire space by default
      width="100%"
    >
      {textComponent}
    </Box>
  ) : (
    <Box display={block ? 'block' : 'inline-flex'}>{textComponent}</Box>
  );
}

/**
 * @deprecated Used to replace old nimbus \<Text /> (from tiendanube/components). It renders an updated \<Text /> from the new nimbus-ds.
 *
 * **For new implementations, it is mandatory to use nimbus-ds/components**
 */
function LegacyText(props: Readonly<Omit<BaseLegacyTextProps, 'as'>>) {
  // To support a few cases where a size 'featured' is used but new nimbus doesn't support this text size (2rem)
  if (props.size === 'featured') {
    return (
      <Title
        as="h2"
        fontSize="h1"
        fontWeight={props.bold ? 'bold' : 'regular'}
        color={
          (props.appearance
            ? textColorMap[props.appearance].text
            : textColorMap.default.text) as TitleColorProperties // Casting necessary, as Title doesn't contain the 'currentColor' property. But we're not using it so
          // it is safe to fix it this way
        }
        // Exactly the previous Text 2.5rem lineHeight
        lineHeight="10"
      >
        {props.children}
      </Title>
    );
  }

  return <BaseLegacyText {...props} />;
}

/**
 * @deprecated Used to replace old nimbus \<Text.Span /> (from tiendanube/components). It renders an updated \<Text.Span /> from the new
 * nimbus-ds.
 *
 * **For new implementations, it is mandatory to use nimbus-ds/components**
 */
function LegacyTextSpan(props: Readonly<Omit<BaseLegacyTextProps, 'as'>>) {
  return <BaseLegacyText {...props} as="span" />;
}

/**
 * @deprecated Used to replace old nimbus \<Text.Skeleton /> (from tiendanube/components). It renders an updated \<Text.Skeleton /> from the
 * new nimbus-ds.
 *
 * **For new implementations, it is mandatory to use nimbus-ds/components**
 */
function LegacyTextSkeleton({
  width,
  size,
}: Readonly<LegacyTextSkeletonProps>) {
  const {
    width: sizeWidth,
    height: sizeHeight,
    marginY,
  } = skeletonSizeMap[size ?? 'base'];

  return (
    <Box marginY={marginY}>
      <Text.Skeleton
        fontSize={textSizeMap[size ?? 'base']}
        width={width ? getSkeletonWidth(width) : sizeWidth}
        height={sizeHeight}
      />
    </Box>
  );
}

LegacyText.Span = LegacyTextSpan;
LegacyText.Skeleton = LegacyTextSkeleton;

export default LegacyText;
