import { DataTable } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';

const { Header, Cell, Row } = DataTable;

function ComparisonTableDesktopSkeleton(): JSX.Element {
  return (
    <Stack column align="stretch">
      <Stack.Item>
        <Text.Skeleton size="featured" />
      </Stack.Item>
      <Stack.Item>
        <DataTable>
          <Header>
            <Cell grow={0} basis={360}>
              <Text.Skeleton />
            </Cell>
            <Cell>
              <Text.Skeleton />
            </Cell>
            <Cell>
              <Text.Skeleton />
            </Cell>
            <Cell>
              <Text.Skeleton />
            </Cell>
            <Cell>
              <Text.Skeleton />
            </Cell>
          </Header>
          <Row id="1">
            <Cell grow={0} basis={360}>
              <Text.Skeleton />
            </Cell>
            <Cell>
              <Text.Skeleton />
            </Cell>
            <Cell>
              <Text.Skeleton />
            </Cell>
            <Cell>
              <Text.Skeleton />
            </Cell>
            <Cell>
              <Text.Skeleton />
            </Cell>
          </Row>
          <Row id="2">
            <Cell grow={0} basis={360}>
              <Text.Skeleton />
            </Cell>
            <Cell>
              <Text.Skeleton />
            </Cell>
            <Cell>
              <Text.Skeleton />
            </Cell>
            <Cell>
              <Text.Skeleton />
            </Cell>
            <Cell>
              <Text.Skeleton />
            </Cell>
          </Row>
          <Row id="3">
            <Cell grow={0} basis={360}>
              <Text.Skeleton />
            </Cell>
            <Cell>
              <Text.Skeleton />
            </Cell>
            <Cell>
              <Text.Skeleton />
            </Cell>
            <Cell>
              <Text.Skeleton />
            </Cell>
            <Cell>
              <Text.Skeleton />
            </Cell>
          </Row>
        </DataTable>
      </Stack.Item>
    </Stack>
  );
}

export default ComparisonTableDesktopSkeleton;
