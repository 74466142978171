import { MetafieldSelectedsOrErrorType } from 'domains/Catalog/Products/pages/components/Variants/types';
import useGetCustomerMetafields from 'domains/Metafields/hooks/useGetCustomerMetafields';
import { MetafieldsSelected } from '../../types';
import SectionCustomerMetafields from '../SectionCustomerMetafields';

interface SectionCustomerMetafieldsNewProps {
  selecteds: MetafieldSelectedsOrErrorType;
  apiSelecteds: MetafieldSelectedsOrErrorType;
  onChange: (metafields: MetafieldsSelected) => void;
}

export default function SectionCustomerMetafieldsNew({
  ...rest
}: SectionCustomerMetafieldsNewProps) {
  const { status } = useGetCustomerMetafields();
  const { isError, isLoading, isSuccess } = status;

  return (
    <SectionCustomerMetafields
      {...rest}
      showError={isError}
      showLoading={isLoading}
      showSuccess={isSuccess}
      mode="add"
    />
  );
}
