import { Text } from '@nimbus-ds/components';
import { StandardColorResponseDtoV2 } from '@tiendanube/common';
import { InteractiveList } from '@tiendanube/components';
import { Stack } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { isAttributeValueSelected } from '../../../utils';
import {
  filterStandardValues,
  filterSuggestedValues,
  getSuggestedListOptionsByAttribute,
} from '../../utils';
import { StandardAttributeProps } from '../types';

interface StandardColorAttributeProps extends StandardAttributeProps {
  colors: StandardColorResponseDtoV2;
}

// TODO: stratus badge should be a common component
function StandardColorAttribute({
  colors,
  selectedValues,
  languageToUse,
  onCheckOption,
  children,
}: StandardColorAttributeProps): JSX.Element {
  const t = useTranslationCatalog();

  const standardOptions = Object.keys(colors.standard)
    .map((colorId) => {
      const standardColor = colors.standard[colorId];
      const standardColorHex = standardColor.visualHelper as string;
      return {
        title: standardColor[languageToUse],
        name: colorId,
        blockLeft: standardColorHex && (
          <div
            className={`stratus--badge color-${standardColorHex.substring(1)}`}
            style={{ backgroundColor: standardColorHex }}
          />
        ),
        active: isAttributeValueSelected(
          selectedValues,
          languageToUse,
          standardColor,
        ),
      };
    })
    .sort((a, b) => a.title.localeCompare(b.title));

  const suggestedOptions = getSuggestedListOptionsByAttribute(
    colors.suggested,
    selectedValues,
    languageToUse,
  );

  const handleCheckSuggestedValue = (selectedIds: string[]) =>
    onCheckOption([
      ...filterStandardValues(selectedValues, languageToUse, colors.standard),
      ...selectedIds,
    ]);

  const handleCheckStandardValue = (selectedIds: string[]) =>
    onCheckOption([
      ...filterSuggestedValues(selectedValues, languageToUse, colors.suggested),
      ...selectedIds,
    ]);

  return (
    <Stack column align="stretch" spacing="loose">
      <Stack column align="stretch">
        <Stack column align="stretch" spacing="tight">
          <Text fontWeight="bold">{t('products.variants.selectedColors')}</Text>
          <Text fontSize="caption">
            {t('products.variants.selectedColorsCaption')}
          </Text>
        </Stack>
        <Stack column align="stretch">
          {children}
        </Stack>
      </Stack>
      <Stack column align="stretch">
        {suggestedOptions.length > 0 && (
          <>
            <Text fontWeight="bold">
              {t('products.variants.suggestedColors')}
            </Text>
            <InteractiveList
              mode="multi"
              onChange={handleCheckSuggestedValue}
              options={suggestedOptions}
            />
          </>
        )}
        <Text fontWeight="bold">{t('products.variants.basicColors')}</Text>
        <InteractiveList
          mode="multi"
          onChange={handleCheckStandardValue}
          options={standardOptions}
        />
      </Stack>
    </Stack>
  );
}

export default StandardColorAttribute;
