import { useEffect } from 'react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { ItemContent, Virtuoso } from 'react-virtuoso';
import { DataList, InterfaceDataList } from '@tiendanube/components';
import Pull from 'commons/components/Pull';
import Spinner from 'commons/components/Spinner';

interface VirtualDataListInterface<T = string>
  extends Pick<
    InterfaceDataList,
    'editMode' | 'onEditMode' | 'bulkAction' | 'selectedRowsId' | 'onSelectRow'
  > {
  onRefresh?: () => Promise<void>;
  isPullable?: boolean;
  data: T[];
  itemContent: ItemContent<T, unknown>; // TODO: Validate second type
  endReached?: () => void;
  showFooter?: boolean;
}

const noop = () => null;
const noopPromise = async () => Promise.resolve();

function VirtualDataList<T = string>({
  editMode,
  onEditMode,
  bulkAction,
  selectedRowsId,
  onSelectRow,
  onRefresh = noopPromise,
  isPullable,
  data,
  itemContent,
  endReached = noop,
  showFooter = false,
}: VirtualDataListInterface<T>): JSX.Element {
  const footer = () => <Spinner show={showFooter} />;

  //The following useEffect adds a class to the body to avoid showing an internal scroll in the list
  useEffect(() => {
    const body = document.body;
    body.classList.add('backdrop-no-scroll');

    return () => {
      body.classList.remove('backdrop-no-scroll');
    };
  }, []);

  return (
    <DataList
      editMode={editMode}
      onEditMode={onEditMode}
      bulkAction={bulkAction}
      selectedRowsId={selectedRowsId}
      onSelectRow={onSelectRow}
    >
      <PullToRefresh
        onRefresh={onRefresh}
        isPullable={isPullable}
        pullDownThreshold={60}
        maxPullDownDistance={60}
        pullingContent={<Pull />}
        refreshingContent={<Spinner show />}
      >
        <Virtuoso
          useWindowScroll
          data={data}
          itemContent={itemContent}
          endReached={endReached}
          components={{
            Footer: footer,
          }}
        />
      </PullToRefresh>
    </DataList>
  );
}

export default VirtualDataList;
