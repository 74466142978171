import { FEATURE_MONTHLY_CATALOG_EXPORT } from 'App/features';
import { ImportCsvCard } from 'commons/components';
import { useTranslationCommon } from 'commons/hooks';
import { CATALOG_IMPORT_PRODUCTS } from 'config/upsellFlowSources';
import { useStatusFeatureByKey } from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import productsService from 'domains/Catalog/Products/productsServices';
import { ImportProductsExtraOptions } from './ImportProductsExtraOptions';
import { useImportMaxLinesProducts, useImportProducts } from '../../hooks';

interface ImportProductsCardProps {
  showUpdateOptions?: boolean;
}

export function ImportProductsCard({
  showUpdateOptions = false,
}: ImportProductsCardProps) {
  const t = useTranslationCommon('importCsv.import');
  const { isAvailable } = useStatusFeatureByKey(FEATURE_MONTHLY_CATALOG_EXPORT);
  const entitiesName = t('entities-products');

  const handleUpsellCallout = useUpsellFlow({
    title: t('upsell.modalTitle', { entities: entitiesName }),
    featureKey: FEATURE_MONTHLY_CATALOG_EXPORT,
    trackingSource: CATALOG_IMPORT_PRODUCTS,
    callback: () => {
      // Just re-render the component
    },
  });

  return (
    <ImportCsvCard
      entity="products"
      entitiesName={entitiesName}
      fetchTemplateCsvHandler={productsService.fetchTemplateCsv}
      useImportCsvWrapper={useImportProducts}
      useImportMaxLinesWrapper={useImportMaxLinesProducts}
      showUpdateOptions={showUpdateOptions}
      handleUpsellCallout={handleUpsellCallout}
      isAvailable={isAvailable}
      extraOptions={showUpdateOptions ? ImportProductsExtraOptions : undefined}
    />
  );
}
