import { ReactNode } from 'react';
import { Box } from '@nimbus-ds/components';
import { Stack, Title } from '@tiendanube/components';
import { useResponsive } from 'commons/components';

interface DetailsHeaderProps {
  title: string;
  right?: ReactNode;
}

function DetailsHeader({ title, right }: DetailsHeaderProps): JSX.Element {
  const { isDesktop } = useResponsive();

  return (
    <Stack justify="space-between">
      {isDesktop && (
        <Stack.Item>
          <Title type="h2">{title}</Title>
        </Stack.Item>
      )}
      <Box flexShrink="1">{right}</Box>
    </Stack>
  );
}

export default DetailsHeader;
