import { Alert, Text } from '@nimbus-ds/components';
import { CheckCircleIcon, PlusCircleIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import HelpLink from 'App/HelpLink';
import { toStatusType } from 'App/rtk';
import {
  ActionProp,
  ActionsDesktop,
  CancelAndSaveButtons,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  useResponsive,
} from 'commons/components';
import Link from 'commons/components/LegacyLink';
import { HELP_LINKS_MENUS } from 'commons/constants';
import { useModal, useToastStatus } from 'commons/hooks';
import { useTranslationOnline } from 'domains/Online';
import { MenuCard, AddMenuModal } from './components';
import MenusPageError from './components/MenusPageError';
import { useMenusForm } from './hooks';
import { useGetMenusQuery, useUpdateMenusMutation } from '../../MenusApi';

interface ApiError {
  status: number;
  data?: {
    response?: {
      message?: string;
      errorCode?: string;
      i18Key?: string;
    };
  };
}

const CYCLIC_ERROR_CODE = 'E_01';
const DESCRIPTION_TOO_LONG_ERROR_CODE = 'E_02';

function MenusPage() {
  const { isMobile } = useResponsive();
  const {
    t: commonT,
    i18n: { language },
  } = useTranslation();
  const t = useTranslationOnline('menus.list');
  const { data, isLoading, isFetching, isError, isSuccess, refetch } =
    useGetMenusQuery();
  const [updateMenus, { error, status, isLoading: isUpdating }] =
    useUpdateMenusMutation();
  const [showAddMenuModal, openAddMenuModal, closeAddMenuModal] = useModal();
  const {
    menus,
    isDirty,
    resetValues,
    handleAddMenuItem,
    handleDeleteMenuItem,
    handleDropMenuItem,
    handleEditMenuItem,
  } = useMenusForm(data?.navigations ?? []);

  const handleCancel = () => resetValues();
  const handleSave = () => updateMenus({ navigations: menus });

  const addAction: ActionProp = {
    children: t('createMenu'),
    icon: PlusCircleIcon,
    onClick: openAddMenuModal,
    appearance: isMobile ? undefined : 'primary',
  };

  const saveAction: ActionProp = {
    onClick: handleSave,
    children: commonT('cancelAndSaveButtons.save'),
    icon: CheckCircleIcon,
    spinner: isUpdating,
    disabled: isUpdating,
  };

  const showCancelAndSaveButtons = isDirty && !isFetching;
  const showSkeleton = isLoading;

  useToastStatus({
    error: t('saveToast.error'),
    success: t('saveToast.success'),
    status: toStatusType(status),
  });

  const responseError = error as ApiError;

  const hasMenuCycleError =
    status === 'rejected' &&
    responseError?.data?.response?.errorCode === CYCLIC_ERROR_CODE;

  const isDescriptionTooLongError =
    status === 'rejected' &&
    responseError?.data?.response?.errorCode ===
      DESCRIPTION_TOO_LONG_ERROR_CODE;

  return (
    <IonPageStratus
      width="small"
      headerTop={
        <HeaderTop
          mainAction={showCancelAndSaveButtons ? saveAction : addAction}
          actions={
            showCancelAndSaveButtons && (
              <Link onClick={openAddMenuModal} icon={PlusCircleIcon} />
            )
          }
        />
      }
      headerContent={
        <HeaderContent
          title={t('title')}
          subtitle={t('subtitle')}
          actions={<ActionsDesktop actions={[addAction]} />}
        />
      }
    >
      <Alert
        appearance="danger"
        show={hasMenuCycleError}
        title={t('menuCycleError.title')}
      >
        <Text>{t('menuCycleError.message')}</Text>
      </Alert>
      <Alert
        appearance="danger"
        show={isDescriptionTooLongError}
        title={t('descriptionTooLong.title', {
          description: responseError?.data?.response?.i18Key,
        })}
      >
        <Text>{t('descriptionTooLong.message')}</Text>
      </Alert>
      {showSkeleton && <MenuCard.Skeleton />}
      {isError && <MenusPageError onRetry={refetch} />}
      {isSuccess && (
        <>
          <Prompt when={isDirty} message={commonT('common:exitEdit.info')} />
          {menus.map((menu) => (
            <MenuCard
              key={menu.id}
              id={menu.id}
              name={menu.name}
              handle={menu.handle}
              items={menu.items}
              onAddMenuItem={handleAddMenuItem(menu.id)}
              onDeleteMenuItem={handleDeleteMenuItem(menu.id)}
              onDropMenuItem={handleDropMenuItem(menu.id)}
              onEditMenuItem={handleEditMenuItem(menu.id)}
              showSkeleton={isFetching}
            />
          ))}
          {showCancelAndSaveButtons && (
            <CancelAndSaveButtons
              onCancel={handleCancel}
              onSave={handleSave}
              isLoading={isUpdating}
              isDisabled={isUpdating}
            />
          )}
          <HelpLink
            title={t('helpLink.title')}
            previousValue=""
            currentViewTracking={t('helpLink.amplitudeName')}
            linkURL={HELP_LINKS_MENUS[language]}
            icon="both"
          />

          <AddMenuModal show={showAddMenuModal} onClose={closeAddMenuModal} />
        </>
      )}
    </IonPageStratus>
  );
}

export default MenusPage;
