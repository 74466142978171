import { logEvent } from 'commons/utils/tracking';
import {
  PROMOTIONS_CREATION_VIEW,
  PROMOTIONS_OPEN_ADOPTION_ALERT,
  PROMOTIONS_CLOSE_ADOPTION_ALERT,
  PROMOTIONS_SHOW_ADOPTION_ALERT,
} from 'config/trackingEvents';

export const promotionsCreationView = () => {
  logEvent(PROMOTIONS_CREATION_VIEW, {});
};

export const promotionsShowAdoptionAlert = (type: string) => {
  logEvent(PROMOTIONS_SHOW_ADOPTION_ALERT, { type });
};

export const promotionsOpenAdoptionAlert = (type: string) => {
  logEvent(PROMOTIONS_OPEN_ADOPTION_ALERT, { type });
};

export const promotionsCloseAdoptionAlert = (type: string) => {
  logEvent(PROMOTIONS_CLOSE_ADOPTION_ALERT, { type });
};
