import { DataList } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';

const { Row, Cell } = DataList;

function PagoNubeInvoiceHistoryDataTableRowSkeleton(): JSX.Element {
  return (
    <Row id="">
      <Cell>
        <Text.Skeleton />
      </Cell>
      <Cell alignRight>
        <Text.Skeleton />
      </Cell>
    </Row>
  );
}

export default PagoNubeInvoiceHistoryDataTableRowSkeleton;
