/* eslint-disable max-statements */
import { useCallback, useMemo } from 'react';
import { Box, IconButton, Popover } from '@nimbus-ds/components';
import {
  EditIcon,
  EllipsisIcon,
  ForbiddenIcon,
  MoneyIcon,
  UndoIcon,
} from '@nimbus-ds/icons';
import { Menu } from '@nimbus-ds/patterns';
import {
  OrderFulfillBulkRequestDto,
  OrderFulfillmentUpdateRequestDto,
  OrderFulfillmentsDto,
  OrderResponseDto,
} from '@tiendanube/common';
import { Status } from '@tiendanube/common/src/enums';
import {
  BoxPackedIcon,
  TruckIcon,
  StoreIcon,
  ArchiveIcon,
  MailIcon,
  LockOpenIcon,
  PrinterIcon,
} from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import { useBoolean, useModal, useToastStatus } from 'commons/hooks';
import { useEditOrderUpsell } from 'domains/Orders/Orders/hooks/useEditOrderUpsell';
import useQuickAction from 'domains/Orders/Orders/hooks/useQuickAction';
import { SingleOrderCancelModal } from 'domains/Orders/Orders/pages/OrderDetailsPage/components';
import FulfillOrdersModal from 'domains/Orders/Orders/pages/OrderListPage/components/FulfillOrdersModal';
import { trackingOrderListQuickAction } from 'domains/Orders/tracking';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { QuickActionsPopoverActionItem } from './QuickActionsPopoverActionItem/QuickActionsPopoverActionItem';
import { QuickActionItemInterface } from './types';
import { useCanBeRefunded } from '../../../../../../../hooks';

interface QuickActionsPopoverProps {
  order: OrderResponseDto;
}

function QuickActionsPopover({
  order,
}: QuickActionsPopoverProps): JSX.Element | null {
  const {
    archiveOrder,
    fulfillOrder,
    openOrder,
    packOrder,
    paidOrder,
    printNuvemEnvio,
    cancelOrder,
    status,
  } = useQuickAction();
  const { canBeRefundedThroughCancelForm, canBeRefundedThroughRefundForm } =
    useCanBeRefunded(order);
  const [showModal, openModal, closeModal] = useModal();
  const [showCancelModal, openCancelModal, closeCancelModal] = useModal();
  const t = useTranslationOrders();
  const { goTo } = useNavegate();

  const editOrderUpsell = useEditOrderUpsell(order.id);

  useToastStatus({
    status,
    error: t('quickActions.error'),
    success: t('quickActions.success'),
    progress: t('quickActions.loading'),
  });

  const hasFulfillments = order.fulfillmentOrders.length > 0;

  const getFulfillments = (order: OrderResponseDto) =>
    order.fulfillmentOrders.map(({ id, status, shipping_info }) => ({
      fulfillmentId: id,
      status,
      shippingType: shipping_info.shipping_type,
    }));

  const handleComfirmTrackingNum = (
    fulfillOrdersAction: OrderFulfillBulkRequestDto[],
  ) => {
    // Llamada a fulfillOrder para cada pedido con su respectivo número de seguimiento
    fulfillOrdersAction.forEach((fulfill) => {
      fulfillOrder(order, {
        ...fulfill,
        fulfillments: fulfill.fulfillment ? [fulfill.fulfillment] : [],
        sendNotification: fulfill.sendNotification,
      });
    });
    closeModal();
  };
  const ordersMissingTracking = order.fulfillmentOrders.filter(
    (fulfillment) => !fulfillment.shipping_info?.tracking_code,
  ).length;

  const ordersWithOutDigital = order.fulfillmentOrders.filter(
    (fulfillment) =>
      fulfillment.shipping_info.shipping_type !== 'non-shippable',
  );
  const packagesCount = order.fulfillmentOrders.length;
  const RESOURCES = useMemo(
    () => ({
      paymentReceived: {
        icon: MoneyIcon,
        onClick: () => paidOrder(order.id),
      },
      packaging: {
        icon: BoxPackedIcon,
        onClick: () => packOrder(order.id, ordersWithOutDigital),
      },
      sent: {
        icon: TruckIcon,
        onClick: () => openModal(),
      },
      retired: {
        icon: StoreIcon,
        onClick: () =>
          order.isShippablePickup
            ? openModal()
            : fulfillOrder(order, { fulfillments: getFulfillments(order) }),
      },
      edit: {
        icon: EditIcon,
        onClick: editOrderUpsell,
      },
      archive: {
        icon: ArchiveIcon,
        onClick: () => archiveOrder(order.id),
      },
      digitallySent: {
        icon: MailIcon,
        onClick: () => openModal(),
      },
      reopen: {
        icon: LockOpenIcon,
        onClick: () => openOrder(order.id),
      },
      printNuvemEnvio: {
        icon: PrinterIcon,
        onClick: () => printNuvemEnvio(order.id),
      },
      cancel: {
        icon: ForbiddenIcon,
        onClick: () => openCancelModal(),
      },
      ...(canBeRefundedThroughRefundForm() && {
        refund: {
          icon: UndoIcon,
          onClick: () => goTo(`/orders/${order.id}/refund`),
        },
      }),
    }),
    [
      archiveOrder,
      editOrderUpsell,
      canBeRefundedThroughRefundForm,
      fulfillOrder,
      openModal,
      openOrder,
      order,
      ordersWithOutDigital,
      packOrder,
      paidOrder,
      printNuvemEnvio,
      openCancelModal,
      goTo,
    ],
  );

  const [popover, showPopover, hidePopover] = useBoolean(false);

  const onActionClick = useCallback(
    (action: string, onClick: () => void) => {
      trackingOrderListQuickAction(action);
      hidePopover();
      onClick();
    },
    [hidePopover],
  );

  const getFulfillmentsForModal = (
    order: OrderResponseDto,
  ): OrderFulfillmentsDto => {
    const fulfillments: OrderFulfillmentUpdateRequestDto[] =
      order.fulfillmentOrders.map((fulfillment) => ({
        fulfillmentId: fulfillment.id,
        status: fulfillment.status,
        shippingType: fulfillment.shipping_info.shipping_type,
      }));

    const fulfillmentIds = order.fulfillmentOrders.map(
      (fulfillment) => fulfillment.id,
    );

    return {
      orderId: order.id,
      fulfillments,
      fulfillmentIds,
    };
  };
  const fulfillmentsForModal = [getFulfillmentsForModal(order)];

  const quickActionsAvailable = useMemo(
    () =>
      order.quickActions
        .filter(({ name }) => name in RESOURCES)
        .map(({ children, name }) => {
          const { icon, onClick } = RESOURCES[name];
          return {
            name,
            children,
            icon,
            onClick: () => onActionClick(name, onClick),
          } as QuickActionItemInterface;
        }),
    [RESOURCES, onActionClick, order.quickActions],
  );

  if (order.quickActions?.length < 1) return null;

  const [suggestedAction, ...otherActions] = quickActionsAvailable;

  const handleCancelOrder = (data) => {
    cancelOrder(order.id, data);
    closeCancelModal();
  };

  return (
    <>
      <Popover
        content={
          <Box display="flex" flexDirection="column" width="100%">
            <Menu.Section>
              <QuickActionsPopoverActionItem quickAction={suggestedAction} />
              {otherActions.length > 0 && <hr />}
              {otherActions.map((quickAction) => (
                <QuickActionsPopoverActionItem
                  key={quickAction.name}
                  quickAction={quickAction}
                />
              ))}
            </Menu.Section>
          </Box>
        }
        arrow={false}
        position="bottom-end"
        offset={0}
        padding="small"
        visible={popover}
        onVisibility={(current) => (current ? showPopover() : hidePopover())}
      >
        <IconButton size="2rem" source={<EllipsisIcon size="small" />} />
      </Popover>

      {showModal && (
        <FulfillOrdersModal
          show={showModal}
          orderIds={[order.id]}
          isLoading={false}
          onConfirm={handleComfirmTrackingNum}
          onClose={closeModal}
          ordersMissingTracking={ordersMissingTracking}
          packagesCount={packagesCount}
          hasFulfillments={hasFulfillments}
          fulfillmentsForModal={fulfillmentsForModal}
          isSingleOrder
        />
      )}

      {showCancelModal && (
        <SingleOrderCancelModal
          orderId={order.number}
          canBeRefunded={canBeRefundedThroughCancelForm()}
          amount={order.total}
          gateway={order.payment.gateway || ''}
          show
          hideModal={closeCancelModal}
          isPaid={
            order.payment.status === Status.PAID && order.status === Status.OPEN
          }
          appliedAction={handleCancelOrder}
          isLoading={false}
        />
      )}
    </>
  );
}

export default QuickActionsPopover;
