import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { InterfaceNameValue } from '@tiendanube/components';
import { Stack } from 'commons/components';
import { InterfaceNameBooleanValue } from 'commons/types';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import {
  Client,
  Quantity,
  QuantityValuesInterface,
  DatesValuesInterface,
  QuantityErrorsInterface,
  DatesErrorsInterface,
  Dates,
  ValueCart,
  Active,
  LimitsSkeleton,
  ClientErrorsInterface,
  ClientValuesInterface,
} from './components';
import { DiscountCombination } from './components/DiscountCombination';
import { CouponValuesInterface } from '../../types';

export interface LimitsValuesInterface
  extends QuantityValuesInterface,
    DatesValuesInterface,
    ClientValuesInterface {
  minPrice: string;
  combinesWithOtherDiscounts: boolean;
}

export interface LimitsErrorsInterface
  extends QuantityErrorsInterface,
    DatesErrorsInterface,
    ClientErrorsInterface {}

interface LimitsProps {
  values: CouponValuesInterface;
  errors: Partial<LimitsErrorsInterface>;
  onChange: ({
    value,
    name,
  }: InterfaceNameValue | InterfaceNameBooleanValue) => void;
  showActiveInput: boolean;
  currentUses: number;
  disabled?: boolean;
}

export function Limits({
  values,
  errors,
  onChange,
  showActiveInput,
  currentUses,
  disabled = false,
}: Readonly<LimitsProps>) {
  const t = useTranslationMarketing();

  return (
    <Card>
      <Card.Header>
        <Stack spacing="none" justify="space-between" align="center">
          <Stack spacing="tight">
            <Title as="h4">{t('coupons.couponForm.limits.title')}</Title>
            <Text>{t('coupons.couponForm.limits.optional')}</Text>
          </Stack>
        </Stack>
      </Card.Header>
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="6">
          <Text>{t('coupons.couponForm.limits.info')}</Text>
          <DiscountCombination
            values={values}
            onChange={onChange}
            disabled={disabled}
          />
          <Box display="flex" flexDirection="column" gap="6">
            {showActiveInput && (
              <Active
                value={values.active}
                onChange={onChange}
                disabled={disabled}
              />
            )}
            <Quantity
              values={values}
              errors={errors}
              onChange={onChange}
              currentUses={currentUses}
              disabled={disabled}
            />
            <Client
              values={values}
              errors={errors}
              onChange={onChange}
              disabled={disabled}
            />
            <Dates
              values={values}
              errors={errors}
              onChange={onChange}
              disabled={disabled}
            />
            <ValueCart
              value={values.minPrice}
              onChange={onChange}
              disabled={disabled}
            />
          </Box>
        </Box>
      </Card.Body>
    </Card>
  );
}

Limits.Skeleton = LimitsSkeleton;
