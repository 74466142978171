import { Text } from '@nimbus-ds/components';
import { CameraIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { Thumbnail } from '@tiendanube/components';
import Blur from 'commons/components/Blur';
import Stack from 'commons/components/Stack';
import { formatCurrency } from 'commons/utils';
import { useGetCurrency } from 'domains/Auth/hooks';
import { IMAGE_WIDTH } from '../../utils';

interface StatsProductsItemPlaceholderProps {
  totalType?: 'number' | 'currency';
}

function StatsProductsItemPlaceholder({
  totalType,
}: Readonly<StatsProductsItemPlaceholderProps>) {
  const { code: currencyCode } = useGetCurrency();
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Stack column align="stretch" spacing="tight">
      <Stack justify="space-between" spacing="tight" align="stretch">
        <Stack justify="center">
          <Thumbnail.Empty
            aspectRatio="1-1"
            width={IMAGE_WIDTH}
            placeholderIcon={CameraIcon}
          />

          <Text color="primary-interactive">
            <Blur>Xxxxxxx xxx xxxxxx</Blur>
          </Text>
        </Stack>

        <Stack column justify="center">
          <Blur>
            {totalType === 'currency' ? (
              <Text color="neutral-textDisabled">
                {formatCurrency(123.45, currencyCode, language).replaceAll(
                  /\d/g,
                  'x',
                )}
              </Text>
            ) : (
              <Text color="neutral-textDisabled">xxx</Text>
            )}
          </Blur>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default StatsProductsItemPlaceholder;
