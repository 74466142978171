import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Title } from '@nimbus-ds/components';
import { TagIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { Input, Stack, Chip, Text } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import { useNavegate } from 'App/hooks';
import {
  HeaderTop,
  IconSaveLink,
  VirtualInteractiveListSkeleton,
} from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import { catalogRoutes } from 'domains/Catalog';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import SelectProductListItem from './SelectProductListItem';
import { SelectProductListProps } from './types';
import useSelectProductsList from './useSelectProductsList';
import SelectProductEmptySearch from '../SelectProductEmptySearch';
import './SelectProductList.scss';

interface EmptyOrSearchStateProps {
  title?: string;
  text?: string;
  ctaText?: string;
  onClose: () => void;
}

function EmptyOrSearchState({
  title,
  text,
  ctaText,
  onClose,
}: EmptyOrSearchStateProps) {
  const { goTo } = useNavegate();
  const goToCreateProductPage = () => {
    onClose();
    goTo(catalogRoutes.newProduct);
  };

  return title ? (
    <div className="stratus-empty-product-modal">
      <EmptyMessage
        {...{ title, text }}
        icon={<TagIcon size="large" />}
        actions={
          <Button appearance="primary" onClick={goToCreateProductPage}>
            {ctaText}
          </Button>
        }
      />
    </div>
  ) : (
    <SelectProductEmptySearch />
  );
}

function SelectProductList({
  title,
  subtitle,
  isShow,
  onClose,
  onChange,
  selectedProducts,
  limit,
  trimTitle = false,
  addItemsToTop = false,
  sortBy = null,
  emptyTitle,
  emptyText,
  ctaText,
  refreshProducts,
  setRefreshProducts,
  excludedProductIds = [],
}: SelectProductListProps): JSX.Element {
  const t = useTranslationCatalog();
  const [searchValue, setSearchValue] = useState('');
  const {
    products,
    isLoading,
    isInternalServerError,
    isSuccess,
    fetchDraftOrderProducts,
    getMoreDraftOrdersProducts,
    isRefreshing,
  } = useSelectProductsList({
    sortBy,
  });

  const filteredProducts = useMemo(() => {
    if (excludedProductIds.length > 0) {
      return products?.filter(
        (product) => !excludedProductIds.includes(product.id),
      );
    }
    return products;
  }, [products, excludedProductIds]);

  useToastStatus({
    error: t('products.selectProductList.internalServerError'),
    status: isInternalServerError ? 'error' : 'idle',
  });

  useEffect(() => {
    const debouncedSearch = setTimeout(() => {
      fetchDraftOrderProducts(searchValue);
    }, 300);
    setRefreshProducts?.(false);
    return () => clearTimeout(debouncedSearch);
  }, [
    searchValue,
    fetchDraftOrderProducts,
    refreshProducts,
    setRefreshProducts,
  ]);

  const handleOnChange = ({ value }: { value: string }) => {
    setSearchValue(value);
  };

  const handleOnClearSelecteds = () => onChange([]);

  const handleOnSubmit = ({ value }) => {
    setSearchValue(value);
    fetchDraftOrderProducts(value);
  };

  const handleOnSelect = ({ name, checked }) => {
    if (checked) {
      const product = filteredProducts?.filter(
        (product) => product.id === name,
      );
      onChange(
        addItemsToTop
          ? [...(product || []), ...selectedProducts]
          : [...selectedProducts, ...(product || [])],
      );
    } else {
      onChange(selectedProducts.filter((product) => product.id !== name));
    }
  };

  const onReached = useCallback(() => {
    getMoreDraftOrdersProducts(searchValue);
  }, [getMoreDraftOrdersProducts, searchValue]);

  const headerNavigation = { onClick: onClose };

  const showSubtitleAndSearch = !(
    searchValue === '' &&
    filteredProducts?.length === 0 &&
    emptyTitle
  );

  const isEmpty = isSuccess && filteredProducts?.length === 0;

  return (
    <ModalAside
      hasVirtualizationContent
      isOpen={isShow}
      onDidDismiss={onClose}
      headerTop={
        <HeaderTop
          navigation={headerNavigation}
          actions={<IconSaveLink onClick={onClose} />}
        />
      }
      headerContent={!isEmpty && <Title fontSize="h2">{title}</Title>}
    >
      <Stack column align="stretch">
        {showSubtitleAndSearch && (
          <>
            {!!subtitle && (
              <Stack.Item>
                <Text> {subtitle} </Text>
              </Stack.Item>
            )}
            <Stack.Item>
              <Input
                type="search"
                name="product"
                placeholder={t('products.selectProductList.placeholder')}
                value={searchValue}
                onChange={handleOnChange}
                onSubmit={handleOnSubmit}
              />
            </Stack.Item>
          </>
        )}
        {selectedProducts.length > 0 && (
          <Stack.Item>
            <Chip
              id="SelectProductListChip"
              label={`${t('products.selectProductList.selected', {
                count: selectedProducts.length,
              })}`}
              onDismiss={handleOnClearSelecteds}
            />
          </Stack.Item>
        )}
        <Stack.Item>
          {isLoading && <VirtualInteractiveListSkeleton />}
          {isEmpty && searchValue !== '' && <SelectProductEmptySearch />}
          {isEmpty && searchValue === '' && (
            <EmptyOrSearchState
              title={emptyTitle}
              text={emptyText}
              ctaText={ctaText}
              onClose={onClose}
            />
          )}
          {isSuccess && filteredProducts && (
            <SelectProductListItem
              products={filteredProducts}
              onChange={handleOnSelect}
              onReached={onReached}
              reachedLimit={limit ? selectedProducts.length >= limit : false}
              selectedProducts={selectedProducts}
              isRefreshing={isRefreshing}
              trimTitle={trimTitle}
            />
          )}
        </Stack.Item>
      </Stack>
    </ModalAside>
  );
}

export default SelectProductList;
