import { Box, Chip, Text } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Stack } from 'commons/components';
import { ClearFiltersButton } from 'commons/components/SearchFilterAppliedFilters/ClearFiltersButton';
import { FilterChip } from '../../ExportCard';

interface FilterChipsProps {
  filters: FilterChip[];
  showClearFiltersButton?: boolean;
  onRemoveFilter: (id: string) => void;
  onOpenFilters: () => void;
  onClearFilterButtonClick?: () => void;
}

function FilterChips({
  filters,
  showClearFiltersButton = false,
  onRemoveFilter,
  onClearFilterButtonClick,
}: FilterChipsProps): JSX.Element {
  const { t } = useTranslation('common');

  const handleClearFilterButtonClick = () => {
    onClearFilterButtonClick?.();
  };

  return (
    <Stack column align="stretch" spacing="tight">
      <Stack.Item>
        <Text>{t('exportCard.filteringBy')}</Text>
      </Stack.Item>
      <Stack.Item>
        <Box display="flex" flexWrap="wrap" alignItems="center">
          {filters.map((filter) => (
            <Box key={filter.id} marginRight="2" marginBottom="2">
              <Chip
                text={filter.label}
                removable={!filter.notRemovable}
                onClick={() => onRemoveFilter(filter.id)}
              />
            </Box>
          ))}
          {showClearFiltersButton && filters.length > 1 && (
            <Box marginBottom="2">
              <ClearFiltersButton onClick={handleClearFilterButtonClick} />
            </Box>
          )}
        </Box>
      </Stack.Item>
    </Stack>
  );
}

export default FilterChips;
