import { Text } from '@nimbus-ds/components';
import { ColorPaletteIcon } from '@nimbus-ds/icons';
import { Stack } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { ColorTile } from './components';

const colors = [
  'none',
  '#FFFFFF',
  '#F5F5F5',
  '#E5E3E3',
  '#DCD7D3',
  '#FECDCE',
  '#DE432C',
  '#000000',
];

interface ColorPickerProps {
  onSelect: (colorCode: string) => void;
  selectedColor?: string;
}

function ColorPicker({
  onSelect,
  selectedColor = undefined,
}: Readonly<ColorPickerProps>) {
  const t = useTranslationCatalog(
    'products.modal.editPhoto.actions.changeBackground.modal.colorBg',
  );

  return (
    <Stack column spacing="tight" align="flex-start">
      <Stack spacing="tight" align="center">
        <ColorPaletteIcon />
        <Text fontWeight="bold">{t('label')}</Text>
      </Stack>
      <Stack gap="2" alignSelf="stretch" align="stretch">
        {colors.map((color) => (
          <Stack.Item key={color} fill>
            <ColorTile
              color={color}
              selected={selectedColor === color}
              onClick={() => onSelect(color)}
            />
          </Stack.Item>
        ))}
      </Stack>
    </Stack>
  );
}

export default ColorPicker;
