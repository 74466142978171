import { useContext } from 'react';
import { Skeleton, Alert, Text, Card } from '@nimbus-ds/components';
import { RocketIcon } from '@nimbus-ds/icons';
import { CalloutCard, Layout } from '@nimbus-ds/patterns';
import { DownloadCsvTemplateResponseDto } from '@tiendanube/common';
import { Button } from '@tiendanube/components';
import { UploadIcon } from '@tiendanube/icons';
import {
  ImportAlterationsModal,
  ImportInProcessModalContext,
  InputFile,
} from 'commons/components';
import { useTranslationCommon } from 'commons/hooks';
import { useImportCsv } from 'commons/hooks/useImportCsv';
import { useImportMaxLines } from 'commons/hooks/useImportMaxLines';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import {
  DownloadTemplateLink,
  ExistingModal,
  ExtraOptionsProps,
  LinesExceededModal,
  UpdateOptions,
} from './components';

const APPEARANCE_ALERT = {
  failed: 'warning',
  pending: 'primary',
  ready: 'success',
};

type FetchTemplateCsvHandler = () => Promise<DownloadCsvTemplateResponseDto>;
type UseImportCsv = () => ReturnType<typeof useImportCsv>;
type UseImportMaxLines = () => ReturnType<typeof useImportMaxLines>;

type EntityType = 'products' | 'customers';

interface ImportCustomersCardProps {
  entity: EntityType;
  showUpdateOptions: boolean;
  entitiesName: string;
  fetchTemplateCsvHandler: FetchTemplateCsvHandler;
  useImportCsvWrapper: UseImportCsv;
  useImportMaxLinesWrapper: UseImportMaxLines;
  isAvailable: boolean;
  handleUpsellCallout?: () => void;
  extraOptions?: (props: ExtraOptionsProps) => React.ReactNode;
}

export function ImportCsvCard({
  entity,
  showUpdateOptions,
  entitiesName,
  fetchTemplateCsvHandler,
  useImportCsvWrapper,
  useImportMaxLinesWrapper,
  isAvailable,
  handleUpsellCallout,
  extraOptions,
}: ImportCustomersCardProps) {
  const t = useTranslationCommon('importCsv.import');
  const isMobileDevice = useIsMobileDevice();
  const {
    updateOptions,
    showImportAlterationsModal,
    showExistingModal,
    jobStatus,
    cleanJob,
    onChangeFile,
    onRemoveFile,
    onRetry,
    status,
    fileName,
    badColumns,
    columnOptions,
    matchColumns,
    setMatchingColumns,
    ignoreAllColumns,
    undoIgnoreAllColumns,
    isLoadingCsv,
    isErrorCsv,
    isLoadingImport,
    isErrorImport,
    onUpdateOptionsChange,
    onCheckExisting,
    onConfirm,
    onImport,
    closeShowImportAlterationsModal,
    closeShowExistingModal,
  } = useImportCsvWrapper();
  const { maxLines, loadingMaxLines } = useImportMaxLinesWrapper();
  const descriptionKey = showUpdateOptions
    ? isMobileDevice
      ? 'descriptionMobile'
      : 'description'
    : 'descriptionImportOnly';
  const showConfirmationModal = useContext(ImportInProcessModalContext);

  const handleShowConfirmation = (file: File) => {
    if (jobStatus === 'pending') {
      showConfirmationModal((isConfirmed) => {
        if (isConfirmed) {
          onChangeFile?.(file);
        } else {
          onRemoveFile();
        }
      });
    } else {
      onChangeFile?.(file);
    }
  };

  const isCustomerImport = entity === 'customers';
  const disabledButton =
    (isCustomerImport && !updateOptions.acceptLegalRequirements) ||
    isLoadingCsv ||
    isErrorCsv ||
    !fileName ||
    status !== 'Complete' ||
    isLoadingImport ||
    isErrorImport;

  const isError = ['FormatError', 'SizeError', 'ReadingError'].includes(status);

  const errorMessage = {
    FormatError: t('uploadFile.errorFormat'),
    SizeError: t('uploadFile.errorSize'),
    ReadingError: t('error'),
  };

  const handleDismissAlert =
    jobStatus === 'ready' || jobStatus === 'failed' ? cleanJob : undefined;

  return (
    <>
      <Card>
        <Card.Body padding="base">
          <Layout columns="1">
            <Layout.Section>
              <Alert
                onRemove={handleDismissAlert}
                show={jobStatus !== 'idle'}
                title={t(`alert.title.${jobStatus}`)}
                appearance={APPEARANCE_ALERT[jobStatus]}
              >
                {t(`alert.description.${jobStatus}`)}
              </Alert>
            </Layout.Section>
            <Layout.Section>
              <Text>
                {t(descriptionKey, {
                  max: maxLines,
                })}
              </Text>
            </Layout.Section>
            <Layout.Section>
              {!loadingMaxLines && !isAvailable && (
                <CalloutCard
                  appearance="primary"
                  title={t('upsell.calloutTitle', { entities: entitiesName })}
                  subtitle={t('upsell.calloutSubtitle')}
                  icon={RocketIcon}
                  onClick={handleUpsellCallout}
                />
              )}
              <DownloadTemplateLink
                fetchTemplateCsvHandler={fetchTemplateCsvHandler}
              />
              {loadingMaxLines && (
                <Skeleton width="100%" height="68px" borderRadius="0.5rem" />
              )}
              {!loadingMaxLines && (
                <InputFile
                  fullWidth
                  fileName={fileName}
                  isError={isError}
                  isErrorRetry={isErrorCsv}
                  isLoading={isLoadingCsv || status === 'Loading'}
                  onRetry={onRetry}
                  disabled={!isAvailable}
                  onChangeFile={handleShowConfirmation}
                  onRemoveFile={onRemoveFile}
                  accept=".csv"
                  helperText={t('uploadFile.description')}
                  errorText={errorMessage[status] ?? undefined}
                />
              )}
              {(showUpdateOptions || extraOptions) && (
                <UpdateOptions
                  disabled={isLoadingImport}
                  options={updateOptions}
                  onChange={onUpdateOptionsChange}
                  entitiesName={entitiesName}
                  showUpdateOptions={showUpdateOptions}
                  extraOptions={extraOptions}
                />
              )}
            </Layout.Section>
          </Layout>
        </Card.Body>
        <Card.Footer padding="base">
          <Button
            appearance="primary"
            icon={UploadIcon}
            iconPosition="start"
            onClick={showUpdateOptions ? onCheckExisting : onConfirm}
            disabled={disabledButton}
            spinner={isLoadingImport}
          >
            {isLoadingImport ? t('importing') : t('import')}
          </Button>
        </Card.Footer>
      </Card>
      <ExistingModal
        show={showExistingModal}
        onDismiss={closeShowExistingModal}
        onAccept={onConfirm}
        entitiesName={entitiesName}
      />
      <ImportAlterationsModal
        badColumns={badColumns}
        columnOptions={columnOptions}
        show={showImportAlterationsModal}
        onConfirm={onImport}
        onClose={closeShowImportAlterationsModal}
        matchColumns={matchColumns}
        setMatchingColumns={setMatchingColumns}
        ignoreAllColumns={ignoreAllColumns}
        undoIgnoreAllColumns={undoIgnoreAllColumns}
      />
      <LinesExceededModal
        open={status === 'LinesExceededError'}
        onDismiss={onRemoveFile}
        maxLines={maxLines}
      />
    </>
  );
}
