import { Box, Checkbox } from '@nimbus-ds/components';
import { ItemProps } from 'domains/Metafields/components/MetafieldInput/types';
import useMergeMetafieldsEvents from 'domains/Metafields/hooks/useMergeMetafieldsEvents';

export default function CheckboxItem({
  metafield,
  metafieldKey,
  disabled,
  value,
  onUpdate,
}: ItemProps) {
  const mergeEvents = useMergeMetafieldsEvents(
    {},
    metafield.formFieldConfig?.events,
  );
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const { onChange: onFormFieldConfigChange = () => {}, ...mergedEvents } =
    mergeEvents();

  return (
    <Box display="flex">
      {metafield.values?.map((metafieldValue: string, index: number) => {
        const keyValue = `${metafieldKey}-${index}`;
        return (
          <Checkbox
            disabled={disabled}
            key={keyValue}
            id={keyValue}
            name={metafieldKey}
            label={metafieldValue}
            checked={value === metafieldValue}
            onChange={(e) => {
              onUpdate?.(e.target.checked ? metafieldValue : '');
              onFormFieldConfigChange(e);
            }}
            {...mergedEvents}
          />
        );
      })}
    </Box>
  );
}
