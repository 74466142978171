import { ReactNode } from 'react';
import { Box } from '@nimbus-ds/components';
import { BaseCard, Title } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import './DoubleCardResponsive.scss';

interface DoubleCardResponsiveProps {
  left: ReactNode;
  right: ReactNode;
  title?: string;
  rightTitle?: string;
  equalWidth?: boolean;
}

function DoubleCardResponsive({
  left,
  right,
  title,
  rightTitle = '',
  equalWidth = false,
}: DoubleCardResponsiveProps) {
  return (
    <BaseCard>
      <Box
        display="grid"
        gridTemplateColumns="repeat(8, 1fr)"
        gridTemplateRows="repeat(1, 1fr)"
        gridTemplateAreas={{
          lg: `"left left left left ${
            equalWidth ? 'right' : 'left'
          } right right right"`,

          md: `"left left left left left left left left" "right right right right right right right right"`,
          xs: `"left left left left left left left left" "right right right right right right right right"`,
        }}
      >
        <Box gridArea="left" paddingBottom="4">
          <BaseCard.Header>
            {!!title && <Title type="h3">{title}</Title>}
          </BaseCard.Header>
          <BaseCard.Body padding="none">{left}</BaseCard.Body>
        </Box>
        <Box
          gridArea="right"
          maxHeight={{
            lg: '420px',
          }}
          overflow="auto"
        >
          <BaseCard.Header>
            {!!rightTitle && <Text>{rightTitle}</Text>}
          </BaseCard.Header>
          <BaseCard.Body padding="none">{right}</BaseCard.Body>
        </Box>
      </Box>
    </BaseCard>
  );
}

export default DoubleCardResponsive;
