import { useTranslation } from 'react-i18next';
import { PlansListResponseDto } from '@tiendanube/common';
import { DataTable, Title } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
import { formatCurrency } from 'commons/utils';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { useTranslationBilling } from 'domains/Billing';
import { ComparisonTableDesktopRow } from './components';
import ComparisonTableDesktopFooter from './components/ComparisonTableDesktopFooter';
import { SelectedPlan } from '../../../ComparisonPlansCards/components/PlanCard/types';

const { Header, Cell } = DataTable;

interface ComparisonTableDesktopProps {
  features: string[];
  plansList: PlansListResponseDto[];
  onSelectPlan: (plan: SelectedPlan) => void;
  currentPlanId: number;
  currentPlanLevel: number;
}

function ComparisonTableDesktop({
  features,
  plansList,
  onSelectPlan,
  currentPlanId,
  currentPlanLevel,
}: ComparisonTableDesktopProps): JSX.Element {
  const t = useTranslationBilling();
  const isMobile = useIsMobileDevice();

  const {
    i18n: { language },
  } = useTranslation();

  const firstPlan = plansList[0];
  const secondPlan = plansList[1];
  const thirdPlan = plansList[2];
  const fourthPlan = plansList[3];

  const formatPrice = (value: number, currency: string): string =>
    formatCurrency(value, currency, language, true);

  return (
    <Stack column align="stretch">
      <Stack.Item>
        <Title type="h3">{`${t(
          'billingPlansPage.comparisonTable.title',
        )}`}</Title>
      </Stack.Item>
      <Stack.Item>
        <DataTable>
          <Header>
            <Cell grow={0} basis={360}>
              <Text block fontWeight="medium">
                {t('billingPlansPage.comparisonTable.features')}
              </Text>
            </Cell>
            <Cell>
              <Text block fontWeight="medium" textAlign="center">
                {firstPlan.name}
              </Text>
            </Cell>
            <Cell>
              <Text block fontWeight="medium" textAlign="center">
                {secondPlan.name}
              </Text>
            </Cell>
            <Cell>
              <Text block fontWeight="medium" textAlign="center">
                {thirdPlan.name}
              </Text>
            </Cell>
            {!fourthPlan.isNextPlan && (
              <Cell>
                <Text block fontWeight="medium" textAlign="center">
                  {fourthPlan.name}
                </Text>
              </Cell>
            )}
          </Header>
          <ComparisonTableDesktopRow
            id={0}
            feature={t('billingPlansPage.comparisonTable.monthlyCost')}
            firstValue={formatPrice(
              firstPlan.price.value,
              firstPlan.price.currency,
            )}
            secondValue={formatPrice(
              secondPlan.price.value,
              secondPlan.price.currency,
            )}
            thirdValue={formatPrice(
              thirdPlan.price.value,
              thirdPlan.price.currency,
            )}
            fourthValue={
              !fourthPlan.isNextPlan
                ? formatPrice(fourthPlan.price.value, fourthPlan.price.currency)
                : undefined
            }
            bold
          />

          {features.map((feature, index) => {
            const fourthValue = !fourthPlan.isNextPlan
              ? fourthPlan.features[index]
              : undefined;
            return (
              <ComparisonTableDesktopRow
                key={index}
                id={index + 2}
                feature={feature}
                firstValue={firstPlan.features[index]}
                secondValue={secondPlan.features[index]}
                thirdValue={thirdPlan.features[index]}
                fourthValue={fourthValue}
              />
            );
          })}
          {!isMobile && (
            <ComparisonTableDesktopFooter
              currentPlanId={currentPlanId}
              currentPlanLevel={currentPlanLevel}
              firstPlan={firstPlan}
              secondPlan={secondPlan}
              thirdPlan={thirdPlan}
              fourthPlan={fourthPlan}
              onSelectPlan={onSelectPlan}
            />
          )}
        </DataTable>
        <br />
      </Stack.Item>
    </Stack>
  );
}

export default ComparisonTableDesktop;
