import { Box } from '@nimbus-ds/components';
import {
  ConceptCode,
  CurrencyType,
  PaymentFeeDto,
  TaxDto,
} from '@tiendanube/common';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { ChargeDataRows, TaxAlert } from './components';
import { getTaxesSummary } from './utils';

export interface ChargeDisplayInterface {
  description: string;
  amountValue: number;
  amountBaseValue?: number;
  amountCurrency: CurrencyType;
  appliedDiscounts?: {
    description: string;
    amountValue: number;
    amountCurrency: CurrencyType;
    rate: number;
    type?: string;
  }[];
  taxes?: {
    taxCode: string;
    amountValue: number;
    amountCurrency: CurrencyType;
    taxRate: number;
  }[];
  recurringInterval?: number;
  recurringFrequency?: string;
  explanation?: {
    message: string;
    link: string;
  };
  conceptCode: ConceptCode;
  fromDate?: string;
  toDate?: string;
  isProportional?: boolean;
}

interface PaymentDataContentProps {
  showTaxes?: boolean;
  charges: ChargeDisplayInterface[];
  extraFees?: PaymentFeeDto[];
}

function transformExtraFeesToChargeDisplay(
  description: string,
  fees?: PaymentFeeDto[],
): [ChargeDisplayInterface[], TaxDto[]] {
  if (!fees) return [[], []];
  const charges: ChargeDisplayInterface[] = [];
  let taxes: TaxDto[] = [];

  fees.forEach((fee) => {
    taxes = taxes.concat(fee.taxes);
    charges.push({
      amountValue: fee.amountValue,
      amountCurrency: fee.amountCurrency,
      conceptCode: fee.concept.code,
      description,
    });
  });

  return [charges, taxes];
}

function PaymentDataContent({
  showTaxes = false,
  charges,
  extraFees,
}: PaymentDataContentProps): JSX.Element {
  const t = useTranslationBilling('checkout.paymentDataContent');

  const [extraFeeCharges, extraFeeTaxes] = transformExtraFeesToChargeDisplay(
    t('extraFeeDescription'),
    extraFees,
  );

  return (
    <Box display="flex" flexDirection="column" gap="4">
      {charges &&
        [...charges, ...extraFeeCharges].map((charge) => (
          <ChargeDataRows
            key={charge.description + charge.conceptCode}
            charge={charge}
          />
        ))}
      {showTaxes ? (
        getTaxesSummary(charges, extraFeeTaxes, t).map((tax) => (
          <ChargeDataRows key={tax.description} charge={tax} />
        ))
      ) : (
        <TaxAlert />
      )}
    </Box>
  );
}

export default PaymentDataContent;
