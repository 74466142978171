import { Modal } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import { useTranslationAccount } from 'domains/Account/hooks';
import { useStoreName } from 'domains/Auth/hooks';

interface DeleteAccountConfirmationModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLoading: boolean;
}

function DeleteAccountConfirmationModal({
  show,
  onClose,
  onConfirm,
  isLoading,
}: DeleteAccountConfirmationModalProps): JSX.Element {
  const t = useTranslationAccount();
  const storeName = useStoreName();

  return (
    <Modal
      onDismiss={onClose}
      title={t('shopCancellationPage.stepThird.important')}
      show={show}
      primaryAction={{
        children: `${t('shopCancellationPage.stepThird.deleteAccount')}`,
        onClick: onConfirm,
        appearance: 'danger',
        disabled: isLoading,
      }}
      secondaryAction={{
        children: `${t('shopCancellationPage.cancelButton')}`,
        onClick: onClose,
      }}
    >
      <Text>{t('shopCancellationPage.stepThird.warning', { storeName })}</Text>
      <Text>{t('shopCancellationPage.stepThird.willMissYou')}</Text>
    </Modal>
  );
}

export default DeleteAccountConfirmationModal;
