import { useSelector } from 'react-redux';
import {
  isUserLogin,
  isAuthInit,
  isAuthCodeError as isAuthCodeErrorSelector,
  getAccessToken,
} from '../../authSlice/authSelectors';

interface InterfaceUseIsLogged {
  isLogin: boolean;
  isInit: boolean;
  isAuthCodeError: boolean;
  accessToken: string | undefined;
}

function useIsLogged(): InterfaceUseIsLogged {
  const isLogin = useSelector(isUserLogin);
  const isInit = useSelector(isAuthInit);
  const isAuthCodeError = useSelector(isAuthCodeErrorSelector);
  const accessToken = useSelector(getAccessToken);
  return { isLogin, isInit, isAuthCodeError, accessToken };
}

export default useIsLogged;
