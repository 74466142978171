import { Card, Input } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function RangeSkeleton(): JSX.Element {
  const t = useTranslationShipping(
    'deliveryMethods.pickupPoints.form.secondStep.range',
  );

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <Stack column align="stretch">
          <FormField label={t('label')}>
            <Input.Skeleton />
          </FormField>
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default RangeSkeleton;
