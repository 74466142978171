import { Box } from '@nimbus-ds/components';
import { BaseCard } from '@tiendanube/components';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { PlanCardBody, PlanCardHeader, PosExtraInfo } from './components';
import { PlanCardProps } from './types';
import './PlanCard.scss';
import ButtonPlan from '../ButtonPlan';

function PlanCard({
  currentPlan = false,
  title,
  description,
  price,
  frequencyPrice,
  mainFeatures,
  posExtraInfo,
  isNextPlan,
  isFree,
  plan,
  currentPlanId,
  currentPlanLevel,
  onSelectPlan,
}: PlanCardProps): JSX.Element | null {
  const isMobile = useIsMobileDevice();

  const shouldRenderButton =
    !isMobile &&
    plan &&
    !!currentPlanId &&
    currentPlanLevel !== undefined &&
    currentPlanLevel !== null &&
    onSelectPlan;

  return (
    <div className={`stratus--plan-card ${isNextPlan && 'dark'}`}>
      <BaseCard>
        <PlanCardHeader
          title={title}
          description={description}
          price={price}
          frequencyPrice={frequencyPrice}
          currentPlan={currentPlan}
          isNextPlan={isNextPlan}
          isFree={isFree}
        />
        <hr />
        <PlanCardBody mainFeatures={mainFeatures} />
        <BaseCard.Footer>
          <Box display="flex" flexDirection="column" gap="3">
            {posExtraInfo && <PosExtraInfo posExtraInfo={posExtraInfo} />}
            {shouldRenderButton && (
              <ButtonPlan
                plan={plan}
                currentPlanId={currentPlanId}
                currentPlanLevel={currentPlanLevel}
                onSelectPlan={onSelectPlan}
              />
            )}
          </Box>
        </BaseCard.Footer>
      </BaseCard>
    </div>
  );
}

export default PlanCard;
