import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  DOMAIN_NS_UPDATED_CORRECT,
  DOMAIN_REQUIRED_UPDATE,
  DOMAIN_REQUIRED_UPDATE_SOFTBLOCK,
} from 'App/featuresFlags';
import { useDismissibleComponent } from 'commons/hooks';
import { useCanEnterAdmin, useGetTags } from 'domains/Auth/hooks';
import {
  useLazyGetDomainSoftBlockQuery,
  useSetDomainToNotifyMutation,
} from '../domainsApi';
import { trackingSoftblockNameserverViewed } from '../tracking';

const noop = () => null;

export interface DomainSoftBlockContext {
  domainSoftBlock: boolean;
  dismissSoftblock: () => void;
  notifyDomainUpdate: () => void;
  shouldShowInDomainPage: boolean;
  dismissInDomainPage: (reset: boolean) => void;
}

export interface InterfaceDomainSoftBlockContextProvider {
  children: ReactNode;
}

export const UpsellSoftBlockContext = createContext<DomainSoftBlockContext>({
  domainSoftBlock: false,
  shouldShowInDomainPage: false,
  dismissSoftblock: noop,
  notifyDomainUpdate: noop,
  dismissInDomainPage: noop,
});

function DomainSoftBlockContextProvider({
  children,
}: Readonly<InterfaceDomainSoftBlockContextProvider>) {
  const [getData, { data, isSuccess }] = useLazyGetDomainSoftBlockQuery();
  const [notifyDomainStore] = useSetDomainToNotifyMutation();
  const [canShowInDomainPage, setCanShowInDomainPage] = useState(true);
  const canEnterAdmin = useCanEnterAdmin();
  const tags = useGetTags();

  const hasMigrationTags = useMemo(
    () =>
      (tags.includes(DOMAIN_REQUIRED_UPDATE) ||
        tags.includes(DOMAIN_REQUIRED_UPDATE_SOFTBLOCK)) &&
      !tags.includes(DOMAIN_NS_UPDATED_CORRECT),
    [tags],
  );

  const { shouldShow: softblockNotDisimissed, dismiss: dismissSoftblock } =
    useDismissibleComponent('domain-soft-block-dismiss', 1);

  const notifyDomainUpdate = useCallback(() => {
    dismissSoftblock();
    notifyDomainStore();
  }, [dismissSoftblock, notifyDomainStore]);

  const dismissInDomainPage = useCallback(
    (reset: boolean) => {
      setCanShowInDomainPage(reset);
    },
    [setCanShowInDomainPage],
  );

  const domainSoftBlock = useMemo(() => {
    if (!hasMigrationTags || !isSuccess || !data) return false;
    return softblockNotDisimissed;
  }, [data, isSuccess, softblockNotDisimissed, hasMigrationTags]);

  const isErrorScreen = !!data?.error;
  const shouldShowInDomainPage =
    !!data && softblockNotDisimissed && canShowInDomainPage;

  useEffect(() => {
    if (domainSoftBlock && data?.storeId) {
      trackingSoftblockNameserverViewed(data.storeId, 'login', isErrorScreen);
    }
  }, [shouldShowInDomainPage, domainSoftBlock, data?.storeId, isErrorScreen]);

  useEffect(() => {
    if (hasMigrationTags && canEnterAdmin && softblockNotDisimissed) {
      getData();
    }
  }, [canEnterAdmin, getData, softblockNotDisimissed, hasMigrationTags]);

  const values = useMemo(
    () => ({
      domainSoftBlock,
      notifyDomainUpdate,
      dismissSoftblock,
      shouldShowInDomainPage,
      dismissInDomainPage,
    }),
    [
      dismissSoftblock,
      domainSoftBlock,
      notifyDomainUpdate,
      shouldShowInDomainPage,
      dismissInDomainPage,
    ],
  );

  return (
    <UpsellSoftBlockContext.Provider value={values}>
      {children}
    </UpsellSoftBlockContext.Provider>
  );
}

export default DomainSoftBlockContextProvider;
