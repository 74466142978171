import { CorreiosContractsTypeResponseDto } from '@tiendanube/common';
import { Input, Select, InterfaceNameValue } from '@tiendanube/components';
import { CardWithTitle, Stack } from 'commons/components';
import Text from 'commons/components/LegacyText';
import { useAutoFocus } from 'commons/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import Skeleton from './Skeleton';

interface ContractCardProps {
  codeAccount: string;
  password: string;
  contractTypeCode: string;
  codeAccountError?: string;
  passwordError?: string;
  contractTypeCodeError?: string;
  onChange: (event: InterfaceNameValue) => void;
  availableContractTypes: CorreiosContractsTypeResponseDto[];
  onChangeContractType: (event: InterfaceNameValue) => void;
}

function ContractCard({
  codeAccount,
  password,
  contractTypeCode,
  codeAccountError,
  passwordError,
  contractTypeCodeError,
  onChange,
  availableContractTypes,
  onChangeContractType,
}: ContractCardProps) {
  const t = useTranslationShipping();
  const { ref } = useAutoFocus();
  const contractTypesOptions = availableContractTypes.map(
    ({ contractName, contractTypeCode }) => ({
      label:
        contractTypeCode === 'no_contract'
          ? t('correios.contract.contractTypeNoContract')
          : contractName,
      value: contractTypeCode,
    }),
  );
  const getTranslatedError = (errorKey) => (errorKey ? t(errorKey) : '');

  return (
    <CardWithTitle title={t('correios.contract.title')}>
      <Stack column align="stretch" innerRef={ref}>
        <Input
          name="codeAccount"
          label={t('correios.contract.inputs.codeAccount')}
          onChange={onChange}
          value={contractTypeCode !== 'no_contract' ? codeAccount : ''}
          disabled={contractTypeCode === 'no_contract'}
          appearance={codeAccountError ? 'validation_error' : 'default'}
          helpText={getTranslatedError(codeAccountError)}
        />

        <Input
          name="password"
          label={t('correios.contract.inputs.password')}
          appearance={passwordError ? 'validation_error' : 'default'}
          disabled={contractTypeCode === 'no_contract'}
          helpText={
            getTranslatedError(passwordError) ??
            t('correios.contract.helpText.password')
          }
          onChange={onChange}
          value={contractTypeCode !== 'no_contract' ? password : ''}
        />

        <Stack column align="stretch" spacing="tight">
          <Select
            name="contractTypeCode"
            options={contractTypesOptions}
            label={t('correios.contract.inputs.contractType')}
            placeholder={t('correios.contract.contractTypePlaceholder')}
            onChange={onChangeContractType}
            value={contractTypeCode}
          />

          {contractTypeCodeError ? (
            <Text appearance="danger" size="caption">
              {getTranslatedError(contractTypeCodeError)}
            </Text>
          ) : (
            <Text size="caption">
              {t('correios.contract.helpText.contractType')}
            </Text>
          )}
        </Stack>
      </Stack>
    </CardWithTitle>
  );
}

ContractCard.Skeleton = Skeleton;

export default ContractCard;
