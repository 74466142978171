/* eslint-disable max-statements */
import { generatePath } from 'react-router-dom';
import { useNavegate } from 'App/hooks';
import { fulfillmentOrdersRoutes } from 'domains/FulfillmentOrders';
import { DocumentType } from '../../constants';

interface GoToPrintPageProps {
  ids: string[];
  type: DocumentType;
  hasOca?: boolean;
  hasOcaAndNotGeneratedTag?: boolean;
  forceOca?: boolean;
}

function useGoToPrintPage() {
  const { goTo } = useNavegate();

  const goToPrintPage = (params: GoToPrintPageProps) => {
    const { ids, type } = params;

    if (
      params.hasOca &&
      params.hasOcaAndNotGeneratedTag &&
      type === DocumentType.LABEL
    ) {
      const routeParams: {
        ids: string;
        overwrite?: string;
      } = {
        ids: ids.join(','),
      };
      if (params.forceOca) {
        routeParams.overwrite = 'overwrite';
      }
      goTo(generatePath(fulfillmentOrdersRoutes['prePrintOca'], routeParams));
      return;
    }

    if (params.hasOca && type === DocumentType.LABEL) {
      goTo(
        generatePath(fulfillmentOrdersRoutes[type], {
          ids: ids.join(','),
          oca: 'oca',
        }),
      );
      return;
    }

    goTo(generatePath(fulfillmentOrdersRoutes[type], { ids: ids.join(',') }));
  };

  return { goToPrintPage };
}

export default useGoToPrintPage;
