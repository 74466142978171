import { Alert, Chip, Text } from '@nimbus-ds/components';
import { EyeOffIcon } from '@nimbus-ds/icons';
import { Stack } from '@tiendanube/components';
import { SelectOrEditLink } from 'commons/components';
import { useModal } from 'commons/hooks';
import {
  isCategoryVisible,
  VisibilityCategoryType,
} from 'domains/Catalog/Products/utils';
import Skeleton from './Skeleton';
import { useTranslationMarketing } from '../../hook';
import { CategoriesModalAside } from '../CategoriesModalAside';

export interface CategoriesInterface {
  id: string;
  description: string;
  visibility?: VisibilityCategoryType;
}

interface CategoriesProps {
  categories: CategoriesInterface[];
  title?: string;
  subtitle?: string;
  requiredText?: string;
  emptyTitle: string;
  emptyText: string;
  ctaText: string;
  showTitle: boolean;
  onChange: (categories: CategoriesInterface[]) => void;
  selectLinkText?: string;
  editLinkText?: string;
  disabled?: boolean;
}

export function Categories({
  categories,
  title,
  subtitle,
  showTitle,
  requiredText,
  emptyTitle,
  emptyText,
  ctaText,
  onChange,
  selectLinkText,
  editLinkText,
  disabled = false,
}: Readonly<CategoriesProps>) {
  const t = useTranslationMarketing('freeShipping.asides.categories');

  const [isShowModal, openModal, closeModal] = useModal();
  const isEmpty = !categories.length;

  const titleOrDefault = title ?? t('title');

  return (
    <>
      {showTitle && <Text fontWeight="bold">{titleOrDefault}</Text>}
      {!isEmpty && (
        <>
          <Stack spacing="tight" wrap>
            {categories.map(({ id, description, visibility = 'visible' }) => (
              <Stack.Item key={`${id}`}>
                <Chip
                  key={id}
                  text={description}
                  removable={!disabled}
                  onClick={() =>
                    onChange(categories.filter((current) => current.id !== id))
                  }
                  icon={!isCategoryVisible({ visibility }) && <EyeOffIcon />}
                />
              </Stack.Item>
            ))}
          </Stack>
          <br />
        </>
      )}
      {!disabled && (
        <>
          <SelectOrEditLink
            isEdit={!isEmpty}
            onSelect={openModal}
            selectLinkText={selectLinkText}
            editLinkText={editLinkText}
          />
          {!!requiredText && <Alert appearance="danger">{requiredText}</Alert>}
          <CategoriesModalAside
            disabled={disabled}
            isOpen={isShowModal}
            selected={categories}
            title={titleOrDefault}
            subtitle={subtitle}
            emptyTitle={emptyTitle}
            emptyText={emptyText}
            ctaText={ctaText}
            onClose={closeModal}
            onChange={onChange}
          />
        </>
      )}
    </>
  );
}

Categories.Skeleton = Skeleton;
