import { BaseCard, Button, Label } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';

function Skeleton(): JSX.Element {
  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack align="stretch" spacing="loose" justify="space-between">
          <Text.Skeleton size="base" width="large" />
          <Label.Skeleton />
        </Stack>
      </BaseCard.Header>
      <BaseCard.Body>
        <Text.Skeleton width="fill" />
        <Text.Skeleton width="fill" />
      </BaseCard.Body>
      <BaseCard.Footer>
        <Button.Skeleton />
      </BaseCard.Footer>
    </BaseCard>
  );
}

export default Skeleton;
