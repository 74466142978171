import { Alert } from '@nimbus-ds/components';
import { TFunction } from 'i18next';

interface BadColumnsAlertProps {
  badColumnsQuantity: number;
  t: TFunction;
}

function BadColumnsAlert({ badColumnsQuantity, t }: BadColumnsAlertProps) {
  return (
    <Alert
      appearance="warning"
      title={t('alterationsAlert.title', {
        count: badColumnsQuantity,
      })}
      show={badColumnsQuantity > 0}
    >
      {t('alterationsAlert.body', {
        count: badColumnsQuantity,
      })}
    </Alert>
  );
}

export default BadColumnsAlert;
