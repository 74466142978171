import {
  GetDocumentSettingsResponseDto,
  InformationForDocumentResponseDto,
  OcaLabelResponseDto,
} from '@tiendanube/common';
import GeneralDocumentPreviewFields from './GeneralDocumentPreviewFields';
import OcaLabelError from '../../../OcaLabelError';

interface DocumentOcaPreviewProps {
  information: (InformationForDocumentResponseDto & {
    ocaLabel: OcaLabelResponseDto;
  })[];
  settings: GetDocumentSettingsResponseDto;
  locationId: string;
}

function DocumentOcaPreview({
  information,
  settings,
  locationId,
}: Readonly<DocumentOcaPreviewProps>) {
  return (
    <>
      {information
        .filter(
          ({ fulfillmentOrder }) =>
            !locationId || fulfillmentOrder.assignedLocation.id === locationId,
        )
        .map(({ order, fulfillmentOrder, store, ocaLabel }) => (
          <div
            key={fulfillmentOrder.number + order.number}
            className="stratus--order-document oca"
          >
            <div className="oca-document">
              <GeneralDocumentPreviewFields
                fulfillmentOrder={fulfillmentOrder}
                order={order}
                settings={settings}
                store={store}
                isOca
              />
            </div>
            {!ocaLabel.error && !!ocaLabel.html && (
              <div
                dangerouslySetInnerHTML={{
                  __html: ocaLabel.html,
                }}
              />
            )}
            {(!!ocaLabel.error || (!ocaLabel.error && !ocaLabel.html)) && (
              <div className="error">
                <OcaLabelError error={ocaLabel.error} />
              </div>
            )}
          </div>
        ))}
    </>
  );
}

export default DocumentOcaPreview;
