import { Box, Chip, Icon, Link } from '@nimbus-ds/components';
import { BoxPackedIcon, EditIcon } from '@nimbus-ds/icons';
import { PriceTableInCard } from 'domains/Customers/Customers/pages/CustomerFormPage/components/CustomerPriceTablesCard/CustomerPriceTablesCard';
import { useTranslationCustomers } from 'domains/Customers/hooks';

interface PriceTablesEditStateProps {
  priceTables: PriceTableInCard[];
  openModal: () => void;
  removePriceTable: (id: string) => void;
}

export default function PriceTablesEditState({
  priceTables,
  openModal,
  removePriceTable,
}: Readonly<PriceTablesEditStateProps>) {
  const t = useTranslationCustomers();
  const tPath = 'customerForm.priceTablesInformation';

  return (
    <Box gap="4" display="flex" flexDirection="column">
      <Box display="flex" gap="1" flexWrap="wrap">
        {priceTables.map((priceTable) => (
          <Chip
            key={priceTable.id}
            icon={<BoxPackedIcon size={12} />}
            text={`${priceTable.name} - ${priceTable.defaultValue}${t(
              `${tPath}.chipInformation`,
            )}`}
            removable
            onClick={() => removePriceTable(priceTable.id)}
          />
        ))}
      </Box>
      <Link
        appearance="primary"
        as="button"
        textDecoration="none"
        type="button"
        onClick={openModal}
      >
        <Icon color="primary-interactive" source={<EditIcon />} />
        {t(`${tPath}.buttonEditLabel`)}
      </Link>
    </Box>
  );
}
