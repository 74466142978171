import { logEvent } from 'commons/utils/tracking';
import {
  NUVEM_ENVIO_CLICK,
  NUVEM_ENVIO_VIEW,
  NUVEMEVIO_SHIPPING_ACTION_LINK,
  NUVEMVIO_JADLOG_WELCOME_PAGE,
} from 'config/trackingEvents';

export const trackJadLogWelcomeButton = (storeId: string) => {
  logEvent(NUVEM_ENVIO_CLICK, {
    storeId,
    page: NUVEMVIO_JADLOG_WELCOME_PAGE,
    action: 'Go to Nuvem Envio dashboard',
  });
};

export const trackJadlogPoints = (storeId: string) => {
  logEvent(NUVEM_ENVIO_CLICK, {
    storeId,
    page: NUVEMVIO_JADLOG_WELCOME_PAGE,
    action: NUVEMEVIO_SHIPPING_ACTION_LINK,
  });
};

export const trackJadLogWelcomeBanner = (storeId: string) => {
  logEvent(NUVEM_ENVIO_VIEW, {
    storeId,
    page: NUVEMVIO_JADLOG_WELCOME_PAGE,
  });
};
