import { useMemo } from 'react';
import { Box, Card } from '@nimbus-ds/components';
import { CheckoutPaymentMethod } from '@tiendanube/common';
import {
  useChargesToPay,
  usePayorderToPay,
} from 'domains/Billing/Checkout/hooks';
import {
  AccountDataContent,
  PaymentDataContent,
} from 'domains/Billing/Checkout/pages/components';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface PayOrderSummaryProps {
  showTaxes?: boolean;
  paymentMethod?: CheckoutPaymentMethod;
}

function PayOrderSummary({
  showTaxes = false,
  paymentMethod,
}: PayOrderSummaryProps) {
  const { transformChargesToDisplayable } = useChargesToPay(false);
  const {
    payorderToPay,
    isSuccess,
    totalWithoutFees,
    subtotalWithoutFees,
    totalFeeForMethod,
    totalFeeForMethodWithTaxes,
    nonFeeCharges,
    feesForMethod,
  } = usePayorderToPay();
  const t = useTranslationBilling('checkout.payOrderSummary');

  const feeTotal =
    (paymentMethod &&
      (showTaxes ? totalFeeForMethodWithTaxes : totalFeeForMethod)(
        paymentMethod,
      )) ??
    0;

  const charges = useMemo(
    () => nonFeeCharges && transformChargesToDisplayable(nonFeeCharges),
    [nonFeeCharges, transformChargesToDisplayable],
  );

  const formatCurrency = useFormatCurrency();

  if (!isSuccess || !payorderToPay || !totalWithoutFees || !subtotalWithoutFees)
    return null;

  return (
    <Card>
      <Card.Header
        title={`${t('total')}: ${formatCurrency(
          (showTaxes ? totalWithoutFees : subtotalWithoutFees) + feeTotal,
          payorderToPay.currency,
        )}`}
      />
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="4" flexGrow="1">
          <AccountDataContent />
          <div className="stratus--checkout-card_content--extra-item">
            {charges && (
              <PaymentDataContent
                charges={charges}
                showTaxes={showTaxes}
                extraFees={paymentMethod && feesForMethod(paymentMethod)}
              />
            )}
          </div>
        </Box>
      </Card.Body>
    </Card>
  );
}

export default PayOrderSummary;
