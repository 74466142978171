import { Box } from '@nimbus-ds/components';
import {
  GetDocumentSettingsResponseDto,
  InformationForDocumentResponseDto,
  OcaLabelResponseDto,
} from '@tiendanube/common';
import DocumentOcaPreview from '../DocumentPreview/DocumentOcaPreview';

interface BoxOcaLabelDocumentPreviewProps {
  ocaInformation: (InformationForDocumentResponseDto & {
    ocaLabel: OcaLabelResponseDto;
  })[];
  isLoading: boolean;
  settings: GetDocumentSettingsResponseDto;
  locationId: string;
}

function BoxOcaLabelDocumentPreview({
  ocaInformation,
  isLoading,
  settings,
  locationId,
}: Readonly<BoxOcaLabelDocumentPreviewProps>) {
  const alignItems = isLoading ? 'center' : 'flex-start';

  return (
    <Box
      id="print-container-oca"
      width="100%"
      display="flex"
      alignItems={alignItems}
      justifyContent="center"
      flexWrap="wrap"
    >
      {!isLoading && ocaInformation && (
        <DocumentOcaPreview
          information={ocaInformation}
          settings={settings}
          locationId={locationId}
        />
      )}
    </Box>
  );
}

export default BoxOcaLabelDocumentPreview;
