import { FulfillmentOrderForDocumentResponseDto } from '@tiendanube/common';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';

interface TotalDocumentPreviewProps {
  fulfillmentOrder: FulfillmentOrderForDocumentResponseDto;
  showPrices?: boolean;
}

function TotalDocumentPreview({
  fulfillmentOrder,
  showPrices = true,
}: Readonly<TotalDocumentPreviewProps>) {
  const t = useTranslationFulfillmentOrders('documents.general');
  return (
    <div className="totals">
      <div className="total-row">
        <p>
          {t('payment.subtotal')} ({fulfillmentOrder.totalQuantity}{' '}
          {fulfillmentOrder.totalQuantity > 1
            ? t('totalQuantityPlural')
            : t('totalQuantity')}
          )
        </p>
        {showPrices && <p>{fulfillmentOrder.subTotal}</p>}
      </div>
      {showPrices && (
        <>
          {!!fulfillmentOrder.shipping?.cost && (
            <div className="total-row">
              <p>{t('shipping.cost')}</p>
              <p>{fulfillmentOrder.shipping.cost}</p>
            </div>
          )}
          {!!fulfillmentOrder.discount && (
            <div className="total-row">
              <p>{t('payment.discount')}</p>
              <p>{fulfillmentOrder.discount}</p>
            </div>
          )}
          <div className="total-row bold">
            <p>{t('payment.total')}</p>
            <p>{fulfillmentOrder.total}</p>
          </div>
        </>
      )}
    </div>
  );
}

export default TotalDocumentPreview;
