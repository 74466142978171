import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { CustomersInterface } from '../types';

export const getCustomersState = (
  state: RootStateType,
): CombinedState<CustomersInterface> => state.customers.customers;

const getEntityById = (_: RootStateType, id: string) => id;

export const getCustomersRefreshStatus = createSelector(
  getCustomersState,
  (customers) => ({
    isRefreshing: customers.refreshStatus === 'loading',
    isRefreshError: customers.refreshStatus === 'error',
    isRefreshSuccess: customers.refreshStatus === 'success',
  }),
);

export const getExportStatus = createSelector(
  getCustomersState,
  (customers) => ({
    isExporting: customers.exportStatus === 'loading',
    isExportError: customers.exportStatus === 'error',
    isExportSuccess: customers.exportStatus === 'success',
  }),
);

export const getExportToken = createSelector(
  getCustomersState,
  ({ exportToken }) => exportToken,
);

export const getCustomersIds = createSelector(
  getCustomersState,
  (customers) => customers.ids,
);

export const getCustomersEntities = createSelector(
  getCustomersState,
  (customers) => customers.entities,
);

export const getCustomersListItemById = createSelector(
  getCustomersEntities,
  getEntityById,
  (customersEntities, id) => customersEntities[id],
);

export const getCustomersPagination = createSelector(
  getCustomersState,
  (customers) => customers.pagination,
);

export const getCustomersFilters = createSelector(
  getCustomersState,
  (customers) => customers.filters,
);

export const getCustomersFiltersWithoutPage = createSelector(
  getCustomersFilters,
  ({ q }) => ({
    q,
  }),
);

export const getCustomersHasFilters = createSelector(
  getCustomersFiltersWithoutPage,
  (filters) => Object.values(filters).filter((f) => f).length !== 0,
);

export const getCustomerDetailsStatus = createSelector(
  getCustomersState,
  (customers) => ({
    isLoading: customers.entityDetails.status === 'loading',
    isError: customers.entityDetails.status === 'error',
    isSuccess: customers.entityDetails.status === 'success',
  }),
);

export const getCustomerDetails = createSelector(
  getCustomersState,
  (customers) => customers.entityDetails.data,
);

export const getCustomerOrdersStatus = createSelector(
  getCustomersState,
  (customers) => ({
    isLoading: customers.customerOrders.status === 'loading',
    isError: customers.customerOrders.status === 'error',
    isSuccess: customers.customerOrders.status === 'success',
  }),
);

export const getCustomerOrders = createSelector(
  getCustomersState,
  (customers) => customers.customerOrders.data?.results || [],
);

export const getCustomerOrdersPagination = createSelector(
  getCustomersState,
  (customers) => customers.customerOrders.data?.pagination,
);

export const importMaxLinesStatus = createSimpleSelector(
  [getCustomersState],
  (customers) => customers.importMaxLines.status,
);

export const importMaxLines = createSimpleSelector(
  [getCustomersState],
  (customers) => customers.importMaxLines.max,
);

export const getCustomerOrdersIds = createSelector(
  getCustomersState,
  (customers) => customers.customerOrders.ids,
);

export const getCustomerOrderById = createSelector(
  getCustomerOrders,
  getEntityById,
  (customerOrders, id) => customerOrders.find((o) => o.id === id),
);

export const getCustomerMessagesStatus = createSelector(
  getCustomersState,
  (customers) => ({
    isLoading: customers.customerMessages.status === 'loading',
    isError: customers.customerMessages.status === 'error',
    isSuccess: customers.customerMessages.status === 'success',
  }),
);

export const getCustomerMessages = createSelector(
  getCustomersState,
  (customers) => customers.customerMessages.entities || [],
);

export const getCustomerMessagesIds = createSelector(
  getCustomersState,
  (customers) => customers.customerMessages.ids,
);

export const getCustomerMessagesById = createSelector(
  getCustomerMessages,
  getEntityById,
  (customerMessages, id) => customerMessages[id],
);

export const getSendEmailStatus = createSimpleSelector(
  [getCustomersState],
  (customers) => customers.sendEmail,
);

export const getCustomerIsEdited = createSimpleSelector(
  [getCustomersState],
  (customers) => customers.isEdited,
);
