import { Link, LinkProps } from '@nimbus-ds/components';
import { useIsInAppBrowser } from 'App/hooks';
import { useGoToPlans } from 'commons/hooks/useGoToPlans';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface GoToBillingPlansLinkProps
  extends Omit<LinkProps, 'onClick' | 'children'> {
  children?: React.ReactNode;
}

function GoToBillingPlansLink({
  children,
  ...rest
}: GoToBillingPlansLinkProps) {
  const t = useTranslationBilling('goToPlans');
  const { handleGoToPlans, isMobileNonFreemium } = useGoToPlans();
  const isMobileDevice = useIsMobileDevice();
  const isInAppBrowserOpen = useIsInAppBrowser();
  const isWebVersion = !isMobileDevice && !isInAppBrowserOpen;

  if (isMobileNonFreemium || !isWebVersion) return null;

  return (
    <Link as="button" onClick={handleGoToPlans} {...rest}>
      {children || t('text')}
    </Link>
  );
}

export default GoToBillingPlansLink;
