import { useImportMaxLines } from 'commons/hooks/useImportMaxLines';
import { getImportMaxLines } from 'domains/Catalog/Products/productsSlice';
import {
  importMaxLines,
  importMaxLinesStatus,
} from 'domains/Catalog/Products/productsSlice/productSelectors';

export function useImportMaxLinesProducts() {
  return useImportMaxLines(
    getImportMaxLines,
    importMaxLines,
    importMaxLinesStatus,
  );
}
