import { useTranslation } from 'react-i18next';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import { Card, Stack, Text } from '@tiendanube/components';
import LocationText from 'domains/Orders/components/LocationText';

export interface DeliveryInfoCardInterface {
  receiverName: string;
  receiverPhone: string;
  zipcode: string;
  street: string;
  number: string;
  floor: string | null;
  city: string;
  province: string;
  country: string;
  locality: string;
  betweenStreet?: string;
  reference?: string;
}

interface DeliveryInfoCardProps {
  deliveryData: DeliveryInfoCardInterface;
}

function DeliveryInfoCard({
  deliveryData,
}: DeliveryInfoCardProps): JSX.Element {
  const { t } = useTranslation([DomainEnum.ORDERS]);

  return (
    <Card title={t('draftOrders.deliveryInfoCard.title')}>
      <Stack column align="flex-start" spacing="tight">
        <Stack.Item>
          <Text block>{deliveryData.receiverName}</Text>
          <Text block>{deliveryData.receiverPhone}</Text>
        </Stack.Item>
        <LocationText
          street={deliveryData.street}
          number={deliveryData.number}
          floor={deliveryData.floor}
          zipcode={deliveryData.zipcode}
          locality={deliveryData.locality}
          city={deliveryData.city}
          province={deliveryData.province}
          country={deliveryData.country}
          betweenStreet={deliveryData.betweenStreet}
          reference={deliveryData.reference}
        />
      </Stack>
    </Card>
  );
}

export default DeliveryInfoCard;
