import { InterfaceSelectOption } from '@tiendanube/components';
import { useCategories } from 'domains/Catalog/Categories/hooks';
import { isCategoryVisible } from 'domains/Catalog/Products/utils';
import useTranslationCatalog from '../useTranslationCatalog';

function useHiddenCategories() {
  const t = useTranslationCatalog();

  const { categories } = useCategories();

  const hiddenCategoriesText = t('categories.hidden');

  const categoriesSelectOptions: InterfaceSelectOption[] = categories.map(
    (category) => {
      const { id, title } = category;
      const hiddenLabel = !isCategoryVisible(category)
        ? ` (${hiddenCategoriesText})`
        : '';
      const label = title + hiddenLabel;
      return {
        value: id,
        label,
      };
    },
  );

  return { categoriesSelectOptions, hiddenCategoriesText };
}

export default useHiddenCategories;
