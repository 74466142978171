import Status from '@tiendanube/common/src/enums/Status';
import { SavedSearchInterface } from './types';

function serializeMultipleOptions(filterOptions: Status[]): string {
  return filterOptions.join(',');
}

export enum DefaultSavedSearchesCode {
  PAYMENT_PENDING = 'paymentPending',
  READY_TO_PACK = 'readyToPack',
  READY_TO_SEND = 'readyToSend',
  READY_TO_PICKUP = 'readyToPickup',
  READY_TO_ARCHIVE = 'readyToArchive',
}

export const defaultSavedSearches = [
  {
    code: DefaultSavedSearchesCode.PAYMENT_PENDING,
    isDefault: true,
    filters: {
      status: Status.OPEN,
      paymentStatus: serializeMultipleOptions([
        Status.PENDING,
        Status.PARTIALLY_PAID,
      ]),
    },
  },
  {
    code: DefaultSavedSearchesCode.READY_TO_PACK,
    isDefault: true,
    filters: {
      status: Status.OPEN,
      paymentStatus: serializeMultipleOptions([
        Status.PAID,
        Status.PARTIALLY_PAID,
      ]),
      fulfillmentStatus: serializeMultipleOptions([
        Status.UNPACKED,
        Status.PARTIALLY_PACKED,
      ]),
    },
  },
  {
    code: DefaultSavedSearchesCode.READY_TO_SEND,
    isDefault: true,
    filters: {
      status: Status.OPEN,
      paymentStatus: Status.PAID,
      fulfillmentStatus: serializeMultipleOptions([
        Status.UNFULFILLED,
        Status.PARTIALLY_FULFILLED,
      ]),
    },
  },
  {
    code: DefaultSavedSearchesCode.READY_TO_PICKUP,
    isDefault: true,
    filters: {
      status: Status.OPEN,
      paymentStatus: Status.PAID,
      fulfillmentStatus: Status.PICKUP_READY,
    },
  },
  {
    code: DefaultSavedSearchesCode.READY_TO_ARCHIVE,
    isDefault: true,
    filters: {
      status: Status.OPEN,
      paymentStatus: Status.PAID,
      fulfillmentStatus: serializeMultipleOptions([
        Status.FULFILLED,
        Status.DELIVERED,
      ]),
    },
  },
] as SavedSearchInterface[];
