import { Box, Link, Tag } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card } from '@tiendanube/components';

export default function PriceTablesLoadingState() {
  const { t } = useTranslation(Domain.CUSTOMERS);
  return (
    <Card title={t('customerForm.priceTablesInformation.title')}>
      <Box display="flex" flexDirection="column" gap="4">
        <Tag.Skeleton width="200px" height="24px" />
        <Link.Skeleton width="60px" />
      </Box>
    </Card>
  );
}
