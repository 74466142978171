import { DataList, IconButton } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';

function PhysicalLocationsSkeleton() {
  return (
    <DataList.Row id="">
      <DataList.Cell>
        <Stack column align="flex-start" spacing="none">
          <Text.Skeleton />
          <Text.Skeleton />
        </Stack>
      </DataList.Cell>
      <DataList.Cell alignRight>
        <Stack spacing="tight" justify="flex-end">
          <IconButton.Skeleton />
          <IconButton.Skeleton />
        </Stack>
      </DataList.Cell>
    </DataList.Row>
  );
}

export default PhysicalLocationsSkeleton;
