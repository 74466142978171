import { Button } from '@tiendanube/components';
import Stack from 'commons/components/Stack';
import { useTranslationAccount } from 'domains/Account/hooks';

interface CancelAndContinueProps {
  onCancel: () => void;
  onContinue: () => void;
  disableNext?: boolean;
}

function CancelAndContinue({
  onCancel,
  onContinue,
  disableNext = false,
}: CancelAndContinueProps): JSX.Element {
  const t = useTranslationAccount();

  return (
    <Stack align="stretch">
      <Button onClick={onCancel} appearance="default">
        {`${t('shopCancellationPage.cancelButton')}`}
      </Button>
      <Button onClick={onContinue} disabled={disableNext} appearance="primary">
        {`${t('shopCancellationPage.continueButton')}`}
      </Button>
    </Stack>
  );
}

export default CancelAndContinue;
