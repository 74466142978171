import { Icon, Tooltip } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import {
  CurrencyType,
  ValuesBusinessInfoResponseDto,
} from '@tiendanube/common';
import { OrderDetailsTaxesResponseDto } from '@tiendanube/common/src/domains/orders/orders/OrderDetailsResponse';
import { Domain } from '@tiendanube/common/src/enums';
import { useFormatCurrency } from '../../../../../../Billing/hooks';

interface Attributes {
  billingAddressCountry?: string;
  businessDataValues: ValuesBusinessInfoResponseDto;
  taxes?: OrderDetailsTaxesResponseDto;
  currency: CurrencyType;
}

function OrderTaxesAmountTooltip({
  billingAddressCountry,
  businessDataValues,
  taxes,
  currency,
}: Attributes): JSX.Element | null {
  const { t } = useTranslation(Domain.ORDERS);
  const formatCurrency = useFormatCurrency();
  const isBillingCountryAR = billingAddressCountry === 'AR';
  const { orderTaxesEnabled } = businessDataValues;

  if (
    !orderTaxesEnabled ||
    !isBillingCountryAR ||
    !taxes ||
    !(taxes?.amount > 0)
  ) {
    return null;
  }

  return (
    <Tooltip
      content={t('orderSummaryCard.tableAmounts.taxes', {
        amount: formatCurrency(taxes.amount, currency),
      })}
      position="right"
    >
      <Icon
        data-testId="icon-taxes-info"
        color="primary-interactive"
        source={<InfoCircleIcon />}
      />
    </Tooltip>
  );
}

export default OrderTaxesAmountTooltip;
