import { ChangeEvent } from 'react';
import {
  Checkbox,
  Icon,
  Input,
  Link,
  Table,
  Text,
} from '@nimbus-ds/components';
import { CopyIcon } from '@nimbus-ds/icons';
import { BusinessHoursResponseDto, WeekdaysType } from '@tiendanube/common';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import BusinessHourItemSkeleton from './Skeleton';
import { END_DEFAULT, START_DEFAULT } from '../utils';

interface BusinessHourItemProps {
  day: WeekdaysType;
  isDayChanged: boolean;
  businessHour?: BusinessHoursResponseDto;
  errors: Partial<Record<string, string>>;
  changeHour: (value: BusinessHoursResponseDto) => void;
  handleApplyAll: (newBusinessHour: BusinessHoursResponseDto) => void;
  handleChangeDay: (day: WeekdaysType, active: boolean) => void;
}

function BusinessHourItem({
  day,
  isDayChanged,
  businessHour,
  errors,
  changeHour,
  handleApplyAll,
  handleChangeDay,
}: Readonly<BusinessHourItemProps>): JSX.Element {
  const t = useTranslationShipping(
    'deliveryMethods.pickupPoints.form.secondStep.businessHours',
  );
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newBusinessHour: BusinessHoursResponseDto = {
      day: day,
      start:
        e.target.name === 'start'
          ? e.target.value
          : businessHour?.start ?? START_DEFAULT,
      end:
        e.target.name === 'end'
          ? e.target.value
          : businessHour?.end ?? END_DEFAULT,
    };

    changeHour(newBusinessHour);
  };

  return (
    <Table.Row backgroundColor="neutral-background">
      <Table.Cell padding="base">
        <Checkbox
          name={day}
          label={t(day)}
          checked={!!businessHour?.day}
          onChange={(e) => handleChangeDay(day, e.target.checked)}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          type={!!businessHour && businessHour.day ? 'time' : 'text'}
          name="start"
          onChange={handleChange}
          disabled={!businessHour?.day}
          appearance={errors.start ? 'danger' : 'neutral'}
          value={
            !!businessHour && businessHour.day
              ? businessHour?.start
              : t('closed')
          }
        />
        {!!errors.start && (
          <Text fontSize="caption" color="danger-textLow">
            {t(errors.start)}
          </Text>
        )}
      </Table.Cell>
      <Table.Cell>
        <Input
          type={!!businessHour && businessHour.day ? 'time' : 'text'}
          name="end"
          onChange={handleChange}
          disabled={!businessHour?.day}
          appearance={errors.end ? 'danger' : 'neutral'}
          value={
            !!businessHour && businessHour.day ? businessHour?.end : t('closed')
          }
        />
        {!!errors.end && (
          <Text fontSize="caption" color="danger-textLow">
            {t(errors.end)}
          </Text>
        )}
      </Table.Cell>
      <Table.Cell padding="base">
        {isDayChanged && businessHour && (
          <Link
            textDecoration="none"
            appearance="neutral"
            as="button"
            onClick={() => handleApplyAll(businessHour)}
          >
            <Icon color="currentColor" source={<CopyIcon size="small" />} />{' '}
            {t('applyAll')}
          </Link>
        )}
      </Table.Cell>
    </Table.Row>
  );
}
BusinessHourItem.Skeleton = BusinessHourItemSkeleton;
export default BusinessHourItem;
