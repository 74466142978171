import { CurrencyPrice, ImageItemStratus } from 'commons/components';
import Stack from 'commons/components/Stack';
import ThousandSeparatorText from 'domains/Statistics/components/ThousandSeparatorText';
import { IMAGE_WIDTH } from '../../../utils';

export interface StatsProductsItemVariant {
  variantDescription: string;
  variantId: string;
  soldQty: number;
  imageUrl?: string;
}

interface StatsProductsItemVariantProps {
  variant: StatsProductsItemVariant;
  totalType?: 'number' | 'currency';
  defaultImage?: string;
}

function StatsProductsItemVariant({
  variant,
  totalType,
  defaultImage,
}: StatsProductsItemVariantProps) {
  const variantImageUrl = variant?.imageUrl || defaultImage;
  return (
    <Stack
      key={variant.variantId}
      spacing="tight"
      align="center"
      justify="space-between"
    >
      <ImageItemStratus
        id={variant.variantId}
        imageUrl={variantImageUrl}
        title={variant.variantDescription}
        imageWidth={IMAGE_WIDTH}
        appearance="default"
        bold={false}
      />

      {totalType === 'currency' ? (
        <CurrencyPrice price={variant.soldQty} />
      ) : (
        <ThousandSeparatorText value={variant.soldQty} />
      )}
    </Stack>
  );
}

export default StatsProductsItemVariant;
