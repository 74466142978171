import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { logout } from 'domains/Auth';
import dashboardService from '../../services';
import { initialState } from '../constants';

export const getOnboardingAction = createAsyncThunk(
  'dashboard/getOnboardingTasksAction',
  dashboardService.getOnboardingTasks,
);

export const updateOnboardingTaskSetupPaymentsAction = createAsyncThunk(
  'dashboard/updateOnboardingTaskSetupPaymentsAction',
  dashboardService.updateOnboardingTaskSetupPayments,
);

const onboardingSlice = createSlice({
  name: 'onboardingSlice',
  initialState: initialState.onboarding,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState.onboarding;
      return state;
    });

    builder
      .addCase(getOnboardingAction.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOnboardingAction.rejected, (state) => {
        state.status = 'error';
      })
      .addCase(getOnboardingAction.fulfilled, (state, action) => {
        state.status = 'success';
        state.data = action.payload;
      });
  },
});

export const { reducer } = onboardingSlice;
