/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { PickupPointRequestDto } from '@tiendanube/common';
import { useToastProgress } from 'App/components/ToastProgressContext';
import { useNavegate } from 'App/hooks';
import { useAsyncFunc, useForm, useToast } from 'commons/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { PickupForm } from '../../components';
import { validationSchemaSecondStep } from '../../components/PickupForm/validationSchemaSecondStep';
import usePickupPoints from '../../hooks/usePickupPoints';

function EditLocationAsPickupPage(): JSX.Element {
  const t = useTranslationShipping(
    'deliveryMethods.pickupPoints.locations.toast',
  );
  const { id } = useParams<{ id: string }>();
  const { updatePickupPoint } = usePickupPoints();
  const { fetchPickupPoint, pickupPointDetails, isErrorDetail } =
    usePickupPoints();
  const { goBack } = useNavegate();
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();

  const initialValuesForLocation = useMemo<PickupPointRequestDto>(
    () => pickupPointDetails,
    [pickupPointDetails],
  );

  const [handleCreatePickupPoint, isSaving] = useAsyncFunc<
    PickupPointRequestDto,
    Promise<void>
  >(
    async (data) => {
      if (data) {
        addToastProgress({
          label: t('edit.loading'),
        });
        await updatePickupPoint({
          id: id,
          payload: data,
        });
      }
    },
    () => {
      closeToastProgress();
      addToast({
        label: t('edit.success'),
        appearance: 'success',
      });
      goBack();
    },
    () => {
      closeToastProgress();
      addToast({
        label: t('edit.loading'),
        appearance: 'danger',
      });
    },
  );

  const onRetryError = () => {
    fetchPickupPoint(id);
  };

  useEffect(() => {
    fetchPickupPoint(id);
  }, []);

  const {
    errors,
    values,
    isDirty,
    handleChange,
    handleOnSubmit,
    setFieldValue,
  } = useForm<PickupPointRequestDto>({
    initialValues: initialValuesForLocation,
    validationSchema: validationSchemaSecondStep,
    onSubmit: handleCreatePickupPoint,
  });

  return (
    <PickupForm
      step={2}
      type="location"
      isSaving={isSaving}
      errors={errors}
      values={values}
      isDirty={isDirty}
      isError={isErrorDetail}
      handleSave={handleOnSubmit}
      handleChange={handleChange}
      setFieldValue={setFieldValue}
      onRetryError={onRetryError}
    />
  );
}

export default EditLocationAsPickupPage;
