import { Route, Switch } from 'react-router-dom';
import ToggleMigratedDomain from 'App/components/Migrations/ToggleMigratedDomain';
import { HideNavTabs } from 'App/components/Navtabs';
import { MIGRATION_ORDERS_EXPORT_FORCE } from 'App/featuresFlags';
import {
  useGetTags,
  useHasPermission,
  useIsMobileDevice,
} from 'domains/Auth/hooks';
import { FULFILLMENT_ORDERS_ROUTES } from 'domains/FulfillmentOrders/fulfillmentOrdersRoutes';
import PrePrintOcaLabelPage from 'domains/FulfillmentOrders/pages/PrePrintOcaDocumentPage/PrePrintOcaLabelPage';
import PrintDocumentPage from 'domains/FulfillmentOrders/pages/PrintDocumentPage';
import { DocumentType } from 'domains/FulfillmentOrders/pages/PrintDocumentPage/constants';
import { useIsEditOrdersEnabled } from './hooks';
import { ORDERS_SECTION_ROUTES } from './ordersSectionRoutes';
import OrderDetailsPage from './pages/OrderDetailsPage';
import OrderEditPage from './pages/OrderEditPage';
import OrderListPage from './pages/OrderListPage';
import OrdersExportPage from './pages/OrdersExportPage';
import RefundOrderPage from './pages/RefundOrderPage';

function Orders(): JSX.Element {
  const isMobileDevice = useIsMobileDevice();
  const isOrderEditEnabled = useIsEditOrdersEnabled();
  const tags = useGetTags();
  const hasOrdersExportForceTag = tags.includes(MIGRATION_ORDERS_EXPORT_FORCE);
  const canExportOrdersCsv = useHasPermission('export_orders_csv');

  return (
    <>
      <Route exact path={ORDERS_SECTION_ROUTES.orderList}>
        {isMobileDevice ? (
          <OrderListPage />
        ) : (
          <ToggleMigratedDomain domain="orders">
            <OrderListPage />
          </ToggleMigratedDomain>
        )}
      </Route>
      <Switch>
        <Route path={FULFILLMENT_ORDERS_ROUTES.label}>
          <PrintDocumentPage type={DocumentType.LABEL} />
        </Route>
        <Route path={FULFILLMENT_ORDERS_ROUTES.receipt}>
          <PrintDocumentPage type={DocumentType.RECEIPT} />
        </Route>
        <Route path={FULFILLMENT_ORDERS_ROUTES.contentDeclaration}>
          <PrintDocumentPage type={DocumentType.CONTENT_DECLARATION} />
        </Route>
        <Route path={FULFILLMENT_ORDERS_ROUTES.prePrintOca}>
          <PrePrintOcaLabelPage />
        </Route>
        {canExportOrdersCsv && (
          <Route exact path={ORDERS_SECTION_ROUTES.ordersExport}>
            {isMobileDevice ? (
              <OrdersExportPage />
            ) : (
              <ToggleMigratedDomain
                domain="ordersexport"
                force={hasOrdersExportForceTag}
              >
                <OrdersExportPage />
              </ToggleMigratedDomain>
            )}
          </Route>
        )}
        {isOrderEditEnabled && (
          <Route path={ORDERS_SECTION_ROUTES.orderEdit}>
            <HideNavTabs />
            {isMobileDevice ? (
              <OrderEditPage />
            ) : (
              <>
                <HideNavTabs />
                <OrderEditPage />
              </>
            )}
          </Route>
        )}
        <Route path={ORDERS_SECTION_ROUTES.refundOrder}>
          <HideNavTabs />
          <RefundOrderPage />
        </Route>
        <Route path={ORDERS_SECTION_ROUTES.orderDetails}>
          <HideNavTabs />
          {isMobileDevice ? (
            <OrderDetailsPage />
          ) : (
            <ToggleMigratedDomain domain="orders-detail">
              <>
                <HideNavTabs />
                <OrderDetailsPage />
              </>
            </ToggleMigratedDomain>
          )}
        </Route>
      </Switch>
    </>
  );
}

export default Orders;
