import { useEffect } from 'react';
import { InvoiceHistoryDataTable } from './components';
import InvoiceHistoryMobileSkeleton from './InvoiceHistoryMobileSkeleton';
import useInvoiceHistoryMobile from './useInvoiceHistoryMobile';

function InvoiceHistoryMobile(): JSX.Element {
  const {
    fetchInvoiceHistory,
    invoiceIds,
    fetchMoreInvoiceHistory,
    status,
    updateStatus,
  } = useInvoiceHistoryMobile();

  useEffect(() => {
    fetchInvoiceHistory();
  }, [fetchInvoiceHistory]);

  const shouldShowSpinner =
    updateStatus === 'loading' && invoiceIds.length !== 0;

  return status === 'loading' ? (
    <InvoiceHistoryDataTable.Skeleton />
  ) : (
    <InvoiceHistoryDataTable
      data={invoiceIds}
      fetchMore={fetchMoreInvoiceHistory}
      onRefresh={fetchInvoiceHistory}
      shouldShowSpinner={shouldShowSpinner}
    />
  );
}

InvoiceHistoryMobile.Skeleton = InvoiceHistoryMobileSkeleton;

export default InvoiceHistoryMobile;
