import { Country } from '@tiendanube/common/src/enums';
import { NUVEMPAGO_ENABLED } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { useGetCountry, useIsLogged } from 'domains/Auth/hooks';
import { useNuvemPagoFeatureFlagQuery } from '../nuvemPagoCommonsApi';

const AVAILABLE_NUVEMPAGO_COUNTRIES = [Country.BR, Country.AR];

export function useNuvemPagoFeatureFlag(featureFlag: string) {
  const storeCountry = useGetCountry();
  const { isLogin } = useIsLogged();
  const { validateRoles } = useRoles();
  const hasFullRole = validateRoles(['full', 'payments']);
  const [hasNuvempagoEnabled] = useHasTags(NUVEMPAGO_ENABLED);
  const availableNuvempago =
    AVAILABLE_NUVEMPAGO_COUNTRIES.includes(storeCountry);
  const shouldHasNuvempagoTag =
    storeCountry === Country.BR ? true : hasNuvempagoEnabled;

  const requireFetch =
    isLogin && shouldHasNuvempagoTag && availableNuvempago && hasFullRole;

  const { data, isSuccess, isError, ...queryResult } =
    useNuvemPagoFeatureFlagQuery(featureFlag, {
      skip: !requireFetch,
      refetchOnMountOrArgChange: true,
    });

  let isFeatureEnabled: boolean | null;

  if (isSuccess) {
    isFeatureEnabled = data as boolean;
  } else {
    isFeatureEnabled = isError ? false : null;
  }

  return {
    ...queryResult,
    isSuccess,
    isError,
    featureFlag,
    isFeatureEnabled,
  };
}
