import { useCallback } from 'react';
import { ConceptCode } from '@tiendanube/common';
import { combineStatus } from 'commons/utils/combineStatus';
import {
  useChargesToPay,
  useGetRecurrentPaymentConcepts,
  useGetSubscriptions,
} from 'domains/Billing/Checkout/hooks';

export function useSubscriptionDetail(
  concept: ConceptCode,
  externalReference?: string,
) {
  const {
    status: subscriptionsStatus,
    getSubscriptionForConcept,
    refreshSubscriptions,
  } = useGetSubscriptions();
  const {
    status: recurrentConceptsStatus,
    recurrentPaymentForConcept,
    refreshRecurrentPaymentConcepts,
  } = useGetRecurrentPaymentConcepts();

  const {
    isAvailableForPayment,
    status: chargesToPayStatus,
    refreshChargesToPay,
  } = useChargesToPay();

  const subscription = getSubscriptionForConcept(concept, externalReference);

  const { isLoading, isError } = combineStatus(
    subscriptionsStatus,
    recurrentConceptsStatus,
    chargesToPayStatus,
  );

  const retryLoad = useCallback(() => {
    refreshRecurrentPaymentConcepts();
    refreshSubscriptions();
    refreshChargesToPay();
  }, [
    refreshRecurrentPaymentConcepts,
    refreshSubscriptions,
    refreshChargesToPay,
  ]);

  const recurrentPaymentActivated = recurrentPaymentForConcept(
    concept,
    externalReference,
  );

  return {
    isLoading,
    isError,
    isAvailableForPayment,
    retryLoad,
    subscription,
    recurrentPaymentActivated,
  };
}
