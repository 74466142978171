import { useState } from 'react';
import { Box, Table, Tag, Text } from '@nimbus-ds/components';
import { UserIcon } from '@nimbus-ds/icons';
import { UsersListItemResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import { useGetUserInfo } from 'domains/Auth/hooks';
import {
  UserHas2faTag,
  UsersListActions,
} from 'domains/Configurations/Users/pages/UsersListPage/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import ShowMoreRoles from '../ShowMoreRoles';

interface UsersListDesktopRowProps {
  user: UsersListItemResponseDto;
  storeRoles: Record<string, string>;
}

function UsersListDesktopRow({
  user: {
    id,
    name,
    email,
    rolesWithoutFilters: userRoles,
    appRoles: userAppRoles,
    isOwner,
    has2FA,
    emailTypes,
  },
  storeRoles,
}: Readonly<UsersListDesktopRowProps>) {
  const t = useTranslationConfigurations('usersListPage.table');
  const { id: userId } = useGetUserInfo();
  const [shownRoles, setShownRoles] = useState(7);

  const showMoreRoles = () => setShownRoles((prev) => prev + 10);
  const showLessRoles = () => setShownRoles((prev) => prev - 10);

  const isCurrentUser = userId === `${id}`;

  const mergedUserRoles = [...userRoles, ...userAppRoles].filter(
    (role) => !!storeRoles[role],
  );
  const showMoreRolesLink = mergedUserRoles.length > 7;
  const hasMoreRoles = mergedUserRoles.length > shownRoles;
  const hasEmailTypes = emailTypes.length > 0;

  return (
    <Table.Row>
      <Table.Cell>
        <Stack spacing="tight">
          <Box display="flex" flexDirection="column">
            <Text lineClamp={1}>{name || t('withoutName')}</Text>
            {isCurrentUser && (
              <Tag appearance="primary">
                <UserIcon size={12} />{' '}
                <Text fontSize="caption" lineClamp={1}>
                  {t('currentUserTag')}
                </Text>
              </Tag>
            )}
          </Box>
        </Stack>
      </Table.Cell>
      <Table.Cell>
        <Text lineClamp={1}>{email || t('withoutName')}</Text>
      </Table.Cell>
      <Table.Cell>
        <Stack column align="flex-start" spacing="tight">
          <Stack spacing="tight" wrap>
            {mergedUserRoles.slice(0, shownRoles).map((role) => (
              <Tag key={`user-${id}-${role}`}>
                <Text fontSize="caption" lineClamp={1}>
                  {storeRoles[role]}
                </Text>
              </Tag>
            ))}
          </Stack>
          {showMoreRolesLink && (
            <ShowMoreRoles
              hasMoreRoles={hasMoreRoles}
              showMoreRoles={showMoreRoles}
              showLessRoles={showLessRoles}
            />
          )}
        </Stack>
      </Table.Cell>
      <Table.Cell>
        <Stack column align="flex-start" spacing="tight">
          <Stack spacing="tight" wrap>
            {hasEmailTypes ? (
              emailTypes.map((emailType) => (
                <Tag key={`user-${id}-${emailType}`}>
                  <Text fontSize="caption" lineClamp={1}>
                    {t(emailType.toLowerCase())}
                  </Text>
                </Tag>
              ))
            ) : (
              <Tag key={`user-${id}-no-email-types`}>
                <Text fontSize="caption" lineClamp={1}>
                  {t('no_email_types')}
                </Text>
              </Tag>
            )}
          </Stack>
        </Stack>
      </Table.Cell>
      <Table.Cell>
        <UserHas2faTag has2fa={has2FA} isCurrentUser={isCurrentUser} />
      </Table.Cell>
      <Table.Cell>
        <UsersListActions
          userId={id}
          isCurrentUser={isCurrentUser}
          isOwner={isOwner}
        />
      </Table.Cell>
    </Table.Row>
  );
}

export default UsersListDesktopRow;
