import { ChangeEvent } from 'react';
import { Card, Radio, Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { PickupPointCostResponseDto } from '@tiendanube/common';
import { Stack, InputNumberNimbus } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import PickupPointCostSkeleton from './Skeleton';

export enum CostType {
  FIXED = 'fixed',
  TO_AGREE = 'to_agree',
  FREE = 'free',
}

interface PickupPointCostProps {
  cost: PickupPointCostResponseDto;
  errors: Partial<Record<string, string>>;
  setFieldValue: (name: string, value: any) => void;
}

function PickupPointCost({
  cost,
  errors,
  setFieldValue,
}: Readonly<PickupPointCostProps>): JSX.Element {
  const t = useTranslationShipping(
    'deliveryMethods.pickupPoints.form.secondStep.cost',
  );

  const options = [
    {
      label: t('fixed'),
      value: CostType.FIXED,
    },
    {
      label: t('free'),
      value: CostType.FREE,
    },
    {
      label: t('toAgree'),
      value: CostType.TO_AGREE,
    },
  ];

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newCost = {
      ...cost,
      [e.target.name]: e.target.value,
    };

    setFieldValue('cost', newCost);
  };

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <Stack column align="stretch">
          <FormField>
            <Stack spacing="tight">
              {options.map((option) => (
                <Radio
                  key={option.value}
                  id={option.value}
                  label={option.label}
                  name="type"
                  value={option.value}
                  onChange={onChange}
                  as="button"
                  checked={cost.type === option.value}
                />
              ))}
            </Stack>
          </FormField>
          {cost.type === CostType.FIXED && (
            <FormField label={t('value')}>
              <InputNumberNimbus
                type="float"
                name="value"
                defaultDecimals={2}
                value={cost.value}
                onChange={onChange}
                append={t('currency')}
                appearance={errors['cost.value'] ? 'danger' : 'neutral'}
              />
              {!!errors['cost.value'] && (
                <Text
                  fontSize="caption"
                  color={
                    errors['cost.value'] ? 'danger-textLow' : 'neutral-textLow'
                  }
                >
                  {t(errors['cost.value'])}
                </Text>
              )}
            </FormField>
          )}
        </Stack>
      </Card.Body>
    </Card>
  );
}

PickupPointCost.Skeleton = PickupPointCostSkeleton;
export default PickupPointCost;
