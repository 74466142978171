import { Alert, Link, Text } from '@nimbus-ds/components';
import { Trans } from 'react-i18next';
import { PaymentProviderDto } from '@tiendanube/common';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

const TRANSLATE_PREFIX = 'deprecatedPaymentProviderWarning';

type DeprecatedPaymentProviderWarningProps = Pick<
  PaymentProviderDto,
  'id' | 'name'
>;

function DeprecatedPaymentProviderWarning(
  paymentProvider: Readonly<DeprecatedPaymentProviderWarningProps>,
) {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  const installationGuideUrl = t(`providers.${paymentProvider.id}.url`, {
    defaultValue: '',
  });
  const deadlineDate = t(`providers.${paymentProvider.id}.deadline`, {
    defaultValue: '',
  });

  return (
    <Alert appearance="warning" title={t('title')}>
      <Text>
        <Trans
          t={t}
          i18nKey={`content.deadline.${deadlineDate ? 'withDate' : 'default'}`}
          values={{
            paymentProvider: paymentProvider.name,
            date: deadlineDate,
          }}
        />
      </Text>
      <Text>{t('content.upgrade')}</Text>
      {!!installationGuideUrl && (
        <Text>
          <Trans
            t={t}
            i18nKey="content.installationGuide"
            components={{
              GuideLink: (
                <Link
                  as="a"
                  textDecoration="underline"
                  href={installationGuideUrl}
                  target="_blank"
                >
                  {' '}
                </Link>
              ),
            }}
          />
        </Text>
      )}
    </Alert>
  );
}

export default DeprecatedPaymentProviderWarning;
