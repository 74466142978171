import { MarketingIcon } from '@nimbus-ds/icons';
import { MenuButton } from '@nimbus-ds/patterns';
import { useLocation, Link } from 'react-router-dom';
import ShowByRole from 'App/components/ShowByRole';
import { useHandleMenuClick } from 'App/hooks';
import { useTranslationCommon } from 'commons/hooks';
import { useNuvemMarketing } from 'domains/Marketing/hook';
import { MARKETING_ROUTES } from 'domains/Marketing/marketingRoutes';
import {
  menuSecondaryMarketingAutomationAppsClick,
  menuSecondaryMarketingAutomationClick,
} from './tracking';

function MarketingAutomationMenu() {
  const t = useTranslationCommon();
  const { pathname } = useLocation();
  const { handleClickItem, handleClickAccordion } = useHandleMenuClick();
  const { hasNuvemMarketingRole, hasAccessToNuvemMarketing } =
    useNuvemMarketing();

  const handleClickFeaturedApplications = () => {
    menuSecondaryMarketingAutomationAppsClick();
    handleClickItem();
  };

  const marketingAutomation = {
    children: t('menu.manage.marketingApps.marketingAutomation'),
    onClick: () => {
      menuSecondaryMarketingAutomationClick();
      handleClickItem();
    },
    active: pathname.startsWith(MARKETING_ROUTES.marketingAutomation),
    href: MARKETING_ROUTES.marketingAutomation,
    roles: ['full', 'nuvem_marketing'],
  };

  const featuredApplications = {
    children: t('menu.manage.marketingApps.featuredApplications'),
    onClick: handleClickFeaturedApplications,
    active: pathname.startsWith(MARKETING_ROUTES.featuredApplications),
    href: MARKETING_ROUTES.featuredApplications,
    roles: ['full', 'marketing'],
  };

  const menuItems = [marketingAutomation, featuredApplications];

  const isActive =
    pathname.startsWith(MARKETING_ROUTES.marketingAutomation) ||
    pathname.startsWith(MARKETING_ROUTES.featuredApplications);

  const targetUrl = MARKETING_ROUTES.marketingAutomation;

  return hasAccessToNuvemMarketing && hasNuvemMarketingRole ? (
    <MenuButton.Accordion
      contentid="content-marketing-apps"
      menuButton={{
        id: 'control-marketing',
        label: t('menu.manage.marketingApps.title'),
        startIcon: MarketingIcon,
        'aria-controls': 'content-marketing-apps',
        onClick: handleClickAccordion,
      }}
      open={isActive}
      active={isActive}
      as={Link}
      to={targetUrl}
    >
      {menuItems.map((item, index) => (
        <ShowByRole key={`mam-${index}`} includeRoles={item.roles}>
          <MenuButton
            key={item.children}
            as={Link}
            label={item.children}
            onClick={item.onClick}
            to={item.href}
            active={item.active}
          />
        </ShowByRole>
      ))}
    </MenuButton.Accordion>
  ) : (
    <ShowByRole includeRoles={['full', 'marketing']}>
      <MenuButton
        as={Link}
        to={MARKETING_ROUTES.featuredApplications}
        startIcon={MarketingIcon}
        onClick={handleClickFeaturedApplications}
        label={t('menu.manage.marketingApps.title')}
        active={pathname.startsWith(MARKETING_ROUTES.featuredApplications)}
      />
    </ShowByRole>
  );
}

export default MarketingAutomationMenu;
