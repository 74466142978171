import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { Country } from '@tiendanube/common/src/enums';
import { CopyLink } from 'commons/components';
import { useGetCountry } from 'domains/Auth/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import ImageSrc from './ms_image.png';
import { SHARE_LANDING_LINKS } from '../../constants';

function MercadoShops() {
  const t = useTranslationDashboard();

  const storeCountry = useGetCountry();
  const countryLink =
    SHARE_LANDING_LINKS[storeCountry] ?? SHARE_LANDING_LINKS[Country.AR];

  return (
    <Box paddingBottom="5">
      <Card>
        <Card.Body>
          <Box display="flex" flexDirection="row" gap="6">
            <Box
              padding="5"
              borderRadius="base"
              backgroundColor="neutral-surface"
              display="flex"
              alignItems="center"
            >
              <img
                src={ImageSrc}
                alt=""
                height="auto"
                style={{ minWidth: '90px' }}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap="4"
              justifyContent="center"
              maxWidth="703px"
            >
              <Box display="flex" flexDirection="column">
                <Title as="h5">{t('shivaProject.mercadoShops.title')}</Title>
                <Text>{t('shivaProject.mercadoShops.description')}</Text>
              </Box>
              <CopyLink
                value={countryLink}
                message={t('shivaProject.mercadoShops.copyLinkMessage')}
                text={t('shivaProject.mercadoShops.link')}
                size="base"
              />
            </Box>
          </Box>
        </Card.Body>
      </Card>
    </Box>
  );
}

export default MercadoShops;
