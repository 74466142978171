import { ReactNode } from 'react';
import { HeaderContent, IonPageStratus } from 'commons/components';
import Link from 'commons/components/LegacyLink';
import {
  useStatisticsFilterContext,
  useTranslationStatistics,
} from 'domains/Statistics/hooks';
import { FiltersStatisticsInterface } from 'domains/Statistics/types';
import FiltersStatistics from '../FiltersStatistics';
import LastUpdate from '../LastUptate';

interface StatisticsPageProps {
  children: ReactNode;
  header?: ReactNode;
  title?: string;
  defaultFilterValues?: FiltersStatisticsInterface;
  lastUpdate?: string;
  isLoading?: boolean;
}

function StatisticsPage({
  children,
  title,
  defaultFilterValues,
  lastUpdate,
  isLoading = false,
  header,
}: StatisticsPageProps) {
  const t = useTranslationStatistics();
  const { showFilter, openFilter, closeFilter } = useStatisticsFilterContext();

  return (
    <IonPageStratus
      headerContent={<HeaderContent title={title || t('statistics.title')} />}
      width="medium"
    >
      {!!lastUpdate && !isLoading && <LastUpdate lastUpdate={lastUpdate} />}
      {isLoading && <Link.Skeleton />}
      {header}
      <FiltersStatistics
        isOpen={showFilter}
        onClose={closeFilter}
        onOpen={openFilter}
        defaultValues={defaultFilterValues}
      />

      {children}
    </IonPageStratus>
  );
}

export default StatisticsPage;
