/* eslint-disable max-statements */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Text, Alert, Box } from '@nimbus-ds/components';
import { useParams } from 'react-router-dom';
import { OrderCancelRequestDto } from '@tiendanube/common';
import {
  Status as StatusEnum,
  FulfillmentPreferenceType as FulfillmentPreferenceTypeEnum,
} from '@tiendanube/common/src/enums';
import { ErrorState, InterfaceLabel } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import {
  ActionProp,
  ApplicationsDesktop,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { useAsyncFunc, useModal } from 'commons/hooks';
import { logEvent } from 'commons/utils/tracking';
import { openWindow } from 'commons/utils/window';
import { NUVEM_ENVIO_VIEW } from 'config/trackingEvents';
import HeaderOrderMobile from 'domains/Orders/components/HeaderOrderMobile';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { useAppsLinks, useStoreInfo } from 'domains/PartnersApps/hooks';
import {
  InsufficientStockModal,
  OrderDetails,
  OrderDetailsSkeleton,
  SingleOrderCancelModal,
  InsufficientStockAlert,
  RefundStatusBanner,
} from './components';
import ActionsOrderDesktop from './components/ActionsOrderDesktop';
import {
  fulfillment as fulfillmentLabelStyle,
  payment as paymentLabelStyle,
  order as orderLabelStyle,
} from './components/commons/Status/status';
import useActionsToast from './components/OrderDetails/useActionsToast';
import { useTimelineSection } from './components/TimelineSection';
import { useFetchOrdersMetafields } from './hooks';
import useOrderActions from './hooks/useOrderActions';
import {
  useOrderDetails,
  useOrdersList,
  useOrdersEmailTracking,
} from '../../hooks';
import './OrderDetail.scss';
import { useCanBeRefunded, useOrderIsEdited } from '../hooks';

const classesToHide = [
  'stratus--app-layout-header',
  'stratus--menu-container',
  'stratus--submenu',
  'stratus--card-metafields',
  'header-content-item',
  'cont-message-alert',
];

function OrderDetailsPage(): JSX.Element {
  const { id } = useParams<{ id: string }>();
  const t = useTranslationOrders();
  const { goTo, goBack, replace } = useNavegate();
  const {
    orderDetails,
    isError,
    isLoading,
    archiveOrder,
    cancelOrder,
    openOrder,
    paidOrder,
    partiallyPaidOrder,
    remarkOrder,
    packOrder,
    fulfillOrder,
    editDeliveryAddress,
  } = useOrderDetails(id);
  const { canBeRefundedThroughCancelForm } = useCanBeRefunded(orderDetails);
  const { id: storeId } = useStoreInfo();
  const { ordersIds } = useOrdersList();
  const { getAppsLinks } = useAppsLinks();
  useTimelineSection({ orderId: id, fetch: true });
  const { onError, onCancelSuccess, onArchiveSuccess, onOpenSuccess } =
    useActionsToast();

  useFetchOrdersMetafields(id);
  const [showCancelModal, openModal, handleHideModal] = useModal();
  const { actions, updateMetadata, handleHideReopenModal, showReopenModal } =
    useOrderActions(
      orderDetails,
      openModal,
      onArchiveSuccess,
      onOpenSuccess,
      openOrder,
      archiveOrder,
      onError,
    );
  const [handleCancelOrder, isCanceling] = useAsyncFunc<
    OrderCancelRequestDto,
    void
  >(
    async (data) => {
      if (data) {
        await cancelOrder(data);
        goTo('/orders');
      }
    },
    onCancelSuccess,
    onError,
  );
  const isEdited = useOrderIsEdited();

  const title = t('detail.title', { number: orderDetails?.number });

  const headerLabels = useMemo<InterfaceLabel[]>(() => {
    if (!orderDetails) return [];

    if (orderDetails.status !== StatusEnum.OPEN) {
      const orderLabel = orderLabelStyle(orderDetails.status);
      return [
        {
          ...orderLabel,
          id: orderLabel.status,
          label: `${t(`status.${orderLabel.status}`)}`,
        },
      ];
    }

    const paymentLabel = paymentLabelStyle(orderDetails.payment.status);

    const fulfillmentLabel = fulfillmentLabelStyle(
      orderDetails.fulfillment.status as StatusEnum,
      orderDetails.fulfillment.preference.type as FulfillmentPreferenceTypeEnum,
      orderDetails.isShippablePickup,
    );

    return [
      {
        ...paymentLabel,
        id: paymentLabel.status,
        label: `${t(`status.${paymentLabel.status}`)}`,
      },
      ...(orderDetails.fulfillment.status
        ? [
            {
              ...fulfillmentLabel,
              id: fulfillmentLabel.status,
              label: `${t(
                `status.${
                  fulfillmentLabel.status === 'pickuped' &&
                  orderDetails.isShippablePickup
                    ? 'shipped'
                    : fulfillmentLabel.status
                }`,
              )}`,
            },
          ]
        : []),
    ];
  }, [orderDetails, t]);

  const hasOrdersList = ordersIds.length > 0;
  const currentOrderIndex = ordersIds.findIndex((orderId) => orderId === id);
  const hasPreviousOrders = hasOrdersList && currentOrderIndex > 0;
  const hasNextOrders =
    hasOrdersList && currentOrderIndex < ordersIds.length - 1;

  const goToPreviousOrder = () => {
    if (hasPreviousOrders) {
      replace(`/orders/${ordersIds[currentOrderIndex - 1]}`);
    }
  };

  const goToNextOrder = () => {
    if (hasNextOrders) {
      replace(`/orders/${ordersIds[currentOrderIndex + 1]}`);
    }
  };

  const { showAlert, fetchBouncedEmails } = useOrdersEmailTracking(id);

  const printSectionRef = useRef<HTMLDivElement>(null);

  const applyClassToElements = useCallback(
    (className: string, action: 'add' | 'remove') => {
      const elements = document.querySelectorAll(`.${className}`);
      elements.forEach((element) => element.classList[action]('no-print'));
    },
    [],
  );

  const toggleNoPrintClass = useCallback(
    (action: 'add' | 'remove') => {
      classesToHide.forEach((className) =>
        applyClassToElements(className, action),
      );
    },
    [applyClassToElements],
  );

  const addNoPrintClass = useCallback(
    () => toggleNoPrintClass('add'),
    [toggleNoPrintClass],
  );
  const removeNoPrintClass = useCallback(
    () => toggleNoPrintClass('remove'),
    [toggleNoPrintClass],
  );

  useEffect(() => {
    window.addEventListener('beforeprint', addNoPrintClass);
    window.addEventListener('afterprint', removeNoPrintClass);

    return () => {
      window.removeEventListener('beforeprint', addNoPrintClass);
      window.removeEventListener('afterprint', removeNoPrintClass);
    };
  }, [addNoPrintClass, removeNoPrintClass]);

  const showUnclaimedStockAlert = !!orderDetails?.products.some(
    (product) =>
      !!product.issues &&
      Object.keys(product.issues).some((issue) => issue === 'unclaimedStock'),
  );
  const insufficientStockProducts =
    orderDetails?.products
      .filter((p) => p.issues?.unclaimedStock)
      .map((p) => ({
        id: p.id,
        title: p.title,
        variants: p.variants,
      })) || [];

  useEffect(() => {
    fetchBouncedEmails();
  }, [fetchBouncedEmails]);

  const applicationsActions: ActionProp[] = getAppsLinks(
    'orders_single',
    'default',
  ).map((app) => ({
    children: app.text,
    onClick: () => {
      if (Number(app.appId) === 3810 || Number(app.appId) === 4190) {
        logEvent(NUVEM_ENVIO_VIEW, {
          storeId,
          page: 'order',
          action: 'nuvem envio',
        });
      }
      openWindow(`${app.url}&id=${id}`, true);
    },
  }));

  const getHeaderTop = () => {
    const backNavitation = {
      onClick: goBack,
    };

    if (isError || isLoading) return <HeaderTop navigation={backNavitation} />;
    return (
      <HeaderOrderMobile
        navigation={backNavitation}
        actions={actions}
        applications={applicationsActions}
      />
    );
  };

  const inconsistencyAlertKey = isEdited ? 'edit' : 'alert';

  const getHeaderContent = () => {
    if (isLoading)
      return (
        <HeaderContent title="skeleton" labels={['skeleton', 'skeleton']} />
      );
    if (!orderDetails) return null;

    return (
      <>
        <HeaderContent
          title={title}
          labels={headerLabels}
          actions={<ActionsOrderDesktop actions={actions} />}
          applications={
            <ApplicationsDesktop applications={applicationsActions} />
          }
          paginationPrevious={hasPreviousOrders ? goToPreviousOrder : undefined}
          paginationNext={hasNextOrders ? goToNextOrder : undefined}
        />
        <div className="cont-message-alert">
          <Box marginTop="3" gap="3" display="flex" flexDirection="column">
            <RefundStatusBanner orderDetails={orderDetails} />
            <Alert
              appearance="warning"
              title={t('emailsTracking.alert.title')}
              show={showAlert}
            >
              <Text>{t('emailsTracking.alert.message')}</Text>
            </Alert>
            <Alert
              appearance="warning"
              title={t(`inconsistentProducts.${inconsistencyAlertKey}.title`)}
              show={!!orderDetails.inconsistentProducts}
            >
              <Text>
                {t(`inconsistentProducts.${inconsistencyAlertKey}.message`)}
              </Text>
            </Alert>
            <InsufficientStockAlert
              products={insufficientStockProducts}
              show={showUnclaimedStockAlert}
            />
          </Box>
        </div>
      </>
    );
  };

  return (
    <IonPageStratus
      width="medium"
      headerTop={getHeaderTop()}
      headerContent={getHeaderContent()}
    >
      {isError && <ErrorState title={t('errorPage.message')} />}
      {isLoading && <OrderDetailsSkeleton />}
      {!isError && !isLoading && orderDetails && (
        <>
          <div
            ref={printSectionRef}
            className="print-section stratus--order-details"
          >
            <OrderDetails
              orderDetails={orderDetails}
              onRemarkOrder={remarkOrder}
              onPaidOrder={paidOrder}
              onPartiallyPaidOrder={partiallyPaidOrder}
              onPackOrder={packOrder}
              onFulfillOrder={fulfillOrder}
              onEditDeliveryAddress={editDeliveryAddress}
            />
          </div>

          {showCancelModal && (
            <SingleOrderCancelModal
              orderId={orderDetails?.number}
              canBeRefunded={canBeRefundedThroughCancelForm()}
              amount={orderDetails?.amounts.total}
              gateway={orderDetails?.payment.gateway || ''}
              show
              hideModal={handleHideModal}
              isPaid={
                orderDetails.payment.status === StatusEnum.PAID &&
                orderDetails.status === StatusEnum.OPEN
              }
              appliedAction={handleCancelOrder}
              isLoading={isCanceling}
            />
          )}
          {showReopenModal && (
            <InsufficientStockModal
              open={showReopenModal}
              onConfirm={() => openOrder(false)}
              onDismiss={handleHideReopenModal}
              updateMetadata={updateMetadata}
              toastSuccess={onOpenSuccess}
              onError={onError}
            />
          )}
        </>
      )}
    </IonPageStratus>
  );
}

export default OrderDetailsPage;
