import {
  GetDocumentSettingsResponseDto,
  InformationForContentDeclarationResponseDto,
  InformationForDocumentResponseDto,
} from '@tiendanube/common';
import { DocumentType } from 'domains/FulfillmentOrders/pages/PrintDocumentPage/constants';
import DocumentPreview from '../DocumentPreview';

interface BoxLabelDocumentPreviewProps {
  information?:
    | InformationForContentDeclarationResponseDto[]
    | InformationForDocumentResponseDto[];
  isLoading: boolean;
  settings: GetDocumentSettingsResponseDto;
  locationId: string;
}

function BoxLabelDocumentPreview({
  information,
  isLoading,
  settings,
  locationId,
}: Readonly<BoxLabelDocumentPreviewProps>) {
  const alignItems = isLoading ? 'center' : 'flex-start';

  return (
    <div
      id={`print-container-${DocumentType.LABEL}`}
      style={
        information?.length && information.length <= 2
          ? {
              display: 'grid',
              gridTemplateColumns: information.length === 1 ? '1fr' : '1fr 1fr',
              columnGap: '0',
              width: information.length === 1 ? '50%' : '100%',
              alignItems: alignItems,
              justifyContent: 'center',
              paddingLeft: '2%',
            }
          : {
              width: '100%',
              justifyContent: 'center',
              alignItems: alignItems,
              columns: 2,
              columnGap: '0',
              padding: '2%',
            }
      }
    >
      {!isLoading && information && (
        <DocumentPreview
          type={DocumentType.LABEL}
          information={information as InformationForDocumentResponseDto[]}
          settings={settings}
          locationId={locationId}
        />
      )}
    </div>
  );
}

export default BoxLabelDocumentPreview;
