import { List, Title } from '@tiendanube/components';
import { ChevronDownIcon, ChevronUpIcon } from '@tiendanube/icons';
import Text from 'commons/components/LegacyText';
import { useBoolean } from 'commons/hooks';
import Skeleton from './Skeleton';
import ScopesItemHeaderLayout from '../ScopesItemHeaderLayout';

interface ScopesItemProps {
  scopes: string[];
  title: string;
}

function ScopesItem({ scopes, title }: ScopesItemProps) {
  const [isActive, open, close] = useBoolean(true);

  if (!scopes || !scopes.length) return null;

  return (
    <>
      <ScopesItemHeaderLayout
        title={<Title type="h5">{title}</Title>}
        rigthIcon={isActive ? <ChevronUpIcon /> : <ChevronDownIcon />}
        onClickRigthIcon={isActive ? close : open}
      />

      {isActive && (
        <List>
          {scopes.map((scope) => (
            <List.Item key={scope}>
              <Text>{scope}</Text>
            </List.Item>
          ))}
        </List>
      )}
    </>
  );
}

ScopesItem.Skeleton = Skeleton;

export default ScopesItem;
