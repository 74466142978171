import { v4 as uuidv4 } from 'uuid';
import {
  FulfillmentOrdersResponseDto,
  FulfillmentResponseDto,
  LogEntryResponseDto,
} from '@tiendanube/common';
import { Status } from '@tiendanube/common/src/enums';
import { Timeline, Card } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import CardTimelineErrorState from './CardTimelineErrorState';
import CardTimelineSkeleton from './CardTimelineSkeleton';
import useCardTimeline from './useCardTimeline';

interface CardTimelineProps {
  fulfillment: FulfillmentResponseDto;
  fulfillmentOrders?: FulfillmentOrdersResponseDto[];
  logEntries: LogEntryResponseDto[];
  isShippablePickup: boolean;
  isManualPayment: boolean;
}

function CardTimeline({
  logEntries,
  fulfillment,
  fulfillmentOrders,
  isShippablePickup,
  isManualPayment,
}: CardTimelineProps) {
  const t = useTranslationOrders();
  const timelineProps = useCardTimeline({
    fulfillment,
    fulfillmentOrders,
    isShippablePickup,
  });
  const logClone = [...logEntries];

  const timeLogs = logClone
    .reverse()
    .filter(({ type, data2: action }) => {
      const pendingManualPayments =
        action === Status.PENDING && isManualPayment;
      const refundOrderEvents =
        type === 'order-payment' && action === 'refunded';

      return !pendingManualPayments && !refundOrderEvents;
    })
    .map((log) => <Timeline.Item key={uuidv4()} {...timelineProps(log)} />);

  if (timeLogs.length === 0) return null;

  return (
    <Card title={t('cardTimeline.title')}>
      <Timeline>{timeLogs}</Timeline>
    </Card>
  );
}

CardTimeline.Skeleton = CardTimelineSkeleton;
CardTimeline.ErrorState = CardTimelineErrorState;

export default CardTimeline;
