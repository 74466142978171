import { AxiosResponse } from 'axios';
import {
  LoginRequestDto,
  LoginResponseDto,
  AuthSyncResponseDto,
  FeatureUsesResponseDto,
  ValidateCredentialsRequestDto,
  ValidateCredentialsResponseDto,
  TokenRequestDto,
  AuthorizeResponseDto,
  LoginAuthorizationCodeRequestDto,
} from '@tiendanube/common';
import axios, {
  setTokenToAxiosInstance,
  cleanTokenInAxiosInstance,
} from 'App/axios';
import saveLanguage from 'App/i18n/saveLanguage';
import { cleanupKeyboardConfig } from 'App/keyboard';
import { getStorage, setStorage, removeSessionKeys } from 'App/storage';
import getUrlWithParams from 'commons/utils/getUrlWithParams/getUrlWithParams';
import goToAdmin from 'commons/utils/gotToAdmin';
import {
  AUTH_FEATURE_USES_URL,
  AUTH_INFO_SYNC_URL,
  AUTH_LOGIN_URL,
  AUTH_V1_TOKEN_URL,
  AUTH_V1_URL,
  AUTH_VALIDATE_CREDENTIALS_URL,
} from './urls';
import { OLD_ADMIN_LOGOUT_ROUTE } from '../constants';

export const validateCredentials = async (
  payload: ValidateCredentialsRequestDto,
): Promise<ValidateCredentialsResponseDto> => {
  const { data } = await axios.post<ValidateCredentialsResponseDto>(
    AUTH_VALIDATE_CREDENTIALS_URL,
    payload,
  );
  return data;
};

const doLogin = async (data: LoginResponseDto): Promise<void> => {
  setTokenToAxiosInstance(data.accessToken);
  const authStoragePromise = setAuthStorage(data);
  const saveLanguagePromise = saveLanguage(data.defaultLanguage, data.country);
  const removeSessionKeysPromise = removeSessionKeys();
  await Promise.all([
    authStoragePromise,
    saveLanguagePromise,
    removeSessionKeysPromise,
  ]);
};

export const authorize = async ({
  email,
  password,
}: LoginRequestDto): Promise<AuthorizeResponseDto> => {
  try {
    const { data } = await axios.post<AuthorizeResponseDto>(AUTH_V1_URL, {
      email,
      password,
    });
    return data;
  } catch (error) {
    if (error?.isAxiosError && error.response?.status === 401) {
      throw new Error('401');
    }
    throw new Error(error?.response?.status || '500');
  }
};

export const createSession = async (
  payload: TokenRequestDto,
): Promise<LoginResponseDto> => {
  try {
    const { data } = await axios.post<
      LoginResponseDto,
      AxiosResponse<LoginResponseDto>,
      TokenRequestDto
    >(AUTH_V1_TOKEN_URL, payload);
    await doLogin(data);
    return data;
  } catch (error) {
    const defaultError = new Error(error?.response?.status || '500');
    const errorMessage = error.response?.data?.response?.message;

    if (!error?.isAxiosError) throw defaultError;
    if (error.response?.status === 401) {
      throw new Error('401');
    }
    if (error.response?.status === 408) {
      throw new Error('408');
    }
    if (error.response?.status === 403 && errorMessage === 'limited_access') {
      throw new Error('403');
    }
    throw defaultError;
  }
};

export const getAuthByToken = async (
  payload: LoginAuthorizationCodeRequestDto,
): Promise<LoginResponseDto | undefined> => {
  const { data } = await axios.get<LoginResponseDto>(AUTH_LOGIN_URL, {
    params: payload,
  });

  if (data) {
    await doLogin(data);
  }
  return data;
};

export const getInfoToSync = async (
  noCache = false,
): Promise<AuthSyncResponseDto | undefined> => {
  try {
    const { data } = await axios.get<AuthSyncResponseDto>(AUTH_INFO_SYNC_URL, {
      headers: {
        'x-not-cache': String(noCache),
      },
    });
    if (data) {
      updateAuthStorage(data);
    }
    return data;
  } catch (error) {
    return;
  }
};

const afterActionsLogout = (isMobile: boolean) => {
  if (!isMobile) {
    /**
     * in the web version when logout is executed it will
     * be forwarded to the root of the admin path in old admin
     * if authentication is active the user will decide whether
     * to return to the screen in New Admin
     */
    goToAdmin(OLD_ADMIN_LOGOUT_ROUTE)();
  }
};

export const logout = async (isMobile: boolean): Promise<void> => {
  cleanTokenInAxiosInstance();
  await cleanAuthStorage();
  cleanupKeyboardConfig();
  afterActionsLogout(isMobile);
};

export const getAuthStorage = async (): Promise<
  LoginResponseDto | undefined
> => {
  try {
    const state: LoginResponseDto = await getStorage('auth');
    if (state) {
      setTokenToAxiosInstance(state.accessToken);
      return state;
    }
    return;
  } catch (error) {
    return;
  }
};

export const setAuthStorage = async (data: LoginResponseDto): Promise<void> => {
  try {
    await setStorage('auth', data);
  } catch (error) {
    return;
  }
};

export const cleanAuthStorage = async (): Promise<void> => {
  await setStorage('auth', null);
};

const updateAuthStorage = async (data: AuthSyncResponseDto): Promise<void> => {
  const currentData = await getAuthStorage();
  if (currentData) {
    setAuthStorage({ ...currentData, ...data } as LoginResponseDto);
  }
};

export const getFeatureUsesByKey = async (
  featureCode: string,
): Promise<FeatureUsesResponseDto> => {
  const { data } = await axios.get<FeatureUsesResponseDto>(
    getUrlWithParams(AUTH_FEATURE_USES_URL, { featureCode }),
  );

  return data;
};
