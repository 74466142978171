import { Alert, Link, Text } from '@nimbus-ds/components';
import goToAdmin from 'commons/utils/gotToAdmin';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

function InvoiceAlert() {
  const t = useTranslationCatalog('products.detail.invoice');

  //TODO: The redirection will be to another admin, then delete.
  const goToEnvoice = goToAdmin('nuvempago/dashboard');

  const handleClick = () => {
    goToEnvoice();
  };
  return (
    <Alert appearance="primary" title={t('titleAlert')}>
      <Text>{t('contentAlert')}</Text>
      <Link onClick={handleClick}>{t('linkAlert')}</Link>
    </Alert>
  );
}

export default InvoiceAlert;
