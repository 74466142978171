import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
import {
  ThousandSeparatorText,
  ColorAndText,
} from 'domains/Statistics/components';
import { useGetFormatedDateTooltip } from 'domains/Statistics/hooks';
import { capitalizeFirstLetter } from 'domains/Statistics/utils';
import { StratusTooltipContentProps } from '../../StratusTooltipContent';
import '../../StratusTooltipContent.scss';

function StratusTooltipContentDefault({
  label,
  payload,
  formatLabel = (text: string) => text,
  granularity,
}: Omit<StratusTooltipContentProps, 'hasComparedData'>) {
  const getDateFormated = useGetFormatedDateTooltip(granularity);
  return (
    <Stack column align="stretch" spacing="tight">
      {!!label && (
        <Text size="caption">
          {capitalizeFirstLetter(getDateFormated(label || ''))}
        </Text>
      )}
      {payload?.map(({ name, value, color }) => (
        <Stack key={name} spacing="tight" wrap justify="space-between">
          <div className="stratus--tooltip-content__max-width">
            <ColorAndText color={color} text={formatLabel(name as string)} />
          </div>
          <ThousandSeparatorText
            size="caption"
            value={(value as number) ?? 0}
            withoutDecimal={false}
          />
        </Stack>
      ))}
    </Stack>
  );
}

export default StratusTooltipContentDefault;
