import { useCallback } from 'react';
import { Box, Icon, Radio, Text } from '@nimbus-ds/components';
import { IconProps } from '@nimbus-ds/icons';
import { CheckoutPaymentMethod } from '@tiendanube/common';
import { usePayorderToPay } from 'domains/Billing/Checkout/hooks';
import useTranslatePaymentMethod from 'domains/Billing/Checkout/hooks/useTranslatePaymentMethod';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface PaymentOptionProps {
  icon: React.FC<IconProps>;
  setPaymentMethodSelected: (method: CheckoutPaymentMethod) => void;
  paymentMethodSelected: CheckoutPaymentMethod;
  method: CheckoutPaymentMethod;
  isLoading: boolean;
}

function PaymentOption({
  icon,
  method,
  setPaymentMethodSelected,
  isLoading,
  paymentMethodSelected,
}: Readonly<PaymentOptionProps>) {
  const t = useTranslationBilling(
    `checkout.choosePaymentMethodForPayOrder.paymentSelection`,
  );
  const { translateCheckoutPaymentMethod } = useTranslatePaymentMethod();
  const { feePercentageForMethod } = usePayorderToPay();

  const handleClick = useCallback(() => {
    if (!isLoading) {
      setPaymentMethodSelected(method);
    }
  }, [setPaymentMethodSelected, isLoading, method]);

  const feePercentage = feePercentageForMethod(method);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      onClick={handleClick}
    >
      <Box display="flex" flexDirection="row" gap="3">
        {icon && <Icon source={icon({})} />}
        <Box display="flex" flexDirection="column" gap="1">
          <Text>{translateCheckoutPaymentMethod(method)}</Text>
          {method === 'cc' && (
            <Text fontSize="caption" color="neutral-textLow">
              {t('ccSubtitle')}
            </Text>
          )}
          {!!feePercentage && (
            <Text fontSize="caption" color="neutral-textLow">
              {t('extraFee', { percentage: feePercentage })}
            </Text>
          )}
        </Box>
      </Box>
      <Box>
        <Radio
          name="payment-option"
          checked={paymentMethodSelected === method}
          id={method}
          disabled={isLoading}
        />
      </Box>
    </Box>
  );
}

export default PaymentOption;
