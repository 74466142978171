import { useEffect } from 'react';
import userflow from 'userflow.js';
import { useGetPlan, useGetUserInfo } from 'domains/Auth/hooks';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import { config } from './config';

function Userflow(): null {
  const { email, id, isMain, name } = useGetUserInfo();
  const {
    id: storeId,
    country: countryCode,
    url: storeUrl,
    createdAt,
    currentSegment,
  } = useStoreInfo();
  const { niceName } = useGetPlan();

  useEffect(() => {
    if (!config.id) return;

    userflow.init(config.id);
    userflow.identify(email, {
      userId: id,
      email,
      isMain,
      name,
    });
    userflow.group(storeId, {
      storeUrl,
      countryCode,
      statusByOrder: currentSegment,
      storePlan: niceName,
      signUpAt: createdAt,
    });
  }, [
    email,
    storeId,
    createdAt,
    niceName,
    storeUrl,
    currentSegment,
    countryCode,
    id,
    isMain,
    name,
  ]);

  return null;
}

export default Userflow;
