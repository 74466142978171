import { Card, Title, Text, Link } from '@nimbus-ds/components';
import { Trans } from 'react-i18next';
import { InterfaceNameValue } from '@tiendanube/components';
import { RfcInput, Stack } from 'commons/components';
import { InterfaceNameBooleanValue } from 'commons/types';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

const TRANSLATE_PREFIX = 'nuvempagoSettings';

interface NuvempagoInitMexicanCard {
  readonly onChange: (
    data: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => void;
  readonly document: string;
  readonly error?: string;
}

function NuvempagoInitMexicanCard({
  document,
  onChange,
  error,
}: NuvempagoInitMexicanCard) {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  return (
    <>
      <Card>
        <Card.Header>
          <Title as="h3">{t('card.title')}</Title>
        </Card.Header>
        <Card.Body>
          <Stack column align="flex-start">
            <Text>{t('card.subtitle')}</Text>
            <RfcInput
              onChange={onChange}
              value={document}
              name="document"
              label={t('card.identification')}
              helpText={t(error || 'card.helpText')}
              appearance={error ? 'danger' : undefined}
            />
          </Stack>
        </Card.Body>
      </Card>
      <Text>
        <Trans
          t={t}
          i18nKey="disclaimer"
          components={{
            TyCLink: (
              <Link
                as="a"
                appearance="primary"
                href={t('tycLink')}
                target="_blank"
              >
                {' '}
              </Link>
            ),
          }}
        />
      </Text>
    </>
  );
}

export default NuvempagoInitMexicanCard;
