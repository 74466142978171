import { useState } from 'react';
import { FileAltIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CurrencyType } from '@tiendanube/common';
import { Domain as DomainEnum } from '@tiendanube/common/src/enums';
import { DataList, Text, Stack, Link, Tooltip } from '@tiendanube/components';
import {
  StickyNoteIcon,
  ChatDotsIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@tiendanube/icons';
import { useToastStatus } from 'commons/hooks';
import { formatCurrency } from 'commons/utils';
import { dateFormat, Format } from 'commons/utils/date';
import { ResultProductsMobile } from 'domains/Orders/components';
import { useOrderListItem } from 'domains/Orders/Orders/hooks';
import OriginLabel, {
  showOriginLabel,
} from '../../../../../commons/OriginLabel';
import {
  ColumnFulfillmentStatus,
  ColumnPaymentStatus,
} from '../../../../../commons/Status';

const { Row, Cell } = DataList;

interface OrderListItemProps {
  id: string;
}

function OrderListItem({ id }: OrderListItemProps) {
  const { push } = useHistory();
  const {
    t,
    i18n: { language },
  } = useTranslation([DomainEnum.ORDERS]);

  const { order, getOrderProducts, isLoadingProducts, isErrorProducts } =
    useOrderListItem(id);
  const [showProducts, setShowProducts] = useState(false);

  const handleShowProducts = () => {
    const showProductsUpdated = !showProducts;
    setShowProducts(showProductsUpdated);
    if (!order) return;
    if (showProductsUpdated && !order.products && !isLoadingProducts) {
      getOrderProducts();
    }
  };

  useToastStatus({
    error: t('toast.errorProductList'),
    status: isErrorProducts ? 'error' : 'idle',
  });

  if (!order) return null;

  const productsQuantity = order.quantityProducts;

  const goToOrder = () => {
    push(`/orders/${order.id}`);
  };

  return (
    <>
      <Row id={id} onClick={goToOrder}>
        <Cell width={60} rowTitle>
          <Stack spacing="tight">
            <Stack.Item>
              <Link appearance="primary" onClick={goToOrder}>
                {`${t('resultRow.title', { number: order.number })}`}
              </Link>
            </Stack.Item>
            {!!order?.orderOrigin && (
              <Tooltip labelIcon={FileAltIcon} labelText={order.orderOrigin}>
                <Text>{order.orderOrigin}</Text>
              </Tooltip>
            )}
            {!!order.remarks && (
              <Stack.Item>
                <Tooltip labelIcon={StickyNoteIcon} labelText={order.remarks}>
                  <Text>{order.remarks}</Text>
                </Tooltip>
              </Stack.Item>
            )}
            {showOriginLabel(order) && (
              <Stack.Item>
                <OriginLabel order={order} />
              </Stack.Item>
            )}
          </Stack>
        </Cell>
        <Cell width={40} alignRight>
          <Text>{dateFormat(order.date, Format.DAY_MONTH)}</Text>
        </Cell>
        <Cell width={60}>
          <Stack spacing="tight">
            {order.consumer && order.consumer.name !== '' ? (
              <>
                <Stack.Item>
                  <Text trimText trimLines={1}>
                    {order.consumer.name}
                  </Text>
                </Stack.Item>
                {!!order.consumer.remarks && (
                  <Stack.Item>
                    <Tooltip
                      labelIcon={ChatDotsIcon}
                      labelText={order.consumer.remarks}
                    >
                      <Text>{order.consumer.remarks}</Text>
                    </Tooltip>
                  </Stack.Item>
                )}
              </>
            ) : (
              <Stack.Item>
                <Text appearance="light">{t('without-customer')}</Text>
              </Stack.Item>
            )}
          </Stack>
        </Cell>
        <Cell width={40} alignRight>
          <Text key="4">
            {formatCurrency(order.total, order.currency, language)}
          </Text>
        </Cell>
        <Cell width={100}>
          <Link
            appearance="primary"
            icon={showProducts ? ChevronUpIcon : ChevronDownIcon}
            iconPosition="end"
            onClick={handleShowProducts}
          >
            {t('resultRow.products.label', { count: productsQuantity })}
          </Link>
        </Cell>
        <Cell width="auto" extraPadding>
          <ColumnPaymentStatus key={order.id} order={order} />
        </Cell>
        <Cell width="auto" extraPadding>
          <ColumnFulfillmentStatus key={order.id} order={order} />
        </Cell>
      </Row>
      <ResultProductsMobile
        show={showProducts}
        products={order.products}
        quantityUniqueProducts={order.quantityUniqueProducts}
        currency={order.currency as CurrencyType}
      />
    </>
  );
}

export default OrderListItem;
