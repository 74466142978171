import { Menu } from '@nimbus-ds/patterns';
import classNames from 'classnames';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { useGetTags } from 'domains/Auth/hooks';
import AccountMenuContent from './components/AccountMenuContent';
import NewAccountMenuContent from './components/NewAccountMenuContent';

interface AccountMenuProps {
  visible: boolean;
  device: 'mobile' | 'desktop';
}

function AccountMenuWeb({ visible, device }: AccountMenuProps): JSX.Element {
  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);
  return (
    <div
      className={classNames('stratus--menu-container', 'stratus--submenu', {
        visible: visible,
      })}
    >
      <Menu>
        {isRevampMenu ? (
          <NewAccountMenuContent device={device} />
        ) : (
          <AccountMenuContent device={device} />
        )}
      </Menu>
    </div>
  );
}

export default AccountMenuWeb;
