import { Link } from '@nimbus-ds/components';
import { StoreIcon } from '@nimbus-ds/icons';
import { CalloutCard } from '@nimbus-ds/patterns';
import { PointOfSaleData } from '@tiendanube/common';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface PosExtraInfoProps {
  posExtraInfo: PointOfSaleData;
}

export default function PosExtraInfo({
  posExtraInfo: { url, cost },
}: PosExtraInfoProps) {
  const t = useTranslationBilling('billingPlansPage.posExtraInfo');
  const formatCurrency = useFormatCurrency();

  if (!cost || Number(cost) <= 0) return null;

  return (
    <CalloutCard
      appearance="primary"
      title={t('title')}
      icon={StoreIcon}
      subtitle={t('subtitle', { cost: formatCurrency(Number(cost)) })}
      link={
        <Link fontSize="caption" as="a" target="_blank" href={url}>
          {t('details')}
        </Link>
      }
    />
  );
}
