export const PromotionsEndpoints = {
  promotionsList: 'list',
  promotionsListInf: 'listInf',
  hasAny: 'hasAny',
  setPromotionStatus: 'setPromotionStatus',
  deletePromotion: 'deletePromotion',
  promotionTypes: 'promotionTypes',
  createPromotion: 'createPromotion',
  getPromotion: 'getPromotion',
  updatePromotion: 'updatePromotion',
} as const;

export const BASE_PROMOTIONS_URL = '/v1/promotions';
export const PROMOTION_TYPES_URL = `${BASE_PROMOTIONS_URL}/types`;
export const PROMOTION_STATUS_URL = `${BASE_PROMOTIONS_URL}/:id/:status`;
export const PROMOTION_ID_URL = `${BASE_PROMOTIONS_URL}/:id`;
export const PROMOTION_HAS_ANY_URL = `${BASE_PROMOTIONS_URL}/has_any`;
