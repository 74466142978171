import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  fetchExternalInvoiceHistoryByScroll as fetchExternalInvoiceHistoryAction,
  getExternalInvoiceHistory,
  getExternalInvoiceHistoryStatus,
  fetchExternalInvoiceHistoryByPagination,
  getExternalInvoiceHistoryUpdateStatus,
} from 'domains/Billing/Invoices/invoicesSlice';
import { ExternalInvoiceMerchantService } from '../../types';

function useExternalInvoiceHistoryMobile(
  merchantService: ExternalInvoiceMerchantService,
) {
  const dispatch = useAppDispatch();
  const externalInvoiceHistory = useSelector(getExternalInvoiceHistory);
  const status = useSelector(getExternalInvoiceHistoryStatus);
  const updateStatus = useSelector(getExternalInvoiceHistoryUpdateStatus);

  const fetchMoreExternalInvoiceHistory = useCallback(() => {
    dispatch(fetchExternalInvoiceHistoryAction(merchantService));
  }, [dispatch, merchantService]);

  const fetchExternalInvoiceHistory = useCallback(async () => {
    status === 'idle' &&
      (await dispatch(
        fetchExternalInvoiceHistoryByPagination({ page: 1, merchantService }),
      ));
  }, [dispatch, merchantService, status]);

  return {
    status,
    updateStatus,
    externalInvoiceHistory,
    fetchExternalInvoiceHistory,
    fetchMoreExternalInvoiceHistory,
  };
}

export default useExternalInvoiceHistoryMobile;
