import { ChangeEvent } from 'react';
import { Checkbox, Icon, Label } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import { InterfaceNameValue } from '@tiendanube/components';
import { CardWithTitle, Stack } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { VisibilityCategoryType } from 'domains/Catalog/Products/utils';

interface VisibilityProps {
  visibility: VisibilityCategoryType;
  hasChildren?: boolean;
  disabled: boolean;
  onChange: (data: InterfaceNameValue) => void;
}

function Visibility({
  visibility,
  hasChildren = false,
  disabled,
  onChange,
}: Readonly<VisibilityProps>): JSX.Element | null {
  const t = useTranslationCatalog('categories.detail.visibility');

  const handleOnChange = ({
    target: { name, checked },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange({
      name,
      value: checked ? 'visible' : 'hidden',
    });
  };

  return (
    <CardWithTitle title={t('title')}>
      <Stack column spacing="tight" align="stretch">
        <Checkbox
          label={t('label')}
          name="visibility"
          checked={visibility === 'visible'}
          disabled={disabled}
          onChange={handleOnChange}
        />
        {disabled && (
          <Label>
            <Icon source={<InfoCircleIcon />} />
            {t('disabled')}
          </Label>
        )}
        {!disabled && hasChildren && visibility === 'visible' && (
          <Label>
            <Icon source={<InfoCircleIcon />} />
            {t('hasChildren.visible')}
          </Label>
        )}
        {!disabled && hasChildren && visibility !== 'visible' && (
          <Label>
            <Icon source={<InfoCircleIcon />} />
            {t('hasChildren.hidden')}
          </Label>
        )}
      </Stack>
    </CardWithTitle>
  );
}

export default Visibility;
