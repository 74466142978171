import { Box, Card, Title } from '@nimbus-ds/components';
import { InformationForContentDeclarationResponseDto } from '@tiendanube/common';
import { useResponsive } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import ContentDeclarationPreview from '../ContentDeclarationPreview';

interface BoxContentDeclarationPreviewProps {
  information?: InformationForContentDeclarationResponseDto[];
  isLoading: boolean;
  locationId: string;
  componentRef?: React.RefObject<HTMLDivElement>;
}

function BoxContentDeclarationPreview({
  information,
  isLoading,
  locationId,
  componentRef,
}: Readonly<BoxContentDeclarationPreviewProps>) {
  const t = useTranslationFulfillmentOrders('printManager');
  const { isMobile } = useResponsive();

  const width = isMobile ? '100%' : '45vw';
  const alignItems = isLoading ? 'center' : 'flex-start';

  return (
    <Card>
      <Card.Header>
        <Title as="h3">{t('viewTitle')}</Title>
      </Card.Header>
      <Card.Body>
        <Box
          id="print-container-content-declaration"
          backgroundColor="neutral-surfaceDisabled"
          borderColor="neutral-surfaceDisabled"
          borderRadius="2"
          width={width}
          minHeight="55vh"
          maxHeight="65vh"
          overflowX="auto"
          display="flex"
          alignItems={alignItems}
          justifyContent="center"
          flexWrap="wrap"
          ref={componentRef}
        >
          {!isLoading && information && (
            <ContentDeclarationPreview
              information={information}
              locationId={locationId}
            />
          )}
        </Box>
      </Card.Body>
    </Card>
  );
}

export default BoxContentDeclarationPreview;
