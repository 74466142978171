import { BaseCard, Button } from '@tiendanube/components';
import Link from 'commons/components/LegacyLink';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';

function Skeleton(): JSX.Element {
  return (
    <Stack column justify="space-between" align="stretch">
      <BaseCard>
        <BaseCard.Header>
          <Text.Skeleton size="base" width="large" />
        </BaseCard.Header>
        <BaseCard.Body>
          <Stack column justify="space-between" align="stretch">
            <Text.Skeleton width="fill" />
            <Link.Skeleton />
            <Text.Skeleton width="fill" />
          </Stack>
        </BaseCard.Body>
      </BaseCard>
      <Stack justify="flex-end">
        <Button.Skeleton />
        <Button.Skeleton />
      </Stack>
    </Stack>
  );
}

export default Skeleton;
