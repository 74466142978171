import { useEffect, useState } from 'react';
import { InputNumberNimbus } from 'commons/components';
import { ItemProps } from 'domains/Metafields/components/MetafieldInput/types';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';
import useMergeMetafieldsEvents from 'domains/Metafields/hooks/useMergeMetafieldsEvents';

function NumberItem({
  metafield,
  metafieldKey,
  disabled,
  value,
  onChange,
  onUpdate,
  placeholder,
}: ItemProps) {
  const [internalValue, setInternalValue] = useState(value);
  const t = useTranslationsMetafields();
  const inputPlaceholder =
    placeholder || t('assignMetafieldsForm.placeholders.textOrNumber');

  const handleChange = (event) => {
    const value = event.target.value;
    setInternalValue(value);
    onChange?.(value);
  };
  const handleBlur = (event) => {
    const value = event.target.value;
    setInternalValue(value);
    onUpdate?.(value || null);
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const inputEvents = {
    onChange: handleChange,
    onBlur: handleBlur,
  };

  const mergeEvents = useMergeMetafieldsEvents(
    inputEvents,
    metafield.formFieldConfig?.events,
  );
  const mergedEvents = mergeEvents();

  return (
    <InputNumberNimbus
      id={metafieldKey}
      type="float5d"
      disabled={disabled !== undefined ? disabled : metafield.disabled}
      name={metafield.name}
      value={internalValue}
      placeholder={inputPlaceholder}
      {...mergedEvents}
    />
  );
}

export default NumberItem;
