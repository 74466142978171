import { IconButton, Tag, Tooltip } from '@nimbus-ds/components';
import { ExternalLinkIcon, OnlineStoreIcon } from '@nimbus-ds/icons';
import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { useHandleMenuClick } from 'App/hooks';
import { trackingMenuStoreVisit } from 'App/tracking';
import goToAdmin from 'commons/utils/gotToAdmin';
import { openWindow } from 'commons/utils/window';
import { useGetTags, useHeaderMenuData } from 'domains/Auth/hooks';
import { themesRoutes } from 'domains/Online/Themes';
import {
  menuSecondaryBlogClick,
  menuSecondaryNavigationClick,
  menuSecondaryPagesClick,
  menuSecondaryPasswordProtectedClick,
  menuSecondaryPreferencesClick,
  menuSecondaryThemesClick,
} from './tracking';
import './CustomizeMenu.scss';

const PATHS = {
  customize: '/themes',
  pages: '/pages',
  blog: '/blog',
  navigation: '/navigation',
  filters: '/filters',
  contactInfo: themesRoutes.contactInfo,
  socialNetworks: themesRoutes.socialNetworks,
  pageUnderConstruction: themesRoutes.passwordProtected,
};

function CustomizeMenu() {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem, handleClickAccordion } = useHandleMenuClick();
  const { url } = useHeaderMenuData();
  const tags = useGetTags();
  const isRevampMenu = tags.includes(REVAMP_MENU_V1);

  const menuActive = Object.values(PATHS).some((path) =>
    pathname.startsWith(path),
  );

  const menuItem = (
    name: string,
    path: string,
    redirectToAdmin = true,
    isNewFeature = false,
    trackingLog: () => void = () => null,
  ) => ({
    children: `${t(`menu.salesChannels.myStore.${name}`)}`,
    active:
      pathname.endsWith(path) ||
      pathname.endsWith(path + '/') ||
      (pathname.startsWith(path) && path === PATHS.pages),
    onClick: () => {
      trackingLog();
      redirectToAdmin ? goToAdmin(path)() : handleClickItem();
    },
    label: isNewFeature && t('menu.new'),
    href: (redirectToAdmin ? '/admin' : '') + path,
    external: redirectToAdmin,
  });

  const menuItems = [
    menuItem('design', PATHS.customize, false, false, menuSecondaryThemesClick),
    menuItem('pages', PATHS.pages, false, false, menuSecondaryPagesClick),
    menuItem('blog', PATHS.blog, false, true, menuSecondaryBlogClick),
    menuItem(
      'navigation',
      PATHS.navigation,
      false,
      false,
      menuSecondaryNavigationClick,
    ),
    menuItem('filters', PATHS.filters, false),
    isRevampMenu
      ? menuItem(
          'linksSocialNetworks',
          PATHS.socialNetworks,
          false,
          false,
          menuSecondaryPreferencesClick,
        )
      : menuItem(
          'contactInfo',
          PATHS.contactInfo,
          false,
          false,
          menuSecondaryPreferencesClick,
        ),
    menuItem(
      'pageUnderConstruction',
      PATHS.pageUnderConstruction,
      false,
      false,
      menuSecondaryPasswordProtectedClick,
    ),
  ].filter(Boolean);

  const handleMenuStoreVisitClick = () => {
    trackingMenuStoreVisit();
    openWindow(url, true);
  };

  const handleClickMenuAccordion = () => {
    menuSecondaryThemesClick();
    handleClickAccordion();
  };

  return (
    <MenuButton.Accordion
      contentid="content-customize"
      menuButton={{
        id: 'control-customize',
        children: (
          <Tooltip
            content={`Ir a ${t(
              'menu.salesChannels.myStore.title',
            ).toLowerCase()}`}
            position="right"
          >
            <IconButton
              size="1.125rem"
              source={<ExternalLinkIcon size={10} />}
              onClick={handleMenuStoreVisitClick}
            />
          </Tooltip>
        ),
        label: t('menu.salesChannels.myStore.storeFront'),
        startIcon: OnlineStoreIcon,
        'aria-controls': 'content-customize',
        onClick: handleClickMenuAccordion,
      }}
      open={menuActive}
      active={menuActive}
      as={Link}
      to={PATHS.customize}
    >
      {menuItems.map((item, index) =>
        item && !item.external ? (
          <MenuButton
            key={index}
            as={Link}
            label={item.children}
            onClick={item.onClick}
            to={item.href}
            active={item.active}
          >
            {!!item.label && <Tag appearance="primary">{item.label}</Tag>}
          </MenuButton>
        ) : (
          item && (
            <MenuButton
              key={index}
              as="a"
              label={item.children}
              href={item.href}
              active={item.active}
            />
          )
        ),
      )}
    </MenuButton.Accordion>
  );
}

export default CustomizeMenu;
