import { Card, ErrorState } from '@tiendanube/components';
import { useTranslationCustomers } from 'domains/Customers/hooks';

interface PriceTablesErrorStateProps {
  onRetry: () => void;
}

export default function PriceTablesErrorState({
  onRetry,
}: Readonly<PriceTablesErrorStateProps>) {
  const t = useTranslationCustomers();
  const tPath = 'customerForm.priceTablesInformation';
  return (
    <Card title={t(`${tPath}.title`)}>
      <ErrorState
        title={t(`${tPath}.errorState.title`)}
        action={{
          children: t(`${tPath}.errorState.action`),
          onClick: onRetry,
        }}
      />
    </Card>
  );
}
