import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { DataTable, Stack, Text } from '@tiendanube/components';
import { ChevronDownIcon, ChevronUpIcon } from '@tiendanube/icons';
import { SortType } from 'commons/types';
import NuvemEnvioPopover from '../NuvemEnvioPopover';

const { Header, Cell } = DataTable;

interface ResultHeaderDesktopProps {
  sortByDate: SortType;
  setSortByDate: (value: SortType) => void;
}

function ResultHeaderDesktop({
  sortByDate,
  setSortByDate,
}: ResultHeaderDesktopProps): JSX.Element {
  const { t } = useTranslation([Domain.ORDERS]);

  const toggleSortByDate = () => {
    setSortByDate(sortByDate === 'asc' ? 'desc' : 'asc');
  };

  return (
    <Header>
      <Cell grow={0} basis={90}>
        <Text>{t('resultHeader.order')}</Text>
      </Cell>
      <Cell grow={0} basis={70}>
        <div
          className="stratus--orders-result-desktop__sort"
          onClick={toggleSortByDate}
        >
          <Stack spacing="extra-tight">
            <Text>{t('resultHeader.date')}</Text>
            {sortByDate === 'asc' ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </Stack>
        </div>
      </Cell>
      <Cell grow={1} basis={100}>
        <Text>{t('resultHeader.buyer')}</Text>
      </Cell>
      <Cell grow={0} basis={125}>
        <Text>{t('resultHeader.total')}</Text>
      </Cell>
      <Cell grow={0} basis={100}>
        <Text>{t('resultHeader.products')}</Text>
      </Cell>
      <Cell grow={0} basis={200}>
        <Text>{t('resultHeader.paymentStatus')}</Text>
      </Cell>
      <Cell grow={0} basis={205}>
        <NuvemEnvioPopover>
          <Text>{t('resultHeader.fulfillmentStatus')}</Text>
        </NuvemEnvioPopover>
      </Cell>
      <Cell grow={0} basis={80}>
        <Text>{t('resultHeader.actions')}</Text>
      </Cell>
    </Header>
  );
}

export default ResultHeaderDesktop;
