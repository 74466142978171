import { Box } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import {
  CurrencyType,
  RefundAttemptDto,
  ValuesBusinessInfoResponseDto,
} from '@tiendanube/common';
import { OrderDetailsTaxesResponseDto } from '@tiendanube/common/src/domains/orders/orders/OrderDetailsResponse';
import { Domain } from '@tiendanube/common/src/enums';
import { DataList, Text } from '@tiendanube/components';
import { CurrencyPrice } from 'commons/components';
import OrderTaxesAmountTooltip from './components/OrderTaxesAmountTooltip/OrderTaxesAmountTooltip';
import useCanBeRefunded from '../../../Orders/pages/hooks/useCanBeRefunded';

interface Attributes {
  isEdited: boolean;
  isTotalUpdated: boolean;
  total: number;
  currency: CurrencyType;
  refundAttempts: RefundAttemptDto[];
  billingAddressCountry?: string;
  businessDataValues: ValuesBusinessInfoResponseDto;
  taxes?: OrderDetailsTaxesResponseDto;
}

function OrderTotalAmountRow({
  isEdited,
  isTotalUpdated,
  total,
  refundAttempts,
  currency,
  billingAddressCountry,
  businessDataValues,
  taxes,
}: Attributes): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);
  const { hasRefundTag } = useCanBeRefunded(null);

  const hasBeenRefunded = () =>
    refundAttempts.filter((attempt) => attempt.status.code === 'success')
      .length > 0;
  const showRefundDetails = () =>
    hasBeenRefunded() && !isEdited && hasRefundTag();
  const refundedAmount = () =>
    refundAttempts
      .filter((a) => a.status.code === 'success')
      .reduce((sum, attempt) => sum + Number(attempt.refunded_amount.value), 0);

  return (
    <>
      {showRefundDetails() && (
        <>
          <DataList.Row id="total" grayed>
            <DataList.Cell width={70}>
              <Box display="flex" flexDirection="row" gap="0-5">
                <Text bold>
                  {t('orderSummaryCard.tableAmounts.totalBeforeRefund')}
                </Text>
                <OrderTaxesAmountTooltip
                  billingAddressCountry={billingAddressCountry}
                  businessDataValues={businessDataValues}
                  taxes={taxes}
                  currency={currency}
                />
              </Box>
            </DataList.Cell>
            <DataList.Cell width={30}>
              <CurrencyPrice
                price={total}
                currency={currency}
                block
                textAlign="right"
                bold
              />
            </DataList.Cell>
          </DataList.Row>

          <DataList.Row id="refund_details">
            <DataList.Cell width={70}>
              <Text>{t('orderSummaryCard.tableAmounts.refundedAmount')}</Text>
            </DataList.Cell>
            <DataList.Cell width={30}>
              <CurrencyPrice
                appearance="danger"
                price={-refundedAmount()}
                currency={currency}
                block
                textAlign="right"
              />
            </DataList.Cell>
          </DataList.Row>
        </>
      )}

      <DataList.Row id="total" grayed>
        <DataList.Cell width={70}>
          <Box display="flex" flexDirection="row" gap="0-5">
            <Text bold>
              {isTotalUpdated
                ? t('orderSummaryCard.tableAmounts.updatedTotal')
                : t('orderSummaryCard.tableAmounts.total')}
            </Text>
            {!showRefundDetails() && (
              <OrderTaxesAmountTooltip
                billingAddressCountry={billingAddressCountry}
                businessDataValues={businessDataValues}
                taxes={taxes}
                currency={currency}
              />
            )}
          </Box>
        </DataList.Cell>
        <DataList.Cell width={30}>
          <CurrencyPrice
            price={showRefundDetails() ? total - refundedAmount() : total}
            currency={currency}
            block
            textAlign="right"
            bold
          />
        </DataList.Cell>
      </DataList.Row>
    </>
  );
}
export default OrderTotalAmountRow;
