import { Text } from '@nimbus-ds/components';
import { TransactionFeePaymentDetailDto } from '@tiendanube/common';
import { DataList } from '@tiendanube/components';
import { ChevronRightIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import { CurrencyPrice } from 'commons/components';
import Stack from 'commons/components/Stack';
import { useGetLanguage } from 'domains/Auth/hooks';
import { getMonthName } from 'domains/Billing/TransactionFees/pages/utils';
import { TRANSACTION_FEES_ROUTES } from 'domains/Billing/TransactionFees/transactionFeesRoutes';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import MonthRowSkeleton from './MonthRowSkeleton';

const { Row, Cell } = DataList;

interface MonthRowProps {
  payment: TransactionFeePaymentDetailDto;
  split: boolean;
}

function MonthRow({ payment, split }: MonthRowProps): JSX.Element {
  const { goTo } = useNavegate();
  const t = useTranslationBilling();
  const lang = useGetLanguage();
  const monthName = getMonthName(payment.month, lang);

  const goToOrders = () => {
    if (split) {
      goTo(
        TRANSACTION_FEES_ROUTES.transactionFeesSplitMonthly.replace(
          ':paymentId',
          payment.id,
        ),
      );
    } else {
      goTo(
        TRANSACTION_FEES_ROUTES.transactionFeesNoSplitMonthly.replace(
          ':paymentId',
          payment.id,
        ),
      );
    }
  };

  return (
    <Row key={payment.id} id={payment.id} onClick={goToOrders}>
      <Cell width={70}>
        <Stack column align="flex-start">
          <Text color="primary-textLow">{monthName}</Text>
          <Text color="neutral-textLow" fontSize="caption" as="span">
            {`${t('transactionFeesMonthSelection.total')}: `}
            <CurrencyPrice
              price={Number(payment.total)}
              size="caption"
              block={false}
            />
          </Text>
        </Stack>
      </Cell>
      <Cell width={30} alignRight>
        <ChevronRightIcon />
      </Cell>
    </Row>
  );
}

MonthRow.Skeleton = MonthRowSkeleton;

export default MonthRow;
