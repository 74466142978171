import { Button, Text, Title } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';

function PickupPointItemSkeleton() {
  return (
    <DataList.Row>
      <Stack column align="flex-start">
        <Title.Skeleton as="h6" />
        <Text.Skeleton width="80%" />
        <Button.Skeleton />
      </Stack>
    </DataList.Row>
  );
}

export default PickupPointItemSkeleton;
