import { TFunction } from 'i18next';
import { CurrencyType, TaxDto } from '@tiendanube/common';
import { ChargeDisplayInterface } from '../PaymentDataContent';

export function getTaxesSummary(
  charges: ChargeDisplayInterface[],
  extraTaxes: TaxDto[],
  t: TFunction,
): ChargeDisplayInterface[] {
  const allTaxes = charges
    .reduce<
      {
        taxCode: string;
        amountValue: number;
        amountCurrency: CurrencyType;
        taxRate: number;
      }[]
    >((taxes, charge) => taxes.concat(charge.taxes ?? []), [])
    .concat(extraTaxes);

  const result: Record<string, ChargeDisplayInterface> = allTaxes.reduce(
    (result, { taxCode, amountCurrency, amountValue, taxRate }) => {
      const existingChargeDisplay = result[taxCode];
      if (existingChargeDisplay)
        existingChargeDisplay.amountValue += Number(amountValue);
      else {
        const explanationMessage = t(
          `taxCodeDescriptions.${taxCode}.explanationMessage`,
        );
        const explanationLink = t(
          `taxCodeDescriptions.${taxCode}.explanationLink`,
        );
        const title = t(`taxCodeDescriptions.${taxCode}.title`);
        result[taxCode] = {
          amountCurrency,
          amountValue: Number(amountValue),
          description: `${title} (${taxRate * 100}%)`,
          explanation:
            explanationMessage !== ''
              ? {
                  message: explanationMessage,
                  link: explanationLink,
                }
              : undefined,
        };
      }
      return result;
    },
    {},
  );
  return Object.values(result);
}
