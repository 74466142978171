import { CATALOG_INVOICE } from 'App/featuresFlags';
import { useGetCountry, useGetTags } from 'domains/Auth/hooks';

function useShowInvoiceCard(): boolean {
  const country = useGetCountry();
  const tags = useGetTags();
  const isBr = country === 'BR';
  const hasInvoiceCard = isBr && tags.includes(CATALOG_INVOICE);

  return hasInvoiceCard;
}

export default useShowInvoiceCard;
