import { useCallback } from 'react';
import { CheckoutPaymentMethod } from '@tiendanube/common';
import { toStatusType } from 'App/rtk';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import useAfterPaymentRedirection from './useAfterPaymentRedirection';
import { usePostMpPreferenceForPayOrderMutation } from '../CheckoutApi';

function useMercadoPagoPreference() {
  const [requestMpPreferenceForPayOrder, { status, reset }] =
    usePostMpPreferenceForPayOrderMutation();
  const { fullPath } = useAfterPaymentRedirection();

  const requestPreference = useCallback(
    (payOrderId: string, availablePaymentMethods: CheckoutPaymentMethod[]) =>
      requestMpPreferenceForPayOrder({
        payOrderId,
        backUrls: {
          success: fullPath,
          failure: fullPath,
          pending: fullPath,
        },
        availablePaymentMethods,
      }),
    [fullPath, requestMpPreferenceForPayOrder],
  );

  return {
    requestPreference,
    resetPreference: reset,
    status: toStatusType(status),
    ...convertStatusTypeToObject(status),
  };
}

export default useMercadoPagoPreference;
