import { OrderDetailsResponseDto, OrderResponseDto } from '@tiendanube/common';
import { PAYMENTS_REFUND_TAG } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { useHasPermission } from '../../../../../Auth/hooks';
import { useOrderIsEdited } from '../index';

export interface Result {
  canBeRefundedThroughCancelForm: () => boolean;
  canBeRefundedThroughRefundForm: () => boolean;
  hasRefundTag: () => boolean;
}

function useCanBeRefunded(
  orderDetails: Pick<
    OrderDetailsResponseDto | OrderResponseDto,
    'payment'
  > | null,
): Result {
  const isEdited = useOrderIsEdited();
  const canBeRefunded = orderDetails?.payment.canBeRefunded ?? false;
  const gateway = orderDetails?.payment.gateway ?? '';
  const [hasRefundTag] = useHasTags([PAYMENTS_REFUND_TAG]);
  const hasRefundOrderPermission = useHasPermission('refund_order');
  const isNuvemPagoPayment = ['Pago Nube', 'Nuvem Pago'].includes(gateway);
  const isRefundAllowed = canBeRefunded && hasRefundOrderPermission;
  const isRefundAllowedForNonEditedOrders = isRefundAllowed && !isEdited;

  // TODO: remove once we rollout this tag to 100% of stores, to unify business rules for cancel and refund form
  if (!hasRefundTag) {
    return {
      canBeRefundedThroughCancelForm: () => isRefundAllowed,
      canBeRefundedThroughRefundForm: () => false,
      hasRefundTag: () => hasRefundTag,
    };
  }

  return {
    canBeRefundedThroughCancelForm: () => isRefundAllowed,
    canBeRefundedThroughRefundForm: () =>
      isRefundAllowedForNonEditedOrders && isNuvemPagoPayment,
    hasRefundTag: () => hasRefundTag,
  };
}

export default useCanBeRefunded;
