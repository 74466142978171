import {
  Table,
  TableCellProps,
  Text,
  TextColorProperties,
} from '@nimbus-ds/components';

interface CustomHeaderCellProps {
  width?: TableCellProps['width'];
  children?: string;
  color?: TextColorProperties;
}

export function CustomHeaderCell({
  width = 'auto',
  children = '',
  color = 'neutral-textLow',
}: Readonly<CustomHeaderCellProps>) {
  return (
    <Table.Cell backgroundColor="neutral-surface" as="th" width={width}>
      <Text color={color} fontWeight="medium">
        {children}
      </Text>
    </Table.Cell>
  );
}
