import { Button, Spinner } from '@nimbus-ds/components';
import Stack from 'commons/components/Stack';
import useTranslationMetafields from 'domains/Metafields/hooks/useTranslationsMetafields';

export default function ModalOrderButtons({
  isLoading,
  handleOnClose,
  handleOnConfirm,
}) {
  const t = useTranslationMetafields();

  return (
    <Stack spacing="base" justify="flex-end">
      <Stack.Item>
        <Button onClick={handleOnClose} disabled={isLoading}>
          {`${t('assignOrdersMetafieldsModal.cancel')}`}
        </Button>
      </Stack.Item>
      <Stack.Item>
        <Button
          onClick={handleOnConfirm}
          disabled={isLoading}
          appearance="primary"
        >
          {isLoading && <Spinner color="neutral-interactive" size="small" />}
          {`${t('assignOrdersMetafieldsModal.save')}`}
        </Button>
      </Stack.Item>
    </Stack>
  );
}
