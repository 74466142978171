import { Card, Input, Radio } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function PickupPointCostSkeleton(): JSX.Element {
  const t = useTranslationShipping(
    'deliveryMethods.pickupPoints.form.secondStep.cost',
  );

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <Stack column align="stretch">
          <FormField>
            <Stack spacing="tight">
              <Radio.Skeleton />
              <Radio.Skeleton />
              <Radio.Skeleton />
            </Stack>
          </FormField>
          <FormField label={t('value')}>
            <Input.Skeleton />
          </FormField>
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default PickupPointCostSkeleton;
