import { useState } from 'react';
import {
  OrderDetailsResponseDto,
  OrderFulfillRequestDto,
  OrderFulfillmentUpdateRequestDto,
} from '@tiendanube/common';
import { generateFulfillmentLabel } from 'domains/Orders/Orders/utils/OrderDetailsPage';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import CardOrderAndFulfillment from '../CardOrderAndFulfillment';

interface CardFulfillmentOrdersListProps {
  order: OrderDetailsResponseDto;
  packOrder: (
    fulfillments?: OrderFulfillmentUpdateRequestDto[],
  ) => Promise<void>;
  fulfillOrder: (action: OrderFulfillRequestDto) => Promise<void>;
}

function CardFulfillmentOrdersList({
  order,
  packOrder,
  fulfillOrder,
}: CardFulfillmentOrdersListProps): JSX.Element | null {
  const t = useTranslationOrders();

  const [loadingStates, setLoadingStates] = useState(
    order.fulfillmentOrders?.map(() => false) ?? [],
  );

  const getCardTitle = (
    productsQuantity: number,
    locationName: string,
  ): string => `${productsQuantity} 
    ${t('orderSummaryCard.productInOrderWithLocation', {
      count: productsQuantity,
      locationName: locationName,
    })}`;

  const setLoadingState = (index: number, newState: boolean) => {
    setLoadingStates((prev) =>
      prev.map((state, i) => (i === index ? newState : state)),
    );
  };

  if (!order.fulfillmentOrders || order.fulfillmentOrders?.length === 0)
    return null;

  return (
    <>
      {order.fulfillmentOrders.map((fulfillmentOrder) => (
        <CardOrderAndFulfillment
          key={fulfillmentOrder.number}
          headerLabel={generateFulfillmentLabel(
            fulfillmentOrder.status,
            fulfillmentOrder.shipping.type,
            t,
          )}
          order={order}
          packOrder={async () => {
            setLoadingState(fulfillmentOrder.number - 1, true);
            await packOrder([
              {
                fulfillmentId: fulfillmentOrder.id,
                shippingType: fulfillmentOrder.shipping.type,
                status: fulfillmentOrder.status,
              },
            ]);
            setLoadingState(fulfillmentOrder.number - 1, false);
          }}
          fulfillOrder={async (action) => {
            setLoadingState(fulfillmentOrder.number - 1, true);
            await fulfillOrder({
              ...action,
              fulfillments: [
                {
                  fulfillmentId: fulfillmentOrder.id,
                  shippingType: fulfillmentOrder.shipping.type,
                  status: fulfillmentOrder.status,
                  ...action,
                },
              ],
            });
            setLoadingState(fulfillmentOrder.number - 1, false);
          }}
          isLoading={loadingStates[fulfillmentOrder.number - 1]}
          products={fulfillmentOrder.products}
          title={getCardTitle(
            fulfillmentOrder.products.reduce(
              (total, product) => total + product.quantity,
              0,
            ),
            fulfillmentOrder.assignedLocation.name,
          )}
          number={fulfillmentOrder.number}
          fulfillmentOrder={fulfillmentOrder}
        />
      ))}
    </>
  );
}

export default CardFulfillmentOrdersList;
