import { Variant } from '../components/Variants/types';
import { ProductFormState } from '../hooks/useProductForm/types';

export const variantDefault: Variant = {
  id: '',
  name: '',
  productId: '',
  promotionalPrice: '',
  price: '',
  isInfinite: true,
  pricePublished: true,
  barcode: '',
  sku: '',
  stock: null,
  weight: '',
  depth: '',
  width: '',
  height: '',
  imageUrl: '',
  imageId: '',
  values: [],
  costPrice: '',
  metafields: {
    internals: [],
    fromApi: [],
  },
  visible: true,
};

export const productDefault: ProductFormState = {
  brand: '',
  isDigital: false,
  name: { es: '' },
  description: { es: '' },
  categories: [],
  published: true,
  images: [],
  media: [],
  videoUrl: '',
  attributes: {},
  variants: [variantDefault],
  freeShipping: false,
  canonicalUrl: '',
  seoDescription: { es: '' },
  seoTitle: { es: '' },
  tags: [],
  seoUrl: { es: '' },
  sectionCodes: [],
  productMetafields: {
    internals: [],
    fromApi: [],
  },
  relatedProducts: {
    complementary: [],
    alternative: [],
    status: 'success',
  },
};
