import { mexicoVatConditionMap } from '@tiendanube/common/src/domains/billing/constants';
import {
  BaseCard,
  Grid,
  Input,
  InterfaceNameValue,
  Select,
} from '@tiendanube/components';
import { CancelAndSaveButtons } from 'commons/components';
import Stack from 'commons/components/Stack';
import { useTranslationBilling } from 'domains/Billing';
import MexicanFiscalDataFormSkeleton from './MexicanFiscalDataFormSkeleton';
import { MexicanAddress } from '../../../../Addresses';

const vatConditionTypes = Object.entries(mexicoVatConditionMap).map(
  ([key, value]) => ({
    value: key.toString(),
    label: value,
  }),
);

export interface FiscalDataformMexico {
  name: string;
  numberId: string;
  street: string;
  locality: string;
  province: string;
  city: string;
  zipcode: string;
  number: string;
  floor: string;
  vatCondition: string;
}

interface MexicanFiscalDataFormProps {
  values: FiscalDataformMexico;
  onChange: (data: InterfaceNameValue) => void;
  onSave: () => void;
  onCancel: () => void;
  errors: Partial<FiscalDataformMexico>;
  setMultipleFieldValues: (values: Record<string, any>) => void;
  isLoading: boolean;
  isDirty: boolean;
}

function MexicanFiscalDataForm({
  values,
  onChange,
  onSave,
  onCancel,
  errors,
  setMultipleFieldValues,
  isLoading,
  isDirty,
}: MexicanFiscalDataFormProps) {
  const t = useTranslationBilling();
  const nameHelpText = t(errors.name || '');
  const numberIdHelpText = t(errors.numberId || '');

  return (
    <>
      <BaseCard>
        <BaseCard.Body>
          <Grid spacing="loose">
            <Grid.Row>
              {[
                <Grid.Column key={1}>
                  <Stack column align="stretch">
                    <Input
                      type="text"
                      label={t('invoicesInfoPage.name')}
                      name="name"
                      value={values.name}
                      helpText={nameHelpText}
                      appearance={nameHelpText ? 'validation_error' : 'default'}
                      onChange={onChange}
                    />
                  </Stack>
                </Grid.Column>,
                <Grid.Column key={2}>
                  <Stack column align="stretch">
                    <Input
                      type="text"
                      label={t('invoicesInfoPage.numberId')}
                      name="numberId"
                      value={values.numberId}
                      helpText={numberIdHelpText}
                      appearance={
                        numberIdHelpText ? 'validation_error' : 'default'
                      }
                      onChange={onChange}
                    />
                  </Stack>
                </Grid.Column>,
                <Grid.Column key={3}>
                  <Stack column align="stretch">
                    <Select
                      label={t('invoicesInfoPage.vatCondition')}
                      name="vatCondition"
                      value={values.vatCondition}
                      placeholder={t(
                        'invoicesInfoPage.vatConditionPlaceholder',
                      )}
                      options={vatConditionTypes}
                      onChange={onChange}
                    />
                  </Stack>
                </Grid.Column>,
              ]}
            </Grid.Row>
            <MexicanAddress
              values={values}
              setMultipleFieldValues={setMultipleFieldValues}
              errors={errors}
            />
          </Grid>
        </BaseCard.Body>
      </BaseCard>
      <CancelAndSaveButtons
        isLoading={isLoading}
        onCancel={onCancel}
        onSave={onSave}
        isDisabled={!isDirty}
      />
    </>
  );
}

MexicanFiscalDataForm.Skeleton = MexicanFiscalDataFormSkeleton;

export default MexicanFiscalDataForm;
