import { Box } from '@nimbus-ds/components';
import HelpLink from 'App/HelpLink';
import {
  AppItemCard,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { HELP_LINKS_APPS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import useTranslationMarketing from 'domains/Marketing/useTranslationMarketing';
import { useAppStoreTracker } from 'domains/PartnersApps/hooks';
import {
  MarketingAppsCalloutCard,
  MarketingAppsPageError,
  MarketingAppsPageSkeleton,
} from './components';
import useMarketingApps from './useMarketingApps';
import { trackingMarketingAppsViewDetails } from '../../tracking';

function MarketingAppsPage(): JSX.Element {
  const t = useTranslationMarketing('marketingApps');
  const language = useTranslationLanguage();
  const { isLoading, isSuccess, isError, marketingApps, getMarketingApps } =
    useMarketingApps();
  const { clickToAppStoreAppDetails } = useAppStoreTracker();

  return (
    <IonPageStratus
      width="medium"
      headerTop={<HeaderTop />}
      headerContent={
        <HeaderContent title={t('title')} subtitle={t('subtitle')} />
      }
    >
      {isLoading && <MarketingAppsPageSkeleton />}
      {isError && (
        <MarketingAppsPageError getMarketingApps={getMarketingApps} />
      )}
      {isSuccess && !!marketingApps.length && (
        <>
          <Box
            display="grid"
            gridTemplateColumns={{
              lg: '1fr 1fr 1fr',
              md: '1fr',
              xs: '1fr',
            }}
            gridGap="4"
          >
            {marketingApps.map((marketingApp, index) => (
              <AppItemCard
                key={marketingApp.name}
                app={marketingApp}
                onClick={() => {
                  clickToAppStoreAppDetails('admin/v2/marketing/apps', {
                    name: marketingApp.name,
                    category: 'marketing',
                    position: index + 1,
                  });
                  trackingMarketingAppsViewDetails({
                    appName: marketingApp.name,
                  });
                }}
              />
            ))}
          </Box>
          <MarketingAppsCalloutCard />
        </>
      )}
      <HelpLink
        title={t('helpLink.title')}
        previousValue=""
        currentViewTracking={t('helpLink.amplitudeName')}
        linkURL={HELP_LINKS_APPS[language]}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default MarketingAppsPage;
