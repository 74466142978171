import { IconButton, Popover } from '@nimbus-ds/components';
import {
  EditIcon,
  EllipsisIcon,
  EyeIcon,
  EyeOffIcon,
  TrashIcon,
} from '@nimbus-ds/icons';
import { MenuButton } from '@nimbus-ds/patterns';
import { generatePath } from 'react-router';
import { PickupPointResponseDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { ActionIconButton, Stack, useResponsive } from 'commons/components';
import { DELIVERY_METHODS_ROUTES } from 'domains/Shipping/DeliveryMethods/shippingRoutes';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

export interface GroupedBusinessHours {
  start: string;
  end: string;
  days: string[];
}

interface PickupPointItemActionsProps {
  pickupPoint: PickupPointResponseDto;
  handleUpdateStatus: (pickupPoint: PickupPointResponseDto) => void;
  handleDelete?: (id: string) => void;
}

function PickupPointItemActions({
  pickupPoint,
  handleUpdateStatus,
  handleDelete,
}: Readonly<PickupPointItemActionsProps>) {
  const { isMobile } = useResponsive();
  const t = useTranslationShipping('deliveryMethods.pickupPoints.list');
  const { goTo } = useNavegate();
  const { id, isLocation, locations: vinculedLocations, status } = pickupPoint;

  return (
    <>
      {!isMobile && (
        <Stack spacing="tight" justify="flex-end">
          <ActionIconButton
            source={<EditIcon />}
            content={t('actions.edit')}
            onClick={() =>
              goTo(
                generatePath(
                  !isLocation
                    ? DELIVERY_METHODS_ROUTES.editPickupPoint
                    : DELIVERY_METHODS_ROUTES.editLocationAsPickupPoint,
                  { id, location: vinculedLocations[0] },
                ),
              )
            }
          />
          <ActionIconButton
            source={status ? <EyeOffIcon /> : <EyeIcon />}
            content={status ? t('actions.disable') : t('actions.activate')}
            onClick={() => handleUpdateStatus(pickupPoint)}
          />
          {handleDelete && !isLocation && (
            <ActionIconButton
              source={<TrashIcon />}
              content={t('actions.delete')}
              onClick={() => handleDelete(id)}
            />
          )}
        </Stack>
      )}
      {isMobile && (
        <Popover
          content={
            <Stack column spacing="tight" align="flex-start">
              <MenuButton
                label={t('actions.edit')}
                startIcon={EditIcon}
                onClick={() =>
                  goTo(
                    generatePath(
                      !isLocation
                        ? DELIVERY_METHODS_ROUTES.editPickupPoint
                        : DELIVERY_METHODS_ROUTES.editLocationAsPickupPoint,
                      { id, location: vinculedLocations[0] },
                    ),
                  )
                }
              />
              <MenuButton
                label={status ? t('actions.disable') : t('actions.activate')}
                startIcon={status ? EyeOffIcon : EyeIcon}
                onClick={() => handleUpdateStatus(pickupPoint)}
              />
              {handleDelete && !isLocation && (
                <MenuButton
                  label={t('actions.delete')}
                  startIcon={TrashIcon}
                  onClick={() => handleDelete(id)}
                />
              )}
            </Stack>
          }
          enabledHover
          position="left"
        >
          <IconButton size="2rem" source={<EllipsisIcon size="small" />} />
        </Popover>
      )}
    </>
  );
}

export default PickupPointItemActions;
