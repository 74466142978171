import { Input } from '@tiendanube/components';
import Stack from 'commons/components/Stack';

function BrazilianAddressSkeleton() {
  return (
    <Stack column align="stretch">
      <Input.Skeleton />
      <Input.Skeleton />
      <Input.Skeleton />
      <Input.Skeleton />
      <Input.Skeleton />
      <Input.Skeleton />
      <Input.Skeleton />
    </Stack>
  );
}

export default BrazilianAddressSkeleton;
