import { Box, Tag, Text } from '@nimbus-ds/components';
import { PopoverToogle, Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface UserRolesPopoverProps {
  id: string;
  userRoles: string[];
  userAppRoles: string[];
  storeRoles: Record<'roles' | 'appRoles', Record<string, string>>;
}

function UserRolesPopover({
  id,
  userRoles,
  userAppRoles,
  storeRoles: { roles, appRoles },
}: Readonly<UserRolesPopoverProps>) {
  const t = useTranslationConfigurations('usersListPage.rolesPopover');
  const nativeUserAppRoles = userAppRoles.filter((role) => !!appRoles[role]);

  const storeRolesLength = Object.keys(roles).length;
  const storeAppRolesLength = Object.keys(appRoles).length;
  const userRolesLength = userRoles.length;
  const userAppRolesLength = nativeUserAppRoles.length;

  if (userRoles.includes('full')) return <Tag>{roles.full}</Tag>;

  return (
    <PopoverToogle
      label={t('title', { count: userRolesLength + userAppRolesLength })}
      appearance="primary"
      content={
        <Stack column spacing="tight" align="stretch">
          <Stack>
            <Stack.Item fill>
              <Text color="neutral-textHigh">{t('userPermissions')}</Text>
            </Stack.Item>
            <Tag>
              {t('quantity', {
                count: userRolesLength,
                total: storeRolesLength,
              })}
            </Tag>
          </Stack>
          <Stack column spacing="none" align="flex-start">
            {userRoles.map((role) => (
              <Text
                color="neutral-textLow"
                fontSize="caption"
                key={`user-${id}-${role}`}
              >
                {roles[role]}
              </Text>
            ))}
          </Stack>
          {userAppRolesLength > 0 && (
            <Box
              paddingTop="3"
              borderStyle="solid"
              borderTopWidth="1"
              borderColor="neutral-surfaceHighlight"
            >
              <Stack>
                <Stack.Item fill>
                  <Text fontWeight="bold">{t('apps')}</Text>
                </Stack.Item>
                <Tag>
                  {t('quantity', {
                    count: userAppRolesLength,
                    total: storeAppRolesLength,
                  })}
                </Tag>
              </Stack>
              <Stack column spacing="none" align="flex-start">
                {nativeUserAppRoles.map((appRole) => (
                  <Text key={`user-${id}-${appRole}`}>{appRoles[appRole]}</Text>
                ))}
              </Stack>
            </Box>
          )}
        </Stack>
      }
    />
  );
}

export default UserRolesPopover;
