import { FC } from 'react';
import {
  BarcodeIcon,
  CreditCardIcon,
  IconProps,
  MoneyIcon,
  PixIcon,
  ShoppingCartIcon,
} from '@nimbus-ds/icons';
import { CheckoutPaymentMethod, ConceptCode } from '@tiendanube/common';

const PAYMENT_METHOD_ICON_MAP: Record<CheckoutPaymentMethod, FC<IconProps>> = {
  cc: CreditCardIcon,
  transfer: MoneyIcon,
  ticket: ShoppingCartIcon,
  'mp-cc': CreditCardIcon,
  'mp-dc': CreditCardIcon,
  'mp-bank-transfer': PixIcon,
  'mp-boleto': BarcodeIcon,
  'mp-ticket': ShoppingCartIcon,
};

const MERCADO_PAGO_PAYMENT_METHODS: CheckoutPaymentMethod[] = [
  'mp-bank-transfer',
  'mp-boleto',
  'ticket',
  'mp-cc',
  'mp-dc',
  'mp-ticket',
];

export const FEE_CONCEPTS: ConceptCode[] = [
  'shipping-mercadopago-fee',
  'ar-shipping-mercadopago-fee',
];

export function getIconForPaymentMethod(method: CheckoutPaymentMethod) {
  return PAYMENT_METHOD_ICON_MAP[method];
}

export function paymentMethodIsMercadoPago(method: CheckoutPaymentMethod) {
  return MERCADO_PAGO_PAYMENT_METHODS.includes(method);
}
