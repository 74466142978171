/* eslint-disable max-statements */
import { Box, Text, Icon } from '@nimbus-ds/components';
import { ChevronLeftIcon, UserIcon } from '@nimbus-ds/icons';
import { Menu } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Country } from '@tiendanube/common/src/enums';
import { useMenuScrollShadow } from 'App/components/Menu/hooks';
import ShowByRole from 'App/components/ShowByRole';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { useNavegate, useHandleMenuClick, useIsInAppBrowser } from 'App/hooks';
import useShouldRedirectPlanPage from 'App/hooks/useShouldRedirectPlanPage';
import { trackingSessionManagementMenuItemClick } from 'domains/Account/tracking';
import {
  useGetIsFreePlan,
  useGetUserInfo,
  useHeaderMenuData,
  useIsMobileDevice,
  useAdminLinks,
  useLoggedAdminLink,
  useGetCountry,
  useGetPlan,
  useHasPermission,
  useGetTags,
} from 'domains/Auth/hooks';
import useLogout from 'domains/Auth/pages/LogoutPage/useLogout';
import { BILLING_ROUTES } from 'domains/Billing/billingRoutes';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import { TRANSACTION_FEES_BASE_ROUTE } from 'domains/Billing/TransactionFees/transactionFeesRoutes';
import { UsersMenu } from 'domains/Configurations/Users';
import { PATH } from '../../../utils';

interface AccountMenuContentProps {
  device?: 'mobile' | 'desktop';
}

const invalidCountriesFortransactionCostsMenu = [Country.CL, Country.CO];

function AccountMenuContent({ device }: Readonly<AccountMenuContentProps>) {
  const { t } = useTranslation('common');
  const { pathname } = useNavegate();
  const isMobile = device === 'mobile';
  const isMobileDevice = useIsMobileDevice();
  const isInAppBrowserOpen = useIsInAppBrowser();
  const { getOldAdminLink } = useAdminLinks();
  const { goToLoggedAdminLink } = useLoggedAdminLink();
  const country = useGetCountry();
  const { hasTransactionFeesForMx } = useGetPlan();
  const hasInvoiceHistoryPermission = useHasPermission('invoice_history');
  const hasPaymentsAndSubscriptionsPermission = useHasPermission(
    'payments_and_subscriptions',
  );

  const { handleClickItem } = useHandleMenuClick();
  const { isFreePlan } = useGetIsFreePlan();
  const logout = useLogout();
  const { goTo } = useNavegate();
  const { name } = useHeaderMenuData();
  const { email } = useGetUserInfo();
  const handleSessionManagementMenuItemClick = () => {
    trackingSessionManagementMenuItemClick();
    handleClickItem();
  };
  const handleClickLogOut = isMobile ? handleClickItem : logout;
  const menuBodyRef = useMenuScrollShadow();

  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);

  const handleGoToPlansWeb = async () => {
    await goToLoggedAdminLink(PATH.plans);
  };
  const plansPageNewAdmin = useShouldRedirectPlanPage();

  const paymentsSection = (
    <Menu.Section title={t('accountMenu.payments.title')}>
      {!isMobile && !isFreePlan && hasPaymentsAndSubscriptionsPermission ? (
        <Menu.Button
          as="a"
          label={t('accountMenu.payments.paymentsAndSubscriptions')}
          href={getOldAdminLink(PATH.paymentsAndSubscriptions)}
        />
      ) : !isMobile && hasPaymentsAndSubscriptionsPermission ? (
        <Menu.Button
          as={Link}
          to={CHECKOUT_ROUTES.plansAndSubscriptions}
          active={pathname.startsWith(CHECKOUT_ROUTES.plansAndSubscriptions)}
          label={t('accountMenu.payments.paymentsAndSubscriptions')}
        />
      ) : null}
      {!isMobile &&
        !isInAppBrowserOpen &&
        hasPaymentsAndSubscriptionsPermission && (
          <Menu.Button
            as={Link}
            to={BILLING_ROUTES.coupons}
            active={pathname.startsWith(BILLING_ROUTES.coupons)}
            label={t('accountMenu.payments.creditCoupon')}
          />
        )}
      {!isMobileDevice && hasInvoiceHistoryPermission && (
        <Menu.Button
          as="a"
          href={getOldAdminLink(PATH.invoiceHistory)}
          label={t('accountMenu.payments.invoiceHistory')}
        />
      )}
      {!isMobile &&
        !isRevampMenu &&
        hasPaymentsAndSubscriptionsPermission &&
        !invalidCountriesFortransactionCostsMenu.includes(country) &&
        (country !== Country.MX || hasTransactionFeesForMx) && (
          <Menu.Button
            as={Link}
            to={TRANSACTION_FEES_BASE_ROUTE}
            active={pathname.startsWith(TRANSACTION_FEES_BASE_ROUTE)}
            label={t('accountMenu.payments.transactionCosts')}
          />
        )}
    </Menu.Section>
  );

  const plansSection = (
    <Box pt="2">
      <Menu.Section>
        {isMobileDevice || !plansPageNewAdmin ? (
          <Menu.Button
            label={t('accountMenu.plans')}
            onClick={handleGoToPlansWeb}
          />
        ) : (
          <Menu.Button
            as={Link}
            to={PATH.plans}
            active={pathname.startsWith(PATH.plans)}
            label={t('accountMenu.plans')}
            onClick={handleClickItem}
          />
        )}
      </Menu.Section>
    </Box>
  );

  const securitySection = (
    <Menu.Section title={t('accountMenu.security.title')}>
      <Menu.Button
        as={Link}
        to={PATH.authenticationFactor}
        active={pathname.startsWith(PATH.authenticationFactor)}
        label={t('accountMenu.security.authenticationFactor')}
        onClick={handleClickItem}
      />
      <UsersMenu />
      <Menu.Button
        as={Link}
        to={PATH.sessionManagement}
        active={pathname.startsWith(PATH.sessionManagement)}
        label={t('accountMenu.security.sessionManagement')}
        onClick={handleSessionManagementMenuItemClick}
      />
    </Menu.Section>
  );

  const accountDetailsSection = (
    <Menu.Section title={t('accountMenu.accountDetails.title')}>
      {!isRevampMenu && (
        <Menu.Button
          as={Link}
          to={PATH.socialNetworks}
          active={pathname.startsWith(PATH.socialNetworks)}
          label={t('accountMenu.accountDetails.socialNetworks')}
          onClick={handleClickItem}
        />
      )}

      <Menu.Button
        as={Link}
        to={PATH.dataBusinessAndCancellation}
        active={pathname.startsWith(PATH.dataBusinessAndCancellation)}
        label={t('accountMenu.accountDetails.dataBusinessAndCancellation')}
        onClick={handleClickItem}
      />
    </Menu.Section>
  );

  return (
    <>
      <Menu.Header>
        <Box display="flex" flexDirection="column" gap="4">
          {isMobile && (
            <Box
              display="flex"
              gap="1"
              as="button"
              backgroundColor="transparent"
              borderColor="transparent"
              onClick={() => goTo('/')}
              alignItems="center"
              cursor="pointer"
            >
              <Icon
                color="neutral-textHigh"
                source={<ChevronLeftIcon size={14} />}
              />
              <Text fontWeight="medium" lineClamp={1} color="neutral-textHigh">
                {name}
              </Text>
            </Box>
          )}
          <Box display="flex" gap="2" alignItems="flex-start">
            <Box
              width="40px"
              height="40px"
              borderRadius="2"
              backgroundColor="neutral-surface"
              display="flex"
              alignItems="center"
              justifyContent="center"
              minWidth="40px"
            >
              <Icon source={<UserIcon />} />
            </Box>
            <Box display="flex" flexDirection="column">
              <Text color="neutral-textHigh" fontWeight="medium" lineClamp={1}>
                {t('accountMenu.title')}
              </Text>
              <Text fontSize="caption" lineClamp={1} wordBreak="break-all">
                {email}
              </Text>
            </Box>
          </Box>
        </Box>
      </Menu.Header>
      <div className="stratus--menu-body" ref={menuBodyRef}>
        <Menu.Body>
          <ShowByRole
            includeRoles={[
              'full',
              'invoice_history',
              'payments_and_subscriptions',
            ]}
            rolesWithoutFilter
          >
            {paymentsSection}
          </ShowByRole>
          <ShowByRole includeRoles={['full', 'payments_and_subscriptions']}>
            {plansSection}
          </ShowByRole>
          {securitySection}
          <ShowByRole includeRoles={['full']}>
            {accountDetailsSection}
          </ShowByRole>
          {!isMobile && (
            <Menu.Button
              label={t('menu.closeSession')}
              onClick={handleClickLogOut}
            />
          )}
        </Menu.Body>
      </div>
      {isMobile && (
        <Box
          boxSizing="border-box"
          display="flex"
          flex="0 1 auto"
          paddingX="2"
          paddingY="3"
          width="100%"
        >
          <Menu.Button
            as={Link}
            to={PATH.logout}
            label={t('menu.closeSession')}
            onClick={handleClickLogOut}
          />
        </Box>
      )}
    </>
  );
}

export default AccountMenuContent;
