import { useEffect } from 'react';
import { Button, Text } from '@nimbus-ds/components';
import { AlertSessionStorage } from 'App/components';
import { NoInfer } from 'commons/types';
import { useTranslationMarketing } from '../../hook';
import { useShowNewPromoAlert } from '../hooks';
import { PromotionType } from '../pages/constants';

interface NewPromoAlertProps<T> {
  onClick: (type: NoInfer<T>) => void;
  onRemove: (type: NoInfer<T>) => void;
  onShow: (type: NoInfer<T>) => void;
  type: T;
}

export function NewPromoAlert<T extends PromotionType>({
  onClick,
  onRemove,
  onShow,
  type,
}: Readonly<NewPromoAlertProps<T>>) {
  const t = useTranslationMarketing('promotions.adoptionAlert');
  const showNewPromoAlert = useShowNewPromoAlert(type);

  useEffect(() => {
    if (showNewPromoAlert) {
      onShow(type);
    }
  }, [onShow, showNewPromoAlert, type]);

  if (!showNewPromoAlert) return null;

  return (
    <AlertSessionStorage
      appearance="primary"
      title={t('title')}
      keyName="showAdoptionAlert"
      onRemove={() => onRemove(type)}
    >
      <Text>{t('text')}</Text>
      <Button onClick={() => onClick(type)}>{t('buttonText')}</Button>
    </AlertSessionStorage>
  );
}
