import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  MetafieldSelectedDetails,
  MetafieldSelectedsOrErrorListV2Type,
  MetafieldSelectedV2Dto,
} from '@tiendanube/common';
import { CustomerMetafieldsInterface } from './types';
import { statusType } from '../../customersSlice';
import customerMetafieldsService from '../customerMetafieldsService';

const initialState: CustomerMetafieldsInterface = {
  status: statusType.idle,
  data: {
    internals: [],
    fromApi: [],
  },
  details: [],
  statusDetails: statusType.idle,
  wholesaleCustomer: {
    data: [],
    status: statusType.idle,
  },
};

export const getCustomerMetafields = createAsyncThunk<
  MetafieldSelectedsOrErrorListV2Type,
  string
>(
  'customers/metafields',
  async (id) => await customerMetafieldsService.getCustomerMetafields(id),
);

export const getCustomerMetafieldsDetails = createAsyncThunk<
  MetafieldSelectedDetails[],
  string
>(
  'customers/metafields/details',
  async (id) =>
    await customerMetafieldsService.getCustomerMetafieldsDetails(id),
);

export const fetchAssignedWholesaleCustomerMetafieldsAction = createAsyncThunk<
  MetafieldSelectedV2Dto[],
  string
>(
  'customers/fetchAssignedWholesaleCustomerMetafields',
  async (id) =>
    await customerMetafieldsService.getWholesaleCustomerMetafields(id),
);

const customersMetafields = createSlice({
  name: 'customersMetafields',
  initialState,
  reducers: {
    clearWholesaleCustomerMetafields(state) {
      state.wholesaleCustomer.data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerMetafields.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = statusType.success;
      })
      .addCase(getCustomerMetafields.pending, (state) => {
        state.status = statusType.loading;
      })
      .addCase(getCustomerMetafields.rejected, (state) => {
        state.status = statusType.error;
      });

    builder
      .addCase(getCustomerMetafieldsDetails.fulfilled, (state, action) => {
        state.details = action.payload;
        state.statusDetails = statusType.success;
      })
      .addCase(getCustomerMetafieldsDetails.pending, (state) => {
        state.statusDetails = statusType.loading;
      })
      .addCase(getCustomerMetafieldsDetails.rejected, (state) => {
        state.statusDetails = statusType.error;
      });

    builder
      .addCase(
        fetchAssignedWholesaleCustomerMetafieldsAction.pending,
        (state) => {
          state.wholesaleCustomer.status = statusType.loading;
        },
      )
      .addCase(
        fetchAssignedWholesaleCustomerMetafieldsAction.fulfilled,
        (state, action) => {
          state.wholesaleCustomer.data = action.payload;
          state.wholesaleCustomer.status = statusType.success;
        },
      )

      .addCase(
        fetchAssignedWholesaleCustomerMetafieldsAction.rejected,
        (state) => {
          state.wholesaleCustomer.status = statusType.error;
        },
      );
  },
});

export const { reducer } = customersMetafields;
export const { clearWholesaleCustomerMetafields } = customersMetafields.actions;
