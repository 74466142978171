import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

const noop = () => null;

export interface MediaUploadStatusContextResult {
  start: () => void;
  stop: () => void;
  isUploading: boolean;
}

export const MediaUploadContext = createContext<MediaUploadStatusContextResult>(
  {
    start: noop,
    stop: noop,
    isUploading: false,
  },
);

interface MediaUploadStatusProps {
  children: ReactNode;
}

function MediaUploadStatusContextProvider({
  children,
}: Readonly<MediaUploadStatusProps>): JSX.Element {
  const [isUploading, setIsUploading] = useState(false);

  const start = useCallback(() => setIsUploading(() => true), []);
  const stop = useCallback(() => setIsUploading(() => false), []);

  const value = useMemo(
    () => ({
      isUploading,
      start,
      stop,
    }),
    [isUploading, start, stop],
  );

  return (
    <MediaUploadContext.Provider value={value}>
      {children}
    </MediaUploadContext.Provider>
  );
}

function useMediaUploadStatus() {
  return useContext(MediaUploadContext);
}

export { MediaUploadStatusContextProvider, useMediaUploadStatus };
