import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Stack, Text } from '@tiendanube/components';
import { FEATURE_MONTHLY_ORDERS_EXPORT } from 'App/features';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import {
  ExportCard,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { HELP_LINKS_TO_EXPORT_ORDERS } from 'commons/constants';
import { useTranslationLanguage, useModal } from 'commons/hooks';
import { ORDERS_EXPORT_ORDERS } from 'config/upsellFlowSources';
import { useFeatureUsesByKey, useStatusFeatureByKey } from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { trackingOrderListExport } from 'domains/Orders/tracking';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import {
  OrdersExportFiltersModal,
  UpgradePlanAlert,
  ExportOrdersAlert,
} from './components';
import {
  useDownloadOrdersCsv,
  useOrdersExport,
  useOrdersExportFilters,
} from './hooks';
import { useIsSavedSearchesEnabled } from '../../hooks/useIsSavedSearchesEnabled';
import { FilterStatusEnum } from '../../ordersService';
import { getOrdersAggregations } from '../../ordersSlice';
import { useFilterSelectedProducts } from '../OrderListPage/hooks/useFilterSelectedProducts';

function OrdersExportPage(): JSX.Element {
  const t = useTranslationOrders();
  const language = useTranslationLanguage();
  const { goBack } = useNavegate();
  const { isAvailable, unlimited, maxUse } = useStatusFeatureByKey(
    'monthly_orders_export',
  );
  const {
    featureUses,
    isLoading: isFeatureUsesLoading,
    getFeatureUses,
  } = useFeatureUsesByKey('monthly_orders_export');
  const [showFiltersModal, openFiltersModal, closeFiltersModal] = useModal();
  const [showUpsellAlert, setShowUpsellAlert] = useState(true);

  const {
    filters,
    totalResults,
    isLoading,
    getTotalOrders,
    refreshTotalOrders,
    removeFilter,
    clearFilters,
  } = useOrdersExport();
  const {
    exportOrders,
    isExporting,
    jobCsvDownloadStatus,
    resetExportingStatus,
    csvDownloadLinkUrl,
  } = useDownloadOrdersCsv();
  const { getFilterChips } = useOrdersExportFilters(filters);

  const aggregations = useSelector(getOrdersAggregations);
  const { selectedProducts, serializeProducts } = useFilterSelectedProducts(
    filters.products,
    aggregations,
  );
  const isSavedSearchesEnabled = useIsSavedSearchesEnabled();

  useEffect(() => {
    refreshTotalOrders();
    getFeatureUses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = useUpsellFlow({
    title: t('exportPage.upsellTitle'),
    featureKey: FEATURE_MONTHLY_ORDERS_EXPORT,
    minValue: featureUses + 1,
    trackingSource: ORDERS_EXPORT_ORDERS,
    callback: () => {
      resetExportingStatus();
      exportOrders();
      trackingOrderListExport({
        orderStatusFilter: filters.status,
        dateFrom: filters.dateFrom,
        dateTo: filters.dateTo,
        shippingStatusFilter: filters.fulfillmentStatus.split(','),
        paymentStatusFilter: filters.paymentStatus.split(','),
        paymentMethodFilter: String(!!filters.paymentMethods),
        paymentProviderFilter: String(!!filters.paymentProvider),
        shippingFilter: String(!!filters.shippingMethod),
        channelFilter: String(!!filters.origin),
        applicationFilter: String(!!filters.appId),
      });
    },
  });

  const handleDismissUpsellAlert = () => setShowUpsellAlert(false);

  const availableUses = maxUse - featureUses;
  const hasAvailableUses = availableUses > 0;
  const maxLimitReached = !unlimited && !hasAvailableUses;
  const disableExport = totalResults === 0 || isLoading;
  const cardTotalLabel =
    filters.status === FilterStatusEnum.EMPTY
      ? t('exportPage.totalOpen')
      : t('exportPage.total');

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={{ onClick: goBack }} />}
      headerContent={<HeaderContent title={t('exportPage.title')} />}
      width="small"
    >
      <Stack column align="stretch">
        <Stack.Item>
          <Text>{t('exportPage.subtitle')}</Text>
        </Stack.Item>
        <Stack.Item />
      </Stack>
      {!isFeatureUsesLoading && !unlimited && isAvailable && (
        <UpgradePlanAlert
          show={showUpsellAlert}
          availableUses={availableUses}
          isMaxExportReached={maxLimitReached}
          onClick={handleConfirm}
          onDismiss={handleDismissUpsellAlert}
        />
      )}
      <ExportOrdersAlert
        exportingStatus={jobCsvDownloadStatus}
        csvUrl={csvDownloadLinkUrl}
        resetExportingStatus={resetExportingStatus}
      />
      <Stack column align="stretch">
        <Stack.Item>
          <ExportCard
            filters={getFilterChips()}
            showClearFiltersButton={isSavedSearchesEnabled}
            onClearFilterButtonClick={clearFilters}
            totalLabel={cardTotalLabel}
            total={totalResults}
            loadingTotal={isLoading}
            exporting={isExporting}
            disabled={disableExport}
            onRemoveFilter={removeFilter}
            onOpenFilters={openFiltersModal}
            onConfirm={handleConfirm}
          />
        </Stack.Item>
      </Stack>
      <HelpLink
        title={t('exportPage.helpLinkTitle')}
        previousValue=""
        currentViewTracking={t('exportPage.helpLinkTracking')}
        linkURL={HELP_LINKS_TO_EXPORT_ORDERS[language]}
        icon="both"
      />
      <OrdersExportFiltersModal
        show={showFiltersModal}
        filters={filters}
        onClose={closeFiltersModal}
        onConfirm={getTotalOrders}
        selectedProducts={selectedProducts}
        serializeProducts={serializeProducts}
      />
    </IonPageStratus>
  );
}

export default OrdersExportPage;
