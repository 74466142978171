import {
  GetDocumentSettingsResponseDto,
  InformationForDocumentResponseDto,
} from '@tiendanube/common';
import './DocumentPreview.scss';
import { useResponsive } from 'commons/components';
import { DocumentType } from 'domains/FulfillmentOrders/pages/PrintDocumentPage/constants';
import GeneralDocumentPreviewFields from './GeneralDocumentPreviewFields';

interface DocumentPreviewProps {
  type: DocumentType;
  information: InformationForDocumentResponseDto[];
  settings: GetDocumentSettingsResponseDto;
  locationId: string;
}

function DocumentPreview({
  type,
  information,
  settings,
  locationId,
}: Readonly<DocumentPreviewProps>) {
  const { isMobile } = useResponsive();
  return (
    <>
      {information
        .filter(
          ({ fulfillmentOrder }) =>
            !locationId || fulfillmentOrder.assignedLocation.id === locationId,
        )
        .map(({ order, fulfillmentOrder, store }) => (
          <div
            key={fulfillmentOrder.number + order.number}
            className={`stratus--order-document ${type}${
              isMobile ? '-mobile' : ''
            } `}
          >
            <GeneralDocumentPreviewFields
              fulfillmentOrder={fulfillmentOrder}
              order={order}
              settings={settings}
              store={store}
            />
          </div>
        ))}
    </>
  );
}

export default DocumentPreview;
