import { useEffect } from 'react';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { SortByType } from '@tiendanube/common';
import { SORT_BY_VALUES } from '@tiendanube/common/src/domains/marketing/coupons/types';
import { Domain } from '@tiendanube/common/src/enums';
import { Link } from '@tiendanube/components';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import {
  ActionProp,
  ActionsDesktop,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Responsive,
  SearchFilter,
  SortByPopover,
  useResponsive,
} from 'commons/components';
import { HELP_LINKS_COUPON_LIST } from 'commons/constants';
import { useDocumentTitle, useModal } from 'commons/hooks';
import { MARKETING_ROUTES } from 'domains/Marketing/marketingRoutes';
import { NewPromoAlert } from 'domains/Marketing/Promotions/components';
import {
  PromotionType,
  PromotionTypes,
} from 'domains/Marketing/Promotions/pages/constants';
import { buildNewPromotionUrl } from 'domains/Marketing/Promotions/utils';
import {
  CouponsListDesktop,
  CouponsListEmpty,
  CouponsListError,
  CouponsListMobile,
} from './components';
import { CouponsEmptySearch } from './components/CouponsEmptySearch';
import FilterModal from './components/FilterModal';
import useCouponListPage from './useCouponListPage';
import { DEFAULT_SORT_BY } from '../../couponsService';
import useCoupons from '../../hooks/useCoupons';
import {
  couponsOpenAdoptionAlert,
  couponsCloseAdoptionAlert,
  couponsShowAdoptionAlert,
} from '../../tracking';

function CouponListPage() {
  const {
    t,
    i18n: { language },
  } = useTranslation(Domain.MARKETING);
  const { isMobile } = useResponsive();

  const {
    isError,
    fetchCoupons,
    isEmpty,
    isSuccess,
    isLoading,
    total,
    filters,
    changeFilters,
    updateFilters,
    searchWithDebounce,
    optionsLabels,
  } = useCoupons();

  const [showModal, onShowModal, onCloseModal] = useModal();

  const { handleSubmit, handleChange, handleSort, handleDismiss } =
    useCouponListPage(updateFilters);

  useEffect(() => searchWithDebounce(filters), [filters, searchWithDebounce]);

  const title = t('coupons.title');
  useDocumentTitle(title);
  const { goTo } = useNavegate();

  const action: ActionProp = {
    children: t('coupons.addCoupon'),
    icon: PlusCircleIcon,
    onClick: () => goTo(MARKETING_ROUTES.newCoupon),
    appearance: 'primary',
  };

  const renderMenuMobile = !isEmpty && isSuccess && (
    <Link onClick={action.onClick} icon={action.icon} />
  );

  const someFilterApplied = optionsLabels.length > 0 || filters.page > 1;
  const resultCount = isLoading
    ? ''
    : someFilterApplied
    ? t('coupons.filters.filterResult', { count: total || 0 })
    : t('coupons.filters.result', { count: total || 0 });

  const isEmptyScreen = isEmpty && !someFilterApplied;
  const isEmptySearch = isEmpty && someFilterApplied;
  const sortBy = filters.sortBy || DEFAULT_SORT_BY;

  const createListOption = (value: SortByType) => ({
    value,
    label: t(`coupons.filters.sortBy.${value}`),
  });

  const gotoCreatePromotion = (promotionType: PromotionType) => {
    couponsOpenAdoptionAlert(promotionType);
    goTo(buildNewPromotionUrl(promotionType));
  };

  return (
    <IonPageStratus
      width="medium"
      headerTop={<HeaderTop actions={renderMenuMobile} />}
      headerContent={
        <HeaderContent
          title={title}
          actions={!isEmptyScreen && <ActionsDesktop actions={[action]} />}
        />
      }
    >
      {!isLoading && !isError && !isEmptyScreen && (
        <NewPromoAlert
          type={PromotionTypes.CROSS_SELLING}
          onClick={gotoCreatePromotion}
          onRemove={couponsCloseAdoptionAlert}
          onShow={couponsShowAdoptionAlert}
        />
      )}
      {!isEmptyScreen && (
        <>
          <SearchFilter
            placeholder={t('coupons.filters.search')}
            ariaLabel={t('coupons.filters.search')}
            onSubmit={handleSubmit}
            resultCount={resultCount}
            appliedFilters={optionsLabels}
            isLoading={isLoading}
            value={filters.q}
            onChange={handleChange}
            onClick={onShowModal}
            onDismiss={handleDismiss}
            label={isMobile ? '' : t('coupons.filters.searchLabel')}
            sortByElement={
              <SortByPopover
                listOptions={SORT_BY_VALUES.map(createListOption)}
                valueOption={createListOption(sortBy)}
                onChange={handleSort}
              />
            }
          />
          <FilterModal
            filter={filters}
            addParam={changeFilters}
            show={showModal}
            hideModal={onCloseModal}
          />
        </>
      )}

      {isEmptySearch && <CouponsEmptySearch />}
      {isEmptyScreen && <CouponsListEmpty />}
      {isError && (
        <CouponsListError onRetryError={() => fetchCoupons(filters)} />
      )}
      {!isEmpty && !isError && (
        <Responsive
          mobile={<CouponsListMobile />}
          desktop={<CouponsListDesktop />}
        />
      )}
      <HelpLink
        title={t('helpLink.promotionsAndDiscounts')}
        linkURL={HELP_LINKS_COUPON_LIST[language]}
        currentViewTracking={t('helpLink.promotionsAndDiscountsTrackingName')}
        previousValue="coupon_list"
        icon="both"
      />
    </IonPageStratus>
  );
}

export default CouponListPage;
