import { Modal, Text } from '@nimbus-ds/components';
import { generatePath } from 'react-router';
import { PickupPointResponseDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { CancelAndConfirmButtons, Stack } from 'commons/components';
import { useAsyncFunc, useToast } from 'commons/hooks';
import { DELIVERY_METHODS_ROUTES } from 'domains/Shipping/DeliveryMethods/shippingRoutes';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import usePickupPoints from '../../hooks/usePickupPoints';

interface PickupPointStatusModalProps {
  pickupPoint: PickupPointResponseDto;
  onClose: () => void;
}
function PickupPointStatusModal({
  pickupPoint,
  onClose,
}: Readonly<PickupPointStatusModalProps>) {
  const { addToast } = useToast();
  const { goTo } = useNavegate();
  const t = useTranslationShipping(
    `deliveryMethods.pickupPoints.${
      pickupPoint.isLocation ? 'locations' : 'pickup'
    }`,
  );

  const statusTranslate = pickupPoint.status ? 'disable' : 'activate';
  const toastTranslate = `toast.${statusTranslate}`;
  const isPickupWithoutLocations =
    !pickupPoint.isLocation && pickupPoint.locations.length === 0;
  const bodyTranslate = !isPickupWithoutLocations
    ? `status.${statusTranslate}`
    : 'status.withoutLocation';

  const { updatePickupPointStatus } = usePickupPoints();
  const [handleUpdateStatus, isSaving] = useAsyncFunc(
    async () => {
      await updatePickupPointStatus({
        id: pickupPoint.id,
        payload: {
          status: !pickupPoint.status,
        },
      });
    },
    () => {
      addToast({
        label: t(`${toastTranslate}.success`),
        appearance: 'success',
      });
      onClose();
    },
    () => {
      addToast({
        label: t(`${toastTranslate}.error`),
        appearance: 'danger',
      });
    },
  );

  return (
    <Modal open onDismiss={onClose}>
      <Modal.Header
        title={t(`${bodyTranslate}.title`, {
          name: pickupPoint.name,
        })}
      />
      <Modal.Body padding="none">
        <Text>{t(`${bodyTranslate}.description`)}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Stack spacing="base" justify="flex-end">
          <CancelAndConfirmButtons
            onCancel={onClose}
            onConfirm={
              !isPickupWithoutLocations
                ? handleUpdateStatus
                : () =>
                    goTo(
                      generatePath(DELIVERY_METHODS_ROUTES.editPickupPoint, {
                        id: pickupPoint.id,
                      }),
                    )
            }
            isLoading={isSaving}
            confirmText={t(`${bodyTranslate}.action`)}
            appearance={
              pickupPoint.status && !isPickupWithoutLocations
                ? 'danger'
                : 'primary'
            }
          />
        </Stack>
      </Modal.Footer>
    </Modal>
  );
}
export default PickupPointStatusModal;
