import { Card, Text } from '@nimbus-ds/components';
import { InteractiveList } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function LocationListForPickupSkeleton() {
  const t = useTranslationShipping(
    'deliveryMethods.pickupPoints.form.firstStep',
  );

  return (
    <Card padding="none">
      <Card.Header padding="base" title={t('location')} />
      <Card.Body padding="base">
        <Stack align="flex-start" column>
          <Text>{t('locationSubtitle')}</Text>
        </Stack>
      </Card.Body>
      <Card.Footer>
        <InteractiveList>
          <InteractiveList.CheckboxItemSkeleton title="" />
          <InteractiveList.CheckboxItemSkeleton title="" />
        </InteractiveList>
      </Card.Footer>
    </Card>
  );
}

export default LocationListForPickupSkeleton;
