import { Input, InterfaceNameValue } from '@tiendanube/components';
import useScrollToError from 'App/components/AppLayout/useScrollToError';
import { CardWithTitle } from 'commons/components';
import Stack from 'commons/components/Stack';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import Skeleton from './Skeleton';

export interface AccountValuesInterface {
  user: string;
  password: string;
  numberAccount: string;
}

interface AccountCardProps {
  values: AccountValuesInterface;
  errors: Partial<Record<string, string>>;
  onChange: (group: string, data: InterfaceNameValue) => void;
}

function AccountCard({
  values,
  errors,
  onChange,
}: AccountCardProps): JSX.Element {
  const t = useTranslationShipping();

  useScrollToError(errors);

  const handleChange = (event: InterfaceNameValue) => {
    onChange('account', event);
  };

  const error = {
    user: errors['account.user'] || '',
    password: errors['account.password'] || '',
    numberAccount: errors['account.numberAccount'] || '',
  };

  return (
    <CardWithTitle title={t('oca.config.account.title')}>
      <Stack column align="stretch">
        <Input
          type="text"
          name="user"
          label={t('oca.config.account.user')}
          value={values.user}
          appearance={error.user ? 'validation_error' : 'default'}
          helpText={t(error.user)}
          onChange={handleChange}
        />

        <Input
          name="password"
          type="password"
          label={t('oca.config.account.password')}
          value={`${values.password}`}
          appearance={error.password ? 'validation_error' : 'default'}
          helpText={t(error.password)}
          onChange={handleChange}
        />

        <Input
          name="numberAccount"
          label={t('oca.config.account.numberAccount')}
          value={`${values.numberAccount}`}
          appearance={error.numberAccount ? 'validation_error' : 'default'}
          helpText={
            t(error.numberAccount) ||
            t('oca.config.account.numberAccountExample')
          }
          maxLength={10}
          onChange={handleChange}
        />
      </Stack>
    </CardWithTitle>
  );
}

AccountCard.Skeleton = Skeleton;

export default AccountCard;
