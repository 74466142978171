import { Chip, Icon, Link, Text, Title } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { CardCollapsible, Stack } from 'commons/components';
import { useModal } from 'commons/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import SelectProvinceModal from '../SelectModals/SelectProvinceModal';

export interface SelectedZonesInterface {
  provinceId: string;
  provinceName: string;
  citiesIds: string[];
}

interface DeliveryZoneCardProp {
  selectedZones?: SelectedZonesInterface[];
  setFieldValue: (name: string, value: any) => void;
}

function DeliveryZoneCard({
  selectedZones,
  setFieldValue,
}: Readonly<DeliveryZoneCardProp>) {
  const [isOpen, openModal, closeModal] = useModal();
  const t = useTranslationShipping();
  const onChange = (deliveryZones: SelectedZonesInterface[]) => {
    setFieldValue('deliveryZones', deliveryZones);
  };

  const handleRemoveZone = (provinceId: string) => {
    if (!!selectedZones && selectedZones?.length > 0) {
      const newDeliveryZones = selectedZones.filter(
        (zone) => zone.provinceId !== provinceId,
      );
      onChange(newDeliveryZones);
    }
  };

  return (
    <CardCollapsible
      title={
        <Stack spacing="tight">
          <Title as="h3">
            {t('deliveryMethods.customShipping.shippingZone.title')}
          </Title>
          <Text>
            {t('deliveryMethods.customShipping.shippingZone.optional')}
          </Text>
        </Stack>
      }
      active={false}
    >
      <Stack column align="stretch">
        <Text>
          {t('deliveryMethods.customShipping.shippingZone.description')}
        </Text>
        <Stack wrap>
          {selectedZones?.map(({ provinceId, provinceName, citiesIds }) => (
            <Chip
              key={provinceId}
              id={provinceId}
              text={
                citiesIds?.length !== 0
                  ? `${provinceName} (${citiesIds.length})`
                  : `${provinceName} (${t(
                      'deliveryMethods.customShipping.allCities',
                    )})`
              }
              removable
              onClick={() => handleRemoveZone(provinceId)}
            />
          ))}
        </Stack>
        <Link appearance="primary" onClick={openModal} textDecoration="none">
          <Icon color="currentColor" source={<PlusCircleIcon />} />
          {t('deliveryMethods.customShipping.shippingZone.add')}
        </Link>
      </Stack>
      {isOpen && selectedZones && (
        <SelectProvinceModal
          selectedZones={selectedZones}
          isOpen={isOpen}
          onClose={closeModal}
          onChange={onChange}
        />
      )}
    </CardCollapsible>
  );
}
export default DeliveryZoneCard;
