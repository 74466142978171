import { Accordion, Box, Card, Link, Text } from '@nimbus-ds/components';
import { Trans } from 'react-i18next';
import { DomainToUpdateDto } from '@tiendanube/common';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { CopyDomainText } from './CopyDomainText';

interface AccordionDomainListProps {
  domainToUpdate: DomainToUpdateDto;
}

export function AccordionDomainList({
  domainToUpdate,
}: Readonly<AccordionDomainListProps>) {
  const t = useTranslationConfigurations('domains');

  function getDomainFromUrl(url: string): string {
    try {
      const urlObject = new URL(url);
      return urlObject.hostname;
    } catch (err) {
      return url;
    }
  }

  return (
    <Box width="100%" borderRadius="2">
      <Card padding="none">
        <Accordion selectedDefault="0">
          {domainToUpdate.domains.map(({ domain, ns, registrar }, index) => (
            <Accordion.Item index={`${index}`} key={domain}>
              <Accordion.Header title={domain} />
              <Accordion.Body>
                <Box display="flex" flexDirection="column" gap="4">
                  <Box display="flex" flexDirection="row" gap="1">
                    <Text>
                      <Trans
                        t={t}
                        i18nKey="softBlock.register"
                        values={{
                          domain,
                        }}
                        components={{
                          customLink: <CopyDomainText> </CopyDomainText>,
                        }}
                      />
                    </Text>
                    <Link
                      as="a"
                      target="_blank"
                      href={registrar}
                      appearance="primary"
                      textDecoration="none"
                    >
                      {getDomainFromUrl(registrar)}
                    </Link>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    borderColor="neutral-interactive"
                    width="100%"
                    gap="1"
                  >
                    {ns.map((n, index) => (
                      <CopyDomainText key={`${n}-${index}`}>{n}</CopyDomainText>
                    ))}
                  </Box>
                </Box>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Card>
    </Box>
  );
}
