import {
  MetafieldFormDto,
  MetafieldsListV2Dto,
  MetafieldsMapV2Dto,
  MetafieldsValueV2Type,
} from '@tiendanube/common';
import axios from 'App/axios';
import {
  METAFIELDS_CATEGORIES_URL,
  METAFIELDS_CUSTOMERS_URL,
  METAFIELDS_ORDERS_URL,
  METAFIELDS_VARIANTS_URL,
  METAFIELDS_PRODUCTS_AND_VARIANTS_URL,
} from './urls';

export const getMetafieldsOrders = async (): Promise<MetafieldsMapV2Dto> => {
  const { data } = await axios.get<MetafieldsMapV2Dto>(METAFIELDS_ORDERS_URL);
  return data;
};

export const getMetafieldsVariants = async (): Promise<MetafieldsListV2Dto> => {
  const { data } = await axios.get<MetafieldsListV2Dto>(
    METAFIELDS_VARIANTS_URL,
  );
  return data;
};

export const getMetafieldsCustomer = async (): Promise<MetafieldsListV2Dto> => {
  const { data } = await axios.get<MetafieldsListV2Dto>(
    METAFIELDS_CUSTOMERS_URL,
  );
  return data;
};

export const getWholesaleCustomerMetafields = async (): Promise<
  MetafieldFormDto[]
> => {
  // TODO: Add new URL const and get real data;
  const data = new Promise<MetafieldFormDto[]>((resolve) => {
    const metafields = [
      {
        id: '7db5dbfd-98ff-47d5-8627-ffd7b0f3ad40',
        key: 'tipo-de-cadastro',
        name: 'Tipo de cadastro',
        type: 'select' as MetafieldsValueV2Type,
        values: ['Pessoa física', 'Pessoa jurídica'],
        value: '',
        disabled: false,
        formFieldConfig: {
          type: '',
          labeled: true,
          hidden: false,
          defaultValue: 'Pessoa jurídica',
          placeholder: '',
          events: {},
          rules: [],
        },
      },
      {
        id: '65b1ecd7-112e-45d1-9d70-db2326e4f6d6',
        key: 'segmento',
        name: 'Segmento',
        type: 'select' as MetafieldsValueV2Type,
        values: ['Atacadista', 'Varejista'],
        value: '',
        disabled: false,
        formFieldConfig: {
          type: 'select',
          labeled: true,
          hidden: false,
          defaultValue: 'Atacadista',
          placeholder: '',
          events: {},
          rules: [],
        },
      },
      {
        id: '0c7fc2ce-8871-485d-a430-aade3830524f',
        key: 'cpf',
        name: 'CPF',
        type: 'text' as MetafieldsValueV2Type,
        values: [],
        value: '',
        disabled: false,
        formFieldConfig: {
          type: 'text',
          labeled: true,
          hidden: true,
          defaultValue: '',
          placeholder: '000.000.000-00',
          events: {},
          rules: [
            {
              when: {
                fieldKey: 'tipo-de-cadastro',
                value: 'Pessoa física',
              },
              actions: [{ type: 'show' }],
            },
            {
              when: {
                fieldKey: 'tipo-de-cadastro',
                value: 'Pessoa jurídica',
              },
              actions: [{ type: 'hide' }, { type: 'set', value: '' }],
            },
          ],
        },
      },
      {
        id: 'eecd5cdb-da79-42ee-8da3-621ab28b5ff9',
        key: 'razao-social',
        name: 'Razão Social',
        type: 'text' as MetafieldsValueV2Type,
        values: [],
        value: '',
        disabled: false,
        formFieldConfig: {
          type: 'text',
          labeled: true,
          hidden: false,
          defaultValue: '',
          placeholder: 'Minha Loja',
          events: {},
          rules: [
            {
              when: {
                fieldKey: 'tipo-de-cadastro',
                value: 'Pessoa jurídica',
              },
              actions: [{ type: 'show' }],
            },
            {
              when: {
                fieldKey: 'tipo-de-cadastro',
                value: 'Pessoa física',
              },
              actions: [{ type: 'hide' }, { type: 'set', value: '' }],
            },
          ],
        },
      },
      {
        id: '5011442d-a102-4c43-9f6c-58dd10076d06',
        key: 'cnpj',
        name: 'CNPJ',
        type: 'text' as MetafieldsValueV2Type,
        values: [],
        value: '',
        disabled: false,
        formFieldConfig: {
          type: 'text',
          labeled: true,
          hidden: false,
          defaultValue: '',
          placeholder: '00.000.000/0000-00',
          events: {},
          rules: [
            {
              when: {
                fieldKey: 'tipo-de-cadastro',
                value: 'Pessoa jurídica',
              },
              actions: [{ type: 'show' }],
            },
            {
              when: {
                fieldKey: 'tipo-de-cadastro',
                value: 'Pessoa física',
              },
              actions: [{ type: 'hide' }, { type: 'set', value: '' }],
            },
          ],
        },
      },
      {
        id: 'ef866e17-2bb5-4552-a39d-aee8b82f86f5',
        key: 'inscricao-estadual',
        name: 'Inscrição Estadual',
        type: 'text' as MetafieldsValueV2Type,
        values: [],
        value: '',
        disabled: false,
        formFieldConfig: {
          type: 'text',
          labeled: true,
          hidden: false,
          defaultValue: '',
          placeholder: '123456 ou Isento',
          events: {},
          rules: [
            {
              when: {
                fieldKey: 'inscricao-estadual-isento',
                value: 'Isento',
              },
              actions: [{ type: 'disable' }, { type: 'set', value: 'Isento' }],
            },
            {
              when: {
                fieldKey: 'inscricao-estadual-isento',
                value: '',
              },
              actions: [{ type: 'enable' }, { type: 'set', value: '' }],
            },
            {
              when: {
                fieldKey: 'tipo-de-cadastro',
                value: 'Pessoa jurídica',
              },
              actions: [{ type: 'show' }],
            },
            {
              when: {
                fieldKey: 'tipo-de-cadastro',
                value: 'Pessoa física',
              },
              actions: [
                { type: 'hide' },
                { type: 'enable' },
                { type: 'set', value: '' },
              ],
            },
          ],
        },
      },
      {
        id: '56f6ed16-ca37-4455-a094-d0000ccffff6',
        key: 'inscricao-estadual-isento',
        name: 'Isento',
        type: 'select' as MetafieldsValueV2Type,
        values: ['Isento'],
        value: '',
        disabled: false,
        formFieldConfig: {
          type: 'checkbox',
          labeled: false,
          hidden: false,
          defaultValue: '',
          placeholder: '',
          events: {},
          rules: [
            {
              when: {
                fieldKey: 'tipo-de-cadastro',
                value: 'Pessoa jurídica',
              },
              actions: [{ type: 'show' }],
            },
            {
              when: {
                fieldKey: 'tipo-de-cadastro',
                value: 'Pessoa física',
              },
              actions: [{ type: 'hide' }, { type: 'set', value: '' }],
            },
          ],
        },
      },
    ];

    setTimeout(() => {
      resolve(metafields);
    }, 1000);
  });

  return data;
};

export const getMetafieldsCategories =
  async (): Promise<MetafieldsListV2Dto> => {
    const { data } = await axios.get<MetafieldsListV2Dto>(
      METAFIELDS_CATEGORIES_URL,
    );
    return data;
  };

export const getMetafieldsProductsAndVariants =
  async (): Promise<MetafieldsListV2Dto> => {
    const { data } = await axios.get<MetafieldsListV2Dto>(
      METAFIELDS_PRODUCTS_AND_VARIANTS_URL,
    );
    return data;
  };
