import { FEATURE_EDIT_ORDER } from 'App/features';
import { useNavegate } from 'App/hooks';
import { ORDERS_EDIT_ORDERS } from 'config/upsellFlowSources';
import useUpsellFlow, {
  UpsellResultEnum,
} from 'domains/Billing/UpsellFlow/hooks/useUpsellFlow';
import { trackingOrderEditClick } from 'domains/Orders/tracking';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

export function useEditOrderUpsell(orderId: string | undefined) {
  const { goTo } = useNavegate();
  const t = useTranslationOrders();

  return useUpsellFlow({
    title: t('editOrders.upsellTitle'),
    featureKey: FEATURE_EDIT_ORDER,
    trackingSource: ORDERS_EDIT_ORDERS,
    includeCallbackResult: true,
    onPlansRedirect: () => {
      trackingOrderEditClick(UpsellResultEnum.PLANS_REDIRECT);
    },
    callback: (upsellResult) => {
      if (orderId) {
        trackingOrderEditClick(upsellResult);
        goTo(`/orders/${orderId}/edit`);
      }
    },
    onCloseModal: (upsellResult) => {
      if (upsellResult === UpsellResultEnum.DECLINED) {
        trackingOrderEditClick(upsellResult);
      }
    },
  });
}
