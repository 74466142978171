import { Box, Card, IconButton, Text } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import './Skeleton.scss';

function Skeleton() {
  return (
    <div className="stratus--sort-payments-card-skeleton">
      <Box display="flex" flexDirection="column" gap="8">
        <Box display="flex" gap="2-5" flexDirection="column">
          <Text.Skeleton width="80px" height="8px" />
          <Box display="flex" flexDirection="row" gap="4">
            <Text.Skeleton width="60px" height="25px" />
            <Text.Skeleton width="90px" height="25px" />
          </Box>
        </Box>
        <Box>
          <Card padding="none">
            <Card.Header padding="base">
              <Box alignItems="center">
                <Text.Skeleton height="25px" width="180px" />
              </Box>
            </Card.Header>
            <Card.Body>
              <DataList bottomDivider={false}>
                {[...Array(3)].map((_, index) => (
                  <DataList.Row key={index} id={index.toString()}>
                    <Box display="flex" alignItems="center" gap="2">
                      <IconButton.Skeleton width="20px" height="20px" />
                      <Text.Skeleton width="100px" height="20px" />
                    </Box>
                  </DataList.Row>
                ))}
              </DataList>
            </Card.Body>
          </Card>
        </Box>
        <Box display="flex" justifyContent="flex-end" gap="2">
          <Text.Skeleton height="30px" width="80px" />
          <Text.Skeleton height="30px" width="80px" />
        </Box>
      </Box>
    </div>
  );
}

export default Skeleton;
