import { ReactNode } from 'react';
import { Link, LinkBaseProps, Popover } from '@nimbus-ds/components';
import { ChevronDownIcon, ChevronUpIcon } from '@nimbus-ds/icons';
import { useBoolean } from 'commons/hooks';

interface PopoverToogleProps {
  label: ReactNode;
  content: ReactNode;
  appearance?: LinkBaseProps['appearance'];
}

function PopoverToogle({
  label,
  content,
  appearance = undefined,
}: Readonly<PopoverToogleProps>) {
  const [popover, showPopover, hidePopover] = useBoolean(false);

  const Icon = popover ? ChevronUpIcon : ChevronDownIcon;

  return (
    <Popover
      arrow={false}
      content={content}
      position="bottom-start"
      visible={popover}
      maxWidth="80%"
      onVisibility={(current) => (current ? showPopover() : hidePopover())}
    >
      <Link as="button" textDecoration="none" appearance={appearance}>
        {label}
        <Icon />
      </Link>
    </Popover>
  );
}

export default PopoverToogle;
