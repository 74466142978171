import { useMemo } from 'react';
import Status from '@tiendanube/common/src/enums/Status';
import { InterfaceSelectOptionGroup } from '@tiendanube/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

export function useGetStatusOptionsFilters(status) {
  const t = useTranslationOrders();

  const statusOptions: InterfaceSelectOptionGroup[] = useMemo(
    () =>
      status.map((status) => ({
        value: status === Status.OPEN ? '' : status,
        label: t(`filters.status.${status}`),
      })),
    [status, t],
  );

  return statusOptions;
}
