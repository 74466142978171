import { GetCustomerTablePricesType } from 'domains/Customers/CustomerTablePrices/customerTablePricesService/types';

export const getCustomerTablePrices: GetCustomerTablePricesType = async (
  customerId,
) => {
  // TODO: Remove console.log and add new URL const when real data is available;
  console.log(customerId);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        tablePricesIds: ['1'],
      });
    }, 1000);
  });
};

const customerTablePricesService: {
  getCustomerTablePrices: GetCustomerTablePricesType;
} = {
  getCustomerTablePrices,
};

export default customerTablePricesService;
