import { useEffect, useState } from 'react';
import {
  BaseCard,
  Input,
  InterfaceNameValue,
  Title,
} from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
import { useModal } from 'commons/hooks';
import { useTranslationAccount } from 'domains/Account/hooks';
import CancelAndContinue from '../CancelAndContinue';
import DeleteAccountConfirmationModal from '../DeleteAccountConfirmationModal';

interface ShopCancellationThirdStepProps {
  onCancel: () => void;
  onDeleteAccount: (suggestions: string) => void;
  isLoading: boolean;
}

function ShopCancellationThirdStep({
  onCancel,
  onDeleteAccount,
  isLoading,
}: ShopCancellationThirdStepProps): JSX.Element {
  const t = useTranslationAccount();
  const [suggestions, setSuggestions] = useState<string>();

  const [isShow, openModal, closeModal] = useModal();
  const handleConfirm = () => {
    onDeleteAccount(suggestions || '');
  };
  const handleChange = (event: InterfaceNameValue) =>
    setSuggestions(event.value);

  useEffect(() => {
    if (!isLoading) {
      closeModal();
    }
  }, [isLoading, closeModal]);

  return (
    <BaseCard>
      <DeleteAccountConfirmationModal
        show={isShow}
        onClose={closeModal}
        onConfirm={handleConfirm}
        isLoading={isLoading}
      />

      <BaseCard.Header>
        <Title type="h3">{`${t(
          'shopCancellationPage.stepThird.anyMore',
        )}`}</Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column spacing="tight" align="stretch">
          <Text>{t('shopCancellationPage.stepThird.suggestions')}</Text>
          <Input
            maxLength={200}
            value={suggestions}
            onChange={handleChange}
            name="suggestions"
            multiRows
            rows={6}
          />
        </Stack>
      </BaseCard.Body>
      <BaseCard.Footer>
        <CancelAndContinue onCancel={onCancel} onContinue={openModal} />
      </BaseCard.Footer>
    </BaseCard>
  );
}

export default ShopCancellationThirdStep;
