import { ChangeEvent, useState } from 'react';
import { Checkbox } from '@nimbus-ds/components';
import { ExclamationCircleIcon } from '@nimbus-ds/icons';
import { FormField } from '@nimbus-ds/patterns';
import { OrderFulfillRequestDto } from '@tiendanube/common';
import { Modal } from '@tiendanube/components';
import { Stack } from 'commons/components';
import { useAutoFocus } from 'commons/hooks';
import {
  trackingOrderEditTrackingCodeSaveClick,
  trackingOrderNotifyShippingButtonClick,
} from 'domains/Orders/tracking';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface ConfirmTrackingNumModalProps {
  shippingTrackingNum?: string;
  onClose: () => void;
  onConfirm: (fulfill: OrderFulfillRequestDto) => void;
  isDigital: boolean;
  isEditing?: boolean;
}

function ConfirmTrackingNumModal({
  shippingTrackingNum,
  onClose,
  onConfirm,
  isDigital,
  isEditing = false,
}: ConfirmTrackingNumModalProps): JSX.Element {
  const t = useTranslationOrders();
  const { ref } = useAutoFocus();

  const [notifyFulfillment, setNotifyFulfillment] =
    useState<OrderFulfillRequestDto>({
      fulfillments: [],
      shippingTrackingNum,
      sendNotification: !isEditing, // Sets the default value for the checkbox.
      isEdit: isEditing,
    });

  const [error, setError] = useState<string | null>(null);
  const handleChange = ({
    target: { name, value, type, checked },
  }: ChangeEvent<HTMLInputElement>) => {
    setError(null);
    setNotifyFulfillment((state) => ({
      ...state,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleClickFulfill = () => {
    if (
      isEditing &&
      notifyFulfillment.shippingTrackingNum?.trim() ===
        shippingTrackingNum?.trim()
    ) {
      setError(t('quickActions.modal.fieldEqualsError'));
      return;
    }
    setError(null);
    if (isEditing) {
      trackingOrderEditTrackingCodeSaveClick(
        notifyFulfillment.sendNotification ?? false,
      );
    } else {
      trackingOrderNotifyShippingButtonClick(
        !!notifyFulfillment.shippingTrackingNum,
        !!notifyFulfillment.sendNotification,
      );
    }
    onConfirm(notifyFulfillment);
  };

  const baseKey = isDigital
    ? 'quickActions.modal.digital'
    : 'quickActions.modal';

  const title = isEditing ? `${baseKey}.editTitle` : `${baseKey}.title`;
  const label = isEditing ? `${baseKey}.editTracking` : `${baseKey}.tracking`;
  const action = isEditing
    ? 'quickActions.modal.editAction'
    : 'quickActions.modal.action';

  const isDisabled =
    isEditing && (!notifyFulfillment.shippingTrackingNum?.trim() || !!error);

  return (
    <Modal
      title={t(title)}
      show
      onDismiss={onClose}
      primaryAction={{
        children: t(action),
        disabled: isDisabled,
        onClick: handleClickFulfill,
        appearance: 'primary',
      }}
      secondaryAction={{
        children: `${t('quickActions.modal.close')}`,
        onClick: onClose,
      }}
    >
      <Stack innerRef={ref} column align="stretch">
        <FormField.Input
          value={notifyFulfillment.shippingTrackingNum}
          helpText={error || undefined}
          label={t(label)}
          type="text"
          appearance={error ? 'danger' : undefined}
          helpIcon={error ? ExclamationCircleIcon : undefined}
          showHelpText={isEditing}
          name="shippingTrackingNum"
          onChange={handleChange}
        />
        <Checkbox
          label={t('quickActions.modal.sendNotification')}
          name="sendNotification"
          onChange={handleChange}
          checked={notifyFulfillment.sendNotification}
        />
      </Stack>
    </Modal>
  );
}

export default ConfirmTrackingNumModal;
