import HelpLink from 'App/HelpLink';
import { CAN_LINK_ABOUT_ORDERS } from 'commons/constants/externalLinks';
import useTranslationLanguage from 'commons/hooks/useTranslationLanguage';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

export function OrderListEmptyHelpLink() {
  const t = useTranslationOrders();
  const language = useTranslationLanguage();

  return (
    <HelpLink
      title={t('helpText')}
      previousValue=""
      currentViewTracking=""
      linkURL={CAN_LINK_ABOUT_ORDERS[language]}
      icon="both"
      showInMobile
    />
  );
}
