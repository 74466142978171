import { useEffect, useMemo } from 'react';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { useNavegate } from 'App/hooks';
import { toStatusType } from 'App/rtk';
import { useForm, useToastStatus } from 'commons/hooks';
import { trackingEmailNotificationConfiguration } from 'domains/Account/tracking';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { useGetMainUserId, useGetUserInfo } from 'domains/Auth/hooks';
import {
  useGetEmailTypesQuery,
  useGetRolesQuery,
  useGetUserQuery,
  useUpdateUserMutation,
} from 'domains/Configurations/Users/usersApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { validationSchema } from './validationSchema';
import { EDIT_USER_INITIAL_VALUES } from '../../constants';
import UserForm from '../UserForm';

interface EditUserFormProps {
  userId: string;
}

function EditUserForm({ userId }: Readonly<EditUserFormProps>) {
  const t = useTranslationConfigurations('userFormPage');
  const { goBack } = useNavegate();
  const [updateUser, { status, error: requestError }] = useUpdateUserMutation();
  const mainUserId = useGetMainUserId();
  const { id: currentUserId } = useGetUserInfo();
  const isCurrentUser = userId === currentUserId;
  const { validateRoles } = useRoles();

  const isFullRole = validateRoles('full');
  const { scrollToTop } = useLayoutScroll();

  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
    refetch: refetchUser,
  } = useGetUserQuery(userId);

  const {
    data: storeRoles,
    isLoading: isRolesLoading,
    isError: isRolesError,
    refetch: refetchRoles,
  } = useGetRolesQuery();

  const {
    values,
    errors,
    isDirty,
    setFieldValue,
    handleChange,
    handleValidations,
    handleOnSubmit,
  } = useForm({
    initialValues: user || EDIT_USER_INITIAL_VALUES,
    validationSchema,
    onSubmit: (data) => updateUser({ id: userId, payload: data }),
  });

  const updateStatus = toStatusType(status);
  const requestErrors =
    (requestError as any)?.data?.response?.description || {};

  useToastStatus({
    status: updateStatus,
    success: t('toast.editSuccess'),
    error: requestErrors?.description?.email
      ? t(`errors.emailAlreadyTaken`)
      : t('toast.editError'),
    onSuccess: goBack,
  });

  const refetch = () => {
    refetchUser();
    refetchRoles();
  };

  const isError = useMemo(() => {
    if (!isFullRole) return isUserError || !user;
    return isUserError || isRolesError || !user;
  }, [isFullRole, isUserError, isRolesError, user]);

  const isLoading = useMemo(() => {
    if (!isFullRole) return isUserLoading;
    return isUserLoading || isRolesLoading;
  }, [isFullRole, isUserLoading, isRolesLoading]);

  const { data: emailTypes } = useGetEmailTypesQuery();

  const handleEventsAndOnSubmit = () => {
    const currentEmailTypes = user?.emailTypes || [];
    const newEmailTypes = values.emailTypes;

    const trackConfiguration = (emailTypes: string[], isEnabled: boolean) => {
      emailTypes.forEach((emailType) => {
        trackingEmailNotificationConfiguration(isEnabled, emailType);
      });
    };

    if (currentEmailTypes.length === 0 && newEmailTypes.length > 0) {
      trackConfiguration(newEmailTypes, true);
      handleOnSubmit();
      return;
    }

    if (currentEmailTypes.length > 0 && newEmailTypes.length === 0) {
      trackConfiguration(currentEmailTypes, false);
      handleOnSubmit();
      return;
    }

    const newSelected = newEmailTypes.filter(
      (item) => !currentEmailTypes.includes(item),
    );
    const unselected = currentEmailTypes.filter(
      (item) => !newEmailTypes.includes(item),
    );

    trackConfiguration(newSelected, true);
    trackConfiguration(unselected, false);
    handleOnSubmit();
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      scrollToTop();
    }
  }, [errors, scrollToTop]);

  return (
    <UserForm
      isMainUser={String(userId) === String(mainUserId)}
      isLoading={isLoading}
      isError={isError}
      isDirty={isDirty}
      values={values}
      errors={errors}
      requestErrors={requestErrors}
      submitStatus={updateStatus}
      storeRoles={storeRoles}
      emailTypes={emailTypes}
      onChange={handleChange}
      onChangeRole={setFieldValue}
      onChangeEmailType={setFieldValue}
      onRefetch={refetch}
      onSubmit={handleEventsAndOnSubmit}
      onValidate={handleValidations}
      isCurrentUser={isCurrentUser}
    />
  );
}

export default EditUserForm;
