import { useCallback } from 'react';
import { CheckoutPaymentMethod } from '@tiendanube/common';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import {
  usePayorderToPay,
  usePaymentSetup,
} from 'domains/Billing/Checkout/hooks';

function usePaymentSelectionForPayOrder() {
  const { payPayOrder, extraStatus, resetPreference } = usePaymentSetup();

  const preferenceLoading = extraStatus === 'loading';

  const { payorderToPay, status, mainConcept, refreshPayOrderToPay } =
    usePayorderToPay();

  const availablePaymentMethods = payorderToPay?.allowedPaymentMethods;

  const payWithMethod = useCallback(
    (method: CheckoutPaymentMethod) => {
      if (preferenceLoading) return;
      payPayOrder[method](method, payorderToPay);
    },
    [payorderToPay, payPayOrder, preferenceLoading],
  );

  return {
    availablePaymentMethods,
    payWithMethod,
    ...convertStatusTypeToObject(status),
    preferenceStatus: extraStatus || 'idle',
    preferenceLoading,
    onFinish: resetPreference,
    mainConcept,
    retryLoad: refreshPayOrderToPay,
  };
}

export default usePaymentSelectionForPayOrder;
