export const PartnerAppsEndpoints = {
  getAppsList: 'getAppsList',
  getUninstallReasons: 'getUninstallReasons',
  deleteApp: 'deleteApp',
  uninstallApp: 'uninstallApp',
  postAppCharge: 'postAppCharge',
  getOldAdminAuthorizationCode: 'getOldAdminAuthorizationCode',
  getAppPlan: 'getAppPlan',
} as const;

export const PartnerAppsTags = {
  appsList: 'appsList',
  appPlan: 'appPlan',
} as const;
