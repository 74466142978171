import { ChangeEvent } from 'react';
import { Box, Button, Icon, Input } from '@nimbus-ds/components';
import { SlidersIcon } from '@nimbus-ds/icons';
import { SearchFilterInterfaceIdLabel } from 'commons/types';
import { SearchFilterAppliedFilters } from '../SearchFilterAppliedFilters';

interface SearchFilterProps {
  placeholder: string;
  label?: string;
  ariaLabel?: string;
  resultCount?: string;
  appliedFilters: SearchFilterInterfaceIdLabel[];
  desktopWrap?: 'wrap' | 'nowrap';
  isLoading?: boolean;
  onClick?: () => void;
  onDismiss?: (key: string) => any;
  rightElement?: React.ReactNode;
  onSubmit: (text: string) => void;
  value: string;
  onChange: (value: string) => void;
  sortByElement?: React.ReactNode;
}
function SearchFilter({
  placeholder,
  label: labelButton,
  ariaLabel,
  resultCount,
  appliedFilters,
  rightElement,
  onSubmit,
  desktopWrap,
  onDismiss,
  onClick,
  isLoading,
  value,
  onChange,
  sortByElement,
}: Readonly<SearchFilterProps>) {
  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onSubmit(value);
    }
  };

  const renderFiltersButton = onClick && (
    <Button onClick={onClick}>
      <Icon source={<SlidersIcon />} />
      {labelButton}
    </Button>
  );

  const styleGridSortBy = sortByElement ? '9.5fr 0.5fr' : '';

  return (
    <Box display="flex" flexDirection="column" gap="2">
      <Box display="grid" gridTemplateColumns={styleGridSortBy} gridGap="1">
        <Box display="flex" gap="1">
          <Input.Search
            type="search"
            name="search"
            aria-label={ariaLabel}
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            value={value}
            onChange={handleChange}
            maxLength={150}
          />
          {renderFiltersButton}
        </Box>
        {!!sortByElement && <Box>{sortByElement}</Box>}
      </Box>
      <SearchFilterAppliedFilters
        resultCount={resultCount}
        appliedFilters={appliedFilters}
        rightElement={rightElement}
        desktopWrap={desktopWrap}
        isLoading={isLoading}
        onChange={onChange}
        onDismiss={onDismiss}
      />
    </Box>
  );
}

export default SearchFilter;
