import { useCallback } from 'react';
import { generatePath } from 'react-router-dom';
import { CheckoutPaymentMethod, PayOrderCreatedDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { StatusType } from 'commons/types';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import useAfterPaymentRedirection from './useAfterPaymentRedirection';
import useMercadoPagoPreference from './useMercadoPagoPreference';

interface UsePaymentSetupOutput {
  payPayOrder: Record<
    CheckoutPaymentMethod,
    (
      method: CheckoutPaymentMethod,
      payOrder?: PayOrderCreatedDto | string | null,
    ) => void
  >;
  extraStatus?: StatusType;
  resetPreference: () => void;
}

const getPayOrderId = (payOrder: PayOrderCreatedDto | string) =>
  typeof payOrder === 'string' ? payOrder : payOrder.id;

function usePaymentSetup(): UsePaymentSetupOutput {
  const { goTo } = useNavegate();
  const { passOnActionToPath } = useAfterPaymentRedirection();

  const payWithCreditCard = useCallback(
    (
      _method: CheckoutPaymentMethod,
      payOrder?: PayOrderCreatedDto | string | null,
    ) => {
      if (!payOrder) return;
      const payOrderId = getPayOrderId(payOrder);
      goTo(
        passOnActionToPath(
          generatePath(CHECKOUT_ROUTES.payWithCreditCard, {
            payOrderId,
          }),
        ),
      );
    },
    [goTo, passOnActionToPath],
  );

  const payWithTransfer = useCallback(
    (
      _method: CheckoutPaymentMethod,
      payOrder?: PayOrderCreatedDto | string | null,
    ) => {
      if (!payOrder) return;
      const payOrderId = getPayOrderId(payOrder);
      goTo(
        passOnActionToPath(
          generatePath(CHECKOUT_ROUTES.payWithWireTransfer, {
            payOrderId,
          }),
        ),
      );
    },
    [goTo, passOnActionToPath],
  );

  const { status, requestPreference, resetPreference } =
    useMercadoPagoPreference();

  const payWithMercadoPago = useCallback(
    (
      method: CheckoutPaymentMethod,
      payOrder?: PayOrderCreatedDto | string | null,
    ) => {
      if (!payOrder) return;
      const payOrderId = getPayOrderId(payOrder);
      requestPreference(payOrderId, [method])
        ?.unwrap()
        .then((response) => {
          if (response?.url) window.location.href = response.url;
        });
    },
    [requestPreference],
  );

  return {
    payPayOrder: {
      cc: payWithCreditCard,
      ticket: payWithMercadoPago,
      transfer: payWithTransfer,
      'mp-bank-transfer': payWithMercadoPago,
      'mp-boleto': payWithMercadoPago,
      'mp-cc': payWithMercadoPago,
      'mp-dc': payWithMercadoPago,
      'mp-ticket': payWithMercadoPago,
    },
    extraStatus: status,
    resetPreference,
  };
}

export default usePaymentSetup;
