import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useHasNewPickupPoints } from 'domains/Auth/hooks';
import {
  getTab,
  updateTabIndex,
} from 'domains/Shipping/DeliveryMethods/deliveryMethodsSlice';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

const formatId = (translate: string) =>
  `tab-${translate.toLowerCase().replace(' ', '-')}`;

function useTabToShow() {
  const t = useTranslationShipping('deliveryMethods.tabs');
  const hasPickupPoints = useHasNewPickupPoints();
  const dispatch = useAppDispatch();
  const tab = useSelector(getTab);
  const changeTabToShow = (id: string) => {
    let selectedTab = tab;
    switch (id) {
      case formatId(t('nationals')):
        selectedTab = 0;
        break;
      case formatId(t('international')):
        selectedTab = 1;
        break;
      case formatId(t('phisical')):
        selectedTab = hasPickupPoints ? 3 : 2;
        break;
      case formatId(t('pickup')):
        selectedTab = 2;
        break;
    }
    dispatch(updateTabIndex(selectedTab));
  };
  return {
    tab,
    changeTabToShow,
  };
}

export default useTabToShow;
