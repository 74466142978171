import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { Layout } from '@nimbus-ds/patterns';
import { Trans, useTranslation } from 'react-i18next';

export function NuvemChatPlans() {
  const { t } = useTranslation('nuvemchat');

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" gap="4">
        <Box width="100%" height="auto" backgroundColor="neutral-surface">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            gap="2"
          >
            <Text fontSize="base" color="neutral-textHigh" textAlign="left">
              {t('plans.subtitle')}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap="4" paddingBottom="20">
        <Layout width="100%" columns="3">
          <Layout.Section justifyContent="center" alignItems="flex-end">
            <Card>
              <Card.Body>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="4"
                  paddingBottom="5"
                >
                  <Text fontWeight="bold" color="neutral-textHigh">
                    1 - 500 {t('plans.conversationMonth')}
                  </Text>
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap="4"
                    alignItems="baseline"
                  >
                    <Title as="h2">{t('plans.price_1_500')}</Title>
                    <Text fontSize="base">{t('plans.detail')}</Text>
                  </Box>
                </Box>
              </Card.Body>
            </Card>
          </Layout.Section>
          <Layout.Section justifyContent="center" alignItems="flex-end">
            <Card>
              <Card.Body>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="4"
                  paddingBottom="5"
                >
                  <Text fontWeight="bold" color="neutral-textHigh">
                    501 - 1000 {t('plans.conversationMonth')}
                  </Text>
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap="4"
                    alignItems="baseline"
                  >
                    <Title as="h2">{t('plans.price_501_1000')}</Title>
                    <Text fontSize="base">{t('plans.detail')}</Text>
                  </Box>
                </Box>
              </Card.Body>
            </Card>
          </Layout.Section>
          <Layout.Section justifyContent="center" alignItems="flex-end">
            <Card>
              <Card.Body>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="4"
                  paddingBottom="5"
                >
                  <Text fontWeight="bold" color="neutral-textHigh">
                    + 1000 {t('plans.conversationMonth')}
                  </Text>
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap="4"
                    alignItems="baseline"
                  >
                    <Title as="h2">{t('plans.price_1000_plus')}</Title>
                    <Text fontSize="base">{t('plans.detail')}</Text>
                  </Box>
                </Box>
              </Card.Body>
            </Card>
          </Layout.Section>
        </Layout>
        <Text fontSize="base" color="neutral-textHigh" textAlign="left">
          <Trans
            t={t}
            i18nKey="plans.helpText"
            components={{ bold: <strong /> }}
          />
        </Text>
      </Box>
    </>
  );
}
