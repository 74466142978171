import { useEffect, useState } from 'react';
import {
  DataTable,
  EmptySearch,
  ErrorState,
  Link,
  Stack,
} from '@tiendanube/components';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import PaginationStratus from 'commons/components/PaginationStratus';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import {
  ResultHeaderDesktop,
  ResultRowDesktop,
  ResultRowDesktopSkeleton,
} from './components';
import { useProductsList } from '../../../hooks';
import { useBulkActionProducts } from '../hooks/useBulkActionProducts';

import './ProductResultsDesktop.scss';

const PRODUCTS_PER_PAGE = 20;

function ProductResultsDesktop(): JSX.Element {
  const t = useTranslationCatalog();

  const {
    isLoading,
    isSuccess,
    isError,
    productsCount,
    productsIds,
    filters,
    hasFilters,
    hasVariants,
    refreshProductsList,
    goToPage,
    urlToBuild,
  } = useProductsList();
  const { scrollToTop } = useLayoutScroll();
  const [isAllSelected, setIsAllSelected] = useState(false);

  const textSelectAllElement = !isAllSelected
    ? t(`products.bulkActions.selectAll`, { count: productsCount })
    : t(`products.bulkActions.deselectAll`, { count: productsCount });

  const selectAllElement =
    filters.q || productsCount <= PRODUCTS_PER_PAGE ? undefined : (
      <Link
        appearance="primary"
        size="small"
        onClick={() => handleOnSelectAll(!selectAll)}
      >
        {textSelectAllElement}
      </Link>
    );

  const {
    bulkAction,
    showAlertMetafields,
    deactivateAlertMetafields,
    handleOnSelectRow,
    selectedRowsId,
    selectAll,
    handleOnSelectAll,
  } = useBulkActionProducts({
    rowsId: productsIds,
    selectAllElement,
  });

  useEffect(() => {
    handleOnSelectAll(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    setIsAllSelected(selectAll);
  }, [selectAll]);

  const handleSelectPage = (page: number) => {
    scrollToTop();
    goToPage(page);
    if (showAlertMetafields) {
      deactivateAlertMetafields();
    }
  };

  const showBulkCheckbox = !isLoading;

  if (isError && productsIds.length === 0) {
    return (
      <ErrorState
        title={t('products.errorResults.title')}
        action={{
          children: t('products.errorResults.action'),
          onClick: refreshProductsList,
        }}
      />
    );
  }

  if (isSuccess && productsIds.length === 0 && hasFilters) {
    return (
      <EmptySearch
        text={t('products.withoutResults.text')}
        title={t('products.withoutResults.title')}
      />
    );
  }

  return (
    <div className="stratus--product-results-desktop">
      <Stack spacing="tight" align="stretch" column>
        <DataTable
          bulkAction={showBulkCheckbox ? bulkAction : undefined}
          selectedRowsId={showBulkCheckbox ? selectedRowsId : undefined}
          onSelectRow={showBulkCheckbox ? handleOnSelectRow : undefined}
        >
          <ResultHeaderDesktop hasVariants={hasVariants} />
          {isLoading && <ResultRowDesktopSkeleton />}
          {!isLoading &&
            productsIds.map((id) => (
              <ResultRowDesktop key={id} id={id} hasVariations={hasVariants} />
            ))}
        </DataTable>
        {!isLoading && (
          <PaginationStratus
            currentPage={filters.page}
            totalItems={productsCount}
            itemName={t('products.item')}
            page={filters.page}
            perPage={PRODUCTS_PER_PAGE}
            onPageSelect={handleSelectPage}
            urlToBuild={urlToBuild}
          />
        )}
      </Stack>
    </div>
  );
}

export default ProductResultsDesktop;
