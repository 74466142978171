import { Table } from '@nimbus-ds/components';
import { CustomHeaderCell } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

function UsersListDesktopHeader() {
  const t = useTranslationConfigurations('usersListPage.table');

  return (
    <Table.Head>
      <CustomHeaderCell width="144px" color="neutral-textHigh">
        {t('name')}
      </CustomHeaderCell>
      <CustomHeaderCell width="144px" color="neutral-textHigh">
        {t('email')}
      </CustomHeaderCell>
      <CustomHeaderCell width="240px" color="neutral-textHigh">
        {t('permissions')}
      </CustomHeaderCell>
      <CustomHeaderCell width="188px" color="neutral-textHigh">
        {t('notifications')}
      </CustomHeaderCell>
      <CustomHeaderCell width="188px" color="neutral-textHigh">
        {t('2fa')}
      </CustomHeaderCell>
      <CustomHeaderCell width="88px" color="neutral-textHigh">
        {t('actions')}
      </CustomHeaderCell>
    </Table.Head>
  );
}

export default UsersListDesktopHeader;
