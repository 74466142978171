import {
  CheckoutPaymentsResponseDto,
  CheckoutSavePaymentOrdersDto,
} from '@tiendanube/common';
import axios from 'App/axios';

const getCheckoutPaymentOptions =
  async (): Promise<CheckoutPaymentsResponseDto> => {
    const { data } = await axios.get<CheckoutPaymentsResponseDto>(
      'v1/checkout/payment-options',
    );

    return data;
  };

const saveCheckoutPaymentOptions = async (
  payload: CheckoutSavePaymentOrdersDto,
) => {
  const { data } = await axios.post<CheckoutPaymentsResponseDto>(
    'v1/checkout/payment-options',
    payload,
  );

  return data;
};

export default { getCheckoutPaymentOptions, saveCheckoutPaymentOptions };
