import { CombinedState } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { InterfaceDeliveryMethodsSchema } from '../types';

export const getDeliveryMethodsState = (
  state: RootStateType,
): CombinedState<InterfaceDeliveryMethodsSchema> =>
  state.shipping.deliveryMethods;

const getNewCustomShipping = (state: RootStateType) =>
  getDeliveryMethodsState(state).customShipping;

const getRequestOcaRegistration = (state: RootStateType) =>
  getDeliveryMethodsState(state).requestOcaRegistration;

const getActiveShippingMethods = (state: RootStateType) =>
  getDeliveryMethodsState(state).activeShippingMethods;

const getPickupPoints = (state: RootStateType) =>
  getDeliveryMethodsState(state).pickupPoints;

export const getTab = (state: RootStateType) =>
  getDeliveryMethodsState(state).tab;

export const getActiveShippingMethodsDataSelector = (state: RootStateType) =>
  getActiveShippingMethods(state).list.data;

export const getActiveShippingMethodsStatusSelector = (
  state: RootStateType,
) => {
  const status = getActiveShippingMethods(state).list.status;
  return {
    isLoading: status === 'loading',
    isError: status === 'error',
  };
};

export const deleteActiveShippingMethodsStatus = (state: RootStateType) =>
  getActiveShippingMethods(state).delete.status;

export const enableCarrierStatus = (state: RootStateType) => {
  const status = getActiveShippingMethods(state).list.status;

  return {
    isLoading: status === 'loading',
    isSuccess: status === 'success',
  };
};

const getAvailableApps = (state: RootStateType) =>
  getDeliveryMethodsState(state).availableApps;

export const getAvailableAppsData = (state: RootStateType) =>
  getAvailableApps(state).list.data;

export const getAcvailableAppsStatus = (state: RootStateType) => {
  const status = getAvailableApps(state).list.status;
  return {
    isLoading: status === 'loading',
    isError: status === 'error',
  };
};

const createLinkAppScopes = (state: RootStateType) =>
  getDeliveryMethodsState(state).availableApps;

export const createLinkAppScopesStatus = (state: RootStateType) =>
  createLinkAppScopes(state).create.status;

export const getHasNativeShippingMethod = (state: RootStateType) =>
  getActiveShippingMethodsDataSelector(state)?.some(
    (shippingMethod) => shippingMethod.isNative,
  ) || false;

const getFallback = (state: RootStateType) =>
  getDeliveryMethodsState(state).fallback;

export const getFallbackSelector = (state: RootStateType) =>
  getFallback(state).data;

export const getFallbackStatus = (state: RootStateType) => ({
  getStatus: getFallback(state).status,
  updateStatus: getFallback(state).updateStatus,
});

export const getNewCustomShippingStatus = (state: RootStateType) =>
  getNewCustomShipping(state).create.status;

export const getRequestOcaRegistrationStatus = (state: RootStateType) => {
  const status = getRequestOcaRegistration(state).status;
  return {
    isLoading: status === 'loading',
    isError: status === 'error',
    isSuccess: status === 'success',
  };
};

export const getPhysicalLocationsState = (state: RootStateType) =>
  getDeliveryMethodsState(state).physicalLocations;

export const getPhysicalLocations = (state: RootStateType) =>
  getPhysicalLocationsState(state).data;

export const getPhysicalLocationsStatus = (state: RootStateType) =>
  getPhysicalLocationsState(state).getStatus;

export const updatePhysicalLocationsStatus = (state: RootStateType) =>
  getPhysicalLocationsState(state).updateStatus;

export const deletePhysicalLocationsStatus = (state: RootStateType) =>
  getPhysicalLocationsState(state).deleteStatus;

export const createPhysicalLocationsStatus = (state: RootStateType) =>
  getPhysicalLocationsState(state).createStatus;
export const getCustomShipping = (state: RootStateType) =>
  getDeliveryMethodsState(state).customShipping;

export const getCustomShippingData = (state: RootStateType) =>
  getCustomShipping(state).list.data;

export const getCustomShippingStatus = (state: RootStateType) => {
  const status = getCustomShipping(state).list.status;
  return {
    isLoading: status === 'loading',
    isError: status === 'error',
  };
};

export const deleteCustomShipping = (state: RootStateType) =>
  getDeliveryMethodsState(state).customShipping;

export const deleteCustomShippingStatus = (state: RootStateType) =>
  deleteCustomShipping(state).delete.status;

export const getDetailCustomShipping = (state: RootStateType) =>
  getDeliveryMethodsState(state).customShipping;

export const getDetailCustomShippingData = (state: RootStateType) =>
  getDetailCustomShipping(state).detail.data;

export const getDetailCustomShippingStatus = (state: RootStateType) => {
  const status = getDetailCustomShipping(state).detail.status;
  return convertStatusTypeToObject(status);
};

export const updateDetailCustomShippingStatus = (state: RootStateType) =>
  getDetailCustomShipping(state).update.status;

export const getDetailActiveApp = (state: RootStateType) =>
  getDeliveryMethodsState(state).activeShippingMethods;

export const getDetailActiveAppData = (state: RootStateType) =>
  getDetailActiveApp(state).detail.data;

export const getDetailActiveAppStatus = (state: RootStateType) => {
  const status = getDetailActiveApp(state).detail.status;
  return convertStatusTypeToObject(status);
};

export const updateDetailActiveStatus = (state: RootStateType) =>
  getDetailActiveApp(state).update.status;

export const getShippingMethodsData = (state: RootStateType) =>
  getDeliveryMethodsState(state).shippingMethods.data;

export const getShippingMethodsStatus = (state: RootStateType) => {
  const status = getDeliveryMethodsState(state).shippingMethods.status;
  return convertStatusTypeToObject(status);
};
export const getNuvemEnvioConfigs = (state: RootStateType) =>
  getDeliveryMethodsState(state).nuvemEnvioConfigs;

export const getNuvemEnvioConfigsData = (state: RootStateType) =>
  getDeliveryMethodsState(state).nuvemEnvioConfigs.data;

export const getNuvemEnvioConfigsStatus = (state: RootStateType) => {
  const status = getDeliveryMethodsState(state).nuvemEnvioConfigs.status;
  return convertStatusTypeToObject(status);
};

export const updateNuvemEnvioConfigsStatus = (state: RootStateType) =>
  getNuvemEnvioConfigs(state).status;

export const getAddressingConfigurationRulesStatus = (state: RootStateType) =>
  getDeliveryMethodsState(state).addressingConfiguration.getStatus;

export const getAddressingConfigurationRulesSaveStatus = (
  state: RootStateType,
) => getDeliveryMethodsState(state).addressingConfiguration.saveStatus;

export const getAddressingConfigurationRules = (state: RootStateType) =>
  getDeliveryMethodsState(state).addressingConfiguration.data;

export const getPickupPointsStatus = (state: RootStateType) => {
  const status = getPickupPoints(state).list.status;
  return {
    isLoading: status === 'loading',
    isError: status === 'error',
    isSuccess: status === 'success',
  };
};

export const getLocationsAsPickupPointsList = (state: RootStateType) =>
  getPickupPoints(state).list.data.filter((item) => item.isLocation);

export const getOtherPickupPointsList = (state: RootStateType) =>
  getPickupPoints(state).list.data.filter((item) => !item.isLocation);

export const getPickupPointStatus = (state: RootStateType) => {
  const status = getPickupPoints(state).detail.status;
  return {
    isLoading: status === 'loading',
    isError: status === 'error',
    isSuccess: status === 'success',
  };
};

export const getPickupPointDetails = (state: RootStateType) =>
  getPickupPoints(state).detail.data;
