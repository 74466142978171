import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootStateType, useAppDispatch } from 'App/store';
import { getInventoryProductById } from 'domains/Catalog/Inventory/inventorySlice';
import {
  createGetProductById,
  getInventoryStatus,
} from 'domains/Catalog/Inventory/inventorySlice/inventorySelectors/inventorySelectors';
import { ProductFormState } from './types';

interface UseProductInventoryResult {
  isLoading: boolean;
  isError: boolean;
  product: ProductFormState;
  fetchProductInventory: () => void;
}

function useProductInventory(id: string): UseProductInventoryResult {
  const dispatch = useAppDispatch();

  const getProductById = useMemo(createGetProductById, []);
  const product = useSelector((state: RootStateType) =>
    getProductById(state, id),
  );

  const { isLoading, isError } = useSelector(getInventoryStatus);

  const fetchProductInventory = useCallback(() => {
    dispatch(getInventoryProductById({ id, version: '3' }));
  }, [id, dispatch]);

  return {
    isLoading,
    isError,
    product: product as unknown as ProductFormState,
    fetchProductInventory,
  };
}

export default useProductInventory;
