enum Log {
  PAID = "paid",
  VOIDED = "voided",
  REFUNDED = "refunded",
  UNFULFILLED = "unfulfilled",
  FULFILLED = "fulfilled",
  CLOSED = "closed",
  CANCELLED = "cancelled",
  OPEN = "open",
  READ = "read",
  CREATED = "order-created",
  NOTE_CREATED = "created",
  UPDATED = "updated",
  DISPATCHED = "dispatched",
  MARKED_AS_FULFILLED = "marked_as_fulfilled",
  PACKED = "packed",
  READY_FOR_PICKUP = "ready_for_pickup",
  UNPACKED = "unpacked",
  DELIVERED = "delivered",
  REASON = "reason",
  ADD = "add",
  REMOVE = "remove",
  REMOVE_STOCK = "remove-stock",
  SHIPPING_COST = "shipping-cost",
  TRANSACTION_INFO = "transaction-info",
  CF_ASSOCIATED = "custom_field_associated",
  NOTIFICATION = "notification",
  SHIPPING_ADDRESS_EDIT = "edited",
  AUTHORIZED = "authorized",
}

export enum LogType {
  TRANSACTION = "transaction-event",
  FULFILLMENT = "order-fulfillment",
  FULFILLMENT_TRACKING_INFO_ADD = "order-fulfillment-tracking-info-add",
  FULFILLMENT_TRACKING_INFO_EDIT = "order-fulfillment-tracking-info-edit",
  EDIT = "order-edit",
  EDIT_SUB = "order-edit-sub",
  METAFIELDS = "order-custom-field-association",
  ORDER_PAYMENT = "order-payment",
}

export default Log;
