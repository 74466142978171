import {
  OrderResponseDto,
  OrderFulfillBulkRequestDto,
  OrderCancelRequestDto,
  OrderChangeStatusRequestDto,
  OrderFulfillmentsDto,
} from '@tiendanube/common';
import { Status, Action } from '@tiendanube/common/src/enums';
import { ORDERS_PER_PAGE } from '../pages/constants';

export enum FilterStatusEnum {
  ALL = 'all',
  EMPTY = '',
}

export type StatusType =
  | FilterStatusEnum.EMPTY
  | FilterStatusEnum.ALL
  | Status.OPEN
  | Status.CLOSED
  | Status.CANCELLED
  | Status.ARCHIVED;

export type FulfillmentStatusType =
  | FilterStatusEnum
  | Status.UNPACKED
  | Status.UNFULFILLED
  | Status.PARTIALLY_PACKED
  | Status.PARTIALLY_FULFILLED
  | Status.FULFILLED
  | Status.DELIVERED
  | Status.PICKUP_READY;

export type PaymentStatusType =
  | FilterStatusEnum.EMPTY
  | FilterStatusEnum.ALL
  | Status.PAID
  | Status.PENDING
  | Status.VOIDED
  | Status.REFUNDED
  | Status.PARTIALLY_PAID;

export type PaymentProviderType =
  | FilterStatusEnum.EMPTY
  | FilterStatusEnum.ALL
  | 'custom';

export type OriginType =
  | FilterStatusEnum.EMPTY
  | FilterStatusEnum.ALL
  | 'store'
  | 'form'
  | 'pos'
  | 'api';

export type FiltersType = {
  page: number;
  perPage?: string;
  q?: string;
  status: StatusType;
  dateFrom: string;
  dateTo: string;
  paymentStatus: PaymentStatusType;
  fulfillmentStatus: FulfillmentStatusType;
  paymentMethods: string;
  shippingMethod: string;
  paymentProvider: string;
  location: string;
  origin: string;
  appId: string;
  products: string; // serialized as "variantId1;variantId2;..."
  productsCount: string;
};

export type FiltersParamsType = Omit<FiltersType, 'page'>;
export type ExportFiltersType = Omit<FiltersType, 'page' | 'location'>;

export const defaultFilters: FiltersType = {
  page: 1,
  perPage: ORDERS_PER_PAGE.toString(),
  q: '',
  dateFrom: '',
  dateTo: '',
  status: FilterStatusEnum.EMPTY,
  paymentStatus: FilterStatusEnum.EMPTY,
  fulfillmentStatus: FilterStatusEnum.EMPTY,
  paymentMethods: '',
  paymentProvider: '',
  shippingMethod: '',
  location: '',
  origin: '',
  appId: '',
  products: '',
  productsCount: '',
};

export const defaultFilterParams: FiltersParamsType = {
  perPage: ORDERS_PER_PAGE.toString(),
  q: '',
  dateFrom: '',
  dateTo: '',
  status: FilterStatusEnum.EMPTY,
  paymentStatus: FilterStatusEnum.EMPTY,
  fulfillmentStatus: FilterStatusEnum.EMPTY,
  paymentMethods: '',
  shippingMethod: '',
  paymentProvider: '',
  location: '',
  origin: '',
  appId: '',
  products: '',
  productsCount: '',
};

export const status: StatusType[] = [
  FilterStatusEnum.ALL,
  Status.OPEN,
  Status.CLOSED,
  Status.CANCELLED,
  Status.ARCHIVED,
];

export const fulfillmentStatus: FulfillmentStatusType[] = [
  FilterStatusEnum.ALL,
  Status.UNPACKED,
  Status.PARTIALLY_PACKED,
  Status.UNFULFILLED,
  Status.PARTIALLY_FULFILLED,
  Status.FULFILLED,
  Status.PICKUP_READY,
  Status.DELIVERED,
];

export const paymentStatus: PaymentStatusType[] = [
  FilterStatusEnum.ALL,
  Status.PAID,
  Status.PENDING,
  Status.VOIDED,
  Status.REFUNDED,
];

export const paymentProvider: PaymentProviderType[] = ['custom'];

export const orderOrigin: OriginType[] = [
  FilterStatusEnum.ALL,
  'store',
  'form',
  'pos',
  'api',
];

export type ActionType =
  | OrderFulfillBulkRequestDto[]
  | OrderCancelRequestDto
  | OrderChangeStatusRequestDto
  | OrderFulfillmentsDto[];

export interface InterfaceOrderUpdateParams {
  actionType: Action;
  orderIds: string[];
  action?: ActionType;
}

export type UpdateBulkOrdersType = (
  p: InterfaceOrderUpdateParams,
) => Promise<OrderResponseDto[]>;

export const paymentStatusFilter = 'paymentStatus';
export const fulfillmentStatusFilter = 'fulfillmentStatus';
