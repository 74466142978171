import { Box, Tabs } from '@nimbus-ds/components';
import { Title } from '@tiendanube/components';
import { HeaderContent, IonPageStratus, Responsive } from 'commons/components';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import {
  InvoiceHistoryWeb,
  InvoiceHistoryMobile,
  PagoNubeInvoiceHistoryMobile,
  PagoNubeInvoiceHistoryWeb,
} from './components';
import TaxCondition from './components/TaxCondition';

function InvoiceHistory(): JSX.Element {
  const t = useTranslationBilling();
  const { validateRoles } = useRoles();

  return (
    <IonPageStratus
      headerContent={<HeaderContent title={t('invoiceHistory.title')} />}
    >
      <Box display="flex" flexDirection="column" gap="8">
        <TaxCondition />
        <Box display="flex" flexDirection="column" gap="4">
          <Title type="h3">{`${t('invoiceHistory.subTitle')}`}</Title>
          {validateRoles('nuvem_pago') ? (
            <Tabs>
              <Tabs.Item label={t('invoiceHistory.tiendanube')}>
                <Responsive
                  mobile={<InvoiceHistoryMobile />}
                  desktop={<InvoiceHistoryWeb />}
                />
              </Tabs.Item>
              <Tabs.Item label={t('invoiceHistory.pagonube')}>
                <Responsive
                  mobile={<PagoNubeInvoiceHistoryMobile />}
                  desktop={<PagoNubeInvoiceHistoryWeb />}
                />
              </Tabs.Item>
            </Tabs>
          ) : (
            <Responsive
              mobile={<InvoiceHistoryMobile />}
              desktop={<InvoiceHistoryWeb />}
            />
          )}
        </Box>
      </Box>
    </IonPageStratus>
  );
}

export default InvoiceHistory;
