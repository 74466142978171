import { Tag } from '@nimbus-ds/components';
import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { ChatDotsIcon } from '@tiendanube/icons';
import ShowByRole from 'App/components/ShowByRole';
import { NUVEM_CHAT_MENU_ENABLED } from 'App/featuresFlags';
import { useHandleMenuClick } from 'App/hooks';
import { trackingMenuClick } from 'App/tracking';
import { useResponsive } from 'commons/components';
import { NUVEMCHAT_MENU_CLICK } from 'config/trackingEvents';
import { useGetTags } from 'domains/Auth/hooks';
import { useNuvemApps } from 'domains/PartnersApps/pages/NuvemAppPage/hooks';

function NuvemChatMenu() {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem } = useHandleMenuClick();
  const { nuvemApps, isEmpty } = useNuvemApps();
  const { isMobile } = useResponsive();
  const tags = useGetTags();
  const hasNuvemChatEnabled = tags.includes(NUVEM_CHAT_MENU_ENABLED);
  const handleNuvemChatClick = () => {
    trackingMenuClick({
      tag: NUVEMCHAT_MENU_CLICK,
      options: {
        is_mobile_device: isMobile.toString(),
        is_nuvem_chat_installed: nuvemApps?.nuvemchat ? 'true' : 'false',
      },
    });
    handleClickItem();
  };
  return (
    <ShowByRole includeRoles={['nuvem_chat']}>
      {!isEmpty && (hasNuvemChatEnabled || nuvemApps.nuvemchat) && (
        <MenuButton
          as={Link}
          to="/chat"
          startIcon={ChatDotsIcon}
          onClick={handleNuvemChatClick}
          active={pathname.startsWith(`/chat`)}
          label={t('menu.manage.nuvemchat.title')}
        >
          <Tag appearance="primary">{t('menu.new')}</Tag>
        </MenuButton>
      )}
    </ShowByRole>
  );
}

export default NuvemChatMenu;
