import { ReactNode } from 'react';
import { Box, Text } from '@nimbus-ds/components';
import { InterfaceButton, InterfaceLabel, Stack } from '@tiendanube/components';
import { useResponsive } from 'commons/components/Responsive';
import { useDocumentTitle } from 'commons/hooks';
import { Labels, Pagination, Subtitle, Title } from './components';

export interface HeaderProps {
  title?: string;
  documentTitle?: string;
  titleDetail?: string;
  subtitle?: string;
  labels?: (InterfaceLabel | 'skeleton')[];
  menuMobile?: ReactNode;
  actions?: ReactNode;
  applications?: ReactNode;
  headerNavigation?: Pick<InterfaceButton, 'children' | 'onClick'>;

  paginationPrevious?: () => void;
  paginationNext?: () => void;
}

function HeaderContent({
  title,
  documentTitle = '',
  titleDetail,
  subtitle,
  labels,
  actions,
  applications,
  paginationPrevious,
  paginationNext,
}: HeaderProps): JSX.Element {
  const { isDesktop } = useResponsive();
  useDocumentTitle(documentTitle || title);

  return (
    <>
      <Stack spacing="tight">
        <Stack.Item fill>
          <Box display="flex" flexDirection="row" alignItems="baseline">
            {!!title && (
              <Box paddingRight="2">
                <Title text={title} />
              </Box>
            )}
            {!!titleDetail && <Text fontSize="base">{titleDetail}</Text>}
          </Box>
        </Stack.Item>
        {(paginationPrevious || paginationNext) && (
          <div className="header-content-item">
            <Stack.Item>
              <Pagination
                paginationPrevious={paginationPrevious}
                paginationNext={paginationNext}
              />
            </Stack.Item>
          </div>
        )}
        {!!applications && isDesktop && (
          <div className="header-content-item">
            <Stack.Item>{applications}</Stack.Item>
          </div>
        )}
        {!!actions && isDesktop && (
          <div className="header-content-item">
            <Stack.Item>{actions}</Stack.Item>
          </div>
        )}
      </Stack>
      {!!subtitle && <Subtitle text={subtitle} />}
      {labels && <Labels>{labels}</Labels>}
    </>
  );
}

export default HeaderContent;
