import { ReactNode } from 'react';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { useNavegate } from 'App/hooks';
import {
  ActionsDesktop,
  ErrorScreen,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { NewPromoAlert } from 'domains/Marketing/Promotions/components/NewPromoAlert';
import { FreeShippingHelpLink } from './FreeShippingHelpLink';
import { FreeShippingListEmpty } from './FreeShippingListEmpty';
import {
  PromotionType,
  PromotionTypes,
} from '../../Promotions/pages/constants';
import { buildNewPromotionUrl } from '../../Promotions/utils';
import { FREE_SHIPPING_ROUTES } from '../freeShippingRoutes';
import {
  freeShippingCloseAdoptionAlert,
  freeShippingOpenAdoptionAlert,
  freeShippingShowAdoptionAlert,
} from '../tracking';

interface FreeShippingPageProps {
  skeleton: ReactNode;
  isLoading: boolean;
  isEmpty: boolean;
  isError: boolean;
  children: ReactNode;
  onRetry: () => void;
}

export function FreeShippingListPage({
  skeleton,
  isLoading,
  isEmpty,
  isError,
  children,
  onRetry,
}: Readonly<FreeShippingPageProps>) {
  const t = useTranslationMarketing('freeShipping');
  const { goTo } = useNavegate();
  const title = isEmpty ? t('titleEmpty') : t('title');
  const gotoCreate = () => goTo(FREE_SHIPPING_ROUTES.newFreeShipping);
  const gotoCreatePromotion = (promotionType: PromotionType) => {
    freeShippingOpenAdoptionAlert(promotionType);
    goTo(buildNewPromotionUrl(promotionType));
  };

  return (
    <IonPageStratus
      width="medium"
      headerTop={
        <HeaderTop
          mainAction={
            !isEmpty
              ? {
                  onClick: gotoCreate,
                  icon: PlusCircleIcon,
                }
              : undefined
          }
        />
      }
      headerContent={
        <HeaderContent
          title={title}
          actions={
            !isEmpty && (
              <ActionsDesktop
                actions={[
                  {
                    icon: PlusCircleIcon,
                    children: t('addFreeShipping'),
                    appearance: 'primary',
                    onClick: gotoCreate,
                  },
                ]}
              />
            )
          }
        />
      }
    >
      {isLoading && skeleton}
      {isError && <ErrorScreen onRetry={onRetry} />}
      {isEmpty && <FreeShippingListEmpty onCreate={gotoCreate} />}
      {!isLoading && !isError && !isEmpty && (
        <>
          <NewPromoAlert
            type={PromotionTypes.CROSS_SELLING}
            onClick={gotoCreatePromotion}
            onRemove={freeShippingCloseAdoptionAlert}
            onShow={freeShippingShowAdoptionAlert}
          />
          {children}
        </>
      )}
      {!isError && <FreeShippingHelpLink />}
    </IonPageStratus>
  );
}
