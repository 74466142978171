import { useCallback } from 'react';
import { IconButton, Tooltip } from '@nimbus-ds/components';
import {
  EnableCountryRequestDto,
  EnabledCountriesResponseDto,
} from '@tiendanube/common';
import { EditIcon, TrashIcon } from '@tiendanube/icons';
import { toStatusType } from 'App/rtk';
import { Stack } from 'commons/components';
import { useModal, useToastStatus } from 'commons/hooks';
import useRefreshInfoStoreUser from 'domains/Auth/hooks/useRefreshInfoToSync';
import {
  useDeleteEnabledCountryMutation,
  useUpdateEnabledCountryMutation,
} from 'domains/Configurations/I18n/i18nApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import DeleteEnabledCountryModal from '../DeleteEnabledCountryModal';
import UpdateEnabledCountryModal from '../UpdateEnabledCountryModal';

interface ActionButtonsProps {
  enabledCountry: EnabledCountriesResponseDto;
}
function ActionButtons({ enabledCountry }: ActionButtonsProps) {
  const t = useTranslationConfigurations('languagesAndCurrencies');
  const { refreshInfo } = useRefreshInfoStoreUser();

  const [updateCountry, { status: statusUpdate, isLoading: loadingUpdate }] =
    useUpdateEnabledCountryMutation();

  const [
    deleteEnabledCountry,
    { status: statusDelete, isLoading: loadingDelete },
  ] = useDeleteEnabledCountryMutation();

  const [
    showDeleteCountryModal,
    openDeleteCountryModal,
    closeDeleteCountryModal,
  ] = useModal();

  const [
    showUpdateCountryModal,
    openUpdateCountryModal,
    closeUpdateCountryModal,
  ] = useModal();

  const handleSuccessDelete = useCallback(() => {
    refreshInfo(true);
    closeDeleteCountryModal();
  }, [refreshInfo, closeDeleteCountryModal]);

  const handleSuccessUpdate = useCallback(() => {
    refreshInfo(true);
    closeUpdateCountryModal();
  }, [refreshInfo, closeUpdateCountryModal]);

  const handleDelete = async () => {
    deleteEnabledCountry(enabledCountry.id);
  };

  useToastStatus({
    status: toStatusType(statusDelete),
    error: t('toastErrorDelete'),
    success: t('toastSuccessDelete'),
    onSuccess: handleSuccessDelete,
  });

  useToastStatus({
    status: toStatusType(statusUpdate),
    error: t('toastErrorUpate'),
    success: t('toastSuccessUpdate'),
    onSuccess: handleSuccessUpdate,
  });

  const handleUpdate = async (data: EnableCountryRequestDto) => {
    updateCountry({ id: enabledCountry.id, body: data });
  };

  return (
    <>
      <Stack gap="3">
        <Tooltip content={t('actions.edit')}>
          <IconButton
            source={<EditIcon size="small" />}
            size="2rem"
            onClick={openUpdateCountryModal}
          />
        </Tooltip>

        {!enabledCountry.isMain && (
          <Tooltip content={t('actions.delete')}>
            <IconButton
              source={<TrashIcon size="small" />}
              size="2rem"
              onClick={openDeleteCountryModal}
            />
          </Tooltip>
        )}
      </Stack>
      {showDeleteCountryModal && (
        <DeleteEnabledCountryModal
          onDelete={handleDelete}
          onClose={closeDeleteCountryModal}
          isLoading={loadingDelete}
        />
      )}
      {showUpdateCountryModal && (
        <UpdateEnabledCountryModal
          onUpdate={handleUpdate}
          onClose={closeUpdateCountryModal}
          enabledCountry={enabledCountry}
          isEnabledCountry={enabledCountry.isMain}
          isLoading={loadingUpdate}
        />
      )}
    </>
  );
}

export default ActionButtons;
