import React from 'react';
import { OrderFulfillRequestDto } from '@tiendanube/common';
import {
  Status as StatusEnum,
  FulfillmentPreferenceType as FulfillmentPreferenceTypeEnum,
} from '@tiendanube/common/src/enums';
import { InterfaceButton } from '@tiendanube/components';
import { useHasPermission } from 'domains/Auth/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

export const notifyFulfillmentDefault = {
  shippingTrackingNum: '',
  sendNotification: true,
};

interface UseFulfillmentActionButtonProps {
  packOrder: () => void;
  fulfillOrder: (order: OrderFulfillRequestDto) => void;
  isLoading: boolean;
  openModal: () => void;
  closeModal: () => void;
  status: string;
  type: FulfillmentPreferenceTypeEnum;
  orderStatus: StatusEnum;
  isShippablePickup: boolean;
}

function useFulfillmentActionButton({
  fulfillOrder,
  packOrder,
  openModal,
  closeModal,
  status,
  type,
  isLoading,
  orderStatus,
  isShippablePickup,
}: UseFulfillmentActionButtonProps) {
  const t = useTranslationOrders();
  const canSetOrderPackaged = useHasPermission('set_order_packaged');

  const isArchived = orderStatus === StatusEnum.ARCHIVED;
  const isCancelled = orderStatus === StatusEnum.CANCELLED;

  const secondaryButtonProps = React.useMemo<
    InterfaceButton | undefined
  >(() => {
    const action = {
      [StatusEnum.UNPACKED]: {
        [FulfillmentPreferenceTypeEnum.DELIVERY]: packOrder,
        [FulfillmentPreferenceTypeEnum.DELIVERY_DIGITAL]: openModal,
        [FulfillmentPreferenceTypeEnum.NON_SHIPPABLE]: openModal,
        [FulfillmentPreferenceTypeEnum.PICKUP]: packOrder,
      },
      [StatusEnum.UNSHIPPED]: {
        [FulfillmentPreferenceTypeEnum.DELIVERY]: openModal,
        [FulfillmentPreferenceTypeEnum.DELIVERY_DIGITAL]: openModal,
        [FulfillmentPreferenceTypeEnum.NON_SHIPPABLE]: openModal,
        [FulfillmentPreferenceTypeEnum.PICKUP]: () =>
          fulfillOrder({ fulfillments: [] }),
      },
      [StatusEnum.PACKED]: {
        [FulfillmentPreferenceTypeEnum.DELIVERY]: openModal,
      },
      [StatusEnum.READY_FOR_PICKUP]: {
        [FulfillmentPreferenceTypeEnum.PICKUP]: () =>
          fulfillOrder({ fulfillments: [] }),
      },
    };

    if (!action[status] || !action[status][type]) return undefined;

    if (status === StatusEnum.UNPACKED && !canSetOrderPackaged)
      return undefined;

    return {
      children: `${t(
        `cardFulfillment.action.${status}-${
          status === 'unshipped' && isShippablePickup ? 'delivery' : type
        }`,
      )}`,
      onClick: action[status][type],
      spinner: isLoading,
      disabled: isLoading,
    };
  }, [
    packOrder,
    openModal,
    status,
    type,
    t,
    isLoading,
    fulfillOrder,
    isShippablePickup,
    canSetOrderPackaged,
  ]);

  const secondaryButton =
    isArchived || isCancelled ? undefined : secondaryButtonProps;

  const handleClickFulfill = (notifyFulfillment) => {
    fulfillOrder(notifyFulfillment);
    if (
      type === FulfillmentPreferenceTypeEnum.DELIVERY ||
      type === FulfillmentPreferenceTypeEnum.NON_SHIPPABLE ||
      type === FulfillmentPreferenceTypeEnum.DELIVERY_DIGITAL
    )
      closeModal();
  };

  return { secondaryButton, handleClickFulfill };
}

export default useFulfillmentActionButton;
