import { useCallback, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { CheckoutPaymentsResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { useAsyncFunc } from 'commons/hooks';
import { fetchCheckoutPaymentOptionsAction } from '../../checkoutPaymentsSlice';

interface UseFetchCheckoutPaymentOptionsResult {
  isLoading: boolean;
  isError: boolean;
  getCheckoutOptions: () => void;
  data: CheckoutPaymentsResponseDto | undefined;
}

function useFetchCheckoutPaymentOptions(): UseFetchCheckoutPaymentOptionsResult {
  const [data, setData] = useState<CheckoutPaymentsResponseDto>();
  const dispatch = useAppDispatch();

  const fetchCheckoutPaymentOptions = useCallback(async () => {
    const featureUses = await dispatch(fetchCheckoutPaymentOptionsAction());
    return unwrapResult(featureUses);
  }, [dispatch]);

  const [getCheckoutOptions, isLoading, isError] = useAsyncFunc(async () => {
    const data = await fetchCheckoutPaymentOptions();
    setData(data);
  });

  return {
    data,
    isLoading,
    isError,
    getCheckoutOptions,
  };
}

export default useFetchCheckoutPaymentOptions;
