import { Modal, Text } from '@tiendanube/components';
import { useTranslationCommon } from 'commons/hooks';

interface ExistingModalProps {
  show: boolean;
  onDismiss: () => void;
  onAccept: () => void;
  entitiesName: string;
}

export function ExistingModal({
  show,
  onDismiss,
  onAccept,
  entitiesName,
}: ExistingModalProps) {
  const t = useTranslationCommon();

  return (
    <Modal
      title={t('importCsv.import.existing.titleModal', {
        entities: entitiesName,
      })}
      show={show}
      onDismiss={onDismiss}
      secondaryAction={{
        children: `${t('importCsv.import.existing.cancel')}`,
        onClick: onDismiss,
      }}
      primaryAction={{
        children: `${t('importCsv.import.existing.confirm')}`,
        appearance: 'primary',
        onClick: onAccept,
      }}
    >
      <Text>
        {t('importCsv.import.existing.messageModal', {
          entities: entitiesName,
        })}
      </Text>
    </Modal>
  );
}
