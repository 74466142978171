import { Select } from '@nimbus-ds/components';
import { ItemProps } from 'domains/Metafields/components/MetafieldInput/types';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';
import useMergeMetafieldsEvents from 'domains/Metafields/hooks/useMergeMetafieldsEvents';

import './SelectItem.scss';

function SelectItem({
  metafield,
  metafieldKey,
  disabled,
  value,
  onChange,
  onUpdate,
  placeholder,
}: ItemProps) {
  const t = useTranslationsMetafields();
  const optionSelect = placeholder || t('select.one');

  const addOptionSelect = metafield
    ? [optionSelect, ...(metafield.values || [])]
    : [];

  const options = addOptionSelect.map((item, index) => (
    <Select.Option key={index} label={item} value={item} />
  ));

  const handleChange = ({ target: { value } }) => {
    const selectValue = value === optionSelect ? '' : value;
    onChange?.(selectValue);
    onUpdate?.(selectValue || null);
  };

  const metafieldSelected = value === '' ? optionSelect : value;

  const inputEvents = {
    onChange: handleChange,
  };

  const mergeEvents = useMergeMetafieldsEvents(
    inputEvents,
    metafield.formFieldConfig?.events,
  );
  const mergedEvents = mergeEvents();

  return (
    <div className="select-item-wrapper">
      <Select
        value={metafieldSelected}
        id={metafieldKey}
        name={metafield.name}
        disabled={disabled !== undefined ? disabled : metafield.disabled}
        {...mergedEvents}
      >
        {options}
      </Select>
    </div>
  );
}

export default SelectItem;
