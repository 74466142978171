import { DataTable } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

const { Cell, Header } = DataTable;

function DataTableHeader(): JSX.Element {
  const t = useTranslationBilling();
  return (
    <Header>
      <Cell grow={1}>
        <Text>{t('invoiceHistory.month')}</Text>
      </Cell>
      <Cell grow={2}>
        <Text>{t('invoiceHistory.period')}</Text>
      </Cell>
      <Cell grow={4}>
        <Text>{t('invoiceHistory.bill')}</Text>
      </Cell>
      <Cell grow={1}>
        <Text>{t('invoiceHistory.amount')}</Text>
      </Cell>
      <Cell grow={1}>
        <Text>{t('invoiceHistory.download')}</Text>
      </Cell>
    </Header>
  );
}

export default DataTableHeader;
