import { useCallback, useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { CustomersDetailsResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { useAsyncFunc } from 'commons/hooks';
import { StatusObjectType } from 'commons/types';
import {
  addCustomer as addCustomerAction,
  getCustomerFormValues,
  updateCustomer as updateCustomerAction,
} from 'domains/Customers/Customers/customersSlice';
import { useCustomerMetafields } from 'domains/Customers/hooks';
import { useCustomerMetafieldsAccess } from 'domains/Metafields/hooks';
import { useCustomerResults } from '../../CustomerListPage/hooks';
import { initialValues } from '../constants';
import { CustomerFormValuesInterface } from '../types';

type submitCustomerType = (
  customerValues: CustomerFormValuesInterface,
) => Promise<CustomersDetailsResponseDto>;

interface UseCustomerFormResult {
  customer: CustomerFormValuesInterface;
  isLoading: boolean;
  isError: boolean;
  getCustomerValues: () => void;
  addCustomer: submitCustomerType;
  updateCustomer: submitCustomerType;
  customerMetafieldsStatus: StatusObjectType;
}

function useCustomerForm(customerId?: string): UseCustomerFormResult {
  const dispatch = useAppDispatch();
  const { refreshCustomers } = useCustomerResults();
  const useHasCustomerMetafields = useCustomerMetafieldsAccess();
  const {
    customerMetafields,
    fetchCustomerMetafields,
    status: customerMetafieldsStatus,
  } = useCustomerMetafields(customerId);

  const [customer, setCustomer] =
    useState<CustomerFormValuesInterface>(initialValues);

  const [getCustomerValues, isLoading, isError, clearErrors] = useAsyncFunc<
    CustomerFormValuesInterface,
    Promise<void>
  >(async () => {
    await fetchCustomerValues();
    clearErrors();
  });

  const fetchCustomerValues = useCallback(async () => {
    if (customerId) {
      const customerFormValues = await dispatch(
        getCustomerFormValues(customerId),
      );
      setCustomer((customer) => ({
        ...customer,
        ...unwrapResult(customerFormValues),
        id: customerId,
      }));
    }
  }, [customerId, dispatch]);

  const addCustomer: submitCustomerType = useCallback(
    async (customerValues) => {
      const result = await dispatch(addCustomerAction(customerValues));
      refreshCustomers();
      return unwrapResult(result);
    },
    [dispatch, refreshCustomers],
  );

  const updateCustomer: submitCustomerType = useCallback(
    async (customerValues) => {
      const result = await dispatch(updateCustomerAction(customerValues));
      refreshCustomers();
      return unwrapResult(result);
    },
    [dispatch, refreshCustomers],
  );

  useEffect(() => {
    if (customerId) getCustomerValues();
    if (customerId && useHasCustomerMetafields) fetchCustomerMetafields();

    return () => {
      // Clear metafields values
      setCustomer(initialValues);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  useEffect(() => {
    if (customerMetafieldsStatus.isSuccess && customerId) {
      setCustomer((customer) => ({
        ...customer,
        metafields: customerMetafields,
      }));
    }
  }, [customerMetafields, customerId, customerMetafieldsStatus.isSuccess]);

  return {
    customer,
    isLoading,
    isError,
    getCustomerValues,
    addCustomer,
    updateCustomer,
    customerMetafieldsStatus,
  };
}

export default useCustomerForm;
