import { AxiosError } from 'axios';
import {
  OrderDetailsResponseDto,
  OrderResponseDto,
  OrderCancelRequestDto,
  OrderMultiActionRequestDto,
  OrderChangeStatusRequestDto,
  OrderFulfillRequestDto,
  OrdersListResponseDto,
  EditDeliveryAddressRequestDto,
  OrderTotalResponseDto,
  OrdersExportResponseDto,
  HasFundsAvailableNuvemPagoResponseDto,
  PendingOrdersResponseDto,
  LogEntryResponseDto,
  OrderFulfillmentUpdateRequestDto,
  OrderProductsResponseDto,
  OrdersFiltersByTypeResponseDto,
  EditOrderRequestDto,
  OrderShippingCostRequestDto,
  OrderArchiveRequestDto,
  OrderUpdateResponseDto,
  RefundPaymentRequestDto,
  RefundAttemptStatusResponseDto,
  RefundPaymentResponseDto,
} from '@tiendanube/common';
import { OrdersListAggregationsResponseDto } from '@tiendanube/common/src/domains/orders/orders/OrderResponse';
import { Action, Status } from '@tiendanube/common/src/enums';
import axios from 'App/axios';
import { parseDateFrom, parseDateTo } from 'commons/utils/date';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import { getAuthStorage } from 'domains/Auth/authService/authService';
import {
  FiltersType,
  FilterStatusEnum,
  UpdateBulkOrdersType,
  ExportFiltersType,
  FiltersParamsType,
} from './constants';
import { fetchOrderDigest } from './digest';
import {
  EXPORT_ORDERS,
  FETCH_ORDERS_FILTERS,
  FETCH_TOTAL_ORDERS,
  ORDERS_BOUNCED_EMAILS_URL,
  PENDING_ORDERS_URL,
} from './urls';
import { ORDERS_LIST_TIMEOUT_ERROR } from '../pages/constants';

type FetchOrdersType = (
  filtersParams: FiltersType,
) => Promise<OrdersListResponseDto>;

// TODO: Remove when METAFILEDS ORDERS be full rollouted
const fetchOrders: FetchOrdersType = async (filtersParams) => {
  const paramsToRequest: FiltersType = { ...filtersParams };

  if (paramsToRequest.status === FilterStatusEnum.EMPTY) {
    paramsToRequest.status = Status.OPEN;
  }

  if (paramsToRequest.status === FilterStatusEnum.ALL) {
    paramsToRequest.status = FilterStatusEnum.EMPTY;
  }

  const { data } = await axios.get<OrdersListResponseDto>(
    `/v2/orders/list`,
    paramsToRequest && {
      params: paramsToRequest,
    },
  );
  return data;
};

const fetchOrdersAdvanced: FetchOrdersType = async (filtersParams) => {
  const payload = fetchOrderDigest(filtersParams);
  const timezone = (await getAuthStorage())?.timezone || 'UTC';

  if (!payload.status) {
    payload.status = Status.OPEN;
  }

  if (payload.status === FilterStatusEnum.ALL) {
    delete payload.status;
  }

  if (payload.dateFrom) {
    payload.dateFrom = parseDateFrom(payload.dateFrom, timezone);
  }

  if (payload.dateTo) {
    payload.dateTo = parseDateTo(payload.dateTo, timezone);
  }

  try {
    const { data } = await axios.post<OrdersListAggregationsResponseDto>(
      '/v3/orders/advanced-search',
      payload,
    );
    return data;
  } catch (error) {
    if (
      (error as AxiosError).response?.data.response.errorType ===
      ORDERS_LIST_TIMEOUT_ERROR
    ) {
      throw new Error(ORDERS_LIST_TIMEOUT_ERROR);
    } else {
      throw error;
    }
  }
};

const fetchBulkOrders = async (filters: FiltersParamsType) => {
  const { data } = await axios.get('v2/orders/fetchBulkIds', {
    params: filters,
  });
  const { results } = data;
  return results;
};

const fetchTotalOrders = async (
  filtersParams: ExportFiltersType,
): Promise<number> => {
  const paramsToRequest: ExportFiltersType = {
    ...filtersParams,
    ...(filtersParams.products && {
      variantIds: filtersParams.products.toString().split(';'),
    }),
  };
  const timezone = (await getAuthStorage())?.timezone || 'UTC';

  if (!paramsToRequest.status) {
    paramsToRequest.status = Status.OPEN;
  }
  if (paramsToRequest.status === FilterStatusEnum.ALL) {
    paramsToRequest.status = FilterStatusEnum.EMPTY;
  }

  if (paramsToRequest.dateFrom) {
    paramsToRequest.dateFrom = parseDateFrom(
      paramsToRequest.dateFrom,
      timezone,
    );
  }

  if (paramsToRequest.dateTo) {
    paramsToRequest.dateTo = parseDateTo(paramsToRequest.dateTo, timezone);
  }

  const { data } = await axios.get<OrderTotalResponseDto>(
    FETCH_TOTAL_ORDERS,
    paramsToRequest && {
      params: paramsToRequest,
    },
  );
  return data.total;
};

const exportOrders = async (
  filtersParams: ExportFiltersType,
): Promise<OrdersExportResponseDto> => {
  const paramsToRequest = {
    ...filtersParams,
    ...(filtersParams.products && {
      variantIds: filtersParams.products.split(';'),
    }),
  };

  if (!paramsToRequest.status) {
    paramsToRequest.status = Status.OPEN;
  }
  if (paramsToRequest.status === FilterStatusEnum.ALL) {
    paramsToRequest.status = FilterStatusEnum.EMPTY;
  }

  const { data } = await axios.post<OrdersExportResponseDto>(
    EXPORT_ORDERS,
    paramsToRequest,
  );
  return data;
};

const fetchOrderById = async (id: string): Promise<OrderDetailsResponseDto> => {
  const { data } = await axios.get<OrderDetailsResponseDto>(`/v2/orders/${id}`);
  return data;
};

const fetchOrderProducts = async (
  id: string,
): Promise<OrderProductsResponseDto> => {
  const { data } = await axios.get<OrderProductsResponseDto>(
    `/v2/orders/${id}/products`,
  );
  return data;
};

const updateBulkOrders: UpdateBulkOrdersType = async ({
  actionType,
  orderIds,
  action,
}) => {
  const payload: OrderMultiActionRequestDto = {
    actionType: actionType,
    collectionsIds: orderIds.map((id) => id.toString()),
    action: actionType === Action.PAY ? { status: Status.PAID } : action,
  };
  const response = await axios.patch<OrderResponseDto[]>(
    '/v2/orders/bulk',
    payload,
  );
  return response.data;
};

const fetchCancelOrder = async (id: string): Promise<any> => {
  const response = await axios.patch(`/v2/orders/${id}/cancel`);
  return response.data;
};

const fetchHasAvailableFunds = async (
  amount: number,
  app: 'nuvempago' | 'pagonube',
): Promise<HasFundsAvailableNuvemPagoResponseDto> => {
  const response = await axios.get(`/v2/orders/can-refund`, {
    params: {
      amount: `${amount}`,
      app,
    },
  });
  return response.data;
};

const fetchArchiveOrder = async (
  id: string,
  payload: OrderArchiveRequestDto,
): Promise<OrderDetailsResponseDto> => {
  const response = await axios.patch<OrderDetailsResponseDto>(
    `/v2/orders/${id}/archive`,
    payload,
  );
  return response.data;
};

const fetchOrderPatch = async (
  id: string,
  action: OrderChangeStatusRequestDto,
): Promise<OrderDetailsResponseDto> => {
  const response = await axios.patch<OrderDetailsResponseDto>(
    `/v2/orders/${id}`,
    action,
  );
  return response.data;
};

const cancelById = async (
  id: string,
  action: OrderCancelRequestDto,
): Promise<OrderDetailsResponseDto> => {
  const response = await axios.patch<OrderDetailsResponseDto>(
    `/v2/orders/${id}/cancel`,
    action,
  );
  return response.data;
};

const refundById = async (
  id: string,
  action: RefundPaymentRequestDto,
): Promise<RefundPaymentResponseDto> => {
  try {
    const response = await axios.post<RefundPaymentResponseDto>(
      `/v2/orders/${id}/refund`,
      action,
    );
    return response.data;
  } catch (error) {
    const errorCode = error.response?.data?.response?.error_code;
    if (error?.isAxiosError) {
      throw new Error(errorCode);
    }
    throw error;
  }
};

const fetchRefundAttemptStatus = async (
  orderId: string,
  refundAttemptId: string,
): Promise<RefundAttemptStatusResponseDto> => {
  const response = await axios.get<RefundAttemptStatusResponseDto>(
    `/v2/orders/${orderId}/refund/${refundAttemptId}`,
  );
  return response.data;
};

const openById = async (
  id: string,
  validateStock?: boolean,
  isActionFromOrdersList?: boolean,
): Promise<OrderUpdateResponseDto> => {
  const response = await axios.patch<OrderUpdateResponseDto>(
    `/v2/orders/${id}`,
    {
      status: Status.OPEN,
      validateStock,
      isActionFromOrdersList,
    },
  );
  return response.data;
};

const paidById = async (
  id: string,
  gateway?: string,
  gatewayMethod?: string,
  gatewayName?: string,
  isActionFromOrdersList?: boolean,
): Promise<OrderDetailsResponseDto> => {
  const response = await axios.patch<OrderDetailsResponseDto>(
    `/v2/orders/${id}`,
    {
      status: Status.PAID,
      isActionFromOrdersList,
      ...(gateway || gatewayMethod
        ? {
            gatewayInformation: {
              gateway,
              gatewayMethod,
              gatewayName,
            },
          }
        : {}),
    },
  );
  return response.data;
};

const partiallyPaidById = async (
  id: string,
  method: string,
  name?: string,
): Promise<OrderDetailsResponseDto> => {
  const response = await axios.patch<OrderDetailsResponseDto>(
    `/v2/orders/${id}/partial-pay`,
    { method, name },
  );
  return response.data;
};

const remarkById = async (
  id: string,
  text: string,
): Promise<OrderDetailsResponseDto> => {
  const response = await axios.patch<OrderDetailsResponseDto>(
    `/v2/orders/${id}/remarks`,
    {
      remarks: text,
    },
  );
  return response.data;
};

const packById = async (
  id: string,
  fulfillments: OrderFulfillmentUpdateRequestDto[],
  isActionFromOrdersList?: boolean,
): Promise<OrderDetailsResponseDto> => {
  const response = await axios.patch<OrderDetailsResponseDto>(
    `/v2/orders/${id}/pack`,
    { fulfillments, isActionFromOrdersList },
  );
  return response.data;
};

const fetchTimeline = async (id: string): Promise<LogEntryResponseDto[]> => {
  const { data } = await axios.get<LogEntryResponseDto[]>(
    `/v2/orders/timeline/${id}`,
  );
  return data;
};

const fulfillById = async (
  id: string,
  action: OrderFulfillRequestDto,
): Promise<OrderDetailsResponseDto> => {
  const response = await axios.patch<OrderDetailsResponseDto>(
    `/v2/orders/${id}/fulfill`,
    action,
  );
  return response.data;
};

type EditDeliveryAddressParams = {
  id: string;
  deliveryAddress: EditDeliveryAddressRequestDto;
};

const editDeliveryAddress = async (
  params: EditDeliveryAddressParams,
): Promise<void> => {
  await axios.put(
    `/v2/orders/${params.id}/delivery-address`,
    params.deliveryAddress,
  );
};

const getPendingOrders = async () => {
  const { data } = await axios.get<PendingOrdersResponseDto>(
    PENDING_ORDERS_URL,
  );

  return data;
};

const getOrdersFilters = async () => {
  const { data } = await axios.get<OrdersFiltersByTypeResponseDto>(
    FETCH_ORDERS_FILTERS,
  );

  return data;
};

const editOrder = async (id: string, editRequest: EditOrderRequestDto) => {
  await axios.patch(`/v2/orders/${id}/edit`, editRequest);
};

const getOrderBouncedEmails = async (id: string) => {
  const { data } = await axios.get(
    getUrlWithParams(ORDERS_BOUNCED_EMAILS_URL, { id }),
  );
  return data;
};

const getOrderShippingCost = async (
  id: string,
  payload: OrderShippingCostRequestDto,
  signal?: AbortSignal,
) => {
  const { data } = await axios.post(`/v2/orders/${id}/shipping-cost`, payload, {
    signal,
  });
  return data;
};

const ordersService = {
  fulfillById,
  fetchTimeline,
  packById,
  paidById,
  partiallyPaidById,
  remarkById,
  openById,
  cancelById,
  refundById,
  fetchOrderPatch,
  fetchArchiveOrder,
  fetchCancelOrder,
  fetchHasAvailableFunds,
  fetchOrders,
  fetchOrdersAdvanced,
  fetchOrderById,
  fetchTotalOrders,
  exportOrders,
  updateBulkOrders,
  editDeliveryAddress,
  fetchBulkOrders,
  getPendingOrders,
  fetchOrderProducts,
  getOrdersFilters,
  editOrder,
  getOrderBouncedEmails,
  getOrderShippingCost,
  fetchRefundAttemptStatus,
};

export default ordersService;
