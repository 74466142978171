import { Alert, Text } from '@nimbus-ds/components';
import { OrderDetailsResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import CancelAndSaveButtons from 'commons/components/CancelAndSaveButtons';
import { RefundResumeCard, RefundDetailsCard } from '../';
import useTranslationOrders from '../../../../../useTranslationOrders';
import { RefundAttributes } from '../../../hooks/useRefundOrder/useRefundOrder';
import { UseRefundStepsResult } from '../../hooks/useSteps';

interface Attributes {
  onConfirm: () => void;
  disableButton: (values: RefundAttributes) => boolean;
  orderDetails: OrderDetailsResponseDto;
  values: RefundAttributes;
  useSteps: UseRefundStepsResult;
}

function ConfirmRefundDetailsPage({
  onConfirm,
  disableButton,
  orderDetails,
  values,
  useSteps,
}: Attributes): JSX.Element {
  const t = useTranslationOrders();

  return (
    <Stack column align="stretch">
      <RefundResumeCard values={values} orderDetails={orderDetails} />
      <RefundDetailsCard orderDetails={orderDetails} />
      <Alert appearance="neutral" show>
        <Text>{t('refund.confirmRefundDetails.info')}</Text>
      </Alert>
      <Stack justify="flex-end">
        <CancelAndSaveButtons
          saveText={t('refund.confirmRefundDetails.buttons.save')}
          cancelText={t('refund.confirmRefundDetails.buttons.cancel')}
          onSave={onConfirm}
          onCancel={useSteps.backStep}
          isDisabled={disableButton(values)}
        />
      </Stack>
    </Stack>
  );
}

export default ConfirmRefundDetailsPage;
