import { Popover, Button, Icon } from '@nimbus-ds/components';
import { EllipsisIcon } from '@nimbus-ds/icons';
import { MenuButton } from '@nimbus-ds/patterns';
import { Country } from '@tiendanube/common/src/enums';
import { useNavegate } from 'App/hooks';
import Stack from 'commons/components/Stack';
import { useGetCountry, useHasPermission } from 'domains/Auth/hooks';
import {
  DocumentType,
  FULFILLMENT_ORDER_ID_IDENTIFIER,
} from 'domains/FulfillmentOrders/pages/PrintDocumentPage/constants';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import { partnersAppsRoutes } from 'domains/PartnersApps';
import { isShippingMethodOca } from '../../../../../Orders/Orders/pages/OrderDetailsPage/components/EditDeliveryAdressModal/utils';
import { useGoToPrintPage } from '../../hooks';
import { trackingPrintDocument } from '../tracking';

interface PrintManagerDrodownProps {
  fulfillmentOrderId?: string;
  orderId: string;
  shipping: string;
  hasGeneratedTag: boolean;
  isNuvemEnvio?: boolean;
}

function PrintManagerDropdown({
  fulfillmentOrderId,
  orderId,
  shipping,
  hasGeneratedTag,
  isNuvemEnvio,
}: Readonly<PrintManagerDrodownProps>): JSX.Element {
  const t = useTranslationFulfillmentOrders();
  const storeCountry = useGetCountry();
  const { goToPrintPage } = useGoToPrintPage();
  const { goTo } = useNavegate();
  const hasPrintShippingLabelPermission = useHasPermission(
    'print_order_shipping_labels',
  );

  const handlePrintManager = (documentType: DocumentType) => {
    const isOca = isShippingMethodOca(shipping);
    const isOcaAndNotGeneratedTag = isOca && !hasGeneratedTag;

    if (documentType === DocumentType.NUVEM_ENVIO_LABEL) {
      const path = `#/dashboard?&externalSaleIds=${orderId}`;

      goTo(`${partnersAppsRoutes.nuvemenvio}${path}`);
    } else {
      goToPrintPage({
        ids: fulfillmentOrderId
          ? isOca
            ? [orderId]
            : [`${FULFILLMENT_ORDER_ID_IDENTIFIER}${fulfillmentOrderId}`]
          : [orderId],
        type: documentType,
        hasOca: isOca,
        hasOcaAndNotGeneratedTag: isOcaAndNotGeneratedTag,
      });
    }

    trackingPrintDocument(documentType, [orderId]);
  };

  return (
    <Popover
      id={`${fulfillmentOrderId ?? orderId}-printlabelDetail`}
      position="bottom-start"
      padding="small"
      arrow={false}
      enabledClick
      content={
        <Stack column spacing="tight" align="flex-start">
          {hasPrintShippingLabelPermission && (
            <>
              <MenuButton
                label={t(`printManager.label.title`)}
                onClick={() => handlePrintManager(DocumentType.LABEL)}
              />

              {storeCountry === Country.BR && isNuvemEnvio && (
                <MenuButton
                  label={t(`printManager.nuvemEnvio.title`)}
                  onClick={() =>
                    handlePrintManager(DocumentType.NUVEM_ENVIO_LABEL)
                  }
                />
              )}
            </>
          )}
          {storeCountry === Country.BR && (
            <MenuButton
              label={t(`printManager.contentDeclaration.title`)}
              onClick={() =>
                handlePrintManager(DocumentType.CONTENT_DECLARATION)
              }
            />
          )}
          <MenuButton
            label={t(`printManager.receipt.title`)}
            onClick={() => handlePrintManager(DocumentType.RECEIPT)}
          />
        </Stack>
      }
    >
      <Button appearance="neutral">
        <Icon source={<EllipsisIcon />} color="primary-textLow" />
        {t('printManager.dropdown')}
      </Button>
    </Popover>
  );
}

export default PrintManagerDropdown;
