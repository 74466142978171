import { AppsListIcon, AppsIcon } from '@nimbus-ds/icons';
import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { useHandleMenuClick } from 'App/hooks';
import { useGetTags } from 'domains/Auth/hooks';
import { useExperimentalApp } from 'domains/PartnersApps/pages/NuvemAppPage/components/ExperimentalApp';
import { useNuvemApps } from 'domains/PartnersApps/pages/NuvemAppPage/hooks';
import { trackingMenuPrimaryAppsClick } from './tracking';

const PATH = {
  apps: '/apps',
  appsWidgets: '/apps/widgets',
  appsTestApplication: '/apps/test-application',
};

interface ExternalAppsMenuProps {
  isMobile: boolean;
}

function ExternalAppsMenu({ isMobile }: Readonly<ExternalAppsMenuProps>) {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem } = useHandleMenuClick();
  const { nuvemApps } = useNuvemApps();
  const isExperimentalApp = useExperimentalApp();
  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);

  const handleClickNewAdmin = () => {
    trackingMenuPrimaryAppsClick();
    handleClickItem();
  };

  return (
    <>
      <MenuButton
        as={Link}
        to={PATH.apps}
        startIcon={AppsListIcon}
        onClick={handleClickNewAdmin}
        label={`${t(
          isRevampMenu ? 'menu.maximize.revampMyApps' : 'menu.maximize.myApps',
        )}`}
        active={pathname === PATH.apps}
      />

      {nuvemApps.widgets && isMobile && (
        <MenuButton
          as={Link}
          to={PATH.appsWidgets}
          startIcon={AppsIcon}
          onClick={handleClickItem}
          active={pathname === PATH.appsWidgets}
          label={`${t('menu.maximize.widgets')}`}
        />
      )}

      {nuvemApps['test-application'] && isExperimentalApp && (
        <MenuButton
          as={Link}
          to={PATH.appsTestApplication}
          startIcon={AppsIcon}
          onClick={handleClickItem}
          active={pathname.startsWith(PATH.appsTestApplication)}
          label={`${t('menu.maximize.testApplication')}`}
        />
      )}
    </>
  );
}

export default ExternalAppsMenu;
