import { useEffect } from 'react';
import { ErrorScreen } from 'commons/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { InvoiceHistoryDataTable } from './components';
import InvoiceHistoryWebSkeleton from './InvoiceHistoryWebSkeleton';
import useInvoiceHistoryWeb from './useInvoiceHistoryWeb';

function InvoiceHistoryWeb(): JSX.Element {
  const t = useTranslationBilling();

  const {
    fetchInvoiceHistory,
    fetchInvoiceHistoryInit,
    invoiceHistory,
    status,
  } = useInvoiceHistoryWeb();

  const getInvoiceHistory = () => fetchInvoiceHistory(1);

  useEffect(() => {
    fetchInvoiceHistoryInit();
  }, [fetchInvoiceHistoryInit]);

  const handlePageSelect = (page) => {
    fetchInvoiceHistory(page);
  };

  if (status === 'error')
    return (
      <ErrorScreen
        description={t('invoiceHistory.errors.load')}
        onRetry={getInvoiceHistory}
      />
    );

  return (
    <div className="invoice-history-web">
      <InvoiceHistoryDataTable
        onPageSelect={handlePageSelect}
        pagination={invoiceHistory.pagination}
        data={invoiceHistory.results}
        status={status}
      />
    </div>
  );
}

InvoiceHistoryWeb.Skeleton = InvoiceHistoryWebSkeleton;

export default InvoiceHistoryWeb;
