import { Trans, useTranslation } from 'react-i18next';
import { Alert, Text } from '@tiendanube/components';
import { CATALOG_UPLOAD_VIDEOS } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import Stack from '../Stack';

interface AlertImagesValidationProps {
  filenames: string[];
  validation: 'size' | 'type' | 'videoSize' | 'videoQuantity';
}
// POST-ROLLOUT: rename to AlertMediaValidation
function AlertImagesValidation({
  filenames,
  validation,
}: AlertImagesValidationProps) {
  const { t } = useTranslation('common');

  const [hasUploadVideos] = useHasTags([CATALOG_UPLOAD_VIDEOS]);

  const validationKeys = {
    size: {
      true: 'alertImagesExceededSize',
      false: 'alertImagesExceededSize',
    },
    videoSize: {
      true: 'alertVideosExceededSize',
      false: 'alertVideosExceededSize',
    },
    videoQuantity: {
      true: 'alertVideosExceededQuantity',
      false: 'alertVideosExceededQuantity',
    },
    type: {
      true: 'alertMediaWithBadType',
      false: 'alertImagesWithBadType',
    },
  };

  const validationKey = validationKeys[validation][String(hasUploadVideos)];

  if (filenames.length === 0) return null;
  return (
    <Alert appearance="warning" show>
      <Stack column>
        {filenames.map((filename) => (
          <Text key={filename}>
            <Trans
              t={t}
              i18nKey={validationKey}
              values={{ name: filename }}
              components={{ bold: <strong /> }}
            />
          </Text>
        ))}
      </Stack>
    </Alert>
  );
}

export default AlertImagesValidation;
