import { BaseCard, Button, Label, Title } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
import { useTranslationAccount } from 'domains/Account/hooks';

interface AuthenticationFactorActivationCardProps {
  active: boolean;
  title: string;
  activeDescription: string;
  inactiveDescription: JSX.Element;
  activeActionText: string;
  inactiveActionText: string;
  onContinue: () => void;
}

function AuthenticationFactorActivationCard({
  title,
  activeDescription,
  inactiveDescription,
  activeActionText,
  inactiveActionText,
  active,
  onContinue,
}: AuthenticationFactorActivationCardProps) {
  const t = useTranslationAccount(
    'authenticationFactorPage.authenticationFactorActivationCard',
  );

  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack justify="space-between" wrap>
          <Stack.Item>
            <Title type="h3">{title}</Title>
          </Stack.Item>
          <Stack.Item>
            <Label
              label={t(`label.${active ? 'active' : 'inactive'}`)}
              id="authentication-app-active-label"
              appearance={active ? 'success' : 'warning'}
            />
          </Stack.Item>
        </Stack>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch">
          {active ? <Text>{activeDescription}</Text> : inactiveDescription}
        </Stack>
      </BaseCard.Body>
      <BaseCard.Footer>
        <Button
          onClick={onContinue}
          appearance={active ? 'default' : 'primary'}
        >
          {active ? activeActionText : inactiveActionText}
        </Button>
      </BaseCard.Footer>
    </BaseCard>
  );
}

export default AuthenticationFactorActivationCard;
