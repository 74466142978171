import { logEvent } from 'commons/utils/tracking';
import {
  FREE_SHIPPING_SHOW_ADOPTION_ALERT,
  FREE_SHIPPING_OPEN_ADOPTION_ALERT,
  FREE_SHIPPING_CLOSE_ADOPTION_ALERT,
} from 'config/trackingEvents';

export const freeShippingShowAdoptionAlert = (type: string) => {
  logEvent(FREE_SHIPPING_SHOW_ADOPTION_ALERT, { type });
};

export const freeShippingOpenAdoptionAlert = (type: string) => {
  logEvent(FREE_SHIPPING_OPEN_ADOPTION_ALERT, { type });
};

export const freeShippingCloseAdoptionAlert = (type: string) => {
  logEvent(FREE_SHIPPING_CLOSE_ADOPTION_ALERT, { type });
};
