import { useEffect, useMemo } from 'react';
import { useNavegate } from 'App/hooks';
import { combineStatus } from 'commons/utils/combineStatus';
import { useGetIsFreePlan, useStoreStatus } from 'domains/Auth/hooks';
import { useGetLastPayment, useGetSubscriptions } from '../Checkout/hooks';
import { ChurnStatus, trackingChurnStatusCheck } from '../tracking';

function getChurnedStatus(
  isStoreDown?: boolean,
  isFreePlan?: boolean,
  hasActivePlanCharge?: boolean,
  hasPlanSubscription?: boolean,
): ChurnStatus | undefined {
  if (
    [
      isStoreDown,
      isFreePlan,
      hasActivePlanCharge,
      hasPlanSubscription,
    ].includes(undefined)
  )
    return undefined;
  if (!isStoreDown) return 'store_active';
  if (!isFreePlan) return 'has_free_plan';
  if (!hasActivePlanCharge) return 'needs_payment';
  return hasPlanSubscription ? 'unsynced' : 'has_remaining_days';
}

export default function useStoreChurnedStatus() {
  const { storeStatus } = useStoreStatus();
  const { isFreePlan } = useGetIsFreePlan();
  const isStoreDown = storeStatus === 'store_down';

  const { pathname } = useNavegate();

  const {
    paymentStatus,
    lastRelevantCharge,
    isSuccess,
    status: chargeStatus,
  } = useGetLastPayment('plan-cost', undefined, isStoreDown);
  const { status: subStatus, subscriptionForPlan } =
    useGetSubscriptions(isStoreDown);

  const statusObject = combineStatus(chargeStatus, subStatus);

  const hasActivePlanCharge = useMemo(() => {
    const now = new Date(Date.now());
    return isSuccess
      ? !!lastRelevantCharge &&
          paymentStatus === 'PAID' &&
          new Date(lastRelevantCharge.fromDate) < now &&
          new Date(lastRelevantCharge.toDate) > now
      : undefined;
  }, [isSuccess, paymentStatus, lastRelevantCharge]);

  const hasPlanSubscription = !!subscriptionForPlan;

  const canReactivateWithoutPayment =
    isStoreDown &&
    (isFreePlan || (hasPlanSubscription === false && hasActivePlanCharge));

  const churnStatus = useMemo(
    () =>
      getChurnedStatus(
        isStoreDown,
        isFreePlan,
        hasActivePlanCharge,
        !!subscriptionForPlan,
      ),
    [isStoreDown, isFreePlan, hasActivePlanCharge, subscriptionForPlan],
  );

  useEffect(() => {
    if (churnStatus && isStoreDown) {
      trackingChurnStatusCheck({
        status: churnStatus,
        route: pathname,
      });
    }
  }, [isStoreDown, churnStatus, pathname]);
  return {
    hasActivePlanCharge,
    ...statusObject,
    isStoreDown,
    canReactivateWithoutPayment,
  };
}
