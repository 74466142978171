import { useSelector } from 'react-redux';
import {
  getSavedSearchesCount,
  getSavedSearchesCountStatus,
} from 'domains/Orders/Orders/ordersSlice/ordersSelectors/ordersSelectors';
import {
  defaultSavedSearches,
  DefaultSavedSearchesCode,
} from './defaultSavedSearches';

function useSavedSearches() {
  const savedSearchesCount = useSelector(getSavedSearchesCount);
  const savedSearchesCountStatus = useSelector(getSavedSearchesCountStatus);

  const buildQueryParamsFromSavedSearch = (
    code: DefaultSavedSearchesCode,
  ): string => {
    const savedSearch = defaultSavedSearches.filter(
      (savedSearch) => savedSearch.code === code,
    )[0];
    if (!savedSearch) {
      return '';
    }
    const searchParams = new URLSearchParams();

    Object.entries(savedSearch.filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => searchParams.append(key, v));
      } else if (value !== undefined && value !== null) {
        searchParams.append(key, value);
      }
    });

    return searchParams.toString();
  };

  return {
    savedSearchesCount,
    savedSearchesCountStatus,
    buildQueryParamsFromSavedSearch,
  };
}

export default useSavedSearches;
