import { Box, Card, Text, Checkbox, Alert } from '@nimbus-ds/components';
import { trackingBillingCheckoutTransactionFeesRpCheckbox } from 'domains/Billing/Checkout/tracking';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { useIncludeTransactionFees } from '../../hooks';

interface IncludeTransactionFeeProps {
  includeTransactionFee: boolean;
  onCheckIncludeTransactionFee: (isChecked: boolean) => void;
}

function IncludeTransactionFee({
  includeTransactionFee,
  onCheckIncludeTransactionFee,
}: Readonly<IncludeTransactionFeeProps>) {
  const t = useTranslationBilling('checkout.includeTransactionFee');

  const handleCheckboxChange = () => {
    trackingBillingCheckoutTransactionFeesRpCheckbox(!includeTransactionFee);
    onCheckIncludeTransactionFee(!includeTransactionFee);
  };

  const { shouldShowCard } = useIncludeTransactionFees();
  if (!shouldShowCard) return null;

  return (
    <Card>
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="4">
          <Text fontWeight="bold">{t('title')}</Text>
          <Box
            display="flex"
            flexDirection="row"
            gap="4"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text>{t('checkboxText')}</Text>
            <Checkbox
              name="includeTransactionFee"
              onChange={handleCheckboxChange}
              checked={includeTransactionFee}
            />
          </Box>
          <Alert appearance="neutral">
            <Text fontSize="caption">{t('alertText')}</Text>
          </Alert>
        </Box>
      </Card.Body>
    </Card>
  );
}

export default IncludeTransactionFee;
