import { Box, Text, Title } from '@nimbus-ds/components';
import FeatureFlag from 'App/components/FeatureFlag';
import { NEW_ONBOARDING_B, PROJECT_SHIVA_TAG } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import useOnboardingTasks from '../OnboardingTasks/useOnboardingTasks';
import './HeaderPage.scss';

interface TitleWordBreakProps {
  children: React.ReactNode;
}

// We have incorporated this internal component with CSS to introduce
// the functionality of adding a line break to a long word,
// since Nimbus components do not support this property.
function TitleWordBreak({ children }: TitleWordBreakProps) {
  return <div className="stratus--title-word-break">{children}</div>;
}

function HeaderPage() {
  const t = useTranslationDashboard();
  const { isLoading, isSuccess, hasCompleted, hasStartedOnboarding, isPos } =
    useOnboardingTasks();
  const { name: storeName } = useStoreInfo();
  const [hasNewOnboarding] = useHasTags(NEW_ONBOARDING_B);
  const [isShivaProject] = useHasTags(PROJECT_SHIVA_TAG);

  const titleContent = hasNewOnboarding
    ? 'header.newOnboardingStarted'
    : 'header.onboardingStarted';

  let onboardingStarted = isShivaProject
    ? t('header.newOnboardingStartedShiva')
    : t(titleContent);

  const onboardingDidNotStart = t('header.onboardingDidNotStart');

  if (isPos) {
    onboardingStarted = t('header.posOnboardingStarted');
  }

  return (
    <Box display="flex" flexDirection="column" gap="2">
      <FeatureFlag
        flag={NEW_ONBOARDING_B}
        renderElse={
          <TitleWordBreak>
            <Title as="h2" fontWeight="regular">
              {t('header.title', { storeName })}
            </Title>
          </TitleWordBreak>
        }
      />
      {isLoading && <Text.Skeleton width="340px" fontSize="highlight" />}
      {isSuccess && !hasCompleted && (
        <TitleWordBreak>
          <FeatureFlag
            flag={NEW_ONBOARDING_B}
            renderElse={
              <Title as="h3" fontWeight="regular">
                {hasStartedOnboarding
                  ? onboardingStarted
                  : onboardingDidNotStart}
              </Title>
            }
          >
            <Title as="h6" fontWeight="regular">
              {onboardingStarted}
            </Title>
          </FeatureFlag>
        </TitleWordBreak>
      )}
    </Box>
  );
}

export default HeaderPage;
