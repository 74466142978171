import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CheckoutPaymentMethod } from '@tiendanube/common';
import { toStatusType } from 'App/rtk';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { getConceptsFromCharges, getMainConceptFromCharges } from './utils';
import {
  useGetPayOrderToPayQuery,
  usePostCreatePayOrderMutation,
} from '../CheckoutApi';
import { FEE_CONCEPTS } from '../pages/utils';

function usePayorderToPay(initialFetch = true) {
  const { payOrderId } = useParams<{ payOrderId: string }>() || '';

  const {
    status,
    data: payorderToPay,
    refetch,
  } = useGetPayOrderToPayQuery(payOrderId, {
    skip: !payOrderId || !initialFetch,
  });

  const [createPayorder, { status: payOrderCreationStatus }] =
    usePostCreatePayOrderMutation();

  const conceptsToPay = useMemo(
    () => payorderToPay && getConceptsFromCharges(payorderToPay.charges),
    [payorderToPay],
  );

  const mainConcept =
    payorderToPay && getMainConceptFromCharges(payorderToPay.charges);

  const isForPlan =
    conceptsToPay && Array.from(conceptsToPay).includes('plan-cost');

  const totalWithoutFees = useMemo(
    () =>
      payorderToPay?.charges.reduce(
        (acc, { conceptCode, amountValue }) =>
          FEE_CONCEPTS.includes(conceptCode) ? acc : acc + Number(amountValue),
        0,
      ),
    [payorderToPay],
  );

  const subtotalWithoutFees = useMemo(
    () =>
      payorderToPay?.charges.reduce(
        (acc, { conceptCode, amountBaseValue }) =>
          FEE_CONCEPTS.includes(conceptCode) ? acc : acc + amountBaseValue,
        0,
      ),
    [payorderToPay],
  );

  const feesForMethod = useCallback(
    (selectedMethod: CheckoutPaymentMethod) =>
      payorderToPay?.paymentFees.filter(
        ({ method }) => method === selectedMethod,
      ),
    [payorderToPay],
  );

  const totalFeeForMethod = useCallback(
    (selectedMethod: CheckoutPaymentMethod) =>
      feesForMethod(selectedMethod)?.reduce(
        (acc, fee) => acc + Number(fee.amountValue),
        0,
      ),
    [feesForMethod],
  );

  const totalFeeForMethodWithTaxes = useCallback(
    (selectedMethod: CheckoutPaymentMethod) =>
      feesForMethod(selectedMethod)?.reduce(
        (acc, fee) =>
          acc +
          Number(fee.amountValue) +
          fee.taxes.reduce(
            (acc, { amountValue }) => acc + Number(amountValue),
            0,
          ),
        0,
      ),
    [feesForMethod],
  );

  const feePercentageForMethod = useCallback(
    (selectedMethod: CheckoutPaymentMethod) => {
      const totalFee = totalFeeForMethod(selectedMethod);
      return (
        totalFee &&
        subtotalWithoutFees &&
        ((100 * totalFee) / subtotalWithoutFees).toFixed(2)
      );
    },
    [totalFeeForMethod, subtotalWithoutFees],
  );

  const nonFeeCharges = useMemo(
    () =>
      payorderToPay?.charges.filter(
        ({ conceptCode }) => !FEE_CONCEPTS.includes(conceptCode),
      ),
    [payorderToPay],
  );

  return {
    createPayorder,
    payorderToPay,
    status: toStatusType(status),
    refreshPayOrderToPay: refetch,
    payOrderCreationStatus: toStatusType(payOrderCreationStatus),
    ...convertStatusTypeToObject(status),
    payOrderId,
    isForPlan,
    isForPlanOnly: isForPlan && conceptsToPay.size === 1,
    conceptsToPay,
    recurrentPaymentCompatible: payorderToPay?.charges.some(
      ({ concept }) => concept.recurrentPaymentCompatible,
    ),
    mainConcept,
    feesForMethod,
    totalFeeForMethod,
    totalFeeForMethodWithTaxes,
    totalWithoutFees,
    subtotalWithoutFees,
    feePercentageForMethod,
    nonFeeCharges,
  };
}

export default usePayorderToPay;
