import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  clearCustomerIsEdited,
  getCustomerIsEdited,
} from 'domains/Customers/Customers/customersSlice';

interface UseCustomerDetailsEditedAlertResult {
  isEdited: boolean;
  onRemoveAlert: () => void;
}

function useCustomerDetailsEditedAlert(): UseCustomerDetailsEditedAlertResult {
  const dispatch = useAppDispatch();
  const isEdited = useSelector(getCustomerIsEdited);

  const onRemoveAlert = () => {
    dispatch(clearCustomerIsEdited());
  };

  return {
    isEdited,
    onRemoveAlert,
  };
}

export default useCustomerDetailsEditedAlert;
