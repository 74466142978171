import { SocialNetworksRequestDto } from '@tiendanube/common';
import { Input, BaseCard } from '@tiendanube/components';
import { Stack } from 'commons/components';
import Link from 'commons/components/LegacyLink';
import Text from 'commons/components/LegacyText';
import { useAutoFocus } from 'commons/hooks';
import { useTranslationAccount } from 'domains/Account/hooks';
import Skeleton from './Sekeleton';
import { InterfaceNameValue } from '../../SocialNetworksPage';

type SocialNetworksErrorInterface = SocialNetworksRequestDto;
interface SocialNetworksCardProps {
  errors: SocialNetworksErrorInterface;
  values: SocialNetworksRequestDto;
  handleOnChange: (value: InterfaceNameValue) => void;
}

const HTTP = 'http://';
const HTTPS = 'https://';
const AT = '@';

const isEmpty = (value): boolean => value?.trim() === '';
const hasHttp = (value): boolean =>
  [HTTP, HTTPS].some((protocol) => value?.includes(protocol));

const hasAt = (value): boolean => value?.includes(AT);

function SocialNetworksCard({
  values,
  errors,
  handleOnChange,
}: SocialNetworksCardProps): JSX.Element {
  const t = useTranslationAccount();
  const { ref } = useAutoFocus();
  const {
    instagram,
    facebook,
    tiktok,
    twitter,
    pinterest,
    blog,
    pinterestMeta,
    instagramToken,
    youtube,
  } = values;

  const handleBlurUrl = ({ name }: InterfaceNameValue) => {
    if (hasHttp(values[name])) {
      handleOnChange({
        name,
        value: values[name].replace(HTTP, '').replace(HTTPS, ''),
      });
    } else if (isEmpty(values[name])) {
      handleOnChange({
        name,
        value: null,
      });
    }
  };

  const handleBlurAt = ({ name }: InterfaceNameValue) => {
    if (hasAt(values[name])) {
      handleOnChange({
        name,
        value: values[name].replace(AT, ''),
      });
    }
  };
  return (
    <BaseCard>
      <BaseCard.Body>
        <Stack column align="stretch" spacing="loose" innerRef={ref}>
          <Input
            value={instagram || undefined}
            onChange={handleOnChange}
            label={t('socialNetworksPage.instagramUser')}
            name="instagram"
            onBlur={handleBlurAt}
            required
          />

          <Stack spacing="tight" column align="stretch">
            <Input
              value={instagramToken || undefined}
              onChange={handleOnChange}
              label={t('socialNetworksPage.instagramToken')}
              name="instagramToken"
              required
            />

            <Text size="caption">
              {t('socialNetworksPage.allowFeed')}{' '}
              <Link
                size="small"
                underline
                appearance="primary"
                href={t('socialNetworksPage.allowFeedLink')}
              >
                {t('socialNetworksPage.howToGet')}
              </Link>
            </Text>
          </Stack>
          <Input
            value={facebook || undefined}
            onChange={handleOnChange}
            onBlur={handleBlurUrl}
            label={t('socialNetworksPage.facebookPage')}
            appearance={errors.facebook ? 'validation_error' : undefined}
            helpText={t(errors.facebook || '')}
            name="facebook"
            required
          />

          <Input
            value={youtube || undefined}
            onChange={handleOnChange}
            label={t('socialNetworksPage.youtubeChanel')}
            onBlur={handleBlurUrl}
            appearance={errors.youtube ? 'validation_error' : undefined}
            helpText={t(errors.youtube || '')}
            name="youtube"
            required
          />

          <Input
            value={tiktok || undefined}
            onChange={handleOnChange}
            label={t('socialNetworksPage.tiktokUser')}
            onBlur={handleBlurAt}
            name="tiktok"
            required
          />

          <Input
            value={twitter || undefined}
            onChange={handleOnChange}
            label={t('socialNetworksPage.twitterAccount')}
            onBlur={handleBlurAt}
            name="twitter"
            required
          />

          <Input
            value={pinterest || undefined}
            onChange={handleOnChange}
            label={t('socialNetworksPage.pinterest')}
            appearance={errors.pinterest ? 'validation_error' : undefined}
            helpText={t(errors.pinterest || '')}
            onBlur={handleBlurUrl}
            name="pinterest"
            required
          />

          <Stack spacing="tight" column align="stretch">
            <Input
              value={pinterestMeta || undefined}
              onChange={handleOnChange}
              label={t('socialNetworksPage.pinterestLabel')}
              placeholder={t('socialNetworksPage.ejPinterestLabel')}
              name="pinterestMeta"
              required
            />

            <Text size="caption">
              {t('socialNetworksPage.allowTracking')}{' '}
              <Link
                size="small"
                underline
                appearance="primary"
                href={t('socialNetworksPage.allowTrackingLink')}
              >
                {t('socialNetworksPage.howToInstall')}
              </Link>
            </Text>
          </Stack>
          <Input
            value={blog || undefined}
            onChange={handleOnChange}
            label={t('socialNetworksPage.blog')}
            appearance={errors.blog ? 'validation_error' : undefined}
            helpText={t(errors.blog || '')}
            onBlur={handleBlurUrl}
            name="blog"
            required
          />
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

SocialNetworksCard.Skeleton = Skeleton;

export default SocialNetworksCard;
