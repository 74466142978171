import { useCallback } from 'react';
import { Box, Card, Icon, Link, Text } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { openWindow } from 'commons/utils/window';

interface AppItem {
  image: string;
  name: string;
  handle?: string;
  description: string;
  url?: string;
  installUrl?: string;
  profileUrl?: string;
}

interface AppItemCardProps {
  app: AppItem;
  installText?: string;
  urlText?: string;
  onClick?: () => void;
  onClickInstall?: () => void;
  onClickTitle?: () => void;
  height?: string;
}

function AppItemCard({
  app,
  installText,
  urlText,
  onClick,
  onClickInstall,
  onClickTitle,
  height,
}: AppItemCardProps) {
  const { t } = useTranslation('common');
  const { image, name, description, url, installUrl } = app;

  const handleClick = useCallback(() => {
    if (url) openWindow(url, true);
    onClick && onClick();
  }, [url, onClick]);

  const handleClickInstall = useCallback(() => {
    if (installUrl) openWindow(installUrl, true);
    onClickInstall && onClickInstall();
  }, [installUrl, onClickInstall]);

  const handleClickTitle = useCallback(() => {
    if (app.profileUrl) openWindow(app.profileUrl, true);
    onClickTitle && onClickTitle();
  }, [app.profileUrl, onClickTitle]);

  return (
    <Card>
      <Card.Body>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          gap="4"
          height={height ?? '80px'}
        >
          <img src={image} width="50px" height="50px" />
          <Box flex="1 1 auto" height="100%">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="flex-start"
              height={height ? `calc(${height} - 18px)` : '62px'}
            >
              {app.profileUrl ? (
                <Text
                  fontSize="highlight"
                  fontWeight="bold"
                  lineClamp={1}
                  as="span"
                >
                  <Link
                    as="a"
                    appearance="primary"
                    textDecoration="none"
                    fontSize="highlight"
                    onClick={handleClickTitle}
                  >
                    {name}
                  </Link>
                </Text>
              ) : (
                <Text fontSize="highlight" fontWeight="bold" lineClamp={1}>
                  {name}
                </Text>
              )}
              <Text lineClamp={2}>{description}</Text>
            </Box>
            <Box display="flex" gap="3">
              {(!!app.installUrl || !!onClickInstall) && (
                <Link
                  as="a"
                  appearance="primary"
                  textDecoration="none"
                  onClick={handleClickInstall}
                >
                  {installText ?? t('install')}
                </Link>
              )}
              {!!app.url && (
                <Link
                  as="a"
                  appearance="primary"
                  textDecoration="none"
                  onClick={handleClick}
                >
                  {urlText ?? t('viewDetails')}
                  <Icon color="currentColor" source={<ExternalLinkIcon />} />
                </Link>
              )}
            </Box>
          </Box>
        </Box>
      </Card.Body>
    </Card>
  );
}

export default AppItemCard;
