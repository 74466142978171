import { Fragment } from 'react';
import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { CheckoutPaymentMethod } from '@tiendanube/common';
import { ErrorState } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import { combineStatus } from 'commons/utils/combineStatus';
import {
  usePaymentMethods,
  usePayorderToPay,
} from 'domains/Billing/Checkout/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { RecurrencyOption } from './components';
import { ChooseRecurrencyItemsSkeleton } from './Skeleton';

export default function ChooseRecurrency() {
  const t = useTranslationBilling('checkout.choosePaymentMethodWithRecurrency');
  const {
    status: methodsStatus,
    refreshPaymentMethods,
    paymentMethods,
  } = usePaymentMethods();
  const { getQueryParam } = useNavegate();
  const selectedMethod = getQueryParam('method') as CheckoutPaymentMethod;
  const { isForPlanOnly, status: payOrderStatus } = usePayorderToPay();

  const { isError, isLoading } = combineStatus(payOrderStatus, methodsStatus);

  if (isError)
    return (
      <ErrorState
        title={t('error.title')}
        action={{
          children: t('error.retry'),
          onClick: refreshPaymentMethods,
        }}
      />
    );

  const recurrencyOptions = paymentMethods?.find(
    ({ method }) => method === selectedMethod,
  )?.recurrencyOptions;

  if (!selectedMethod) return null;

  return (
    <Card padding="none">
      <Box display="flex" flexDirection="column">
        <Box padding="4">
          <Title as="h4">{t('chooseRecurrency.title')}</Title>
        </Box>
        <Box padding="4" backgroundColor="neutral-surface">
          <Box display="flex" flexDirection="column">
            <Text color="neutral-textDisabled" fontSize="caption">
              {t('chooseRecurrency.selectedMethodDescription')}
            </Text>
            <Text fontSize="caption">{t(selectedMethod)}</Text>
          </Box>
        </Box>
        {isLoading ? (
          <ChooseRecurrencyItemsSkeleton />
        ) : (
          recurrencyOptions?.map((option) => (
            <Fragment key={option.amountBaseValue}>
              <hr />
              <RecurrencyOption
                method={selectedMethod}
                option={option}
                isForPlanOnly={!!isForPlanOnly}
              />
            </Fragment>
          ))
        )}
      </Box>
    </Card>
  );
}
