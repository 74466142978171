import { useCallback, useMemo, useState } from 'react';
import {
  ArchiveIcon,
  ForbiddenIcon,
  LockOpenIcon,
  EditIcon,
  UndoIcon,
} from '@nimbus-ds/icons';
import { OrderDetailsResponseDto } from '@tiendanube/common';
import { Status as StatusEnum } from '@tiendanube/common/src/enums';
import { useNavegate } from 'App/hooks';
import { ActionProp } from 'commons/components';
import { useAsyncFunc, useModal } from 'commons/hooks';
import { useHasPermission } from 'domains/Auth/hooks';
import { useEditOrderUpsell } from 'domains/Orders/Orders/hooks/useEditOrderUpsell';
import { trackingOrderReopenButtonClick } from 'domains/Orders/tracking';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { useCanBeRefunded } from '../../../hooks';
import useBuildPrintLabelAction from '../usePrintLabelAction/useBuildPrintLabelAction';

// eslint-disable-next-line max-statements
const useOrderActions = (
  orderDetails: OrderDetailsResponseDto | null,
  openModal,
  onArchiveSuccess,
  onOpenSuccess,
  openOrder,
  archiveOrder,
  onError,
) => {
  const t = useTranslationOrders();
  const { goTo } = useNavegate();
  const { canBeRefundedThroughRefundForm } = useCanBeRefunded(orderDetails);

  const isEditOrderActionShowable = useMemo(
    () => orderDetails?.status === StatusEnum.OPEN,
    [orderDetails],
  );
  const canCancelOrder = useHasPermission('cancel_order');
  const canReopenOrder = useHasPermission('reopen_order');
  const [updateMetadata, setUpdateMetadata] = useState<any>(null);
  const [showReopenModal, openReopenModal, handleHideReopenModal] = useModal();

  const [handleArchiveOrder] = useAsyncFunc(
    async () => archiveOrder(),
    onArchiveSuccess,
    onError,
  );

  const handleOpenOrder = useCallback(async () => {
    try {
      trackingOrderReopenButtonClick(orderDetails?.status ?? '');
      if (orderDetails?.status === StatusEnum.CANCELLED) {
        const openResponse = await openOrder(true);
        const updateMetadata = openResponse?.updateMetadata;
        setUpdateMetadata(updateMetadata);
        updateMetadata?.insufficientStock ? openReopenModal() : onOpenSuccess();
      } else {
        await openOrder();
        onOpenSuccess();
      }
    } catch (error) {
      onError();
    }
  }, [
    orderDetails?.status,
    openOrder,
    openReopenModal,
    onOpenSuccess,
    onError,
  ]);

  const printLabelAction = useBuildPrintLabelAction(orderDetails, t);

  const getActions = (): ActionProp[] => {
    const isOpen = orderDetails?.status === StatusEnum.OPEN;

    if (isOpen) {
      return [
        ...(canCancelOrder
          ? [
              {
                children: `${t('detail.action.cancel')}`,
                onClick: openModal,
                icon: ForbiddenIcon,
              },
            ]
          : []),
        ...(canBeRefundedThroughRefundForm()
          ? [
              {
                children: `${t('detail.action.refund')}`,
                onClick: () => goTo(`/orders/${orderDetails?.id}/refund`),
                icon: UndoIcon,
              },
            ]
          : []),
        {
          children: `${t('detail.action.archive')}`,
          onClick: handleArchiveOrder,
          icon: ArchiveIcon,
        },
      ];
    }

    return canReopenOrder
      ? [
          {
            children: `${t('detail.action.reOpen')}`,
            onClick: handleOpenOrder,
            icon: LockOpenIcon,
          },
        ]
      : [];
  };

  const isEditOrderButtonDisabled = !orderDetails?.isEditable;

  const getDisabledEditOrdersMessage = useCallback(() => {
    if (!orderDetails || !isEditOrderButtonDisabled) {
      return undefined;
    }

    if (orderDetails.inconsistentProducts) {
      return t('editOrders.disabledTooltipInconsitentOrder');
    } else if (!orderDetails.fulfillmentOrders?.length) {
      return t('editOrders.disabledIncompatible');
    } else {
      return t('editOrders.disabledTooltip');
    }
  }, [orderDetails, t, isEditOrderButtonDisabled]);

  const editOrderUpsell = useEditOrderUpsell(orderDetails?.id);

  const editOrderAction: ActionProp[] = isEditOrderActionShowable
    ? [
        {
          children: t(`detail.editOrder`),
          onClick: editOrderUpsell,
          icon: EditIcon,
          disabled: isEditOrderButtonDisabled,
          tooltip: getDisabledEditOrdersMessage(),
        },
      ]
    : [];

  const getHeaderActionsDesktop = (): ActionProp[] => {
    const baseActions = getActions();

    return [...editOrderAction, ...printLabelAction, ...baseActions];
  };

  const actions = getHeaderActionsDesktop();
  return {
    actions,
    updateMetadata,
    showReopenModal,
    handleHideReopenModal,
  };
};

export default useOrderActions;
