import { useState } from 'react';
import { Popover, Box, Button, Icon, Text } from '@nimbus-ds/components';
import { ArrowsVerticalIcon, EllipsisIcon } from '@nimbus-ds/icons';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

const TRANSLATE_PREFIX = 'paymentProviders';

interface HeaderSortPaymentsMobileProps {
  onClick: () => void;
}

function HeaderSortPaymentsMobile({ onClick }: HeaderSortPaymentsMobileProps) {
  const [showSortPaymentsModal, setShowSortPaymentsModal] = useState(false);
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  const handleOnVisibility = () => {
    setShowSortPaymentsModal(!showSortPaymentsModal);
  };

  return (
    <Popover
      content={
        <Box padding="2" display="flex" flexDirection="row" width="100%">
          <Button
            onClick={onClick}
            data-style="button-override"
            appearance="transparent"
          >
            <Icon source={<ArrowsVerticalIcon />} />
            <Text>{`${t('sortPaymentsButton')}`}</Text>
          </Button>
        </Box>
      }
      padding="none"
      position="bottom-end"
      visible={showSortPaymentsModal}
      onVisibility={handleOnVisibility}
    >
      <Button appearance="transparent" onClick={console.log}>
        <Icon color="currentColor" source={<EllipsisIcon />} />
      </Button>
    </Popover>
  );
}

export default HeaderSortPaymentsMobile;
