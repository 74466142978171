import { useCallback, useEffect, useState } from 'react';
import {
  Input,
  InterfaceInput,
  InterfaceNameValue,
} from '@tiendanube/components';

interface InputPhoneProps extends Omit<InterfaceInput, 'onChange'> {
  onChange: (event: InterfaceNameValue) => void;
}

function InputPhone({ onChange, value, name, ...props }: InputPhoneProps) {
  const [cleanedUpPhone, setCleanedUpPhone] = useState('');
  const handleOnChange = useCallback(
    ({ name, value }: InterfaceNameValue) => {
      const valueCharacterCleanup = value.replace(/(?!^\+)\D/g, '');
      setCleanedUpPhone(valueCharacterCleanup);
      onChange({ name, value: valueCharacterCleanup });
    },
    [onChange],
  );

  useEffect(() => {
    if (!cleanedUpPhone && value) handleOnChange({ name, value });
  }, [cleanedUpPhone, handleOnChange, value, name]);

  return (
    <Input
      onChange={handleOnChange}
      value={cleanedUpPhone || ''}
      name={name}
      {...props}
    />
  );
}

export default InputPhone;
