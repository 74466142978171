import { useEffect } from 'react';
import { useGoToPlans } from 'commons/hooks/useGoToPlans';
import { AffiliateDiscountPercentageAlert } from './AffiliateDiscountPercentageAlert';
import useTrialMessages from './useTrialMessages/useTrialMessages';

function TrialMessages() {
  const { getTrialMessages, trialMessage, isSuccess } = useTrialMessages();
  const { isMobileNonFreemium } = useGoToPlans();

  useEffect(() => {
    getTrialMessages();
  }, [getTrialMessages]);

  if (!isSuccess) return null;

  const mobileSuffix = isMobileNonFreemium ? 'Mobile' : '';

  return (
    <AffiliateDiscountPercentageAlert
      mobileSuffix={mobileSuffix}
      affiliateDiscountPercentage={trialMessage?.affiliateDiscountPercentage}
      show={trialMessage?.showMessage}
    />
  );
}

export default TrialMessages;
