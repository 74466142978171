import { AxiosError } from 'axios';
import {
  CouponsCreateRequestDto,
  CouponsFilterRequestDto,
  CouponsResponseDto,
  CouponsUpdateRequestDto,
  ResultPaginationResponseDto,
} from '@tiendanube/common';
import axios from 'App/axios';
import { CouponValuesInterface } from '../components/CouponForm';
import { UNIQUE_CODE } from '../utils';

export const getCouponsList = async (
  params?: CouponsFilterRequestDto,
): Promise<ResultPaginationResponseDto<CouponsResponseDto[]>> => {
  const response = await axios.get(
    `v1/coupons`,
    params && {
      params,
    },
  );
  return response.data;
};

export const getCoupon = async (id: string): Promise<CouponsResponseDto> => {
  const response = await axios.get(`v1/coupons/${id}`);
  return response.data;
};

export const createCoupon = async (
  coupon: CouponValuesInterface,
): Promise<void> => {
  const payload: CouponsCreateRequestDto = {
    code: coupon.couponCode,
    type: coupon.discountType,
    valueAbsolute: Number(coupon.valueAbsolute),
    valuePercentage: Number(coupon.valuePercentage),
    includeShipping: coupon.includeShipping,
    minPrice: Number(coupon.minPrice),
    maxUses: Number(coupon.maxUses),
    dateType: coupon.dateType,
    startDate: coupon.startDate,
    endDate: coupon.endDate,
    categories: coupon.categories.map((category) => category.id),
    products: coupon.products.map((product) => product.id),
    clientLimitationType: coupon.clientLimitationType,
    maxUsesPerClient:
      coupon.maxUsesPerClient === '' ? null : Number(coupon.maxUsesPerClient),
    combinesWithOtherDiscounts: coupon.combinesWithOtherDiscounts,
  };
  try {
    await axios.post('v1/coupons', payload);
    return;
  } catch (error) {
    if ((error as AxiosError).response?.data.response.code[0] === UNIQUE_CODE) {
      throw new Error(UNIQUE_CODE);
    } else {
      throw error;
    }
  }
};

export const editCoupon = async (
  id: string,
  coupon: Partial<CouponValuesInterface>,
): Promise<void> => {
  const payload: CouponsUpdateRequestDto = {
    code: coupon.couponCode,
    valid: coupon.active,
    type: coupon.discountType,
    ...(coupon.valueAbsolute && {
      valueAbsolute: Number(coupon.valueAbsolute),
    }),
    ...(coupon.valuePercentage && {
      valuePercentage: Number(coupon.valuePercentage),
    }),
    includeShipping: coupon.includeShipping,
    ...(coupon.minPrice && { minPrice: Number(coupon.minPrice) }),
    ...(coupon.maxUses && { maxUses: Number(coupon.maxUses) }),
    dateType: coupon.dateType,
    startDate: coupon.startDate,
    endDate: coupon.endDate,
    categories: coupon.categories?.map((category) => category.id),
    products: coupon.products?.map((product) => product.id),
    clientLimitationType: coupon.clientLimitationType,
    combinesWithOtherDiscounts: coupon.combinesWithOtherDiscounts,
    ...(coupon.maxUsesPerClient && {
      maxUsesPerClient: Number(coupon.maxUsesPerClient),
    }),
    ...(coupon.clientLimitationType &&
      coupon.clientLimitationType !== 'limited' && {
        maxUsesPerClient: null,
      }),
  };
  try {
    await axios.put(`v1/coupons/${id}`, payload);
    return;
  } catch (error) {
    if ((error as AxiosError).response?.data.response.code[0] === UNIQUE_CODE) {
      throw new Error(UNIQUE_CODE);
    } else {
      throw error;
    }
  }
};

export const removeCoupon = async (id: string): Promise<void> => {
  await axios.delete(`v1/coupons/${id}`);
  return;
};

export const activateCoupon = async (id: string): Promise<void> => {
  await axios.put(`v1/coupons/${id}`, { valid: true });
  return;
};

export const deactivateCoupon = async (id: string): Promise<void> => {
  await axios.put(`v1/coupons/${id}`, { valid: false });
  return;
};
