import { Box, Button, Text } from '@nimbus-ds/components';
import { InitialScreen } from '@nimbus-ds/patterns';
import { Trans, useTranslation } from 'react-i18next';
import { useResponsive } from 'commons/components';
import { NUVEM_CHAT_APP_INSTALL_LINK } from 'commons/constants/externalLinks';
import { useTranslationLanguage } from 'commons/hooks';
import { openWindow } from 'commons/utils/window';
import { NuvemChatPlans } from './components/NuvemChatPlans';
import NuvemChatImageLanding from './images/nuvem-chat-image-landing.png';
import NuvemChatImagePt from './images/nuvem-chat-image-pt.png';
import NuvemChatImageSmPt from './images/nuvem-chat-image-sm-pt.png';
import NuvemChatImageSm from './images/nuvem-chat-image-sm.png';
import NuvemChatImage from './images/nuvem-chat-image.png';
import { trackingNuvemChatInstallClick } from '../tracking';
import './NuvemChatPage.scss';

export function NuvemChatPage() {
  const { t } = useTranslation('nuvemchat');
  const language = useTranslationLanguage();
  const { isMobile } = useResponsive();

  const handleInstallApplicationClick = () => {
    const url = NUVEM_CHAT_APP_INSTALL_LINK[language];
    trackingNuvemChatInstallClick();
    openWindow(url, true);
  };

  const chatView = language === 'pt-BR' ? NuvemChatImagePt : NuvemChatImage;
  const chatViewSm =
    language === 'pt-BR' ? NuvemChatImageSmPt : NuvemChatImageSm;
  const responsiveSizes = isMobile
    ? {
        imgSrc: chatViewSm,
        boxMaxWidth: '500px',
      }
    : {
        imgSrc: chatView,
        boxMaxWidth: '600px',
        boxMaxHeight: '500px',
      };
  return (
    <InitialScreen>
      <InitialScreen.Hero
        image={
          <img
            src={NuvemChatImageLanding}
            alt="NuvemChat"
            width="100%"
            height="auto"
          />
        }
        title={t('hero.title')}
        subtitle={t('hero.subtitle').toUpperCase()}
        description={
          <Text fontSize="highlight" color="neutral-textHigh" textAlign="left">
            <Trans
              t={t}
              i18nKey="hero.description"
              components={{ bold: <strong /> }}
            />
          </Text>
        }
        actions={
          <Button appearance="primary" onClick={handleInstallApplicationClick}>
            {t('hero.button')}
          </Button>
        }
      />
      <InitialScreen.Section title={t('contentSection.title')}>
        <InitialScreen.Feature
          content={
            <>
              <InitialScreen.FeatureItem
                title={t('contentSection.content1.title')}
                description={t('contentSection.content1.description')}
              >
                <InitialScreen.FeatureItemSpacing />
              </InitialScreen.FeatureItem>

              <InitialScreen.FeatureItem
                title={t('contentSection.content2.title')}
                description={t('contentSection.content2.description')}
              >
                <InitialScreen.FeatureItemSpacing />
              </InitialScreen.FeatureItem>
              <InitialScreen.FeatureItem
                title={t('contentSection.content3.title')}
                description={t('contentSection.content3.description')}
              >
                <Button
                  onClick={handleInstallApplicationClick}
                  appearance="primary"
                >
                  {t('contentSection.button')}
                </Button>
              </InitialScreen.FeatureItem>
            </>
          }
          image={
            <Box
              maxWidth={responsiveSizes.boxMaxWidth}
              maxHeight={responsiveSizes.boxMaxHeight}
              borderRadius="6"
              borderColor="primary-interactive"
              borderWidth="4"
              borderStyle="solid"
            >
              <img
                src={responsiveSizes.imgSrc}
                alt="NuvemChatView"
                width="100%"
                style={{ borderRadius: '20px' }}
              />
            </Box>
          }
        />
      </InitialScreen.Section>
      <InitialScreen.Section title={t('plans.title')}>
        <NuvemChatPlans />
      </InitialScreen.Section>
    </InitialScreen>
  );
}
