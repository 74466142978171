import { v4 as uuidv4 } from 'uuid';
import { CategorySyncTreeRequestDto } from '@tiendanube/common';
import { VisibilityCategoryType } from '@tiendanube/common/src/domains/catalog/interfaces';
import { TreeData } from './hooks/useCategoriesTree/useCategoriesTreeNew';
import { CategoryNodeIdType, CategoryNodeType } from './types';

export const ID_ROOT = '0';

interface NewEmptyNodeProps {
  parentId?: CategoryNodeIdType;
  visibility?: VisibilityCategoryType;
}

export const newEmptyNode = ({
  parentId,
  visibility = 'visible',
}: NewEmptyNodeProps) => ({
  id: `new-${uuidv4()}`,
  parent: parentId || ID_ROOT,
  expanded: true,
  droppable: true,
  text: '',
  visibility,
});

export const decendents = (
  tree: CategoryNodeType[],
  id: CategoryNodeIdType,
) => {
  const children = tree.filter((current) => current.parent === id);
  const subChildren: CategoryNodeType[] = [];
  children.forEach((current) =>
    subChildren.push(...decendents(tree, current.id)),
  );
  return [...children, ...subChildren];
};

export const idDecendents = (
  tree: CategoryNodeType[],
  id: CategoryNodeIdType,
) => decendents(tree, id).map((current) => current.id);

export const hasSavedDecendents = (
  tree: CategoryNodeType[],
  id: CategoryNodeIdType,
) =>
  idDecendents(tree, id).some(
    (current) => !(current as string).startsWith('new-'),
  );

//for the validation of emojis that are not part of the Unicode BMP:
const emojiNotAllowedRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;

export const hasEmojisAllowed = (value: string) =>
  !value.match(emojiNotAllowedRegex);

export const findEmojiNotAllowed = (value: CategoryNodeType[]) =>
  value.some((current) => !hasEmojisAllowed(current.text));

export const validateEmojis = (value: string | undefined) => {
  if (!value) return true;
  return hasEmojisAllowed(value);
};

export const findById = (array: TreeData[], id: string): TreeData | null => {
  for (const item of array) {
    if (item.id === id) {
      return item;
    }
    if (item.children && item.children.length > 0) {
      const found = findById(item.children, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
};

export const hasParentVisible = (
  categories: CategorySyncTreeRequestDto[],
  id: string,
) => {
  const child = categories.find((current) => current.id === id);
  const parent =
    child && categories.find((current) => current.id === child.parent);

  return parent ? parent.visibility === 'visible' : false;
};

export const hasParent = (
  categories: CategorySyncTreeRequestDto[],
  id: string,
) => {
  const child = categories.find((current) => current.id === id);
  return child ? child.parent !== '0' : false;
};

export const parentVisibility = (
  categories: CategorySyncTreeRequestDto[],
  id: string,
) => {
  const child = categories.find((current) => current.id === id);
  const parent =
    child && categories.find((current) => current.id === child.parent);

  return parent?.visibility || 'visible';
};
