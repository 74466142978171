import { DataList, IconButton } from '@tiendanube/components';
import { DownloadIcon } from '@tiendanube/icons';
import { useTranslationBilling } from 'domains/Billing';
import { useDownloadExternalInvoice } from 'domains/Billing/Invoices/pages/InvoicesListPage/components/InvoiceHistory';

const { Cell } = DataList;

interface DataTableRowProps {
  invoiceId: string;
}

function RightCell({ invoiceId }: DataTableRowProps): JSX.Element {
  const t = useTranslationBilling();
  const { getLink } = useDownloadExternalInvoice();

  const handleDownload = () => {
    getLink(invoiceId);
  };

  return (
    <Cell alignRight>
      <IconButton
        ariaLabel={t('invoiceHistory.buttonArial')}
        icon={DownloadIcon}
        onClick={handleDownload}
      />
    </Cell>
  );
}

export default RightCell;
