import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';
import uniq from 'lodash.uniq';
import {
  EditOrderRequestDto,
  HasFundsAvailableNuvemPagoResponseDto,
  LogEntryResponseDto,
  OrderArchiveRequestDto,
  OrderBulkResponseDto,
  OrderCancelRequestDto,
  OrderDetailsResponseDto,
  OrderFulfillmentsDto,
  OrderFulfillmentUpdateRequestDto,
  OrderFulfillRequestDto,
  OrderPaymentRequestDto,
  OrderProductsResponseDto,
  OrderResponseDto,
  OrdersExportResponseDto,
  OrdersFiltersByTypeResponseDto,
  OrderShippingCostRequestDto,
  OrderShippingCostResponseDto,
  OrderUpdateResponseDto,
  PaginationResponseDto,
  PendingOrdersResponseDto,
} from '@tiendanube/common';
import { OrdersBouncedEmailsValuesResponseDto } from '@tiendanube/common/src/domains/orders/commons';
import {
  AggregationsResponseDto,
  OrdersListAggregationsResponseDto,
} from '@tiendanube/common/src/domains/orders/orders/OrderResponse';
import { Status } from '@tiendanube/common/src/enums';
import { RootStateType } from 'App/store';
import { StatusType } from 'commons/types';
import { logout } from 'domains/Auth/authSlice';
import {
  SavedSearchCountResponseDto,
  SavedSearchInterface,
} from 'domains/Orders/components/SavedSearches';
import { ORDERS_PER_PAGE } from 'domains/Orders/Orders/pages/constants';
import { trackingOrderMetafieldFilterError } from 'domains/Orders/tracking';
import ordersService, {
  defaultFilters,
  ExportFiltersType,
  FiltersParamsType,
  FilterStatusEnum,
  FiltersType,
  InterfaceOrderUpdateParams,
} from '../ordersService';
import { getOrderItemFromDetail } from '../utils';

type OrderEntitiesType = Record<
  string,
  OrderResponseDto & { productListStatus?: StatusType }
>;

type OrderEntitiesDetailsType = {
  data: OrderDetailsResponseDto | null;
  status: string;
  timeline: {
    status: StatusType;
    data: LogEntryResponseDto[];
  };
};

type ExportOrdersType = {
  status: string;
  downloadStatus: string;
  totalResults: number;
};

export type UpdateStatusMetafieldType = Record<string, StatusType>;

export type statusSuccessType =
  | 'idle'
  | 'success'
  | 'errorMetafield'
  | 'errorTimeout';

interface PendingOrdersEntity {
  status: StatusType;
  data: PendingOrdersResponseDto | null;
}

interface OrdersFiltersEntity {
  status: StatusType;
  data: OrdersFiltersByTypeResponseDto | null;
}
interface OrdersBouncedEmails {
  status: StatusType;
  data: OrdersBouncedEmailsValuesResponseDto[];
}

interface EditOrder {
  status: StatusType;
}

interface EditOrderShippingCosts {
  data: OrderShippingCostResponseDto[];
  statuses: { ffooId: string; status: StatusType }[];
}

interface SavedSearchesCount {
  data: Record<string, SavedSearchCountResponseDto>;
  status: StatusType;
}

export interface InterfaceOrdersSchema {
  entities: OrderEntitiesType;
  entityDetails: OrderEntitiesDetailsType;
  status: string;
  statusSuccess: string;
  updateOrderStatus: StatusType;
  newOrdersCount: number;
  currentRequestID: string;
  ids: string[];
  bulkOrders: OrderFulfillmentsDto[];
  bulkOrdersMissingTracking: number;
  pagination: PaginationResponseDto;
  appliedFilters: FiltersType;
  export: ExportOrdersType;
  totalResultsOpened: number;
  pendingOrders: PendingOrdersEntity;
  totalAllOrders: number;
  filtersByType: OrdersFiltersEntity;
  editOrder: EditOrder;
  bouncedEmails: OrdersBouncedEmails;
  editOrderShippingCosts: EditOrderShippingCosts;
  aggregations: AggregationsResponseDto;
  savedSearchesCount: SavedSearchesCount;
}

const paginationDefault = {
  currentPage: 1,
  totalPages: 1,
  totalResults: 0,
  perPage: ORDERS_PER_PAGE,
  nextPage: null,
};

const initialState: InterfaceOrdersSchema = {
  status: 'idle',
  statusSuccess: 'idle',
  updateOrderStatus: 'idle',
  currentRequestID: '',
  newOrdersCount: 0,
  entities: {},
  entityDetails: {
    status: 'idle',
    data: null,
    timeline: {
      data: [],
      status: 'idle',
    },
  },
  pagination: paginationDefault,
  appliedFilters: {} as FiltersType,
  export: {
    status: 'idle',
    downloadStatus: 'idle',
    totalResults: 0,
  },
  ids: [],
  bulkOrders: [],
  bulkOrdersMissingTracking: 0,
  totalResultsOpened: 0,
  totalAllOrders: 0,
  pendingOrders: {
    status: 'idle',
    data: null,
  },
  filtersByType: {
    status: 'idle',
    data: null,
  },
  editOrder: {
    status: 'idle',
  },
  bouncedEmails: {
    status: 'idle',
    data: [],
  },
  editOrderShippingCosts: {
    statuses: [],
    data: [],
  },
  aggregations: {},
  savedSearchesCount: {
    data: {},
    status: 'idle',
  },
};

export const fetchOrderById = createAsyncThunk<OrderDetailsResponseDto, string>(
  'orders/fetchOrderById',
  async (id) => {
    const data = await ordersService.fetchOrderById(id);
    return data;
  },
);

export const fetchTimelineById = createAsyncThunk<
  LogEntryResponseDto[],
  { id: string; setLoading?: boolean }
>('orders/fetchTimelineById', async ({ id }) => {
  const data = await ordersService.fetchTimeline(id);
  return data;
});

export const refreshOrderById = createAsyncThunk<
  OrderDetailsResponseDto,
  string
>('orders/refreshOrderById', async (id) => {
  const data = await ordersService.fetchOrderById(id);
  return data;
});

type FetchOrdersActionPayloadType = {
  data: OrdersListAggregationsResponseDto;
  filters: FiltersType;
};

type FetchBulkOrdersActionPayloadType = {
  data: OrderBulkResponseDto;
};

type FetchTotalOrdersActionPayloadType = {
  total: number;
  filters: ExportFiltersType;
};

type FetchTotalOrdersExportActionPayloadType = {
  data: OrdersListAggregationsResponseDto;
  filters: ExportFiltersType;
};

type ThunkStateType = { state: RootStateType };

type FetchOrdersActionRequestType = {
  filters: FiltersType;
  type: 'normal' | 'advanced';
};

const hasFilters = (filtersState: FiltersType) => {
  const filters: Partial<FiltersType> = { ...filtersState };
  // we remove default values to ensure no filters are applied
  delete filters.page;
  if (
    Number(filters.perPage) === 1 ||
    Number(filters.perPage) === ORDERS_PER_PAGE
  )
    delete filters.perPage;
  if (filters.status === Status.OPEN) delete filters.status;
  return Object.values(filters).some((value) => value !== '');
};

export const fetchOrders = createAsyncThunk<
  FetchOrdersActionPayloadType,
  FetchOrdersActionRequestType,
  ThunkStateType
>('orders/fetchOrders', async ({ filters, type }) => {
  if (type === 'normal') {
    const data = await ordersService.fetchOrders(filters);
    return { data, filters };
  }
  const data = await ordersService.fetchOrdersAdvanced(filters);
  return { data, filters };
});

export const fetchOrderProducts = createAsyncThunk<
  OrderProductsResponseDto,
  string
>('orders/fetchOrderProducts', async (orderId) =>
  ordersService.fetchOrderProducts(orderId),
);

export const fetchBulkOrders = createAsyncThunk<
  FetchBulkOrdersActionPayloadType,
  FiltersParamsType
>('orders/fetchBulkOrders', async (filters) => {
  const data = await ordersService.fetchBulkOrders(filters);
  return { data };
});

export const fetchTotalOrders = createAsyncThunk<
  FetchTotalOrdersActionPayloadType,
  ExportFiltersType,
  ThunkStateType
>('orders/fetchTotalOrders', async (filters) => {
  const total = await ordersService.fetchTotalOrders(filters);
  return { total, filters };
});

export const fetchSavedSearchesCountOrders = createAsyncThunk<
  SavedSearchCountResponseDto,
  SavedSearchInterface
>('orders/fetchSavedSearchesCountOrders', async (savedSearch) => {
  const total = await ordersService.fetchTotalOrders({
    ...defaultFilters,
    ...savedSearch.filters,
  });
  return { count: total };
});

export const fetchTotalAllOrders = createAsyncThunk(
  'orders/fetchTotalAllOrders',
  async () =>
    ordersService.fetchTotalOrders({
      ...defaultFilters,
      status: FilterStatusEnum.ALL,
    }),
);

export const fetchTotalOpenedOrders = createAsyncThunk(
  'orders/fetchTotalOpenedOrders',
  async () =>
    ordersService.fetchTotalOrders({
      ...defaultFilters,
      status: Status.OPEN,
      perPage: '1',
    }),
);

export const fetchTotalOrdersExport = createAsyncThunk<
  FetchTotalOrdersExportActionPayloadType,
  ExportFiltersType,
  ThunkStateType
>('orders/fetchTotalOrdersExport', async (filters) => {
  const data = await ordersService.fetchOrdersAdvanced({
    ...filters,
    page: 1,
    perPage: '1',
    location: '',
  });
  return { data, filters };
});

export const exportOrders = createAsyncThunk<
  OrdersExportResponseDto,
  ExportFiltersType,
  ThunkStateType
>('orders/exportOrders', async (filters) => {
  const data = await ordersService.exportOrders(filters);
  return data;
});

export const fetchMoreOrders = createAsyncThunk<
  FetchOrdersActionPayloadType,
  FetchOrdersActionRequestType,
  ThunkStateType
>('orders/fetchMoreOrders', async ({ filters, type }) => {
  if (type === 'normal') {
    const data = await ordersService.fetchOrders(filters);
    return { data, filters };
  }
  const data = await ordersService.fetchOrdersAdvanced(filters);
  return { data, filters };
});

export const updateOrders = createAsyncThunk<
  void,
  InterfaceOrderUpdateParams,
  ThunkStateType
>('orders/updateOrders', async (params) => {
  await ordersService.updateBulkOrders(params);
});

export const getOrdersFilters =
  createAsyncThunk<OrdersFiltersByTypeResponseDto>('orders/filters', () =>
    ordersService.getOrdersFilters(),
  );

/* Order Details actions */

export const archiveOrder = createAsyncThunk<
  OrderDetailsResponseDto,
  { id: string; payload: OrderArchiveRequestDto }
>('orders/archivebyid', async ({ id, payload }, { dispatch }) => {
  const data = await ordersService.fetchArchiveOrder(id, payload);
  dispatch(fetchTimelineById({ id }));
  return data;
});

/* Order Details actions */

export const cancelOrderById = createAsyncThunk<
  OrderDetailsResponseDto,
  { id: string; action: OrderCancelRequestDto }
>('orders/cancelById', async ({ id, action }, { dispatch }) => {
  const data = await ordersService.cancelById(id, action);
  dispatch(fetchTimelineById({ id }));
  return data;
});

/* Order Details actions */

export const openOrderById = createAsyncThunk<
  OrderUpdateResponseDto,
  { id: string; validateStock?: boolean; isActionFromOrdersList?: boolean }
>(
  'orders/openById',
  async ({ id, validateStock, isActionFromOrdersList }, { dispatch }) => {
    const data = await ordersService.openById(
      id,
      validateStock,
      isActionFromOrdersList,
    );
    dispatch(fetchTimelineById({ id }));
    return data;
  },
);

/* Order Details actions */

export const paidOrderById = createAsyncThunk<
  OrderDetailsResponseDto,
  OrderPaymentRequestDto
>(
  'orders/paidById',
  async (
    { id, gateway, gatewayMethod, gatewayName, isActionFromOrdersList },
    { dispatch },
  ) => {
    const data = await ordersService.paidById(
      id,
      gateway,
      gatewayMethod,
      gatewayName,
      isActionFromOrdersList,
    );
    dispatch(fetchTimelineById({ id }));
    return data;
  },
);

export const partiallyPaidOrderById = createAsyncThunk<
  OrderDetailsResponseDto,
  { id: string; method: string; name?: string }
>('orders/partiallyPaidById', async ({ id, method, name }, { dispatch }) => {
  const data = await ordersService.partiallyPaidById(id, method, name);
  dispatch(fetchTimelineById({ id }));
  return data;
});

/* Order Details actions */

export const remarkOrderById = createAsyncThunk<
  OrderDetailsResponseDto,
  { id: string; text: string }
>('orders/remarkById', async ({ id, text }, { dispatch }) => {
  const data = await ordersService.remarkById(id, text);
  dispatch(fetchTimelineById({ id }));
  return data;
});

/* Order Details actions */
export const packOrderById = createAsyncThunk<
  OrderDetailsResponseDto,
  {
    orderId: string;
    fulfillments: OrderFulfillmentUpdateRequestDto[];
    updateHistory?: boolean;
    isActionFromOrdersList?: boolean;
  }
>(
  'orders/packById',
  async (
    { orderId, fulfillments, updateHistory, isActionFromOrdersList },
    { dispatch },
  ) => {
    const data = await ordersService.packById(
      orderId,
      fulfillments,
      isActionFromOrdersList,
    );
    if (updateHistory) dispatch(fetchTimelineById({ id: orderId }));
    return data;
  },
);

export const fulfillOrderById = createAsyncThunk<
  OrderDetailsResponseDto,
  { orderId: string; action: OrderFulfillRequestDto; updateHistory?: boolean }
>(
  'orders/fulfillById',
  async ({ orderId, action, updateHistory }, { dispatch }) => {
    const data = await ordersService.fulfillById(orderId, action);
    if (updateHistory) dispatch(fetchTimelineById({ id: orderId }));
    return data;
  },
);

export const fetchHasAvailableFunds = createAsyncThunk<
  HasFundsAvailableNuvemPagoResponseDto,
  { amount: number; app: 'nuvempago' | 'pagonube' }
>('orders/fetchHasAvailableFunds', async ({ amount, app }) => {
  const data = await ordersService.fetchHasAvailableFunds(amount, app);
  return data;
});

const applyStatusSuccess = (
  state: InterfaceOrdersSchema,
  statusSuccess = 'idle',
) => {
  state.statusSuccess = statusSuccess;
  statusSuccess === 'errorMetafield' && trackingOrderMetafieldFilterError();
};

export const getPendingOrdersAction = createAsyncThunk(
  'orders/getPendingOrdersAction',
  ordersService.getPendingOrders,
);

export const editOrderById = createAsyncThunk<
  void,
  { id: string; editRequest: EditOrderRequestDto }
>('orders/editOrderById', async ({ id, editRequest }) =>
  ordersService.editOrder(id, editRequest),
);

export const getOrderBouncedEmails = createAsyncThunk<
  OrdersBouncedEmailsValuesResponseDto[],
  { id: string }
>('orders/bouncedEmails', async ({ id }) => {
  const data = await ordersService.getOrderBouncedEmails(id);
  return data;
});

export const getOrderShippingCost = createAsyncThunk<
  OrderShippingCostResponseDto,
  {
    id: string;
    shippingCostRequest: OrderShippingCostRequestDto;
    signal?: AbortSignal;
  }
>('orders/shippingCost', async ({ id, shippingCostRequest, signal }) => {
  const data = await ordersService.getOrderShippingCost(
    id,
    shippingCostRequest,
    signal,
  );
  return { ...data };
});

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    incrementNewOrders(state) {
      state.newOrdersCount = state.newOrdersCount + 1;
    },
    cleanEditOrderShippingCost(
      state,
      action: { payload: { fulfillmentOrderId?: string } },
    ) {
      state.editOrderShippingCosts.data =
        state.editOrderShippingCosts.data.filter(
          (sc) =>
            sc.fulfillmentOrderId !==
            (action.payload.fulfillmentOrderId ?? null),
        );
      state.editOrderShippingCosts.statuses =
        state.editOrderShippingCosts.statuses.filter(
          (s) => s.ffooId !== (action.payload.fulfillmentOrderId ?? ''),
        );
    },
    cleanUpDetails(state) {
      state.entityDetails = initialState.entityDetails;
    },
    cleanBulkOrdersIds(state) {
      state.bulkOrders = [];
    },
    cleanOrdersMissingTracking(state) {
      state.bulkOrdersMissingTracking = 0;
    },
    cleanUpdateOrderStatus(state) {
      state.updateOrderStatus = initialState.updateOrderStatus;
    },
    cleanExportOrders(state) {
      state.export.downloadStatus = 'idle';
    },
    cleanEditOrder(state) {
      state.editOrder.status = 'idle';
      state.editOrderShippingCosts.data = [];
      state.editOrderShippingCosts.statuses = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState;
      return state;
    });

    // Fetch Order Details

    builder.addCase(fetchOrderById.pending, (state) => {
      state.entityDetails.status = 'loading';
      state.entityDetails.data = null;
      return state;
    });

    builder.addCase(fetchOrderById.rejected, (state) => {
      state.entityDetails.status = 'error';
      state.entityDetails.data = null;
      return state;
    });

    builder.addCase(fetchOrderById.fulfilled, (state, action) => {
      state.entityDetails.data = action.payload;
      state.entityDetails.status = 'idle';
      return state;
    });

    // Fetch Order List
    builder.addCase(fetchOrders.pending, (state, action) => {
      state.status = 'loading';
      state.statusSuccess = 'idle';
      state.currentRequestID = action.meta.requestId;

      if (!isEqual(action.meta.arg, state.appliedFilters)) {
        state.entities = {};
        state.ids = [];
      }
    });
    builder.addCase(fetchOrders.rejected, (state, action) => {
      state.status = 'error';
      applyStatusSuccess(state, action?.error?.message);
    });
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      if (state.currentRequestID === action.meta.requestId) {
        state.newOrdersCount = 0;
        const ids: string[] = [];
        state.entities = action.payload.data.results.reduce((acc, order) => {
          acc[order.id] = order;
          const orderId = order.id.toString();
          ids.push(orderId);
          return acc;
        }, {} as OrderEntitiesType);
        state.ids = ids;
        state.pagination = action.payload.data.pagination;
        state.appliedFilters = action.payload.filters;
        if (action.payload.data.aggregations)
          state.aggregations = action.payload.data.aggregations;
        state.status = 'success';
        applyStatusSuccess(state, action.payload.data.status?.code);
        // Save complete orders (opened) only when service is called without filters.
        if (!hasFilters(action.meta.arg.filters))
          state.totalResultsOpened =
            action.payload.data.pagination.totalResults;
      }
    });

    // Fetch More Order List
    builder.addCase(fetchMoreOrders.pending, (state, action) => {
      state.status = 'loading';
      state.statusSuccess = 'idle';
      state.currentRequestID = action.meta.requestId;
    });
    builder.addCase(fetchMoreOrders.fulfilled, (state, action) => {
      if (state.currentRequestID === action.meta.requestId) {
        const ids: string[] = [];
        action.payload.data.results.forEach((order) => {
          state.entities[order.id] = order;
          ids.push(order.id.toString());
        });
        state.status = 'success';
        applyStatusSuccess(state, action.payload.data.status?.code);
        state.ids = uniq(state.ids.concat(ids));
        state.pagination = action.payload.data.pagination;
        state.appliedFilters = action.payload.filters;
      }
    });
    builder.addCase(fetchMoreOrders.rejected, (state) => {
      state.status = 'error';
    });

    /* Fetch Total Orders */
    builder
      .addCase(fetchTotalOrders.pending, (state, action) => {
        state.currentRequestID = action.meta.requestId;
        state.export.status = 'loading';
      })
      .addCase(fetchTotalOrders.rejected, (state) => {
        state.export.status = 'error';
      })
      .addCase(fetchTotalOrders.fulfilled, (state, action) => {
        if (state.currentRequestID === action.meta.requestId) {
          state.export.totalResults = action.payload.total;
          state.export.status = 'success';
        }
      });

    /* Fetch Total All Orders */
    builder.addCase(fetchTotalAllOrders.fulfilled, (state, action) => {
      state.totalAllOrders = Number(action.payload);
    });

    builder.addCase(fetchTotalOpenedOrders.fulfilled, (state, action) => {
      state.totalResultsOpened = Number(action.payload);
    });

    /* Fetch Total Orders Export */
    builder
      .addCase(fetchTotalOrdersExport.pending, (state, action) => {
        state.currentRequestID = action.meta.requestId;
        state.export.status = 'loading';
      })
      .addCase(fetchTotalOrdersExport.rejected, (state) => {
        state.export.status = 'error';
      })
      .addCase(fetchTotalOrdersExport.fulfilled, (state, action) => {
        if (state.currentRequestID === action.meta.requestId) {
          state.export.totalResults =
            action.payload.data.pagination.totalResults;
          if (action.payload.data.aggregations)
            state.aggregations = action.payload.data.aggregations;
          state.export.status = 'success';
        }
      });

    builder
      .addCase(fetchBulkOrders.pending, (state, action) => {
        state.bulkOrders = [];
        state.bulkOrdersMissingTracking = 0;
        state.status = 'loading';
        state.currentRequestID = action.meta.requestId;
      })
      .addCase(fetchBulkOrders.rejected, (state) => {
        state.bulkOrders = [];
        state.bulkOrdersMissingTracking = 0;
        state.status = 'error';
      })
      .addCase(fetchBulkOrders.fulfilled, (state, action) => {
        if (state.currentRequestID === action.meta.requestId) {
          state.newOrdersCount = 0;
          state.bulkOrders = action.payload.data.orders;
          state.bulkOrdersMissingTracking =
            action.payload.data.missingTrackingCount;
          state.status = 'success';
        }
      });

    builder.addCase(
      fetchSavedSearchesCountOrders.fulfilled,
      (state, action) => {
        const savedSearchCode = action.meta.arg.code;
        const { count } = action.payload;
        if (state.savedSearchesCount.data[savedSearchCode]) {
          state.savedSearchesCount.data[savedSearchCode].previousCount =
            state.savedSearchesCount.data[savedSearchCode].count;
          state.savedSearchesCount.data[savedSearchCode].count = count;
        } else {
          state.savedSearchesCount.data[savedSearchCode] = { count: count };
        }
        state.savedSearchesCount.status = 'success';
      },
    );
    builder.addCase(fetchSavedSearchesCountOrders.pending, (state) => {
      state.savedSearchesCount.status = 'loading';
    });
    builder.addCase(fetchSavedSearchesCountOrders.rejected, (state) => {
      state.savedSearchesCount.status = 'error';
    });

    builder.addCase(updateOrders.rejected, (state) => {
      state.bulkOrders = [];
      state.status = 'error';
    });

    builder.addCase(archiveOrder.fulfilled, (state, action) => {
      state.updateOrderStatus = 'success';
      state.entityDetails.data = action.payload;
      state.entities[action.meta.arg.id] = getOrderItemFromDetail(
        action.payload,
      );
      return state;
    });

    builder.addCase(archiveOrder.rejected, (state) => {
      state.updateOrderStatus = 'error';
    });

    builder.addCase(archiveOrder.pending, (state) => {
      state.updateOrderStatus = 'loading';
    });

    builder.addCase(cancelOrderById.fulfilled, (state, action) => {
      state.updateOrderStatus = 'success';
      state.entityDetails.data = action.payload;
      state.entities[action.meta.arg.id] = getOrderItemFromDetail(
        action.payload,
      );
      return state;
    });

    builder.addCase(cancelOrderById.rejected, (state) => {
      state.updateOrderStatus = 'error';
    });

    builder.addCase(cancelOrderById.pending, (state) => {
      state.updateOrderStatus = 'loading';
    });

    builder.addCase(openOrderById.fulfilled, (state, action) => {
      state.updateOrderStatus = 'success';
      state.entityDetails.data = action.payload;
      state.entities[action.meta.arg.id] = getOrderItemFromDetail(
        action.payload,
      );
      return state;
    });

    builder.addCase(openOrderById.rejected, (state) => {
      state.updateOrderStatus = 'error';
    });

    builder.addCase(openOrderById.pending, (state) => {
      state.updateOrderStatus = 'loading';
    });

    builder.addCase(paidOrderById.fulfilled, (state, action) => {
      state.updateOrderStatus = 'success';
      state.entityDetails.data = action.payload;
      state.entities[action.meta.arg.id] = getOrderItemFromDetail(
        action.payload,
      );
      return state;
    });

    builder.addCase(paidOrderById.rejected, (state) => {
      state.updateOrderStatus = 'error';
    });

    builder.addCase(paidOrderById.pending, (state) => {
      state.updateOrderStatus = 'loading';
    });

    builder.addCase(partiallyPaidOrderById.fulfilled, (state, action) => {
      state.updateOrderStatus = 'success';
      state.entityDetails.data = action.payload;
      state.entities[action.meta.arg.id] = getOrderItemFromDetail(
        action.payload,
      );
      return state;
    });

    builder.addCase(partiallyPaidOrderById.rejected, (state) => {
      state.updateOrderStatus = 'error';
    });

    builder.addCase(partiallyPaidOrderById.pending, (state) => {
      state.updateOrderStatus = 'loading';
    });

    builder.addCase(remarkOrderById.fulfilled, (state, action) => {
      state.entityDetails.data = action.payload;
      state.entities[action.meta.arg.id] = getOrderItemFromDetail(
        action.payload,
      );
      return state;
    });

    builder.addCase(packOrderById.fulfilled, (state, action) => {
      state.updateOrderStatus = 'success';
      state.entityDetails.data = action.payload;
      state.entities[action.meta.arg.orderId] = getOrderItemFromDetail(
        action.payload,
      );
      return state;
    });

    builder.addCase(packOrderById.rejected, (state) => {
      state.updateOrderStatus = 'error';
    });

    builder.addCase(packOrderById.pending, (state) => {
      state.updateOrderStatus = 'loading';
    });

    builder.addCase(fulfillOrderById.fulfilled, (state, action) => {
      state.updateOrderStatus = 'success';
      state.entityDetails.data = action.payload;
      state.entities[action.meta.arg.orderId] = getOrderItemFromDetail(
        action.payload,
      );
      return state;
    });

    builder.addCase(fulfillOrderById.rejected, (state) => {
      state.updateOrderStatus = 'error';
    });

    builder.addCase(fulfillOrderById.pending, (state) => {
      state.updateOrderStatus = 'loading';
    });

    builder.addCase(refreshOrderById.fulfilled, (state, action) => {
      state.entityDetails.data = action.payload;
      state.entityDetails.status = 'idle';
      return state;
    });

    builder
      .addCase(getPendingOrdersAction.pending, (state) => {
        state.pendingOrders.status = 'loading';
      })
      .addCase(getPendingOrdersAction.rejected, (state) => {
        state.pendingOrders.status = 'error';
      })
      .addCase(getPendingOrdersAction.fulfilled, (state, action) => {
        state.pendingOrders.status = 'success';
        state.pendingOrders.data = action.payload;
      });

    builder.addCase(fetchTimelineById.pending, (state, action) => {
      if (action.meta.arg.setLoading) {
        state.entityDetails.timeline.status = 'loading';
      }
    });

    builder.addCase(fetchTimelineById.rejected, (state) => {
      state.entityDetails.timeline.status = 'error';
    });

    builder.addCase(fetchTimelineById.fulfilled, (state, { payload }) => {
      state.entityDetails.timeline.status = 'success';
      state.entityDetails.timeline.data = payload;
    });

    builder.addCase(fetchOrderProducts.pending, (state, action) => {
      state.entities[action.meta.arg].productListStatus = 'loading';
    });

    builder.addCase(fetchOrderProducts.fulfilled, (state, action) => {
      state.entities[action.meta.arg].productListStatus = 'success';
      state.entities[action.meta.arg].products = action.payload.products;
    });

    builder.addCase(fetchOrderProducts.rejected, (state, action) => {
      state.entities[action.meta.arg].productListStatus = 'error';
    });

    builder
      .addCase(exportOrders.pending, (state) => {
        state.export.downloadStatus = 'loading';
      })
      .addCase(exportOrders.rejected, (state) => {
        state.export.downloadStatus = 'error';
      })
      .addCase(exportOrders.fulfilled, (state) => {
        state.export.downloadStatus = 'success';
      });

    /* Fetch Orders Filters */
    builder.addCase(getOrdersFilters.pending, (state) => {
      state.filtersByType.status = 'loading';
    });

    builder.addCase(getOrdersFilters.rejected, (state) => {
      state.filtersByType.status = 'error';
    });

    builder.addCase(getOrdersFilters.fulfilled, (state, action) => {
      state.filtersByType.status = 'success';
      state.filtersByType.data = action.payload;
    });

    builder.addCase(editOrderById.pending, (state) => {
      state.editOrder.status = 'loading';
    });

    builder.addCase(editOrderById.rejected, (state) => {
      state.editOrder.status = 'error';
    });

    builder.addCase(editOrderById.fulfilled, (state) => {
      state.editOrder.status = 'success';
    });

    builder.addCase(getOrderBouncedEmails.rejected, (state) => {
      state.bouncedEmails.status = 'error';
    });

    builder.addCase(getOrderBouncedEmails.pending, (state) => {
      state.bouncedEmails.status = 'loading';
    });

    builder.addCase(getOrderBouncedEmails.fulfilled, (state, action) => {
      state.bouncedEmails.status = 'success';
      state.bouncedEmails.data = action.payload;
    });

    builder.addCase(getOrderShippingCost.pending, (state, action) => {
      const fulfillmentId =
        action.meta.arg.shippingCostRequest.fulfillmentOrderId ?? '';
      const fulfillmentStatus = state.editOrderShippingCosts.statuses.find(
        (s) => s.ffooId === fulfillmentId,
      );
      if (fulfillmentStatus) {
        fulfillmentStatus.status = 'loading';
      } else {
        state.editOrderShippingCosts.statuses.push({
          ffooId: fulfillmentId,
          status: 'loading',
        });
      }
    });

    builder.addCase(getOrderShippingCost.rejected, (state, action) => {
      const fulfillmentId =
        action.meta.arg.shippingCostRequest.fulfillmentOrderId ?? '';
      const fulfillmentStatus = state.editOrderShippingCosts.statuses.find(
        (s) => s.ffooId === fulfillmentId,
      );

      if (action.error.message === 'canceled') {
        return;
      }

      if (fulfillmentStatus) {
        fulfillmentStatus.status = 'error';
      } else {
        state.editOrderShippingCosts.statuses.push({
          ffooId: fulfillmentId,
          status: 'error',
        });
      }
    });

    builder.addCase(getOrderShippingCost.fulfilled, (state, action) => {
      const fulfillmentId =
        action.meta.arg.shippingCostRequest.fulfillmentOrderId ?? '';
      const fulfillmentStatus = state.editOrderShippingCosts.statuses.find(
        (s) => s.ffooId === fulfillmentId,
      );
      if (fulfillmentStatus) {
        fulfillmentStatus.status = 'success';
      } else {
        state.editOrderShippingCosts.statuses.push({
          ffooId: fulfillmentId,
          status: 'success',
        });
      }
      const existingCostIndex = state.editOrderShippingCosts.data.findIndex(
        (shippingCost) =>
          shippingCost.fulfillmentOrderId === action.payload.fulfillmentOrderId,
      );
      if (existingCostIndex >= 0) {
        state.editOrderShippingCosts.data[existingCostIndex] = action.payload;
      } else {
        state.editOrderShippingCosts.data.push(action.payload);
      }
    });
  },
});

export const { reducer } = ordersSlice;

export const {
  cleanUpDetails,
  cleanEditOrderShippingCost,
  incrementNewOrders,
  cleanBulkOrdersIds,
  cleanOrdersMissingTracking,
  cleanUpdateOrderStatus,
  cleanExportOrders,
  cleanEditOrder,
} = ordersSlice.actions;
