import {
  MIGRATION_ORDERS,
  MIGRATION_ORDER,
  MIGRATION_ABANDONED_CARTS,
  MIGRATION_ORDERS_EXPORT,
  MIGRATION_OLD_PRODUCTS_IMPORT,
  MIGRATION_OLD_PRODUCT_ORDER,
  MIGRATION_SOCIAL_NETWORKS,
  MIGRATION_OLD_DASHBOARD,
  MIGRATION_OLD_CATEGORIES,
  MIGRATION_OLD_COUPONS,
  MIGRATION_OLD_CUSTOMERS,
  MIGRATION_OLD_DRAFT_ORDERS,
  MIGRATION_OLD_MESSAGES,
  MIGRATION_OLD_CONTENT_PAGES,
  MIGRATION_OLD_SEO_REDIRECTS,
  MIGRATION_OLD_WHATSAPP,
  MIGRATION_OLD_PASSWORD_PROTECTED,
  MIGRATION_OLD_MARKETING_APPS,
  MIGRATION_OLD_SALES_CHANNELS_APPS,
  MIGRATION_OLD_DOMAINS,
  MIGRATION_OLD_APPS,
  MIGRATION_OLD_INFO_MESSAGE,
  MIGRATION_OLD_PREFERENCES_ADVANCED,
  MIGRATION_OLD_NOTIFICATIONS,
  MIGRATION_OLD_I18N,
  MIGRATION_OLD_MENUS,
  MIGRATION_OLD_FREE_SHIPPING,
  MIGRATION_OLD_PROMOTIONS,
  MIGRATION_OLD_USERS,
  MIGRATION_OLD_CONTENT_SETTINGS_CHECKOUT,
  MIGRATION_OLD_MARKETPLACES_APPS,
  MIGRATION_OLD_THEMES,
  MIGRATION_OLD_FACEBOOK,
  MIGRATION_OLD_PAYMENTS,
} from './featuresFlags';

/**
 * Routes Maping
 * new-admin (without slash): old-admin
 */
export const ROUTE_MAPPING = {
  orders: 'orders',
  'orders-detail': 'orders/:id',
  products: 'products',
  'products-detail': 'products/:id',
  productsnew: 'products/new',
  'free-shipping': 'free-shipping',
  promotions: 'marketing/promotions',
  coupons: 'marketing/discounts',
  'draft-orders': 'draft_orders',
  'abandoned-carts': 'abandoned_carts',
  customers: 'customers',
  messages: 'contacts',
  categories: 'categories',
  ordersexport: 'orders/download',
  productsimport: 'products/import',
  productsorganize: 'products/product_order',
  stats: 'stats',
  'accountsocial-networks': 'preferences/social_networks',
  'settingsshipping-methods': 'shipping',
  settingspayments: 'payments',
  dashboard: '',
  'password-protected': 'themes/settings/password_protected',
  'content-pages': 'pages',
  'marketing-apps': 'marketing/marketing_apps',
  'sales-channels-apps': 'sales_channel/sales_channel_apps',
  'marketplaces-apps': 'sales_channel/meli_apps',
  i18n: 'i18n',
  'seo-redirects': 'preferences/advanced/seo_redirects',
  'apps-list': 'apps',
  navigation: 'navigation',
  whatsapp: 'whatsapp',
  'settings-checkout': 'preferences/checkout',
  users: 'preferences/users',
  domains: 'preferences/domains',
  'info-message': 'info_message',
  'external-codes': 'preferences/advanced',
  notifications: 'notifications',
  themes: 'themes',
  facebook: 'facebook',
  instagram: 'sales_channel/instagram',
};

/**
 * Relation tags with routes
 * new-admin (without slash): tags
 */
export const TAGS_MIGRATE = {
  orders: MIGRATION_ORDERS,
  'orders-detail': MIGRATION_ORDER,
  'abandoned-carts': MIGRATION_ABANDONED_CARTS,
  ordersexport: MIGRATION_ORDERS_EXPORT,
  'accountsocial-networks': MIGRATION_SOCIAL_NETWORKS,
};

export const TAGS_MIGRATE_OLD = {
  categories: MIGRATION_OLD_CATEGORIES,
  customers: MIGRATION_OLD_CUSTOMERS,
  messages: MIGRATION_OLD_MESSAGES,
  coupons: MIGRATION_OLD_COUPONS,
  'draft-orders': MIGRATION_OLD_DRAFT_ORDERS,
  productsimport: MIGRATION_OLD_PRODUCTS_IMPORT,
  productsorganize: MIGRATION_OLD_PRODUCT_ORDER,
  'password-protected': MIGRATION_OLD_PASSWORD_PROTECTED,
  'marketing-apps': MIGRATION_OLD_MARKETING_APPS,
  'sales-channels-apps': MIGRATION_OLD_SALES_CHANNELS_APPS,
  'content-pages': MIGRATION_OLD_CONTENT_PAGES,
  i18n: MIGRATION_OLD_I18N,
  navigation: MIGRATION_OLD_MENUS,
  'free-shipping': MIGRATION_OLD_FREE_SHIPPING,
  promotions: MIGRATION_OLD_PROMOTIONS,
  users: MIGRATION_OLD_USERS,
  'settings-checkout': MIGRATION_OLD_CONTENT_SETTINGS_CHECKOUT,
  dashboard: MIGRATION_OLD_DASHBOARD,
  'seo-redirects': MIGRATION_OLD_SEO_REDIRECTS,
  whatsapp: MIGRATION_OLD_WHATSAPP,
  'info-message': MIGRATION_OLD_INFO_MESSAGE,
  'marketplaces-apps': MIGRATION_OLD_MARKETPLACES_APPS,
  notifications: MIGRATION_OLD_NOTIFICATIONS,
  domains: MIGRATION_OLD_DOMAINS,
  themes: MIGRATION_OLD_THEMES,
  'apps-list': MIGRATION_OLD_APPS,
  'external-codes': MIGRATION_OLD_PREFERENCES_ADVANCED,
  facebook: MIGRATION_OLD_FACEBOOK,
  settingspayments: MIGRATION_OLD_PAYMENTS,
};
