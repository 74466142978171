import { useState } from 'react';
import {
  ImportColumnsResponseDto,
  ImportFileRequestDto,
  ImportFileResponseDto,
} from '@tiendanube/common';
import { trackingCsvImportClick } from 'App/tracking';
import { useModal } from 'commons/hooks';
import { useImportFile } from '../useImportFile';
import { useLoadCsvFileAndCompareColumns } from '../useLoadCsvFileAndCompareColumns';

type UpdateOptionsCatalogType = {
  updateStock: boolean;
  selectedLocation: string;
};

type UpdateOptionsCustomersType = {
  acceptLegalRequirements: boolean;
};

export type UpdateOptionsType = UpdateOptionsCatalogType &
  UpdateOptionsCustomersType & {
    updateExisting: boolean;
  };

type ImportColumnsHandler = () => Promise<ImportColumnsResponseDto>;
type ImportFileHandler = (
  params: ImportFileRequestDto,
) => Promise<ImportFileResponseDto>;
type ImportJobKey = 'productsCsvImport' | 'customersCsvImport';

export function useImportCsv(
  importJobKey: ImportJobKey,
  importFileHandler: ImportFileHandler,
  importColumnsHandler: ImportColumnsHandler,
  trackingIgnoreAllColumns?: () => void,
) {
  const defaultUpdateOptions: UpdateOptionsType = {
    updateExisting: true,
    updateStock: true,
    selectedLocation: 'all',
    acceptLegalRequirements: false,
  };
  const [updateOptions, setUpdateOptions] =
    useState<UpdateOptionsType>(defaultUpdateOptions);
  const [
    showImportAlterationsModal,
    openShowImportAlterationsModal,
    closeShowImportAlterationsModal,
  ] = useModal();
  const [showExistingModal, openShowExistingModal, closeShowExistingModal] =
    useModal();
  const {
    onChangeFile,
    onRemoveFile,
    onRetry,
    status,
    fileName,
    badColumns,
    columnOptions,
    matchColumns,
    setMatchingColumns,
    ignoreAllColumns,
    undoIgnoreAllColumns,
    file,
    fileColumns,
    isLoading: isLoadingCsv,
    isError: isErrorCsv,
  } = useLoadCsvFileAndCompareColumns(
    importColumnsHandler,
    trackingIgnoreAllColumns,
  );
  const {
    sendFile,
    isLoading: isLoadingImport,
    isError: isErrorImport,
    jobStatus,
    cleanJob,
  } = useImportFile(importJobKey, importFileHandler);

  const onUpdateOptionsChange = (prop: string, value: boolean | string) =>
    setUpdateOptions((options) => ({
      ...options,
      [prop]: value,
    }));

  const onCheckExisting = (): void => {
    if (!updateOptions.updateExisting) {
      openShowExistingModal();
    } else {
      onConfirm();
    }
  };

  const onConfirm = (): void => {
    closeShowExistingModal();
    openShowImportAlterationsModal();
  };

  const onImport = async () => {
    trackingCsvImportClick(importJobKey);
    closeShowImportAlterationsModal();
    const columns = fileColumns
      .map((key) =>
        ignoreColumn(matchColumns[key]) ? 'ignore' : matchColumns[key],
      )
      .join(',');
    const result = await sendFile({
      file,
      columns,
      replace: updateOptions.updateExisting,
      columnsChanged: !!badColumns.length,
    });
    if (result) {
      onRemoveFile();
      setUpdateOptions(defaultUpdateOptions);
    }
  };

  const ignoreColumn = (column: string) => {
    const { updateExisting, updateStock, selectedLocation } = updateOptions;
    if (updateExisting && column.match(/stock.*/)) {
      const ignoreLocation =
        selectedLocation !== 'all' && column !== `stock_${selectedLocation}`;
      return !updateStock || ignoreLocation;
    }
    return false;
  };

  return {
    updateOptions,
    showImportAlterationsModal,
    showExistingModal,
    jobStatus,
    cleanJob,
    onChangeFile,
    onRemoveFile,
    onRetry,
    status,
    fileName,
    badColumns,
    columnOptions,
    matchColumns,
    setMatchingColumns,
    ignoreAllColumns,
    undoIgnoreAllColumns,
    isLoadingCsv,
    isErrorCsv,
    isLoadingImport,
    isErrorImport,
    onUpdateOptionsChange,
    onCheckExisting,
    onConfirm,
    onImport,
    closeShowImportAlterationsModal,
    closeShowExistingModal,
  };
}
