import { IconButton, Tooltip } from '@nimbus-ds/components';
import {
  CurrencyType,
  OrderDetailsAmountsDto,
  OrderDetailsProductDto,
  RefundAttemptDto,
} from '@tiendanube/common';
import { OrderDetailsTaxesResponseDto } from '@tiendanube/common/src/domains/orders/orders/OrderDetailsResponse';
import { BaseCard, DataList, Stack, Title } from '@tiendanube/components';
import { PrinterIcon } from '@tiendanube/icons';
import { useResponsive } from 'commons/components';
import { DocumentType } from 'domains/FulfillmentOrders/pages/PrintDocumentPage/constants';
import { useGoToPrintPage } from 'domains/FulfillmentOrders/pages/PrintDocumentPage/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import OrderSummaryAmounts from './OrderSummaryAmounts';
import OrderSummaryProducts from './OrderSummaryProducts';

interface OrderSummaryCardProps {
  products: OrderDetailsProductDto[];
  amounts: OrderDetailsAmountsDto;
  taxes?: OrderDetailsTaxesResponseDto;
  currency: CurrencyType;
  receiptId?: string;
  billingAddressCountry?: string;
  paymentMethod?: string;
  refundAttempts?: RefundAttemptDto[];
}

function OrderSummaryCard({
  products,
  amounts,
  taxes,
  currency,
  receiptId,
  billingAddressCountry,
  paymentMethod,
  refundAttempts = [],
}: OrderSummaryCardProps): JSX.Element {
  const t = useTranslationOrders();
  const { isDesktop } = useResponsive();
  const { goToPrintPage } = useGoToPrintPage();

  const totalProducts = products.reduce(
    (total, product) => total + product.quantity,
    0,
  );

  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack justify="space-between">
          <Title type="h4">
            {`${totalProducts} ${t('orderSummaryCard.productInOrder', {
              count: totalProducts,
            })}`}
          </Title>
          {!!receiptId && isDesktop && (
            <Tooltip content={t('orderSummaryCard.printReceipt')}>
              <IconButton
                size="2rem"
                source={<PrinterIcon />}
                onClick={() =>
                  goToPrintPage({
                    ids: [receiptId],
                    type: DocumentType.RECEIPT,
                  })
                }
              />
            </Tooltip>
          )}
        </Stack>
      </BaseCard.Header>
      <DataList ruled={false} spacing="tight">
        <OrderSummaryProducts products={products} currency={currency} />
        <OrderSummaryAmounts
          taxes={taxes}
          billingAddressCountry={billingAddressCountry}
          refundAttempts={refundAttempts}
          amounts={amounts}
          currency={currency}
          totalProducts={totalProducts}
          paymentMethod={paymentMethod}
        />
      </DataList>
    </BaseCard>
  );
}

export default OrderSummaryCard;
