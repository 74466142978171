import { Redirect, Route } from 'react-router-dom';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { useGetTags } from 'domains/Auth/hooks';
import { Users } from 'domains/Configurations/Users';
import { UserFormPage } from 'domains/Configurations/Users/pages';
import { THEMES_ROUTES } from 'domains/Online/Themes/themesRoutes';
import { ACCOUNT_ROUTES } from './accountRoutes';
import {
  BusinessDataPage,
  ShopCancellationPage,
  CanceledStorePage,
  SocialNetworksPage,
} from './pages';
import AuthenticationFactorPage from './pages/AuthenticationFactorPage';
import SessionManagementPage from './pages/SessionManagementPage';

function Account(): JSX.Element {
  const { validateRoles } = useRoles();
  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);

  if (!validateRoles('full'))
    return (
      <>
        <Route exact path={ACCOUNT_ROUTES.authenticationFactor}>
          <AuthenticationFactorPage />
        </Route>
        <Route exact path={ACCOUNT_ROUTES.sessionManagement}>
          <SessionManagementPage />
        </Route>
        <Route path={ACCOUNT_ROUTES.userForm}>
          <UserFormPage />
        </Route>
      </>
    );

  return (
    <>
      {isRevampMenu ? (
        <Route exact path={ACCOUNT_ROUTES.socialNetworks}>
          <Redirect to={THEMES_ROUTES.socialNetworks} />
        </Route>
      ) : (
        <Route exact path={ACCOUNT_ROUTES.socialNetworks}>
          <SocialNetworksPage />
        </Route>
      )}
      <Route exact path={ACCOUNT_ROUTES.businessData}>
        <BusinessDataPage />
      </Route>
      <Route exact path={ACCOUNT_ROUTES.shopCancellation}>
        <ShopCancellationPage />
      </Route>
      <Route exact path={ACCOUNT_ROUTES.authenticationFactor}>
        <AuthenticationFactorPage />
      </Route>
      <Route exact path={ACCOUNT_ROUTES.sessionManagement}>
        <SessionManagementPage />
      </Route>
      <Route path={ACCOUNT_ROUTES.canceledStore}>
        <CanceledStorePage />
      </Route>
      <Route path={ACCOUNT_ROUTES.users}>
        <Users />
      </Route>
    </>
  );
}

export default Account;
