import { useEffect } from 'react';
import { BaseCard, Title } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import { CurrencyPrice } from 'commons/components';
import Link from 'commons/components/LegacyLink';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
import { TRANSACTION_FEES_ROUTES } from 'domains/Billing/TransactionFees/transactionFeesRoutes';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import TransactionFeePaidCardSkeleton from './TransactionFeePaidCardSkeleton';
import { useTransactionFeePaid } from '../../hooks';
import TransactionFeesErrorState from '../TransactionFeesErrorState';

function TransactionFeePaidCard(): JSX.Element {
  const t = useTranslationBilling();
  const {
    fetchTransactionFeePaid,
    transactionFeePaid,
    isLoading,
    isSuccess,
    isError,
  } = useTransactionFeePaid();
  const { goTo } = useNavegate();

  useEffect(fetchTransactionFeePaid, [fetchTransactionFeePaid]);

  const goToDetail = () =>
    goTo(`${TRANSACTION_FEES_ROUTES.transactionFeesSplitList}`);

  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack spacing="base" column align="flex-start">
          <Title type="h3">{`${t(
            'transactionFeesPage.transactionFeePaidCard.title',
          )}`}</Title>
        </Stack>
      </BaseCard.Header>
      {isLoading && <TransactionFeePaidCardSkeleton />}
      {isSuccess &&
        transactionFeePaid &&
        transactionFeePaid.hasCurrentMonthSplitPaidOrders && (
          <>
            <BaseCard.Body>
              <Stack spacing="base" column align="flex-start">
                <Text>
                  {t('transactionFeesPage.transactionFeePaidCard.subtitle')}
                </Text>
                <Text>{transactionFeePaid.paidFromDescription}</Text>
                <CurrencyPrice
                  size="featured"
                  price={
                    transactionFeePaid.currentMonthSplitPaidOrdersCostNumber ||
                    0
                  }
                />
              </Stack>
            </BaseCard.Body>
            <BaseCard.Footer>
              <Link onClick={goToDetail} appearance="primary" underline>
                {t('transactionFeesPage.transactionFeePaidCard.moreDetails')}
              </Link>
            </BaseCard.Footer>
          </>
        )}
      {isSuccess &&
        (!transactionFeePaid ||
          !transactionFeePaid.hasCurrentMonthSplitPaidOrders) && (
          <BaseCard.Body>
            <Text size="highlight">
              {t('transactionFeesPage.transactionFeePaidCard.emptyMessage')}
            </Text>
          </BaseCard.Body>
        )}
      {isError && (
        <TransactionFeesErrorState onClick={fetchTransactionFeePaid} />
      )}
    </BaseCard>
  );
}

export default TransactionFeePaidCard;
