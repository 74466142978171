import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  MetafieldInterface,
  MetafieldToBeChanged,
  OnMultipleChangeMetafieldType,
  SourceType,
} from 'domains/Metafields/types';

enum FormActionsEnum {
  SHOW = 'show',
  HIDE = 'hide',
  DISABLE = 'disable',
  ENABLE = 'enable',
  SET = 'set',
}

interface FormAction {
  actionName: FormActionsEnum;
  value?: string;
}

interface ApplyFormAction {
  id: string;
  actions: FormAction[];
  value?: string;
}

interface MetafieldsFormContextProps {
  formState: MetafieldInterface[];
  applyFormActions: (formActions: ApplyFormAction[]) => void;
}

export const MetafieldsFormContext = createContext({
  formState: [],
  applyFormActions: () => undefined,
} as MetafieldsFormContextProps);

interface MetafieldsFormProviderProps {
  children: ReactNode;
  metafields: MetafieldInterface[];
  source: SourceType;
  onMultipleChange: OnMultipleChangeMetafieldType;
}

export default function MetafieldsFormProvider({
  children,
  metafields,
  source,
  onMultipleChange,
}: Readonly<MetafieldsFormProviderProps>) {
  const [formState, setFormState] = useState(metafields || []);

  const applyFieldAction = useCallback(
    (action: FormAction, metafield) => {
      const { actionName } = action;
      const changes: MetafieldToBeChanged[] = [];

      switch (actionName) {
        case FormActionsEnum.DISABLE:
        case FormActionsEnum.ENABLE:
          metafield.disabled = actionName === FormActionsEnum.DISABLE;
          break;
        case FormActionsEnum.HIDE:
        case FormActionsEnum.SHOW:
          if (!metafield.formFieldConfig) break;
          metafield.formFieldConfig.hidden =
            actionName === FormActionsEnum.HIDE;
          break;
        case FormActionsEnum.SET:
          changes.push({
            id: metafield.id,
            source,
            value: action.value || '',
          });
          break;
        default:
      }

      return {
        metafield: { ...metafield },
        changes,
      };
    },
    [source],
  );

  const applyFormActions = useCallback(
    (formActions: ApplyFormAction[]) => {
      const stateCopy = [...formState];
      const metafieldsChanges: MetafieldToBeChanged[] = [];

      formActions.forEach((formAction) => {
        const { id, actions } = formAction;
        const index = stateCopy.findIndex((metafield) => metafield.key === id);

        const metafieldFound = { ...stateCopy[index] };

        if (metafieldFound) {
          for (const action of actions) {
            const { metafield, changes } = applyFieldAction(
              action,
              metafieldFound,
            );

            stateCopy[index] = metafield;
            if (changes.length) {
              metafieldsChanges.push(...changes);
            }
          }
        }
      });

      if (metafieldsChanges.length) {
        onMultipleChange(metafieldsChanges);
      }
      setFormState(stateCopy);
    },
    [formState, onMultipleChange, applyFieldAction],
  );

  const providerValue = useMemo(
    () => ({
      formState,
      applyFormActions,
    }),
    [formState, applyFormActions],
  );

  return (
    <MetafieldsFormContext.Provider value={providerValue}>
      {children}
    </MetafieldsFormContext.Provider>
  );
}
