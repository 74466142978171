import { useState, useEffect } from 'react';
import {
  InteractiveListPriv,
  Modal,
  Stack,
  Text,
  Link,
} from '@tiendanube/components';
import { EditIcon } from '@tiendanube/icons';
import { CancelAndSaveButtons } from 'commons/components';
import { useAsyncFunc, useToast } from 'commons/hooks';
import goToAdmin from 'commons/utils/gotToAdmin';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { AbandonedCartEmalConfg } from 'domains/Orders/AbandonedCart/abandonedCartsSlice';
import { useAbandonedCartConfigEmail } from 'domains/Orders/AbandonedCart/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface ConfigEmailModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function AbandonedCartsConfigEmailModal({
  isOpen,
  onClose,
}: ConfigEmailModalProps) {
  const t = useTranslationOrders();
  const { addToast } = useToast();
  const isMobileDevice = useIsMobileDevice();
  const {
    abandonedCartEmailConfig,
    updateEmailConfig,
    isLoading,
    isAutomaticOptionAllowed,
  } = useAbandonedCartConfigEmail();
  const [handleOnSave] = useAsyncFunc(
    async () => {
      await updateEmailConfig(config);
      onClose();
    },
    () =>
      addToast({
        label: t('abandonedCarts.emailConfigModal.toast.success'),
        appearance: 'success',
      }),
    () =>
      addToast({
        label: t('abandonedCarts.emailConfigModal.toast.error'),
        appearance: 'danger',
      }),
  );

  const [config, setConfig] = useState<AbandonedCartEmalConfg>(
    abandonedCartEmailConfig,
  );

  const handleOnSelect = ({ name }) => {
    setConfig(name);
  };

  const handleOnClose = () => {
    onClose();
    setConfig(abandonedCartEmailConfig);
  };

  useEffect(() => {
    setConfig(abandonedCartEmailConfig);
  }, [abandonedCartEmailConfig]);

  return (
    <Modal
      show={isOpen}
      title={t('abandonedCarts.emailConfigModal.title')}
      onDismiss={handleOnClose}
    >
      <Stack column align="stretch">
        <Stack.Item fill>
          <Text>{t('abandonedCarts.emailConfigModal.description')}</Text>
        </Stack.Item>
        <Stack.Item fill>
          <InteractiveListPriv>
            <InteractiveListPriv.RadioItem
              title={t('abandonedCarts.emailConfigModal.automatic.label')}
              checked={config === 'automatic'}
              onChange={handleOnSelect}
              value="automatic"
              description={t(
                'abandonedCarts.emailConfigModal.automatic.description',
              )}
              disabled={!isAutomaticOptionAllowed}
            />
            <InteractiveListPriv.RadioItem
              title={t('abandonedCarts.emailConfigModal.manual.label')}
              checked={config === 'manual'}
              onChange={handleOnSelect}
              value="manual"
              description={t(
                'abandonedCarts.emailConfigModal.manual.description',
              )}
            />
          </InteractiveListPriv>
        </Stack.Item>
        {!isMobileDevice && (
          <Stack.Item fill>
            <Link
              onClick={goToAdmin('notifications')}
              icon={EditIcon}
              iconPosition="end"
              appearance="primary"
            >
              {t('abandonedCarts.emailConfigModal.link')}
            </Link>
          </Stack.Item>
        )}
        <Stack.Item fill>
          <CancelAndSaveButtons
            onCancel={handleOnClose}
            onSave={handleOnSave}
            isLoading={isLoading}
            isDisabled={isLoading}
          />
        </Stack.Item>
      </Stack>
    </Modal>
  );
}

export default AbandonedCartsConfigEmailModal;
