import { Text, Title } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { generatePath } from 'react-router';
import { Button } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import { Stack } from 'commons/components';
import { DELIVERY_METHODS_ROUTES } from 'domains/Shipping/DeliveryMethods/shippingRoutes';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import LocationPointItemSkeleton from './Skeleton';

interface LocationPointItemProps {
  id: string;
  name: string;
  address: string;
}

function LocationPointItem({
  id,
  name,
  address,
}: Readonly<LocationPointItemProps>) {
  const t = useTranslationShipping('deliveryMethods.pickupPoints.list');
  const { goTo } = useNavegate();

  return (
    <DataList.Row id={id}>
      <Stack column align="flex-start" justify="space-between">
        <Title as="h6">{name}</Title>
        <Text>{address}</Text>
        <Button
          appearance="primary"
          onClick={() =>
            goTo(
              generatePath(DELIVERY_METHODS_ROUTES.locationAsPickupPoint, {
                location: id,
              }),
            )
          }
        >
          {t('actions.activate')}
        </Button>
      </Stack>
    </DataList.Row>
  );
}

LocationPointItem.Skeleton = LocationPointItemSkeleton;

export default LocationPointItem;
