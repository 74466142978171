import {
  CustomersDetailsFormResponseDto,
  CustomersDetailsResponseDto,
  CustomersExportCsvResponseDto,
  CustomersListResponseDto,
  CustomersOrdersResponseDto,
  ResultPaginationResponseDto,
  CustomersMessagesResponseDto,
  CustomersCheckEmailExistsDto,
  ImportMaxLinesResponse,
  ImportFileRequestDto,
  ImportFileResponseDto,
  ImportColumnsResponseDto,
  DownloadCsvTemplateResponseDto,
} from '@tiendanube/common';
import { CustomerFormValuesInterface } from '../pages/CustomerFormPage/types';

export const defaultFilters: CustomersFiltersType = {
  page: 1,
  q: '',
  sort: '',
};

export type SortType = 'asc' | 'desc' | '';

type MetafieldsPrefix = `mf-${string}`;

export type CustomersFiltersType = {
  page: number;
  q: string;
  sort: SortType;
  [key: MetafieldsPrefix]: string;
};

export type CustomerFiltersParamsType = Omit<CustomersFiltersType, 'page'>;

export type AdvancedSearchCustomerFiltersParamsType = Omit<
  CustomersFiltersType,
  'page' | 'q' | 'sort'
>;

export type GetCustomersType = (
  f: CustomersFiltersType,
) => Promise<CustomersListResponseDto>;

export type AdvancedSearchCustomersType = (
  f: CustomersFiltersType,
) => Promise<CustomersListResponseDto>;

export type ExportCustomersType = () => Promise<CustomersExportCsvResponseDto>;

export type GetCustomerFormValuesType = (
  customerId: string,
) => Promise<CustomersDetailsFormResponseDto>;

export type CustomerRequestType = (
  customerValues: CustomerFormValuesInterface,
) => Promise<CustomersDetailsResponseDto>;
export type GetCustomerByIdType = (
  id: string,
) => Promise<CustomersDetailsResponseDto>;

export type GetCustomerOrdersType = (
  id: string,
  page?: number,
) => Promise<ResultPaginationResponseDto<CustomersOrdersResponseDto[]>>;

export type GetCustomerMessagesType = (
  id: string,
) => Promise<CustomersMessagesResponseDto[]>;

export type UpdateCustomerNoteType = (
  id: string,
  nota: string,
) => Promise<CustomersDetailsResponseDto>;

export type UpdateCustomerMessagesType = (
  id: string,
  action: string,
) => Promise<CustomersMessagesResponseDto>;

export type RemoveMessageType = (
  id: string,
) => Promise<CustomersMessagesResponseDto>;

export type ReadMessageType = (
  id: string,
) => Promise<CustomersMessagesResponseDto>;

export type UnreadMessageType = (
  id: string,
) => Promise<CustomersMessagesResponseDto>;

export type SpamMessageType = (
  id: string,
) => Promise<CustomersMessagesResponseDto>;

export type SendActivationMailType = (id: string) => Promise<void>;

export type SendResetPasswordMailType = (id: string) => Promise<void>;

export type ExportCustomerDataType = (id: string) => Promise<void>;

export type FetchCustomerCsvTemplateType =
  () => Promise<DownloadCsvTemplateResponseDto>;

export type DeleteCustomerType = (id: string) => Promise<void>;

export type FetchImportColumnsType = () => Promise<ImportColumnsResponseDto>;

export type ImportCustomersType = (
  params: ImportFileRequestDto,
) => Promise<ImportFileResponseDto>;

export type AnonymizeCustomerType = (id: string) => Promise<void>;

export type GetImportCustomersMaxLines = () => Promise<ImportMaxLinesResponse>;

export interface EmailDataInterface {
  subject: string;
  content: string;
  sendCopy: boolean;
  source: string;
}

export type SendEMailType = (data: {
  customerId: string;
  emailData: EmailDataInterface;
}) => Promise<void>;

export type GetCheckEmailExistsType = (
  email: string,
) => Promise<CustomersCheckEmailExistsDto>;
