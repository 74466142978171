import { DataList } from '@tiendanube/components';
import MetafieldsDataRows from 'domains/Metafields/components/MetafieldsCustomerDataList/components/MetafieldsDataRows';
import {
  MetafieldInterface,
  MetafieldSelectedInterface,
  OnChangeMetafieldType,
  OnMultipleChangeMetafieldType,
  SourceType,
} from 'domains/Metafields/types';

import './MetafieldsCustomerDataList.scss';

interface MetafieldsCustomerDataListProps {
  metafields: MetafieldInterface[] | null;
  selectedMetafields: MetafieldSelectedInterface[];
  source: SourceType;
  onChange: OnChangeMetafieldType;
  onMultipleChange: OnMultipleChangeMetafieldType;
}

export default function MetafieldsCustomerDataList({
  metafields,
  selectedMetafields,
  source,
  onChange,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onMultipleChange,
}: MetafieldsCustomerDataListProps) {
  return (
    <DataList spacing="base" ruled={false}>
      <MetafieldsDataRows
        metafields={metafields}
        selectedMetafields={selectedMetafields}
        source={source}
        onChange={onChange}
      />
    </DataList>
  );
}
