import { CombinedState } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { ExternalInvoicesHistory } from '../types';

export const getExternalInvoiceHistoryState = (
  state: RootStateType,
): CombinedState<ExternalInvoicesHistory> =>
  state.billing.invoices.externalInvoices;

export const getExternalInvoiceHistory = (state: RootStateType) =>
  getExternalInvoiceHistoryState(state).data;

export const getExternalCurrentPage = (state: RootStateType) =>
  getExternalInvoiceHistoryState(state).data.pagination.currentPage;

export const getExternalTotalPages = (state: RootStateType) =>
  getExternalInvoiceHistoryState(state).data.pagination.totalPages;

export const getExternalInvoiceHistoryStatus = (state: RootStateType) =>
  getExternalInvoiceHistoryState(state).status.get;

export const getExternalInvoiceHistoryList = (state: RootStateType) =>
  getExternalInvoiceHistoryState(state).data.results;

export const getExternalInvoiceHistoryUpdateStatus = (state: RootStateType) =>
  getExternalInvoiceHistoryState(state).status.update;

export const getExternalInvoiceLinkStatus = (state: RootStateType) =>
  getExternalInvoiceHistoryState(state).status.link;
