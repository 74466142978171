import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { LocationResponseDto } from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { InterfaceLocationsSchema } from '../types';

const getLocationState = (
  state: RootStateType,
): CombinedState<InterfaceLocationsSchema> => state.shipping.locations;

export const getLocationsStatus = (state: RootStateType) => {
  const status = getLocationState(state).status;
  return {
    isLoading: status === 'loading',
    isError: status === 'error',
    isSuccess: status === 'success',
  };
};

export const getLocationsEntities = (state: RootStateType) =>
  getLocationState(state).entities;

export const getLocationsEntitiesArray = (state: RootStateType) => {
  const locations = getLocationsEntities(state);
  return Object.entries(locations)
    .map((location) => ({
      id: location[1].id,
      name: location[1].name,
      address: location[1].address,
      main: location[1].main,
      priority: location[1].priority,
      tags: location[1].tags,
    }))
    .sort((a, b) => a.priority - b.priority) as LocationResponseDto[];
};

export const getLocationMain = (state: RootStateType) => {
  const locations = getLocationsEntitiesArray(state);
  return locations.find((location) => location.main);
};

export const getLocationsOthers = (state: RootStateType) => {
  const locations = getLocationsEntitiesArray(state);
  return locations
    .filter((location) => !location.main)
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const getLocationsMainId = (state: RootStateType) =>
  getLocationState(state).idMain;

export const getLocationsOthersIds = createSelector(
  getLocationsOthers,
  (locations) => locations.map((_, idx) => locations[idx].id),
);

export const getLocationById = (state: RootStateType, id: string) =>
  getLocationsEntities(state).find(
    (location) => location.id === id,
  ) as LocationResponseDto;

export const getLocationDeatils = (state: RootStateType) => {
  const { status, data } = getLocationState(state).entityDetails;
  return {
    location: data,
    isLoading: status === 'loading',
    isError: status === 'error',
    isSuccess: status === 'success',
  };
};

export const getInventoryLevelsByLocationStatus = (state: RootStateType) => {
  const status = getLocationState(state).inventoryLevels.status;
  return {
    isLoading: status === 'loading',
    isError: status === 'error',
    isSuccess: status === 'success',
  };
};

export const getInventoryLevelsByLocationResults = (state: RootStateType) => {
  const results = getLocationState(state).inventoryLevels.data;
  return results;
};

export const getInventoryLevelsByLocationTotal = (state: RootStateType) =>
  getLocationState(state).inventoryLevels.pagination.totalResults;
