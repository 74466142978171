import { PromotionResponseDto, Reward, Trigger } from '@tiendanube/common';
import { PromotionDiscountTypes } from '@tiendanube/common/src/domains/marketing/promotions/PromotionDiscountTypes';
import { addMounthsToday, getTodayIsoString } from 'commons/utils/date';
import { Scope } from 'domains/Marketing/enums';

export const PromotionTypes = {
  /*
   * Replicated from Common to avoid known bug:
   * when importing variables from Common into Stratus (types have no issues),
   * compilation may crash with an error such as:
   * `Could not resolve "class-transformer/storage"`
   */
  BUY_X_PAY_Y: 'BuyXPayY',
  N_AT_X_OFF: 'NAtX%off', // Deprecated progressive discount
  PROGRESSIVE_DISCOUNT: 'ProgressiveDiscount',
  BUY_2_PAY_1: '2x1',
  BUY_3_PAY_2: '3x2',
  CROSS_SELLING: 'CrossSelling',
} as const;

export type PromotionType = typeof PromotionTypes[keyof typeof PromotionTypes];

export const promotionDefault: PromotionResponseDto = {
  name: '',
  promotionType: PromotionTypes.BUY_X_PAY_Y,
  scopeType: Scope.ALL,
  categories: [],
  products: [],
  discountForQuantity: false,
  rewards: [],
  progressiveDiscounts: [{ quantity: 0, discount: 0 }],
  buyXPayY: null,
  dateType: 'unlimited',
  startDate: getTodayIsoString(),
  endDate: addMounthsToday(1),
  combinesWithOtherDiscounts: true,
};

export const CROSS_SELLING_DEFAULT_REWARD_VALUES = {
  scope_type: Scope.PRODUCTS,
  discount_type: PromotionDiscountTypes.PERCENTAGE,
  max_quantity: 1,
};

export const CROSS_SELLING_DEFAULT_TRIGGER: Trigger = {
  scope_type: Scope.ALL,
  scope_value: null,
  min_quantity: 1,
};

export const CROSS_SELLING_DEFAULT_REWARD: Reward = {
  ...CROSS_SELLING_DEFAULT_REWARD_VALUES,
  scope_value: [],
  discount_value: 0,
  triggers: [CROSS_SELLING_DEFAULT_TRIGGER],
};
