import { useEffect, useMemo, useRef } from 'react';
import { Text, Link, Alert } from '@nimbus-ds/components';
import { Link as LinkRoute } from 'react-router-dom';
import { useDismissibleComponent } from 'commons/hooks';
import { configurationsRoutes } from 'domains/Configurations';
import { useGetDomainsErrorsQuery } from 'domains/Configurations/Domains/domainsApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { useCustomDomainDisabled, useHasDomainsErrorAlertTag } from '../hooks';
import {
  trackingDomainsErrorAlertDashboardClick,
  trackingDomainsErrorAlertDashboardDismiss,
  trackingDomainsErrorAlertDashboardShow,
} from '../tracking';

interface SomeDomainHasErrorAlertProps {
  showLink?: boolean;
  isRemovable?: boolean;
}

export function SomeDomainHasErrorAlert({
  showLink = true,
  isRemovable = true,
}: Readonly<SomeDomainHasErrorAlertProps>) {
  const t = useTranslationConfigurations('domains.errorAlert');
  const hasDomainsErrorAlertTag = useHasDomainsErrorAlertTag();
  const { data: domainsWithErrors } = useGetDomainsErrorsQuery(undefined, {
    skip: !hasDomainsErrorAlertTag,
  });
  const isFreePlan = useCustomDomainDisabled();
  const isAlreadyLoggedEvent = useRef(false);

  const handleDismiss = () => {
    trackingDomainsErrorAlertDashboardDismiss();
    dismiss();
  };

  const { shouldShow, dismiss } = useDismissibleComponent(
    'domain-has-error-alert',
    1,
  );

  const shouldRenderAlert = useMemo(
    () =>
      hasDomainsErrorAlertTag &&
      !isFreePlan &&
      domainsWithErrors &&
      domainsWithErrors.ids.length > 0 &&
      (!isRemovable || shouldShow),
    [
      hasDomainsErrorAlertTag,
      isFreePlan,
      domainsWithErrors,
      isRemovable,
      shouldShow,
    ],
  );

  useEffect(() => {
    if (shouldRenderAlert && isRemovable && !isAlreadyLoggedEvent.current) {
      trackingDomainsErrorAlertDashboardShow();
      isAlreadyLoggedEvent.current = true;
    } else if (!shouldRenderAlert) {
      isAlreadyLoggedEvent.current = false;
    }
  }, [shouldRenderAlert, isAlreadyLoggedEvent, isRemovable]);

  if (!shouldRenderAlert) return null;

  return (
    <Alert
      appearance="warning"
      show={!isRemovable || shouldShow}
      title={t('title')}
      onRemove={isRemovable ? handleDismiss : undefined}
    >
      <Text>{t('text')}</Text>
      {showLink && (
        <Link
          onClick={trackingDomainsErrorAlertDashboardClick}
          as={LinkRoute}
          to={configurationsRoutes.domains}
        >
          {t('btn')}
        </Link>
      )}
    </Alert>
  );
}
