import { Alert } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import Text from 'commons/components/LegacyText';
import useStorage from 'commons/hooks/useStorage';
import {
  trackingCustomerAlertClose,
  trackingCustomerAlertPlans,
} from 'domains/Customers/tracking';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';
import { OwnerResourceType } from 'domains/Metafields/types';

import './EmptyAlert.scss';

interface EmptyOnAlertProps {
  ownerResource: OwnerResourceType;
}

function EmptyOnAlert({ ownerResource }: EmptyOnAlertProps) {
  const t = useTranslationsMetafields();
  const { goTo } = useNavegate();

  const [showAlertMetafieldsEmpty, setShowAlertMetafieldsEmpty] = useStorage(
    `showAlertMetafieldEmpty-${ownerResource}`,
    true,
    true,
  );

  const closeAlert = () => {
    if (ownerResource === 'customer') {
      trackingCustomerAlertClose('CustomerFormPage');
    }
    setShowAlertMetafieldsEmpty(false);
  };

  const handleClickPrimary = () => {
    if (ownerResource === 'customer') {
      trackingCustomerAlertPlans('CustomerFormPage');
    }
    goTo('/settings/metafields');
  };

  if (!showAlertMetafieldsEmpty) return null;

  return (
    <div className="stratus--alert-metafields-empty">
      <Alert
        show
        title={t(`alertEmpty.${ownerResource}.title`)}
        primaryLabel={t('alertEmpty.nameLink')}
        onClickPrimary={handleClickPrimary}
        appearance="primary"
        onDismiss={closeAlert}
      >
        <Text size="caption">
          {t(`alertEmpty.${ownerResource}.description`)}
        </Text>
      </Alert>
    </div>
  );
}

export default EmptyOnAlert;
