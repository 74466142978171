import { Text } from '@nimbus-ds/components';
import { EditIcon } from '@nimbus-ds/icons';
import { Link } from '@tiendanube/components';
import { Stack } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { RelatedProductType } from 'domains/Catalog/Products/pages/hooks/useProductForm';
import RelatedProductImage from './RelatedProductImages';

interface PreviewRelatedProductsProps {
  title: string;
  description: string;
  onOpen: () => void;
  relatedProducts: RelatedProductType[];
}

function PreviewRelatedProducts({
  title,
  description,
  onOpen,
  relatedProducts,
}: Readonly<PreviewRelatedProductsProps>) {
  const t = useTranslationCatalog();

  return (
    <Stack column align="stretch">
      <Text fontSize="highlight" fontWeight="medium">
        {title}
      </Text>
      <Text>{description}</Text>
      <RelatedProductImage relatedProducts={relatedProducts} />
      <Link
        icon={EditIcon}
        iconPosition="start"
        appearance="primary"
        onClick={onOpen}
      >
        {t('products.detail.highlight.editSelected')}
      </Link>
    </Stack>
  );
}

export default PreviewRelatedProducts;
