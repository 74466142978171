import { ConceptCode } from '@tiendanube/common';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { useGetAppsListQuery } from 'domains/PartnersApps/partnersAppsApi';

export default function useAppName(concept: ConceptCode, appId?: string) {
  const isApp = concept === 'app-extra-fee' && appId;
  const { data: appsList, status } = useGetAppsListQuery(undefined, {
    skip: !isApp,
  });

  const app = appsList?.find(({ id }) => id === appId);

  return { appName: app?.name, status, ...convertStatusTypeToObject(status) };
}
