import { useMemo } from 'react';
import { Icon, Input, Link, Sidebar, Text, Title } from '@nimbus-ds/components';
import { ChevronLeftIcon } from '@nimbus-ds/icons';
import { FormField } from '@nimbus-ds/patterns';
import { CustomShippingRangeDetailsDto } from '@tiendanube/common';
import {
  CancelAndConfirmButtons,
  InputNumberNimbus,
  Stack,
} from 'commons/components';
import { useForm } from 'commons/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { initialRangeValues, MAX_RANGE, MIN_RANGE } from './constants';
import { shippingCustomRangeSchema } from './ShippingCustomRangeSchema';

interface ShippingCustomRangeModalProps {
  range?: CustomShippingRangeDetailsDto;
  ranges: CustomShippingRangeDetailsDto[];
  isEditing: boolean;
  onChange: (ranges: CustomShippingRangeDetailsDto[]) => void;
  onClose: () => void;
}

function ShippingCustomRangeModal({
  onClose,
  onChange,
  isEditing,
  ranges,
  range,
}: Readonly<ShippingCustomRangeModalProps>) {
  const t = useTranslationShipping(
    'deliveryMethods.customShipping.rangeOptionsCard.byRanges.form',
  );

  const sortedRanges = [...ranges].sort(
    (a, b) => Number(a.finalRange) - Number(b.finalRange),
  );

  const rangeIndex = range
    ? sortedRanges.findIndex((r) => r.initialRange === range.initialRange)
    : -1;

  const lastRange = sortedRanges.at(-1);

  const initialValues = useMemo<CustomShippingRangeDetailsDto>(() => {
    if (isEditing && range) {
      return range;
    }

    return {
      ...initialRangeValues,
      initialRange: ranges.length
        ? Number(lastRange?.finalRange ?? 0).toString()
        : '0',
      finalRange: ranges.length
        ? (Number(lastRange?.finalRange ?? 0) + 1).toString()
        : '10',
    };
  }, [isEditing, lastRange?.finalRange, range, ranges.length]);

  const handleEdit = (values) => {
    const updatedRanges = sortedRanges.map((r, index) =>
      index === rangeIndex ? values : r,
    );

    onChange(updatedRanges);
    onClose();
  };

  const handleAdd = (values) => {
    const newRanges = [...sortedRanges, values];
    onChange(newRanges);
    onClose();
  };

  const { values, handleChange, handleOnSubmit, errors } = useForm({
    initialValues: initialValues,
    validationSchema: shippingCustomRangeSchema(),
    onSubmit: async (values) => {
      if (isEditing) {
        handleEdit(values);
      } else {
        handleAdd(values);
      }
    },
  });

  return (
    <Sidebar open onRemove={onClose}>
      <Sidebar.Header padding="base">
        <Stack column align="flex-start" justify="space-between">
          <Link onClick={onClose} textDecoration="none">
            <Icon color="primary-textHigh" source={<ChevronLeftIcon />} />
          </Link>
          <Title>{t('title')}</Title>
          <Text>{t('description')}</Text>
        </Stack>
      </Sidebar.Header>
      <hr />
      <Sidebar.Body padding="base">
        <Stack column wrap justify="space-around">
          {((ranges.length > 0 && !isEditing) ||
            (rangeIndex > 0 && isEditing)) && (
            <FormField label={t('initialRange')}>
              <InputNumberNimbus
                type="float"
                onChange={handleChange}
                defaultDecimals={0}
                name="initialRange"
                value={values.initialRange}
                append={t('rangePlaceholder')}
                appendPosition="end"
                disabled
              />
            </FormField>
          )}
          <FormField
            label={t(
              rangeIndex === 0 || ranges.length === 0
                ? 'finalRangeSingle'
                : 'finalRange',
            )}
            showHelpText={!!errors.finalRange}
            appearance={errors.finalRange ? 'danger' : 'none'}
            helpText={
              errors.finalRange
                ? t(errors.finalRange, {
                    initial: lastRange?.finalRange ?? MIN_RANGE,
                    final: MAX_RANGE,
                  })
                : undefined
            }
          >
            <InputNumberNimbus
              type="float"
              defaultDecimals={0}
              name="finalRange"
              value={values.finalRange}
              onChange={handleChange}
              appendPosition="end"
              append={t('rangePlaceholder')}
              disabled={isEditing && rangeIndex !== sortedRanges.length - 1}
              appearance={errors.finalRange ? 'danger' : 'neutral'}
            />
          </FormField>
          <FormField
            label={t('cost')}
            showHelpText={!!errors.cost}
            appearance={errors.cost ? 'danger' : 'none'}
            helpText={errors.cost ? t(errors.cost) : undefined}
          >
            <InputNumberNimbus
              type="float"
              name="cost"
              value={values.cost}
              onChange={handleChange}
              append={t('currency')}
              appearance={errors.cost ? 'danger' : 'neutral'}
            />
          </FormField>
          <Stack align="flex-end">
            <FormField
              label={t('deliveryTime')}
              appearance={errors.deliveryTimeMin ? 'danger' : 'none'}
              helpText={
                errors.deliveryTimeMin
                  ? t(errors.deliveryTimeMin)
                  : t('utilDays')
              }
              showHelpText
            >
              <Input
                type="number"
                min={0}
                name="deliveryTimeMin"
                value={values.deliveryTimeMin}
                onChange={handleChange}
                append={t('initialRange')}
                appearance={errors.deliveryTimeMin ? 'danger' : 'neutral'}
              />
            </FormField>
            <FormField
              helpText={
                errors.deliveryTimeMax
                  ? t(errors.deliveryTimeMax)
                  : t('utilDays')
              }
              appearance={errors.deliveryTimeMax ? 'danger' : 'none'}
              showHelpText
            >
              <Input
                type="number"
                min={0}
                name="deliveryTimeMax"
                value={values.deliveryTimeMax}
                onChange={handleChange}
                append={t('finalRange')}
                appearance={errors.deliveryTimeMax ? 'danger' : 'neutral'}
              />
            </FormField>
          </Stack>
        </Stack>
      </Sidebar.Body>
      <Sidebar.Footer padding="base">
        <CancelAndConfirmButtons
          cancelText={t('cancel')}
          confirmText={t('save')}
          onCancel={onClose}
          onConfirm={handleOnSubmit}
        />
      </Sidebar.Footer>
    </Sidebar>
  );
}

export default ShippingCustomRangeModal;
