import { Box } from '@nimbus-ds/components';
import emptyOrders from '../empty-orders.png';
import { OrderListEmptyContent } from '../OrderListEmptyContent';
import { OrderListEmptyHelpLink } from '../OrderListEmptyHelpLink';
import '../OrderListEmpty.scss';

export function OrderListEmptyMobile() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="12"
      padding="4"
    >
      <Box display="flex" flexDirection="column" gap="9">
        <Box flex="1 1 100%" padding="2" display="flex" justifyContent="center">
          <img width="192px" src={emptyOrders} alt="empty-orders" />
        </Box>
        <Box
          flex="1 1 100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <OrderListEmptyContent isMobile />
        </Box>
      </Box>
      <Box>
        <OrderListEmptyHelpLink />
      </Box>
    </Box>
  );
}
