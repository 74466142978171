import { Tag } from '@nimbus-ds/components';
import { EyeOffIcon } from '@nimbus-ds/icons';

function HiddenVariantTag({
  visible,
}: Readonly<{
  visible: boolean;
}>): JSX.Element | null {
  if (visible) return null;
  return (
    <Tag appearance="warning">
      <EyeOffIcon size="small" />
    </Tag>
  );
}

export default HiddenVariantTag;
