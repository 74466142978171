import { Key } from 'react';
import {
  DataList,
  InterfaceNameValue,
  Stack,
  Text,
  InterfaceSelectOptionGroup,
} from '@tiendanube/components';
import { InfoCircleIcon, UploadIcon } from '@tiendanube/icons';
import ModalAside from 'App/components/lab/ModalAside';
import {
  CancelAndSaveButtons,
  HeaderContent,
  HeaderTop,
} from 'commons/components';
import { useModal, useTranslationCommon } from 'commons/hooks';
import {
  AlterationItem,
  BadColumnsAlert,
  ConfirmIgnoreModal,
  IgnoreAllLink,
} from './components';
import './ImportAlterations.scss';

interface ImportAlterationsModalProps {
  badColumns: string[];
  matchColumns: Record<string, string>;
  columnOptions?: InterfaceSelectOptionGroup[];
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  setMatchingColumns: ({ name, value }: InterfaceNameValue) => void;
  ignoreAllColumns: () => void;
  undoIgnoreAllColumns: () => void;
}

export function ImportAlterationsModal({
  badColumns,
  matchColumns,
  columnOptions,
  show,
  onClose,
  onConfirm,
  setMatchingColumns,
  ignoreAllColumns,
  undoIgnoreAllColumns,
}: ImportAlterationsModalProps): JSX.Element {
  const t = useTranslationCommon('importCsv.alterationsModal');
  const [showIgnoreModal, openIgnoreModal, closeIgnoreModal] = useModal();

  const handleVerification = () => {
    if (Object.values(matchColumns).includes('ignore')) openIgnoreModal();
    else onConfirm();
  };

  const handleOnConfirm = () => {
    closeIgnoreModal();
    onConfirm();
  };

  const columnItems = Object.keys(matchColumns);
  const totalColumnsQuantity = Object.keys(matchColumns).length;
  const ignoredColumnsQuantity = Object.keys(matchColumns).filter(
    (col) => matchColumns[col] === 'ignore',
  ).length;
  const allColumnsAreIgnored = totalColumnsQuantity === ignoredColumnsQuantity;

  return (
    <>
      <ModalAside
        isOpen={show}
        onDidDismiss={onClose}
        headerTop={
          <HeaderTop
            navigation={{
              onClick: onClose,
            }}
            mainAction={{
              children: t('confirm'),
              appearance: 'secondary',
              icon: UploadIcon,
              disabled: allColumnsAreIgnored,
              onClick: handleVerification,
            }}
          />
        }
        headerContent={
          <HeaderContent title={t('title')} subtitle={t('subtitle')} />
        }
      >
        <BadColumnsAlert t={t} badColumnsQuantity={badColumns.length} />
        <Stack column align="stretch">
          <Stack.Item>
            <Stack>
              <Stack.Item fill>
                <Text block size="caption">
                  <InfoCircleIcon />
                  {t('ignoredColumnsQuantity', {
                    count: ignoredColumnsQuantity,
                    total: totalColumnsQuantity,
                  })}
                </Text>
              </Stack.Item>
              <IgnoreAllLink
                t={t}
                allColumnsAreIgnored={allColumnsAreIgnored}
                onClickIgnoreAll={ignoreAllColumns}
                onClickUndoIgnoreAll={undoIgnoreAllColumns}
              />
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <div className="stratus--import-alterations-modal-data-list">
              <DataList>
                {columnItems.map((column: string, key: Key) => (
                  <DataList.Row key={`${column}-${key}`} id={`${key}`}>
                    <DataList.Cell width="fill">
                      <AlterationItem
                        t={t}
                        columnName={column}
                        options={columnOptions}
                        value={matchColumns[column]}
                        isBadColumn={badColumns.includes(column)}
                        onChange={setMatchingColumns}
                      />
                    </DataList.Cell>
                  </DataList.Row>
                ))}
              </DataList>
            </div>
          </Stack.Item>
          <Stack.Item>
            <CancelAndSaveButtons
              saveText={t('confirm')}
              onSave={handleVerification}
              onCancel={onClose}
              isDisabled={allColumnsAreIgnored}
              icon={UploadIcon}
            />
          </Stack.Item>
        </Stack>
      </ModalAside>

      <ConfirmIgnoreModal
        t={t}
        show={showIgnoreModal}
        onClose={closeIgnoreModal}
        onConfirm={handleOnConfirm}
      />
    </>
  );
}
