import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { CheckoutDataInterface } from './types';

export const getCheckoutState = (
  state: RootStateType,
): CombinedState<CheckoutDataInterface> => state.billing.checkout;

export const getPaymentMethodSelected = createSelector(
  getCheckoutState,
  (plansAndSubscriptions) => plansAndSubscriptions.paymentMethodSelected,
);

export const getChargesToPaySelected = createSelector(
  getCheckoutState,
  (plansAndSubscriptions) => plansAndSubscriptions.chargesToPaySelected,
);
