import { Route, Switch } from 'react-router-dom';
import ToggleMigratedOldDomain from 'App/components/Migrations/ToggleMigratedOldDomain';
import { HideNavTabs } from 'App/components/Navtabs';
import { useHasPermission, useIsMobileDevice } from 'domains/Auth/hooks';
import { CATALOG_ROUTES } from './catalogRoutes';
import { MediaUploadStatusContextProvider } from './Products/pages/components/MediaUploadStatus';
import StockHistoryProvider from './Products/pages/components/StockHistoryModal/StockHistoryProvider';
import EditProductPage from './Products/pages/EditProductPage';
import ImportAndExportProductsPage from './Products/pages/ImportAndExportProductsPage';
import ImportProductsPage from './Products/pages/ImportAndExportProductsPage/ImportProductsPage';
import NewProductPage from './Products/pages/NewProductPage';
import OrganizeFeaturedProductsPage from './Products/pages/OrganizeFeaturedProductsPage';
import OrganizeProductsPage from './Products/pages/OrganizeProductsPage';
import ProductListPage from './Products/pages/ProductListPage';

function Products(): JSX.Element {
  const isMobileDevice = useIsMobileDevice();
  const canImportExportProductsCsv = useHasPermission(
    'import_export_products_csv',
  );

  return (
    <>
      <Route path={CATALOG_ROUTES.productList} exact>
        <ProductListPage />
      </Route>
      <Switch>
        <Route path={CATALOG_ROUTES.newProduct} exact>
          <MediaUploadStatusContextProvider>
            <StockHistoryProvider>
              <HideNavTabs />
              <NewProductPage />
            </StockHistoryProvider>
          </MediaUploadStatusContextProvider>
        </Route>
        <Route path={CATALOG_ROUTES.organizeFeaturedProducts} exact>
          <OrganizeFeaturedProductsPage />
        </Route>
        <Route path={CATALOG_ROUTES.organizeProducts} exact>
          {isMobileDevice ? (
            <OrganizeProductsPage />
          ) : (
            <ToggleMigratedOldDomain domain="productsorganize">
              <OrganizeProductsPage />
            </ToggleMigratedOldDomain>
          )}
        </Route>
        {canImportExportProductsCsv && (
          <Route path={CATALOG_ROUTES.importAndExportProducts} exact>
            {isMobileDevice ? (
              <>
                <HideNavTabs />
                <ImportAndExportProductsPage />
              </>
            ) : (
              <ToggleMigratedOldDomain domain="productsimport">
                <ImportAndExportProductsPage />
              </ToggleMigratedOldDomain>
            )}
          </Route>
        )}
        {canImportExportProductsCsv && (
          <Route path={CATALOG_ROUTES.importProducts} exact>
            {isMobileDevice ? (
              <>
                <HideNavTabs />
                <ImportProductsPage />
              </>
            ) : (
              <ToggleMigratedOldDomain domain="productsimport">
                <ImportProductsPage />
              </ToggleMigratedOldDomain>
            )}
          </Route>
        )}
        <Route path={CATALOG_ROUTES.editProduct}>
          <MediaUploadStatusContextProvider>
            <StockHistoryProvider>
              <HideNavTabs />
              <EditProductPage />
            </StockHistoryProvider>
          </MediaUploadStatusContextProvider>
        </Route>
      </Switch>
    </>
  );
}

export default Products;
