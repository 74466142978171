import { useCallback, useEffect, useState } from 'react';
import { ConceptCode } from '@tiendanube/common';
import { useForm, useScript } from 'commons/hooks';
import { StatusType } from 'commons/types';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { useGetCountry } from 'domains/Auth/hooks';
import usePayorderToPay from './usePayorderToPay';
import {
  CreditCardFormFields,
  formConfigMercadoPago,
  formCreditCardSchema,
  getCreditCardFormErrors,
  validateErrorCodesMercadoPago,
} from './utils';
import { config } from '../../../../App/components/ExternalServices/MercadoPago/config';
import { usePostCreditCardPaymentMutation } from '../CheckoutApi';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const MercadoPago: any;

interface errorsInterface {
  code: string;
  message: string;
}

const initialValues: CreditCardFormFields = {
  cardNumber: '',
  cardholderName: '',
  expirationDate: '',
  expirationMonth: '',
  expirationYear: '',
  securityCode: '',
  identificationType: '',
  identificationNumber: '',
  cardholderEmail: '',
  installments: '',
  issuer: '',
};

function useCreditCardCheckout(includeTransactionFee: boolean) {
  const [executeCreditCardPayment, { data: creditCardPaymentData }] =
    usePostCreditCardPaymentMutation();
  const {
    payorderToPay,
    conceptsToPay,
    status: payOrderStatus,
  } = usePayorderToPay();

  const [executionStatus, setExecutionStatus] = useState<StatusType>('idle');

  const status: StatusType =
    executionStatus === 'success'
      ? creditCardPaymentData?.successfulExecution
        ? 'success'
        : 'error'
      : executionStatus;
  const country = useGetCountry();

  const cleanStatus = useCallback(() => {
    setExecutionStatus('idle');
  }, [setExecutionStatus]);

  const statusMercadoPago = useScript(
    import.meta.env.VITE_MP_SDK_PATH as string,
    {
      removeOnUnmount: true,
    },
  );

  const [cardForm, setCardForm] = useState<any>(undefined);

  const onSubmit = useCallback(async () => {
    if (!cardForm) return;
    setExecutionStatus('loading');

    if (!payorderToPay) {
      setExecutionStatus('error');
      return;
    }

    setErrorsMercadoPago(initialValues);
    try {
      await cardForm.createCardToken();
    } catch (error) {
      setExecutionStatus('error');
    }
    const { token, issuerId, cardholderEmail, paymentMethodId } =
      cardForm.getCardFormData();

    executeCreditCardPayment({
      payorderId: payorderToPay.id,
      token,
      issuerId,
      paymentMethodId,
      email: cardholderEmail,
      shouldActivateRecurrentPayment: true,
      shouldSubscribeTransactionFeesToRp: includeTransactionFee,
    })
      .unwrap()
      .then(() => {
        setExecutionStatus('success');
      })
      .catch(() => {
        setExecutionStatus('error');
      });
  }, [
    cardForm,
    executeCreditCardPayment,
    payorderToPay,
    includeTransactionFee,
  ]);
  const {
    values: formValues,
    errors: formErrors,
    handleOnSubmit,
    handleOnChange,
    setFieldValue,
  } = useForm<CreditCardFormFields, Partial<CreditCardFormFields>>({
    initialValues,
    onSubmit,
    validationSchema: formCreditCardSchema(country),
  });

  const [errorsMercadoPago, setErrorsMercadoPago] =
    useState<Partial<CreditCardFormFields>>(initialValues);
  const [setupStatus, setSetupStatus] = useState<StatusType>('loading');

  useEffect(() => {
    if (statusMercadoPago === 'error' || payOrderStatus === 'error') {
      setSetupStatus('error');
    }
    if (statusMercadoPago === 'ready' && payOrderStatus === 'success') {
      const thereAreArgENConcepts = thereAreArgEnvioNubeConcepts(conceptsToPay);
      const pk = thereAreArgENConcepts
        ? config.pk.envioNube.AR
        : config.pk[country];
      const mp = new MercadoPago(pk);
      const cardForm = mp.cardForm({
        amount: '1000', // This amount is not actually used for the payment
        autoMount: true,
        form: formConfigMercadoPago(country),
        callbacks: {
          onFormMounted: (errors) => {
            if (errors) {
              setSetupStatus('error');
            }
          },
          onReady: () => {
            setSetupStatus('success');
          },
          onCardTokenReceived: (errors: errorsInterface[]) => {
            if (errors) {
              setErrorsMercadoPago(validateErrorCodesMercadoPago(errors));
              return false;
            }
          },
          onSubmit: (event: SubmitEvent) => {
            event.preventDefault();
          },
        },
      });
      setCardForm(cardForm);
      return () => cardForm.unmount();
    }
  }, [
    statusMercadoPago,
    payorderToPay,
    country,
    payOrderStatus,
    includeTransactionFee,
    conceptsToPay,
  ]);

  const errors = getCreditCardFormErrors(
    creditCardPaymentData?.errorCode,
    errorsMercadoPago,
    formErrors,
  );

  return {
    formErrors,
    handleOnChange,
    formValues,
    handleOnSubmit,
    setFieldValue,
    errors,
    setupStatusObject: convertStatusTypeToObject(setupStatus),
    status,
    cleanStatus,
    ...convertStatusTypeToObject(status),
  };
}

function thereAreArgEnvioNubeConcepts(
  conceptsToPay: Set<ConceptCode> | undefined,
): boolean {
  const argEnvioNubeConcepts: ConceptCode[] = [
    'ar-shipping-cost',
    'ar-shipping-credit',
    'ar-shipping-penalty',
    'ar-shipping-fee',
    'ar-shipping-credit-cost',
    'ar-shipping-credit-fee',
  ];

  return conceptsToPay
    ? argEnvioNubeConcepts.some((concept) => conceptsToPay.has(concept))
    : false;
}

export default useCreditCardCheckout;
