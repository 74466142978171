import { Card, Title } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import Skeleton from './Skeleton';

interface PickupNameProps {
  name: string;
  errors: Partial<Record<string, string>>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function PickupName({
  name,
  errors,
  onChange,
}: Readonly<PickupNameProps>): JSX.Element {
  const t = useTranslationShipping(
    'deliveryMethods.pickupPoints.form.firstStep',
  );
  const title = t('pickupName');
  const helpText = errors.name ? t(errors.name) : null;

  return (
    <Card>
      <Card.Header>
        <Title as="h3">{title}</Title>
      </Card.Header>
      <Card.Body>
        <FormField.Input
          type="text"
          onChange={onChange}
          name="name"
          showHelpText={!!helpText}
          value={name}
          appearance={helpText ? 'danger' : 'none'}
          helpText={helpText ?? ''}
        />
      </Card.Body>
    </Card>
  );
}

PickupName.Skeleton = Skeleton;

export default PickupName;
