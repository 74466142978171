const SETTING_BASE_ROUTE = '/settings';

export const CONFIGURATIONS_ROUTES = {
  i18n: `${SETTING_BASE_ROUTE}/i18n`,
  whatsapp: `${SETTING_BASE_ROUTE}/whatsapp`,
  settingsCheckout: `${SETTING_BASE_ROUTE}/checkout`,
  users: `${SETTING_BASE_ROUTE}/users`,
  userForm: `${SETTING_BASE_ROUTE}/users/:id`,
  domains: `${SETTING_BASE_ROUTE}/domains`,
  externalCodes: `${SETTING_BASE_ROUTE}/external-codes`,
  notifications: `${SETTING_BASE_ROUTE}/notifications`,
  notificationsForm: `${SETTING_BASE_ROUTE}/notifications/:id`,
  invoiceHistory: `${SETTING_BASE_ROUTE}/invoices`,
  transactionFees: `${SETTING_BASE_ROUTE}/transaction-fees`,
};
