import { Box } from '@nimbus-ds/components';
import emptyOrders from '../empty-orders.png';
import { OrderListEmptyContent } from '../OrderListEmptyContent';
import { OrderListEmptyHelpLink } from '../OrderListEmptyHelpLink';
import '../OrderListEmpty.scss';

export function OrderListEmptyDesktop() {
  return (
    <Box paddingTop="10">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        paddingX="4"
        paddingBottom="3"
        paddingTop="20"
        gap="12"
      >
        <Box display="flex" flexDirection="row" gap="9">
          <Box
            flex="1 1 40%"
            padding="1"
            display="flex"
            justifyContent="flex-end"
          >
            <img width="320px" src={emptyOrders} alt="empty-orders" />
          </Box>
          <Box
            flex="1 1 60%"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <OrderListEmptyContent />
          </Box>
        </Box>
        <Box>
          <OrderListEmptyHelpLink />
        </Box>
      </Box>
    </Box>
  );
}
