import { MetafieldSelectedsOrErrorType } from 'domains/Catalog/Products/pages/components/Variants/types';
import { useCustomerMetafields } from 'domains/Customers/hooks';
import useGetCustomerMetafields from 'domains/Metafields/hooks/useGetCustomerMetafields';
import { MetafieldsSelected } from '../../types';
import SectionCustomerMetafields from '../SectionCustomerMetafields';

export interface SectionCustomerMetafieldsExistingProps {
  id: string;
  selecteds: MetafieldSelectedsOrErrorType;
  apiSelecteds: MetafieldSelectedsOrErrorType;
  onChange: (metafields: MetafieldsSelected) => void;
}

export default function SectionVariantsMetafieldsExisting({
  id,
  ...rest
}: SectionCustomerMetafieldsExistingProps) {
  const { status } = useGetCustomerMetafields();
  const { status: metafieldsStatus } = useCustomerMetafields(id);

  const showError = status.isError;
  const showLoading = status.isLoading || metafieldsStatus.isLoading;
  const showSuccess = status.isSuccess && metafieldsStatus.isSuccess;

  return (
    <SectionCustomerMetafields
      {...rest}
      showError={showError}
      showLoading={showLoading}
      showSuccess={showSuccess}
      mode="edit"
    />
  );
}
