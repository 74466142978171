import { ProductDetailsResponseDto } from '@tiendanube/common';
import { Label, Stack, Thumbnail } from '@tiendanube/components';
import { CameraIcon } from '@tiendanube/icons';
import { useGetLanguage } from 'domains/Auth/hooks';
import { HiddenProductPill } from 'domains/Catalog/Products/pages/ProductListPage/components';
import { LinkGoToProduct } from 'domains/Catalog/Products/pages/ProductListPage/components/ProductResultsDesktop/components/ResultRowDesktop/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

interface ProductCellProps {
  product: Pick<
    ProductDetailsResponseDto,
    'published' | 'tags' | 'images' | 'name' | 'id'
  >;
  maxTagsAndMaxTags: number;
}

function ProductCell({
  product,
  maxTagsAndMaxTags,
}: ProductCellProps): JSX.Element {
  const language = useGetLanguage();
  const t = useTranslationCatalog();

  const allsTags = product.tags.split(',');
  const tags = allsTags.slice(0, maxTagsAndMaxTags);
  const [firstImage] = product.images;

  return (
    <Stack spacing="tight" align="flex-start">
      <Stack.Item>
        {firstImage && (
          <Thumbnail
            aspectRatio="1-1"
            width="72px"
            src={firstImage.src}
            altText={
              (firstImage.alt
                ? firstImage.alt[language]
                : `${product.name[language]} - ${firstImage.id}`) || ''
            }
          />
        )}
        {!firstImage && (
          <Thumbnail.Empty
            aspectRatio="1-1"
            width="72px"
            placeholderIcon={CameraIcon}
          />
        )}
      </Stack.Item>
      <Stack.Item fill>
        <Stack column align="flex-start" spacing="tight">
          <Stack.Item>
            <LinkGoToProduct
              title={product.name[language] || ''}
              id={product.id}
              bold
            />
          </Stack.Item>
          <Stack.Item>
            <Stack spacing="tight" wrap>
              {tags.map((tag) =>
                tag ? (
                  <Stack.Item key={tag}>
                    <Label id={tag} label={tag} />
                  </Stack.Item>
                ) : null,
              )}
            </Stack>
          </Stack.Item>
          <Stack.Item>
            {allsTags.length > maxTagsAndMaxTags && (
              <LinkGoToProduct title={t('products.moreTags')} id={product.id} />
            )}
          </Stack.Item>
          <Stack.Item>
            {!product.published && (
              <HiddenProductPill id={`${product.id}`} withIcon />
            )}
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
}

export default ProductCell;
