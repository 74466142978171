import { MailIcon } from '@nimbus-ds/icons';
import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { useHandleMenuClick } from 'App/hooks';
import { useGetTags } from 'domains/Auth/hooks';
import { configurationsRoutes } from 'domains/Configurations';
import { trackingMenuSecondaryNotificationsClick } from '../tracking';

export function NotificationsMenu() {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem } = useHandleMenuClick();
  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);

  const handleClickNewAdmin = () => {
    trackingMenuSecondaryNotificationsClick();
    handleClickItem();
  };

  return (
    <MenuButton
      as={Link}
      to={configurationsRoutes.notifications}
      startIcon={MailIcon}
      onClick={handleClickNewAdmin}
      active={pathname.startsWith(configurationsRoutes.notifications)}
      label={t(
        `settingsMenu.communication.${isRevampMenu ? 'revampEmail' : 'email'}`,
      )}
    />
  );
}
