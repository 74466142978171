import { Checkbox } from '@nimbus-ds/components';
import { Layout } from '@nimbus-ds/patterns';
import { ExtraOptionsProps } from 'commons/components/ImportCsvCard/components';
import { useTranslationCommon } from 'commons/hooks';

export function ImportCustomersExtraOptions({
  disabled,
  options,
  onChange,
}: ExtraOptionsProps) {
  const t = useTranslationCommon('importCsv.import');
  const entities = 'customers';

  const { acceptLegalRequirements } = options;

  return (
    <Layout columns="1">
      <Layout.Section>
        <Checkbox
          label={t('notification.legalRequirement', {
            entities,
          })}
          name="importCardLegalRequirementCheckbox"
          checked={acceptLegalRequirements}
          disabled={disabled}
          onChange={(e) =>
            onChange('acceptLegalRequirements', e.target.checked)
          }
        />
      </Layout.Section>
    </Layout>
  );
}
