import { useEffect } from 'react';
import { AsyncThunk } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { ImportMaxLinesResponse } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { StatusType } from 'commons/types';

type ImportMaxLinesThunk = AsyncThunk<ImportMaxLinesResponse, void, any>;

export function useImportMaxLines(
  getImportMaxLines: ImportMaxLinesThunk,
  importMaxLinesSelector: (state: unknown) => number,
  importMaxLinesStatusSelector: (state: unknown) => StatusType,
) {
  const dispatch = useAppDispatch();
  const maxLines = useSelector(importMaxLinesSelector);
  const maxLinesStatus = useSelector(importMaxLinesStatusSelector);
  const loadingMaxLines =
    maxLinesStatus === 'loading' || maxLinesStatus === 'idle';

  useEffect(() => {
    if (maxLinesStatus === 'idle') {
      dispatch(getImportMaxLines());
    }
  }, [dispatch, maxLinesStatus, getImportMaxLines]);

  return {
    maxLines,
    loadingMaxLines,
  };
}
