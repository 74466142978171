import { Modal, Text } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { CancelAndConfirmButtons } from 'commons/components';

interface ConfirmInventoryModalProps {
  show: boolean;
  onDismiss: () => void;
  onSave: () => void;
}
function ConfirmInventoryModal({
  show,
  onDismiss,
  onSave,
}: ConfirmInventoryModalProps) {
  const { t } = useTranslation(Domain.CATALOG);

  return (
    <Modal open={show} onDismiss={onDismiss}>
      <Modal.Header title={t('products.detail.goToInventorymodal.title')} />
      <Modal.Body padding="none">
        <Text textAlign="left">
          {t('products.detail.goToInventorymodal.description')}
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <CancelAndConfirmButtons
          confirmText={t('products.detail.goToInventorymodal.buttonConfirm')}
          onCancel={onDismiss}
          onConfirm={onSave}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmInventoryModal;
