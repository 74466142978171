import { Box, Link } from '@nimbus-ds/components';
import { useHistory } from 'react-router';
import {
  EmptySearch,
  ErrorState,
  EmptyState,
  Text,
  DataTable,
  Stack,
} from '@tiendanube/components';
import { PlusCircleIcon } from '@tiendanube/icons';
import { LinkButton } from 'App/components';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { PaginationStratus } from 'commons/components';
import { CUSTOMERS_SECTION_ROUTES } from 'domains/Customers/Customers/customersRoutes';
import { useCustomerResults } from 'domains/Customers/Customers/pages/CustomerListPage/hooks';
import { useTranslationCustomers } from 'domains/Customers/hooks';
import {
  SectionAppCard,
  SectionAppCardDomains,
} from 'domains/PartnersApps/components';
import {
  ResultHeaderDesktop,
  ResultRowDesktop,
  ResultsSkeleton,
} from './components';
import emptyCustomers from '../../empty-customers.png';

function CustomerResultsDesktop(): JSX.Element {
  const t = useTranslationCustomers();
  const { scrollToTop } = useLayoutScroll();
  const { push } = useHistory();

  const {
    isRefreshError,
    isRefreshSuccess,
    isRefreshing,
    customersIds,
    pagination,
    hasFilters,
    refreshCustomers,
    goToPage,
    filters,
    sortTotalConsumed,
  } = useCustomerResults();

  const onClickImport = () => {
    // trackingCoreProductImportOnboardingClick(); TODO(marcos): add tracking for this event
    push(CUSTOMERS_SECTION_ROUTES.customerEmptyImport);
  };

  const isEmptyList = customersIds.length === 0;

  const handleSelectPage = (page: number) => {
    scrollToTop();
    goToPage(page);
  };

  if (isRefreshError)
    return (
      <ErrorState
        title={t('errorResults.title')}
        action={{
          children: t('errorResults.action'),
          onClick: refreshCustomers,
        }}
      />
    );
  if (isRefreshSuccess && isEmptyList) {
    if (hasFilters) {
      return (
        <EmptySearch
          title={t('withoutResults.title')}
          text={t('withoutResults.text')}
        />
      );
    }
    return (
      <EmptyState image={emptyCustomers} title={t('emptyResults.title')}>
        <Stack column align="flex-start">
          <Stack.Item>
            <Text>{t('emptyResults.body')}</Text>
          </Stack.Item>
          <Stack.Item>
            <Box paddingTop="4" display="flex" gap="4" flexWrap="wrap">
              <LinkButton
                to="/customers/new"
                text={t('emptyResults.action_primary')}
                icon={PlusCircleIcon}
                appearance="primary"
                iconPosition="start"
              />
              <Link
                appearance="primary"
                textDecoration="none"
                onClick={onClickImport}
              >
                {t('emptyResults.action_secondary')}
              </Link>
              <Box marginTop="4">
                <SectionAppCard
                  source={CUSTOMERS_SECTION_ROUTES.customerList}
                  appCategory="marketing"
                  domain={SectionAppCardDomains.CUSTOMERS}
                />
              </Box>
            </Box>
          </Stack.Item>
        </Stack>
      </EmptyState>
    );
  }

  return (
    <div className="stratus--flashing-table-color">
      <Stack spacing="tight" align="stretch" column>
        <DataTable>
          <ResultHeaderDesktop
            filters={filters}
            onSortTotalConsumed={sortTotalConsumed}
          />
          {isRefreshing && <ResultsSkeleton />}
          {!isRefreshing &&
            customersIds.map((id) => <ResultRowDesktop key={id} id={id} />)}
        </DataTable>
        {!isRefreshing && (
          <PaginationStratus
            currentPage={pagination.currentPage}
            totalItems={pagination.totalResults}
            itemName={t('pagination.items')}
            page={pagination.currentPage}
            perPage={20}
            onPageSelect={handleSelectPage}
          />
        )}
      </Stack>
    </div>
  );
}

export default CustomerResultsDesktop;
