import { Card, Checkbox, Title, Icon, Label, Box } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import { InterfaceNameBooleanValue } from 'commons/types';
import {
  useTrackFullCatalog,
  useTranslationCatalog,
} from 'domains/Catalog/hooks';
import { trackingProductDetailVariantHideSelect } from 'domains/Catalog/Products/tracking';

interface VisibilityCardProps {
  visible: boolean;
  disableHideVariant: boolean;
  onChange: ({ name, value }: InterfaceNameBooleanValue) => void;
}

function VisibilityCard({
  visible,
  disableHideVariant,
  onChange,
}: Readonly<VisibilityCardProps>) {
  const t = useTranslationCatalog();
  const sender = useTrackFullCatalog();

  const handleChangeShow = (event) => {
    sender(() => trackingProductDetailVariantHideSelect(!event.target.checked));
    onChange({ name: 'visible', value: event.target.checked });
  };

  const disabled = visible && disableHideVariant;

  return (
    <Card>
      <Card.Header>
        <Title as="h4">{t('products.detail.visibility')}</Title>
      </Card.Header>
      <Box display="flex" flexDirection="column" gap="2">
        <Checkbox
          label={t('products.detail.showVariantInStore')}
          name="visible"
          onChange={handleChangeShow}
          checked={visible}
          disabled={disabled}
        />
        {disabled && (
          <Label>
            <Icon source={<InfoCircleIcon />} />
            {t('products.detail.visibilityDisabledDisclaimer')}
          </Label>
        )}
      </Box>
    </Card>
  );
}

export default VisibilityCard;
