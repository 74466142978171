import { Box } from '@nimbus-ds/components';
import { ExternalInvoicesResponseDto } from '@tiendanube/common';
import { DataTable, IconButton } from '@tiendanube/components';
import { DownloadIcon } from '@tiendanube/icons';
import Text from 'commons/components/LegacyText';
import { useDownloadExternalInvoice } from 'domains/Billing/Invoices/pages/InvoicesListPage/components/InvoiceHistory';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import PagoNubeDataTableRowSkeleton from './PagoNubeDataTableRowSkeleton';

const { Cell, Row } = DataTable;

type PagoNubeDataTableRowProps = ExternalInvoicesResponseDto;

function PagoNubeDataTableRow({
  id,
  month,
  description,
}: PagoNubeDataTableRowProps): JSX.Element {
  const t = useTranslationBilling();
  const { getLink } = useDownloadExternalInvoice();

  const handleDownload = (invoiceId: string) => getLink(invoiceId);

  return (
    <div className="stratus--row-no-bottom-border">
      <Row id={month} align="flex-start">
        <Cell grow={4}>
          <Box display="flex" flexDirection="row" gap="2">
            <Text bold>{month}</Text>
            <Text>{description}</Text>
          </Box>
        </Cell>
        <Cell grow={1}>
          <IconButton
            icon={DownloadIcon}
            ariaLabel={t('invoiceHistory.buttonArial')}
            onClick={() => handleDownload(id)}
          />
        </Cell>
      </Row>
    </div>
  );
}

PagoNubeDataTableRow.Skeleton = PagoNubeDataTableRowSkeleton;

export default PagoNubeDataTableRow;
