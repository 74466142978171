import { useState } from 'react';
import {
  Accordion,
  Box,
  Button,
  Link,
  Spinner,
  Text,
} from '@nimbus-ds/components';
import {
  BoxPackedIcon,
  CheckCircleIcon,
  CreditCardIcon,
  RedoIcon,
  RocketIcon,
  TagIcon,
  ToolsIcon,
  TruckIcon,
  UploadIcon,
} from '@nimbus-ds/icons';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as LinkRoute } from 'react-router-dom';
import { Country } from '@tiendanube/common/src/enums';
import { PROJECT_SHIVA_TAG } from 'App/featuresFlags';
import { useHasTags, useNavegate } from 'App/hooks';
import {
  trackingDashboardSeeStoreClick,
  trackingGoPosClick,
  trackingMeliSyncClick,
  trackingMeliSyncDismissClick,
  trackingPaymentOthersClick,
  trackingRegisterLocationClick,
  trackingSeePreferencesNuvemEnvioClick,
  trackingSeePreferencesNuvemPagoClick,
  trackingSeePreferencesPagoNubeClick,
  trackingThemePersonalizeClick,
} from 'App/tracking';
import { ExternalLink, IconBox, Stack, SubmitButton } from 'commons/components';
import { openWindow } from 'commons/utils/window';
import { getStoreURL } from 'domains/Auth/authSlice/authSelectors';
import {
  useGetCountry,
  useLoggedAdminLink,
  useTransactionFeeAdminDisabled,
} from 'domains/Auth/hooks';
import { TRANSACTION_FEES_BASE_ROUTE } from 'domains/Billing/TransactionFees/transactionFeesRoutes';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import dashboardService from 'domains/Dashboard/services';
import {
  trackingAddProductClick,
  trackingSelectThemeClick,
  trackingShippingClick,
} from 'domains/Dashboard/tracking';
import Skeleton from './Skeleton';
import useOnboardingTasks from '../useOnboardingTasks';
import './OnboardingAccordionTasks.scss';

interface OnboardingAccordionProps {
  items: any[]; // TODO: add type
}

const ONBOARDING_TASK_ORDER: {
  setup_first_product: number;
  setup_payments: number;
  setup_layout: number;
  setup_shipping: number;
  store_created: number;
  setup_multicd: number;
  setup_meli_sync: number;
} = {
  store_created: 0,
  setup_first_product: 1,
  setup_layout: 2,
  setup_multicd: 3,
  setup_shipping: 4,
  setup_payments: 5,
  setup_meli_sync: 6,
};

const PRINCIPAL_LOG_EVENTS: {
  setup_first_product: () => void;
  setup_multicd: () => void;
  setup_layout: () => void;
  store_created: () => void;
  setup_meli_sync: () => void;
} = {
  store_created: trackingDashboardSeeStoreClick,
  setup_layout: trackingThemePersonalizeClick,
  setup_first_product: trackingAddProductClick,
  setup_multicd: trackingRegisterLocationClick,
  setup_meli_sync: trackingMeliSyncClick,
};

const SECONDARY_LOG_EVENTS: {
  setup_layout: () => void;
} = {
  setup_layout: trackingSelectThemeClick,
};

function OnboardingTaskAccordion({ items }: OnboardingAccordionProps) {
  const { goTo } = useNavegate();
  const { getOnboarding } = useOnboardingTasks();
  const { goToLoggedAdminLink } = useLoggedAdminLink();
  const isBlocked = useTransactionFeeAdminDisabled();
  const url = useSelector(getStoreURL);
  const sortedItems = [...items]
    .filter((item) => item.name !== 'setup_shipping_nuvemenvio')
    .sort((a, b) => {
      const orderA = ONBOARDING_TASK_ORDER[a.name];
      const orderB = ONBOARDING_TASK_ORDER[b.name];
      return orderA - orderB;
    });

  const [isProjectShiva] = useHasTags([PROJECT_SHIVA_TAG]);

  const ICONS: {
    setup_first_product: JSX.Element;
    setup_payments: JSX.Element;
    setup_layout: JSX.Element;
    setup_shipping: JSX.Element;
    store_created: JSX.Element;
    setup_multicd: JSX.Element;
    setup_meli_sync: JSX.Element;
    setup_testing_sale: JSX.Element;
  } = {
    setup_layout: <ToolsIcon />,
    setup_first_product: isProjectShiva ? <UploadIcon /> : <TagIcon />,
    setup_payments: <CreditCardIcon />,
    setup_shipping: <TruckIcon />,
    setup_multicd: <BoxPackedIcon />,
    store_created: <TruckIcon />,
    setup_meli_sync: <RedoIcon />,
    setup_testing_sale: <RocketIcon />,
  };

  const [statusLoadingPaymentsConfig, setStatusLoadingPaymentsConfig] =
    useState<'idle' | 'success' | 'loading' | 'error'>('idle');

  const [statusLoadingShippingConfig, setStatusLoadingShippingConfig] =
    useState<'idle' | 'success' | 'loading' | 'error'>('idle');

  const [statusLoadingMeliSyncConfig, setStatusLoadingMeliSyncConfig] =
    useState<'idle' | 'success' | 'loading' | 'error'>('idle');

  const [statusLoadingTestingSaleConfig, setStatusLoadingTestingSaleConfig] =
    useState<'idle' | 'success' | 'loading' | 'error'>('idle');

  const isLoadingPayments = statusLoadingPaymentsConfig === 'loading';
  const isLoadingShipping = statusLoadingShippingConfig === 'loading';
  const isLoadingMeliSync = statusLoadingMeliSyncConfig === 'loading';
  const isLoadingTestingSale = statusLoadingTestingSaleConfig === 'loading';

  const t = useTranslationDashboard();
  const storeCountry = useGetCountry();
  const isArgOrBr = [Country.BR, Country.AR].includes(storeCountry);

  const taskConfig = (task: string, url: string) => {
    if (task === 'setup_meli_sync') {
      return goToConfig('none', task);
    }

    if (task === 'setup_shipping' || task === 'setup_payments') {
      return goToConfig(url, task, true);
    }
  };

  const goToConfig =
    (url: string, task: string, isSecondaryLink = false) =>
    async () => {
      switch (task) {
        case 'setup_payments':
          await paymentConfig(url, isSecondaryLink);
          break;
        case 'setup_shipping':
          await shippingConfig(url, task, isSecondaryLink);
          break;
        case 'setup_testing_sale':
          await posTestingSaleConfig(url);
          break;
        default:
          await meliSyncConfig(url);
          break;
      }
    };

  const posTestingSaleConfig = async (url: string) => {
    setStatusLoadingTestingSaleConfig('loading');
    try {
      await dashboardService.updateOnboardingTaskSetupTestingSale();
      getOnboarding();
      setStatusLoadingTestingSaleConfig('success');
      trackingGoPosClick();
      openWindow(url, true);
    } catch (e) {
      setStatusLoadingTestingSaleConfig('error');
    }
  };

  const meliSyncConfig = async (url: string) => {
    setStatusLoadingMeliSyncConfig('loading');
    try {
      await dashboardService.updateOnboardingTaskSetupMeliSync();
      getOnboarding();
      setStatusLoadingPaymentsConfig('success');
      if (url !== 'none') {
        trackingMeliSyncClick();
        goTo(url);
      } else {
        trackingMeliSyncDismissClick();
      }
    } catch (e) {
      setStatusLoadingPaymentsConfig('error');
    }
  };

  const paymentConfig = async (url: string, isSecondaryLink: boolean) => {
    setStatusLoadingPaymentsConfig('loading');
    const isCountryNeeded = [Country.BR, Country.AR, Country.MX].includes(
      storeCountry,
    );

    try {
      if (isProjectShiva && isSecondaryLink) {
        if (isCountryNeeded) {
          goTo(url);
          return;
        } else {
          openWindow(url, true);
          setStatusLoadingPaymentsConfig('success');
          return;
        }
      }

      if (isProjectShiva && url.startsWith('/settings/payments/nuvempago')) {
        trackingSeePreferencesNuvemPagoClick();
        goTo(url);
      }

      if (url.startsWith('/settings/payments')) {
        trackingPaymentOthersClick();
        goTo(url);
      } else {
        if (!isProjectShiva) {
          await dashboardService.updateOnboardingTaskSetupPayments();
        }
        setStatusLoadingPaymentsConfig('success');
        if (url.startsWith('/admin')) {
          trackingSeePreferencesNuvemPagoClick();
          url = url.replace('/admin', '');
          goToLoggedAdminLink(url);
        } else {
          trackingSeePreferencesPagoNubeClick();
          goTo(url);
        }
      }
    } catch (e) {
      setStatusLoadingPaymentsConfig('error');
    }
  };

  const shippingConfig = async (
    url: string,
    task: string,
    isSecondaryLink: boolean,
  ) => {
    setStatusLoadingShippingConfig('loading');
    try {
      if (isProjectShiva && isSecondaryLink) {
        if (storeCountry === Country.BR) {
          const isMultiCDCompleted = sortedItems.find(
            (item) => item.name === 'setup_multicd' && item.completed === true,
          );
          if (isMultiCDCompleted) {
            await dashboardService.updateOnboardingTaskSetupShippingNative();
          }
        } else {
          setStatusLoadingShippingConfig('success');
          openWindow(url, true);
          return;
        }
      } else if (url.startsWith('/settings/shipping-methods/carriers')) {
        await dashboardService.updateOnboardingTaskSetupShippingNative();
        trackEvent(url, task);
      }
      setStatusLoadingShippingConfig('success');
      goTo(url);
    } catch (e) {
      setStatusLoadingShippingConfig('error');
    }
  };

  const trackEvent = (url: string, taskName: string) => () => {
    if (taskName === 'setup_shipping') {
      if (url.startsWith('/settings/shipping-methods/carriers')) {
        trackingSeePreferencesNuvemEnvioClick();
      } else {
        trackingShippingClick();
      }
    } else {
      PRINCIPAL_LOG_EVENTS[taskName]();
    }
  };

  const setLoading = (taskName: string) => {
    switch (taskName) {
      case 'setup_payments':
        return isLoadingPayments;
      case 'setup_shipping':
        return isLoadingShipping;
      case 'setup_testing_sale':
        return isLoadingTestingSale;
      default:
        return isLoadingMeliSync;
    }
  };

  return (
    <div className="stratus--onboarding-accordion-tasks">
      <Accordion>
        {sortedItems.map(
          ({
            name,
            title,
            ctaText,
            ctaLink,
            completed,
            secondaryCtaLink,
            secondaryCtaText,
            description,
            ctaExternal,
          }) =>
            name === 'store_created' || name === 'pos_store_created' ? (
              <Accordion.Item key={name} index={name}>
                <div className="stratus--store-created-item">
                  <Stack align="stretch" justify="space-between">
                    <Stack spacing="none">
                      <IconBox
                        backgroundColor="success-surfaceHighlight"
                        color="primary-textLow"
                        borderRadius="full"
                        height="24px"
                        width="24px"
                        marginRight="2-5"
                      >
                        <CheckCircleIcon color="currentColor" />
                      </IconBox>
                      <Stack column align="flex-start" spacing="none">
                        <Text fontWeight="medium" color="neutral-textHigh">
                          <s>{title}</s>
                        </Text>
                        <Text fontSize="caption">{description}</Text>
                      </Stack>
                    </Stack>
                    {name === 'store_created' && (
                      <div className="stratus--store-created-link">
                        <ExternalLink
                          href={isBlocked ? TRANSACTION_FEES_BASE_ROUTE : url}
                          textDecoration="none"
                          appearance="primary"
                          fontSize="caption"
                          onClick={PRINCIPAL_LOG_EVENTS[name]}
                        >
                          {ctaText}
                        </ExternalLink>
                      </div>
                    )}
                  </Stack>
                </div>
              </Accordion.Item>
            ) : (
              <Accordion.Item key={name} index={name}>
                <Accordion.Header borderTop="base">
                  <Box width="100%" display="flex" alignItems="center">
                    <Box>
                      <IconBox
                        backgroundColor={
                          completed
                            ? 'success-surfaceHighlight'
                            : 'primary-surface'
                        }
                        color={completed ? 'currentColor' : 'primary-textLow'}
                        borderRadius="full"
                        height="24px"
                        width="24px"
                        marginRight="2-5"
                      >
                        {completed ? (
                          <CheckCircleIcon color="currentColor" />
                        ) : (
                          ICONS[name]
                        )}
                      </IconBox>
                    </Box>
                    {completed ? (
                      <Text fontWeight="medium" color="neutral-textHigh">
                        <s>{title}</s>
                      </Text>
                    ) : (
                      <Text fontWeight="medium" color="neutral-textHigh">
                        {title}
                      </Text>
                    )}
                  </Box>
                </Accordion.Header>
                <Accordion.Body padding="none">
                  <Box width="85%" paddingLeft="12" paddingBottom="4">
                    <Text fontSize="caption" textAlign="left">
                      {isProjectShiva &&
                      isArgOrBr &&
                      name === 'setup_payments' ? (
                        <Trans
                          t={t}
                          i18nKey="shiva.setup_payments.description"
                          components={{
                            strong: <strong />,
                          }}
                        />
                      ) : (
                        description
                      )}
                    </Text>
                    <Box
                      paddingTop="1-5"
                      display="flex"
                      justifyContent="flex-start"
                    >
                      <Box marginRight="4">
                        {name === 'setup_payments' ||
                        name === 'setup_shipping' ||
                        name === 'setup_testing_sale' ||
                        name === 'setup_meli_sync' ? (
                          <SubmitButton
                            appearance="primary"
                            onClick={goToConfig(ctaLink, name)}
                            disabled={setLoading(name)}
                          >
                            {ctaText}
                          </SubmitButton>
                        ) : (
                          <Button
                            as={LinkRoute}
                            appearance="primary"
                            to={
                              isBlocked ? TRANSACTION_FEES_BASE_ROUTE : ctaLink
                            }
                            onClick={trackEvent(ctaLink, name)}
                          >
                            {ctaText}
                          </Button>
                        )}
                      </Box>
                      {name === 'setup_meli_sync' ||
                      name === 'setup_shipping' ||
                      name === 'setup_payments' ? (
                        <Link
                          fontSize="caption"
                          textDecoration="none"
                          appearance="primary"
                          as="button"
                          disabled={
                            isLoadingMeliSync ||
                            isLoadingShipping ||
                            isLoadingPayments
                          }
                          onClick={taskConfig(name, secondaryCtaLink)}
                        >
                          {secondaryCtaText}
                          {(isLoadingMeliSync || isLoadingShipping) && (
                            <Spinner color="currentColor" size={10} />
                          )}
                        </Link>
                      ) : (
                        <Link
                          fontSize="caption"
                          textDecoration="none"
                          appearance="primary"
                          as={name === 'setup_meli_sync' ? 'button' : 'a'}
                          target={ctaExternal ? '_blank' : '_self'}
                          disabled={isLoadingMeliSync}
                          href={
                            isBlocked
                              ? TRANSACTION_FEES_BASE_ROUTE
                              : secondaryCtaLink
                          }
                          onClick={SECONDARY_LOG_EVENTS[name]}
                        >
                          {secondaryCtaText}
                          {isLoadingMeliSync && (
                            <Spinner color="currentColor" size={10} />
                          )}
                        </Link>
                      )}
                    </Box>
                  </Box>
                </Accordion.Body>
              </Accordion.Item>
            ),
        )}
      </Accordion>
    </div>
  );
}

OnboardingTaskAccordion.Skeleton = Skeleton;

export default OnboardingTaskAccordion;
