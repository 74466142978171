import { useTranslation } from 'react-i18next';
import { FulfillmentPreferenceTypeEnum } from '@tiendanube/common';
import { Domain } from '@tiendanube/common/src/enums';
import { useToastProgress } from 'App/components/ToastProgressContext';
import { useToast } from 'commons/hooks';

interface UseActionsToastResult {
  onError: () => void;
  onCancelSuccess: () => void;
  onArchiveSuccess: () => void;
  onPaidSuccess: () => void;
  onPackSuccess: () => void;
  onFulfillSuccess: (isEdit?: boolean) => void;
  onLoading: (isEdit?: boolean) => void;
  onOpenSuccess: () => void;
}

function useActionsToast(
  fulfillmentPrefeenceType?: FulfillmentPreferenceTypeEnum,
): UseActionsToastResult {
  const { t } = useTranslation(Domain.ORDERS);
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();

  const onError = () => {
    closeToastProgress();
    addToast({
      label: t('toast.error'),
      appearance: 'danger',
    });
  };

  const onCancelSuccess = () => {
    closeToastProgress();
    addToast({
      label: t('toast.successCancel'),
      appearance: 'success',
    });
  };

  const onArchiveSuccess = () => {
    closeToastProgress();
    addToast({
      label: t('toast.successArchive'),
      appearance: 'success',
    });
  };

  const onPaidSuccess = () => {
    closeToastProgress();
    addToast({
      label: t('toast.successPaid'),
      appearance: 'success',
    });
  };

  const onPackSuccess = () => {
    closeToastProgress();
    addToast({
      label: t('toast.successPacked'),
      appearance: 'success',
    });
  };

  const onFulfillSuccess = (isEdit?: boolean) => {
    if (!fulfillmentPrefeenceType) return;
    closeToastProgress();
    addToast({
      label: isEdit
        ? t('toast.successFulfillEdit')
        : t(`toast.successFulfill-${fulfillmentPrefeenceType}`),
      appearance: 'success',
    });
  };

  const onLoading = (isEdit?: boolean) => {
    addToastProgress({
      label: isEdit ? t('toast.loadingEdit') : t('toast.loading'),
    });
  };

  const onOpenSuccess = () => {
    closeToastProgress();
    addToast({
      label: t('toast.successOpen'),
      appearance: 'success',
    });
  };

  return {
    onError,
    onLoading,
    onCancelSuccess,
    onArchiveSuccess,
    onPaidSuccess,
    onPackSuccess,
    onFulfillSuccess,
    onOpenSuccess,
  };
}

export default useActionsToast;
