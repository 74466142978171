import { ExternalInvoicesResponseDto } from '@tiendanube/common';
import { VirtualDataList } from 'commons/components';
import { PagoNubeInvoiceHistoryDataTableRow } from './components';
import PagoNubeInvoiceHistoryDataTableSkeleton from './PagoNubeInvoiceHistoryDataTableSkeleton';

interface PagoNubeInvoiceHistoryDataTableProps {
  data: ExternalInvoicesResponseDto[];
  onRefresh: () => Promise<void>;
  fetchMore: () => void;
  shouldShowSpinner: boolean;
}

function PagoNubeInvoiceHistoryDataTable({
  data,
  onRefresh,
  fetchMore,
  shouldShowSpinner,
}: PagoNubeInvoiceHistoryDataTableProps): JSX.Element {
  return (
    <VirtualDataList<ExternalInvoicesResponseDto>
      data={data}
      itemContent={(index, invoice) => (
        <PagoNubeInvoiceHistoryDataTableRow key={index} {...invoice} />
      )}
      onRefresh={onRefresh}
      endReached={fetchMore}
      showFooter={shouldShowSpinner}
    />
  );
}

PagoNubeInvoiceHistoryDataTable.Skeleton =
  PagoNubeInvoiceHistoryDataTableSkeleton;

export default PagoNubeInvoiceHistoryDataTable;
