import { Card, Title, Select, Box, Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import { toStatusType } from 'App/rtk';
import { ErrorScreen } from 'commons/components';
import SubmitButton from 'commons/components/SubmitButton';
import { useForm, useToastStatus } from 'commons/hooks';
import { useRefreshInfoToSync } from 'domains/Auth/hooks';
import {
  useGetCurrenciesQuery,
  useUpdateMainCurrencyMutation,
} from 'domains/Configurations/I18n/i18nApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import Skeleton from './Skeleton';
import { mainCurrencyData } from '../../utils';

function MainCurrencyCard() {
  const t = useTranslationConfigurations('languagesAndCurrencies');
  const { t: tCommon } = useTranslation('common');
  const { refreshInfo } = useRefreshInfoToSync();

  const { data, isLoading, isSuccess, isError, refetch } =
    useGetCurrenciesQuery();

  const [updateMainCurrency, { status, isLoading: loadingUpdate }] =
    useUpdateMainCurrencyMutation();

  const mainCurrency = useGetCurrenciesQuery(undefined, {
    selectFromResult: ({ data }) => mainCurrencyData(data),
  });

  useToastStatus({
    status: toStatusType(status),
    error: t('toastErrorUpate'),
    success: t('toastSuccessUpdate'),
  });

  const { handleOnChange, handleOnSubmit, values, isDirty } = useForm({
    initialValues: mainCurrency,
    onSubmit: async ({ mainCurrencyId }) => {
      await updateMainCurrency(mainCurrencyId).unwrap();
      refreshInfo(true);
    },
  });

  const handleSelectChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLSelectElement>) => {
    handleOnChange({ name, value });
  };

  const submitButtonDisabled =
    values.mainCurrencyId === mainCurrency.mainCurrencyId;

  return (
    <Card padding="none">
      <Prompt when={isDirty} message={tCommon('exitEdit.info')} />
      <Card.Header padding="base">
        <Title as="h3">{t('mainCurrency.title')}</Title>
      </Card.Header>
      <Card.Body padding="base">
        <Box display="grid" gridGap="4" paddingBottom="4">
          <Text>{t('mainCurrency.subtitle')}</Text>
          {isLoading && <Skeleton />}
          {isSuccess && (
            <>
              <FormField.Select
                id="main-currency"
                label={t('mainCurrency.labelCurrency')}
                name="mainCurrencyId"
                onChange={handleSelectChange}
                defaultValue={mainCurrency.mainCurrencyId}
              >
                {data?.map(({ currencyId, currencyName }) => (
                  <Select.Option
                    key={`currency-${currencyId}`}
                    value={currencyId}
                    label={currencyName}
                  />
                ))}
              </FormField.Select>
              <SubmitButton
                onClick={handleOnSubmit}
                isLoading={loadingUpdate}
                disabled={submitButtonDisabled}
                appearance="primary"
              >
                {t('buttonSave')}
              </SubmitButton>
            </>
          )}
          {isError && (
            <Box paddingBottom="4">
              <ErrorScreen onRetry={refetch} description={t('errorState')} />
            </Box>
          )}
        </Box>
      </Card.Body>
    </Card>
  );
}

export default MainCurrencyCard;
