import { DomainTranslations } from 'App/i18n';
import { Locale } from 'App/i18n/enums';
import esAR from './nuvemchat.es-AR.json';
import esCL from './nuvemchat.es-CL.json';
import esCO from './nuvemchat.es-CO.json';
import esMX from './nuvemchat.es-MX.json';
import ptBR from './nuvemchat.pt-BR.json';

const nuvemchatTranslations: DomainTranslations = {
  name: 'nuvemchat',
  languages: {
    [Locale.ARGENTINA]: esAR,
    [Locale.MEXICO]: esMX,
    [Locale.BRAZIL]: ptBR,
    [Locale.CHILE]: esCL,
    [Locale.COLOMBIA]: esCO,
  },
};

export default nuvemchatTranslations;
