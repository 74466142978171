import {
  Box,
  Card,
  Checkbox,
  Radio,
  Tag,
  Text,
  Title,
  Input,
} from '@nimbus-ds/components';
import { Stack } from 'commons/components';

function RefundOrderPageSkeleton(): JSX.Element {
  return (
    <Stack column align="stretch">
      <Card>
        <Box display="flex" gap="4" justifyContent="space-between">
          <Stack column align="stretch" justify="flex-start" spacing="tight">
            <Radio.Skeleton />
            <Text.Skeleton width="100px" />
          </Stack>
          <Box justifyContent="flex-start">
            <Text.Skeleton width="160px" />
          </Box>
        </Box>

        <Box
          id="divider"
          backgroundColor="neutral-surfaceHighlight"
          height="1px"
        />

        <Box display="flex" gap="4" justifyContent="space-between">
          <Stack spacing="tight" justify="flex-start">
            <Radio.Skeleton width="200px" />
            <Tag.Skeleton height="20px" width="50px" />
          </Stack>
          <Box justifyContent="flex-start">
            <Input.Skeleton width="150px" />
          </Box>
        </Box>
      </Card>

      <Card>
        <Card.Header>
          <Title.Skeleton height="25px" width="150px" />
        </Card.Header>
        <Card.Body>
          <Stack column align="stretch">
            <Checkbox.Skeleton width="50px" />
          </Stack>
        </Card.Body>
      </Card>
    </Stack>
  );
}

export default RefundOrderPageSkeleton;
