import { useEffect } from 'react';
import {
  BaseCard,
  Input,
  Select,
  InterfaceNameValue,
  Grid,
} from '@tiendanube/components';
import { CancelAndSaveButtons } from 'commons/components';
import Stack from 'commons/components/Stack';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import ColombianFiscalDataFormSkeleton from './ColombianFiscalDataFormSkeleton';
import {
  CC,
  IVA_NO_RESPONSABLE,
  NIT,
  PERSONA_JURIDICA,
  PERSONA_MORAL,
  RESPONSABLE_IVA,
} from './constants';
import {
  ColombianNumberIdType,
  ColombianPersonType,
  ColombianVatConditionType,
} from './types';
import useGetBillingCities from '../../../hooks/useGetCities';

export interface FiscalDataformColombia {
  name: string;
  numberId: string;
  cityId?: string;
  address: string;
  vatCondition?: ColombianVatConditionType;
  personType?: ColombianPersonType;
  numberIdType?: ColombianNumberIdType;
}

interface ColombianFiscalDataFormProps {
  values: FiscalDataformColombia;
  onChange: (data: InterfaceNameValue) => void;
  onSave: () => void;
  onCancel: () => void;
  errors: Partial<FiscalDataformColombia>;
  isLoading: boolean;
  isDirty: boolean;
}

function ColombianFiscalDataForm({
  values,
  onChange,
  onSave,
  onCancel,
  errors,
  isLoading,
  isDirty,
}: ColombianFiscalDataFormProps) {
  const t = useTranslationBilling();
  const nameHelpText = t(errors.name || '');
  const numberIdHelpText = t(errors.numberId || '');
  const addressHelpText = t(errors.address || '');
  const { cities, fetchCities } = useGetBillingCities();
  useEffect(() => {
    fetchCities();
  }, [fetchCities]);

  const personTypes = [
    {
      label: t('invoicesInfoPage.person_type_persona_juridica'),
      value: PERSONA_JURIDICA,
    },
    {
      label: t('invoicesInfoPage.person_type_persona_moral'),
      value: PERSONA_MORAL,
    },
  ];

  const vatConditions = [
    {
      label: t('invoicesInfoPage.vat_condition_exento_iva'),
      value: IVA_NO_RESPONSABLE,
    },
    {
      label: t('invoicesInfoPage.vat_condition_exento_iva_responsable'),
      value: RESPONSABLE_IVA,
    },
  ];

  const numberTypeIds = [
    { label: t('invoicesInfoPage.number_id_type_NIT'), value: NIT },
  ];

  if (values.personType === PERSONA_MORAL) {
    numberTypeIds.push({
      label: t('invoicesInfoPage.number_id_type_CC'),
      value: CC,
    });
  } else if (values.personType === PERSONA_JURIDICA) {
    values.numberIdType = NIT;
  }

  return (
    <>
      <BaseCard>
        <BaseCard.Body>
          <Grid spacing="loose">
            <Grid.Row>
              {[
                <Grid.Column key={1}>
                  <Stack column align="stretch">
                    <Select
                      label={t('invoicesInfoPage.person_type')}
                      name="personType"
                      value={values.personType}
                      placeholder={t(
                        'invoicesInfoPage.person_type_placeholder',
                      )}
                      options={personTypes}
                      onChange={onChange}
                    />

                    <Select
                      label={t('invoicesInfoPage.vat_condition')}
                      name="vatCondition"
                      value={values.vatCondition}
                      placeholder={t(
                        'invoicesInfoPage.vat_condition_placeholder',
                      )}
                      options={vatConditions}
                      onChange={onChange}
                    />

                    <Input
                      type="text"
                      label={t('invoicesInfoPage.address')}
                      name="address"
                      value={values.address}
                      helpText={addressHelpText}
                      appearance={
                        addressHelpText ? 'validation_error' : 'default'
                      }
                      onChange={onChange}
                    />
                  </Stack>
                </Grid.Column>,
                <Grid.Column key={2}>
                  <Stack column align="stretch">
                    <Input
                      type="text"
                      label={t('invoicesInfoPage.name')}
                      name="name"
                      value={values.name}
                      helpText={nameHelpText}
                      appearance={nameHelpText ? 'validation_error' : 'default'}
                      onChange={onChange}
                    />

                    <Stack>
                      <Select
                        label={t('invoicesInfoPage.number_id_type')}
                        name="numberIdType"
                        value={values.numberIdType}
                        placeholder={t(
                          'invoicesInfoPage.number_id_type_placeholder',
                        )}
                        options={numberTypeIds}
                        onChange={onChange}
                      />

                      <Input
                        type="text"
                        label={t('invoicesInfoPage.number_id')}
                        name="numberId"
                        value={values.numberId}
                        helpText={numberIdHelpText}
                        appearance={
                          numberIdHelpText ? 'validation_error' : 'default'
                        }
                        onChange={onChange}
                      />
                    </Stack>
                    <Select
                      label={t('invoicesInfoPage.city_id')}
                      name="cityId"
                      placeholder={t(
                        'invoicesInfoPage.number_id_type_placeholder',
                      )}
                      value={String(values.cityId)}
                      options={cities}
                      onChange={onChange}
                    />
                  </Stack>
                </Grid.Column>,
              ]}
            </Grid.Row>
          </Grid>
        </BaseCard.Body>
      </BaseCard>
      <CancelAndSaveButtons
        isLoading={isLoading}
        onCancel={onCancel}
        onSave={onSave}
        isDisabled={!isDirty}
      />
    </>
  );
}

ColombianFiscalDataForm.Skeleton = ColombianFiscalDataFormSkeleton;

export default ColombianFiscalDataForm;
