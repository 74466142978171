import { useCallback, useMemo } from 'react';
import { Box, Text } from '@nimbus-ds/components';
import { InvoiceIcon } from '@nimbus-ds/icons';
import { ConceptCode, PaymentBillingEngineDto } from '@tiendanube/common';
import { CurrencyPrice } from 'commons/components';
import { dateFormat, Format } from 'commons/utils/date';
import { useGetDownloadInvoiceMutation } from 'domains/Billing/Checkout/CheckoutApi';
import { useFrequencyTranslation } from 'domains/Billing/Checkout/hooks';
import useTranslatePaymentMethod from 'domains/Billing/Checkout/hooks/useTranslatePaymentMethod';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import PaymentHistory from '../../PaymentHistory';

interface PaymentDataProps {
  concept: ConceptCode;
  appId?: string;
  payment: PaymentBillingEngineDto;
}

export default function PaymentData({
  payment: { charges, paidAt, amountCurrency, gatewayDetail },
  concept,
  appId,
}: Readonly<PaymentDataProps>) {
  const t = useTranslationBilling(
    'checkout.conceptDetailContent.paymentHistory',
  );
  const relevantCharges = useMemo(() => {
    const isAppRelated = concept === 'app-cost' || concept === 'app-extra-fee';
    return charges.filter(
      (charge) =>
        (charge.concept === concept ||
          (isAppRelated && charge.concept === 'app-extra-fee')) &&
        (!isAppRelated || charge.appId === appId),
    );
  }, [charges, concept, appId]);
  const { translateGatewayPaymentMethod } = useTranslatePaymentMethod();

  const [getDownloadInvoice, { isLoading }] = useGetDownloadInvoiceMutation({
    fixedCacheKey: PaymentHistory.name,
  });

  const invoiceId = relevantCharges[0]?.invoiceId;

  const onDownloadInvoice = useCallback(() => {
    if (isLoading || !invoiceId) return;
    getDownloadInvoice(invoiceId)
      .unwrap()
      .then(({ url }) => {
        window.open(url, '_blank');
      });
  }, [getDownloadInvoice, invoiceId, isLoading]);

  const { getFrequencyDescriptor } = useFrequencyTranslation();
  const price = useMemo(
    () =>
      relevantCharges?.reduce((acc, { amountValue }) => acc + amountValue, 0),
    [relevantCharges],
  );

  if (!paidAt || !relevantCharges.length) return null;
  const subscriptionData = relevantCharges[0].subscription;
  const isVariableCharge = relevantCharges[0]?.concept === 'app-extra-fee';

  return (
    <>
      <hr />
      <Box
        paddingX="3"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        paddingY="0-5"
      >
        <Box display="flex" flexDirection="column" gap="1-5">
          <Text>{dateFormat(paidAt, Format.DD_MM_YYYY, 'UTC')}</Text>
          <Text color="neutral-textLow">
            {translateGatewayPaymentMethod(gatewayDetail.paymentMethod)}
          </Text>
          {subscriptionData && (
            <Text color="neutral-textLow">
              {`${t('subscription')} ${getFrequencyDescriptor(
                subscriptionData,
              )}`}
            </Text>
          )}
          {isVariableCharge && (
            <Text color="neutral-textLow">{t('extraFeeDescription')}</Text>
          )}
          {!!invoiceId && (
            <Box
              color="primary-interactive"
              display="flex"
              flexDirection="row"
              gap="1"
              alignItems="center"
              onClick={onDownloadInvoice}
            >
              <InvoiceIcon size="small" />
              <Text color="primary-interactive">{t('downloadInvoice')}</Text>
            </Box>
          )}
        </Box>
        <CurrencyPrice price={price} currency={amountCurrency} />
      </Box>
    </>
  );
}
