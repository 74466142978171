import { ChangeEvent, useEffect } from 'react';
import { FileUploader } from '@nimbus-ds/components';
import classNames from 'classnames';
import { AI_PRODUCT_IMAGE_EDIT_CUSTOM_BACKGROUND_ENABLED } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { Stack } from 'commons/components';
import { trackingAiEditImageReplaceBgWithImageCtaClick } from 'domains/Ai/tracking';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { backgrounds, BackgroundGalleryElement } from './backgrounds';
import './BackgroundGallery.scss';

const CUSTOM_IMAGE_ID = 'custom-image';
const ACCEPT_TYPE_FILE = 'image/jpeg,image/gif,image/png,image/webp';
const REMOVED_BACKGROUND_ID = 'background-04'; // This background is removed when custom background is enabled

export interface CustomBackgroundImage {
  id: string;
  base64: string;
}

interface BackgroundGalleryProps {
  selectedBackground?: BackgroundGalleryElement | CustomBackgroundImage;
  onSelect: (
    background: BackgroundGalleryElement | CustomBackgroundImage,
  ) => void;
}

function BackgroundGallery({
  selectedBackground,
  onSelect,
}: Readonly<BackgroundGalleryProps>) {
  const t = useTranslationCatalog(
    'products.modal.editPhoto.actions.changeBackground.modal.imageBg',
  );

  useEffect(() => {
    trackingAiEditImageReplaceBgWithImageCtaClick();
  }, []);

  const [allowCustomBackground] = useHasTags(
    AI_PRODUCT_IMAGE_EDIT_CUSTOM_BACKGROUND_ENABLED,
  );

  const onChangeFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onSelect({
          id: CUSTOM_IMAGE_ID,
          base64: reader.result as string,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Stack column spacing="tight" align="flex-start">
      <Stack gap="3" alignSelf="stretch" align="stretch" wrap>
        {allowCustomBackground && (
          <div className={classNames('stratus--background-gallery-tile')}>
            <FileUploader
              placeholder={t('upload')}
              onChange={onChangeFile}
              accept={ACCEPT_TYPE_FILE}
            />
          </div>
        )}
        {backgrounds.map((background) =>
          allowCustomBackground &&
          background.id === REMOVED_BACKGROUND_ID ? null : (
            <div
              key={background.id}
              onClick={() => onSelect(background)}
              className={classNames('stratus--background-gallery-tile', {
                '--selected': selectedBackground === background,
              })}
              style={{ backgroundImage: `url(${background.thumbnailUrl})` }}
            />
          ),
        )}
      </Stack>
    </Stack>
  );
}

export default BackgroundGallery;
