import { Alert } from '@tiendanube/components';
import Link from 'commons/components/LegacyLink';
import Text from 'commons/components/LegacyText';
import goToAdmin from 'commons/utils/gotToAdmin';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface CouponsAlertProps {
  show: boolean;
  onDismiss: () => void;
}
function CouponsAlertError({ show, onDismiss }: CouponsAlertProps) {
  const t = useTranslationBilling();

  return (
    <Alert
      title={t('couponsPage.couponsAlert.errorTitle')}
      appearance="warning"
      show={show}
      onDismiss={onDismiss}
    >
      <Text>{t('couponsPage.couponsAlert.errorText')}</Text>
      <br />
      <Link underline onClick={goToAdmin('/account/checkout/finish/')}>
        {t('couponsPage.couponsAlert.linkText')}
      </Link>
    </Alert>
  );
}

export default CouponsAlertError;
