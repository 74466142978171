import { useEffect } from 'react';
import { Button, Text } from '@nimbus-ds/components';
import { TrashIcon } from '@nimbus-ds/icons';
import { useParams } from 'react-router';
import { useNavegate } from 'App/hooks';
import { Stack, SubmitButton } from 'commons/components';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components/IonPageStratus';
import { useAsyncFunc, useForm, useToast } from 'commons/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import Skeleton from './Skeleton';
import useEditCouponPage from './useEditCouponPage';
import CouponForm, {
  CouponErrorsInterface,
  CouponValuesInterface,
  validationSchema,
} from '../../components/CouponForm';
import { CouponDeletedAlert } from '../../components/CouponForm/components/CouponDeletedAlert';
import CouponModalConfirmationAction from '../../components/CouponModalConfirmationAction';
import useCoupon from '../../hooks/useCoupon';
import useCoupons from '../../hooks/useCoupons';
import {
  trackingDeletedCouponDetail,
  trackingExitEditCouponPage,
} from '../../tracking';
import './EditCouponPage.scss';
import { CouponPageModes } from '../types';

function EditCouponPage() {
  const { goBack } = useNavegate();
  const { addToast } = useToast();
  const t = useTranslationMarketing('coupons.editCoupon');
  const { id } = useParams<{ id: string }>();
  const { editCoupon, deactivateCoupon, removeCoupon, activateCoupon } =
    useCoupons();
  const { coupon } = useCoupon(id);
  const { initialValues, changedValues } = useEditCouponPage(coupon);

  useEffect(() => {
    if (coupon?.status === 'deleted') {
      trackingDeletedCouponDetail();
    }
  }, [coupon?.status]);

  const [handleEditCoupon, isLoading, isError, clearError, errorMessage] =
    useAsyncFunc<CouponValuesInterface, Promise<void>>(async (data) => {
      if (!data) return;
      await editCoupon(id, changedValues(data));
      addToast({
        label: t('success'),
        appearance: 'success',
      });
      trackingExitEditCouponPage(id);
      goBack();
    });

  const {
    errors,
    values,
    isDirty,
    isValidating,
    handleOnChange,
    handleOnSubmit,
    setFieldValue,
  } = useForm<CouponValuesInterface, CouponErrorsInterface>({
    initialValues,
    validationSchema: validationSchema(coupon?.used || 0),
    onSubmit: handleEditCoupon,
  });

  if (!coupon) return <Skeleton />;

  const doActionOnCouponAndGoBack =
    (action: (id: string) => Promise<void>) => async () => {
      await action(id);
      trackingExitEditCouponPage(id);
      goBack();
    };

  const isDeleted = coupon.status === 'deleted';

  const headerActions = !isDeleted ? (
    <Stack spacing="tight">
      {coupon.status === 'disabled' ? (
        <CouponModalConfirmationAction
          action="activate"
          onAction={doActionOnCouponAndGoBack(activateCoupon)}
        >
          {(openModal) => <Button onClick={openModal}>{t('activate')}</Button>}
        </CouponModalConfirmationAction>
      ) : (
        <CouponModalConfirmationAction
          action="deactivate"
          onAction={doActionOnCouponAndGoBack(deactivateCoupon)}
        >
          {(openModal) => (
            <Button onClick={openModal}>{t('deactivate')}</Button>
          )}
        </CouponModalConfirmationAction>
      )}
      <CouponModalConfirmationAction
        action="delete"
        onAction={doActionOnCouponAndGoBack(removeCoupon)}
      >
        {(openModal) => (
          <Button onClick={openModal}>
            <TrashIcon />
            {t('delete')}
          </Button>
        )}
      </CouponModalConfirmationAction>
      <SubmitButton
        onClick={handleOnSubmit}
        isLoading={isValidating || isLoading}
        disabled={!isDirty}
      >
        {t('submit')}
      </SubmitButton>
    </Stack>
  ) : null;

  return (
    <IonPageStratus
      width="medium"
      headerContent={
        <HeaderContent title={coupon.code} actions={headerActions} />
      }
      headerTop={<HeaderTop navigation={{ onClick: goBack }} />}
    >
      <Text>
        {t(coupon.used === 0 ? 'uses_zero' : 'uses', { count: coupon.used })}
      </Text>
      <CouponDeletedAlert couponIsDeleted={isDeleted} />
      <CouponForm
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        isValidating={isValidating}
        isLoading={isLoading}
        handleOnChange={handleOnChange}
        handleOnSubmit={handleOnSubmit}
        isError={isError}
        errorMessageTranslation={t('error')}
        clearError={clearError}
        errorMessage={errorMessage}
        isDirty={isDirty}
        showActiveInput
        couponIsUsed={coupon.used > 0}
        currentUses={coupon.used}
        confirmText={t('submit')}
        readOnly={isDeleted}
        couponId={id}
        couponStatus={coupon.status}
        mode={CouponPageModes.EDIT}
      />
    </IonPageStratus>
  );
}

export default EditCouponPage;
