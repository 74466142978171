import { useEffect } from 'react';
import { PagoNubeInvoiceHistoryDataTable } from './components';
import PagoNubeInvoiceHistoryMobileSkeleton from './PagoNubeInvoiceHistoryMobileSkeleton';
import useExternalInvoiceHistoryMobile from './useExternalInvoiceHistoryMobile';
import { ExternalInvoiceMerchantService } from '../../types';

function PagoNubeInvoiceHistoryMobile(): JSX.Element {
  const {
    fetchExternalInvoiceHistory,
    fetchMoreExternalInvoiceHistory,
    externalInvoiceHistory,
    status,
    updateStatus,
  } = useExternalInvoiceHistoryMobile(ExternalInvoiceMerchantService.PAGO_NUBE);

  useEffect(() => {
    fetchExternalInvoiceHistory();
  }, [fetchExternalInvoiceHistory]);

  const shouldShowSpinner = updateStatus === 'loading';

  return status === 'loading' ? (
    <PagoNubeInvoiceHistoryDataTable.Skeleton />
  ) : (
    <PagoNubeInvoiceHistoryDataTable
      data={externalInvoiceHistory.results}
      fetchMore={fetchMoreExternalInvoiceHistory}
      onRefresh={fetchExternalInvoiceHistory}
      shouldShowSpinner={shouldShowSpinner}
    />
  );
}

PagoNubeInvoiceHistoryMobile.Skeleton = PagoNubeInvoiceHistoryMobileSkeleton;

export default PagoNubeInvoiceHistoryMobile;
