import { useEffect } from 'react';
import { Box, Button } from '@nimbus-ds/components';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import HelpLink from 'App/HelpLink';
import {
  ErrorScreen,
  HeaderContent,
  IonPageStratus,
  useResponsive,
  RenderByStatus,
} from 'commons/components';
import { useBoolean } from 'commons/hooks';
import { useGetTags } from 'domains/Auth/hooks';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { WhatsappForm, WhatsappFormSkeleton } from './components';
// TODO: Replace this illustration with the one defined by the Design team
import { ReactComponent as EmptyImage } from './empty.svg';
import { useGetWhatsappQuery } from '../../whatsappApi';

export function WhatsappPage() {
  const t = useTranslationConfigurations('whatsapp');
  const { isError, isLoading, refetch, data } = useGetWhatsappQuery();
  const [showEmpty, , closeEmpty] = useBoolean(true);
  const { isMobile } = useResponsive();
  const isRevmapMenu = useGetTags().includes(REVAMP_MENU_V1);
  // We add hardcoded sizes temporarily until we get the image and the original size.
  // This is done to match the dimensions of the image with the other empty states.
  const imgWidth = isMobile ? 243 : 492;

  useEffect(() => {
    if (data?.phone) closeEmpty();
  }, [data?.phone, closeEmpty]);

  return (
    <IonPageStratus
      width="small"
      headerContent={
        <HeaderContent title={t(isRevmapMenu ? 'titleCard' : 'title')} />
      }
    >
      <RenderByStatus
        success={<WhatsappForm />}
        loading={<WhatsappFormSkeleton />}
        error={<ErrorScreen onRetry={refetch} />}
        isLoading={isLoading}
        isError={isError}
        isEmpty={showEmpty}
        empty={
          <EmptyMessage
            title={t('emptyState.title')}
            text={t('emptyState.description')}
            illustration={
              <Box padding="4">
                <EmptyImage width={imgWidth} />
              </Box>
            }
            actions={
              <Button appearance="primary" onClick={closeEmpty}>
                {t('emptyState.cta')}
              </Button>
            }
          />
        }
      />
      <HelpLink
        title={t('helpLink.title')}
        previousValue=""
        currentViewTracking=""
        linkURL={t('helpLink.href')}
        icon="both"
      />
    </IonPageStratus>
  );
}
