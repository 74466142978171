import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useToast } from 'commons/hooks';
import { openWindow } from 'commons/utils/window';
import {
  getExternalInvoiceLinkStatus,
  fetchExternalInvoiceDownloadLink,
} from 'domains/Billing/Invoices/invoicesSlice';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

function useDownloadExternalInvoice() {
  const dispatch = useAppDispatch();
  const status = useSelector(getExternalInvoiceLinkStatus);
  const t = useTranslationBilling();
  const { addToast } = useToast();

  useEffect(() => {
    if (status === 'error') {
      addToast({
        label: t('invoiceHistory.errors.download'),
        appearance: 'danger',
      });
    }
  }, [status, addToast, t]);

  const getLink = async (id: string) => {
    if (status !== 'loading') {
      const { url } = await dispatch(
        fetchExternalInvoiceDownloadLink(id),
      ).unwrap();
      openWindow(url, true);
    }
  };

  return {
    getLink,
    status,
  };
}

export default useDownloadExternalInvoice;
