import { GetDocumentSettingsResponseDto } from '@tiendanube/common';

export enum DocumentType {
  CONTENT_DECLARATION = 'contentDeclaration',
  LABEL = 'label',
  RECEIPT = 'receipt',
  NUVEM_ENVIO_LABEL = 'nuvemEnvioLabel',
}

export const FULFILLMENT_ORDER_ID_IDENTIFIER = 'ffoId';

export const DOCUMENT_SETTINGS_EMPTY_STATE: GetDocumentSettingsResponseDto = {
  showLabelRecipientDocument: false,
  showOrdersNote: false,
  showProductsInfo: false,
  showSenderInfo: false,
  showReceiptStub: false,
  showMerchantNote: false,
  showPrices: false,
  downloadDocument: false,
  pageSize: {
    code: 'A4',
    width: '21',
    height: '29.7',
  },
  availablePageSizes: [
    {
      code: 'A4',
      width: '21',
      height: '29.7',
    },
  ],
};

export const CUSTOM_PAGE_SIZE_CODE = 'custom';
export const MINIMUM_WIDTH_ALLOWED = 5;
export const MAXIMUM_WIDTH_ALLOWED = 29.7;
export const MINIMUM_HEIGHT_ALLOWED = 8;
export const MAXIMUM_HEIGHT_ALLOWED = 42;

export const SHIPPING_TYPE = 'ship';
export const PICKUP_TYPE = 'pickup';
