import { useState } from 'react';
import { Tabs } from '@tiendanube/components';
import { ImportInProcessModal } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import ExportProductsCard from '../ExportProductsCard';
import { ImportProductsCard } from '../ImportProductsCard';

function TabsImportAndExportProducts(): JSX.Element {
  const t = useTranslationCatalog();
  const [activeTab, setActiveTab] = useState(0);

  const handleChangeTab = (activeTab: number) => {
    setActiveTab(activeTab);
  };

  return (
    <Tabs activeTab={activeTab} onChange={handleChangeTab}>
      <Tabs.Item
        label={t('products.importExportPage.export.export')}
        key="exportProducts"
      >
        <ExportProductsCard />
      </Tabs.Item>
      <Tabs.Item
        label={t('products.importExportPage.import.import')}
        key="importProducts"
      >
        <ImportInProcessModal>
          <ImportProductsCard showUpdateOptions />
        </ImportInProcessModal>
      </Tabs.Item>
    </Tabs>
  );
}

export default TabsImportAndExportProducts;
