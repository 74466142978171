import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ShippingMethodsResponseDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  getShippingMethodsAction,
  getShippingMethodsData,
  getShippingMethodsStatus,
} from '../../deliveryMethodsSlice';

const EMPTY_SHIPPING_METHODS: ShippingMethodsResponseDto = {
  others: [],
  branch: [],
  table: [],
  pickupPoint: [],
};

function useGetShippingMethods() {
  const dispatch = useAppDispatch();
  const shippingMethods = useSelector(getShippingMethodsData);
  const status = useSelector(getShippingMethodsStatus);

  const fetchShippingMethods = useCallback(
    () => dispatch(getShippingMethodsAction()),
    [dispatch],
  );

  const groupedShippingMethods = mergePickupPointMethods(shippingMethods);

  return {
    isIdle: shippingMethods === null,
    shippingMethods: groupedShippingMethods ?? EMPTY_SHIPPING_METHODS,
    fetchShippingMethods,
    status,
  };
}

function mergePickupPointMethods(
  shippingMethods: ShippingMethodsResponseDto | null,
): ShippingMethodsResponseDto | null {
  if (!shippingMethods?.pickupPoint) {
    return shippingMethods;
  }

  // Start with pickupPoint merged into branch
  const mergedShippingMethods = {
    ...shippingMethods,
    branch: [
      ...(shippingMethods.branch || []),
      ...(shippingMethods.pickupPoint || []),
    ],
  };

  // Remove pickupPoint key
  delete mergedShippingMethods.pickupPoint;

  return mergedShippingMethods;
}

export default useGetShippingMethods;
