import {
  Title,
  Tooltip,
  IconButton,
  Icon,
  Card,
  Box,
} from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import './SortCheckoutPaymentsCard.scss';

type SortCheckoutPaymentsCardProps = {
  children: React.ReactNode;
  title: string;
  tooltipText?: string;
};

function SortCheckoutPaymentsCard({
  children,
  title,
  tooltipText,
}: SortCheckoutPaymentsCardProps) {
  return (
    <div className="stratus--sort-payments-card">
      <Card padding="none">
        <Card.Header padding="base">
          <Box display="flex" alignItems="center" gap="2">
            <Title as="h4">{title}</Title>
            {!!tooltipText && (
              <Tooltip position="top" content={tooltipText}>
                <IconButton
                  size="1rem"
                  source={
                    <Icon
                      color="primary-interactive"
                      source={<InfoCircleIcon />}
                    />
                  }
                />
              </Tooltip>
            )}
          </Box>
        </Card.Header>
        <Card.Body padding="none">{children}</Card.Body>
      </Card>
    </div>
  );
}

export default SortCheckoutPaymentsCard;
