import { IonPageStratus, useResponsive } from 'commons/components';
import { OrderListEmptyDesktop } from './OrderListEmptyDesktop';
import { OrderListEmptyMobile } from './OrderListEmptyMobile';

export function OrderListEmpty() {
  const { isMobile } = useResponsive();

  return (
    <IonPageStratus width="medium">
      {isMobile ? <OrderListEmptyMobile /> : <OrderListEmptyDesktop />}
    </IonPageStratus>
  );
}
