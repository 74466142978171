import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { TablePricesDto } from '@tiendanube/common';
import tablePricesService from 'domains/Customers/TablePrices/tablePricesService/tablePricesService';
import {
  statusType,
  TablePricesInterface,
} from 'domains/Customers/TablePrices/tablePricesSlice/types';

const initialState: TablePricesInterface = {
  tablePrices: {
    data: [],
    status: statusType.idle,
  },
};

export const getTablePrices = createAsyncThunk<TablePricesDto[], void>(
  'tablePrices/getTablePrices',
  async () => await tablePricesService.getTablePrices(),
);

const tablePrices = createSlice({
  name: 'tablePrices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTablePrices.pending, (state) => {
        state.tablePrices.status = statusType.loading;
      })
      .addCase(getTablePrices.fulfilled, (state, action) => {
        state.tablePrices.data = action.payload;
        state.tablePrices.status = statusType.success;
      })
      .addCase(getTablePrices.rejected, (state) => {
        state.tablePrices.status = statusType.error;
      });
  },
});

export const { reducer } = tablePrices;
