import { useSelector } from 'react-redux';
import { getUserInfo } from '../../authSlice/authSelectors';

interface UseGetUserInfoResult {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAtStore: number;
  isEmployee: boolean;
  userConfirmed?: boolean;
  isMain: boolean;
}

function useGetUserInfo(): UseGetUserInfoResult {
  const userInfo = useSelector(getUserInfo);
  return userInfo;
}

export default useGetUserInfo;
