import { Tag } from '@nimbus-ds/components';
import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { Country } from '@tiendanube/common/src/enums';
import { ShareIcon } from '@tiendanube/icons';
import { SOCIAL_GOOGLE_ADMIN, SOCIAL_TIKTOK_ADMIN } from 'App/featuresFlags';
import { useHasTags, useHandleMenuClick } from 'App/hooks';
import { trackingMenuPrimarySalesChannelClick } from 'App/tracking';
import goToAdmin from 'commons/utils/gotToAdmin';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { useGetCountry } from 'domains/Auth/hooks';
import {
  menuSecondaryFacebookClick,
  menuSecondaryGoogleShoppingClick,
  menuSecondaryInstagramClick,
  menuSecondaryTikTokClick,
} from './tracking';

const PATHS = {
  google: '/apps/social-google/',
  productFeeds: '/marketing/product_feeds/',
  otherSalesChannels: '/sales-channels/apps',
  socialTiktok: '/apps/social-tiktok/',
  facebookNewAdmin: '/social/meta/#/facebook',
  instagramNewAdmin: '/social/meta/#/instagram',
};

type MenuItem = {
  onClick: () => void;
  external?: boolean;
  children: string;
  active: boolean;
  href: string;
  label?: string;
};

function SalesChannelsMenu() {
  const { validateRoles } = useRoles();
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem, handleClickAccordion } = useHandleMenuClick();
  const storeCountry = useGetCountry();

  const getIsMenuMetaActive = (path: string) =>
    `${pathname}${window.location.hash}`.startsWith(path);

  const facebookActive = getIsMenuMetaActive(PATHS.facebookNewAdmin);
  const instagramActive = getIsMenuMetaActive(PATHS.instagramNewAdmin);

  const hasMetaPermission = validateRoles('meta');
  const hasGooglePermission = validateRoles('google_shopping');
  const hasTiktokPermission = validateRoles('tiktok');

  const [hasSocialGoogleAdmin, hasSocialTiktokAdmin] = useHasTags([
    SOCIAL_GOOGLE_ADMIN,
    SOCIAL_TIKTOK_ADMIN,
  ]);

  const ACTIVE_SALES_CHANNELS_PATHS_REGEX = new RegExp(
    `^(${Object.values(PATHS).join('|')})`,
  );

  const activeSalesChannelsPath =
    ACTIVE_SALES_CHANNELS_PATHS_REGEX.test(pathname) ||
    ACTIVE_SALES_CHANNELS_PATHS_REGEX.test(
      `${pathname}${window.location.hash}`,
    );

  const handleMenuSalesChannelClick = () => {
    trackingMenuPrimarySalesChannelClick();
    handleClickAccordion();
  };

  const menuItemsBaseProps = (name: string, salesChannelPath: string) => ({
    children: `${t(`menu.salesChannels.more.${name}`)}`,
    active:
      name === 'facebook'
        ? facebookActive
        : name === 'instagram'
        ? instagramActive
        : pathname === salesChannelPath,
    href: salesChannelPath,
  });

  const getSalesChannel = (name: string, salesChannelPath: string) => ({
    ...menuItemsBaseProps(name, salesChannelPath),
    onClick: goToAdmin(salesChannelPath),
    external: true,
    href: '/admin' + salesChannelPath,
  });

  const getSocialSalesChannel = (
    name: string,
    salesChannelPath: string,
    trackingCallback?: () => void,
  ) => ({
    ...menuItemsBaseProps(name, salesChannelPath),
    onClick: () => {
      trackingCallback?.call({});
      handleClickItem();
    },
    external: false,
    href: salesChannelPath,
  });

  const [facebook, instagram, google, socialTiktok] = [
    getSocialSalesChannel(
      'facebook',
      PATHS.facebookNewAdmin,
      menuSecondaryFacebookClick,
    ),
    getSocialSalesChannel(
      'instagram',
      PATHS.instagramNewAdmin,
      menuSecondaryInstagramClick,
    ),
    getSocialSalesChannel(
      'google',
      PATHS.google,
      menuSecondaryGoogleShoppingClick,
    ),
    getSocialSalesChannel(
      'tiktok',
      PATHS.socialTiktok,
      menuSecondaryTikTokClick,
    ),
  ];

  const productFeeds = getSalesChannel('productFeeds', PATHS.productFeeds);

  const getMenuItems = () => {
    const menuItems: MenuItem[] = [];

    if (hasMetaPermission) {
      menuItems.push(facebook);

      // Meta Shops is only available in BR and MX
      if ([Country.BR, Country.MX].includes(storeCountry)) {
        menuItems.push(instagram);
      }
    }

    if (hasGooglePermission) {
      if (hasSocialGoogleAdmin) {
        menuItems.push(google);
      } else {
        menuItems.push(productFeeds);
      }
    }

    if (
      ([Country.CL, Country.BR, Country.MX, Country.CO].includes(
        storeCountry,
      ) ||
        hasSocialTiktokAdmin) &&
      hasTiktokPermission
    ) {
      menuItems.push(socialTiktok);
    }

    return menuItems;
  };

  const menuItems = getMenuItems();

  if (menuItems.length === 0) {
    return null;
  }

  return (
    <MenuButton.Accordion
      contentid="content-sales-channels"
      menuButton={{
        id: 'control-sales-channels',
        label: t(`menu.salesChannels.more.socialNetworks`),
        startIcon: ShareIcon,
        'aria-controls': 'content-sales-channels',
        onClick: handleMenuSalesChannelClick,
      }}
      open={activeSalesChannelsPath}
      active={activeSalesChannelsPath}
      as={Link}
      to={PATHS.facebookNewAdmin}
    >
      {menuItems.map((item, index) =>
        !item.external ? (
          <MenuButton
            key={index}
            as={Link}
            label={item.children}
            onClick={item.onClick}
            to={item.href}
            active={item.active}
          >
            {!!item.label && <Tag appearance="primary">{item.label}</Tag>}
          </MenuButton>
        ) : (
          <MenuButton
            key={index}
            as="a"
            onClick={item.onClick}
            label={item.children}
            href={item.href}
            active={item.active}
          />
        ),
      )}
    </MenuButton.Accordion>
  );
}

export default SalesChannelsMenu;
