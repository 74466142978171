import { Box } from '@nimbus-ds/components';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { Layout } from 'commons/components';
import { useGetTags } from 'domains/Auth/hooks';
import { StoreStatusAlert } from 'domains/Billing/Checkout/components';
import {
  NoFiscalDataAlert,
  AccountData,
  ApplyDiscount,
  RecurrentPaymentsStatus,
  FreePlanCard,
  PaymentsTabs,
} from '../';

function PlansAndSubscriptionsV2(): JSX.Element {
  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);

  return (
    <>
      <StoreStatusAlert includePaymentAlert={false} />
      <NoFiscalDataAlert />
      <Layout
        mainContent
        left={
          <Box display="flex" flexDirection="column" gap="4">
            <FreePlanCard />
            <PaymentsTabs />
            <RecurrentPaymentsStatus />
          </Box>
        }
        right={
          <Box display="flex" flexDirection="column" gap="4">
            <AccountData />
            {isRevampMenu && <ApplyDiscount />}
          </Box>
        }
        orderMobile="reverse"
      />
    </>
  );
}

export default PlansAndSubscriptionsV2;
