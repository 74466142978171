import { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Country } from '@tiendanube/common/src/enums';
import ToggleMigratedDomain from 'App/components/Migrations/ToggleMigratedDomain';
import ToggleMigratedOldDomain from 'App/components/Migrations/ToggleMigratedOldDomain';
import { FEATURE_ABANDONED_CARTS, FEATURE_DISCOUNTS } from 'App/features';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import useShowByFeature from 'App/hooks/useShowByFeature';
import Account from 'domains/Account';
import { CanceledStorePage, SocialNetworksPage } from 'domains/Account/pages';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import {
  useAdminDisabled,
  useCanEnterAdmin,
  useGetTags,
  useTransactionFeeAdminDisabled,
  useValidateCurrentUserSession,
} from 'domains/Auth/hooks';
import LogoutPage from 'domains/Auth/pages/LogoutPage';
import BillingPlans from 'domains/Billing/BillingPlans';
import Checkout from 'domains/Billing/Checkout';
import { CHECKOUT_BASE_ROUTE } from 'domains/Billing/Checkout/checkoutRoutes';
import BillingCoupons from 'domains/Billing/Coupons/Coupons';
import Invoices, { InvoicesInfoPage } from 'domains/Billing/Invoices';
import TransactionFees from 'domains/Billing/TransactionFees';
import { TRANSACTION_FEES_ROUTES } from 'domains/Billing/TransactionFees/transactionFeesRoutes';
import UpsellSoftBlockPage from 'domains/Billing/UpsellFlow/pages/UpsellSoftBlockPage';
import useUpsellSoftBlock from 'domains/Billing/UpsellFlow/pages/UpsellSoftBlockPage/useUpsellSoftBlock';
import { Categories, Inventory, Products } from 'domains/Catalog';
import { CONFIGURATIONS_ROUTES } from 'domains/Configurations/configurationsRoutes';
import { useDomainSoftBlock } from 'domains/Configurations/Domains/hooks';
import {
  DomainSoftBlockPage,
  DomainsPageWithSoftBlock,
} from 'domains/Configurations/Domains/pages';
import { ExternalCodes } from 'domains/Configurations/ExternalCodes';
import I18n from 'domains/Configurations/I18n';
import { Notifications } from 'domains/Configurations/Notifications';
import SeoRedirects from 'domains/Configurations/SeoRedirects/SeoRedirects';
import { seoRedirectsRoutes } from 'domains/Configurations/SeoRedirects/seoRedirectsRoutes';
import { SettingsCheckoutPage } from 'domains/Configurations/SettingsCheckout';
import { WhatsappPage } from 'domains/Configurations/Whatsapp';
import Customers from 'domains/Customers';
import { useGetHasAccessToCustomers } from 'domains/Customers/hooks';
import Messages from 'domains/Customers/Messages';
import { MESSAGES_ROUTES } from 'domains/Customers/Messages/messagesRoutes';
import Dashboard, {
  dashboardRoutes,
  useDisabledAdminForRoles,
} from 'domains/Dashboard';
import useCloudflareBlocked from 'domains/Dashboard/hooks/useCloudflareBlocked';
import CloudflareBlockPage from 'domains/Dashboard/pages/CloudflareBlock';
import { marketingRoutes } from 'domains/Marketing';
import Coupons from 'domains/Marketing/Coupons';
import FreeShipping, {
  FREE_SHIPPING_ROUTES,
} from 'domains/Marketing/FreeShipping';
import MarketingApps from 'domains/Marketing/MarketingApps';
import MarketingAutomation from 'domains/Marketing/MarketingAutomation';
import Promotions from 'domains/Marketing/Promotions';
import NuvemChat from 'domains/NuvemChat/NuvemChat';
import { CONTENT_PAGES_ROUTES, ContentPages } from 'domains/Online';
import { InfoMessage, infoMessageRoutes } from 'domains/Online/InfoMessage';
import Menus from 'domains/Online/Menus';
import { MENUS_ROUTES } from 'domains/Online/Menus/menusRoutes';
import {
  ContactInfoPage,
  PasswordProtectedPage,
  ThemesPage,
  ThemesUpsellPage,
} from 'domains/Online/Themes/pages';
import { useThemesRequireUpsell } from 'domains/Online/Themes/pages/ThemesPage/hooks/useThemesRequireUpsell';
import { THEMES_ROUTES } from 'domains/Online/Themes/themesRoutes';
import Orders from 'domains/Orders';
import AbandonedCarts from 'domains/Orders/AbandonedCart';
import DraftOrders from 'domains/Orders/DraftOrders';
import PartnersApps from 'domains/PartnersApps';
import NuvemAppPage from 'domains/PartnersApps/pages/NuvemAppPage';
import Payments from 'domains/Payments/Payments';
import { PAYMENTS_BASE_ROUTE } from 'domains/Payments/paymentsRoutes';
import { salesChannelsRoutes } from 'domains/SalesChannels';
import SalesChannels from 'domains/SalesChannels/SalesChannels';
import DeliveryMethods from 'domains/Shipping/DeliveryMethods';
import { SHIPPING_METHODS_BASE_ROUTE } from 'domains/Shipping/DeliveryMethods/shippingRoutes';
import Locations from 'domains/Shipping/Locations';
import Statistics, { useHasAccessToStats } from 'domains/Statistics';
import { STATS_ROUTES } from 'domains/Statistics/statisticsRoutes';
import AppLayout from '../components/AppLayout';
import Menu from '../components/Menu';
import { NavTabsWeb } from '../components/Navtabs';
import PrivateRoutes from '../components/PrivateRoute';
import { isDomainWebAdmin } from '../components/PrivateRoute/utils';
import RouteByCountry from '../components/RouteByCountry';
import Topbar from '../components/Topbar';

function RoutesWeb() {
  const { validateRoles } = useRoles();
  const isAdminDisabled = useAdminDisabled();
  const canEnterAdmin = useCanEnterAdmin();
  const isTransactionFeeAdminDisabled = useTransactionFeeAdminDisabled();
  const isDisabledAdminForRoles = useDisabledAdminForRoles();
  const { cloudflareBlocked, dismissCloudflareBlock, hardBlock } =
    useCloudflareBlocked();
  const hasAbandonedCartsFeature = useShowByFeature(FEATURE_ABANDONED_CARTS);
  const hasAccessToCustomers = useGetHasAccessToCustomers();
  const hasDiscountFeature = useShowByFeature(FEATURE_DISCOUNTS);
  const hasAccessToStats = useHasAccessToStats();
  const isCurrentUserSessionValid = useValidateCurrentUserSession();
  const themesRequiresUpsell = useThemesRequireUpsell();
  const { upsellSoftBlock } = useUpsellSoftBlock();
  const { domainSoftBlock } = useDomainSoftBlock();
  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);
  const currentUrl = window.location;

  if (currentUrl.pathname === '/admin/') {
    return null;
  }

  if (isCurrentUserSessionValid === null) {
    return null;
  }

  if (isDomainWebAdmin(currentUrl.hostname) && !isCurrentUserSessionValid) {
    return <LogoutPage />;
  }

  const hasNuvemPagoRole = validateRoles('nuvem_pago');

  return (
    <Switch>
      <PrivateRoutes path="/">
        <Switch>
          {cloudflareBlocked && (
            <Route path="*">
              <CloudflareBlockPage
                onDismiss={dismissCloudflareBlock}
                hardBlock={hardBlock}
              />
            </Route>
          )}

          <Route path="/cloudflare-block">
            <CloudflareBlockPage
              onDismiss={dismissCloudflareBlock}
              isBlocked={cloudflareBlocked}
              hardBlock={hardBlock}
            />
          </Route>

          <AppLayout
            menu={<Menu device="desktop" />}
            navtabs={<NavTabsWeb />}
            topbar={<Topbar />}
          >
            <Switch>
              {isAdminDisabled && !canEnterAdmin && (
                <Switch>
                  <Route exact path="/account/invoices/info">
                    <Suspense fallback={null}>
                      <InvoicesInfoPage />
                    </Suspense>
                  </Route>
                  <Route exact path="/account/plans">
                    <Suspense fallback={null}>
                      <BillingPlans />
                    </Suspense>
                  </Route>
                  <Route path={CHECKOUT_BASE_ROUTE}>
                    <Suspense fallback={null}>
                      <Checkout />
                    </Suspense>
                  </Route>
                  <Route path="*">
                    <Suspense fallback={null}>
                      <CanceledStorePage />
                    </Suspense>
                  </Route>
                </Switch>
              )}
              {domainSoftBlock && (
                <Route path="*">
                  <DomainSoftBlockPage />
                </Route>
              )}

              {upsellSoftBlock && (
                <Route path="*">
                  <UpsellSoftBlockPage />
                </Route>
              )}

              <Route path={dashboardRoutes.dashboard}>
                <ToggleMigratedOldDomain domain="dashboard">
                  <Dashboard />
                </ToggleMigratedOldDomain>
              </Route>

              {isTransactionFeeAdminDisabled && (
                <Switch>
                  <Route path="/account">
                    <Suspense fallback={null}>
                      {validateRoles('payments_and_subscriptions') ? (
                        <>
                          <Account />
                          <BillingPlans />
                          <BillingCoupons />
                          <Invoices />
                          <TransactionFees />
                          <Checkout />
                        </>
                      ) : (
                        <>
                          <Account />
                          <Invoices />
                          {validateRoles('nuvem_envio') && <Checkout />}
                        </>
                      )}
                    </Suspense>
                  </Route>
                  <Redirect from="/" to={dashboardRoutes.dashboard} exact />
                  <Redirect
                    from="*"
                    to={
                      isDisabledAdminForRoles
                        ? dashboardRoutes.dashboard
                        : TRANSACTION_FEES_ROUTES.transactionFees
                    }
                    exact
                  />
                </Switch>
              )}

              <Route path="/apps">
                <Suspense fallback={null}>
                  <PartnersApps />
                </Suspense>
              </Route>

              {validateRoles('design') && (
                <Route path="/blog">
                  <Suspense fallback={null}>
                    <NuvemAppPage code="blog" />
                  </Suspense>
                </Route>
              )}

              {validateRoles('orders') && (
                <Route path="/orders">
                  <Suspense fallback={null}>
                    <Orders />
                  </Suspense>
                </Route>
              )}
              {validateRoles('draft_orders') && (
                <Route path="/draft-orders">
                  <ToggleMigratedOldDomain domain="draft-orders">
                    <Suspense fallback={null}>
                      <DraftOrders />
                    </Suspense>
                  </ToggleMigratedOldDomain>
                </Route>
              )}
              {validateRoles('abandoned_carts') && hasAbandonedCartsFeature && (
                <Route path="/abandoned-carts">
                  <ToggleMigratedDomain domain="abandoned-carts">
                    <Suspense fallback={null}>
                      <AbandonedCarts />
                    </Suspense>
                  </ToggleMigratedDomain>
                </Route>
              )}

              {validateRoles('products') && (
                <Route path="/products">
                  <Suspense fallback={null}>
                    <Products />
                  </Suspense>
                </Route>
              )}
              {validateRoles('products') && (
                <Route path="/inventory">
                  <Suspense fallback={null}>
                    <Inventory />
                  </Suspense>
                </Route>
              )}
              {validateRoles('products') && (
                <Route path="/categories">
                  <ToggleMigratedOldDomain domain="categories">
                    <Suspense fallback={null}>
                      <Categories />
                    </Suspense>
                  </ToggleMigratedOldDomain>
                </Route>
              )}
              {hasAccessToCustomers && (
                <Route path="/customers">
                  <ToggleMigratedOldDomain domain="customers">
                    <Suspense fallback={null}>
                      <Customers />
                    </Suspense>
                  </ToggleMigratedOldDomain>
                </Route>
              )}
              {hasAccessToCustomers && (
                <Route path={MESSAGES_ROUTES.messages}>
                  <ToggleMigratedOldDomain domain="messages">
                    <Suspense fallback={null}>
                      <Messages />
                    </Suspense>
                  </ToggleMigratedOldDomain>
                </Route>
              )}

              {validateRoles(['full', 'marketing']) && (
                <Route path={FREE_SHIPPING_ROUTES.freeShippingList}>
                  <ToggleMigratedOldDomain domain="free-shipping">
                    <Suspense fallback={null}>
                      <FreeShipping />
                    </Suspense>
                  </ToggleMigratedOldDomain>
                </Route>
              )}

              {validateRoles(['full', 'marketing']) && (
                <Route path="/promotions">
                  <ToggleMigratedOldDomain domain="promotions">
                    <Suspense fallback={null}>
                      <Promotions />
                    </Suspense>
                  </ToggleMigratedOldDomain>
                </Route>
              )}

              {validateRoles(['full', 'marketing']) && hasDiscountFeature && (
                <Route path="/coupons">
                  <ToggleMigratedOldDomain domain="coupons">
                    <Suspense fallback={null}>
                      <Coupons />
                    </Suspense>
                  </ToggleMigratedOldDomain>
                </Route>
              )}

              {validateRoles(['full', 'marketing']) && (
                <Route path={marketingRoutes.featuredApplications}>
                  <ToggleMigratedOldDomain domain="marketing-apps">
                    <Suspense fallback={null}>
                      <MarketingApps />
                    </Suspense>
                  </ToggleMigratedOldDomain>
                </Route>
              )}

              {validateRoles(['full', 'nuvem_marketing']) && (
                <Route path={marketingRoutes.marketingAutomation}>
                  <Suspense fallback={null}>
                    <MarketingAutomation />
                  </Suspense>
                </Route>
              )}

              {hasAccessToStats && (
                <Route path={STATS_ROUTES.stats}>
                  <Statistics />
                </Route>
              )}

              <Route path="/account">
                <Suspense fallback={null}>
                  <Switch>
                    {validateRoles('payments_and_subscriptions') ? (
                      <>
                        <Account />
                        <BillingPlans />
                        <BillingCoupons />
                        <Invoices />
                        <TransactionFees />
                        <Checkout />
                      </>
                    ) : (
                      <>
                        <Account />
                        <Invoices />
                        {validateRoles('nuvem_envio') && <Checkout />}
                      </>
                    )}
                  </Switch>
                </Suspense>
              </Route>

              {hasNuvemPagoRole && (
                <RouteByCountry
                  allowedCountries={[Country.AR, Country.BR, Country.MX]}
                  path="/nuvempago"
                >
                  <Suspense fallback={null}>
                    <NuvemAppPage code="nuvempago-commons" />
                  </Suspense>
                </RouteByCountry>
              )}
              {validateRoles('nuvem_chat') && (
                <Route path="/chat">
                  <Suspense fallback={null}>
                    <NuvemChat />
                  </Suspense>
                </Route>
              )}
              {hasNuvemPagoRole && (
                <RouteByCountry allowedCountries={[Country.BR]} path="/lending">
                  <Suspense fallback={null}>
                    <NuvemAppPage code="lending" />
                  </Suspense>
                </RouteByCountry>
              )}

              {validateRoles(['full', 'pos']) && (
                <Route path="/pos">
                  <Suspense fallback={null}>
                    <NuvemAppPage code="pos" />
                  </Suspense>
                </Route>
              )}

              {validateRoles(['full', 'locations']) && (
                <Route path="/settings/locations">
                  <Suspense fallback={null}>
                    <Locations />
                  </Suspense>
                </Route>
              )}

              {validateRoles('payments') && (
                <Route path={PAYMENTS_BASE_ROUTE}>
                  <Suspense fallback={null}>
                    <Payments />
                  </Suspense>
                </Route>
              )}

              <Route path="/settings/metafields">
                <Suspense fallback={null}>
                  {validateRoles('full') && <NuvemAppPage code="metafields" />}
                </Suspense>
              </Route>

              {validateRoles('meta') && (
                <Route path="/social/meta">
                  <Suspense fallback={null}>
                    <ToggleMigratedOldDomain domain="facebook">
                      <NuvemAppPage code="social-meta" />
                    </ToggleMigratedOldDomain>
                  </Suspense>
                </Route>
              )}

              <Route path="/filters">
                <NuvemAppPage code="filter-settings" />
              </Route>

              <Route path="/price-tables">
                <Suspense fallback={null}>
                  {validateRoles('full') && (
                    <NuvemAppPage code="price-tables" />
                  )}
                </Suspense>
              </Route>

              <Route exact path={THEMES_ROUTES.contactInfo}>
                <ContactInfoPage />
              </Route>

              {validateRoles('preferences_checkout') && (
                <Route exact path={CONFIGURATIONS_ROUTES.settingsCheckout}>
                  <ToggleMigratedOldDomain domain="settings-checkout">
                    <SettingsCheckoutPage />
                  </ToggleMigratedOldDomain>
                </Route>
              )}

              {validateRoles('preferences_advanced') && (
                <Route exact path={CONFIGURATIONS_ROUTES.externalCodes}>
                  <Suspense fallback={null}>
                    <ToggleMigratedOldDomain domain="external-codes">
                      <ExternalCodes />
                    </ToggleMigratedOldDomain>
                  </Suspense>
                </Route>
              )}

              <Route path={CONTENT_PAGES_ROUTES.pagesList}>
                <ToggleMigratedOldDomain domain="content-pages">
                  <ContentPages />
                </ToggleMigratedOldDomain>
              </Route>

              {validateRoles('seo_redirects') && (
                <Route path={seoRedirectsRoutes.redirects}>
                  <SeoRedirects />
                </Route>
              )}

              <Route path={THEMES_ROUTES.passwordProtected}>
                <ToggleMigratedOldDomain domain="password-protected">
                  <PasswordProtectedPage />
                </ToggleMigratedOldDomain>
              </Route>

              {themesRequiresUpsell && validateRoles(['full']) && (
                <Route path={THEMES_ROUTES.themesUpsell}>
                  <ThemesUpsellPage />
                </Route>
              )}

              <Route path={THEMES_ROUTES.themes}>
                <ToggleMigratedOldDomain domain="themes">
                  <ThemesPage />
                </ToggleMigratedOldDomain>
              </Route>

              {validateRoles(['full', 'shipping']) && (
                <Route path={SHIPPING_METHODS_BASE_ROUTE}>
                  <Suspense fallback={null}>
                    <DeliveryMethods />
                  </Suspense>
                </Route>
              )}

              <Route path={salesChannelsRoutes.salesChannels}>
                <SalesChannels />
              </Route>

              {validateRoles('domains') && (
                <Route path={CONFIGURATIONS_ROUTES.domains}>
                  <ToggleMigratedOldDomain domain="domains">
                    <DomainsPageWithSoftBlock />
                  </ToggleMigratedOldDomain>
                </Route>
              )}

              <Route path={MENUS_ROUTES.menus}>
                <ToggleMigratedOldDomain domain="navigation">
                  <Menus />
                </ToggleMigratedOldDomain>
              </Route>

              {validateRoles('i18n') && (
                <Route path={CONFIGURATIONS_ROUTES.i18n} exact>
                  <ToggleMigratedOldDomain domain="i18n">
                    <Suspense fallback={null}>
                      <I18n />
                    </Suspense>
                  </ToggleMigratedOldDomain>
                </Route>
              )}

              <Route path={CONFIGURATIONS_ROUTES.whatsapp} exact>
                <ToggleMigratedOldDomain domain="whatsapp">
                  <Suspense fallback={null}>
                    <WhatsappPage />
                  </Suspense>
                </ToggleMigratedOldDomain>
              </Route>

              <Route path={infoMessageRoutes.infoMessage} exact>
                <ToggleMigratedOldDomain domain="info-message">
                  <Suspense fallback={null}>
                    <InfoMessage />
                  </Suspense>
                </ToggleMigratedOldDomain>
              </Route>

              {validateRoles('emails') && (
                <Route path={CONFIGURATIONS_ROUTES.notifications}>
                  <Notifications />
                </Route>
              )}

              {isRevampMenu && (
                <Route path={THEMES_ROUTES.socialNetworks} exact>
                  <Suspense fallback={null}>
                    <SocialNetworksPage />
                  </Suspense>
                </Route>
              )}

              <Redirect from="*" to={dashboardRoutes.dashboard} exact />
            </Switch>
          </AppLayout>
        </Switch>
      </PrivateRoutes>
    </Switch>
  );
}

export default RoutesWeb;
