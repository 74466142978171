import { DataList } from '@nimbus-ds/patterns';
import { CheckoutPaymentOptionDto } from '@tiendanube/common/src/domains/checkout/CheckoutPaymentsResponseDto';
import { SortableDataList } from 'commons/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import { SortCheckoutPaymentsCard, SortCheckoutPaymentListItem } from '../..';
import { TRANSLATE_PREFIX } from '../CheckoutPaymentOptionsSorterModal';
import './SortCheckoutPaymentsListCustom.scss';

interface SortCheckoutPaymentsListCustomProps {
  data: Array<CheckoutPaymentOptionDto>;
  onChange: (data: CheckoutPaymentOptionDto[]) => void;
}

function SortCheckoutPaymentsListCustom({
  data,
  onChange,
}: SortCheckoutPaymentsListCustomProps) {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  const fixedPayments = data.filter((item) => 'fixedPriority' in item);
  const draggablePayments = data.filter((item) => !('fixedPriority' in item));

  const handleOnChange = (data?: (CheckoutPaymentOptionDto | undefined)[]) => {
    const newData: CheckoutPaymentOptionDto[] = [];
    data?.map((item) => !!item && newData.push(item));
    onChange([...fixedPayments, ...newData]);
  };

  return (
    <>
      {fixedPayments.length > 0 && (
        <SortCheckoutPaymentsCard
          title={t('titleFixedPaymentMethod')}
          tooltipText={t('tooltip')}
        >
          <DataList bottomDivider={false}>
            {fixedPayments.map((item) => (
              <DataList.Row borderBottomWidth="none" key={item.id} id={item.id}>
                <SortCheckoutPaymentListItem
                  opacity={0.5}
                  item={item}
                  key={item.id}
                />
              </DataList.Row>
            ))}
          </DataList>
        </SortCheckoutPaymentsCard>
      )}
      {draggablePayments.length > 0 && (
        <div className="stratus--sort-payments-list-custom">
          <SortCheckoutPaymentsCard
            title={
              fixedPayments.length
                ? t('titleOtherWays')
                : t('titlePaymentMethod')
            }
          >
            <SortableDataList<CheckoutPaymentOptionDto>
              items={draggablePayments}
              onChange={handleOnChange}
              renderItem={(item: CheckoutPaymentOptionDto) => (
                <SortCheckoutPaymentListItem item={item} key={item.id} />
              )}
            />
          </SortCheckoutPaymentsCard>
        </div>
      )}
    </>
  );
}

export default SortCheckoutPaymentsListCustom;
