import { Box, Text, Icon } from '@nimbus-ds/components';
import {
  ChevronLeftIcon,
  WhatsappIcon,
  BrowserSearchIcon,
  ChatDotsIcon,
  TelephoneIcon,
} from '@nimbus-ds/icons';
import { Menu } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Country } from '@tiendanube/common/src/enums';
import {
  canCountryLink,
  phoneCallLink,
  whatsappCountryLink,
} from 'App/components/SupportChanels/components/SupportCard/components';
import { useSupportChat } from 'App/components/SupportChatContext';
import { FEATURE_CS_PHONE_CALL, FEATURE_CS_WHATSAPP } from 'App/features';
import { openWindow } from 'commons/utils/window';
import { SUPPORT_PHONECALL, SUPPORT_WHATSAPP } from 'config/upsellFlowSources';
import { useGetCountry } from 'domains/Auth/hooks';
import { useTranslationBilling } from 'domains/Billing';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';

interface AccountMenuContentProps {
  onClose: () => void;
}

function HelpMenuContent({ onClose }: Readonly<AccountMenuContentProps>) {
  const { t: commonT } = useTranslation('common');
  const t = useTranslationBilling('supportChanels');
  const { openChat } = useSupportChat();
  const country = useGetCountry();

  const openInNewTab = (url: string) => {
    onClose();
    openWindow(url, true);
  };

  const onClickWhatsapp = useUpsellFlow({
    featureKey: FEATURE_CS_WHATSAPP,
    trackingSource: SUPPORT_WHATSAPP,
    asAside: true,
    callback: () => {
      openInNewTab(whatsappCountryLink[country] || whatsappCountryLink.default);
    },
  });

  const onClickPhoneCall = useUpsellFlow({
    featureKey: FEATURE_CS_PHONE_CALL,
    trackingSource: SUPPORT_PHONECALL,
    asAside: true,
    callback: () => {
      openInNewTab(phoneCallLink[country]);
    },
  });

  return (
    <>
      <Menu.Header>
        <Box display="flex" flexDirection="column" gap="4">
          <Box
            display="flex"
            gap="1"
            as="button"
            backgroundColor="transparent"
            borderColor="transparent"
            onClick={onClose}
            alignItems="center"
            cursor="pointer"
          >
            <Icon
              color="neutral-textHigh"
              source={<ChevronLeftIcon size={14} />}
            />
            <Text fontWeight="medium" lineClamp={1} color="neutral-textHigh">
              {commonT('topbar.help')}
            </Text>
          </Box>
        </Box>
      </Menu.Header>
      <Menu.Body>
        <Menu.Button
          label={t('types.centerAttention.title')}
          onClick={() => {
            openInNewTab(canCountryLink[country] || canCountryLink.default);
          }}
          startIcon={BrowserSearchIcon}
        />
        <Menu.Button
          label={t('types.chat.title')}
          onClick={openChat}
          startIcon={ChatDotsIcon}
        />
        <Menu.Button
          label={t('types.whatsapp.title')}
          onClick={onClickWhatsapp}
          startIcon={WhatsappIcon}
        />
        {country === Country.BR && (
          <Menu.Button
            label={t('types.call.title')}
            onClick={onClickPhoneCall}
            startIcon={TelephoneIcon}
          />
        )}
      </Menu.Body>
    </>
  );
}

export default HelpMenuContent;
