import useGetIsUserFullRole from 'domains/Auth/hooks/useGetIsUserFullRole';
import {
  CardMetafieldsList,
  UpsellAlertCard,
} from 'domains/Metafields/components';
import EmptyOnCard from 'domains/Metafields/components/EmptyCard';
import LinkToMetafieldsBasedOnUser from 'domains/Metafields/components/LinkToMetafieldsBasedOnUser';
import MetafieldsCategoriesDataList from 'domains/Metafields/components/MetafieldsCategoriesDataList';
import { RenderCategoryMetafieldWhen } from 'domains/Metafields/components/RenderMetafieldWhen';
import { useGetCategoriesMetafields } from 'domains/Metafields/hooks';
import {
  MetafieldSelectedInterface,
  SourceType,
} from 'domains/Metafields/types';
import { MetafieldSelectedsOrErrorType, MetafieldsSelected } from '../../types';

interface SectionCategoriesMetafieldsProps {
  selecteds: MetafieldSelectedsOrErrorType;
  apiSelecteds: MetafieldSelectedsOrErrorType;
  onChange: (metafield: MetafieldsSelected) => void;
  showError: boolean;
  showSuccess: boolean;
  showLoading: boolean;
}

function SectionCategoriesMetafields({
  selecteds,
  apiSelecteds,
  onChange,
  showError,
  showLoading,
  showSuccess,
}: Readonly<SectionCategoriesMetafieldsProps>) {
  const { hasInternals, metafields, apiMetafields, status, fetchMetafields } =
    useGetCategoriesMetafields();
  const isFullUser = useGetIsUserFullRole();

  const handleChange = (
    id: string,
    value: string | null,
    source: SourceType,
  ) => {
    const initialValue = {
      internals: selecteds,
      fromApi: apiSelecteds,
    };
    const isAdmin = source === SourceType.ADMIN;

    const selectedArray = selecteds !== 'error' ? selecteds : [];
    const apiSelectedArray = apiSelecteds !== 'error' ? apiSelecteds : [];
    const attr = isAdmin ? 'internals' : 'fromApi';

    const rest = isAdmin
      ? selectedArray.filter((current) => current.id !== id)
      : apiSelectedArray.filter((current) => current.id !== id);

    initialValue[attr] = [...rest, { id, value }];

    onChange?.(initialValue);
  };

  const isSelectedsError = selecteds === 'error' || apiSelecteds === 'error';

  const apiMetafieldsList = apiMetafields || [];
  const metafieldsList = metafields || [];

  return (
    <>
      {(showError || (status.isSuccess && isSelectedsError)) && (
        <CardMetafieldsList.ErrorState onRetry={fetchMetafields} />
      )}
      {showLoading && <CardMetafieldsList.Skeleton />}

      {showSuccess && (
        <>
          <RenderCategoryMetafieldWhen
            conditions={[
              {
                hasInternals: false,
                hasExternals: true,
                isAvailable: false,
              },
              {
                hasInternals: true,
                hasExternals: true,
                isAvailable: false,
              },
            ]}
          >
            {isFullUser ? (
              <CardMetafieldsList
                metafieldsTotal={apiMetafieldsList.length}
                metafieldSlot={
                  <UpsellAlertCard
                    owner="customer"
                    hasInternals={hasInternals}
                  />
                }
                apiMetafieldSlot={
                  <MetafieldsCategoriesDataList
                    source={SourceType.APP}
                    metafields={apiMetafieldsList}
                    selectedMetafields={
                      apiSelecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                  />
                }
              />
            ) : (
              <CardMetafieldsList
                metafieldSlot={
                  <MetafieldsCategoriesDataList
                    source={SourceType.APP}
                    metafields={apiMetafieldsList}
                    selectedMetafields={
                      apiSelecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                  />
                }
                metafieldsTotal={apiMetafieldsList.length}
              />
            )}
          </RenderCategoryMetafieldWhen>

          <RenderCategoryMetafieldWhen
            conditions={[
              {
                hasInternals: true,
                hasExternals: false,
                isAvailable: true,
              },
            ]}
          >
            <CardMetafieldsList
              metafieldSlot={
                <>
                  <MetafieldsCategoriesDataList
                    source={SourceType.ADMIN}
                    metafields={metafieldsList}
                    selectedMetafields={
                      selecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                  />
                  <LinkToMetafieldsBasedOnUser isFullUser={isFullUser} />
                </>
              }
              metafieldsTotal={metafieldsList.length}
            />
          </RenderCategoryMetafieldWhen>

          <RenderCategoryMetafieldWhen
            conditions={[
              {
                hasInternals: true,
                hasExternals: true,
                isAvailable: true,
              },
            ]}
          >
            <CardMetafieldsList
              metafieldSlot={
                <>
                  <MetafieldsCategoriesDataList
                    source={SourceType.ADMIN}
                    metafields={metafieldsList}
                    selectedMetafields={
                      selecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                  />
                  <LinkToMetafieldsBasedOnUser
                    isFullUser={isFullUser}
                    hasPaddingBottom
                  />
                </>
              }
              metafieldsTotal={metafieldsList.length + apiMetafieldsList.length}
              apiMetafieldSlot={
                <MetafieldsCategoriesDataList
                  source={SourceType.APP}
                  metafields={apiMetafieldsList}
                  selectedMetafields={
                    apiSelecteds as MetafieldSelectedInterface[]
                  }
                  onChange={handleChange}
                />
              }
            />
          </RenderCategoryMetafieldWhen>

          <RenderCategoryMetafieldWhen
            conditions={[
              {
                hasInternals: false,
                hasExternals: true,
                isAvailable: true,
              },
            ]}
          >
            {isFullUser ? (
              <CardMetafieldsList
                metafieldSlot={<EmptyOnCard />}
                apiMetafieldSlot={
                  <MetafieldsCategoriesDataList
                    source={SourceType.APP}
                    metafields={apiMetafieldsList}
                    selectedMetafields={
                      apiSelecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                  />
                }
                metafieldsTotal={apiMetafieldsList.length}
              />
            ) : (
              <CardMetafieldsList
                metafieldSlot={
                  <MetafieldsCategoriesDataList
                    source={SourceType.APP}
                    metafields={apiMetafieldsList}
                    selectedMetafields={
                      apiSelecteds as MetafieldSelectedInterface[]
                    }
                    onChange={handleChange}
                  />
                }
                metafieldsTotal={apiMetafieldsList.length}
              />
            )}
          </RenderCategoryMetafieldWhen>
        </>
      )}
    </>
  );
}

export default SectionCategoriesMetafields;
