import {
  Alert,
  Card,
  Icon,
  Label,
  Link,
  Tag,
  Text,
} from '@nimbus-ds/components';
import { CheckIcon, ChevronUpIcon, StarIcon } from '@nimbus-ds/icons';
import { Trans } from 'react-i18next';
import { PaymentProviderDto } from '@tiendanube/common';
import {
  PaymentProvidersCode,
  muvemPagoOrPagoNubeIds,
} from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { MethodNameAndImage, Stack } from 'commons/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import { PaymentMethodsTable, ProvidersActions } from '.';
import { PaymentMethodsEmptyTable } from './PaymentMethodsEmptyTable';
import DeprecatedPaymentProviderWarning from '../../DeprecatedPaymentProviderWarning';
import { OnPaymentProviderActionType } from '../../PaymentProviderActionModal';
import { PaymentProviderStatusLabel } from '../../PaymentProviderStatusLabel';

type PaymentProviderBodyProps = {
  paymentProvider: PaymentProviderDto;
  onAction: OnPaymentProviderActionType;
  onClose: () => void;
};

export function PaymentProviderBody({
  paymentProvider,
  onAction,
  onClose,
}: Readonly<PaymentProviderBodyProps>) {
  const t = useTranslationPayments();
  const {
    id,
    logo,
    features,
    isActive,
    mustCompleteInstallation,
    rates,
    processingInstallation,
    isDeprecated,
  } = paymentProvider;
  const isPagoNube = muvemPagoOrPagoNubeIds.includes(id);
  const hasPaymentMethods = rates.length > 0;

  return (
    <>
      <Card.Header>
        <Stack justify="space-between">
          <MethodNameAndImage image={logo} isPagoNube={isPagoNube} />
          <Stack>
            <Stack column align="flex-end" spacing="tight">
              <PaymentProviderStatusLabel
                isActive={isActive}
                mustCompleteInstallation={mustCompleteInstallation}
                processingInstallation={processingInstallation}
                isDeprecated={isDeprecated}
              />
              {isPagoNube && (
                <Tag appearance="primary">
                  <StarIcon size={12} />
                  {t('paymentProvider.recommended')}
                </Tag>
              )}
            </Stack>
            {!isPagoNube && (
              <Icon
                onClick={onClose}
                color="neutral-textHigh"
                source={<ChevronUpIcon />}
                cursor="pointer"
              />
            )}
          </Stack>
        </Stack>
      </Card.Header>
      <Card.Body>
        <Stack column align="flex-start">
          <Stack wrap spacing="tight">
            {features.map((feature) => (
              <Label key={feature}>
                <Icon color="primary-textHigh" source={<CheckIcon />} />
                <Text>{t(`paymentFeaturesCard.features.${feature}`)}</Text>
              </Label>
            ))}
          </Stack>
          {paymentProvider.id === PaymentProvidersCode.mercadopago &&
            paymentProvider.isDeprecated && (
              <Alert appearance="warning">
                <Text>
                  <Trans
                    t={t}
                    i18nKey="paymentProvider.settings.mercadopago.deprecatedAlert"
                    components={{
                      TyCLink: (
                        <Link
                          as="a"
                          appearance="neutral"
                          href={t(
                            'paymentProvider.settings.mercadopago.tycLink',
                          )}
                          target="_blank"
                        >
                          {' '}
                        </Link>
                      ),
                    }}
                  />
                </Text>
              </Alert>
            )}
          {paymentProvider.id === PaymentProvidersCode.paypal &&
            paymentProvider.isDeprecated && (
              <DeprecatedPaymentProviderWarning
                id={PaymentProvidersCode.paypal}
                name="PayPal"
              />
            )}
          {hasPaymentMethods ? (
            <PaymentMethodsTable providerId={id} rates={rates} />
          ) : (
            <PaymentMethodsEmptyTable provider={paymentProvider} />
          )}
        </Stack>
        <ProvidersActions
          paymentProvider={paymentProvider}
          onAction={onAction}
        />
      </Card.Body>
    </>
  );
}
