import { Alert } from '@tiendanube/components';
import { ExternalLinkIcon } from '@tiendanube/icons';
import Link from 'commons/components/LegacyLink';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
// TODO: handle redirect
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function FreeShippingAlert(): JSX.Element {
  const t = useTranslationShipping();

  return (
    <Alert show>
      <Stack column align="stretch">
        <Text size="caption">
          {t('shippingModalities.freeShippingAlert.text')}
        </Text>
        <Link
          iconPosition="end"
          size="small"
          underline
          icon={ExternalLinkIcon}
          iconSize="small"
        >
          {t('shippingModalities.freeShippingAlert.linkText')}
        </Link>
      </Stack>
    </Alert>
  );
}

export default FreeShippingAlert;
