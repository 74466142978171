import { useRef, useState, useEffect } from 'react';
import { i18nResponseDto } from '@tiendanube/common';
import { Card } from '@tiendanube/components';
import { CATALOG_UPLOAD_VIDEOS } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { Stack } from 'commons/components';
import { useToast } from 'commons/hooks';
import {
  useTrackFullCatalog,
  useTranslationCatalog,
} from 'domains/Catalog/hooks';
import {
  UploadImageType,
  UploadVideoType,
} from 'domains/Catalog/Products/productsServices';
import {
  trackingProductDetailImageClick,
  trackingProductDetailImageError,
  trackingProductDetailImageSuccess,
} from 'domains/Catalog/Products/tracking';
import {
  ImagePreviews,
  ImageGalleryModal,
  AlertCloneStatus,
} from './components';
import { AltTextGenerationContext } from './components/EditImageModal/EditImageActions/components/EditAltTextModal/components/AltTextGenerationModal';
import useAddImages from './useAddImages';
import { useMediaUploadStatus } from '../MediaUploadStatus';

export type MediaType = 'video' | 'image';

export interface ImageGalleryState {
  isLoading: boolean;
  isError: boolean;
  id: string;
  src: string;
  alt: i18nResponseDto;
  name: string;
  file?: File;
  publicUrl?: string; // May be different from src if it's a freshly uploaded image using a blob as its src
  isEdited: boolean;
  replacedImageId?: string; // Used to store the id of the image that will be replaced by this one
  base64?: string; // Used to store the base64 of the image when it's edited and not uploaded yet
  type: string;
  size: number;
  mimeType: string;
  mediaType: MediaType;
}

export type MediaGalleryState = ImageGalleryState;

interface ImageGalleryProps {
  onChange: (images: ImageGalleryState[], id?: string) => void;
  images: ImageGalleryState[];
  altTextGenerationContext: AltTextGenerationContext;
  isEdit?: boolean;
}

export interface ImageToUploadFromFile {
  id: string;
  file: File;
  mediaType: MediaType;
}
interface ImageToUploadFromEditedImage {
  id: string;
  base64: string;
}

// eslint-disable-next-line max-statements
function ImageGallery({
  onChange,
  images,
  altTextGenerationContext,
  isEdit,
}: Readonly<ImageGalleryProps>): JSX.Element {
  const sender = useTrackFullCatalog();
  const t = useTranslationCatalog();
  const [hasUploadVideos] = useHasTags([CATALOG_UPLOAD_VIDEOS]);
  const { addImage, addVideo } = useAddImages();
  const { addToast } = useToast();
  const imagesRef = useRef<ImageGalleryState[]>(images);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isOpenFileSystem, setIsOpenFileSystem] = useState(false);
  const [imageIdForEditOnOpen, setImageIdForEditOnOpen] = useState<
    string | undefined
  >();
  const [totalToUpload, setTotalToUpload] = useState(0);
  const [countSuccess, setCountSuccess] = useState(0);
  const [countError, setCountError] = useState(0);

  const { start: startMediaUpload, stop: stopMediaUpload } =
    useMediaUploadStatus();

  useEffect(() => {
    imagesRef.current = images;
  }, [images]);

  useEffect(() => {
    const isNotFinishedUpload =
      totalToUpload === 0 || totalToUpload !== countError + countSuccess;

    if (isNotFinishedUpload) return;
    resetCount();
    stopMediaUpload();
    if (countError === 0) {
      sender(() => {
        trackingProductDetailImageSuccess(isEdit);
      });
      addToast({
        label: t('products.toast.uploadPhotosSuccess'),
        appearance: 'success',
      });
      return;
    }
    if (countSuccess === 0) {
      sender(() => {
        trackingProductDetailImageError(isEdit);
      });
      addToast({
        label: t('products.toast.uploadPhotosError'),
        appearance: 'danger',
      });
      return;
    }
    addToast({
      label: t('products.toast.somePhotosNotUploaded'),
      appearance: 'warning',
    });
  }, [
    totalToUpload,
    countError,
    countSuccess,
    t,
    addToast,
    sender,
    isEdit,
    stopMediaUpload,
  ]);

  const resetCount = () => {
    setCountError(0);
    setCountSuccess(0);
    setTotalToUpload(0);
  };

  const handleOnCloseModal = () => {
    setIsOpenFileSystem(false);
    setIsShowModal(false);
  };
  const handleOnOpenModal = () => {
    setIsShowModal(true);
  };
  const handleOpenFileSystem = () => {
    setIsOpenFileSystem(true);
    handleOnOpenModal();
  };

  const updateImageStatus = (
    id: string,
    update: Partial<ImageGalleryState>,
  ) => {
    const image = imagesRef.current.find((image) => image.id === id);
    if (!image) return;
    onChange([{ ...image, ...update }], id);
  };

  const handleAddEditedImage = async (
    editedImage: ImageToUploadFromEditedImage,
  ) => {
    try {
      const { id, url } = await addImage(editedImage.base64);
      setCountSuccess((count) => count + 1);
      updateImageStatus(editedImage.id, {
        id,
        publicUrl: url,
        base64: editedImage.base64,
        isError: false,
        isLoading: false,
      });
    } catch (error) {
      console.error(error);
      setCountError((count) => count + 1);
      updateImageStatus(editedImage.id, {
        isError: true,
        isLoading: false,
      });
    }
  };

  const mediaByType: Record<MediaType, UploadImageType | UploadVideoType> = {
    image: addImage,
    video: addVideo,
  };

  const handleAddImageFromFile = async (image: ImageToUploadFromFile) => {
    try {
      const addMedia = mediaByType[image.mediaType];
      const { id, url } = await addMedia(image.file);
      setCountSuccess((count) => count + 1);
      updateImageStatus(image.id, {
        id,
        publicUrl: url,
        isError: false,
        isLoading: false,
      });
    } catch (error) {
      console.error(error);
      setCountError((count) => count + 1);
      updateImageStatus(image.id, {
        isError: true,
        isLoading: false,
      });
    }
  };

  const handleAddImages = async (newImages: ImageGalleryState[]) => {
    startMediaUpload();
    setTotalToUpload((total) => total + newImages.length);
    newImages.forEach((image) => {
      if (image.isEdited && !!image.base64) {
        handleAddEditedImage({
          id: image.id,
          base64: image.base64,
        });
        return;
      }

      if (!image.file) return;

      handleAddImageFromFile({
        id: image.id,
        file: image.file,
        mediaType: image.mediaType || 'image',
      });
    });
  };

  const onRetryError = () => {
    const imagesWithError = imagesRef.current.filter((image) => image.isError);
    handleAddImages(imagesWithError);
    onChange([
      ...imagesRef.current.map((image) => {
        if (image.isError) {
          return { ...image, isError: false, isLoading: true };
        }
        return image;
      }),
    ]);
  };

  const handleOnchange = (newImages: ImageGalleryState[]) => {
    const imagesWithReplacements = imagesRef.current.map((image) => {
      const replacementImage = newImages.find(
        (newImage) => image.id === newImage.replacedImageId,
      );
      return replacementImage || image;
    });

    onChange([
      ...imagesWithReplacements,
      ...newImages
        .filter((i) => i.replacedImageId === undefined)
        .map((i) => ({ ...i, alt: {} })),
    ]);
    handleAddImages(newImages);
  };

  const handleOnRemove = (imageId: string) =>
    onChange(images.filter((image) => image.id !== imageId));

  const handleOnEdit = (imageId: string) => {
    setImageIdForEditOnOpen(imageId);
    handleOnOpenModal();
  };

  const onOpenEditModal = () => setImageIdForEditOnOpen(undefined);
  const handleOnClickAdd = () => {
    trackingProductDetailImageClick(isEdit);
    handleOpenFileSystem();
  };

  const message = totalToUpload
    ? t('products.detail.uploadingPhotosStatus', {
        photo: countSuccess + 1,
        total: totalToUpload,
      })
    : '';

  const title = hasUploadVideos
    ? t('products.detail.media')
    : t('products.detail.photos');

  return (
    <Card title={title}>
      <Stack column>
        <AlertCloneStatus />
        <Stack.Item fill>
          <ImagePreviews
            isEdit={isEdit || false}
            onClickAdd={handleOnClickAdd}
            onRemove={handleOnRemove}
            onEdit={handleOnEdit}
            openModal={handleOnOpenModal}
            onUploadImages={handleOnchange}
            onError={onRetryError}
            images={images}
            message={message}
          />
        </Stack.Item>
      </Stack>
      <ImageGalleryModal
        isEdit={isEdit || false}
        isShow={isShowModal}
        onClose={handleOnCloseModal}
        images={images}
        imageIdForEditOnOpen={imageIdForEditOnOpen}
        onOpenEditModal={onOpenEditModal}
        onChange={onChange}
        onError={onRetryError}
        isOpenFileSystem={isOpenFileSystem}
        onUploadImages={handleOnchange}
        message={message}
        altTextGenerationContext={altTextGenerationContext}
      />
    </Card>
  );
}

export default ImageGallery;
