import { Alert, Box, Text } from '@nimbus-ds/components';
import { InvoiceIcon } from '@nimbus-ds/icons';
import { TFunction } from 'i18next';
import { Status as StatusEnum, Country } from '@tiendanube/common/src/enums';
import {
  Button,
  Card,
  DataList,
  InterfaceLabel,
  Label,
  Link,
} from '@tiendanube/components';
import { ExternalLinkIcon } from '@tiendanube/icons';
import { useBoolean } from 'commons/hooks';
import { usePartialPayment } from 'domains/Orders/Orders/hooks';
import { trackingOrderMarkAsPaidClick } from 'domains/Orders/tracking';
import CardPaymentAmounts from './CardPaymentAmounts/CardPaymentAmounts';
import OtherPaymentMethodModal from './OtherPaymentMethodModal';
import PaymentDropdownButton from './PaymentDropdownButton';
import { useNavegate } from '../../../../../../../App/hooks';
import { CUSTOM_GATEWAY } from '../../../constants';
import { useOrderIsEdited, useCanBeRefunded } from '../../../hooks';
import { useNuvemPagoRedirectResponse } from '../../../hooks/useNuvemPagoRedirect/useNuvemPagoRedirect';
import { OrderStatusType } from '../commons/useOrderStatus';

interface NewCardPaymentProps {
  order: OrderStatusType;
  t: TFunction;
  country: Country;
  openGatewayLink: () => void;
  showButton: boolean;
  headerLabel: InterfaceLabel;
  showInstallments: boolean;
  nuvemPagoRedirect: useNuvemPagoRedirectResponse;
  isMobile: boolean;
  isDesktop: boolean;
  openNuvemPago: () => void;
  isArchived: boolean;
  isMarkingAsPaid: boolean;
  paidOrder: (
    gateway?: string,
    gatewayMethod?: string,
    gatewayName?: string,
  ) => void;
  partiallyPaidOrder: (method: string, name?: string) => void;
  isMakingPartialPayment: boolean;
  handleMarkAsPaid: () => void;
}

function NewCardPayment({
  order: {
    id,
    payment,
    taxes,
    amounts,
    billingAddress,
    currency,
    products,
    fulfillmentOrders,
  },
  t,
  showButton,
  headerLabel,
  showInstallments,
  nuvemPagoRedirect,
  openGatewayLink,
  country,
  isMobile,
  isDesktop,
  openNuvemPago,
  isArchived,
  isMarkingAsPaid,
  paidOrder,
  partiallyPaidOrder,
  isMakingPartialPayment,
  handleMarkAsPaid,
}: NewCardPaymentProps): JSX.Element {
  const {
    status,
    gateway = '',
    gatewayId,
    method,
    methodCode,
    gatewayLink,
    installments,
    refundAttempts,
  } = payment;

  const { goTo } = useNavegate();
  const { hasRefundTag } = useCanBeRefunded({ payment });
  const isEdited = useOrderIsEdited();
  const [isModalOpen, onOpenModal, onCloseModal] = useBoolean(false);
  const {
    totalDiffAlertText,
    partialPaymentButtonLabel,
    paymentMethodOptions,
  } = usePartialPayment({
    totalDiffPending: amounts.totalDiffPending,
    country: country as string,
    t,
    onOpenModal,
  });

  const rejectedPaymentInfo = nuvemPagoRedirect.isNuvemPagoPayment()
    ? ''
    : t('cardPayment.rejectedPaymentInfo', {
        gateway: t(`gateway.${gateway}`, gateway),
      });

  const handlePartialPayment = (
    gateway: string,
    gatewayMethod: string,
    gatewayName?: string,
  ) => {
    status === StatusEnum.PENDING
      ? (paidOrder(gateway, gatewayMethod, gatewayName),
        trackingOrderMarkAsPaidClick(gatewayMethod))
      : (partiallyPaidOrder(gatewayMethod, gatewayName),
        trackingOrderMarkAsPaidClick(gatewayMethod));
  };

  const handleOtherPaymentMethodSubmit = (method: string, name?: string) => {
    handlePartialPayment(CUSTOM_GATEWAY, method, name);
    onCloseModal();
  };

  return (
    <Card title={t('cardPayment.title')} headerLabel={headerLabel}>
      <DataList ruled={false} spacing="tight">
        <CardPaymentAmounts
          taxes={taxes}
          amounts={amounts}
          billingAddressCountry={billingAddress?.country}
          refundAttempts={refundAttempts}
          currency={currency}
          paymentMethod={method}
          fulfillmentOrders={fulfillmentOrders}
          totalProducts={products.reduce(
            (total, product) => total + product.quantity,
            0,
          )}
          isEdited={isEdited}
        />
        <>
          <DataList.Row id="bottom">
            <DataList.Cell width="fill">
              <>
                <Box paddingBottom="2">
                  <Text fontWeight="bold" color="neutral-textHigh">
                    {`${t('cardPayment.paymentType')}`}
                  </Text>
                </Box>
                <Text as="p" color="neutral-textHigh" fontWeight="medium">{`${t(
                  `gateway.${gateway}`,
                  gateway,
                )}`}</Text>
                {method !== '' && (
                  <Box display="block" paddingY="2">
                    <Label id={method} label={method} />
                  </Box>
                )}
                {showInstallments && (
                  <Text as="p" color="neutral-textHigh">
                    {t('cardPayment.installments', {
                      count: Number(installments),
                    })}
                  </Text>
                )}
                {!!gatewayId && (
                  <>
                    <Text
                      color="neutral-textHigh"
                      as={isDesktop ? 'span' : 'p'}
                    >
                      {status !== StatusEnum.WARNIG_TRANSACTIONS
                        ? t('cardPayment.gatewayId')
                        : rejectedPaymentInfo}
                    </Text>

                    {gatewayLink ? (
                      <Link
                        appearance="primary"
                        icon={ExternalLinkIcon}
                        iconPosition="end"
                        onClick={openGatewayLink}
                      >
                        {gatewayId}
                      </Link>
                    ) : (
                      <Text as={isDesktop ? 'span' : 'p'}>{gatewayId}</Text>
                    )}
                  </>
                )}
                {nuvemPagoRedirect.isNuvemPagoPayment() &&
                  status === StatusEnum.WARNIG_TRANSACTIONS &&
                  (country === Country.BR && isMobile ? (
                    <Text as="p">
                      {t('cardPayment.rejectedNuvemPagoPaymentWithoutLink')}
                    </Text>
                  ) : (
                    <Text as="p">
                      {t('cardPayment.rejectedNuvemPagoPaymentInfo1')}{' '}
                      <Link appearance="primary" onClick={openNuvemPago}>
                        {t('cardPayment.rejectedNuvemPagoPaymentInfo2')}
                      </Link>{' '}
                      {t('cardPayment.rejectedNuvemPagoPaymentInfo3')}
                    </Text>
                  ))}
                {nuvemPagoRedirect.isNuvemPagoPayment() &&
                  hasRefundTag() &&
                  status === 'refunded' && (
                    <Box display="block" paddingY="2">
                      <Button
                        icon={InvoiceIcon}
                        appearance="default"
                        onClick={() =>
                          goTo(nuvemPagoRedirect.refundReceiptURL(id))
                        }
                      >
                        {t('cardPayment.refundReceipt')}
                      </Button>
                    </Box>
                  )}
              </>
            </DataList.Cell>
          </DataList.Row>
          {isEdited &&
            !!amounts.totalDiffPending &&
            amounts.totalDiffPending !== 0 && (
              <DataList.Row id="totalDiffAlert">
                <DataList.Cell width="fill">
                  <Alert appearance="warning" title={totalDiffAlertText}>
                    {t('cardPayment.totalDiffAlert.subtitle', {
                      paidByCustomer: amounts.paidByCustomer,
                      total: amounts.total,
                    })}
                  </Alert>
                </DataList.Cell>
              </DataList.Row>
            )}
          {!isArchived &&
            (isEdited && amounts.total !== amounts.paidByCustomer ? (
              <DataList.Row id="footerButton">
                <DataList.Cell width="fill">
                  <PaymentDropdownButton
                    orderGatewayMethod={methodCode}
                    methodOptions={paymentMethodOptions}
                    label={partialPaymentButtonLabel}
                    isMakingPayment={isMakingPartialPayment || isMarkingAsPaid}
                    onPartialPayment={handlePartialPayment}
                  />
                </DataList.Cell>
              </DataList.Row>
            ) : (
              showButton && (
                <DataList.Row id="footerButton">
                  <DataList.Cell width="fill">
                    <Button
                      appearance="primary"
                      onClick={handleMarkAsPaid}
                      spinner={isMarkingAsPaid}
                      disabled={isMarkingAsPaid}
                    >
                      {`${t(`cardPayment.action.${status}`)}`}
                    </Button>
                  </DataList.Cell>
                </DataList.Row>
              )
            ))}
        </>
      </DataList>
      <OtherPaymentMethodModal
        isOpen={isModalOpen}
        isCharge={amounts.totalDiffPending > 0}
        onClose={onCloseModal}
        onSubmit={handleOtherPaymentMethodSubmit}
      />
    </Card>
  );
}

export default NewCardPayment;
