import { InterfaceText } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
import ThousandSeparatorText from 'domains/Statistics/components/ThousandSeparatorText';
import { thousandSeparator } from 'domains/Statistics/utils';

interface RankByProvinceValueAndPercentageProps
  extends Omit<InterfaceText, 'children'> {
  value: number;
  percentageDiff?: number;
}

function RankByProvinceValueAndPercentage({
  value,
  percentageDiff,
  textAlign = 'left',
}: RankByProvinceValueAndPercentageProps) {
  return (
    <Stack wrap align="center" justify="flex-start" spacing="none">
      <ThousandSeparatorText
        textAlign={textAlign}
        size="caption"
        value={value}
      />

      {!!percentageDiff && (
        <Text textAlign={textAlign} size="caption" bold>
          {` - ${thousandSeparator(value)}`}%
        </Text>
      )}
    </Stack>
  );
}

export default RankByProvinceValueAndPercentage;
