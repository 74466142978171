import { IonPageStratus } from 'commons/components';
import { AfterPaymentAlert } from '../../components';

export default function ThankYouPage() {
  return (
    <IonPageStratus width="small">
      <AfterPaymentAlert />
    </IonPageStratus>
  );
}
