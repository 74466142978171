import { ReactNode, useEffect } from 'react';
import { Prompt } from 'react-router';
import { ErrorState, Link } from '@tiendanube/components';
import { PlusCircleIcon, CheckCircleIcon } from '@tiendanube/icons';
import HelpLink from 'App/HelpLink';
import {
  ActionProp,
  ActionsDesktop,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { HELP_LINKS_CATEGORIES } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { StatusSaveType } from 'commons/types';
import { useCategoriesAlerts } from 'domains/Catalog/Categories/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { useGetCategoriesMetafields } from 'domains/Metafields/hooks';
import InviteToCreateMetafieldsAlert from '../InviteToCreateMetafieldsAlert';

interface CategoriesPage {
  statusSave: StatusSaveType;
  showEmpty: boolean;
  isSuccess: boolean;
  isDirty: boolean;
  isError: boolean;
  categoriesLimitReached: boolean;

  handleSave: () => void;
  handleAddRow: () => void;
  handleRetry: () => void;
  children: ReactNode;
}

function CategoriesPage({
  handleSave,
  statusSave,
  handleAddRow,
  handleRetry,
  isSuccess,
  showEmpty,
  isDirty,
  isError,
  categoriesLimitReached,
  children,
}: CategoriesPage) {
  const t = useTranslationCatalog();
  const language = useTranslationLanguage();

  const { fetchMetafields } = useGetCategoriesMetafields();

  const { showCreateMetafieldsAlert } = useCategoriesAlerts();

  useEffect(() => {
    fetchMetafields();
  }, [fetchMetafields]);

  const saveAction: ActionProp = {
    children: `${t('categories.save')}`,
    onClick: handleSave,
    icon: CheckCircleIcon,
    spinner: statusSave === 'saving',
    disabled: statusSave === 'saving',
  };

  const addAction: ActionProp = {
    children: `${t('categories.action')}`,
    appearance: 'primary',
    onClick: handleAddRow,
    disabled: categoriesLimitReached,
  };

  return (
    <IonPageStratus
      headerTop={
        <HeaderTop
          actions={
            isSuccess &&
            !showEmpty && (
              <Link
                onClick={handleAddRow}
                icon={PlusCircleIcon}
                disabled={categoriesLimitReached}
              />
            )
          }
          mainAction={isDirty ? saveAction : {}}
        />
      }
      headerContent={
        <HeaderContent
          title={t('categories.title')}
          subtitle={!isError && !showEmpty ? t('categories.subtitle') : ''}
          actions={
            isSuccess && !showEmpty && <ActionsDesktop actions={[addAction]} />
          }
        />
      }
      width="small"
    >
      {isError && (
        <ErrorState
          title={t('categories.errorResults.title')}
          action={{
            children: t('categories.errorResults.action'),
            onClick: handleRetry,
          }}
        />
      )}
      <Prompt
        when={statusSave === 'idle' && isDirty}
        message={t('common:exitEdit.info')}
      />

      {isSuccess && showCreateMetafieldsAlert && (
        <InviteToCreateMetafieldsAlert />
      )}

      {children}

      <HelpLink
        title={t('helpLink.organizeCategories')}
        linkURL={HELP_LINKS_CATEGORIES[language]}
        previousValue="category_list"
        currentViewTracking={t('helpLink.organizeCategoriesTrackingName')}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default CategoriesPage;
