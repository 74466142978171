import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  AppScopesAndAuthorizationResponseDto,
  AppsAdminLinksResponseDto,
  AppsListItemResponseDto,
  NuvemAppsReponseDto,
} from '@tiendanube/common';
import { RootStateType } from 'App/store';
import {
  EntityStatusDataInterface,
  EntityStatusInterface,
  StatusType,
} from 'commons/types';
import { isExpired } from 'commons/utils/time';
import { getTokens } from './partnersAppsSelectors';
import partnersAppService, {
  AppInstalled,
  AppToken,
} from '../partnersAppService';

type ThunkStateType = { state: RootStateType };

interface ScopesInterface
  extends EntityStatusDataInterface<AppScopesAndAuthorizationResponseDto> {
  statusCode: string;
}
export interface InterfaceAppsSchema {
  status: StatusType;
  statusApp: StatusType;
  entities: Record<string, AppInstalled>;
  ids: string[];
  tokens: Record<string, AppToken>;
  nuvemapps: NuvemAppsReponseDto;
  appsLinks: AppsAdminLinksResponseDto[];
  scopes: ScopesInterface;
  appLinkAuthorization: EntityStatusInterface;
  appToUninstall: AppsListItemResponseDto | null;
}

const initialState: InterfaceAppsSchema = {
  status: 'idle',
  statusApp: 'idle',
  entities: {},
  ids: [],
  tokens: {},
  nuvemapps: {} as NuvemAppsReponseDto,
  appsLinks: [],
  appToUninstall: null,
  scopes: {
    status: 'idle',
    statusCode: '',
    data: null,
  },
  appLinkAuthorization: {
    status: 'idle',
  },
};

export const getNuvemApps = createAsyncThunk<NuvemAppsReponseDto>(
  'apps/getNuvemApps',
  async () => await partnersAppService.getNuvemApps(),
);

export const getAppsLinks = createAsyncThunk<AppsAdminLinksResponseDto[]>(
  'apps/getAppsLinks',
  async () => await partnersAppService.getAppsLinks(),
);

export const getTokenById = createAsyncThunk<AppToken, string, ThunkStateType>(
  'apps/getToken',
  async (id, thunkApi) => {
    const state = thunkApi.getState();
    const tokens = getTokens(state);
    const token = tokens[id];

    if (token && !isExpired(token.expiresOn)) {
      return token;
    }

    return await partnersAppService.getToken(id);
  },
);

export const fetchAppAction = createAsyncThunk<AppInstalled, string>(
  'apps/getApp',
  async (appId) => await partnersAppService.getApp(appId),
);

export const getAppsScopeAuthorization = createAsyncThunk(
  'apps/getAppsScopesAuthorization',
  partnersAppService.getAppsScopesAuthorization,
);

export const getLinkAuthorization = createAsyncThunk(
  'apps/getLinkAuthorization',
  partnersAppService.getLinkAuthorization,
);

const appsSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    cleanGetLinkAuthorizationStatus(state) {
      state.appLinkAuthorization.status = 'idle';
      return state;
    },
    setAppToUninstall(state, action: PayloadAction<AppsListItemResponseDto>) {
      state.appToUninstall = action.payload;
      return state;
    },
    clearAppToUninstall(state) {
      state.appToUninstall = null;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNuvemApps.fulfilled, (state, action) => {
      const nuvemApps = action.payload;
      for (const key of Object.keys(nuvemApps)) {
        const nuvemApp = nuvemApps[key];
        if (!nuvemApp) break;
        state.entities[nuvemApp.id] = nuvemApp;
      }
      state.nuvemapps = nuvemApps;
      state.status = 'success';
    });

    builder.addCase(getAppsLinks.fulfilled, (state, action) => {
      state.appsLinks = action.payload;
      state.status = 'success';
    });

    builder.addCase(getTokenById.fulfilled, (state, action) => {
      state.tokens[action.payload.id] = action.payload;
    });

    builder
      .addCase(fetchAppAction.pending, (state) => {
        state.statusApp = 'loading';
      })
      .addCase(fetchAppAction.rejected, (state) => {
        state.statusApp = 'error';
      })
      .addCase(fetchAppAction.fulfilled, (state, action) => {
        state.statusApp = 'success';
        state.entities[action.payload.id] = action.payload;
      });

    builder
      .addCase(getAppsScopeAuthorization.pending, (state) => {
        state.scopes.status = 'loading';
      })
      .addCase(getAppsScopeAuthorization.rejected, (state, action) => {
        state.scopes.status = 'error';
        state.scopes.statusCode = action.error.message || '';
      })
      .addCase(getAppsScopeAuthorization.fulfilled, (state, action) => {
        state.scopes.status = 'success';
        state.scopes.data = action.payload;
      });

    builder
      .addCase(getLinkAuthorization.pending, (state) => {
        state.appLinkAuthorization.status = 'loading';
      })
      .addCase(getLinkAuthorization.rejected, (state) => {
        state.appLinkAuthorization.status = 'error';
      })
      .addCase(getLinkAuthorization.fulfilled, (state) => {
        state.appLinkAuthorization.status = 'success';
      });
  },
});

export const { reducer } = appsSlice;

export const {
  cleanGetLinkAuthorizationStatus,
  setAppToUninstall,
  clearAppToUninstall,
} = appsSlice.actions;
