import { useEffect } from 'react';
import { Box, Card, Icon, Link, Text } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { ErrorScreen, Stack } from 'commons/components';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import ShippingListCard from './ShippingListCard';
import { ShippingListInterface } from './ShippingListCard/ShippingListCard';
import ShippingListSkeleton from './ShippingListSkeleton';

interface ShippingCardProps {
  title: string;
  subTitle?: string;
  linkText: string;
  shippingList: ShippingListInterface[];
  editDeliveryRoute: string;
  isError: boolean;
  isLoading: boolean;
  onClickAddDelivery: () => void;
  fetchData: () => void;
  handleDelete: (id: string) => void;
}

function ShippingCard({
  title,
  subTitle,
  linkText,
  shippingList,
  editDeliveryRoute,
  isError,
  isLoading,
  onClickAddDelivery,
  fetchData,
  handleDelete,
}: ShippingCardProps) {
  const t = useTranslationShipping();
  const {
    locations,
    isLoading: isLoadingLocations,
    isError: isErrorLocations,
    fetchLocations,
  } = useLocations();

  useEffect(() => {
    if (!locations.length && !isErrorLocations) {
      fetchLocations();
    }
  }, [fetchLocations, locations.length, isErrorLocations]);

  const isLoadingPage = isLoading || isLoadingLocations;
  const isErrorPage = isError || isErrorLocations;

  const shouldRenderShippingListCard =
    !isErrorPage && !isLoadingPage && shippingList;

  const fetchDataAndLocations = () => {
    fetchData();
    fetchLocations();
  };

  return (
    <Card padding="none">
      <Card.Header padding="base" title={title} />
      <Card.Body padding="base">
        <Stack align="flex-start" column>
          {!isErrorPage && <Text>{subTitle}</Text>}

          {isLoadingPage && !isErrorPage && <Link.Skeleton />}
          {shouldRenderShippingListCard && (
            <Link
              appearance="primary"
              textDecoration="none"
              onClick={onClickAddDelivery}
            >
              <Icon color="currentColor" source={<PlusCircleIcon />} />
              {linkText}
            </Link>
          )}
          {isErrorPage && !isLoadingPage && (
            <ErrorScreen
              description={t(
                'deliveryMethods.activeShippingMethods.error.description',
              )}
              onRetry={fetchDataAndLocations}
            />
          )}
        </Stack>
      </Card.Body>
      <Card.Footer padding="none">
        <Box padding="none" paddingBottom="1-5" width="100%">
          {isLoadingPage && !isErrorPage && <ShippingListSkeleton />}
          {shouldRenderShippingListCard && (
            <ShippingListCard
              shippingList={shippingList}
              editDeliveryRoute={editDeliveryRoute}
              locations={locations}
              handleDelete={handleDelete}
            />
          )}
        </Box>
      </Card.Footer>
    </Card>
  );
}

export default ShippingCard;
