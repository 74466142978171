import { Box, Button, Icon, Text } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { MARKETING_ROUTES } from 'domains/Marketing/marketingRoutes';
import {
  SectionAppCardDomains,
  SectionAppCard,
} from 'domains/PartnersApps/components';
import emptyCouponsImage from './empty-coupons.png';

export function CouponsListEmpty() {
  const t = useTranslationMarketing('coupons.emptyResults');
  const { push } = useHistory();
  const handlePrimary = () => push(MARKETING_ROUTES.newCoupon);

  return (
    <EmptyMessage
      title={t('title')}
      actions={
        <Box display="flex" flex="1" flexDirection="column" width="100%">
          <Text>
            <Trans
              t={t}
              i18nKey="body"
              components={{
                br: <br />,
              }}
            />
          </Text>
          <Box paddingTop="3" display="flex" gap="4" flexWrap="wrap">
            <Button appearance="primary" onClick={handlePrimary}>
              <Icon color="currentColor" source={<PlusCircleIcon />} />
              {t('action')}
            </Button>
          </Box>
          <Box paddingTop="8">
            <SectionAppCard
              source={MARKETING_ROUTES.couponList}
              appCategory="marketing"
              domain={SectionAppCardDomains.COUPONS}
            />
          </Box>
        </Box>
      }
      illustration={<img width="100%" alt="" src={emptyCouponsImage} />}
    />
  );
}
