import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CreateTaxBillingDataRequestDto,
  ExternalInvoicesResponseDto,
  InvoicesResponseDto,
  ProvinceDto,
  ResultPaginationResponseDto,
  ShippingAddressResponseDto,
  UpdateTaxBillingDataRequest,
} from '@tiendanube/common';
import { Country } from '@tiendanube/common/src/enums';
import { RootStateType } from 'App/store';
import { logout } from 'domains/Auth';
import {
  getCurrentPage,
  getExternalCurrentPage,
  getExternalTotalPages,
  getTotalPages,
} from './invoicesSelectors';
import {
  BillingCitiesInterface,
  CitiesInterface,
  CodeCountryCodeProvinceInterface,
} from './types';
import { initialState } from './utils';
import invoicesServices from '../invoicesServices';
import { ExternalInvoiceMerchantService } from '../pages/InvoicesListPage/components/InvoiceHistory/types';

export const fetchCountries = createAsyncThunk(
  'shipping/addresses/fetchCountries',
  async () => invoicesServices.getCountries(),
);

export const fetchAddresses = createAsyncThunk<
  ShippingAddressResponseDto,
  { zipcode: string; countryCode: string }
>('shipping/addresses/fetchAddresses', async ({ zipcode, countryCode }) => {
  switch (countryCode) {
    case 'MX':
      return invoicesServices.addressesMexico(zipcode);
    case 'BR':
      return invoicesServices.addressesBrazil(zipcode);
    default:
      throw 'Not implemented for country';
  }
});

export const fetchProvinces = createAsyncThunk<ProvinceDto[], string>(
  'shipping/addresses/fetchProvinces',
  async (countryCode) => {
    const response = await invoicesServices.getProvinces(countryCode);
    return response;
  },
);

export const fetchInvoiceHistoryByPagination = createAsyncThunk(
  'shipping/addresses/fetchInvoiceHistoryByPagination',
  async (page: number) => {
    const response = await invoicesServices.getInvoiceHistory({
      page,
      perPage: 20,
    });
    return response;
  },
);

export const fetchInvoiceHistoryByScroll = createAsyncThunk<
  ResultPaginationResponseDto<InvoicesResponseDto[]> | void,
  void,
  { state: RootStateType }
>('shipping/addresses/fetchInvoiceHistoryByScroll', async (_, { getState }) => {
  const state = getState();

  const nextPage = getCurrentPage(state) + 1;
  const totalPage = getTotalPages(state);

  if (nextPage <= totalPage) {
    const response = await invoicesServices.getInvoiceHistory({
      page: nextPage,
      perPage: 20,
    });
    return response;
  }
});

export const fetchCities = createAsyncThunk<
  CitiesInterface[],
  CodeCountryCodeProvinceInterface
>('shipping/addresses/fetchCities', async ({ countryCode, provinceCode }) => {
  const response = await invoicesServices.getCities(countryCode, provinceCode);
  return response;
});

export const fetchBillingCities = createAsyncThunk<
  BillingCitiesInterface,
  Country
>('billing/invoices/fetchCities', async (countryCode) => {
  const response = await invoicesServices.getBillingCities(countryCode);
  response.results.sort((a, b) =>
    a.label > b.label ? 1 : b.label > a.label ? -1 : 0,
  );
  return response;
});

export const createFiscalData = createAsyncThunk(
  'billing/invoices/fiscalData',
  async (data: CreateTaxBillingDataRequestDto) => {
    const response = await invoicesServices.createFiscalData(data);
    return response;
  },
);

export const validateCuit = createAsyncThunk(
  'billing/invoices/validateCuit',
  async (cuit: string) => {
    const response = await invoicesServices.validateCuit(cuit);
    return response;
  },
);
export const updateFiscalData = createAsyncThunk(
  'billing/invoices/updateFiscalData',
  async (data: UpdateTaxBillingDataRequest) => {
    const { id, ...updateData } = data;
    const response = await invoicesServices.updateFiscalData(updateData, id);
    return response;
  },
);

export const fetchInvoiceInfo = createAsyncThunk(
  'billing/invoices/fetchInvoiceInfo',
  async () => {
    const response = await invoicesServices.getFiscalData();
    return response;
  },
);

export const fetchBillingEngineInvoiceInfo = createAsyncThunk(
  'billing/invoices/fetchBillingEngineInvoiceInfo',
  async () => await invoicesServices.getBillingEngineFiscalData(),
);

export const getInvoiceDownloadLink = createAsyncThunk(
  'billing/invoices/getInvoiceDownloadLink',
  async (id: string) => {
    const response = await invoicesServices.getDownloadLink(id);
    return response;
  },
);

export const fetchExternalInvoiceHistoryByPagination = createAsyncThunk(
  'billing/invoices/fetchExternalInvoiceHistoryByPagination',
  async ({
    page,
    merchantService,
  }: {
    page: number;
    merchantService: string;
  }) => {
    const response = await invoicesServices.getExternalInvoiceHistory({
      page,
      merchantService,
      perPage: 20,
    });
    return response;
  },
);

export const fetchExternalInvoiceHistoryByScroll = createAsyncThunk<
  ResultPaginationResponseDto<ExternalInvoicesResponseDto[]> | void,
  ExternalInvoiceMerchantService,
  { state: RootStateType }
>(
  'billing/invoices/fetchExternalInvoiceHistoryByScroll',
  async (merchantService: ExternalInvoiceMerchantService, { getState }) => {
    const state = getState();

    const nextPage = getExternalCurrentPage(state) + 1;
    const totalPage = getExternalTotalPages(state);

    if (nextPage <= totalPage) {
      const response = await invoicesServices.getExternalInvoiceHistory({
        page: nextPage,
        perPage: 20,
        merchantService,
      });
      return response;
    }
  },
);

export const fetchExternalInvoiceDownloadLink = createAsyncThunk(
  'billing/invoices/fetchExternalInvoiceDownloadLink',
  async (id: string) => {
    const response = await invoicesServices.getExternalDownloadLink(id);
    return response;
  },
);

const invoices = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    cleanAddresses(state) {
      state.address.addresses = initialState.address.addresses;
    },
    cleanFiscalData(state) {
      state.fiscalData = initialState.fiscalData;
    },
    cleanValidateCuit(state) {
      state.fiscalData.validateCuit.status =
        initialState.fiscalData.validateCuit.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state.address = initialState.address;
      return state;
    });

    builder.addCase(fetchCountries.pending, (state) => {
      state.address.countries.data = initialState.address.countries.data;
      state.address.countries.status = 'loading';
    });

    builder.addCase(fetchCountries.fulfilled, (state, action) => {
      state.address.countries.data = action.payload;
      state.address.countries.status = 'success';
    });

    builder.addCase(fetchCountries.rejected, (state) => {
      state.address.countries.data = initialState.address.countries.data;
      state.address.countries.status = 'error';
    });

    builder.addCase(fetchAddresses.pending, (state) => {
      state.address.addresses.data = initialState.address.addresses.data;
      state.address.addresses.status = 'loading';
    });

    builder.addCase(fetchAddresses.fulfilled, (state, action) => {
      state.address.addresses.data = action.payload;
      state.address.addresses.status = 'success';
    });

    builder.addCase(fetchAddresses.rejected, (state) => {
      state.address.addresses.data = initialState.address.addresses.data;
      state.address.addresses.status = 'error';
    });

    builder.addCase(fetchProvinces.pending, (state) => {
      state.address.provinces.status = 'loading';
      state.address.provinces.data = initialState.address.provinces.data;
    });

    builder.addCase(fetchProvinces.rejected, (state) => {
      state.address.provinces.data = initialState.address.provinces.data;
      state.address.provinces.status = 'error';
    });

    builder.addCase(fetchProvinces.fulfilled, (state, action) => {
      state.address.provinces.status = 'success';
      state.address.provinces.data = action.payload;
    });

    builder.addCase(fetchCities.pending, (state) => {
      state.address.cities.status = 'loading';
    });

    builder.addCase(fetchCities.rejected, (state) => {
      state.address.cities.status = 'error';
    });

    builder.addCase(fetchCities.fulfilled, (state, action) => {
      state.address.cities.status = 'success';
      state.address.cities.data = {
        ...state.address.cities.data,
        [action.meta.arg.provinceCode]: action.payload,
      };
    });

    builder.addCase(fetchBillingCities.pending, (state) => {
      state.fiscalData.cities.status = 'loading';
    });

    builder.addCase(fetchBillingCities.rejected, (state) => {
      state.fiscalData.cities.status = 'error';
    });

    builder.addCase(fetchBillingCities.fulfilled, (state, action) => {
      state.fiscalData.cities.status = 'success';
      state.fiscalData.cities.data = action.payload.results;
    });

    builder.addCase(createFiscalData.fulfilled, (state) => {
      state.fiscalData.status = 'success';
    });

    builder.addCase(createFiscalData.rejected, (state) => {
      state.fiscalData.status = 'error';
    });

    builder.addCase(createFiscalData.pending, (state) => {
      state.fiscalData.status = 'loading';
    });
    builder.addCase(updateFiscalData.fulfilled, (state) => {
      state.fiscalData.status = 'success';
    });

    builder.addCase(updateFiscalData.rejected, (state) => {
      state.fiscalData.status = 'error';
    });

    builder.addCase(updateFiscalData.pending, (state) => {
      state.fiscalData.status = 'loading';
    });

    builder.addCase(fetchInvoiceInfo.fulfilled, (state, action) => {
      state.fiscalData.invoiceInformation.status = 'success';
      state.fiscalData.invoiceInformation.hasInvoiceInfo =
        action.payload.hasTaxData;
      state.fiscalData.invoiceInformation.data = action.payload.taxData;
    });

    builder.addCase(fetchInvoiceInfo.rejected, (state) => {
      state.fiscalData.invoiceInformation.status = 'error';
    });

    builder.addCase(fetchInvoiceInfo.pending, (state) => {
      state.fiscalData.invoiceInformation.status = 'loading';
    });

    builder.addCase(
      fetchBillingEngineInvoiceInfo.fulfilled,
      (state, action) => {
        state.fiscalData.invoiceInformation.hasBillingEngineInvoiceInfo =
          action.payload.hasTaxData;
        state.fiscalData.invoiceInformation.billingEngineInvoiceInfoStatus =
          'success';
      },
    );

    builder.addCase(fetchBillingEngineInvoiceInfo.rejected, (state) => {
      state.fiscalData.invoiceInformation.billingEngineInvoiceInfoStatus =
        'error';
    });

    builder.addCase(fetchBillingEngineInvoiceInfo.pending, (state) => {
      state.fiscalData.invoiceInformation.billingEngineInvoiceInfoStatus =
        'loading';
    });

    builder.addCase(validateCuit.pending, (state) => {
      state.fiscalData.validateCuit.status = 'loading';
    });

    builder.addCase(validateCuit.rejected, (state) => {
      state.fiscalData.validateCuit.status = 'error';
    });

    builder.addCase(validateCuit.fulfilled, (state, action) => {
      state.fiscalData.validateCuit.status = 'success';
      state.fiscalData.validateCuit.data = action.payload;
    });

    builder.addCase(
      fetchInvoiceHistoryByPagination.fulfilled,
      (state, action) => {
        state.invoices.status.get = 'success';
        state.invoices.data = action.payload;
      },
    );

    builder.addCase(fetchInvoiceHistoryByPagination.rejected, (state) => {
      state.invoices.status.get = 'error';
    });

    builder.addCase(fetchInvoiceHistoryByPagination.pending, (state) => {
      state.invoices.status.get = 'loading';
    });

    builder.addCase(fetchInvoiceHistoryByScroll.fulfilled, (state, action) => {
      if (action.payload) {
        state.invoices.data.results.push(...action.payload.results);
        state.invoices.data.pagination = action.payload.pagination;
      }
      state.invoices.status.update = 'success';
    });

    builder.addCase(fetchInvoiceHistoryByScroll.rejected, (state) => {
      state.invoices.status.update = 'error';
    });

    builder.addCase(fetchInvoiceHistoryByScroll.pending, (state) => {
      state.invoices.status.update = 'loading';
    });

    builder.addCase(getInvoiceDownloadLink.pending, (state) => {
      state.invoices.status.link = 'loading';
    });

    builder.addCase(getInvoiceDownloadLink.rejected, (state) => {
      state.invoices.status.link = 'error';
    });

    builder.addCase(getInvoiceDownloadLink.fulfilled, (state) => {
      state.invoices.status.link = 'success';
    });

    builder.addCase(
      fetchExternalInvoiceHistoryByPagination.pending,
      (state) => {
        state.externalInvoices.status.get = 'loading';
      },
    );

    builder.addCase(
      fetchExternalInvoiceHistoryByPagination.rejected,
      (state) => {
        state.externalInvoices.status.get = 'error';
      },
    );

    builder.addCase(
      fetchExternalInvoiceHistoryByPagination.fulfilled,
      (state, action) => {
        state.externalInvoices.status.get = 'success';
        state.externalInvoices.data = action.payload;
      },
    );

    builder.addCase(fetchExternalInvoiceHistoryByScroll.pending, (state) => {
      state.externalInvoices.status.update = 'loading';
    });

    builder.addCase(fetchExternalInvoiceHistoryByScroll.rejected, (state) => {
      state.externalInvoices.status.update = 'error';
    });

    builder.addCase(
      fetchExternalInvoiceHistoryByScroll.fulfilled,
      (state, action) => {
        if (action.payload) {
          state.externalInvoices.data.results.push(...action.payload.results);
          state.externalInvoices.data.pagination = action.payload.pagination;
        }
        state.externalInvoices.status.update = 'success';
      },
    );

    builder.addCase(fetchExternalInvoiceDownloadLink.pending, (state) => {
      state.externalInvoices.status.link = 'loading';
    });

    builder.addCase(fetchExternalInvoiceDownloadLink.rejected, (state) => {
      state.externalInvoices.status.link = 'error';
    });

    builder.addCase(fetchExternalInvoiceDownloadLink.fulfilled, (state) => {
      state.externalInvoices.status.link = 'success';
    });
  },
});

export const { reducer } = invoices;

export const { cleanAddresses, cleanFiscalData, cleanValidateCuit } =
  invoices.actions;
