import { DownloadCsvTemplateResponseDto } from '@tiendanube/common';
import { Link, Stack } from '@tiendanube/components';
import { DownloadIcon } from '@tiendanube/icons';
import { useResponsive } from 'commons/components';
import { useTranslationCommon } from 'commons/hooks';
import { useDownloadTemplateCsv } from 'commons/hooks/useDownloadTemplateCsv';
import downloadBlob from 'commons/utils/downloadBlob';

type FetchTemplateCsvHandler = () => Promise<DownloadCsvTemplateResponseDto>;

interface DownloadTemplateLinkProps {
  fetchTemplateCsvHandler: FetchTemplateCsvHandler;
}

export function DownloadTemplateLink({
  fetchTemplateCsvHandler,
}: DownloadTemplateLinkProps): JSX.Element | null {
  const t = useTranslationCommon();
  const { isMobile } = useResponsive();
  const { isLoading, getTemplateCsv } = useDownloadTemplateCsv({
    fetchTemplateCsvHandler,
    messageSuccess: t('importCsv.import.downloadCsv.success'),
    messageError: t('importCsv.import.downloadCsv.error'),
  });

  const onClick = async () => {
    const templateCsv = await getTemplateCsv();
    if (templateCsv && templateCsv.fileName && templateCsv.content) {
      downloadBlob(templateCsv.fileName, templateCsv.content);
    }
  };

  const textLink = isLoading
    ? t('importCsv.import.downloadCsv.loading')
    : t('importCsv.import.downloadCsv.title');

  if (isMobile) {
    return null;
  }

  return (
    <Stack.Item>
      <Link
        appearance="default"
        icon={DownloadIcon}
        iconPosition="start"
        onClick={onClick}
        underline
        spinner={isLoading}
        disabled={isLoading}
      >
        {textLink}
      </Link>
    </Stack.Item>
  );
}
