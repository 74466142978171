import { CombinedState } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { AccountSchema } from '../accountSlice';

export const getAccountState = (
  state: RootStateType,
): CombinedState<AccountSchema> => state.account;

export const deleteAccountStatus = createSimpleSelector(
  [getAccountState],
  (account) => ({
    deleteStatus: account.shopCancellation.deleteStatus,
  }),
);

export const fetchShopCancellationStatus = createSimpleSelector(
  [getAccountState],
  (account) => ({
    getStatus: account.shopCancellation.getStatus,
  }),
);

export const shopCancellationSelector = createSimpleSelector(
  [getAccountState],
  (account) => ({
    shopCancellation: account.shopCancellation.data,
  }),
);

export const authenticationFactorsStatus = createSimpleSelector(
  [getAccountState],
  (account) => ({
    status: account.authenticationFactors.status,
  }),
);

export const authenticationFactorsData = createSimpleSelector(
  [getAccountState],
  (account) => ({
    data: account.authenticationFactors.data,
  }),
);

export const recoveryCodesStatus = createSimpleSelector(
  [getAccountState],
  (account) => ({
    status: account.recoveryCodes.status,
  }),
);

export const recoveryCodesData = createSimpleSelector(
  [getAccountState],
  (account) => ({
    data: account.recoveryCodes.data,
  }),
);

export const regenerateRecoveryCodesStatus = createSimpleSelector(
  [getAccountState],
  (account) => ({
    status: account.regenerateRecoveryCodes.status,
  }),
);

export const authenticationFactorCodeStatus = createSimpleSelector(
  [getAccountState],
  (account) => ({
    status: account.authenticationFactorCode.status,
  }),
);

export const authenticationFactorCodeData = createSimpleSelector(
  [getAccountState],
  (account) => ({
    data: account.authenticationFactorCode.data,
  }),
);

export const updateAuthenticationFactorStatus = createSimpleSelector(
  [getAccountState],
  (account) => ({
    status: account.updateAuthenticationFactor.status,
  }),
);

export const updateAuthenticationFactorData = createSimpleSelector(
  [getAccountState],
  (account) => account.updateAuthenticationFactor.data,
);

export const validateCredentialsStatus = createSimpleSelector(
  [getAccountState],
  (account) => account.validateCredentials.status,
);

export const validateCredentialsData = createSimpleSelector(
  [getAccountState],
  (account) => account.validateCredentials.data?.valid,
);

export const authenticationFactorsDashboardStatus = createSimpleSelector(
  [getAccountState],
  (dashboard) => ({
    status: dashboard.authenticationFactorsDashboardStatus.status,
  }),
);

export const authenticationFactorsDashboardData = createSimpleSelector(
  [getAccountState],
  (dashboard) => ({
    data: dashboard.authenticationFactorsDashboardStatus.data,
  }),
);

export const recoveryCodesAlertToShow = createSimpleSelector(
  [getAccountState],
  (dashboard) => ({
    data: dashboard.recoveryCodesAlertToShow.data.alertToShow,
  }),
);

export const postReviveStoreStatus = createSimpleSelector(
  [getAccountState],
  (dashboard) => dashboard.postReviveStore.status,
);
