import { Box, Card, Checkbox, Text, Title } from '@nimbus-ds/components';
import Stack from 'commons/components/Stack';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function Skeleton(): JSX.Element {
  const t = useTranslationShipping();
  return (
    <Card padding="none">
      <Card.Header padding="base">
        <Stack column align="flex-start">
          <Title as="h3">{t('locations.form.salesChannel.title')}</Title>
          <Text>{t('locations.form.salesChannel.subtitle')}</Text>
        </Stack>
      </Card.Header>
      <Card.Body>
        <Stack column align="stretch" spacing="base">
          <hr />
          <Box paddingLeft="4">
            <Checkbox.Skeleton />
          </Box>
          <hr />
          <Box paddingLeft="4" paddingBottom="4">
            <Checkbox.Skeleton />
          </Box>
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default Skeleton;
