/* eslint-disable max-statements */
import { useState } from 'react';
import { Link } from '@nimbus-ds/components';
import { EmptyMessage } from '@nimbus-ds/patterns';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Action, Domain } from '@tiendanube/common/src/enums';
import {
  Button,
  DataTable,
  EmptyState,
  ErrorState,
  Stack,
  Text,
} from '@tiendanube/components';
import { PlusCircleIcon, SearchIcon } from '@tiendanube/icons';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { PaginationStratus } from 'commons/components';
import { SortType } from 'commons/types';
import goToAdmin from 'commons/utils/gotToAdmin';
import { OrderHelpLink } from 'domains/Orders/components';
import {
  useBulkActionOrders,
  useOrdersList,
} from 'domains/Orders/Orders/hooks';
import { useIsSavedSearchesEnabled } from 'domains/Orders/Orders/hooks/useIsSavedSearchesEnabled';
import {
  ORDERS_LIST_TIMEOUT_ERROR,
  ORDERS_PER_PAGE,
  ORDERS_PAGE_LIMIT,
} from 'domains/Orders/Orders/pages/constants';
import { trackingLastPageClick } from 'domains/Orders/tracking';
import {
  OrderResultsDesktopSkeleton,
  ResultHeaderDesktop,
  ResultRowDesktop,
} from './components';
import emptyOrders from '../../empty-orders.png';
import { useFixedBulkHeaderScroll } from '../../hooks/useFixedBulkHeaderScroll';
import { OrderSelectInBulk } from '../OrderSelectInBulk';
import './OrderResultsDesktop.scss';

interface OrderResultsMobileProps {
  hasOrder: boolean;
  isLoadingHasOrders: boolean;
  onHandleBulkAction: (ids: string[], value: Action) => void;
  onFilterLinkClick: () => void;
}

function OrderResultsDesktop({
  hasOrder,
  isLoadingHasOrders,
  onHandleBulkAction,
  onFilterLinkClick,
}: OrderResultsMobileProps): JSX.Element {
  const { t } = useTranslation([Domain.ORDERS]);
  const { scrollToTop } = useLayoutScroll();
  useFixedBulkHeaderScroll();

  const {
    isLoading,
    isSuccess,
    isError,
    statusSuccess,
    ordersIds,
    pagination,
    filters,
    hasFilters,
    getOrdersList,
    goToPage,
  } = useOrdersList();

  const isSavedSearchesEnabled = useIsSavedSearchesEnabled();
  const [sortByDate, setSortByDate] = useState<SortType>('desc');

  const { selectedRowsId, bulkAction, handleOnSelectRow } = useBulkActionOrders(
    {
      rowsId: ordersIds,
      onHandleBulkAction,
      actionElement: <OrderSelectInBulk />,
    },
  );

  const handleSelectPage = (page: number) => {
    if (page > ORDERS_PAGE_LIMIT) {
      trackingLastPageClick();
    }
    scrollToTop();
    goToPage(page);
  };

  const isEmptyList = ordersIds.length === 0;
  const isFirstPage = pagination.currentPage === 1;
  const showSkeleton =
    (isLoading && (isEmptyList || filters.page !== 1 || !isFirstPage)) ||
    isLoadingHasOrders;

  const sortedOrdersIds =
    sortByDate === 'desc' ? ordersIds : [...ordersIds].reverse();

  const emptyResultClassName = classNames({
    'stratus--orders-empty-results':
      isSavedSearchesEnabled &&
      (isEmptyList || filters.page > ORDERS_PAGE_LIMIT),
  });

  if (!isLoading && filters.page > ORDERS_PAGE_LIMIT) {
    return (
      <div className={emptyResultClassName}>
        <EmptyMessage
          title={t('lastPageReachedResults.title')}
          text={t('lastPageReachedResults.text')}
          icon={<SearchIcon size={30} />}
          actions={
            <>
              <Button onClick={() => onFilterLinkClick()} appearance="primary">
                {t('lastPageReachedResults.filter')}
              </Button>
              <Link
                appearance="primary"
                textDecoration="none"
                onClick={() => goToPage(1)}
              >
                {t('lastPageReachedResults.refreshPage')}
              </Link>
            </>
          }
        />
      </div>
    );
  }
  if (
    isError &&
    isEmptyList &&
    statusSuccess === ORDERS_LIST_TIMEOUT_ERROR &&
    hasFilters
  )
    return (
      <div className={emptyResultClassName}>
        <EmptyMessage
          title={t('timeoutErrorResults.title')}
          text={t('timeoutErrorResults.text')}
          icon={<SearchIcon size={30} />}
        />
      </div>
    );
  if (isError && isEmptyList)
    return (
      <div className={emptyResultClassName}>
        <ErrorState
          title={t('errorResults.title')}
          action={{
            children: t('errorResults.action'),
            onClick: getOrdersList,
          }}
        />
      </div>
    );
  if (isSuccess && isEmptyList && hasOrder) {
    if (hasFilters) {
      return (
        <div className={emptyResultClassName}>
          <EmptyMessage
            title={t('withoutResults.title')}
            text={t('withoutResults.text')}
            icon={<SearchIcon size={30} />}
          />
        </div>
      );
    }
    return (
      <EmptyState image={emptyOrders} title={t('emptyResults.title')}>
        <Stack spacing="base" column align="flex-start">
          <Stack.Item>
            <Text>{`${t('emptyResults.body')}`}</Text>
          </Stack.Item>
          <Stack.Item>
            <Button
              icon={PlusCircleIcon}
              appearance="primary"
              onClick={goToAdmin('draft_orders/new')}
            >
              {`${t('draftOrders.addDraftOrder')}`}
            </Button>
          </Stack.Item>
        </Stack>
      </EmptyState>
    );
  }

  const tableClassName = classNames(
    'stratus--orders-result-desktop',
    'stratus--flashing-table-color',
    { 'stratus--with-saved-searches-header': isSavedSearchesEnabled },
  );

  return (
    <div className={tableClassName}>
      <Stack spacing="tight" align="stretch" column>
        {(isLoadingHasOrders || hasOrder) && (
          <DataTable
            bulkAction={bulkAction}
            selectedRowsId={selectedRowsId}
            onSelectRow={handleOnSelectRow}
          >
            <ResultHeaderDesktop
              sortByDate={sortByDate}
              setSortByDate={setSortByDate}
            />
            {showSkeleton ? (
              <OrderResultsDesktopSkeleton />
            ) : (
              sortedOrdersIds.map((id) => <ResultRowDesktop key={id} id={id} />)
            )}
          </DataTable>
        )}
        {!isLoading && hasOrder && (
          <PaginationStratus
            currentPage={pagination.currentPage}
            totalItems={pagination.totalResults || 0}
            itemName={t('pagination.items')}
            page={pagination.currentPage}
            perPage={ORDERS_PER_PAGE}
            onPageSelect={handleSelectPage}
            pageLimit={ORDERS_PAGE_LIMIT}
          />
        )}
      </Stack>
      <OrderHelpLink />
    </div>
  );
}

export default OrderResultsDesktop;
