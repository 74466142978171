import ModalAside from 'App/components/lab/ModalAside';
import { HeaderContent, HeaderTop } from 'commons/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import CheckoutPaymentOptionsSorterContent from './CheckoutPaymentOptionsSorterContent';

export const TRANSLATE_PREFIX = 'sortPaymentsModal';

interface CheckoutPaymentOptionsSorterModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function CheckoutPaymentOptionsSorterModal({
  isOpen,
  onClose,
}: Readonly<CheckoutPaymentOptionsSorterModalProps>) {
  const t = useTranslationPayments(TRANSLATE_PREFIX);
  const headerNavigation = {
    onClick: onClose,
    children: '',
  };

  return (
    <ModalAside
      isOpen={isOpen}
      onDidDismiss={onClose}
      headerTop={<HeaderTop navigation={headerNavigation} />}
      headerContent={<HeaderContent title={t('title')} />}
    >
      <CheckoutPaymentOptionsSorterContent onClose={onClose} />
    </ModalAside>
  );
}

export default CheckoutPaymentOptionsSorterModal;
