import { Tag, Text } from '@nimbus-ds/components';
import { EyeOffIcon } from '@nimbus-ds/icons';
import {
  CouponCategoryResponseDto,
  CouponProductResponseDto,
} from '@tiendanube/common';
import { PopoverToogle, Stack } from 'commons/components';
import { useGetCurrency } from 'domains/Auth/hooks';
import { isCategoryVisible } from 'domains/Catalog/Products/utils';
import { useTranslationMarketing } from 'domains/Marketing/hook';

interface LimitedProps {
  startDate?: string;
  endDate?: string;
  minPrice: number;
  categories: CouponCategoryResponseDto[];
  products: CouponProductResponseDto[];
  maxUsesPerClient: number | null;
}

export function Limited({
  minPrice,
  categories,
  products,
  startDate,
  endDate,
  maxUsesPerClient,
}: Readonly<LimitedProps>) {
  const t = useTranslationMarketing();
  const { symbol } = useGetCurrency();
  const cantLimitedPeriod = Number(!!startDate && !!endDate);
  const cantLimitedMinPrice = Number(minPrice > 0);
  const cantLimitedCategories = Number(categories?.length > 0);
  const cantLimitedProducts = Number(products?.length > 0);
  const cantLimitedUsesPerClient = Number(!!maxUsesPerClient);
  const countLimitedTotal =
    cantLimitedPeriod +
    cantLimitedMinPrice +
    cantLimitedCategories +
    cantLimitedProducts +
    cantLimitedUsesPerClient;

  return (
    <PopoverToogle
      label={t('coupons.details.limited', {
        count: countLimitedTotal,
      })}
      content={
        <Stack column align="stretch">
          {!!startDate && !!endDate && (
            <>
              <Text fontWeight="bold">{t('coupons.headers.validity')}</Text>
              <Text>
                {startDate} - {endDate}
              </Text>
            </>
          )}
          {!!minPrice && (
            <>
              <Text fontWeight="bold">{t('coupons.details.minPrice')}</Text>
              <Text>
                {symbol} {minPrice}
              </Text>
            </>
          )}
          {!!maxUsesPerClient && (
            <>
              <Text fontWeight="bold">
                {t('coupons.details.maxUsesPerClient')}
              </Text>
              <Text>
                {t('coupons.details.uses', { count: maxUsesPerClient })}
              </Text>
            </>
          )}
          {categories && (
            <>
              <Text fontWeight="bold">{t('coupons.details.categories')}</Text>
              <Stack spacing="tight" wrap>
                {categories.length > 0 &&
                  categories.map(({ name, visibility }) => (
                    <Tag key={name} id={name}>
                      {!isCategoryVisible({ visibility }) && <EyeOffIcon />}
                      {name}
                    </Tag>
                  ))}
                {categories.length === 0 && (
                  <Tag id="category_deleted">
                    {t('coupons.details.categoryDeleted')}
                  </Tag>
                )}
              </Stack>
            </>
          )}
          {products && (
            <>
              <Text fontWeight="bold">{t('coupons.details.products')}</Text>
              <Stack spacing="tight" wrap>
                {products.length > 0 &&
                  products.map(({ name }) => (
                    <Tag key={name} id={name}>
                      {name}
                    </Tag>
                  ))}
                {products.length === 0 && (
                  <Tag id="product_deleted">
                    {t('coupons.details.productDeleted')}
                  </Tag>
                )}
              </Stack>
            </>
          )}
        </Stack>
      }
    />
  );
}
