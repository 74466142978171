import ApplyValuesToAllVariants from 'domains/Catalog/Products/pages/components/ApplyValuesToAllVariants';
import { MetafieldSelectedInterface } from 'domains/Catalog/Products/pages/components/Variants/types';
import {
  MetafieldInterface,
  OnChangeMetafieldType,
  SourceType,
} from 'domains/Metafields/types';
import Skeleton from './Skeleton';
import MetafieldInput from '../MetafieldInput';

import './MetafieldItem.scss';

interface MetafieldItemProps {
  metafield: MetafieldInterface;
  disabled?: boolean;
  value: string;
  source: SourceType;
  selectFirstOption?: string;
  showDescription?: boolean;
  onChange: OnChangeMetafieldType;
  onApplytoAllVariants?: (
    selected: MetafieldSelectedInterface,
    source: SourceType,
  ) => void;
}

function MetafieldItem({
  metafield,
  disabled,
  value,
  source,
  selectFirstOption,
  showDescription = false,
  onChange,
  onApplytoAllVariants,
}: MetafieldItemProps) {
  const handleChange = (value) =>
    onChange(metafield.id, value, source, metafield.resource, metafield.name);

  const handleApplytoAllVariants = () =>
    onApplytoAllVariants?.({ id: metafield.id, value }, source);

  return (
    <MetafieldInput
      metafield={metafield}
      disabled={disabled}
      showDescription={showDescription}
      itemTypeGenericProps={{
        value,
        onUpdate: handleChange,
        selectFirstOption,
      }}
      footerSlot={
        onApplytoAllVariants && (
          <ApplyValuesToAllVariants
            value={value}
            onChange={handleApplytoAllVariants}
          />
        )
      }
    />
  );
}

MetafieldItem.Skeleton = Skeleton;

export default MetafieldItem;
