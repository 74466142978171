import { ChangeEvent } from 'react';
import { Checkbox } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { CardCollapsible, InputNumberNimbus, Stack } from 'commons/components';
import { getDifference } from 'commons/utils/date';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import Skeleton from './Skeleton';

const MODALITIES_WITHOUT_ADD_DAYS = ['sedex_10', 'sedex_12', 'sedex_hoje'];

interface ShippingModalityCardProps {
  code: string;
  name: string;
  isEnabled: boolean;
  allowedFreeShipping: boolean;
  additionalCost: string;
  additionalDays: string;
  onChange: (group: string, e: ChangeEvent<HTMLInputElement>) => void;
}

const NELoggiEndDate = new Date(2025, 6, 31).toDateString();
const today = new Date().toDateString();

function ShippingModalityCard({
  code,
  name,
  isEnabled,
  allowedFreeShipping,
  additionalCost,
  additionalDays,
  onChange,
}: ShippingModalityCardProps): JSX.Element {
  const t = useTranslationShipping();

  const handleChangeToggle = (active: boolean) => {
    onChange(code, {
      target: { checked: active, type: 'checkbox', name: 'isEnabled' },
    } as ChangeEvent<HTMLInputElement>);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(code, e);
  };

  const shouldShowNewNETag = Boolean(
    getDifference(NELoggiEndDate, today) > 1 && code.includes('ne-loggi'),
  );

  return (
    <CardCollapsible
      title={name}
      active={isEnabled}
      onChange={handleChangeToggle}
      appearance="toggle"
      headerTag={{
        visible: shouldShowNewNETag,
        text: t('shippingModalities.tag'),
        appearance: 'primary',
      }}
    >
      <Stack column align="stretch">
        <FormField label={t('shippingModalities.inputs.additionalCost')}>
          <InputNumberNimbus
            name="additionalCost"
            type="float"
            appendPosition="start"
            append={t('shippingModalities.inputs.prependLabel')}
            value={additionalCost}
            onChange={handleOnChange}
          />
        </FormField>
        {!MODALITIES_WITHOUT_ADD_DAYS.includes(code) && (
          <FormField label={t('shippingModalities.inputs.additionalDays')}>
            <InputNumberNimbus
              name="additionalDays"
              type="integer"
              value={additionalDays}
              onChange={handleOnChange}
            />
          </FormField>
        )}
        <Checkbox
          name="allowedFreeShipping"
          id={`allowedFreeShipping_${code}`}
          label={t('shippingModalities.inputs.allowedFreeShipping', {
            modality: name,
          })}
          defaultChecked={allowedFreeShipping}
          onChange={handleOnChange}
        />
      </Stack>
    </CardCollapsible>
  );
}

ShippingModalityCard.Skeleton = Skeleton;

export default ShippingModalityCard;
