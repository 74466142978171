import { Responsive } from 'commons/components';
import {
  VisibleVariantInfoDesktop,
  VisibleVariantInfoDesktopProps,
  VisibleVariantInfoMobile,
  VisibleVariantInfoMobileProps,
} from './components';

type VariantInfoProps = VisibleVariantInfoDesktopProps &
  VisibleVariantInfoMobileProps;

function VariantInfo({
  variant,
  locations,
  index,
  isDigital,
  onChange,
  onClickImage,
  onClickVariant,
  onOpenVariantStock,
}: VariantInfoProps) {
  return (
    <Responsive
      mobile={
        <VisibleVariantInfoMobile
          variant={variant}
          locations={locations}
          index={index}
          isDigital={isDigital}
          onChange={onChange}
          onClickImage={onClickImage}
          onClickVariant={onClickVariant}
          onOpenVariantStock={onOpenVariantStock}
        />
      }
      desktop={
        <VisibleVariantInfoDesktop
          variant={variant}
          index={index}
          isDigital={isDigital}
          onClickImage={onClickImage}
          onClickVariant={onClickVariant}
          onChange={onChange}
        />
      }
    />
  );
}

export default VariantInfo;
