import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { getTablePrices } from 'domains/Customers/TablePrices/tablePricesSlice';
import {
  getTablePricesData,
  getTablePricesStatus,
} from 'domains/Customers/TablePrices/tablePricesSlice/tablePricesSelectors';

export default function useTablePrices() {
  const dispatch = useAppDispatch();

  const tablePricesData = useSelector(getTablePricesData);
  const tablePricesStatus = useSelector(getTablePricesStatus);

  const fetchTablePrices = useCallback(
    () => dispatch(getTablePrices()),
    [dispatch],
  );

  return {
    tablePricesData,
    tablePricesStatus,
    fetchTablePrices,
  };
}
