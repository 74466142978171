import { Tag, Text } from '@nimbus-ds/components';
import { PopoverToogle, Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface UserRolesPopoverProps {
  id: string;
  userEmailTypes: string[];
  emailTypes: string[];
}

function UserEmailTypesPopover({
  id,
  userEmailTypes,
  emailTypes = [],
}: Readonly<UserRolesPopoverProps>) {
  const t = useTranslationConfigurations('usersListPage.emailTypesPopover');

  const userEmailTypesLength = userEmailTypes.length;
  const emailTypesLength = emailTypes.length;

  if (userEmailTypesLength === 0) return <Tag>{t('no_notifications')}</Tag>;

  if (userEmailTypesLength === emailTypesLength)
    return <Tag>{t('all_notifications')}</Tag>;

  return (
    <PopoverToogle
      label={t('title', { count: userEmailTypesLength })}
      appearance="primary"
      content={
        <Stack column spacing="tight" align="stretch">
          <Stack>
            <Stack.Item fill>
              <Text color="neutral-textHigh">{t('user_notifications')}</Text>
            </Stack.Item>
            <Tag>
              {t('quantity', {
                count: userEmailTypesLength,
                total: emailTypesLength,
              })}
            </Tag>
          </Stack>
          <Stack column spacing="none" align="flex-start">
            {userEmailTypes.map((emailType) => (
              <Text
                color="neutral-textLow"
                fontSize="caption"
                key={`user-${id}-${emailType}`}
              >
                {t(emailType.toLowerCase())}
              </Text>
            ))}
          </Stack>
        </Stack>
      }
    />
  );
}

export default UserEmailTypesPopover;
