export enum statusType {
  idle = 'idle',
  loading = 'loading',
  error = 'error',
  success = 'success',
}

export interface CustomerTablePricesInterface {
  tablePrices: {
    data: string[];
    status: statusType;
  };
}
