import { CombinedState } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { CustomerMetafieldsInterface } from '../types';

export const getCustomerMetafieldsState = (
  state: RootStateType,
): CombinedState<CustomerMetafieldsInterface> =>
  state.customers.customerMetafields;

export const getCustomerMetafieldsData = createSimpleSelector(
  [getCustomerMetafieldsState],
  (state) => state.data,
);

export const getCustomerMetafieldsStatus = createSimpleSelector(
  [getCustomerMetafieldsState],
  (state) => convertStatusTypeToObject(state.status),
);

export const getCustomerMetafieldsDetailsData = createSimpleSelector(
  [getCustomerMetafieldsState],
  (state) => state.details,
);

export const getCustomerMetafieldsDetailsStatus = createSimpleSelector(
  [getCustomerMetafieldsState],
  (state) => convertStatusTypeToObject(state.statusDetails),
);

export const getAssignedWholesaleCustomerMetafieldsData = createSimpleSelector(
  [getCustomerMetafieldsState],
  (state) => state.wholesaleCustomer.data,
);

export const getAssignedWholesaleCustomerMetafieldsStatus =
  createSimpleSelector([getCustomerMetafieldsState], (state) =>
    convertStatusTypeToObject(state.wholesaleCustomer.status),
  );
