/* eslint-disable max-statements */
import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';
import { useSelector } from 'react-redux';
import { PaginationResponseDto } from '@tiendanube/common';
import { Action, Status } from '@tiendanube/common/src/enums';
import { useToastProgress } from 'App/components/ToastProgressContext';
import { useAppDispatch } from 'App/store';
import { useAsyncFunc, useListFilters, useToast } from 'commons/hooks';
import { useBulkActions } from 'domains/Catalog/Products/pages/ProductListPage/components';
import { defaultSavedSearches } from 'domains/Orders/components/SavedSearches/defaultSavedSearches';
import {
  ORDERS_PAGE_LIMIT,
  ORDERS_PER_PAGE,
} from 'domains/Orders/Orders/pages/constants';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import {
  defaultFilters,
  FiltersParamsType,
  FiltersType,
  InterfaceOrderUpdateParams,
} from '../../ordersService';
import {
  fetchMoreOrders,
  fetchOrders,
  fetchSavedSearchesCountOrders,
  updateOrders as updateOrdersAction,
} from '../../ordersSlice';
import {
  getAppliedFilters,
  getOrdersCount,
  getOrdersIds,
  getOrdersPagination,
  getOrdersStatus,
  getOrdersStatusSuccess,
} from '../../ordersSlice/ordersSelectors';
import { transformReadyToArchiveFilter } from '../../utils/OrderListFilter/utils';
import { useGetTotalOpenedOrders } from '../useGetTotalOpenedOrders';
import { useIsSavedSearchesEnabled } from '../useIsSavedSearchesEnabled';

interface UseOrdersListResult {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  statusSuccess: string;
  ordersCount: number;
  totalOpenOrders: number;
  ordersIds: string[];
  pagination: PaginationResponseDto;
  filters: FiltersType;
  appliedFilters: FiltersType;
  hasFilters: boolean;
  getOrdersList: (filters?: FiltersParamsType) => Promise<void>;
  refreshOrdersList: () => Promise<void>;
  getMoreOrders: () => Promise<void>;
  goToPage: (page: number) => Promise<void>;
  updateOrders: (params: InterfaceOrderUpdateParams) => Promise<void>;
  changeFilters: (filters: FiltersType) => void;
  removeMetafieldsFilters: () => void;
  fetchSavedSearchesCount: () => Promise<void>;
}

const TYPE_FETCH_ORDERS = 'advanced';

function useOrdersList(): UseOrdersListResult {
  const dispatch = useAppDispatch();
  const t = useTranslationOrders();
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();
  const isSavedSearchesEnabled = useIsSavedSearchesEnabled();
  const { filters, hasFilters, changeFilters, removeMetafieldsFilters } =
    useListFilters('orders', defaultFilters);
  const { isLoading, isSuccess, isError } = useSelector(getOrdersStatus);
  const statusSuccess = useSelector(getOrdersStatusSuccess);
  const ordersCount = useSelector(getOrdersCount);
  const ordersIds = useSelector(getOrdersIds);
  const pagination = useSelector(getOrdersPagination);
  const { setDisabled } = useBulkActions();
  const appliedFilters = useSelector(getAppliedFilters);
  const { totalOpenedOrders: totalOpenOrders } = useGetTotalOpenedOrders();

  const fetchSavedSearchesCount = useCallback(async () => {
    if (isSavedSearchesEnabled) {
      await Promise.all(
        defaultSavedSearches.map((savedSearch) => {
          dispatch(fetchSavedSearchesCountOrders(savedSearch)).unwrap();
        }),
      );
    }
  }, [dispatch, isSavedSearchesEnabled]);

  const getOrdersList = useCallback(
    async (customFilters?: FiltersParamsType) => {
      let fetchFilters = customFilters
        ? {
            ...customFilters,
            page: 1,
            perPage: ORDERS_PER_PAGE.toString(),
          }
        : filters;

      if (fetchFilters.status === Status.CLOSED && isSavedSearchesEnabled) {
        fetchFilters = {
          ...fetchFilters,
          ...transformReadyToArchiveFilter(fetchFilters),
        } as FiltersType;
      }

      if (!isEqual(fetchFilters, appliedFilters) || appliedFilters.page === 1) {
        const filtersDigest = Object.fromEntries(
          Object.entries(fetchFilters).filter(
            ([key, value]) =>
              (value !== 'all' && value !== 'open') ||
              (value === 'all' && key === 'status'),
          ),
        ) as FiltersType;
        changeFilters(filtersDigest);
        await dispatch(
          fetchOrders({
            filters: fetchFilters,
            type: TYPE_FETCH_ORDERS,
          }),
        );
      }
    },
    [filters, isSavedSearchesEnabled, appliedFilters, changeFilters, dispatch],
  );

  const refreshOrdersList = useCallback(async () => {
    const filtersWithFirstPage = {
      ...filters,
      page: 1,
      perPage: ORDERS_PER_PAGE.toString(),
    };
    changeFilters(filtersWithFirstPage);
    await dispatch(
      fetchOrders({ filters: filtersWithFirstPage, type: TYPE_FETCH_ORDERS }),
    );
  }, [filters, changeFilters, dispatch]);

  const getMoreOrders = useCallback(async () => {
    if (ordersIds.length !== filters.page * ORDERS_PER_PAGE) return;
    const newFilters = {
      ...filters,
      page: filters.page + 1,
      perPage: ORDERS_PER_PAGE.toString(),
    };
    changeFilters(newFilters);
    await dispatch(
      fetchMoreOrders({ filters: newFilters, type: TYPE_FETCH_ORDERS }),
    );
  }, [changeFilters, dispatch, filters, ordersIds.length]);

  const goToPage = useCallback(
    async (page: number) => {
      if (filters.page === page) return;
      const newFilters = {
        ...filters,
        page,
        perPage: ORDERS_PER_PAGE.toString(),
      };
      changeFilters(newFilters);
      if (page <= ORDERS_PAGE_LIMIT) {
        await dispatch(
          fetchOrders({ filters: newFilters, type: TYPE_FETCH_ORDERS }),
        );
      }
    },
    [changeFilters, dispatch, filters],
  );

  const updateOrdersError = (params) => {
    setDisabled(false);
    showFeedbackToast('error', params?.actionType);
  };

  const [updateOrders] = useAsyncFunc<InterfaceOrderUpdateParams, void>(
    async (params) => {
      if (!params) return;
      showFeedbackToast('loading', params.actionType);
      const result = unwrapResult(await dispatch(updateOrdersAction(params)));
      showFeedbackToast('success', params.actionType);
      return result;
    },
    () => {
      Promise.all([getOrdersList(), fetchSavedSearchesCount()]);
    },
    updateOrdersError,
  );

  const showFeedbackErrorToast = (action) => {
    const errorMessage =
      action && [Action.ARCHIVE, Action.CANCEL].includes(action)
        ? action
        : 'default';
    addToast({
      label: t(`bulkActions.toast.error.${errorMessage}`),
      appearance: 'danger',
    });
  };

  const showFeedbackToast = (
    type: 'loading' | 'success' | 'error',
    action?: Action,
  ) => {
    if (type === 'loading') {
      addToastProgress({ label: t(`bulkActions.toast.loading.${action}`) });
    } else {
      closeToastProgress();
      if (type === 'success')
        addToast({
          label: t(`bulkActions.toast.success.${action}`),
          appearance: 'success',
        });
      else {
        showFeedbackErrorToast(action);
      }
    }
  };

  return {
    isLoading,
    isSuccess,
    isError,
    statusSuccess,
    ordersCount,
    totalOpenOrders,
    ordersIds,
    pagination,
    filters,
    appliedFilters,
    hasFilters,
    getOrdersList,
    refreshOrdersList,
    getMoreOrders,
    goToPage,
    updateOrders,
    changeFilters,
    removeMetafieldsFilters,
    fetchSavedSearchesCount,
  };
}

export default useOrdersList;
