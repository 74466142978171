import { ChangeEvent } from 'react';
import { Prompt } from 'react-router';
import {
  CreateUserRequestDto,
  EmailTypeDtoResponse,
  UpdateUserRequestDto,
} from '@tiendanube/common';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { useNavegate } from 'App/hooks';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { useGetTags } from 'domains/Auth/hooks';
import { CancelAndSaveButtonsWithAuth } from 'domains/AuthenticationFactor';
import { UserRolesType } from 'domains/Configurations/Users/usersApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import { DataCard, RolesCard, UserFormPageError } from '../../components';
import EmailTypesCard from '../EmailTypesCard/EmailTypesCard';
import UserFormPageSkeleton from '../UserFormPageSkeleton';

interface UserFormProps {
  isMainUser?: boolean;
  isLoading: boolean;
  isError: boolean;
  isDirty: boolean;
  values: CreateUserRequestDto & UpdateUserRequestDto;
  errors: Partial<Record<string, string>>;
  requestErrors: Partial<Record<string, any>>;
  submitStatus: string;
  storeRoles: UserRolesType | undefined;
  emailTypes: EmailTypeDtoResponse | undefined;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeRole: (name: string, value: any) => void;
  onChangeEmailType: (name: string, value: any) => void;
  onRefetch: () => void;
  onSubmit: () => void;
  onValidate: (onValid: () => void) => Promise<void>;
  isCurrentUser?: boolean;
  isEmailNotificationLoading?: boolean;
  isEmailNotificationError?: boolean;
}

function UserForm({
  isMainUser = false,
  isLoading,
  isError,
  isDirty,
  values,
  errors,
  requestErrors,
  submitStatus,
  storeRoles,
  emailTypes,
  onChange,
  onChangeRole,
  onChangeEmailType,
  onRefetch,
  onSubmit,
  onValidate,
  isCurrentUser = false,
  isEmailNotificationLoading = false,
  isEmailNotificationError = false,
}: Readonly<UserFormProps>) {
  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);

  const t = useTranslationConfigurations('userFormPage');
  const { goBack } = useNavegate();
  const { validateRoles } = useRoles();

  const isFullRole = validateRoles('full');
  const showRolesCard = !isMainUser && !isCurrentUser;
  const emailTypesArray = emailTypes?.emailTypes;

  const showSkeleton = isRevampMenu
    ? isLoading
    : isLoading || isEmailNotificationLoading;
  const showError = isRevampMenu
    ? isError
    : isError || !storeRoles || isEmailNotificationError || !emailTypes;
  if (showSkeleton) {
    return (
      <UserFormPageSkeleton
        showRolesCard={showRolesCard}
        hasCurrentPassword={
          isRevampMenu ? undefined : values.currentPassword !== undefined
        }
      />
    );
  }

  if (showError) {
    return <UserFormPageError refetch={onRefetch} />;
  }

  return (
    <>
      <Prompt
        when={submitStatus === 'idle' && isDirty}
        message={t('exitPrompt')}
      />
      <DataCard
        values={values}
        errors={errors}
        requestErrors={requestErrors}
        onChange={onChange}
      />
      {showRolesCard && !!storeRoles && (
        <RolesCard
          roles={storeRoles}
          values={values}
          errors={errors}
          onChangeRole={onChangeRole}
        />
      )}
      {isFullRole && (
        <EmailTypesCard
          emailTypes={emailTypesArray}
          values={values}
          onChangeEmailType={onChangeEmailType}
        />
      )}
      <CancelAndSaveButtonsWithAuth
        saveText={t('confirmEdit')}
        typeModal="centered"
        payload={values}
        onSave={onSubmit}
        preSubmitValidation={onValidate}
        onCancel={goBack}
        isDisabled={!isDirty}
        isLoading={submitStatus === 'loading'}
      />
    </>
  );
}

export default UserForm;
