import {
  GetAvailableMethodsResponseDto,
  LocalShippingListResponseDto,
  ShippingFallbackRequestDto,
  ShippingFallbacksResponseDto,
  CustomShippingResponseDto,
  CustomShippingRequestDto,
  LocaleResponseDto,
  CreateUpdateLocaleRequestDto,
  InternationalShippingResponseDto,
  IdResponseDto,
  InternationalShippingRequestDto,
  OcaRegisterRequestDto,
  OcaAgencyResponseDto,
  OcaConfigRequestDto,
  OcaConfigResponseDto,
  CorreiosContractsTypeResponseDto,
  CorreiosOptionsRequestDto,
  CorreiosContractsRequestDto,
  CorreiosContractsResponseDto,
  CorreiosResponseDto,
  CorreiosOptionsResponseDto,
  ShippingAppResponseDto,
  ShippingAppOptionsRequestDto,
  DeleteShippingAppRequestDto,
  ShippingMethodsResponseDto,
  NuvemEnvioFrontConfigsResponseDto,
  NuvemEnvioFrontConfigsRequestDto,
  ShippingFallbackAvailableRequestDto,
  AddressingConfigurationRuleResponseDto,
  PickupPointRequestDto,
  PickupPointStatusRequestDto,
  PickupPointResponseDto,
  PickupPointDetailResponseDto,
} from '@tiendanube/common';
import axios from 'App/axios';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import {
  INTERNATIONALS_URL,
  OCA_CONFIGURATION_URL,
  OCA_AGENCIES_URL,
  SHIPPING_LOCALES_URL,
  SHIPPING_FALLBACKS,
  CORREIOS_SHIPPING_METHODS_URL,
  CORREIOS_CONTRACTS_URL,
  CORREIOS_URL,
  APPS_DETAIL_URL,
  INTERNATIONALS_DETAIL_URL,
  CUSTOM_SHIPPINGS_URL,
  CUSTOM_SHIPPINGS_DETAIL_URL,
  LOCAL_SHIPPING_URL,
  AVAILABLE_APPS_URL,
  OCA_REGISTRATION_URL,
  SHIPPING_METHODS_URL,
  ONBOARDING_NUVEM_ENVIO_URL,
  ADDRESSING_CONFIGURATION_URL,
  PICKUP_POINTS_URL,
} from './urls';

export const getActiveShippingMethods = async (): Promise<
  LocalShippingListResponseDto[]
> => {
  const { data } = await axios.get<LocalShippingListResponseDto[]>(
    LOCAL_SHIPPING_URL,
  );

  return data;
};

const getAvailableApps = async (): Promise<
  GetAvailableMethodsResponseDto[]
> => {
  const { data } = await axios.get(AVAILABLE_APPS_URL);
  return data;
};

const getDetailActiveApp = async (id: string) => {
  const { data } = await axios.get<Promise<ShippingAppResponseDto>>(
    getUrlWithParams(APPS_DETAIL_URL, { id }),
  );
  return data;
};

const updateDetailActiveApp = async (
  id: string,
  body: ShippingAppOptionsRequestDto[],
) => {
  const { data } = await axios.put<Promise<ShippingAppResponseDto>>(
    getUrlWithParams(APPS_DETAIL_URL, { id }),
    body,
  );

  return data;
};

const getFallback = async (): Promise<ShippingFallbacksResponseDto> => {
  const { data } = await axios.get(SHIPPING_FALLBACKS);
  return data;
};

const createFallback = async (
  arg: ShippingFallbackRequestDto,
): Promise<ShippingFallbacksResponseDto> => {
  const { data } = await axios.post(SHIPPING_FALLBACKS, arg);
  return data;
};

const updateFallback = async (
  arg: ShippingFallbackRequestDto,
  id: string,
): Promise<ShippingFallbacksResponseDto> => {
  const { data } = await axios.put(`${SHIPPING_FALLBACKS}/${id}`, arg);
  return data;
};

const updateAvailableFallback = async (
  arg: ShippingFallbackAvailableRequestDto,
  id: string,
): Promise<void> => {
  await axios.patch(`${SHIPPING_FALLBACKS}/${id}`, arg);
};

export const createCustomShipping = async (
  params: CustomShippingRequestDto,
): Promise<CustomShippingResponseDto> => {
  const { data } = await axios.post(CUSTOM_SHIPPINGS_URL, {
    ...params,
    deliveryZones: params.deliveryZones?.map(({ provinceId, citiesIds }) => ({
      provinceId,
      citiesIds,
    })),
  });

  return data;
};

const fetchPhysicalLocations = async (): Promise<LocaleResponseDto[]> => {
  const { data } = await axios.get(SHIPPING_LOCALES_URL);
  return data;
};

const updatePhysicalLocation = async ({
  id,
  extra,
  name,
}: LocaleResponseDto): Promise<LocaleResponseDto> => {
  const { data } = await axios.put(`${SHIPPING_LOCALES_URL}/${id}`, {
    extra,
    name,
  });
  return data;
};

const createPhysicalLocation = async (
  physicalLocation: CreateUpdateLocaleRequestDto,
): Promise<LocaleResponseDto> => {
  const { data } = await axios.post(SHIPPING_LOCALES_URL, physicalLocation);
  return data;
};

const deletePhysicalLocation = async (id: string): Promise<IdResponseDto> => {
  const { data } = await axios.delete(`${SHIPPING_LOCALES_URL}/${id}`);

  return data;
};
export const requestOcaRegistration = async ({
  countryCode,
  areaCode,
  phoneNumber,
  afipDoc,
  fiscalDoc,
  iibbDoc,
  abmDoc,
}: OcaRegisterRequestDto): Promise<any> => {
  const formData = new FormData();

  formData.append('countryCode', countryCode);
  formData.append('areaCode', areaCode);
  formData.append('phoneNumber', phoneNumber);
  formData.append('afipDoc', afipDoc);
  formData.append('fiscalDoc', fiscalDoc);
  formData.append('iibbDoc', iibbDoc);
  formData.append('abmDoc', abmDoc);

  const { data } = await axios.post(OCA_REGISTRATION_URL, formData);
  return data;
};

const getCustomShipping = async () => {
  const { data } = await axios.get<CustomShippingResponseDto[]>(
    CUSTOM_SHIPPINGS_URL,
  );

  return data;
};

const deleteCustomShipping = async (id: string): Promise<void> => {
  await axios.delete(getUrlWithParams(CUSTOM_SHIPPINGS_DETAIL_URL, { id }));
};

const getDetailCustomShipping = async (id: string) => {
  const { data } = await axios.get<CustomShippingResponseDto>(
    getUrlWithParams(CUSTOM_SHIPPINGS_DETAIL_URL, { id }),
  );
  return data;
};

const updateDetailCustomShipping = async (
  id: string,
  data: CustomShippingRequestDto,
) => {
  const response = await axios.put<CustomShippingResponseDto>(
    getUrlWithParams(CUSTOM_SHIPPINGS_DETAIL_URL, { id }),
    data,
  );
  return response.data;
};

const getInternationalShipping = async () => {
  const { data } = await axios.get<InternationalShippingResponseDto[]>(
    INTERNATIONALS_URL,
  );

  return data;
};

const deleteInternationalShipping = async (id: string) => {
  const { data } = await axios.delete<IdResponseDto>(
    getUrlWithParams(INTERNATIONALS_DETAIL_URL, { id }),
  );

  return data;
};

const newInternationalShipping = async (
  internationalShipping: InternationalShippingResponseDto,
) => {
  const { data } = await axios.post<InternationalShippingResponseDto>(
    INTERNATIONALS_URL,
    internationalShipping,
  );
  return data;
};

const getDetailInternationalShipping = async (
  id: string,
): Promise<InternationalShippingResponseDto> => {
  const { data } = await axios.get<InternationalShippingResponseDto>(
    getUrlWithParams(INTERNATIONALS_DETAIL_URL, { id }),
  );
  return data;
};

const updateDetailInternationalShipping = async (
  id: string,
  data: InternationalShippingRequestDto,
) => {
  const response = await axios.put<InternationalShippingResponseDto>(
    getUrlWithParams(INTERNATIONALS_DETAIL_URL, { id }),
    data,
  );
  return response.data;
};

const deleteShipmentsInstalled = async (
  id: string,
  body: DeleteShippingAppRequestDto,
) => {
  const response = await axios.delete<IdResponseDto>(
    getUrlWithParams(APPS_DETAIL_URL, { id }),
    { data: body },
  );
  return response.data;
};

const enableCarrier = async (id: string) => {
  const response = await axios.patch<IdResponseDto>(
    getUrlWithParams(APPS_DETAIL_URL, { id }),
  );
  return response.data;
};

const getOcaConfiguration = async () => {
  const { data } = await axios.get<OcaConfigResponseDto>(OCA_CONFIGURATION_URL);
  return data;
};

const createOcaConfiguration = async (
  ocaConfigaration: OcaConfigRequestDto,
) => {
  const { data } = await axios.post<OcaConfigResponseDto>(
    OCA_CONFIGURATION_URL,
    ocaConfigaration,
  );
  return data;
};

const getOcaAgencies = async (zipcode: string) => {
  const { data } = await axios.get<OcaAgencyResponseDto[]>(OCA_AGENCIES_URL, {
    params: { zipcode },
  });
  return data;
};

const updateOcaConfiguration = async (
  id: string,
  ocaConfigaration: OcaConfigRequestDto,
) => {
  const { data } = await axios.put<OcaConfigResponseDto>(
    `${OCA_CONFIGURATION_URL}/${id}`,
    ocaConfigaration,
  );
  return data;
};

const getCorreiosContractTypes = async () => {
  const { data } = await axios.get<CorreiosContractsTypeResponseDto[]>(
    CORREIOS_CONTRACTS_URL,
  );
  return data;
};

const updateCorreiosContract = async (body: CorreiosContractsRequestDto) => {
  const { data } = await axios.post<CorreiosContractsResponseDto>(
    CORREIOS_CONTRACTS_URL,
    body,
  );
  return data;
};

const getCorreiosDetail = async () => {
  const { data } = await axios.get<CorreiosResponseDto>(CORREIOS_URL);
  return data;
};

const updateCorreiosShippingModalities = async (
  payload: CorreiosOptionsRequestDto[],
) => {
  const { data } = await axios.post<CorreiosOptionsResponseDto[]>(
    CORREIOS_SHIPPING_METHODS_URL,
    payload,
  );
  return data;
};

const getShippingMethods = async (): Promise<ShippingMethodsResponseDto> => {
  const { data } = await axios.get<ShippingMethodsResponseDto>(
    SHIPPING_METHODS_URL,
  );
  return data;
};

const getNuvemEnvioConfigs =
  async (): Promise<NuvemEnvioFrontConfigsResponseDto> => {
    const { data } = await axios.get<NuvemEnvioFrontConfigsResponseDto>(
      ONBOARDING_NUVEM_ENVIO_URL,
    );
    return data;
  };

const updateNuvemEnvioConfigs = async (
  payload: NuvemEnvioFrontConfigsRequestDto,
): Promise<NuvemEnvioFrontConfigsResponseDto> => {
  const { data } = await axios.patch(ONBOARDING_NUVEM_ENVIO_URL, payload);
  return data;
};

const getAddressingConfigurationRules = async (): Promise<
  AddressingConfigurationRuleResponseDto[]
> => {
  const { data } = await axios.get<AddressingConfigurationRuleResponseDto[]>(
    ADDRESSING_CONFIGURATION_URL,
  );
  return data;
};

const saveAddressingConfigurationRules = async (
  rules: AddressingConfigurationRuleResponseDto[],
): Promise<AddressingConfigurationRuleResponseDto[]> => {
  const { data } = await axios.post<AddressingConfigurationRuleResponseDto[]>(
    ADDRESSING_CONFIGURATION_URL,
    rules,
  );
  return data;
};

const getPickupPoints = async (): Promise<PickupPointResponseDto[]> => {
  const { data } = await axios.get<PickupPointResponseDto[]>(PICKUP_POINTS_URL);

  return data;
};

const getPickupPoint = async (
  id: string,
): Promise<PickupPointDetailResponseDto> => {
  const { data } = await axios.get<PickupPointDetailResponseDto>(
    `${PICKUP_POINTS_URL}/${id}`,
  );

  return data;
};

const createPickupPoint = async (
  request: PickupPointRequestDto,
): Promise<PickupPointDetailResponseDto> => {
  const { data } = await axios.post<PickupPointDetailResponseDto>(
    PICKUP_POINTS_URL,
    request,
  );

  return data;
};

const updatePickupPoint = async (
  id: string,
  request: PickupPointRequestDto,
): Promise<PickupPointDetailResponseDto> => {
  const { data } = await axios.put<PickupPointDetailResponseDto>(
    `${PICKUP_POINTS_URL}/${id}`,
    request,
  );

  return data;
};

const deletePickupPoint = async (id: string): Promise<void> => {
  await axios.delete<PickupPointDetailResponseDto>(
    `${PICKUP_POINTS_URL}/${id}`,
  );
};

const updatePickupPointStatus = async (
  id: string,
  payload: PickupPointStatusRequestDto,
): Promise<void> => {
  await axios.patch<PickupPointDetailResponseDto>(
    `${PICKUP_POINTS_URL}/${id}/status`,
    payload,
  );
};

export default {
  getActiveShippingMethods,
  getAvailableApps,
  getFallback,
  createFallback,
  updateFallback,
  updateAvailableFallback,
  requestOcaRegistration,
  createCustomShipping,
  getCustomShipping,
  deleteCustomShipping,
  getDetailCustomShipping,
  updateDetailCustomShipping,
  getInternationalShipping,
  deleteInternationalShipping,
  newInternationalShipping,
  getDetailInternationalShipping,
  updateDetailInternationalShipping,
  fetchPhysicalLocations,
  updatePhysicalLocation,
  createPhysicalLocation,
  deletePhysicalLocation,
  deleteShipmentsInstalled,
  enableCarrier,
  getOcaAgencies,
  getOcaConfiguration,
  createOcaConfiguration,
  updateOcaConfiguration,
  getCorreiosContractTypes,
  getCorreiosDetail,
  updateCorreiosShippingModalities,
  updateCorreiosContract,
  getDetailActiveApp,
  updateDetailActiveApp,
  getShippingMethods,
  getNuvemEnvioConfigs,
  updateNuvemEnvioConfigs,
  getAddressingConfigurationRules,
  saveAddressingConfigurationRules,
  getPickupPoint,
  getPickupPoints,
  createPickupPoint,
  updatePickupPoint,
  deletePickupPoint,
  updatePickupPointStatus,
};
