/* eslint-disable max-statements */
import { useEffect, useState } from 'react';
import { Box } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Domain } from '@tiendanube/common/src/enums';
import { DownloadIcon, PlusCircleIcon } from '@tiendanube/icons';
import { useNavegate, useQuery } from 'App/hooks';
import {
  IonPageStratus,
  ActionProp,
  ActionsDesktop,
  ApplicationsDesktop,
  HeaderContent,
} from 'commons/components/IonPageStratus';
import useModal from 'commons/hooks/useModal';
import { useHasPermission } from 'domains/Auth/hooks';
import {
  BulkActionsProvider,
  useBulkActions,
} from 'domains/Catalog/Products/pages/ProductListPage/components/BulkActionsProvider';
import {
  AlertOrderMetafields,
  ErrorMetafieldAlert,
} from 'domains/Metafields/components';
import HeaderOrderMobile from 'domains/Orders/components/HeaderOrderMobile';
import { METAFIELD_FILTER_PREFIX } from 'domains/Orders/constants';
import {
  trackingOrderListCreateDraftOrderClick,
  trackingOrderListExportClick,
} from 'domains/Orders/tracking';
import { useAppsLinks } from 'domains/PartnersApps/hooks';
import { OrderListEmpty } from './components/OrderListEmpty';
import OrderListFilter from './components/OrderListFilter';
import OrderListResults from './components/OrderListResults';
import { useOrdersList, useTotalOrders } from '../../hooks';
import { useIsSavedSearchesEnabled } from '../../hooks/useIsSavedSearchesEnabled';
import {
  FiltersParamsType,
  defaultFilterParams,
  defaultFilters,
} from '../../ordersService';

function OrderListPageWithProvider(): JSX.Element {
  return (
    <BulkActionsProvider>
      <OrdersListPage />
    </BulkActionsProvider>
  );
}

function OrdersListPage(): JSX.Element {
  const { t } = useTranslation([Domain.ORDERS]);
  const { goTo } = useNavegate();
  const { search } = useLocation();
  const query = useQuery();
  const { hasOrder, isLoadingHasOrders } = useTotalOrders();

  const {
    isLoading,
    statusSuccess,
    ordersIds,
    ordersCount,
    filters,
    totalOpenOrders,
    getOrdersList,
    removeMetafieldsFilters,
    fetchSavedSearchesCount,
  } = useOrdersList();
  const { selectedRowsId, showAlertMetafields } = useBulkActions();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const isSavedSearchesEnabled = useIsSavedSearchesEnabled();
  const [showModal, onOpenModal, onCloseModal] = useModal();

  const { getAppsLinks } = useAppsLinks();

  const knownFilterKeys = Object.keys(defaultFilters) as Array<
    keyof FiltersParamsType
  >;

  const metafieldPattern = new RegExp(
    `^${METAFIELD_FILTER_PREFIX}[a-f\\d]{8}(-[a-f\\d]{4}){4}[a-f\\d]{8}$`,
    'i',
  );

  const isMetafieldParameter = (key: string): boolean =>
    metafieldPattern.test(key);

  const getFilters = (keys: IterableIterator<string>) =>
    Array.from(keys).reduce((filters, key) => {
      const value = query.get(key);

      if (
        value &&
        (knownFilterKeys.includes(key as keyof FiltersParamsType) ||
          isMetafieldParameter(key))
      ) {
        return { ...filters, [key]: value };
      }
      return filters;
    }, {});

  useEffect(() => {
    const queryParam = query.keys();

    const filters = {
      ...defaultFilterParams,
      ...getFilters(queryParam),
    } as FiltersParamsType;

    getOrdersList(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (statusSuccess === 'errorMetafield') {
      removeMetafieldsFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusSuccess]);

  useEffect(() => {
    fetchSavedSearchesCount();
  }, [fetchSavedSearchesCount]);

  const onToggleEditMode = () => {
    setIsEditMode((e) => !e);
  };

  const handleChangeFilters = (newFilters: FiltersParamsType) => {
    getOrdersList(newFilters);
  };

  const handleChangeSavedSearch = (newFilters: FiltersParamsType) => {
    getOrdersList(newFilters);
    fetchSavedSearchesCount();
  };

  const isEmptyList = !isLoading && ordersIds.length === 0;

  const editModeAction = !isEmptyList
    ? {
        onClick: onToggleEditMode,
        children: isEditMode ? t('editMode.cancel') : t('editMode.edit'),
      }
    : undefined;

  const canExportOrdersCsv = useHasPermission('export_orders_csv');

  const exportOrdersAction: ActionProp = {
    children: t('exportOrders'),
    onClick: () => {
      const urlSearchParams = new URLSearchParams(search);
      urlSearchParams.delete('productsCount');
      for (const [key, _] of urlSearchParams.entries()) {
        if (key.startsWith(METAFIELD_FILTER_PREFIX)) {
          urlSearchParams.delete(key);
        }
      }
      const parsedQueryParams = urlSearchParams.toString();
      goTo(`/orders/export?${parsedQueryParams}`);
      trackingOrderListExportClick();
    },
    icon: DownloadIcon,
  };
  const addDraftOrderAction: ActionProp = {
    children: t('draftOrders.addDraftOrder'),
    onClick: () => {
      trackingOrderListCreateDraftOrderClick();
      goTo('/draft-orders/new');
    },
    icon: PlusCircleIcon,
    appearance: 'primary',
  };

  const actions: ActionProp[] = canExportOrdersCsv
    ? [exportOrdersAction, addDraftOrderAction]
    : [addDraftOrderAction];

  const applications: ActionProp[] = getAppsLinks('orders', 'default').map(
    (app) => ({
      children: app.text,
      onClick: () => window.open(app.url, '_blank'),
    }),
  );

  if (!hasOrder && !isLoadingHasOrders && !isLoading) {
    return <OrderListEmpty />;
  }

  const titleDetail =
    isSavedSearchesEnabled && totalOpenOrders > 0
      ? `${t('subtitleOpenOrders', { count: totalOpenOrders })}`
      : '';

  return (
    <IonPageStratus
      width="medium"
      headerTop={
        selectedRowsId.length === 0 && (
          <HeaderOrderMobile
            actions={actions}
            mainAction={editModeAction}
            applications={applications}
          />
        )
      }
      headerContent={
        <HeaderContent
          title={t('title')}
          titleDetail={titleDetail}
          actions={<ActionsDesktop actions={actions} />}
          applications={<ApplicationsDesktop applications={applications} />}
        />
      }
    >
      <ErrorMetafieldAlert status={statusSuccess} />
      <AlertOrderMetafields show={showAlertMetafields} />
      <Box
        display="flex"
        flexDirection="column"
        gap={isSavedSearchesEnabled ? 'none' : '4'}
      >
        <OrderListFilter
          showModal={showModal}
          filters={filters}
          ordersCount={ordersCount}
          isLoading={isLoading || isLoadingHasOrders}
          onChange={handleChangeFilters}
          onOpenModal={onOpenModal}
          onCloseModal={onCloseModal}
          handleChangeSavedSearch={handleChangeSavedSearch}
        />
        <OrderListResults
          hasOrder={hasOrder}
          isLoadingHasOrders={isLoadingHasOrders}
          isEditMode={isEditMode}
          onToggleEditMode={onToggleEditMode}
          onFilterLinkClick={() => onOpenModal()}
        />
      </Box>
    </IonPageStratus>
  );
}

export default OrderListPageWithProvider;
