import { useEffect, useState } from 'react';
import { useDesktopMenuContext } from 'App/components/Menu/DesktopMenuProvider';
import { useTopAdBar } from 'App/components/TopAdBar/useTopAdBar';
import { IonPageStratus } from 'commons/components';
import { useModal } from 'commons/hooks';
import { DomainSoftBlock } from '../../components';
import { useGetDomainSoftBlockQuery } from '../../domainsApi';
import { useDomainSoftBlock } from '../../hooks';
import {
  trackingNotifyChangesClick,
  trackingRememberLaterClick,
} from '../../tracking';

export type ActionType = 'confirm' | 'cancel';

const PAGE_SOURCE = 'login';

export function DomainSoftBlockPage() {
  const { data } = useGetDomainSoftBlockQuery();
  const { dismissSoftblock, notifyDomainUpdate } = useDomainSoftBlock();
  const { showMenu, hideMenu } = useDesktopMenuContext();
  const { hideTopAdBar, showTopAdBar } = useTopAdBar();
  const [showNotificationModal, openNotificationModal, closeNotificationModal] =
    useModal();
  const [mode, setMode] = useState<ActionType>('cancel');

  const isErrorScreen = !!data?.error;

  const handleAction = (mode: ActionType) => () => {
    setMode(mode);
    openNotificationModal();
  };

  useEffect(() => {
    hideMenu();
    hideTopAdBar();
    return () => {
      showMenu();
      showTopAdBar();
    };
  }, [hideMenu, hideTopAdBar, showMenu, showTopAdBar]);

  if (!data) return null;

  const handleConfirmAction = () => {
    closeNotificationModal();

    if (mode === 'cancel') {
      dismissSoftblock();
      trackingRememberLaterClick(data.storeId, PAGE_SOURCE);
    } else {
      notifyDomainUpdate();
      trackingNotifyChangesClick(data.storeId, PAGE_SOURCE);
    }
  };

  return (
    <IonPageStratus width="small">
      <DomainSoftBlock
        data={data}
        mode={mode}
        isErrorScreen={isErrorScreen}
        showNotificationModal={showNotificationModal}
        onCancel={handleAction('cancel')}
        onConfirm={handleAction('confirm')}
        onConfirmAction={handleConfirmAction}
        source={PAGE_SOURCE}
      />
    </IonPageStratus>
  );
}
