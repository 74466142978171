import { useCallback, useState } from 'react';
import { OrderDetailsResponseDto } from '@tiendanube/common';
import { StatusType } from 'commons/types';
import ordersService from '../../../ordersService';
import useRefundEventListener from '../useRefundEventListener';

interface Result {
  refund: (
    action: RefundAttributes,
    orderDetails: OrderDetailsResponseDto,
  ) => Promise<void>;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  refundOrderErrorCode: string;
}

const INSUFFICIENT_ACCOUNT_BALANCE = 'insufficient_account_balance';
const REFUND_ALREADY_IN_PROCESS = 'refund_already_in_process';
const TRANSACTION_DATE_TOO_OLD = 'transaction_date_too_old';
const REFUND_REJECTED = 'refund_rejected';

const SPECIFIC_ERROR_CODES = [
  REFUND_REJECTED,
  INSUFFICIENT_ACCOUNT_BALANCE,
  REFUND_ALREADY_IN_PROCESS,
  TRANSACTION_DATE_TOO_OLD,
];

export enum RefundAttributesTypes {
  TOTAL = 'total',
  PARTIAL_BY_VALUE = 'partialByValue',
}

export interface RefundAttributes {
  type: RefundAttributesTypes.TOTAL | RefundAttributesTypes.PARTIAL_BY_VALUE;
  value: string;
  returnsStock: boolean;
}

function useRefundOrder(id: string): Result {
  const [refundOrderStatus, setRefundOrderStatus] =
    useState<StatusType>('idle');
  const [refundOrderErrorCode, setRefundOrderErrorCode] = useState<string>('');

  const {
    hasFinalized: hasFinalizedListening,
    isLoading: isLoadingListening,
    isError: isErrorListening,
    polling,
  } = useRefundEventListener(id);

  const refund = useCallback(
    async (
      attributes: RefundAttributes,
      orderDetails: OrderDetailsResponseDto,
    ) => {
      setRefundOrderStatus('loading');
      const amount = attributes.value;
      const action = {
        amount: { value: amount, currency: orderDetails.currency },
        isPartial: Number(amount) < Number(orderDetails.amounts.total),
        shouldReturnStock: attributes.returnsStock,
      };
      try {
        const { refundAttemptId: attemptId } = await ordersService.refundById(
          id,
          action,
        );
        setRefundOrderStatus('success');
        polling(attemptId);
      } catch (error) {
        setRefundOrderStatus('error');
        if (!SPECIFIC_ERROR_CODES.includes(error.message)) {
          setRefundOrderErrorCode(REFUND_REJECTED);
        } else {
          setRefundOrderErrorCode(error.message);
        }
      }
    },
    [polling, id],
  );

  const isRefundAttemptSuccess = refundOrderStatus === 'success';
  const isLoading = refundOrderStatus === 'loading' || isLoadingListening;

  return {
    refund,
    isLoading,
    isError: refundOrderStatus === 'error' || isErrorListening,
    isSuccess: isRefundAttemptSuccess && hasFinalizedListening,
    refundOrderErrorCode,
  };
}

export default useRefundOrder;
