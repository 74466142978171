import { IconButton } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import ScopesItemHeaderLayout from '../ScopesItemHeaderLayout';

function Skeleton() {
  return (
    <ScopesItemHeaderLayout
      title={<Text.Skeleton />}
      rigthIcon={<IconButton.Skeleton />}
    />
  );
}

export default Skeleton;
