import { InfoCircleIcon, SlidersIcon } from '@tiendanube/icons';
import Link from 'commons/components/LegacyLink';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
import { useTranslationStatistics } from 'domains/Statistics/hooks';

interface GenericEmptySearchProps {
  onRetry?: () => void;
}

function GenericEmptySearch({ onRetry }: GenericEmptySearchProps) {
  const t = useTranslationStatistics();
  return (
    <Stack column align="center" justify="center">
      <Stack.Item>
        <InfoCircleIcon size="medium" />
      </Stack.Item>
      <Stack.Item>
        <Text textAlign="center">
          {t('statistics.genericEmptySearch.description')}
        </Text>
      </Stack.Item>
      <Stack.Item>
        <Link
          icon={SlidersIcon}
          iconPosition="end"
          appearance="primary"
          onClick={onRetry}
        >
          {t('statistics.genericEmptySearch.link')}{' '}
        </Link>
      </Stack.Item>
    </Stack>
  );
}

export default GenericEmptySearch;
