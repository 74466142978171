import { Box, Link, Text } from '@nimbus-ds/components';
import { differenceInDays } from 'date-fns';
import { RecurrentPaymentChargeDataDto } from '@tiendanube/common';
import { useExpirationDate } from 'domains/Billing/Checkout/hooks';
import { useGoToConceptDetails } from 'domains/Billing/Checkout/hooks/useGoToConceptDetails';
import { isPayableCharge } from 'domains/Billing/Checkout/hooks/utils';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import RecurrentPaymentDataSkeleton from './Skeleton';
import ChargePeriodDetail from '../../../ChargePeriodDetail/ChargePeriodDetail';
import PaymentStatusTag from '../../../PaymentStatusTag';

interface RecurrentPaymentDataProps {
  data: RecurrentPaymentChargeDataDto;
}

function RecurrentPaymentData({
  data: {
    amountCurrency,
    amountValue,
    description,
    nextExecutionDate,
    status,
    externalReference,
    concept,
    id,
    isProportional,
    fromDate,
    toDate,
  },
}: RecurrentPaymentDataProps) {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.recurrentPaymentsStatus',
  );
  const formatCurrency = useFormatCurrency();
  const { expiresIn } = useExpirationDate(concept, externalReference);

  const daysTillNextExecution = differenceInDays(
    new Date(nextExecutionDate),
    new Date(),
  );

  const goToDetails = useGoToConceptDetails(
    'recurrentPaymentStatus',
    concept,
    externalReference,
    id,
  );

  if (!amountValue) return null;

  const periodData = {
    isProportional,
    fromDate,
    toDate,
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      paddingX="4"
      paddingY="none"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" flex="1 1 60%" gap="1-5">
        <Text>{description}</Text>
        <ChargePeriodDetail {...periodData} />
        <PaymentStatusTag paymentStatus={status} />
        <Link appearance="primary" onClick={goToDetails}>
          {t('details')}
        </Link>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flex="1 1 40%"
        alignItems="flex-end"
        gap="2"
      >
        <Text>{formatCurrency(amountValue, amountCurrency)}</Text>
        {status === 'PAID' ? (
          <Text fontSize="caption" color="neutral-textLow">
            {t('nextExecution', { days: daysTillNextExecution })}
          </Text>
        ) : (
          expiresIn &&
          status &&
          isPayableCharge(status) && (
            <Text fontSize="caption" color="neutral-textLow">
              {t('expiresIn', { expiresIn })}
            </Text>
          )
        )}
      </Box>
    </Box>
  );
}

RecurrentPaymentData.Skeleton = RecurrentPaymentDataSkeleton;

export default RecurrentPaymentData;
