import { EmptyMessage } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Action, Domain as DomainEnum } from '@tiendanube/common/src/enums';
import {
  ErrorState,
  EmptyState,
  Text,
  Stack,
  Button,
} from '@tiendanube/components';
import { PlusCircleIcon, SearchIcon } from '@tiendanube/icons';
import VirtualDataList from 'commons/components/VirtualDataList';
import {
  useBulkActionOrders,
  useOrdersList,
} from 'domains/Orders/Orders/hooks';
import { OrderListItem, OrderResultsMobileSkeleton } from './components';
import emptyOrders from '../../empty-orders.png';
import { OrderSelectInBulk } from '../OrderSelectInBulk';

interface OrderResultsMobileProps {
  isEditMode: boolean;
  hasOrder: boolean;
  isLoadingHasOrders: boolean;
  onToggleEditMode: () => void;
  onHandleBulkAction: (ids: string[], value: Action) => void;
}

function OrderResultsMobile({
  isEditMode,
  hasOrder,
  isLoadingHasOrders,
  onToggleEditMode,
  onHandleBulkAction,
}: OrderResultsMobileProps): JSX.Element {
  const { t } = useTranslation([DomainEnum.ORDERS]);
  const { push } = useHistory();

  const {
    isLoading,
    isSuccess,
    isError,
    ordersIds,
    hasFilters,
    getOrdersList,
    refreshOrdersList,
    getMoreOrders,
  } = useOrdersList();

  const { selectedRowsId, bulkAction, handleOnSelectRow } = useBulkActionOrders(
    {
      rowsId: ordersIds,
      onHandleBulkAction,
      actionElement: <OrderSelectInBulk />,
    },
  );

  const isEmptyList = ordersIds.length === 0;
  const shouldShowSpinner = isLoading && ordersIds.length !== 0;
  const showSkeleton = (isLoading && isEmptyList) || isLoadingHasOrders;

  if (showSkeleton) return <OrderResultsMobileSkeleton />;
  if (isError && isEmptyList)
    return (
      <ErrorState
        title={t('errorResults.title')}
        action={{
          children: t('errorResults.action'),
          onClick: getOrdersList,
        }}
      />
    );
  if (isSuccess && isEmptyList && hasOrder) {
    if (hasFilters) {
      return (
        <EmptyMessage
          title={t('withoutResults.title')}
          text={t('withoutResults.text')}
          icon={<SearchIcon size={30} />}
        />
      );
    }
    return (
      <EmptyState image={emptyOrders} title={t('emptyResults.title')}>
        <Stack spacing="base" column align="flex-start">
          <Stack.Item>
            <Text>{`${t('emptyResults.body')}`}</Text>
          </Stack.Item>
          <Stack.Item>
            <Button
              icon={PlusCircleIcon}
              appearance="primary"
              onClick={() => push('draft-orders/new')}
            >
              {`${t('draftOrders.addDraftOrder')}`}
            </Button>
          </Stack.Item>
        </Stack>
      </EmptyState>
    );
  }

  return (
    <VirtualDataList
      editMode={isEditMode}
      onEditMode={onToggleEditMode}
      bulkAction={bulkAction}
      selectedRowsId={selectedRowsId}
      onSelectRow={handleOnSelectRow}
      onRefresh={refreshOrdersList}
      isPullable
      data={ordersIds}
      endReached={getMoreOrders}
      itemContent={(index, id) => <OrderListItem key={index} id={id} />}
      showFooter={shouldShowSpinner}
    />
  );
}

export default OrderResultsMobile;
