import { CheckCircleIcon } from '@nimbus-ds/icons';
import { useNavegate } from 'App/hooks';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components/IonPageStratus';
import { useAsyncFunc, useForm, useToast } from 'commons/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import CouponForm, {
  CouponErrorsInterface,
  CouponValuesInterface,
  validationSchema,
  initialValues,
} from '../../components/CouponForm';
import useCoupons from '../../hooks/useCoupons';
import { CouponPageModes } from '../types';

const CURRENT_USES = 0;

function NewCouponPage() {
  const { goBack } = useNavegate();
  const { createCoupon } = useCoupons();
  const { addToast } = useToast();
  const t = useTranslationMarketing('coupons.newCoupon');

  const [handleCreateCoupon, isLoading, isError, clearError, errorMessage] =
    useAsyncFunc<CouponValuesInterface, Promise<void>>(async (data) => {
      if (data) {
        await createCoupon(data);
        addToast({
          label: t('success'),
          appearance: 'success',
        });
        goBack();
      }
    });

  const {
    errors,
    values,
    isDirty,
    isValidating,
    handleOnChange,
    handleOnSubmit,
    setFieldValue,
  } = useForm<CouponValuesInterface, CouponErrorsInterface>({
    initialValues,
    validationSchema: validationSchema(CURRENT_USES),
    onSubmit: handleCreateCoupon,
  });

  const headerAction = {
    onClick: handleOnSubmit,
    children: t('submit'),
    icon: CheckCircleIcon,
    spinner: isValidating || isLoading,
    disabled: isValidating || isLoading,
  };

  return (
    <IonPageStratus
      width="small"
      headerTop={
        <HeaderTop mainAction={headerAction} navigation={{ onClick: goBack }} />
      }
      headerContent={<HeaderContent title={t('title')} />}
    >
      <CouponForm
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        isValidating={isValidating}
        isLoading={isLoading}
        handleOnChange={handleOnChange}
        handleOnSubmit={handleOnSubmit}
        isError={isError}
        errorMessageTranslation={t('error')}
        clearError={clearError}
        errorMessage={errorMessage}
        isDirty={isDirty}
        showActiveInput={false}
        couponIsUsed={false}
        currentUses={CURRENT_USES}
        confirmText={t('submit')}
        mode={CouponPageModes.NEW}
      />
    </IonPageStratus>
  );
}

export default NewCouponPage;
