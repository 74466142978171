import { useEffect, useState } from 'react';
import { ImportColumnsResponseDto } from '@tiendanube/common';
import { InterfaceSelectOption } from '@tiendanube/components';
import {
  useAsyncFunc,
  useToast,
  useTranslationCommon,
  WrappedFuncType,
} from 'commons/hooks';

interface useImportColumnOptionsResult {
  columnOptions: InterfaceSelectOption[];
  getColumnOptions: WrappedFuncType<
    string[],
    InterfaceSelectOption[] | undefined
  >;
  isLoading: boolean;
  isError: boolean;
  clearError: () => void;
  setColumnOptions: (columnOptions: InterfaceSelectOption[]) => void;
}

type ImportHandler = () => Promise<ImportColumnsResponseDto>;

export function useImportColumnOptions(
  importHandler: ImportHandler,
): useImportColumnOptionsResult {
  const t = useTranslationCommon();
  const { addToast } = useToast();
  const [columnOptions, setColumnOptions] = useState<InterfaceSelectOption[]>(
    [],
  );

  const [getColumnOptions, isLoading, isError, clearError] = useAsyncFunc(
    async (fileColumns?: string[]) => {
      if (columnOptions.length) {
        return columnOptions;
      }
      if (fileColumns) {
        const result = await importHandler();
        const colsOptions = Object.entries(result).map(([key, value]) => ({
          label: value,
          value: key,
          disabled: fileColumns.includes(value),
        }));
        setColumnOptions(colsOptions);
        return colsOptions;
      }
    },
  );

  useEffect(() => {
    if (isError) {
      addToast({
        label: t('importCsv.import.error'),
        appearance: 'danger',
      });
    }
  }, [addToast, isError, t]);

  return {
    columnOptions,
    getColumnOptions,
    isLoading,
    isError,
    clearError,
    setColumnOptions,
  };
}
