import { useMemo } from 'react';
import { Box, Card, Text } from '@nimbus-ds/components';
import {
  CurrencyType,
  FulfillmentOrdersResponseDto,
  OrderDetailsResponseDto,
} from '@tiendanube/common';
import {
  FulfillmentPreferenceType,
  Status as StatusEnum,
} from '@tiendanube/common/src/enums';
import { useBoolean } from 'commons/hooks';
import {
  ProductInterface,
  ProductSearcher,
} from 'domains/Orders/components/ProductSearcher';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { EditOrderProductList } from './ProductList';
import { ProductListRightHeader } from './ProductListRightHeader';
import { OrderEditProduct } from './types';
import { useEditOrderActions } from '../../hooks/useEditOrderActions';
import './EditOrderProducts.scss';

interface EditOrderProductsProps {
  orderDetails: OrderDetailsResponseDto;
  products: OrderEditProduct[];
  currency: CurrencyType;
  setEditedOrderProducts: (editedProducts: OrderEditProduct[]) => void;
  isOrderEdited: boolean;
  setIsOrderEdited: (isEdited: boolean) => void;
  fulfillmentOrder?: FulfillmentOrdersResponseDto;
}

function EditOrderProducts({
  orderDetails,
  products,
  currency,
  setEditedOrderProducts,
  isOrderEdited,
  setIsOrderEdited,
  fulfillmentOrder,
}: Readonly<EditOrderProductsProps>) {
  const {
    handleAddProduct: onAddProduct,
    handleEditProduct: onEditProduct,
    handleReAddProduct: onReAddProduct,
    handleRemoveProduct: onRemoveProduct,
    handleRestoreStock: onRestoreStock,
    ffoProducts,
  } = useEditOrderActions(
    orderDetails,
    fulfillmentOrder,
    products,
    setEditedOrderProducts,
    isOrderEdited,
    setIsOrderEdited,
  );
  const t = useTranslationOrders();
  const [isProductSearcherOpen, openProductSearcher, closeProductSearcher] =
    useBoolean(false);

  const hasDigitalProducts = useMemo(
    () =>
      [
        FulfillmentPreferenceType.DELIVERY_DIGITAL,
        FulfillmentPreferenceType.NON_SHIPPABLE,
      ].includes(fulfillmentOrder?.shipping.type as FulfillmentPreferenceType),
    [fulfillmentOrder],
  );

  const isEditable = useMemo(
    () =>
      ![
        StatusEnum.PACKED,
        StatusEnum.FULFILLED,
        StatusEnum.MARKED_AS_FULFILLED,
        StatusEnum.DISPATCHED,
      ].includes(fulfillmentOrder?.status as StatusEnum),
    [fulfillmentOrder],
  );

  const productsCount = useMemo(
    () =>
      ffoProducts.reduce(
        (acc, p) => acc + (p.editType !== 'delete' ? p.quantity : 0),
        0,
      ),
    [ffoProducts],
  );

  const titleTranslation = fulfillmentOrder
    ? 'editOrders.productList.fulfillmentTitle'
    : 'editOrders.productList.title';

  const title = t(titleTranslation, {
    count: productsCount,
  });

  const handleProductSearcherSubmit = (
    newProducts: ProductInterface<false>[],
  ) => {
    onAddProduct(newProducts);
    closeProductSearcher();
  };

  return (
    <Box marginBottom="4">
      <Card padding="none">
        <Card.Header>
          <Box
            paddingTop="4"
            paddingLeft="4"
            paddingRight="4"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box>
              <Text as="span" fontSize="highlight" color="neutral-textHigh">
                {title}
              </Text>
              <Text as="span" fontSize="highlight" color="neutral-textLow">
                {fulfillmentOrder?.assignedLocation.name}
              </Text>
            </Box>
            <ProductListRightHeader
              fulfillmentOrder={fulfillmentOrder}
              hasDigitalProducts={hasDigitalProducts}
              isEditable={isEditable}
              openProductSearcher={openProductSearcher}
            />
          </Box>
        </Card.Header>
        <div className="stratus--edit-order-product-list-container">
          <Card.Body padding="none">
            <EditOrderProductList
              products={ffoProducts}
              currency={currency}
              onEditProduct={onEditProduct}
              onRemoveProduct={onRemoveProduct}
              onReAddProduct={onReAddProduct}
              onRestoreStock={onRestoreStock}
              editable={isEditable}
            />
          </Card.Body>
          {!isEditable && (
            <Card.Footer>
              <Box paddingLeft="4" paddingBottom="2" paddingTop="2">
                <Text fontSize="caption">{t('editOrders.notEditable')}</Text>
              </Box>
            </Card.Footer>
          )}
        </div>

        <ProductSearcher<false>
          onClose={closeProductSearcher}
          onSubmit={handleProductSearcherSubmit}
          isOpen={isProductSearcherOpen}
          preselectedProducts={ffoProducts}
          location={fulfillmentOrder?.assignedLocation}
          requiresShipping={fulfillmentOrder ? !hasDigitalProducts : undefined}
          onlyPublishedProducts
        />
      </Card>
    </Box>
  );
}

export default EditOrderProducts;
