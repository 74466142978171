import { useState, useEffect } from 'react';
import { Box, Card, Checkbox, Radio, Text, Title } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import Skeleton from './Skeleton';
import { LOCATION_TAGS } from '../../constants';

export interface SalesChannelCardValuesInterface {
  tags: string[] | null;
}

interface SalesChannelCardProps {
  readonly values: SalesChannelCardValuesInterface;
  readonly onChange: (tags: string[] | null) => void;
}

function SalesChannelCard({
  values,
  onChange,
}: SalesChannelCardProps): JSX.Element {
  const t = useTranslationShipping();
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [allChecked, setAllChecked] = useState<boolean>(true);
  const [userInteracted, setUserInteracted] = useState<boolean>(false);

  useEffect(() => {
    if (!userInteracted) {
      if (values.tags === null) {
        setAllChecked(true);
        setSelectedTag(null);
      } else if (values.tags.length === 1) {
        setAllChecked(false);
        setSelectedTag(values.tags[0]);
      }
    }
  }, [values.tags, userInteracted]);

  const handleAllChange = () => {
    setUserInteracted(true);
    if (allChecked) {
      setAllChecked(false);
      setSelectedTag(null);
      onChange([]);
    } else {
      setAllChecked(true);
      setSelectedTag(null);
      onChange(null);
    }
  };

  const handleRadioChange = (tag: string) => {
    setUserInteracted(true);
    setSelectedTag(tag);
    setAllChecked(false);
    onChange([tag]);
  };

  return (
    <Card padding="none">
      <Card.Header padding="base">
        <Stack column align="flex-start">
          <Title as="h3">{t('locations.form.salesChannel.title')}</Title>
          <Checkbox
            id="all"
            name="all"
            label={t('locations.form.salesChannel.subtitle')}
            checked={allChecked}
            onChange={handleAllChange}
          />
        </Stack>
      </Card.Header>

      {!allChecked && (
        <Box
          width="100%"
          height="1px"
          backgroundColor="neutral-surfaceHighlight"
        />
      )}

      <Card.Body padding="base">
        {!allChecked && (
          <Stack column align="flex-start" spacing="base">
            <Text fontSize="caption">
              {t('locations.form.salesChannel.caption')}
            </Text>
            <Radio
              id={LOCATION_TAGS.online}
              name="salesChannel"
              label={t('locations.form.salesChannel.online')}
              value={LOCATION_TAGS.online}
              checked={selectedTag === LOCATION_TAGS.online}
              onChange={() => handleRadioChange(LOCATION_TAGS.online)}
            />
            <Box paddingBottom="2-5">
              <Radio
                id={LOCATION_TAGS.offline}
                name="salesChannel"
                label={t('locations.form.salesChannel.offline')}
                value={LOCATION_TAGS.offline}
                checked={selectedTag === LOCATION_TAGS.offline}
                onChange={() => handleRadioChange(LOCATION_TAGS.offline)}
              />
            </Box>
          </Stack>
        )}
      </Card.Body>
    </Card>
  );
}

SalesChannelCard.Skeleton = Skeleton;

export default SalesChannelCard;
