import { ChangeEvent } from 'react';
import {
  addNodeUnderParent,
  removeNodeAtPath,
  changeNodeAtPath,
  NodeData,
  getNodeAtPath,
  insertNode,
} from '@nosferatu500/react-sortable-tree';
import { VisibilityCategoryType } from '@tiendanube/common/src/domains/catalog/interfaces';
import { newEmptyNode } from '../../utils';
import { TreeData } from '../useCategoriesTree/useCategoriesTreeNew';

interface UseCategoryHandlersResult {
  handleAddCategory: (
    path: number[],
    parentVisibility: VisibilityCategoryType,
  ) => void;
  handleAddSubcategory: (
    rowInfo: NodeData,
    parentVisibility: VisibilityCategoryType,
  ) => void;
  handleRemoveCategory: (path: number[]) => void;
  handleChangeInput: (
    e: ChangeEvent<HTMLInputElement>,
    rowInfo: NodeData,
  ) => void;
}

function useCategoriesHandlers(
  treeData: TreeData[],
  setTree: (newTree: TreeData[]) => void,
  setIdSelected: (id: string) => void,
): UseCategoryHandlersResult {
  const getNodeKey = ({ treeIndex }) => treeIndex;

  const handleChangeInput = (
    event: ChangeEvent<HTMLInputElement>,
    rowInfo: NodeData,
  ) => {
    const name = event.target.value;

    const treeUpdated = changeNodeAtPath({
      treeData,
      path: rowInfo.path,
      getNodeKey: ({ treeIndex }) => treeIndex,
      newNode: { ...rowInfo.node, text: name },
    });

    setTree(treeUpdated as TreeData[]);
  };

  const handleAddSubcategory = (
    rowInfo: NodeData,
    parentVisibility: VisibilityCategoryType,
  ) => {
    const newNode = newEmptyNode({
      parentId: rowInfo.node.id,
      visibility: parentVisibility === 'visible' ? 'visible' : 'soft-hidden',
    });
    const treeUpdated = addNodeUnderParent({
      treeData,
      parentKey: rowInfo.path[rowInfo.path.length - 1],
      expandParent: true,
      getNodeKey,
      newNode,
    }).treeData;
    setIdSelected(newNode.id);
    setTree(treeUpdated as TreeData[]);
  };

  const handleAddCategory = (
    path: number[],
    parentVisibility: VisibilityCategoryType,
  ) => {
    const newNode = newEmptyNode({
      visibility: parentVisibility === 'visible' ? 'visible' : 'soft-hidden',
    });

    const siblingNode = getNodeAtPath({
      treeData,
      path,
      getNodeKey,
    });

    const treeUpdated = insertNode({
      treeData,
      depth: path.length - 1,
      minimumTreeIndex: siblingNode ? siblingNode.treeIndex + 1 : 0, // treeIndex insert before; +1 insert after
      newNode,
      expandParent: true,
      getNodeKey,
    }).treeData;

    setIdSelected(newNode.id);
    setTree(treeUpdated as TreeData[]);
  };

  const handleRemoveCategory = (path: number[]) => {
    const treeUpdated = removeNodeAtPath({
      treeData,
      path,
      getNodeKey,
    });
    setTree(treeUpdated as TreeData[]);
  };

  return {
    handleAddCategory,
    handleAddSubcategory,
    handleRemoveCategory,
    handleChangeInput,
  };
}

export default useCategoriesHandlers;
