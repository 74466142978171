import { AddressCard } from 'domains/Shipping/Locations/pages/components';
import LocationListForPickup from './components/LocationListForPickup';
import PickupName from './components/PickupName';

function FirsStepFormSkeleton(): JSX.Element {
  return (
    <>
      <PickupName.Skeleton />
      <AddressCard.Skeleton />
      <LocationListForPickup.Skeleton />
    </>
  );
}

export default FirsStepFormSkeleton;
