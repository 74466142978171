import {
  Icon,
  IconButton,
  Link,
  Table,
  Tag,
  Text,
  Tooltip,
} from '@nimbus-ds/components';
import { EditIcon, StarIcon, TrashIcon } from '@nimbus-ds/icons';
import { LocationResponseDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { Stack } from 'commons/components';
import useHasShippingLocationTag from 'domains/Auth/hooks/useHasShippingLocationTag';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import { trackingShippingDeleteDistributionCenter } from 'domains/Catalog/Products/tracking';
import { trackingShippingEditDistributionCenter } from 'domains/Shipping/tracking';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import LocationsListBodySkeleton from './Skeleton';

interface LocationsListDesktopProps {
  locations: LocationResponseDto[];
  openSetMainModal: (locationId: any) => void;
  openDeleteModal: (locationId: any) => void;
}

function LocationsListBody({
  locations,
  openSetMainModal,
  openDeleteModal,
}: Readonly<LocationsListDesktopProps>): JSX.Element | null {
  const t = useTranslationShipping();
  const { goTo } = useNavegate();
  const { Row, Cell, Body } = Table;
  const handleClick = (locationId: string) => {
    trackingShippingEditDistributionCenter('secondary', locationId);
    goTo(`/settings/locations/${locationId}`);
  };

  const hasShippingLocationTag = useHasShippingLocationTag();
  const sender = useTrackFullCatalog();
  const handleDelete = (locationId: string) => {
    sender(() => {
      trackingShippingDeleteDistributionCenter(locationId);
    });
    openDeleteModal(locationId);
  };
  return (
    <Body>
      {locations.map((location) => (
        <Row key={location.id}>
          <Cell padding="base">
            <Text fontWeight="bold">{location.priority + 1}</Text>
          </Cell>
          <Cell padding="base">
            <Stack spacing="tight" align="flex-start">
              <Link
                textDecoration="none"
                appearance="primary"
                onClick={() => handleClick(location.id)}
              >
                <Text fontWeight="bold" color="primary-interactive">
                  {location.name}
                </Text>
              </Link>
              {location.main && (
                <Tag appearance="primary">
                  <Icon source={<StarIcon />} />
                  {t('locations.card.label')}
                </Tag>
              )}
            </Stack>
          </Cell>
          <Cell padding="base">{location.address}</Cell>
          {hasShippingLocationTag && location.tags && (
            <Cell padding="base">
              <Stack
                spacing="tight"
                justify="flex-start"
                align="flex-start"
                wrap
              >
                {location.tags?.map((tag) => (
                  <Tag appearance="neutral" key={tag}>
                    {t(`locations.card.tag.${tag}`)}
                  </Tag>
                ))}
              </Stack>
            </Cell>
          )}
          <Cell padding="small">
            <Stack spacing="tight" align="flex-start">
              <Tooltip content={t('locations.card.edit')}>
                <IconButton
                  borderColor="transparent"
                  source={<EditIcon />}
                  onClick={() => handleClick(location.id)}
                />
              </Tooltip>
              {!location.main && (
                <>
                  <Tooltip content={t('locations.card.setAsMain')}>
                    <IconButton
                      borderColor="transparent"
                      source={<StarIcon />}
                      onClick={() => openSetMainModal(location.id)}
                    />
                  </Tooltip>
                  <Tooltip content={t('locations.card.delete')}>
                    <IconButton
                      borderColor="transparent"
                      source={<TrashIcon />}
                      onClick={() => handleDelete(location.id)}
                    />
                  </Tooltip>
                </>
              )}
            </Stack>
          </Cell>
        </Row>
      ))}
    </Body>
  );
}

LocationsListBody.Skeleton = LocationsListBodySkeleton;
export default LocationsListBody;
