import { PlansListResponseDto } from '@tiendanube/common';
import { DataTable } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import ButtonPlan from 'domains/Billing/BillingPlans/pages/BillingPlansPage/components/ComparisonPlansCards/components/ButtonPlan';
import { SelectedPlan } from 'domains/Billing/BillingPlans/pages/BillingPlansPage/components/ComparisonPlansCards/components/PlanCard/types';

const { Cell, Row } = DataTable;

interface ComparisonTableDesktopFooterProps {
  currentPlanId: number;
  currentPlanLevel: number;
  firstPlan: PlansListResponseDto;
  secondPlan: PlansListResponseDto;
  thirdPlan: PlansListResponseDto;
  fourthPlan: PlansListResponseDto;
  onSelectPlan: (plan: SelectedPlan) => void;
}

function ComparisonTableDesktopFooter({
  currentPlanId,
  currentPlanLevel,
  firstPlan,
  secondPlan,
  thirdPlan,
  fourthPlan,
  onSelectPlan,
}: ComparisonTableDesktopFooterProps): JSX.Element {
  return (
    <Row id="buttons">
      <Cell grow={0} basis={360} />
      <Cell>
        <Text textAlign="center" block>
          {(!firstPlan.isFree || firstPlan.id !== currentPlanId) && (
            <ButtonPlan
              plan={firstPlan}
              currentPlanId={currentPlanId}
              currentPlanLevel={currentPlanLevel}
              onSelectPlan={onSelectPlan}
            />
          )}
        </Text>
      </Cell>
      <Cell>
        <Text textAlign="center" block>
          <ButtonPlan
            plan={secondPlan}
            currentPlanId={currentPlanId}
            currentPlanLevel={currentPlanLevel}
            onSelectPlan={onSelectPlan}
          />
        </Text>
      </Cell>
      <Cell>
        <Text textAlign="center" block>
          <ButtonPlan
            plan={thirdPlan}
            currentPlanId={currentPlanId}
            currentPlanLevel={currentPlanLevel}
            onSelectPlan={onSelectPlan}
          />
        </Text>
      </Cell>
      {!fourthPlan.isNextPlan && (
        <Cell>
          <Text textAlign="center" block>
            <ButtonPlan
              plan={fourthPlan}
              currentPlanId={currentPlanId}
              currentPlanLevel={currentPlanLevel}
              onSelectPlan={onSelectPlan}
            />
          </Text>
        </Cell>
      )}
    </Row>
  );
}
export default ComparisonTableDesktopFooter;
