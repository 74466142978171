import { Locale } from 'App/i18n/enums';
import { ARGENTINA } from './argentina';
import { BRAZIL } from './brazil';
import { CHILE } from './chile';
import { COLOMBIA } from './colombia';
import { MEXICO } from './mexico';
import { SectionAppCardDomains } from '../SectionAppCard';

export interface InterfaceApp {
  id: number;
  name: string;
  description: string;
  image?: string;
  categoryUri: string;
  urlText: string;
  handle: string;
}

export const BASE_IMAGE_URL = 'https://d2r9epyceweg5n.cloudfront.net';

type SectionApps = Record<
  keyof typeof SectionAppCardDomains,
  InterfaceApp | undefined
>;

interface InterfaceData {
  [Locale.BRAZIL]: SectionApps;
  [Locale.ARGENTINA]: SectionApps;
  [Locale.MEXICO]: SectionApps;
  [Locale.CHILE]: SectionApps;
  [Locale.COLOMBIA]: SectionApps;
}

export const data: InterfaceData = {
  [Locale.BRAZIL]: BRAZIL,
  [Locale.ARGENTINA]: ARGENTINA,
  [Locale.MEXICO]: MEXICO,
  [Locale.CHILE]: CHILE,
  [Locale.COLOMBIA]: COLOMBIA,
};
