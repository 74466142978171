import { useEffect, useRef } from 'react';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Modal } from '@tiendanube/components';
import { getStorage, setStorage } from 'App/storage';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
import { STORE_APP } from 'commons/constants/externalLinks';
import { useModal } from 'commons/hooks';
import './RateSuggestionModal.css';

function RateSuggestionModal(): JSX.Element {
  const [show, openModal, closeModal] = useModal();
  const { t } = useTranslation([Domain.DASHBOARD]);
  const timeOutRef = useRef<number>(0);
  const storeUrlRef = useRef<string>();
  const platform = Capacitor.getPlatform();

  useEffect(() => {
    const fn = async () => {
      const TIMES = 10;

      storeUrlRef.current = STORE_APP[platform];

      if (!storeUrlRef.current) return;

      let modalSuggestionCounter = parseInt(
        await getStorage('modalSuggestionCounter'),
      );
      if (!modalSuggestionCounter) {
        setStorage('modalSuggestionCounter', 1);
      }
      if (modalSuggestionCounter <= TIMES) {
        setStorage('modalSuggestionCounter', ++modalSuggestionCounter);
      }
      if (modalSuggestionCounter === TIMES) {
        timeOutRef.current = window.setTimeout(() => {
          openModal();
        }, 500);
      }
    };

    fn();

    return () => {
      if (timeOutRef.current) {
        window.clearTimeout(timeOutRef.current);
      }
    };
  }, [openModal, platform]);

  const handleOnDismiss = () => {
    closeModal();
  };

  const handlePrimaryAction = () => {
    closeModal();
    window.open(storeUrlRef.current);
  };

  return (
    <Modal
      title=""
      show={show}
      onDismiss={handleOnDismiss}
      primaryAction={{
        children: 'Valorar',
        onClick: handlePrimaryAction,
        appearance: 'primary',
      }}
    >
      <Stack column align="flex-start">
        <Stack.Item>
          <span
            role="img"
            aria-label="RateSuggestion"
            className="nimbus--rate-suggestion-modal__icon"
          >
            ⭐⭐⭐⭐⭐
          </span>
        </Stack.Item>
        <Stack.Item>
          <Text bold>{`${t('store.text1')}`}</Text>
        </Stack.Item>
        <Stack.Item>
          <Text>{`${t('store.text2')}`}</Text>
        </Stack.Item>
      </Stack>
    </Modal>
  );
}

export default RateSuggestionModal;
