import { useEffect, useMemo } from 'react';
import { Tag } from '@nimbus-ds/components';
import {
  Card,
  Stack,
  Title,
  InterfaceNameValue,
  Grid,
  Link,
  InteractiveList,
} from '@tiendanube/components';
import { HistoryIcon } from '@tiendanube/icons';
import { InputNumber } from 'commons/components';
import { useModal } from 'commons/hooks';
import {
  useHasMultipleLocations,
  useTranslationCatalog,
} from 'domains/Catalog/hooks';
import { trackingProductStockEditClick } from 'domains/Catalog/Products/tracking';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import LocationSelect from './LocationSelect';
import { HandleChangeInterface } from '../../hooks/useProductForm/types';
import { useGoToInventory } from '../GoToInventoryModal/GoToInventoryProvider';
import { EditStockModal } from '../Stock';
import { LinkStock } from '../Stock/components';
import { useStockHistoryContext } from '../StockHistoryModal/hooks';
import { VariantLocationTag } from '../Variants/components';
import { VariantListItemInterface } from '../Variants/components/CardVariants';
import { Variant } from '../Variants/types';
import './Inventory.scss';

interface InventoryProps {
  isInfinite: boolean;
  stock: string | null;
  currentStock?: string | null;
  variantId: string;
  variantName: string;
  productId: string;
  isVariant?: boolean;
  onChange: (data: HandleChangeInterface) => void;
  hasShippingMultiCD: boolean;
  locationId?: string;
  hasVariants: boolean;
  onChangeLocation: (event: InterfaceNameValue) => void;
  isEdit: boolean;
  variant: Variant;
  canEditStock: boolean;
  onGoToInventory?: (variantId: string) => void;
}

function Inventory({
  isInfinite,
  onChange,
  locationId,
  onChangeLocation,
  isEdit,
  stock,
  productId,
  variantId,
  variantName,
  variant,
  hasShippingMultiCD,
  canEditStock,
}: InventoryProps): JSX.Element | null {
  const t = useTranslationCatalog();

  const options = [
    {
      title: t('products.detail.infinite'),
      name: 'infinite',
      active: isInfinite,
      disabled: !canEditStock,
    },
    {
      title: t('products.detail.limited'),
      name: 'limited',
      active: !isInfinite,
      disabled: !canEditStock,
    },
  ];

  const { openStockHistoryModal, showAccessToStockHistory } =
    useStockHistoryContext();
  const [showEditStockModal, openEditStockModal, closeEditStockModal] =
    useModal();

  const handleOnChangeInfinite = () =>
    onChange({ name: 'isInfinite', value: !isInfinite });

  const locationResult = useLocations();

  const { locations, isSuccess, fetchLocations, locationsMainId } =
    locationResult;

  const hasMultipleLocations = useHasMultipleLocations(locations);

  const currentLocationId = useMemo(
    () =>
      locationId
        ? locationId
        : hasShippingMultiCD
        ? locationsMainId
        : undefined,
    [locationsMainId, locationId, hasShippingMultiCD],
  );

  const { handleGoToInventory } = useGoToInventory();

  const stockTitle = t('products.detail.stock');

  useEffect(() => {
    if (!isSuccess) {
      fetchLocations();
    }
  }, [isSuccess, fetchLocations]);

  const locationSelect = (
    <div className="stratus--wrapper-select">
      <LocationSelect
        locationId={locationId}
        onChange={onChangeLocation}
        locationResult={locationResult}
      />
    </div>
  );

  const handleOpenStock = (variant: VariantListItemInterface) => {
    trackingProductStockEditClick(variant.stock);
    hasMultipleLocations
      ? handleGoToInventory(variant.productId, variant.id)
      : openEditStockModal();
  };

  return (
    <>
      <Card title={t('products.detail.inventory')}>
        <Stack column align="stretch">
          <Stack.Item fill>
            <Stack align="stretch" column>
              {!isEdit && (
                <>
                  <Stack.Item fill>
                    <Title type="h4">{stockTitle}</Title>
                  </Stack.Item>
                  <Stack.Item fill>
                    <InteractiveList
                      onChange={handleOnChangeInfinite}
                      mode="single"
                      options={options}
                    />
                  </Stack.Item>
                </>
              )}
              {((!isEdit && !isInfinite) || isEdit) && (
                <Stack.Item fill>
                  <Grid spacing="tight">
                    <Grid.Row>
                      <Grid.Column lg={6}>
                        {!isEdit && !isInfinite && (
                          <InputNumber
                            type="integer"
                            label={t('products.detail.quantity')}
                            placeholder=""
                            name="stock"
                            onChange={onChange}
                            value={stock || ''}
                            disabled={!canEditStock}
                          />
                        )}
                        {isEdit && (
                          <Stack justify="space-between">
                            <Stack column spacing="tight">
                              <Stack.Item fill>
                                <LinkStock
                                  onEditStock={() => handleOpenStock(variant)}
                                  variant={variant}
                                  canEditStock={canEditStock}
                                />
                              </Stack.Item>
                              {hasMultipleLocations && (
                                <Stack.Item fill>
                                  {currentLocationId ? (
                                    <VariantLocationTag
                                      variant={{
                                        ...variant,
                                        inventoryLevels:
                                          variant.inventory_levels,
                                      }}
                                      locations={locations}
                                    />
                                  ) : (
                                    <Tag.Skeleton />
                                  )}
                                </Stack.Item>
                              )}
                            </Stack>
                          </Stack>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Stack.Item>
              )}
            </Stack>
          </Stack.Item>
          {hasMultipleLocations && !isEdit && (
            <Stack.Item fill>{locationSelect}</Stack.Item>
          )}
          {isEdit && showAccessToStockHistory && (
            <Stack.Item>
              <Link
                appearance="primary"
                icon={HistoryIcon}
                onClick={() =>
                  openStockHistoryModal({
                    variantId,
                    variantName,
                    productId,
                  })
                }
              >
                {t('products.detail.goToStockHistory')}
              </Link>
            </Stack.Item>
          )}
        </Stack>
      </Card>
      {!hasMultipleLocations && (
        <EditStockModal
          isShow={showEditStockModal}
          productId={productId}
          variantId={variantId}
          currentStock={stock}
          onClose={closeEditStockModal}
          displayStockTypes
          locationId={locationId}
        />
      )}
    </>
  );
}

export default Inventory;
