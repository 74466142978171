import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useQuery } from 'App/hooks';
import NuvemAppPage from 'domains/PartnersApps/pages/NuvemAppPage';
import { MARKETING_AUTOMATION_ROUTES } from './marketingAutomationRoutes';
import { MarketingAutomationPage } from './pages';
import { trackingMarketingAutomationPageView } from './tracking';
import { useNuvemMarketing } from '../hook';

function MarketingAutomation() {
  const { hasAccessToNuvemMarketing, hasNuvemMarketingApplication } =
    useNuvemMarketing();
  const showLandingPage = useQuery().get('show_landing_page') === 'true';

  useEffect(() => {
    if (hasAccessToNuvemMarketing) {
      const pageId = hasNuvemMarketingApplication
        ? 'marketing-automation-dashboard'
        : 'marketing-automation-landing';

      trackingMarketingAutomationPageView({
        pageId,
      });
    }
  }, [hasAccessToNuvemMarketing, hasNuvemMarketingApplication]);

  if (!hasAccessToNuvemMarketing) {
    return null;
  }

  return (
    <Switch>
      <Route path={MARKETING_AUTOMATION_ROUTES.marketingAutomation} exact>
        {hasNuvemMarketingApplication && !showLandingPage ? (
          <NuvemAppPage code="marketing-automation" />
        ) : (
          <MarketingAutomationPage />
        )}
      </Route>
    </Switch>
  );
}

export default MarketingAutomation;
