import {
  ImportFileRequestDto,
  ImportFileResponseDto,
  JobStatusType,
} from '@tiendanube/common';
import { useBackgroundJobs } from 'App/settings/jobs/hooks';
import {
  useAsyncFunc,
  useToast,
  useTranslationCommon,
  WrappedFuncType,
} from 'commons/hooks';

interface UseImportFileResult {
  sendFile: WrappedFuncType<ImportFileRequestDto, boolean>;
  isLoading: boolean;
  isError: boolean;
  cleanJob: () => Promise<void>;
  jobStatus: JobStatusType;
}

type JobKey = 'productsCsvImport' | 'customersCsvImport';
type ImportHandler = (
  params: ImportFileRequestDto,
) => Promise<ImportFileResponseDto>;

export function useImportFile(
  jobKey: JobKey,
  importHandler: ImportHandler,
): UseImportFileResult {
  const t = useTranslationCommon();
  const { addToast } = useToast();
  const { setJob, cleanJob, jobStatus } = useBackgroundJobs(jobKey);

  const handleError = () => {
    addToast({
      label: t('importCsv.import.error'),
      appearance: 'danger',
    });
    clearError();
  };

  const handleSuccess = () => null;

  const [sendFile, isLoading, isError, clearError] = useAsyncFunc(
    async (params?: ImportFileRequestDto) => {
      if (params) {
        const { token } = await importHandler(params);
        setJob(token);
        return true;
      }
      return false;
    },
    handleSuccess,
    handleError,
  );

  return { sendFile, isLoading, isError, cleanJob, jobStatus };
}
