// Constants
const HEADER_BYTE_LENGTH = 12; // Number of bytes to read from the file header
const OFFSET_INIT = 16; // Offset inital

// MIME types as an array of objects
const MIME_TYPES = [
  { mediaType: 'image', regex: '^89504e47', mimeType: 'image/png' }, // PNG
  { mediaType: 'image', regex: '^47494638', mimeType: 'image/gif' }, // GIF
  { mediaType: 'image', regex: '^ffd8', mimeType: 'image/jpeg' }, // JPEG
  {
    mediaType: 'image',
    regex: '^52494646.{8}57454250',
    mimeType: 'image/webp',
  }, // RIFF + WEBP
  { mediaType: 'video', regex: '^1a45dfa3', mimeType: 'video/webm' }, // WebM
  { mediaType: 'video', regex: '^52494646.{8}41564920', mimeType: 'video/avi' }, // RIFF + AVI
  { mediaType: 'video', regex: '^00000014', mimeType: 'video/quicktime' }, // QuickTime
  {
    mediaType: 'video',
    regex: '^000001ba|^000001b3|^66747970',
    mimeType: 'video/mpeg',
  }, // MPEG (incluye 66747970)
  { mediaType: 'video', regex: '^.{8}66747970', mimeType: 'video/mp4' }, // MP4 (displaced)
  { mediaType: 'video', regex: '^0000001c', mimeType: 'video/3gpp' }, // 3GPP
  { mediaType: 'video', regex: '^464c5601', mimeType: 'video/x-flv' }, // FLV
];

export const imageMimeTypes = MIME_TYPES.filter(
  (type) => type.mediaType === 'image',
).map((type) => type.mimeType);
export const videoMimeTypes = MIME_TYPES.filter(
  (type) => type.mediaType === 'video',
).map((type) => type.mimeType);

/**
 * Detects the MIME type of a file based on its binary header.
 * @param file - The file to analyze.
 * @returns A promise resolving to the MIME type or an empty string if unknown.
 */
export const getMimeType = (file: File): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = new Uint8Array(
        e.target?.result as ArrayBuffer,
      ).subarray(0, HEADER_BYTE_LENGTH);

      // Convert buffer to hex string
      const headerHex = Array.from(arrayBuffer)
        .map((byte) => byte.toString(OFFSET_INIT).padStart(2, '0'))
        .join('');

      for (const { regex, mimeType } of MIME_TYPES) {
        const pattern = new RegExp(regex);
        if (pattern.test(headerHex)) {
          return resolve(mimeType);
        }
      }

      resolve(''); // Return empty string if no match
    };

    reader.readAsArrayBuffer(file);
  });

export default getMimeType;
