import { ChangeEvent } from 'react';
import { Card, Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { Stack, InputNumberNimbus } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import RangeSkeleton from './Skeleton';

interface RangeProps {
  range: string;
  errors: Partial<Record<string, string>>;
  setFieldValue: (name: string, value: any) => void;
}

function Range({
  range,
  errors,
  setFieldValue,
}: Readonly<RangeProps>): JSX.Element {
  const t = useTranslationShipping(
    'deliveryMethods.pickupPoints.form.secondStep.range',
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue('range', e.target.value);
  };

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <Stack column align="stretch">
          <FormField label={t('label')}>
            <InputNumberNimbus
              type="integer"
              name="range"
              value={range}
              appendPosition="end"
              onChange={onChange}
              append={t('distance')}
              appearance={errors.range ? 'danger' : 'neutral'}
            />
            {!!errors.range && (
              <Text fontSize="caption" color="danger-textLow">
                {t(errors.range)}
              </Text>
            )}
          </FormField>
        </Stack>
      </Card.Body>
    </Card>
  );
}

Range.Skeleton = RangeSkeleton;
export default Range;
