import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import Text from 'commons/components/LegacyText';
import { addOpacityToHexColor } from 'commons/utils/addOpacityToHexColor';
import { calculateOpacity } from 'commons/utils/calculateOpacity';
import {
  ChartContainer,
  StratusTooltipContent,
  TooltipChartStratus,
} from 'domains/Statistics/components';
import {
  StatisticsGranularityType,
  TotalsInterface,
} from 'domains/Statistics/types';
import {
  abbreviateNumber,
  FONT_SIZE_BAR_CHART,
} from 'domains/Statistics/utils';
import useCombinedChartLegend from './useCombinedChartLegend';
import {
  ANIMATION_DURATION,
  COMBINED_CHART_COMPARED_COLORS,
  FULL_BAR_LINE_OPACITY,
  LABEL_FONT_SIZE,
  LIGHT_BAR_OPACITY,
  LIGHT_LINE_OPACITY,
} from '../../utils';
import './CombinedChart.scss';

interface ResultsValueInterface {
  current: Record<string, string | number>;
  previous?: Record<string, string | number>;
}

interface ResultsInterface {
  keys: string[];
  values: ResultsValueInterface[];
}

export interface CombinedChartDataInterface<TKeys extends string> {
  granularity: StatisticsGranularityType;
  results: Record<TKeys, ResultsInterface>;
  summary: Record<TKeys, TotalsInterface>;
}

interface CombinedChartProps<TKeys extends string> {
  data: CombinedChartDataInterface<TKeys>;
  colors: Record<TKeys, string>;
  selectedPrimary: string;
  hasComparedFilter: boolean;
  formatLabelTooltip?: (label: string) => string;
  selectedSecondary?: string;
  xAxisFormatter?: (value: string) => string;
}

function CombinedChart<TKeys extends string>({
  data,
  hasComparedFilter,
  colors,
  selectedPrimary,
  selectedSecondary,
  formatLabelTooltip = (text) => text,
  xAxisFormatter,
}: Readonly<CombinedChartProps<TKeys>>) {
  const getValues = () => {
    if (hasComparedFilter) return data.results[selectedPrimary].values;
    return data.results[selectedPrimary].values.map((item, i) => ({
      current: item.current,
      ...(selectedSecondary && {
        previous: data.results[selectedSecondary].values[i].current,
      }),
    }));
  };

  const mainPeriodColor = hasComparedFilter
    ? COMBINED_CHART_COMPARED_COLORS.mainPeriod
    : colors[selectedPrimary];

  const comparedPeriodColor =
    hasComparedFilter || !selectedSecondary
      ? COMBINED_CHART_COMPARED_COLORS.comparedPeriod
      : colors[selectedSecondary];

  const {
    legendSegments,
    hover,
    handleLegendMouseEnter,
    handleLegendMouseLeave,
    selectBarFromLegend,
  } = useCombinedChartLegend(data, selectedPrimary, selectedSecondary);

  const maxBarSize = Object.keys(data.results).length > 8 ? 12 : undefined;

  const legendFormatter = (value: string, entry: Payload) => {
    // On hover this should gray every other element user's not hovering
    const shouldBeGray = hover && hover !== entry.dataKey;
    const text = formatLabelTooltip(value);
    return (
      <Text>
        <span
          style={{
            opacity: shouldBeGray ? LIGHT_LINE_OPACITY : FULL_BAR_LINE_OPACITY,
          }}
        >
          {text}
        </span>
      </Text>
    );
  };

  return (
    <ChartContainer className="stratus--combined-chart">
      <ComposedChart
        data={getValues()}
        style={{ fontSize: FONT_SIZE_BAR_CHART }}
        maxBarSize={maxBarSize}
        margin={{ top: 20 }}
      >
        <CartesianGrid vertical={false} strokeDasharray="2 0" />
        <XAxis dataKey="current.name" tickFormatter={xAxisFormatter} />
        <YAxis
          yAxisId="left"
          type="number"
          tickFormatter={abbreviateNumber}
          axisLine={false}
          tickLine={false}
        />

        {!hasComparedFilter && (
          <YAxis
            yAxisId="right"
            orientation="right"
            tickFormatter={abbreviateNumber}
            axisLine={false}
            tickLine={false}
          />
        )}
        <Legend
          onClick={selectBarFromLegend}
          onMouseOver={handleLegendMouseEnter}
          onMouseOut={handleLegendMouseLeave}
          formatter={legendFormatter}
          iconSize={30}
        />

        <Tooltip
          content={
            <TooltipChartStratus>
              <StratusTooltipContent
                granularity={data.granularity}
                hasComparedData={hasComparedFilter}
                formatLabel={formatLabelTooltip}
                isCombined
              />
            </TooltipChartStratus>
          }
        />

        <Bar
          key="totals"
          yAxisId="left"
          fill={mainPeriodColor}
          dataKey="current.totals"
          hide={legendSegments['current.totals'] === true}
          fillOpacity={
            !hover || hover === 'current.totals'
              ? FULL_BAR_LINE_OPACITY
              : LIGHT_BAR_OPACITY
          }
          label={
            hover === `current.totals` && {
              fill: mainPeriodColor,
              fontSize: LABEL_FONT_SIZE,
              position: 'top',
              formatter: abbreviateNumber,
            }
          }
          animationDuration={ANIMATION_DURATION}
        />

        {data.results[selectedPrimary].keys.map((key) => (
          <Line
            key={key}
            yAxisId="left"
            dot={false}
            activeDot
            strokeWidth={3}
            connectNulls
            dataKey={`current.${key}`}
            hide={legendSegments[`current.${key}`] === true}
            stroke={addOpacityToHexColor(
              mainPeriodColor,
              calculateOpacity(
                data.results[selectedPrimary].keys.indexOf(key),
                data.results[selectedPrimary].keys.length,
              ),
            )}
            strokeOpacity={
              !hover || hover === `current.${key}`
                ? FULL_BAR_LINE_OPACITY
                : LIGHT_LINE_OPACITY
            }
            label={
              hover === `current.${key}` && {
                fill: mainPeriodColor,
                fontSize: LABEL_FONT_SIZE,
                position: 'top',
                formatter: abbreviateNumber,
              }
            }
            animationDuration={ANIMATION_DURATION}
          />
        ))}

        {(hasComparedFilter || !!selectedSecondary) && (
          <Bar
            key="totals-compared"
            yAxisId={hasComparedFilter ? 'left' : 'right'}
            fill={comparedPeriodColor}
            dataKey="previous.totals"
            hide={legendSegments['previous.totals'] === true}
            fillOpacity={
              !hover || hover === 'previous.totals' || !hover
                ? FULL_BAR_LINE_OPACITY
                : LIGHT_LINE_OPACITY
            }
            label={
              hover === `previous.totals` && {
                fill: comparedPeriodColor,
                fontSize: LABEL_FONT_SIZE,
                position: 'top',
                formatter: abbreviateNumber,
              }
            }
            animationDuration={ANIMATION_DURATION}
          />
        )}

        {(hasComparedFilter || !!selectedSecondary) &&
          data.results[selectedSecondary || selectedPrimary].keys.map((key) => (
            <Line
              key={`${key}-compared`}
              yAxisId={hasComparedFilter ? 'left' : 'right'}
              dot={false}
              activeDot
              strokeWidth={3}
              connectNulls
              dataKey={`previous.${key}`}
              hide={legendSegments[`previous.${key}`] === true}
              stroke={addOpacityToHexColor(
                comparedPeriodColor,
                calculateOpacity(
                  data.results[
                    selectedSecondary || selectedPrimary
                  ].keys.indexOf(key),
                  data.results[selectedSecondary || selectedPrimary].keys
                    .length,
                ),
              )}
              strokeOpacity={
                !hover || hover === `previous.${key}`
                  ? FULL_BAR_LINE_OPACITY
                  : LIGHT_LINE_OPACITY
              }
              label={
                hover === `previous.${key}` && {
                  fill: comparedPeriodColor,
                  fontSize: LABEL_FONT_SIZE,
                  position: 'top',
                  formatter: abbreviateNumber,
                }
              }
              animationDuration={ANIMATION_DURATION}
            />
          ))}
      </ComposedChart>
    </ChartContainer>
  );
}

export default CombinedChart;
