import {
  Box,
  Text,
  Card,
  Title,
  Tag,
  Alert,
  BoxBaseProps,
} from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import tokens from '@nimbus-ds/tokens/dist/js/tokens';
import { Trans } from 'react-i18next';
import { DomainToUpdateDto } from '@tiendanube/common';
import { Logo } from 'App/components';
import HelpLink from 'App/HelpLink';
import {
  CancelAndConfirmButtons,
  ExternalLink,
  useResponsive,
} from 'commons/components';
import { DOMAIN_SOFTBLOCK_TUTORIAL_LINK } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useGetCountry, useGetMainUserId } from 'domains/Auth/hooks';
import { useGetUserQuery } from 'domains/Configurations/Users/usersApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import {
  AccordionDomainList,
  NotificationModal,
  StepAlertDomain,
} from '../components';
import { ActionType } from '../pages';
import { trackingGoToTutorialClick } from '../tracking';
import { isEmailDomainMatch } from '../utils';

export type SourceSoftblock = 'login' | 'domain';

interface DomainSoftBlockProps {
  data: DomainToUpdateDto;
  isErrorScreen: boolean;
  mode: ActionType;
  showNotificationModal: boolean;
  onConfirmAction: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  source: SourceSoftblock;
}

export function DomainSoftBlock({
  data,
  isErrorScreen,
  mode,
  showNotificationModal,
  onConfirmAction,
  onCancel,
  onConfirm,
  source,
}: Readonly<DomainSoftBlockProps>) {
  const t = useTranslationConfigurations('domains');
  const country = useGetCountry();
  const language = useTranslationLanguage();
  const { isMobile } = useResponsive();

  const mainUserId = useGetMainUserId();
  const { data: mainUser } = useGetUserQuery(mainUserId);

  const logo = country === 'BR' ? 'Nuvemshop' : 'Tiendanube';

  const emailIsAffected = data.domains.some(({ domain }) =>
    isEmailDomainMatch(domain, mainUser?.email),
  );

  const handleGoToTutorial = () => {
    trackingGoToTutorialClick(data.storeId, source);
  };

  const cardMobile: BoxBaseProps | false = isMobile && {
    marginLeft: '4',
    marginRight: '4',
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        {...cardMobile}
        flexDirection="column"
        gap="6"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap="4"
          width="100%"
        >
          <Logo
            src={logo}
            color={tokens.color.light.primary.interactive.value}
          />
          <Card padding="none">
            <Card.Header padding="base">
              <Box display="flex" flexDirection="column" gap="4">
                <Box
                  alignItems={isMobile ? 'flex-start' : 'center'}
                  display="flex"
                  justifyContent="space-between"
                  gap="2"
                  flexDirection={isMobile ? 'column' : 'row'}
                >
                  <Title as="h3">{t('softBlock.title')}</Title>
                  <Tag appearance="danger">
                    <Text color="danger-textLow">{t('softBlock.tag')}</Text>
                  </Tag>
                </Box>
                <Text color="neutral-textLow">
                  {t(
                    `softBlock.${
                      emailIsAffected ? 'subTitleEmail' : 'subTitle'
                    }`,
                  )}
                </Text>
                <Text color="neutral-textHigh">
                  <Trans
                    t={t}
                    i18nKey="softBlock.tutorial"
                    components={{
                      customLink: (
                        <ExternalLink
                          appearance="primary"
                          textDecoration="none"
                          href={DOMAIN_SOFTBLOCK_TUTORIAL_LINK[language]}
                          onClick={handleGoToTutorial}
                        >
                          {t('link')}
                        </ExternalLink>
                      ),
                    }}
                  />
                </Text>
                {isErrorScreen && (
                  <Alert
                    appearance="warning"
                    title={t('softBlock.error.title')}
                  >
                    {t('softBlock.error.subtitle')}
                  </Alert>
                )}
              </Box>
            </Card.Header>
            <Card.Body>
              <DataList>
                <DataList.Row>
                  <StepAlertDomain title={t('softBlock.stepAlert.one.title')} />
                </DataList.Row>
                <DataList.Row>
                  <StepAlertDomain
                    title={
                      <Trans
                        t={t}
                        i18nKey="softBlock.stepAlert.two.title"
                        components={{ bold: <strong /> }}
                      />
                    }
                  />
                </DataList.Row>
                <DataList.Row>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    gap="4"
                  >
                    <StepAlertDomain
                      title={t('softBlock.stepAlert.three.title')}
                    />
                    <AccordionDomainList domainToUpdate={data} />
                  </Box>
                </DataList.Row>
              </DataList>
            </Card.Body>
            <Card.Footer padding="base">
              <CancelAndConfirmButtons
                onCancel={onCancel}
                onConfirm={onConfirm}
                cancelText={t('softBlock.cancel')}
                confirmText={t('softBlock.notify')}
              />
            </Card.Footer>
          </Card>
        </Box>
        <HelpLink
          linkURL={DOMAIN_SOFTBLOCK_TUTORIAL_LINK[language]}
          title={t('softBlock.helpLink')}
          icon="both"
          showInMobile
          currentViewTracking=""
          previousValue=""
        />
      </Box>
      <NotificationModal
        type={mode}
        show={showNotificationModal}
        onConfirm={onConfirmAction}
      />
    </>
  );
}
