import { ChatDotsIcon } from '@nimbus-ds/icons';
import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { useHandleMenuClick } from 'App/hooks';
import { useGetTags } from 'domains/Auth/hooks';
import { infoMessageRoutes } from './infoMessageRoutes';

export function InfoMessageMenu() {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem } = useHandleMenuClick();
  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);

  return (
    <MenuButton
      as={Link}
      to={infoMessageRoutes.infoMessage}
      startIcon={ChatDotsIcon}
      onClick={handleClickItem}
      active={pathname.startsWith(infoMessageRoutes.infoMessage)}
      label={t(
        `settingsMenu.communication.${
          isRevampMenu ? 'revampInfoMessage' : 'infoMessage'
        }`,
      )}
    />
  );
}
