import { Text } from '@nimbus-ds/components';
import { PromotionsItemListResponseDto } from '@tiendanube/common';
import { PopoverToogle, Stack } from 'commons/components';

interface DiscountTypeProps {
  discountType: PromotionsItemListResponseDto['discountType'];
  discountTypeInfo: PromotionsItemListResponseDto['discountTypeInfo'];
  discountTypeInfoHeader: PromotionsItemListResponseDto['discountTypeInfoHeader'];
}

export function DiscountType({
  discountType,
  discountTypeInfo,
  discountTypeInfoHeader,
}: Readonly<DiscountTypeProps>) {
  const discountTypeComponent = (
    <Text lineClamp={1} fontWeight="regular">
      {discountType}
    </Text>
  );

  if (!discountTypeInfo) return discountTypeComponent;

  return (
    <PopoverToogle
      label={discountTypeComponent}
      content={
        <Stack column align="stretch" gap="1">
          {!!discountTypeInfoHeader && (
            <Text fontSize="caption" fontWeight="bold">
              {discountTypeInfoHeader}
            </Text>
          )}
          <Text fontSize="caption">
            <span
              style={{ whiteSpace: 'pre-line' }}
              dangerouslySetInnerHTML={{ __html: discountTypeInfo }}
            />
          </Text>
        </Stack>
      }
    />
  );
}
