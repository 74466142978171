import { CheckIcon, CloseIcon } from '@tiendanube/icons';
import Text from 'commons/components/LegacyText';

interface FeatureValueProps {
  value: string | number | boolean;
  bold?: boolean;
  size?: 'caption' | 'base';
}

function FeatureValue({
  value,
  bold,
  size = 'base',
}: FeatureValueProps): JSX.Element {
  return (
    <>
      {value === true && (
        <Text block textAlign="center" appearance="primary" size={size}>
          <CheckIcon />
        </Text>
      )}
      {value === false && (
        <Text block textAlign="center" appearance="light" size={size}>
          <CloseIcon />
        </Text>
      )}
      {value !== true && value !== false && (
        <Text block textAlign="center" bold={bold} size={size}>
          {value}
        </Text>
      )}
    </>
  );
}

export default FeatureValue;
