import { useCallback } from 'react';
import { Box } from '@nimbus-ds/components';
import { defaultSavedSearches } from 'domains/Orders/components/SavedSearches/defaultSavedSearches';
import {
  defaultFilters,
  FiltersParamsType,
  FiltersType,
} from 'domains/Orders/Orders/ordersService/constants';
import { trackingSearchBySavedSearches } from 'domains/Orders/tracking';
import { SavedSearch } from './SavedSearch/SavedSearch';
import { SavedSearchInterface } from './types';
import useSavedSearches from './useSavedSearches';
import useSelectedSavedSearch from './useSelectedSavedSearch';

interface SavedSearchesProperties {
  appliedFilter: FiltersParamsType;
  onSavedSearchClick: (newFilters: FiltersParamsType) => void;
  visible: boolean;
}

export function SavedSearches({
  appliedFilter,
  onSavedSearchClick,
  visible = true,
}: SavedSearchesProperties) {
  const savedSearches: SavedSearchInterface[] = defaultSavedSearches;

  const { selectedSavedSearch, setSelectedSavedSearch } =
    useSelectedSavedSearch(appliedFilter);

  const { savedSearchesCount } = useSavedSearches();

  const onClick = useCallback(
    (savedSearch) => {
      // Saved search is already selected, so it should be deselected
      if (selectedSavedSearch === savedSearch.code) {
        onSavedSearchClick(defaultFilters);
        setSelectedSavedSearch('');
        trackingSearchBySavedSearches(savedSearch.code, false);
      } else {
        const transformedFilters: FiltersType = {
          ...defaultFilters,
          ...savedSearch.filters,
        };
        onSavedSearchClick(transformedFilters);
        setSelectedSavedSearch(savedSearch.code);
        trackingSearchBySavedSearches(savedSearch.code, true);
      }
    },
    [onSavedSearchClick, selectedSavedSearch, setSelectedSavedSearch],
  );

  return (
    <Box
      flexDirection="row"
      display={visible ? 'flex' : 'none'}
      alignSelf="flex-start"
      borderRadius="2"
    >
      {savedSearches.map((savedSearch, index) => (
        <SavedSearch
          key={savedSearch.code}
          isSelected={savedSearch.code === selectedSavedSearch}
          savedSearch={savedSearch}
          count={savedSearchesCount?.[savedSearch.code]?.count}
          onSavedSearchClick={onClick}
          extraClassName={
            index === 0
              ? 'stratus--first-saved-search'
              : index === savedSearches.length - 1
              ? 'stratus--last-saved-search'
              : ''
          }
        />
      ))}
    </Box>
  );
}
