import { useState } from 'react';
import { Card, Table } from '@nimbus-ds/components';
import { BusinessHoursResponseDto, WeekdaysType } from '@tiendanube/common';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import BusinessHourItem from './BusinessHourItem';
import PickupBusinessHoursSkeleton from './Skeleton';
import {
  END_DEFAULT,
  formatBusinessHourErrors,
  sortBusinessHours,
  START_DEFAULT,
  WEEKDAYS_OPTIONS,
} from './utils';
import './PickupBusinessHours.scss';

interface PickupBusinessHoursProps {
  businessHours: BusinessHoursResponseDto[];
  errors: Partial<Record<string, string>>;
  setFieldValue: (name: string, value: any) => void;
}

function PickupBusinessHours({
  businessHours,
  errors,
  setFieldValue,
}: Readonly<PickupBusinessHoursProps>): JSX.Element {
  const t = useTranslationShipping(
    'deliveryMethods.pickupPoints.form.secondStep.businessHours',
  );

  const formattedErrors = formatBusinessHourErrors(errors);
  const [dayChanged, setDayChanged] = useState<string | null>(
    businessHours.length > 0 ? businessHours[0].day : 'monday',
  );

  const handleChangeHour = (newBusinessHour: BusinessHoursResponseDto) => {
    setDayChanged(newBusinessHour.day);
    const newBusinessHours = businessHours.filter(
      (businessHour) => businessHour.day !== newBusinessHour.day,
    );
    newBusinessHours.push(newBusinessHour);
    setFieldValue('businessHours', sortBusinessHours(newBusinessHours));
  };

  const handleChangeDay = (day: WeekdaysType, active: boolean) => {
    const newBusinessHours = active
      ? [
          ...businessHours,
          {
            day,
            start: START_DEFAULT,
            end: END_DEFAULT,
          },
        ]
      : businessHours.filter((businessHour) => businessHour.day !== day);

    if (newBusinessHours.length > 0) {
      setFieldValue('businessHours', sortBusinessHours(newBusinessHours));
    }
  };

  const handleApplyAll = (newBusinessHour: BusinessHoursResponseDto) => {
    const newBusinessHours = businessHours.map(
      (businessHour) =>
        ({
          day: businessHour.day,
          start: newBusinessHour.start,
          end: newBusinessHour.end,
        } as BusinessHoursResponseDto),
    );

    setFieldValue('businessHours', sortBusinessHours(newBusinessHours));
  };

  return (
    <Card padding="none">
      <Card.Header padding="base" title={t('title')} />
      <Card.Body>
        <div className="stratus--custom-table">
          <Table>
            <Table.Head>
              <Table.Cell backgroundColor="neutral-surface" padding="base">
                {t('day')}
              </Table.Cell>
              <Table.Cell backgroundColor="neutral-surface" padding="base">
                {t('start')}
              </Table.Cell>
              <Table.Cell backgroundColor="neutral-surface" padding="base">
                {t('end')}
              </Table.Cell>
              <Table.Cell backgroundColor="neutral-surface" padding="base">
                {t('action')}
              </Table.Cell>
            </Table.Head>
            <Table.Body>
              {WEEKDAYS_OPTIONS.map((weekday) => {
                const index = businessHours.findIndex(
                  (businessHour) => businessHour.day === weekday,
                );

                const businessHour = businessHours[index] ?? undefined;
                const errorStart =
                  index !== -1 ? formattedErrors[`${index}.start`] : undefined;
                const errorEnd =
                  index !== -1 ? formattedErrors[`${index}.end`] : undefined;

                return (
                  <BusinessHourItem
                    key={weekday}
                    day={weekday as WeekdaysType}
                    isDayChanged={dayChanged === weekday}
                    businessHour={businessHour}
                    errors={{ start: errorStart, end: errorEnd }}
                    changeHour={handleChangeHour}
                    handleApplyAll={handleApplyAll}
                    handleChangeDay={handleChangeDay}
                  />
                );
              })}
            </Table.Body>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
}

PickupBusinessHours.Skeleton = PickupBusinessHoursSkeleton;
export default PickupBusinessHours;
