import { Box, Button, Text, Title } from '@nimbus-ds/components';
import classNames from 'classnames';
import { useNavegate } from 'App/hooks';
import { ORDERS_SECTION_ROUTES } from 'domains/Orders/Orders/ordersSectionRoutes';
import { trackingOrderDraftOrderCreateClick } from 'domains/Orders/tracking';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import {
  SectionAppCard,
  SectionAppCardDomains,
} from 'domains/PartnersApps/components';

interface OrderListEmptyContentProps {
  isMobile?: boolean;
}

export function OrderListEmptyContent({
  isMobile = false,
}: Readonly<OrderListEmptyContentProps>) {
  const t = useTranslationOrders();
  const { goTo } = useNavegate();

  const onClickAddProduct = () => {
    trackingOrderDraftOrderCreateClick();
    goTo('/draft-orders/new');
  };

  const className = classNames('stratus--empty-orders-list', {
    'stratus--empty-orders-list-mobile': isMobile,
  });

  return (
    <div className={className}>
      <Text fontWeight="regular" fontSize="base">
        {t('onboarding.ordersTitle').toUpperCase()}
      </Text>
      <Title as="h1" fontWeight="bold">
        {t('onboarding.title')}
      </Title>
      <Text>{t('onboarding.body')}</Text>
      <Button appearance="primary" onClick={onClickAddProduct}>
        {t('onboarding.actionPrimary')}
      </Button>
      <Box marginTop="8">
        <SectionAppCard
          source={ORDERS_SECTION_ROUTES.orderList}
          appCategory="marketing"
          domain={SectionAppCardDomains.ORDERS}
        />
      </Box>
    </div>
  );
}
