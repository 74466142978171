import { Tooltip } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Label } from '@tiendanube/components';
import {
  CalendarIcon,
  DesktopIcon,
  FileAltIcon,
  MobileIcon,
  StoreIcon,
} from '@tiendanube/icons';
import { Stack } from 'commons/components';
import { dateFormat, Format } from 'commons/utils/date';
import './DateAndDevice.scss';

interface DateAndDeviceProps {
  date: string;
  origin?: string;
  orderOrigin?: string;
  initiatedBy?: string;
}

function DateAndDevice({
  date,
  origin,
  orderOrigin,
  initiatedBy,
}: DateAndDeviceProps): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);

  const getOriginIcon = () => {
    if (origin === 'mobile') return MobileIcon;
    if (origin === 'form') return FileAltIcon;
    if (origin === 'pos') return StoreIcon;

    return DesktopIcon;
  };

  const getOriginLabel = (origin: string) => {
    let label = t(`origin.${origin}`, origin);

    if (origin === 'pos' && initiatedBy) {
      label += ` ${t('origin.by')} ${initiatedBy}`;
    }

    if (['pos', 'form'].includes(origin) && !!orderOrigin) {
      return `${label}: ${orderOrigin}`;
    }

    return orderOrigin || label;
  };

  const originLabel = !!origin && (
    <Label
      appearance="transparent"
      id="origin"
      icon={getOriginIcon()}
      label={getOriginLabel(origin)}
    />
  );

  return (
    <div className="stratus--order-details__date-and-device">
      <Stack spacing="tight">
        <Stack.Item>
          <Label
            appearance="transparent"
            id="date"
            icon={CalendarIcon}
            label={dateFormat(date, Format.DAY_HOUR_MINUTE)}
          />
        </Stack.Item>
        {!!origin && (
          <div className="stratus--order-details__device-label">
            <Stack.Item>
              {origin === 'pos' ? (
                <Tooltip content={getOriginLabel(origin)}>
                  {originLabel}
                </Tooltip>
              ) : (
                originLabel
              )}
            </Stack.Item>
          </div>
        )}
      </Stack>
    </div>
  );
}

export default DateAndDevice;
