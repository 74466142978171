import { ChangeEvent } from 'react';
import { Radio, Toggle, Card, Box } from '@nimbus-ds/components';
import { Tooltip } from '@tiendanube/components';
import { InfoCircleIcon } from '@tiendanube/icons';
import { TextDisabledContainer } from 'commons/components';
import Blur from 'commons/components/Blur';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
import { ColorAndText } from 'domains/Statistics/components';
import TrendIndicator from '../TrendIndicator';

export type ItemModeType = 'toggle' | 'radio';
export interface SummaryItemCardProps<TKeys extends string> {
  isSelected: boolean;
  name: TKeys;
  title: string;
  value?: number;
  percentageDiff?: number;
  disabled?: boolean;
  showValues?: boolean;
  itemMode?: ItemModeType;
  textTooltip?: string;
  color: string;
  onChange: (e: string) => void;
  formatValue: (value: number, key: TKeys) => string;
  dataHasComparison?: boolean;
}

function SummaryItemCard<TKeys extends string>({
  onChange,
  isSelected,
  name,
  title,
  value = 0,
  percentageDiff = 0,
  disabled,
  showValues = true,
  itemMode = 'toggle',
  textTooltip,
  color,
  formatValue,
  dataHasComparison = false,
}: SummaryItemCardProps<TKeys>) {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!disabled) onChange?.(e.target.name);
  };

  const fromattedValue = formatValue?.(value, name);
  const displayValue = showValues
    ? fromattedValue
    : fromattedValue.length > 3
    ? fromattedValue?.replaceAll(/\d/g, 'x')
    : 'xxx';

  return (
    <Box display="flex" flexDirection="column" gap="2">
      <Card
        padding="small"
        backgroundColor={disabled ? 'neutral-surface' : undefined}
      >
        <Stack justify="space-between">
          <Stack spacing="extra-tight" align="center">
            <ColorAndText
              color={!dataHasComparison ? color : undefined}
              text={title}
              disabled={disabled}
            />

            {!!textTooltip && (
              <Tooltip labelIcon={InfoCircleIcon}>
                <Text>{textTooltip}</Text>
              </Tooltip>
            )}
          </Stack>
          {showValues && itemMode === 'toggle' && (
            <Toggle
              onChange={handleOnChange}
              name={name}
              checked={isSelected}
              disabled={disabled}
            />
          )}
          {showValues && itemMode === 'radio' && (
            <Radio
              onChange={handleOnChange}
              checked={isSelected}
              value={name}
              name={name}
              id={name}
            />
          )}
        </Stack>
        <TextDisabledContainer disabled={disabled}>
          <Stack column align="stretch" spacing="none">
            <Text size="highlight">
              <Blur radius={showValues ? '0' : '3px'}>{displayValue}</Blur>
            </Text>
            {!!percentageDiff && <TrendIndicator value={percentageDiff} />}
          </Stack>
        </TextDisabledContainer>
      </Card>
    </Box>
  );
}

export default SummaryItemCard;
