import * as Yup from 'yup';
import { COUNTRIES_WITH_RANGE } from '../../pages/utils';
import { CostType } from '../SecondStepForm/components/PickupPointCost/PickupPointCost';

export const validationSchemaSecondStep = Yup.object().shape({
  estimateDeliveryTime: Yup.object().shape({
    maxDays: Yup.string()
      .test('timeMaxNegativeValues', 'errors.negativeValues', (value) => {
        if (value && Number(value) < 0) {
          return false;
        }
        return true;
      })
      .test('timeMax', 'errors.termMax', (value, { parent: { minDays } }) => {
        if (minDays) {
          return Number(minDays) <= Number(value);
        }
        return true;
      }),
    minDays: Yup.string().test(
      'timeMinNegativeValues',
      'errors.negativeValues',
      (value) => {
        if (value && Number(value) < 0) {
          return false;
        }
        return true;
      },
    ),
  }),
  cost: Yup.object().shape({
    value: Yup.number()
      .nullable()
      .when('type', {
        is: CostType.FIXED,
        then: (schema) =>
          schema
            .required('errors.requiredValue')
            .moreThan(0, 'errors.valueMustBeGreaterThanZero'),
        otherwise: (schema) => schema.notRequired(),
      }),
  }),
  range: Yup.string()
    .when('country', {
      is: (country: string) => COUNTRIES_WITH_RANGE.has(country),
      then: (schema) => schema.required('errors.requiredValue'),
      otherwise: (schema) => schema.notRequired(),
    })
    .test('rangeTest', 'errors.requiredValue', function (value) {
      if (!COUNTRIES_WITH_RANGE.has(this.parent.country)) {
        return true;
      }
      if (value && parseFloat(value) >= 10 && parseFloat(value) <= 10000) {
        return true;
      }
      return false;
    }),
  businessHours: Yup.array()
    .of(
      Yup.object().shape({
        start: Yup.string()
          .required('errors.requiredStart')
          .matches(/^([01]?\d|2[0-3]):[0-5]\d$/, 'errors.invalidTimeFormat'),
        end: Yup.string()
          .required('errors.requiredEnd')
          .matches(/^([01]?\d|2[0-3]):[0-5]\d$/, 'errors.invalidTimeFormat'),
      }),
    )
    .min(1, 'errors.businessHoursRequired'),
});
