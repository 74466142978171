export const config = {
  pk: {
    AR: import.meta.env.VITE_MERCADO_PAGO_PUBLIC_KEY_AR || '',
    BR: import.meta.env.VITE_MERCADO_PAGO_PUBLIC_KEY_BR || '',
    CL: import.meta.env.VITE_MERCADO_PAGO_PUBLIC_KEY_CL || '',
    CO: import.meta.env.VITE_MERCADO_PAGO_PUBLIC_KEY_CO || '',
    MX: import.meta.env.VITE_MERCADO_PAGO_PUBLIC_KEY_MX || '',
    envioNube: {
      AR: import.meta.env.VITE_MERCADO_PAGO_PUBLIC_KEY_ENVIO_NUBE_AR || '',
    },
  },
};
