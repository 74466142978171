import { Box, Text, Radio } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

function TypesRadio() {
  const t = useTranslationCatalog('products.detail.invoice');

  return (
    <Stack.Item fill>
      <Box gap="2">
        <Text fontSize="caption">{t('titleType')}</Text>
        <Box display="flex" flexDirection="row" gap="2">
          <Radio
            as="radio"
            name="own-production"
            label={t('radioOwnProduction')}
          />
          <Radio as="radio" name="Revenda" label={t('radioResale')} />
        </Box>
      </Box>
    </Stack.Item>
  );
}

export default TypesRadio;
