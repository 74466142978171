import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { InterfaceMetafieldsSchema } from '../metafieldsSlice';

const getMetafieldsState = (
  state: RootStateType,
): CombinedState<InterfaceMetafieldsSchema> => state.metafields;

export const getOrders = createSelector(
  getMetafieldsState,
  (metafieldState) => metafieldState.orders,
);

export const getMetafieldsOrdersStatus = createSelector(getOrders, (orders) =>
  convertStatusTypeToObject(orders.status),
);

export const getMetafieldsOrdersData = createSelector(
  getOrders,
  (orders) => orders.data,
);

export const getVariants = createSelector(
  getMetafieldsState,
  (metafieldState) => metafieldState.variants,
);

export const getMetafieldsVariantsStatus = createSelector(
  getVariants,
  (variants) => convertStatusTypeToObject(variants.status),
);

export const getMetafieldsVariantsData = createSelector(
  getVariants,
  (variants) => variants.data,
);

export const getCustomer = createSimpleSelector(
  [getMetafieldsState],
  (metafieldState) => metafieldState.customer,
);

export const getMetafieldsCustomerStatus = createSimpleSelector(
  [getCustomer],
  (customer) => convertStatusTypeToObject(customer.status),
);

export const getMetafieldsCustomerData = createSimpleSelector(
  [getCustomer],
  (customer) => customer.data,
);

export const getWholesaleCustomer = createSimpleSelector(
  [getMetafieldsState],
  (metafieldState) => metafieldState.wholesaleCustomer,
);

export const getWholesaleCustomerMetafieldsStatus = createSimpleSelector(
  [getWholesaleCustomer],
  (wholesaleCustomer) => convertStatusTypeToObject(wholesaleCustomer.status),
);

export const getWholesaleCustomerMetafieldsData = createSimpleSelector(
  [getWholesaleCustomer],
  (wholesaleCustomer) => wholesaleCustomer.data,
);

export const getCategories = createSimpleSelector(
  [getMetafieldsState],
  (metafieldState) => metafieldState.categories,
);

export const getMetafieldsCategoriesStatus = createSimpleSelector(
  [getCategories],
  (categories) => convertStatusTypeToObject(categories.status),
);

export const getMetafieldsCategoriesData = createSimpleSelector(
  [getCategories],
  (categories) => categories.data,
);

export const getProductsAndVariants = createSimpleSelector(
  [getMetafieldsState],
  (metafieldState) => metafieldState.productsAndVariants,
);

export const getMetafieldsProductsAndVariantsStatus = createSimpleSelector(
  [getProductsAndVariants],
  (productsAndVariants) =>
    convertStatusTypeToObject(productsAndVariants.status),
);

export const getMetafieldsProductsAndVariantsData = createSimpleSelector(
  [getProductsAndVariants],
  (productsAndVariants) => productsAndVariants.data,
);
