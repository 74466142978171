import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomersDetailsActionsResponseEnum } from '@tiendanube/common';
import { Domain } from '@tiendanube/common/src/enums';
import { EditIcon, MailIcon, DownloadIcon, TrashIcon } from '@tiendanube/icons';
import { useToastProgress } from 'App/components/ToastProgressContext/ToastProgressContext';
import { useNavegate } from 'App/hooks';
import { useAppDispatch } from 'App/store';
import { ActionProp } from 'commons/components';
import { useAsyncFunc, useToast } from 'commons/hooks';
import {
  sendActivationMail as sendActivationMailAction,
  sendResetPasswordMail as sendResetPasswordMailAction,
  exportCustomerData as exportCustomerDataAction,
  deleteCustomer as deleteCustomerAction,
} from 'domains/Customers/Customers/customersSlice';
import { trackingCustomerEditButtonClick } from 'domains/Customers/tracking';

interface UseCustomerActionsResult {
  exportMailSent: boolean;
  getActions: () => ActionProp[];
  sendActivationMail: () => Promise<void>;
  sendResetPasswordMail: () => Promise<void>;
  exportCustomerData: () => Promise<void>;
  deleteCustomer: () => Promise<void>;
}

interface UseCustomerActionsProps {
  id: string;
  actions: CustomersDetailsActionsResponseEnum[];
  isEditable: boolean;
  openDeleteCustomerModal: () => void;
}

function useCustomerActions({
  id,
  actions,
  isEditable,
  openDeleteCustomerModal,
}: UseCustomerActionsProps): UseCustomerActionsResult {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(Domain.CUSTOMERS);
  const { goTo } = useNavegate();
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();

  const [activationMailSent, setActivationMailSent] = useState(false);
  const [resetPasswordMailSent, setResetPasswordMailSent] = useState(false);
  const [exportMailSent, setExportMailSent] = useState(false);

  const getActions = () => {
    const editCustomerAction = {
      children: `${t('customerDetails.secondaryActions.edit')}`,
      icon: EditIcon,
      disabled: false,
      spinner: false,
      appearance: 'primary',
      onClick: () => {
        trackingCustomerEditButtonClick(id);
        goTo(`/customers/edit/${id}`);
      },
    };
    const sendActivationMailAction = {
      children: `${t(
        activationMailSent
          ? 'customerDetails.secondaryActions.resendActivationMail'
          : 'customerDetails.secondaryActions.sendActivationMail',
      )}`,
      icon: MailIcon,
      disabled: sendActivationMailLoading,
      spinner: sendActivationMailLoading,
      onClick: sendActivationMail,
    };
    const sendResetPasswordMailAction = {
      children: `${t(
        resetPasswordMailSent
          ? 'customerDetails.secondaryActions.resendResetPasswordMail'
          : 'customerDetails.secondaryActions.sendResetPasswordMail',
      )}`,
      icon: MailIcon,
      disabled: sendResetPasswordMailLoading,
      spinner: sendResetPasswordMailLoading,
      onClick: sendResetPasswordMail,
    };
    const exportCustomerDataAction = {
      children: `${t('customerDetails.secondaryActions.exportCustomerData')}`,
      icon: DownloadIcon,
      disabled: false,
      spinner: false,
      onClick: exportCustomerData,
    };
    const deleteCustomerAction = {
      children: `${t('customerDetails.secondaryActions.delete')}`,
      icon: TrashIcon,
      disabled: false,
      spinner: false,
      onClick: openDeleteCustomerModal,
    };

    return [
      ...(actions.includes(
        'SEND_EMAIL_ACTIVATE' as CustomersDetailsActionsResponseEnum,
      )
        ? [sendActivationMailAction]
        : []),
      ...(actions.includes(
        'SEND_EMAIL_PASSWORD' as CustomersDetailsActionsResponseEnum,
      )
        ? [sendResetPasswordMailAction]
        : []),
      ...[exportCustomerDataAction],
      ...(actions.includes(
        'DELETE_CLIENT' as CustomersDetailsActionsResponseEnum,
      )
        ? [deleteCustomerAction]
        : []),
      ...(isEditable ? [editCustomerAction] : []),
    ];
  };

  const [
    sendActivationMail,
    sendActivationMailLoading,
    sendActivationMailError,
    clearSendActivationMailError,
  ] = useAsyncFunc(async () => {
    await dispatch(sendActivationMailAction(id));
    setActivationMailSent(true);
    addToast({
      label: t('customerDetails.secondaryActions.success'),
      appearance: 'success',
    });
  });

  const [
    sendResetPasswordMail,
    sendResetPasswordMailLoading,
    sendResetPasswordMailError,
    clearSendResetPasswordMailError,
  ] = useAsyncFunc(async () => {
    await dispatch(sendResetPasswordMailAction(id));
    setResetPasswordMailSent(true);
    addToast({
      label: t('customerDetails.secondaryActions.success'),
      appearance: 'success',
    });
  });

  const [
    exportCustomerData,
    ,
    exportCustomerDataError,
    clearExportCustomerDataError,
  ] = useAsyncFunc(async () => {
    await dispatch(exportCustomerDataAction(id));
    setExportMailSent(true);
  });

  const [deleteCustomer, , deleteCustomerError, clearDeleteCustomerError] =
    useAsyncFunc(async () => {
      addToastProgress({
        label: t('customerDetails.deleteCustomer.progress'),
      });
      await dispatch(deleteCustomerAction(id));
      closeToastProgress();
      addToast({
        label: t('customerDetails.deleteCustomer.success'),
        appearance: 'success',
      });
    });

  useEffect(() => {
    if (
      sendActivationMailError ||
      sendResetPasswordMailError ||
      exportCustomerDataError
    ) {
      addToast({
        label: t('customerDetails.secondaryActions.error'),
        appearance: 'danger',
      });
      clearSendActivationMailError();
      clearSendResetPasswordMailError();
      clearExportCustomerDataError();
    }
  }, [
    sendActivationMailError,
    sendResetPasswordMailError,
    exportCustomerDataError,
    clearSendActivationMailError,
    clearSendResetPasswordMailError,
    clearExportCustomerDataError,
    addToast,
    t,
  ]);

  useEffect(() => {
    if (deleteCustomerError) {
      addToast({
        label: t('customerDetails.deleteCustomer.error'),
        appearance: 'danger',
      });
      clearDeleteCustomerError();
    }
  }, [deleteCustomerError, clearDeleteCustomerError, addToast, t]);

  return {
    exportMailSent,
    getActions,
    sendActivationMail,
    sendResetPasswordMail,
    exportCustomerData,
    deleteCustomer,
  };
}

export default useCustomerActions;
