import { useEffect, useMemo, useState } from 'react';
import {
  defaultFilters,
  FiltersParamsType,
} from 'domains/Orders/Orders/ordersService';
import { areFiltersEquals } from 'domains/Orders/Orders/utils/OrderListFilter/utils';
import { defaultSavedSearches } from './defaultSavedSearches';

function useSelectedSavedSearch(filters: FiltersParamsType) {
  const [selectedSavedSearch, setSelectedSavedSearch] = useState<string>('');

  const filtersMatchWithSavedSearch = useMemo(
    () =>
      defaultSavedSearches.filter((ss) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const onlyFilters = (({ perPage, ...newFilters }) => newFilters)(
          filters,
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const onlyDefaultFilters = (({ perPage, ...newFilters }) => newFilters)(
          defaultFilters,
        );
        const transformedFilters = {
          ...onlyDefaultFilters,
          ...ss.filters,
        };
        return areFiltersEquals(onlyFilters, transformedFilters);
      }),
    [filters],
  );

  useEffect(() => {
    setSelectedSavedSearch(
      filtersMatchWithSavedSearch.length > 0
        ? filtersMatchWithSavedSearch[0].code
        : '',
    );
  }, [filtersMatchWithSavedSearch]);

  return {
    selectedSavedSearch,
    setSelectedSavedSearch,
  };
}

export default useSelectedSavedSearch;
