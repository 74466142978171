import { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@tiendanube/icons';
import { CurrencyPrice, ImageItemStratus } from 'commons/components';
import Link from 'commons/components/LegacyLink';
import Stack from 'commons/components/Stack';
import ThousandSeparatorText from 'domains/Statistics/components/ThousandSeparatorText';
import { useTranslationStatistics } from 'domains/Statistics/hooks';
import ProductsInNumberVariant from './StatsProductsItemVariant';
import { IMAGE_WIDTH } from '../../utils';

interface StatsProductsItemVariant {
  variantDescription: string;
  variantId: string;
  soldQty: number;
  imageUrl?: string;
}

export interface StatsProductsItemInterface {
  productDescription: string;
  productId: string;
  ranking: number;
  soldQty: number;
  imageUrl?: string;
  variants: StatsProductsItemVariant[];
}

interface StatsProductsItemProps {
  product: StatsProductsItemInterface;
  totalType?: 'number' | 'currency';
}

function StatsProductsItem({ product, totalType }: StatsProductsItemProps) {
  const t = useTranslationStatistics();
  const [open, setOpen] = useState(false);
  const handleOnClick = () => {
    setOpen(!open);
  };
  const productImageUrl = product?.imageUrl || '';

  return (
    <Stack column align="stretch" spacing="tight">
      <Stack justify="space-between" spacing="tight" align="stretch">
        <ImageItemStratus
          to={`/products/${product.productId}`}
          id={product.productId}
          title={product.productDescription}
          imageUrl={productImageUrl}
          trimTitle
          trimLines={2}
          imageWidth={IMAGE_WIDTH}
        >
          {product.variants.length > 1 && (
            <Link
              onClick={handleOnClick}
              iconPosition="end"
              icon={open ? ChevronUpIcon : ChevronDownIcon}
            >
              {t('statistics.statsProductsCard.variants', {
                count: product.variants.length,
              })}
            </Link>
          )}
        </ImageItemStratus>
        <Stack column justify="center">
          {totalType === 'currency' ? (
            <CurrencyPrice price={product.soldQty} />
          ) : (
            <ThousandSeparatorText value={product.soldQty} />
          )}
        </Stack>
      </Stack>
      {open &&
        product.variants.map((variant) => (
          <ProductsInNumberVariant
            key={variant.variantId}
            variant={variant}
            totalType={totalType}
            defaultImage={product.imageUrl}
          />
        ))}
    </Stack>
  );
}

export default StatsProductsItem;
