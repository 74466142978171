import {
  PromotionRequestDto,
  PromotionResponseDto,
  PromotionsItemListResponseDto,
  PromotionsTypesResponseDto,
  ResultPaginationResponseDto,
} from '@tiendanube/common';
import { MOBILE_BREAKPOINT } from 'App/constants/breakpoints';
import { cirrusApi, infinityBehavior, tableBehavior } from 'App/rtk';
import { GenericParamsType } from 'commons/types';
import { addMounthsToday, getTodayIsoString } from 'commons/utils/date';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import {
  BASE_PROMOTIONS_URL,
  PROMOTION_HAS_ANY_URL,
  PROMOTION_ID_URL,
  PROMOTION_STATUS_URL,
  PROMOTION_TYPES_URL,
  PromotionsEndpoints,
} from './settings';

const endpointList = () =>
  window.innerWidth > MOBILE_BREAKPOINT
    ? PromotionsEndpoints.promotionsList
    : PromotionsEndpoints.promotionsListInf;

export const promotionsApi = cirrusApi.injectEndpoints({
  endpoints: (builder) => ({
    [PromotionsEndpoints.promotionsList]: builder.query<
      ResultPaginationResponseDto<PromotionsItemListResponseDto[]>,
      GenericParamsType
    >({
      query: (params) => ({
        method: 'GET',
        url: BASE_PROMOTIONS_URL,
        params,
      }),
      ...tableBehavior(PromotionsEndpoints.promotionsList),
    }),
    [PromotionsEndpoints.promotionsListInf]: builder.query<
      ResultPaginationResponseDto<PromotionsItemListResponseDto[]>,
      GenericParamsType
    >({
      query: (params) => ({
        method: 'GET',
        url: BASE_PROMOTIONS_URL,
        params,
      }),
      ...infinityBehavior(PromotionsEndpoints.promotionsListInf),
    }),
    [PromotionsEndpoints.hasAny]: builder.query<boolean, GenericParamsType>({
      query: (params) => ({
        method: 'GET',
        url: PROMOTION_HAS_ANY_URL,
        params,
      }),
    }),
    [PromotionsEndpoints.setPromotionStatus]: builder.mutation<
      void,
      { id: string; status: boolean }
    >({
      query: ({ id, status }) => ({
        method: 'PATCH',
        url: getUrlWithParams(PROMOTION_STATUS_URL, {
          id,
          status: status ? 'enable' : 'disable',
        }),
      }),
      onQueryStarted({ id, status }, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            cirrusApi.util.updateQueryData(
              endpointList() as never,
              undefined as never,
              (
                draft: ResultPaginationResponseDto<
                  PromotionsItemListResponseDto[]
                >,
              ) => {
                const found = draft.results.find(
                  (current) => current.id === id,
                );
                if (found) found.status = status ? 'active' : 'inactive';
              },
            ),
          );
        });
      },
    }),
    [PromotionsEndpoints.deletePromotion]: builder.mutation<void, string>({
      query: (id) => ({
        method: 'DELETE',
        url: getUrlWithParams(PROMOTION_ID_URL, { id }),
      }),
      onQueryStarted(id, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(
            cirrusApi.util.updateQueryData(
              endpointList() as never,
              undefined as never,
              (
                draft: ResultPaginationResponseDto<
                  PromotionsItemListResponseDto[]
                >,
              ) => {
                draft.results = draft.results.filter(
                  ({ id: currentId }) => currentId !== id,
                );
              },
            ),
          );
        });
      },
    }),
    [PromotionsEndpoints.promotionTypes]: builder.query<
      PromotionsTypesResponseDto[],
      void
    >({
      query: () => ({
        method: 'GET',
        url: PROMOTION_TYPES_URL,
      }),
    }),
    [PromotionsEndpoints.createPromotion]: builder.mutation<
      void,
      PromotionRequestDto
    >({
      query: (payload) => ({
        method: 'POST',
        url: BASE_PROMOTIONS_URL,
        data: payload,
      }),
    }),
    [PromotionsEndpoints.getPromotion]: builder.query<
      PromotionResponseDto,
      string
    >({
      query: (id) => ({
        method: 'GET',
        url: getUrlWithParams(PROMOTION_ID_URL, { id }),
      }),
      transformResponse: ({
        startDate,
        endDate,
        ...rest
      }: PromotionResponseDto) => ({
        startDate: startDate || getTodayIsoString(),
        endDate: endDate || addMounthsToday(1),
        ...rest,
      }),
    }),
    [PromotionsEndpoints.updatePromotion]: builder.mutation<
      void,
      { id: string; payload: PromotionRequestDto }
    >({
      query: ({ id, payload }) => ({
        method: 'PATCH',
        url: getUrlWithParams(PROMOTION_ID_URL, { id }),
        data: payload,
      }),
    }),
  }),
});

export const {
  useSetPromotionStatusMutation,
  useDeletePromotionMutation,
  usePromotionTypesQuery,
  useCreatePromotionMutation,
  useGetPromotionQuery,
  useUpdatePromotionMutation,
  useHasAnyQuery,
} = promotionsApi;
