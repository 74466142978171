import { Alert } from '@nimbus-ds/components';
import { PaymentBillingEngineDto } from '@tiendanube/common';
import { useDismissibleComponent } from 'commons/hooks';
import { dateFormat, Format } from 'commons/utils/date';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface PaidPaymentStatusAlertProps {
  payment: PaymentBillingEngineDto | undefined;
}

export default function PaidPaymentStatusAlert({
  payment,
}: PaidPaymentStatusAlertProps) {
  const formatCurrency = useFormatCurrency();
  const t = useTranslationBilling('checkout.paymentStatusAlert.success');
  const { dismiss, shouldShow } = useDismissibleComponent(
    `${PaidPaymentStatusAlert.name}Success-${payment?.id ?? ''}`,
  );
  if (!payment?.paidAt) return null;
  return (
    <Alert
      appearance="success"
      title={t('title')}
      show={shouldShow}
      onRemove={dismiss}
    >
      {t('message', {
        amount: formatCurrency(payment.amountValue, payment.amountCurrency),
        period: dateFormat(payment.paidAt, Format.DD_MM_YYYY),
      })}
    </Alert>
  );
}
