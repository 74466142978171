import { Box, Button, Icon, Text } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { Trans } from 'react-i18next';
import { useResponsive } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { MARKETING_ROUTES } from 'domains/Marketing/marketingRoutes';
import {
  SectionAppCardDomains,
  SectionAppCard,
} from 'domains/PartnersApps/components';
import emptyPromotions from './empty-promotions.png';

interface PromotionsListEmptyProps {
  onCreate: () => void;
}

export function PromotionsListEmpty({
  onCreate,
}: Readonly<PromotionsListEmptyProps>) {
  const t = useTranslationMarketing('promotions.empty');

  const { isMobile } = useResponsive();

  const imgWidth = isMobile ? 313 : 492;

  return (
    <EmptyMessage
      title={t('title')}
      actions={
        <Box display="flex" flex="1" flexDirection="column" width="100%">
          <Text>
            <Trans
              t={t}
              i18nKey="text"
              components={{
                br: <br />,
              }}
            />
          </Text>
          <Box paddingTop="3" display="flex" gap="4" flexWrap="wrap">
            <Button appearance="primary" onClick={onCreate}>
              <Icon color="currentColor" source={<PlusCircleIcon />} />
              {t('action')}
            </Button>
          </Box>
          <Box paddingTop="8">
            <SectionAppCard
              source={MARKETING_ROUTES.promotionsList}
              appCategory="marketing"
              domain={SectionAppCardDomains.PROMOTIONS}
            />
          </Box>
        </Box>
      }
      illustration={<img width={imgWidth} src={emptyPromotions} />}
    />
  );
}
