import { InvoicesResponseDto, PaginationResponseDto } from '@tiendanube/common';
import { DataTable } from '@tiendanube/components';
import { DataTableFooter } from 'commons/components';
import { StatusType } from 'commons/types';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { DataTableHeader, DataTableRow } from './components';
import InvoiceHistoryDataTableSkeleton from './InvoiceHistoryDataTableSkeleton';

interface InvoiceHistoryDataTableProps {
  data: InvoicesResponseDto[];
  pagination: PaginationResponseDto;
  onPageSelect: (page: number) => void;
  status: StatusType;
}

function InvoiceHistoryDataTable({
  data,
  pagination,
  onPageSelect,
  status,
}: InvoiceHistoryDataTableProps): JSX.Element {
  const t = useTranslationBilling();
  return (
    <>
      <DataTable>
        {status === 'loading' ? (
          <InvoiceHistoryDataTableSkeleton />
        ) : (
          <>
            <DataTableHeader />
            {data.map((row, index) => (
              <DataTableRow {...row} key={index} />
            ))}
          </>
        )}
      </DataTable>

      <DataTableFooter
        currentPage={pagination.currentPage}
        perPage={pagination.perPage}
        itemName={t('invoiceHistory.invoice')}
        totalItems={pagination.totalResults}
        onPageSelect={onPageSelect}
      />
    </>
  );
}

InvoiceHistoryDataTable.Skeleton = InvoiceHistoryDataTableSkeleton;

export default InvoiceHistoryDataTable;
