import { SVGProps } from 'react';
import { Text } from '@nimbus-ds/components';
import Stack from 'commons/components/Stack';
import { ProvincesRankingPoint } from '../../RankByProvinceChart';
import RankByProvinceValueAndPercentage from '../RankByProvinceValueAndPercentage';

interface RankByProvinceYAxisLeftTickProps
  extends SVGProps<SVGForeignObjectElement> {
  index?: number;
  width?: number;
  data: ProvincesRankingPoint[];
  showAllwaysPercentage?: boolean;
}

// this constant is used to adjust the alignment of the text with the mark
const Y_POSITION = 18;

function RankByProvinceYAxisLeftTick({
  index = 0,
  data,
  x,
  y,
  width = 0,
  height = 0,
  showAllwaysPercentage = false,
}: RankByProvinceYAxisLeftTickProps) {
  return (
    <foreignObject
      overflow="visible"
      y={Number(y) - Y_POSITION}
      x={Number(x) - width}
      width={width}
      height={height}
    >
      <Stack column align="flex-end" spacing="none">
        <Text textAlign="right" fontSize="caption" color="primary-textLow">
          {data[index].name}
        </Text>
        {showAllwaysPercentage && (
          // we omit the percentage (data[index].percentageDiff) since the calculation returned by the BFF
          // is not calculated based on the total value but on the entire top 5
          <RankByProvinceValueAndPercentage value={data[index].value} />
        )}
      </Stack>
    </foreignObject>
  );
}

export default RankByProvinceYAxisLeftTick;
