import { Card, Text, Title } from '@nimbus-ds/components';
import { OrderDetailsResponseDto } from '@tiendanube/common';
import { CurrencyPrice, Stack } from 'commons/components';
import useTranslationOrders from '../../../../../useTranslationOrders';
import { RefundAttributes } from '../../../hooks/useRefundOrder/useRefundOrder';

interface Attributes {
  orderDetails: OrderDetailsResponseDto;
  values: RefundAttributes;
}

function RefundResumeCard({ orderDetails, values }: Attributes): JSX.Element {
  const t = useTranslationOrders();
  const total = Number(orderDetails.payment.capturedAmount.value);

  return (
    <Card>
      <Card.Header>
        <Title as="h3">{t('refund.confirmRefundDetails.resume.title')}</Title>
      </Card.Header>
      <Card.Body>
        <Stack column align="stretch">
          <Stack justify="space-between" align="stretch">
            <Stack alignSelf="flex-start">
              <Text>
                {t(
                  'refund.confirmRefundDetails.resume.paidByCustomerAmountLabel',
                )}
              </Text>
            </Stack>
            <Stack alignSelf="flex-end">
              <CurrencyPrice price={total} currency={orderDetails.currency} />
            </Stack>
          </Stack>

          <Stack justify="space-between" align="stretch">
            <Stack alignSelf="flex-start">
              <Text>
                {t('refund.confirmRefundDetails.resume.refundedAmountLabel')}
              </Text>
            </Stack>
            <Stack alignSelf="flex-end">
              <CurrencyPrice
                appearance="danger"
                price={-Number(values.value)}
                currency={orderDetails.currency}
              />
            </Stack>
          </Stack>

          <Stack justify="space-between" align="stretch">
            <Stack alignSelf="flex-start">
              <Text fontWeight="bold">
                {t('refund.confirmRefundDetails.resume.total')}
              </Text>
            </Stack>
            <Stack alignSelf="flex-end">
              <CurrencyPrice
                price={total - Number(values.value)}
                currency={orderDetails.currency}
                bold
              />
            </Stack>
          </Stack>
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default RefundResumeCard;
