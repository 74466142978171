import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTrackingAnalytics } from 'App/settings/trackingAnalytics';
import { useAppDispatch } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { refreshInfoToSync } from '../../authSlice';
import { getAuthSyncStatus } from '../../authSlice/authSelectors';

function useRefreshInfoStoreUser() {
  const dispatch = useAppDispatch();
  const { executeTrackingRedirect } = useTrackingAnalytics();

  const status = useSelector(getAuthSyncStatus);

  const refreshInfo = useCallback(
    async (noCache = false) => {
      await dispatch(refreshInfoToSync(noCache));
      executeTrackingRedirect();
    },
    [dispatch, executeTrackingRedirect],
  );

  return { refreshInfo, status, ...convertStatusTypeToObject(status) };
}

export default useRefreshInfoStoreUser;
