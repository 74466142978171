import { Input, InterfaceNameValue } from '@tiendanube/components';
import useScrollToError from 'App/components/AppLayout/useScrollToError';
import { CardWithTitle } from 'commons/components';
import Stack from 'commons/components/Stack';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import Skeleton from './Skeleton';

export interface SenderValuesInterface {
  name: string;
  surname: string;
  companyName: string;
  cuit: string;
  zipcode: string;
}

interface SenderCardProps {
  values: SenderValuesInterface;
  errors: Partial<Record<string, string>>;
  onChange: (group: string, data: InterfaceNameValue) => void;
}

function SenderCard({
  values,
  errors,
  onChange,
}: SenderCardProps): JSX.Element {
  const t = useTranslationShipping();

  useScrollToError(errors);

  const handleChange = (event: InterfaceNameValue) => {
    onChange('sender', event);
  };

  const error = {
    name: errors['sender.name'] || '',
    surname: errors['sender.surname'] || '',
    companyName: errors['sender.companyName'] || '',
    cuit: errors['sender.cuit'] || '',
    zipcode: errors['sender.zipcode'] || '',
  };

  const helpTextCuit = error.cuit
    ? t(error.cuit)
    : t('oca.config.sender.cuitSubline');

  return (
    <CardWithTitle title={t('oca.config.sender.title')}>
      <Stack column align="stretch">
        <Input
          name="name"
          label={t('oca.config.sender.name')}
          value={values.name}
          appearance={error.name ? 'validation_error' : 'default'}
          helpText={t(error.name)}
          onChange={handleChange}
        />

        <Input
          name="surname"
          label={t('oca.config.sender.surname')}
          value={values.surname}
          appearance={error.surname ? 'validation_error' : 'default'}
          helpText={t(error.surname)}
          onChange={handleChange}
        />

        <Input
          name="companyName"
          label={t('oca.config.sender.companyName')}
          value={values.companyName}
          appearance={error.companyName ? 'validation_error' : 'default'}
          helpText={t(error.companyName)}
          onChange={handleChange}
        />

        <Input
          name="cuit"
          label={t('oca.config.sender.cuit')}
          value={values.cuit}
          appearance={error.cuit ? 'validation_error' : 'default'}
          maxLength={13}
          helpText={helpTextCuit}
          onChange={handleChange}
          placeholder="xx-xxxxxxxx-x"
        />

        <Input
          name="zipcode"
          label={t('oca.config.sender.zipcode')}
          value={`${values.zipcode}`}
          appearance={error.zipcode ? 'validation_error' : 'default'}
          maxLength={4}
          helpText={t(error.zipcode)}
          onChange={handleChange}
        />
      </Stack>
    </CardWithTitle>
  );
}

SenderCard.Skeleton = Skeleton;

export default SenderCard;
