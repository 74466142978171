import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { TagIcon } from '@tiendanube/icons';
import PriceTablesMenu from 'App/components/Menu/components/MainMenu/components/ProductsMenu/components/PriceTablesMenu';
import ShowByRole from 'App/components/ShowByRole';
import {
  MIGRATION_ENABLE_ALL_MIGRATED_PAGES,
  NEW_ADMIN_B2B_ACCOUNT_WHOLESALE,
} from 'App/featuresFlags';
import { useHandleMenuClick } from 'App/hooks';
import { trackingMenuPrimaryProductsClick } from 'App/tracking';
import objectToUrlParams from 'commons/utils/objectToUrlParams';
import { useGetTags, useHasCatalogInventory } from 'domains/Auth/hooks';
import { useInventory } from 'domains/Catalog/Inventory/pages/hooks';
import { useProductsList } from 'domains/Catalog/Products/pages/hooks';

const PATH = {
  products: '/products',
  productsOldAdmin: '/admin/products',
  inventory: '/inventory',
  categories: '/categories',
  priceTables: '/price-tables',
};

interface ProductsMenuProps {
  isMobile: boolean;
}

function ProductsMenu({ isMobile }: Readonly<ProductsMenuProps>) {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem, handleClickAccordion } = useHandleMenuClick();
  const { appliedFilters: appliedProductFilters } = useProductsList();
  const { filters: appliedInventoryFilters } = useInventory();
  const hasCatalogInventory = useHasCatalogInventory();
  const tags = useGetTags();

  const hasNewAdminProducts = tags.includes(
    MIGRATION_ENABLE_ALL_MIGRATED_PAGES,
  );

  const hasAccountWholesaleTag = tags.includes(NEW_ADMIN_B2B_ACCOUNT_WHOLESALE);

  const activeProducts = Object.values(PATH).some((each) =>
    pathname.startsWith(each),
  );

  const menuProducts = [
    {
      children: `${t('menu.manage.products.productsList')}`,
      onClick: handleClickItem,
      active: pathname.startsWith(PATH.products),
      href: `${PATH.products}?${objectToUrlParams(appliedProductFilters)}`,
    },
    ...(hasCatalogInventory
      ? [
          {
            children: `${t('menu.manage.products.inventory')}`,
            onClick: handleClickItem,
            active: pathname.startsWith(PATH.inventory),
            label: {
              id: 'inventory',
              label: `${t('menu.new')}`,
              appearance: 'primary',
            },
            href: `${PATH.inventory}?${objectToUrlParams(
              appliedInventoryFilters,
            )}`,
          },
        ]
      : []),
    {
      children: `${t('menu.manage.products.categories')}`,
      onClick: handleClickItem,
      active: pathname.startsWith(PATH.categories),
      label: isMobile && {
        id: 'categories',
        label: `${t('menu.new')}`,
        appearance: 'primary',
      },
      href: PATH.categories,
    },
    ...(hasAccountWholesaleTag
      ? [
          {
            id: 'priceTables',
            children: `${t('menu.manage.products.priceTables.text')}`,
            onClick: handleClickItem,
            active: pathname.startsWith(PATH.priceTables),
            href: PATH.priceTables,
            tag: {
              label: `${t('menu.manage.products.priceTables.tagText')}`,
            },
            productUpdates: {
              title: `${t('menu.manage.products.priceTables.tooltipTitle')}`,
              description: `${t(
                'menu.manage.products.priceTables.tooltipText',
              )}`,
            },
          },
        ]
      : []),
  ];

  const itemsProducts = [...menuProducts];

  const handleMenuProductsClick = () => {
    trackingMenuPrimaryProductsClick();
    handleClickAccordion();
  };

  const productsOldAdmin =
    !isMobile && !hasNewAdminProducts ? PATH.productsOldAdmin : '';

  return (
    <ShowByRole includeRoles="products">
      <MenuButton.Accordion
        contentid="content-products"
        menuButton={{
          id: 'control-products',
          label: t('menu.manage.products.title'),
          startIcon: TagIcon,
          'aria-controls': 'content-products',
          onClick: !productsOldAdmin ? handleMenuProductsClick : undefined,
        }}
        active={activeProducts}
        open={activeProducts}
        as={productsOldAdmin ? 'a' : Link}
        to={PATH.products}
        href={productsOldAdmin || undefined}
      >
        {itemsProducts.map((item, index) => {
          if (item.id === 'priceTables') {
            return <PriceTablesMenu key={`${item.id}-${index}`} item={item} />;
          }

          return (
            <MenuButton
              key={index}
              as={Link}
              label={item.children}
              onClick={item.onClick}
              to={item.href ?? ''}
              active={item.active}
            />
          );
        })}
      </MenuButton.Accordion>
    </ShowByRole>
  );
}

export default ProductsMenu;
