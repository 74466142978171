import {
  NameType,
  Payload,
} from 'recharts/types/component/DefaultTooltipContent';
import Stack from 'commons/components/Stack';
import ColorAndText from 'domains/Statistics/components/ColorAndText';
import ThousandSeparatorText from 'domains/Statistics/components/ThousandSeparatorText';

interface RankByProvinceTooltipProps {
  payload?: Payload<number, NameType>[];
  label?: string;
}

function RankByProvinceTooltip({ payload: data }: RankByProvinceTooltipProps) {
  return (
    <Stack column align="stretch" spacing="tight">
      {data?.map(({ name, value, color, payload }) => (
        <Stack key={name} spacing="tight" wrap justify="space-between">
          <ColorAndText color={color} text={`${payload.name}:`} />
          <ThousandSeparatorText
            size="caption"
            value={(value as number) ?? 0}
          />
        </Stack>
      ))}
    </Stack>
  );
}

export default RankByProvinceTooltip;
