import { DataTable } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import { DataTableRow } from './components';

const { Cell, Header } = DataTable;

function InvoiceHistoryDataTable(): JSX.Element {
  return (
    <>
      <Header>
        <Cell grow={1}>
          <Text.Skeleton />
        </Cell>
        <Cell grow={2}>
          <Text.Skeleton />
        </Cell>
        <Cell grow={4}>
          <Text.Skeleton />
        </Cell>
        <Cell grow={1}>
          <Text.Skeleton />
        </Cell>
        <Cell grow={1}>
          <Text.Skeleton />
        </Cell>
      </Header>
      <DataTableRow.Skeleton />
      <DataTableRow.Skeleton />
      <DataTableRow.Skeleton />
      <DataTableRow.Skeleton />
      <DataTableRow.Skeleton />
      <DataTableRow.Skeleton />
      <DataTableRow.Skeleton />
      <DataTableRow.Skeleton />
      <DataTableRow.Skeleton />
    </>
  );
}

export default InvoiceHistoryDataTable;
