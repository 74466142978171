import { CustomShippingRangeDetailsDto } from '@tiendanube/common';

export const initialRangeValues: CustomShippingRangeDetailsDto = {
  cost: '',
  deliveryTimeMax: '',
  deliveryTimeMin: '',
  initialRange: '',
  finalRange: '',
};

export const MIN_RANGE = '10';
export const MAX_RANGE = '1000';
