import { Route } from 'react-router-dom';
import { CHECKOUT_ROUTES } from './checkoutRoutes';
import {
  ChoosePaymentMethodForPayOrder,
  ChoosePaymentMethodForPlan,
  ChoosePaymentMethodPage,
  ChoosePaymentRecurrencyPage,
  ConceptDetailPage,
  PayWithCreditCard,
  PlansAndSubscriptions,
  ThankYouPage,
  WireTransferPage,
} from './pages';

function Checkout(): JSX.Element {
  return (
    <>
      <Route exact path={CHECKOUT_ROUTES.plansAndSubscriptions}>
        <PlansAndSubscriptions />
      </Route>
      <Route exact path={CHECKOUT_ROUTES.choosePaymentMethodForPlan}>
        <ChoosePaymentMethodForPlan />
      </Route>
      <Route exact path={CHECKOUT_ROUTES.choosePaymentMethodForPayOrder}>
        <ChoosePaymentMethodForPayOrder />
      </Route>
      <Route exact path={CHECKOUT_ROUTES.payWithCreditCard}>
        <PayWithCreditCard />
      </Route>
      <Route exact path={CHECKOUT_ROUTES.payWithWireTransfer}>
        <WireTransferPage />
      </Route>
      <Route exact path={CHECKOUT_ROUTES.conceptDetails}>
        <ConceptDetailPage />
      </Route>
      <Route path={CHECKOUT_ROUTES.choosePaymentMethod}>
        <ChoosePaymentMethodPage />
      </Route>
      <Route path={CHECKOUT_ROUTES.choosePaymentRecurrency}>
        <ChoosePaymentRecurrencyPage />
      </Route>
      <Route path={CHECKOUT_ROUTES.thankYouPage}>
        <ThankYouPage />
      </Route>
    </>
  );
}

export default Checkout;
