import { Tag, Text } from '@nimbus-ds/components';
import { EyeOffIcon } from '@nimbus-ds/icons';
import { v4 as uuidv4 } from 'uuid';
import { ScopeVisibilityType } from '@tiendanube/common';
import { ShowMoreContent } from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';

interface ShowMoreScopeProps {
  scope: string[];
  scopeType: string;
  scopeVisibility: ScopeVisibilityType[];
  onClick?: () => void;
}

const uniqueId = uuidv4();

export function ShowMoreScope({
  scope,
  scopeType,
  scopeVisibility,
  onClick,
}: Readonly<ShowMoreScopeProps>) {
  const t = useTranslationMarketing('promotions.scopeType');
  return (
    <ShowMoreContent contentName={t(scopeType)} onClick={onClick}>
      {scope.map((current, idx) => (
        <Tag key={`${uniqueId}-${current}`}>
          {scopeVisibility[idx] === 'hidden' && <EyeOffIcon />}
          <Text lineClamp={1}>{current}</Text>
        </Tag>
      ))}
    </ShowMoreContent>
  );
}
