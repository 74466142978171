import { CATALOG_ALLOW_EXTRA_CATEGORIES } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';

const MAX_ALLOWED_CATEGORIES = 1000;
const MAX_EXTRA_ALLOWED_CATEGORIES = 7000;

function useGetMaxAllowedCategories(): number {
  const [hasAllowExtraCategories] = useHasTags([
    CATALOG_ALLOW_EXTRA_CATEGORIES,
  ]);

  return hasAllowExtraCategories
    ? MAX_EXTRA_ALLOWED_CATEGORIES
    : MAX_ALLOWED_CATEGORIES;
}

export default useGetMaxAllowedCategories;
