import { logEvent } from 'commons/utils/tracking';
import {
  ORDER_FILTER,
  ORDER_SEARCH,
  ORDER_TRANSACTION_ID_LINK_CLICK,
  ORDER_SELECT_BULK_LINK_CLICK,
  ORDER_METAFIELD_SAVE,
  ORDER_METAFIELD_FILTER,
  ORDER_METAFIELD_SAVE_ERROR,
  ORDER_METAFIELD_FILTER_ERROR,
  ORDER_LIST_EXPORT,
  ORDER_DRAFT_ORDER_CREATE,
  ORDER_LIST_FILTER_CLICK,
  ORDER_EDIT_CLICK,
  ORDER_EDIT_SAVE_CLICK,
  ORDER_EDIT_TRACKING_CODE_CLICK,
  ORDER_EDIT_TRACKING_CODE_SAVE_CLICK,
  ORDER_REOPEN_BUTTON_CLICK,
  ORDER_REOPEN_WITH_ISSUES_BUTTON_CLICK,
  DRAFT_ORDER_MARK_AS_PAID_BUTTON_CLICK,
  DRAFT_ORDER_WITH_STOCK_ISSUES_CONFIRM_BUTTON_CLICK,
  DRAFT_ORDER_WITH_STOCK_ISSUES_CANCEL_BUTTON_CLICK,
  DRAFT_ORDER_WITH_STOCK_ISSUES_UNDERSTOOD_BUTTON_CLICK,
  ORDER_DRAFT_ORDER_CREATE_BUTTON_CLICK,
  ORDER_QUICK_ACTION_CLICK,
  ORDER_BUTTON_MARK_AS_PAID_CLICK,
  ORDER_LIST_EXPORT_BUTTON_CLICK,
  ORDER_LIST_CREATE_DRAFT_ORDER_BUTTON_CLICK,
  ORDER_BUTTON_NOTIFY_SHIPPING,
  ORDER_BUTTON_COPY_SHIPPING_LINK,
  ORDER_SELECT_SAVED_SEARCH,
  ORDER_UNSELECT_SAVED_SEARCH,
  ORDER_FILTER_CHIP_CLOSE,
  ORDERS_CLEAR_FILTERS,
  ORDERS_LAST_PAGE_CLICK,
} from 'config/trackingEvents';
import { UpsellResultEnum } from 'domains/Billing/UpsellFlow/hooks/useUpsellFlow';
import { DefaultSavedSearchesCode } from './components/SavedSearches/defaultSavedSearches';

type OrderFilterParams = {
  orderStatusFilter: string;
  dateRangeFilter: string;
  shippingStatusFilter: string[];
  shippingStatusFilterMultiselect: string;
  paymentStatusFilter: string[];
  paymentStatusFilterMultiselect: string;
  paymentMethodFilter: string;
  paymentProviderFilter: string;
  shippingFilter: string;
  channelFilter: string;
  applicationFilter: string;
  productsFilter: string;
  countSelectedProductsFilter: string;
  productsCountFilter: string;
  productsCountFilterValue: string;
};

type OrderExportFilterParams = {
  orderStatusFilter: string;
  dateFrom: string;
  dateTo: string;
  shippingStatusFilter: string[];
  paymentStatusFilter: string[];
  paymentMethodFilter: string;
  paymentProviderFilter: string;
  shippingFilter: string;
  channelFilter: string;
  applicationFilter: string;
};

// ORDERS

export const trackingOrderFilter = (params: OrderFilterParams): void => {
  logEvent(ORDER_FILTER, {
    order_status_filter: params.orderStatusFilter,
    date_range_filter: params.dateRangeFilter,
    shipping_status_filter: params.shippingStatusFilter,
    shipping_status_filter_multiselect: params.shippingStatusFilterMultiselect,
    payment_status_filter: params.paymentStatusFilter,
    payment_status_filter_multiselect: params.paymentStatusFilterMultiselect,
    payment_method_filter: params.paymentMethodFilter,
    payment_provider_filter: params.paymentProviderFilter,
    shipping_filter: params.shippingFilter,
    channel_filter: params.channelFilter,
    application_filter: params.applicationFilter,
    products_filter: params.productsFilter,
    count_selected_variants: params.countSelectedProductsFilter,
    products_count_filter: params.productsCountFilter,
    products_count_filter_value: params.productsCountFilterValue,
  });
};

export const trackingOrderSearch = (textSearch: string) => {
  logEvent(ORDER_SEARCH, {
    input: textSearch,
    type: Number.isNaN(Number(textSearch)) ? 'string' : 'number',
  });
};

export const trackingTransactionLinkClick = (): void => {
  logEvent(ORDER_TRANSACTION_ID_LINK_CLICK, {});
};

export const trackingOrderBulkSelectClick = (ordersCount: number): void => {
  logEvent(ORDER_SELECT_BULK_LINK_CLICK, { bulkSize: String(ordersCount) });
};

export const trackingOrderMetafieldSave = (
  metafieldId: string,
  orderId: string,
  value: string | null,
): void => {
  logEvent(ORDER_METAFIELD_SAVE, {
    metafieldId,
    orderId,
    value: `${value}`,
  });
};

export const trackingOrderMetafieldSaveError = (
  metafieldId: string,
  orderId: string,
  value: string | null,
): void => {
  logEvent(ORDER_METAFIELD_SAVE_ERROR, {
    metafieldId,
    orderId,
    value: `${value}`,
  });
};

export const trackingOrderMetafieldFilter = (
  metafieldId: string,
  value: string,
): void => {
  logEvent(ORDER_METAFIELD_FILTER, {
    metafieldId,
    value,
  });
};

export const trackingOrderMetafieldFilterError = (): void => {
  logEvent(ORDER_METAFIELD_FILTER_ERROR, {});
};

export const trackingOrderListExport = (
  params: OrderExportFilterParams,
): void => {
  logEvent(ORDER_LIST_EXPORT, {
    order_status_filter: params.orderStatusFilter,
    date_from: params.dateFrom,
    date_to: params.dateTo,
    shipping_status_filter: params.shippingStatusFilter,
    payment_status_filter: params.paymentStatusFilter,
    payment_method_filter: params.paymentMethodFilter,
    payment_provider_filter: params.paymentProviderFilter,
    shipping_filter: params.shippingFilter,
    channel_filter: params.channelFilter,
    application_filter: params.applicationFilter,
  });
};

export const trackingOrderDraftOrderCreate = (
  paymentStatus: 'unpaid' | 'pending_confirmation' | 'paid',
): void => {
  logEvent(ORDER_DRAFT_ORDER_CREATE, {
    order_status: paymentStatus,
  });
};

export const trackingOrderDraftOrderCreateClick = (): void => {
  logEvent(ORDER_DRAFT_ORDER_CREATE_BUTTON_CLICK, {});
};

export const trackingOrderListFilterClick = (): void => {
  logEvent(ORDER_LIST_FILTER_CLICK, {});
};

export const trackingOrderListExportClick = (): void => {
  logEvent(ORDER_LIST_EXPORT_BUTTON_CLICK, {});
};

export const trackingOrderListCreateDraftOrderClick = (): void => {
  logEvent(ORDER_LIST_CREATE_DRAFT_ORDER_BUTTON_CLICK, {});
};

export const trackingOrderListQuickAction = (action: string): void => {
  logEvent(ORDER_QUICK_ACTION_CLICK, {
    action,
  });
};

export const trackingOrderEditClick = (
  upsellResult: UpsellResultEnum,
): void => {
  logEvent(ORDER_EDIT_CLICK, {
    upsell_result: upsellResult,
  });
};

export const trackingOrderEditSaveClick = (
  reason: string | undefined,
  sendNotification: boolean,
): void => {
  logEvent(ORDER_EDIT_SAVE_CLICK, {
    reason: `${reason}`,
    sendNotification: `${sendNotification}`,
  });
};

export const trackingOrderEditTrackingCodeClick = (): void => {
  logEvent(ORDER_EDIT_TRACKING_CODE_CLICK, {});
};

export const trackingOrderEditTrackingCodeSaveClick = (
  sendNotification: boolean,
): void => {
  logEvent(ORDER_EDIT_TRACKING_CODE_SAVE_CLICK, {
    sendNotification: `${sendNotification}`,
  });
};

export const trackingOrderReopenButtonClick = (status: string): void => {
  logEvent(ORDER_REOPEN_BUTTON_CLICK, { order_status: status });
};

export const trackingOrderReopenWithIssuesButtonClick = (): void => {
  logEvent(ORDER_REOPEN_WITH_ISSUES_BUTTON_CLICK, {});
};

export const trackingOrderMarkAsPaidClick = (gatewayMethod?: string): void => {
  logEvent(
    ORDER_BUTTON_MARK_AS_PAID_CLICK,
    gatewayMethod ? { gatewayMethod } : {},
  );
};

export const trackingOrderNotifyShippingButtonClick = (
  trackingCode: boolean,
  notifyCustomer: boolean,
): void => {
  logEvent(ORDER_BUTTON_NOTIFY_SHIPPING, {
    trackingCode: `${trackingCode}`,
    notifyCustomer: `${notifyCustomer}`,
  });
};

export const trackingOrdersCopyShippingLinkButtonClick = (): void => {
  logEvent(ORDER_BUTTON_COPY_SHIPPING_LINK, {});
};
// DRAFT ORDERS

export const trackingDraftOrderMarkAsPaidButtonCLick = (): void => {
  logEvent(DRAFT_ORDER_MARK_AS_PAID_BUTTON_CLICK, {});
};
export const trackingDraftOrderConfirmWithIssuesButtonClick = (): void => {
  logEvent(DRAFT_ORDER_WITH_STOCK_ISSUES_CONFIRM_BUTTON_CLICK, {});
};
export const trackingDraftOrderCancelWithIssuesButtonClick = (): void => {
  logEvent(DRAFT_ORDER_WITH_STOCK_ISSUES_CANCEL_BUTTON_CLICK, {});
};
export const trackingDraftOrderWithIssuesUndestoodButtonClick = (): void => {
  logEvent(DRAFT_ORDER_WITH_STOCK_ISSUES_UNDERSTOOD_BUTTON_CLICK, {});
};

export const trackingSearchBySavedSearches = (
  savedSearchCode: DefaultSavedSearchesCode,
  selected?: boolean,
) => {
  logEvent(selected ? ORDER_SELECT_SAVED_SEARCH : ORDER_UNSELECT_SAVED_SEARCH, {
    savedSearch: savedSearchCode,
  });
};

export const trackingFilterChipClose = (): void => {
  logEvent(ORDER_FILTER_CHIP_CLOSE, {});
};

export const trackingClearFilters = () => {
  logEvent(ORDERS_CLEAR_FILTERS, {});
};

export const trackingLastPageClick = () => {
  logEvent(ORDERS_LAST_PAGE_CLICK, {});
};
