import { useCallback, useEffect, useState } from 'react';
import { Title, Text, Label, Textarea } from '@nimbus-ds/components';
import sanitizeHtml from 'sanitize-html';
import {
  ContentTemplateIdType,
  GenerateContentsPageResponseDto,
  LanguageType,
} from '@tiendanube/common';
import { AiFeature } from '@tiendanube/common/src/enums';
import { useNavegate } from 'App/hooks';
import AiGenerationModal, {
  GenerateContentParams,
} from 'commons/components/AiGenerationModal';
import Stack from 'commons/components/Stack';
import { useCreateContentPages } from 'domains/Online/ContentPages/hooks';
import useGenerateContentsPage from 'domains/Online/ContentPages/hooks/useGenerateContentsPage';
import useTranslationOnline from 'domains/Online/useTranslationOnline';

const ALLOWED_HTML_TAGS = [
  'b',
  'i',
  'em',
  'strong',
  'p',
  'ul',
  'ol',
  'li',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
];

interface GeneratedContent {
  generatedPageContent: string;
  generatedSeoTitle: string;
  generatedSeoDescription: string;
}

export interface PageGenerationModalProps {
  show?: boolean;
  onDismiss: () => void;
  onUseContent: (args: GeneratedContent) => void;
  page: {
    name?: string;
    content?: string;
    lang: LanguageType;
  };
}

function PageGenerationModal({
  show = false,
  onDismiss,
  onUseContent,
  page,
}: Readonly<PageGenerationModalProps>) {
  const t = useTranslationOnline('contentPages.formPage');

  const [pageDescription, setPageDescription] = useState('');
  const { generateContentsPage } = useGenerateContentsPage();

  const { getQueryParam } = useNavegate();
  const templateId =
    (getQueryParam('templateId') as ContentTemplateIdType) || 'blank';
  const { initialValues: pageTemplate, statusTemplate } =
    useCreateContentPages(templateId);

  const insufficientInfo = !page.name;

  useEffect(() => {
    // Initialize page description with the template content once obtained from backend (if available)
    if (statusTemplate !== 'success') {
      return;
    }

    const sanitizedPageContent = sanitizeHtml(
      pageTemplate.content[page.lang] || '',
      {
        allowedTags: [],
      },
    );

    setPageDescription(sanitizedPageContent);
  }, [pageTemplate, statusTemplate, page.lang, setPageDescription]);

  const handleChangePageDescription = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setPageDescription(e.target.value);
  };

  const handleGenerateContent = useCallback(
    async ({ tone, wordCount }: GenerateContentParams) => {
      const response = await generateContentsPage({
        page_name: page.name || '',
        page_content: pageDescription,
        lang: page.lang,
        tone,
        word_count: wordCount,
      });

      const safePagecontent = sanitizeHtml(response.page_content, {
        allowedTags: ALLOWED_HTML_TAGS,
      });

      return {
        ...response,
        page_content: safePagecontent,
      };
    },
    [generateContentsPage, page, pageDescription],
  );

  const handleUseContent = useCallback(
    ({
      page_content,
      seo_title,
      seo_description,
    }: GenerateContentsPageResponseDto) => {
      onUseContent({
        generatedPageContent: page_content,
        generatedSeoTitle: seo_title,
        generatedSeoDescription: seo_description,
      });
    },
    [onUseContent],
  );

  return (
    <AiGenerationModal
      show={show}
      feature={AiFeature.AI_PAGES}
      insufficientInfo={insufficientInfo}
      insufficientInfoError={t('aiGeneration.insufficientInfoError')}
      onDismiss={onDismiss}
      onGenerateContent={handleGenerateContent}
      onUseContent={handleUseContent}
      feedbackTitle={t('aiGeneration.feedbackModal.title')}
      feedbackText={t('aiGeneration.feedbackModal.text')}
      showTone
      wordCountValues={{ short: 100, medium: 300, large: 1000 }}
      extraControls={
        <Stack column align="stretch" spacing="tight">
          <Label htmlFor="merchant-provided-context">
            {t('aiGeneration.pageContentLabel')}
          </Label>
          <Textarea
            id="merchant-provided-context"
            name="merchant-provided-context"
            value={pageDescription}
            lines={10}
            onChange={handleChangePageDescription}
            placeholder={t('aiGeneration.pageContentPlaceholder')}
          />
        </Stack>
      }
      previewGeneratedContent={({
        page_content,
        seo_title,
        seo_description,
      }: GenerateContentsPageResponseDto) => (
        <Stack column align="stretch">
          <Stack.Item>
            <Title as="h4">{t('aiGeneration.response.content')}</Title>
            <AiGenerationModal.HtmlPreview html={page_content} />
          </Stack.Item>

          <Stack.Item>
            <Title as="h4">{t('aiGeneration.response.seo_title')}</Title>
            <Text color="primary-textLow">{seo_title}</Text>
          </Stack.Item>

          <Stack.Item>
            <Title as="h4">{t('aiGeneration.response.seo_description')}</Title>
            <Text color="primary-textLow">{seo_description}</Text>
          </Stack.Item>
        </Stack>
      )}
    />
  );
}

export default PageGenerationModal;
