import { Alert } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import { useTranslationBilling } from 'domains/Billing';
import { ChangeType } from '../../BillingPlansPage';

interface ChangePlanAlertProps {
  show: boolean;
  changeType?: ChangeType;
  newPlanName?: string;
}

function ChangePlanAlert({
  show,
  changeType,
  newPlanName,
}: ChangePlanAlertProps): JSX.Element {
  const t = useTranslationBilling();

  const isUpgrade = changeType === 'upgrade';
  const isBasic = changeType === 'basic';

  const title =
    (isBasic && t('billingPlansPage.alert.basicTitle')) ||
    (isUpgrade && t('billingPlansPage.alert.upgradeTitle')) ||
    t('billingPlansPage.alert.downgradeTitle');

  return (
    <Alert show={show} title={title}>
      {isBasic && <Text>{t('billingPlansPage.alert.basicMessage')}</Text>}
      {!isBasic && isUpgrade && (
        <Text>
          {t('billingPlansPage.alert.upgradeMessage', {
            planName: newPlanName,
          })}
        </Text>
      )}
    </Alert>
  );
}

export default ChangePlanAlert;
