import { useCallback, useMemo } from 'react';
import { useAppDispatch } from 'App/store';
import { useModal } from 'commons/hooks';
import { useRefreshInfoToSync } from 'domains/Auth/hooks';
import { cleanTrialMessages } from 'domains/Dashboard/slices/trialMessagesSlice';
import { useGetPlansQuery, useUpdatePlanMutation } from '../billingPlansApi';

export function useBillingPlans(initialFetch = true) {
  const dispatch = useAppDispatch();
  const [isUpgradedPlanModalOpen, openUpgradedPlanModal] = useModal();

  const {
    data,
    refetch: fetchBillingPlans,
    isLoading,
    isError,
    isSuccess,
    status,
  } = useGetPlansQuery(undefined, {
    skip: !initialFetch,
  });

  const [updatePlan] = useUpdatePlanMutation();

  const features = data?.features || [];
  const plansList = data?.plansList || [];
  const currentPlanId = data?.currentPlan?.id || 0;
  const currentPlanLevel = data?.currentPlan?.level || 0;
  const { refreshInfo } = useRefreshInfoToSync();
  const refreshPlans = useCallback(() => refreshInfo(true), [refreshInfo]);

  const currentPlan = useMemo(
    () => data?.plansList.find(({ id }) => id === data.currentPlan?.id),
    [data],
  );

  const updateBillingPlan = useCallback(
    async (planId: number, autoRefreshPlan = true) => {
      const result = await updatePlan(planId).unwrap();
      dispatch(cleanTrialMessages());
      if (autoRefreshPlan) refreshPlans();
      return result;
    },
    [updatePlan, dispatch, refreshPlans],
  );

  return {
    features,
    plansList,
    currentPlanId,
    currentPlan,
    currentPlanLevel,
    fetchBillingPlans,
    updateBillingPlan,
    isUpgradedPlanModalOpen,
    openUpgradedPlanModal,
    refreshPlans,
    isLoading,
    isError,
    isSuccess,
    status,
  };
}
