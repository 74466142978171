import { logEvent } from 'commons/utils/tracking';
import {
  MENU_SECONDARY_FACEBOOK_CLICK,
  MENU_SECONDARY_GOOGLE_SHOPPING_CLICK,
  MENU_SECONDARY_INSTAGRAM_CLICK,
  MENU_SECONDARY_MARKETPLACES_APPS_CLICK,
  MENU_SECONDARY_TIKTOK_CLICK,
} from 'config/trackingEvents';

export const menuSecondaryMarketplacesAppsClick = () => {
  logEvent(MENU_SECONDARY_MARKETPLACES_APPS_CLICK, {});
};

export const menuSecondaryGoogleShoppingClick = () => {
  logEvent(MENU_SECONDARY_GOOGLE_SHOPPING_CLICK, {});
};

export const menuSecondaryTikTokClick = () => {
  logEvent(MENU_SECONDARY_TIKTOK_CLICK, {});
};

export const menuSecondaryFacebookClick = () => {
  logEvent(MENU_SECONDARY_FACEBOOK_CLICK, {});
};

export const menuSecondaryInstagramClick = () => {
  logEvent(MENU_SECONDARY_INSTAGRAM_CLICK, {});
};
