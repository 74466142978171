import { useEffect } from 'react';
import { Box } from '@nimbus-ds/components';
import { useDesktopMenuContext } from 'App/components/Menu/DesktopMenuProvider';
import useTopBar from 'App/components/Topbar/useTopBar';
import { useNavegate } from 'App/hooks';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Layout,
} from 'commons/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling/useTranslationBilling';
import { PaymentData, PaymentSelection } from './components';
import {
  trackingBillingViewSelectPaymentMethodForPlan,
  useCheckoutTracking,
} from '../../tracking';

function ChoosePaymentMethodForPlan(): JSX.Element {
  const t = useTranslationBilling('checkout.choosePaymentMethodForPlan');
  const tBilling = useTranslationBilling();
  const { goBack } = useNavegate();
  const { isShowMenu } = useTopBar();
  const { isShowMenu: isShowAsideMenu } = useDesktopMenuContext();
  const backNavigation = {
    onClick: goBack,
    children:
      !isShowMenu && !isShowAsideMenu
        ? tBilling('billingPlansPage.back')
        : undefined,
  };

  const logEvent = useCheckoutTracking();
  useEffect(
    () => trackingBillingViewSelectPaymentMethodForPlan(logEvent, 'plan-cost'),
    [logEvent],
  );

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={backNavigation} />}
      headerContent={<HeaderContent title={t('title')} />}
      width="large"
    >
      <Layout
        mainContent
        left={
          <Box display="flex" flexDirection="column" flexGrow="1">
            <PaymentSelection />
          </Box>
        }
        right={
          <Box display="flex" flexDirection="column" flexGrow="1">
            <PaymentData />
          </Box>
        }
        orderMobile="reverse"
      />
    </IonPageStratus>
  );
}

export default ChoosePaymentMethodForPlan;
