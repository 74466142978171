import { PromotionTypes } from 'domains/Marketing/Promotions/pages/constants';

export const getDiscountText = (discount, t) => {
  if (discount.type === PromotionTypes.CROSS_SELLING) {
    return t('orderSummaryCard.tableAmounts.crossSellingPromotion', {
      rewardName: discount.scopeName,
    });
  }
  if (discount.isCustom) {
    return discount.name;
  }

  if (!discount.name) {
    return t('orderSummaryCard.tableAmounts.promotionDiscountDefault', {
      promotionType: discount.type,
    });
  }

  if (discount.type === PromotionTypes.N_AT_X_OFF) {
    return discount.name;
  }

  return t('orderSummaryCard.tableAmounts.promotionDiscount', {
    promotionName: discount.name,
    promotionType: discount.type,
  });
};
