import { TextDisabledContainer } from 'commons/components';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
import StatsColorIcon from '../StatsColorIcon';

interface ColorAndTextProps {
  color?: string;
  secondaryColor?: string;
  text?: string | number;
  disabled?: boolean;
}

function ColorAndText({
  color,
  secondaryColor,
  text,
  disabled,
}: ColorAndTextProps) {
  secondaryColor;
  return (
    <Stack spacing="tight" justify="space-between">
      {!!color && (
        <StatsColorIcon color={color} secondaryColor={secondaryColor} />
      )}
      <TextDisabledContainer disabled={disabled}>
        <Text size="caption" trimText trimLines={1}>
          {text}
        </Text>
      </TextDisabledContainer>
    </Stack>
  );
}

export default ColorAndText;
