import { Fragment } from 'react';
import { Alert, Text, Title } from '@nimbus-ds/components';
import { EditIcon, TrashIcon } from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';
import {
  CustomShippingRangeDetailsDto,
  LocationResponseDto,
} from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { ActionIconButton, Stack } from 'commons/components';
import {
  useHasNewPickupPoints,
  useHasShippingCustomRanges,
} from 'domains/Auth/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  DeliveryZonesInfo,
  DeliverCostInfo,
  DeliveryCartValueInfo,
  DeliveryCartWeightInfo,
  DeliveryDaysInfo,
} from './components';
import ShippingCustomRangeInfo from './components/ShippingCustomRangeInfo';
import { DeliveryCostType } from '../../../DeliveryMethodsPage/components/CustomShippingForm/CustomShippingForm';
import { SelectedZonesInterface } from '../../../NewCustomShippingPage/components/DeliveryZoneCard';

export interface ShippingListInterface {
  id: string;
  name: string;
  showAsPickUpPoint?: boolean;
  allowedForFreeShipping?: boolean;
  deliveryCost: DeliveryCostType;
  cost?: string;
  cartWeightMin?: string;
  cartWeightMax?: string;
  cartValueMin?: string;
  cartValueMax?: string;
  deliveryTimeMin?: string;
  deliveryTimeMax?: string;
  deliveryZones?: SelectedZonesInterface[];
  locationId?: string;
  ranges?: CustomShippingRangeDetailsDto[];
}

interface ShippingListCardProps {
  shippingList: ShippingListInterface[];
  editDeliveryRoute: string;
  locations: LocationResponseDto[];
  handleDelete: (id: string) => void;
}

function ShippingListCard({
  shippingList,
  editDeliveryRoute,
  locations,
  handleDelete,
}: ShippingListCardProps) {
  const t = useTranslationShipping();
  const hasNewPickupPoints = useHasNewPickupPoints();
  const hasCustomRanges = useHasShippingCustomRanges();

  const { goTo } = useNavegate();
  const handleClickEdit = (id: string) => goTo(`${editDeliveryRoute}/${id}`);

  return (
    <DataList bottomDivider={false}>
      {shippingList.map(
        ({
          id,
          name,
          cost,
          deliveryCost,
          cartWeightMin,
          cartWeightMax,
          cartValueMin,
          cartValueMax,
          deliveryTimeMin,
          deliveryTimeMax,
          showAsPickUpPoint,
          deliveryZones = [],
          locationId,
          ranges = [],
        }) => (
          <Fragment key={id}>
            <DataList.Row id={id}>
              <Stack justify="space-between" align="flex-start" wrap>
                <Stack column align="flex-start">
                  <Title as="h5" fontWeight="bold">
                    {name}
                  </Title>
                  <Stack column spacing="none" align="flex-start">
                    {(ranges.length === 0 || !hasCustomRanges) && (
                      <DeliverCostInfo
                        cost={cost}
                        deliveryCost={deliveryCost}
                      />
                    )}
                    {showAsPickUpPoint && (
                      <Text>
                        {t(
                          'deliveryMethods.newInformationShipping.startingPoint',
                        )}
                      </Text>
                    )}
                    {locations && !!locationId && (
                      <Text>
                        {t(
                          'deliveryMethods.newInformationShipping.initialLocation',
                          {
                            location: locations.find(
                              (location) => location.id === locationId,
                            )?.name,
                          },
                        )}
                      </Text>
                    )}
                    {hasCustomRanges &&
                      ranges.length > 0 &&
                      ranges.map((range) => (
                        <ShippingCustomRangeInfo key={range.id} range={range} />
                      ))}

                    {(!!cartValueMin || !!cartValueMax) && (
                      <DeliveryCartValueInfo
                        cartValueMax={cartValueMax}
                        cartValueMin={cartValueMin}
                      />
                    )}

                    {(!!cartWeightMin || !!cartWeightMax) && (
                      <DeliveryCartWeightInfo
                        cartWeightMax={cartWeightMax}
                        cartWeightMin={cartWeightMin}
                      />
                    )}

                    {(!!deliveryTimeMin || !!deliveryTimeMax) &&
                      ranges.length === 0 && (
                        <DeliveryDaysInfo
                          deliveryTimeMax={deliveryTimeMax}
                          deliveryTimeMin={deliveryTimeMin}
                        />
                      )}
                    {deliveryZones &&
                      deliveryZones.length > 0 &&
                      ranges.length === 0 && (
                        <DeliveryZonesInfo deliveryZones={deliveryZones} />
                      )}
                  </Stack>
                </Stack>
                <Stack spacing="tight" justify="flex-end">
                  <ActionIconButton
                    content={t(
                      'deliveryMethods.activeShippingMethods.editButton',
                    )}
                    source={<EditIcon />}
                    onClick={() => handleClickEdit(id)}
                  />
                  <ActionIconButton
                    content={t(
                      'deliveryMethods.activeShippingMethods.deleteButton',
                    )}
                    source={<TrashIcon />}
                    onClick={() => handleDelete(id)}
                  />
                </Stack>
                {showAsPickUpPoint && hasNewPickupPoints && (
                  <Stack flex="1 1 100%">
                    <Alert
                      appearance="danger"
                      title={t(
                        'deliveryMethods.customShipping.pickupPointAlert.title',
                      )}
                    >
                      {t(
                        'deliveryMethods.customShipping.pickupPointAlert.description',
                      )}
                    </Alert>
                  </Stack>
                )}
              </Stack>
            </DataList.Row>
          </Fragment>
        ),
      )}
    </DataList>
  );
}

export default ShippingListCard;
