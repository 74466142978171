import { useCallback, useEffect, useState } from 'react';
import { OwnerResourceType } from '@tiendanube/common/src/domains/metafields/types';
import { CustomersFiltersType } from 'domains/Customers/Customers/customersServices';
import { trackingCustomerFilterCustomFieldChange } from 'domains/Customers/tracking';
import { OnChangeMetafieldType, SourceType } from 'domains/Metafields/types';
import { MetafieldSelectedInterface } from '../../CustomerFormPage/types';

const defaultValueErrors = {};

export default function useAdvancedCustomerFilter(
  filters: CustomersFiltersType,
) {
  const [values, setValues] = useState(filters);
  const [errors, setErrors] = useState(defaultValueErrors);

  const handleChange = useCallback(
    ({ name, value }) => {
      setValues({ ...values, [name]: value });
      setErrors(defaultValueErrors);
    },
    [values],
  );

  const handleChangeMetafields: OnChangeMetafieldType = (
    id,
    value,
    source: SourceType,
    resource?: OwnerResourceType,
    name?: string,
  ) => {
    trackingCustomerFilterCustomFieldChange(id, name, value);
    if (value !== null) {
      setValues((values) => ({
        ...values,
        [`mf-${id}`]: value,
      }));
      return;
    }
    setValues((values) => {
      const newValues = { ...values };
      delete newValues[`mf-${id}`];
      return newValues;
    });
  };

  const setAllValues = useCallback((filters: CustomersFiltersType) => {
    setValues(filters);
  }, []);

  const selecteds = Object.entries(values)
    .filter(([key]) => key.startsWith('mf-'))
    .map(([key, value]) => ({
      id: key.split('mf-')[1],
      value,
    })) as MetafieldSelectedInterface[];

  useEffect(() => {
    setValues(filters);
  }, [filters]);

  return {
    values,
    errors,
    selecteds,
    handleChange,
    handleChangeMetafields,
    setAllValues,
  };
}
