import { ErrorScreen, VirtualDataList } from 'commons/components';
import {
  useGetEmailTypesQuery,
  useGetRolesQuery,
} from 'domains/Configurations/Users/usersApi';
import { UsersListMobileRow, UsersListMobileSkeleton } from './components';
import useAppsListMobile from '../../hooks/useUsersListMobile';

function UsersListMobile() {
  const {
    ids,
    data: users,
    showSkeleton: isUsersLoading,
    showSpinner,
    isError: isUsersError,
    refetch,
    handleInfiniteScroll,
    handlePullToRefresh,
  } = useAppsListMobile();
  const {
    data: roles,
    isLoading: isRolesLoading,
    isError: isRolesError,
    refetch: rolesRefetch,
  } = useGetRolesQuery();

  const { data: allEmailTypes } = useGetEmailTypesQuery();

  const showSkeleton = isUsersLoading || isRolesLoading;
  const showErrorScreen = isUsersError || isRolesError || !users || !roles;

  const refetchAll = () => {
    refetch();
    rolesRefetch();
  };
  const allEmailTypeCodes = allEmailTypes?.emailTypes.map((type) => type.code);

  if (showSkeleton) return <UsersListMobileSkeleton />;
  if (showErrorScreen) return <ErrorScreen onRetry={refetchAll} />;

  return (
    <VirtualDataList
      onRefresh={handlePullToRefresh}
      isPullable
      data={ids}
      endReached={handleInfiniteScroll}
      itemContent={(index) => (
        <UsersListMobileRow
          key={`users-list-item-${users[index].id}`}
          user={users[index]}
          storeRoles={roles}
          allEmailTypes={allEmailTypeCodes || []}
        />
      )}
      showFooter={showSpinner}
    />
  );
}

export default UsersListMobile;
