import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AuthenticationFactorDashboardStatusResponseDto,
  AuthenticationFactorResponseDto,
  AuthenticationFactorType,
  InstallAuthenticationFactorRequestDto,
  InstallAuthenticationFactorResponseDto,
  RecoveryCodesAlertToShowResponseDto,
  RecoveryCodesResponseDto,
  RecoveryCodeUpdateAuthenticationFactorResponseDto,
  UnsubscribeReasonsDto,
  UnsubscribeStoreRequestDto,
  UpdateAuthenticationFactorRequestDto,
  UpdateAuthenticationFactorResponseDto,
  ValidateCredentialsRequestDto,
  ValidateCredentialsResponseDto,
} from '@tiendanube/common';
import { AuthenticationFactorTypes } from '@tiendanube/common/src/domains/account/authenticationFactorTypes';
import { StatusType } from 'commons/types';
import authService from 'domains/Auth/authService';
import accountServices from '../accountServices';

export interface AccountSchema {
  shopCancellation: {
    data: UnsubscribeReasonsDto[];
    getStatus: 'idle' | 'loading' | 'success' | 'error';
    deleteStatus: 'idle' | 'loading' | 'success' | 'error';
    currentRequestID: string;
  };
  authenticationFactors: {
    data: AuthenticationFactorResponseDto[];
    status: StatusType;
  };
  recoveryCodes: {
    data: RecoveryCodesResponseDto[];
    status: StatusType;
  };
  regenerateRecoveryCodes: {
    status: StatusType;
  };
  authenticationFactorCode: {
    data: InstallAuthenticationFactorResponseDto<AuthenticationFactorType> | null;
    status: StatusType;
  };
  updateAuthenticationFactor: {
    data: UpdateAuthenticationFactorResponseDto;
    status: StatusType;
  };
  validateCredentials: {
    data: ValidateCredentialsResponseDto | null;
    status: StatusType;
  };
  invalidateSessions: {
    status: StatusType;
  };
  authenticationFactorsDashboardStatus: {
    data?: AuthenticationFactorDashboardStatusResponseDto;
    status: StatusType;
  };
  recoveryCodesAlertToShow: {
    data: RecoveryCodesAlertToShowResponseDto;
    status: StatusType;
  };
  postReviveStore: {
    status: StatusType;
  };
}

const initialState: AccountSchema = {
  shopCancellation: {
    data: [],
    getStatus: 'idle',
    deleteStatus: 'idle',
    currentRequestID: '',
  },
  authenticationFactors: {
    data: [],
    status: 'idle',
  },
  recoveryCodes: {
    data: [],
    status: 'idle',
  },
  regenerateRecoveryCodes: {
    status: 'idle',
  },
  authenticationFactorCode: {
    data: null,
    status: 'idle',
  },
  updateAuthenticationFactor: {
    data: [],
    status: 'idle',
  },
  validateCredentials: {
    data: null,
    status: 'idle',
  },
  invalidateSessions: {
    status: 'idle',
  },
  authenticationFactorsDashboardStatus: {
    status: 'idle',
  },
  recoveryCodesAlertToShow: {
    data: { alertToShow: null },
    status: 'idle',
  },
  postReviveStore: {
    status: 'idle',
  },
};

export const deleteAccount = createAsyncThunk(
  'account/deleteAccount',
  async (reasons: UnsubscribeStoreRequestDto) => {
    await accountServices.deleteAccount(reasons);
  },
);

export const fetchShopCancellation = createAsyncThunk(
  'account/fetchShopCancellation',
  async () => await accountServices.getUnsubscribeReasons(),
);

export const fetchAuthenticationFactors = createAsyncThunk(
  'account/fetchAuthenticationFactors',
  async () => await accountServices.getAuthenticationFactors(),
);

export const fetchRecoveryCodes = createAsyncThunk(
  'account/fetchRecoveryCodes',
  async () => await accountServices.getRecoveryCodes(),
);

export const regenerateRecoveryCodes = createAsyncThunk(
  'account/regenerateRecoveryCodes',
  async () => await accountServices.regenerateRecoveryCodes(),
);

export const installAuthenticationFactor = createAsyncThunk(
  'account/installAuthenticationFactor',
  async (payload: InstallAuthenticationFactorRequestDto) =>
    await accountServices.installAuthenticationFactor(payload),
);

export const updateAuthenticationFactor = createAsyncThunk(
  'account/updateAuthenticationFactor',
  async (payload: UpdateAuthenticationFactorRequestDto) =>
    await accountServices.updateAuthenticationFactor(payload),
);

export const validateCredentials = createAsyncThunk(
  'account/validateCredentials',
  async (payload: ValidateCredentialsRequestDto) =>
    await authService.validateCredentials(payload),
);

export const invalidateSessions = createAsyncThunk(
  'account/invalidateSessions',
  async () => await accountServices.invalidateSessions(),
);

export const fetchAuthenticationFactorQr = createAsyncThunk(
  'account/fetchAuthenticationFactorQr',
  accountServices.getAuthenticationFactorQr,
);

export const fetchAuthenticationFactorsDashboardStatus = createAsyncThunk(
  'dashboard/fetchAuthenticationFactorsDashboardStatus',
  async () => await accountServices.getAuthenticationFactorsDashboardStatus(),
);

export const fetchRecoveryCodesAlertToShow = createAsyncThunk(
  'dashboard/fetchRecoveryCodesAlertToShow',
  accountServices.getRecoveryCodesAlertToShow,
);

export const postReviveStore = createAsyncThunk(
  'account/reviveStoreWithoutPayment',
  accountServices.postReviveStore,
);

const account = createSlice({
  name: 'account',
  initialState,
  reducers: {
    resetUpdateAuthenticationFactor(state) {
      state.updateAuthenticationFactor =
        initialState.updateAuthenticationFactor;
    },
    resetAuthenticationFactors(state) {
      state.authenticationFactors = initialState.authenticationFactors;
    },
    resetRecoveryCodes(state) {
      state.recoveryCodes = initialState.recoveryCodes;
    },
    resetRegenerateRecoveryCodes(state) {
      state.regenerateRecoveryCodes = initialState.regenerateRecoveryCodes;
    },
    resetRecoveryCodesAlertToShow(state) {
      state.recoveryCodesAlertToShow = initialState.recoveryCodesAlertToShow;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShopCancellation.pending, (state, action) => {
        state.shopCancellation.getStatus = 'loading';
        state.shopCancellation.currentRequestID = action.meta.requestId;
      })
      .addCase(fetchShopCancellation.fulfilled, (state, action) => {
        state.shopCancellation.getStatus = 'success';
        state.shopCancellation.data = action.payload;
      })
      .addCase(fetchShopCancellation.rejected, (state) => {
        state.shopCancellation.getStatus = 'error';
      });

    builder
      .addCase(deleteAccount.pending, (state, action) => {
        state.shopCancellation.deleteStatus = 'loading';
        state.shopCancellation.currentRequestID = action.meta.requestId;
      })
      .addCase(deleteAccount.fulfilled, (state, action) => {
        if (state.shopCancellation.currentRequestID === action.meta.requestId) {
          state.shopCancellation.deleteStatus = 'success';
        }
      })
      .addCase(deleteAccount.rejected, (state) => {
        state.shopCancellation.deleteStatus = 'error';
      });

    builder
      .addCase(fetchAuthenticationFactors.pending, (state) => {
        state.authenticationFactors.status = 'loading';
      })
      .addCase(fetchAuthenticationFactors.fulfilled, (state, action) => {
        state.authenticationFactors.status = 'success';
        state.authenticationFactors.data = action.payload;
      })
      .addCase(fetchAuthenticationFactors.rejected, (state) => {
        state.authenticationFactors.status = 'error';
      });

    builder
      .addCase(fetchRecoveryCodes.pending, (state) => {
        state.recoveryCodes.status = 'loading';
      })
      .addCase(fetchRecoveryCodes.fulfilled, (state, action) => {
        state.recoveryCodes.status = 'success';
        state.recoveryCodes.data = action.payload;
      })
      .addCase(fetchRecoveryCodes.rejected, (state) => {
        state.recoveryCodes.status = 'error';
      });

    builder
      .addCase(regenerateRecoveryCodes.pending, (state) => {
        state.regenerateRecoveryCodes.status = 'loading';
      })
      .addCase(regenerateRecoveryCodes.fulfilled, (state, action) => {
        state.regenerateRecoveryCodes.status = 'success';
        state.recoveryCodes.data = action.payload;
      })
      .addCase(regenerateRecoveryCodes.rejected, (state) => {
        state.regenerateRecoveryCodes.status = 'error';
      });

    builder
      .addCase(installAuthenticationFactor.pending, (state) => {
        state.authenticationFactorCode.status = 'loading';
      })
      .addCase(installAuthenticationFactor.fulfilled, (state, action) => {
        state.authenticationFactorCode.status = 'success';
        state.authenticationFactorCode.data = action.payload;
        if (action.payload && 'codes' in action.payload.authenticationFactor) {
          state.recoveryCodes.data =
            action.payload.authenticationFactor.codes || [];
        }
      })
      .addCase(installAuthenticationFactor.rejected, (state) => {
        state.authenticationFactorCode.status = 'error';
      });

    builder
      .addCase(updateAuthenticationFactor.pending, (state) => {
        state.updateAuthenticationFactor.status = 'loading';
      })
      .addCase(updateAuthenticationFactor.fulfilled, (state, action) => {
        state.recoveryCodes.data =
          action.payload.find(
            (auth): auth is RecoveryCodeUpdateAuthenticationFactorResponseDto =>
              auth.type === AuthenticationFactorTypes.RECOVERY_CODE,
          )?.codes || [];
        state.updateAuthenticationFactor.status = 'success';
      })
      .addCase(updateAuthenticationFactor.rejected, (state) => {
        state.updateAuthenticationFactor.status = 'error';
      });

    builder
      .addCase(validateCredentials.pending, (state) => {
        state.validateCredentials.status = 'loading';
      })
      .addCase(validateCredentials.fulfilled, (state, action) => {
        state.validateCredentials.data = action.payload;
        state.validateCredentials.status = 'success';
      })
      .addCase(validateCredentials.rejected, (state) => {
        state.validateCredentials.status = 'error';
      });

    builder
      .addCase(invalidateSessions.pending, (state) => {
        state.invalidateSessions.status = 'loading';
      })
      .addCase(invalidateSessions.fulfilled, (state) => {
        state.invalidateSessions.status = 'success';
      })
      .addCase(invalidateSessions.rejected, (state) => {
        state.invalidateSessions.status = 'error';
      });

    builder
      .addCase(fetchAuthenticationFactorsDashboardStatus.pending, (state) => {
        state.authenticationFactorsDashboardStatus.status = 'loading';
      })
      .addCase(
        fetchAuthenticationFactorsDashboardStatus.fulfilled,
        (state, action) => {
          state.authenticationFactorsDashboardStatus.status = 'success';
          state.authenticationFactorsDashboardStatus.data = action.payload;
        },
      )
      .addCase(fetchAuthenticationFactorsDashboardStatus.rejected, (state) => {
        state.authenticationFactorsDashboardStatus.status = 'error';
      });

    builder
      .addCase(fetchRecoveryCodesAlertToShow.pending, (state) => {
        state.recoveryCodesAlertToShow.status = 'loading';
      })
      .addCase(fetchRecoveryCodesAlertToShow.fulfilled, (state, action) => {
        state.recoveryCodesAlertToShow.status = 'success';
        state.recoveryCodesAlertToShow.data = action.payload;
      })
      .addCase(fetchRecoveryCodesAlertToShow.rejected, (state) => {
        state.recoveryCodesAlertToShow.status = 'error';
      });

    builder
      .addCase(postReviveStore.pending, (state) => {
        state.postReviveStore.status = 'loading';
      })
      .addCase(postReviveStore.fulfilled, (state) => {
        state.postReviveStore.status = 'success';
      })
      .addCase(postReviveStore.rejected, (state) => {
        state.postReviveStore.status = 'error';
      });
  },
});

export const {
  resetUpdateAuthenticationFactor,
  resetAuthenticationFactors,
  resetRecoveryCodes,
  resetRegenerateRecoveryCodes,
  resetRecoveryCodesAlertToShow,
} = account.actions;

export const { reducer } = account;
