import { Text, Link, Box } from '@nimbus-ds/components';
import { EditIcon } from '@nimbus-ds/icons';
import { CopyLink } from 'commons/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface TrackingNumberInfoProps {
  urlTrackingCode: string | null;
  trackingNumber: string | null | undefined;
  isDigital: boolean;
  showEdit: boolean;
  onClick: () => void;
}

function TrackingNumberInfo({
  trackingNumber,
  urlTrackingCode,
  isDigital,
  showEdit,
  onClick,
}: TrackingNumberInfoProps) {
  const t = useTranslationOrders();
  return (
    <>
      <Text>
        {isDigital
          ? `${t('cardFulfillment.linkDownload.title')}`
          : `${t('cardFulfillment.trankingNumber.title')}`}
      </Text>
      <Box display="flex" alignItems="center">
        {urlTrackingCode ? (
          <Link as="a" href={urlTrackingCode} target="_blank">
            <Text lineClamp={1} wordBreak="break-all" color="neutral-textLow">
              {trackingNumber}
            </Text>
          </Link>
        ) : (
          <Text lineClamp={1} wordBreak="break-all" color="neutral-textLow">
            {trackingNumber?.trim() || t('cardFulfillment.tracking.empty')}
          </Text>
        )}
        {showEdit && (
          <Box display="flex" marginLeft="2" marginRight="2">
            <Link appearance="primary" textDecoration="none" onClick={onClick}>
              <EditIcon size="small" /> {t('cardFulfillment.tracking.edit')}
            </Link>
          </Box>
        )}
        {!!trackingNumber && (
          <CopyLink
            value={`${trackingNumber}`}
            message={
              isDigital
                ? t('cardFulfillment.linkDownload.copyMessage')
                : t('cardFulfillment.trankingNumber.copyMessage')
            }
            text={
              isDigital
                ? t('cardFulfillment.linkDownload.copy')
                : t('cardFulfillment.trankingNumber.copy')
            }
            iconPosition="start"
          />
        )}
      </Box>
    </>
  );
}

export default TrackingNumberInfo;
