import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  PickupPointDetailResponseDto,
  PickupPointRequestDto,
  PickupPointResponseDto,
} from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import {
  createPickupPointAction,
  deletePickupPointAction,
  getLocationsAsPickupPointsList,
  getOtherPickupPointsList,
  getPickupPointAction,
  getPickupPointDetails,
  getPickupPointsAction,
  getPickupPointsStatus,
  getPickupPointStatus,
  updatePickupPointAction,
  UpdatePickupPointParams,
  updatePickupPointStatusAction,
  UpdatePickupPointStatusParams,
} from 'domains/Shipping/DeliveryMethods/deliveryMethodsSlice';

export interface UsePickupPointsResult {
  locationsAsPickupPoint: PickupPointResponseDto[];
  otherPickupPoints: PickupPointResponseDto[];
  pickupPointDetails: PickupPointDetailResponseDto;
  isErrorDetail: boolean;
  isErrorList: boolean;
  isLoadingDetail: boolean;
  isLoadingList: boolean;
  isSuccessList: boolean;
  isSuccessDetail: boolean;
  fetchPickupPoints: () => Promise<void>;
  fetchPickupPoint: (id: string) => Promise<void>;
  createPickupPoint: (request: PickupPointRequestDto) => Promise<void>;
  updatePickupPoint: (request: UpdatePickupPointParams) => Promise<void>;
  updatePickupPointStatus: (
    request: UpdatePickupPointStatusParams,
  ) => Promise<void>;
  deletePickupPoint: (id: string) => Promise<void>;
}

function usePickupPoints(): UsePickupPointsResult {
  const dispatch = useAppDispatch();
  const {
    isError: isErrorList,
    isLoading: isLoadingList,
    isSuccess: isSuccessList,
  } = useSelector(getPickupPointsStatus);
  const {
    isError: isErrorDetail,
    isLoading: isLoadingDetail,
    isSuccess: isSuccessDetail,
  } = useSelector(getPickupPointStatus);

  const locationsAsPickupPoint = useSelector(getLocationsAsPickupPointsList);
  const otherPickupPoints = useSelector(getOtherPickupPointsList);
  const pickupPointDetails = useSelector(getPickupPointDetails);

  const fetchPickupPoints = useCallback(async () => {
    await dispatch(getPickupPointsAction());
  }, [dispatch]);

  const fetchPickupPoint = useCallback(
    async (id) => {
      await dispatch(getPickupPointAction(id));
    },
    [dispatch],
  );

  const createPickupPoint = useCallback(
    async (request: PickupPointRequestDto) => {
      unwrapResult(await dispatch(createPickupPointAction(request)));
    },
    [dispatch],
  );

  const updatePickupPoint = useCallback(
    async (request: UpdatePickupPointParams) => {
      unwrapResult(await dispatch(updatePickupPointAction(request)));
      fetchPickupPoints();
    },
    [dispatch, fetchPickupPoints],
  );

  const updatePickupPointStatus = useCallback(
    async (request: UpdatePickupPointStatusParams) => {
      unwrapResult(await dispatch(updatePickupPointStatusAction(request)));
    },
    [dispatch],
  );

  const deletePickupPoint = useCallback(
    async (id: string) => {
      unwrapResult(await dispatch(deletePickupPointAction(id)));
      fetchPickupPoints();
    },
    [dispatch, fetchPickupPoints],
  );

  return {
    locationsAsPickupPoint,
    otherPickupPoints,
    pickupPointDetails,
    isErrorDetail,
    isErrorList,
    isLoadingDetail,
    isLoadingList,
    isSuccessList,
    isSuccessDetail,
    fetchPickupPoints,
    fetchPickupPoint,
    createPickupPoint,
    updatePickupPoint,
    updatePickupPointStatus,
    deletePickupPoint,
  };
}

export default usePickupPoints;
