import { GetKycStatusResponseDto } from '@tiendanube/common';
import { cirrusApi } from 'App/rtk';
import { NUVEM_PAGO_FEATURE_FLAG_URL, KYC_STATUS_URL } from './urls';

const NuvemPagoCommonsEndpoints = {
  getKycStatus: 'getKycStatus',
  nuvemPagoFeatureFlag: 'nuvemPagoFeatureFlag',
} as const;

export const nuvemPagoCommonsApi = cirrusApi.injectEndpoints({
  endpoints: (builder) => ({
    [NuvemPagoCommonsEndpoints.getKycStatus]: builder.query<
      GetKycStatusResponseDto,
      void
    >({
      query: () => ({
        method: 'GET',
        url: KYC_STATUS_URL,
      }),
      keepUnusedDataFor: 300,
    }),
    [NuvemPagoCommonsEndpoints.nuvemPagoFeatureFlag]: builder.query<
      boolean,
      string
    >({
      query: (featureFlag: string) => ({
        method: 'GET',
        url: NUVEM_PAGO_FEATURE_FLAG_URL.replace(':flag', featureFlag),
      }),
      keepUnusedDataFor: 60,
    }),
  }),
});

export const { useGetKycStatusQuery, useNuvemPagoFeatureFlagQuery } =
  nuvemPagoCommonsApi;
