import { Box, Text } from '@nimbus-ds/components';
import { CurrencyType, OrderDetailsAmountsDto } from '@tiendanube/common';
import { CurrencyPrice } from 'commons/components';
import { useOrderDiscounts } from 'domains/Orders/Orders/pages/hooks';

interface SummaryBreakdownProps {
  readonly amounts: OrderDetailsAmountsDto;
  readonly paymentMethod: string;
  readonly currency: CurrencyType;
}

function SummaryBreakdown({
  amounts,
  paymentMethod,
  currency,
}: SummaryBreakdownProps) {
  const discounts = useOrderDiscounts(amounts, paymentMethod);

  if (discounts.length === 0) {
    return null;
  }

  return (
    <Box
      padding="2"
      borderTopWidth="1"
      borderStyle="solid"
      borderColor="neutral-surfaceHighlight"
    >
      {discounts.map((discount) => (
        <Box
          key={discount.id}
          display="flex"
          flexDirection="row"
          paddingTop="1"
          paddingBottom="1"
          justifyContent="space-between"
        >
          <Box flex="2" alignItems="center" justifyContent="space-between">
            <Text color="neutral-textHigh">{discount.name}</Text>
          </Box>
          <CurrencyPrice
            appearance={discount.appearance}
            price={discount.value}
            currency={currency}
            preFix={discount.prefix}
            size="base"
          />
        </Box>
      ))}
    </Box>
  );
}

export default SummaryBreakdown;
