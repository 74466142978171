import { useCallback } from 'react';
import { combineStatus } from 'commons/utils/combineStatus';
import {
  useGetRecurrentPaymentConcepts,
  useGetSubscriptions,
} from 'domains/Billing/Checkout/hooks';

export default function useNoChargesMessage() {
  const {
    status: subscriptionsStatus,
    subscriptions,
    refreshSubscriptions,
  } = useGetSubscriptions();
  const {
    status: conceptsStatus,
    refreshRecurrentPaymentConcepts,
    recurrentPaymentConcepts,
  } = useGetRecurrentPaymentConcepts();

  const { isLoading, isError, status } = combineStatus(
    subscriptionsStatus,
    conceptsStatus,
  );

  const retryLoad = useCallback(() => {
    refreshSubscriptions();
    refreshRecurrentPaymentConcepts();
  }, [refreshSubscriptions, refreshRecurrentPaymentConcepts]);

  const subscriptionsWithoutRp = subscriptions?.filter(
    (sub) =>
      !recurrentPaymentConcepts?.some(
        ({ concept, externalReference }) =>
          sub.conceptCode === concept.code &&
          (!externalReference || sub.externalReference === externalReference),
      ),
  );

  const nextExecutionDate =
    subscriptionsWithoutRp?.length &&
    subscriptionsWithoutRp.reduce(
      (min, { nextExecutionDate }) =>
        nextExecutionDate < min ? nextExecutionDate : min,
      subscriptionsWithoutRp[0].nextExecutionDate,
    );

  return {
    nextExecutionDate,
    retryLoad,
    isLoading,
    isError,
    status,
  };
}
