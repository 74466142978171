import { Link, Text } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import goToAdmin from 'commons/utils/gotToAdmin';
import { useGetPlan, useGetPlanExpiresIn } from 'domains/Auth/hooks';
import { trackingBillingPostUpsellAdbarClick } from 'domains/Billing/tracking';
import { AdBar } from './AdBar';

export function PostUpsellPaymentInvitation() {
  const { t } = useTranslation('common');
  const { name: planName } = useGetPlan();
  const expiresIn = useGetPlanExpiresIn();

  if (expiresIn === undefined) return null;

  const goToCheckout = goToAdmin('account/checkout/start/');

  const handleClick = () => {
    trackingBillingPostUpsellAdbarClick();
    goToCheckout();
  };

  const showExpiresInDays = expiresIn > 0;
  const expired = expiresIn <= 0;

  const getAdBarText = () => {
    if (showExpiresInDays) {
      return t('topAdBar.postUpsell.textv2', {
        plan: planName,
        count: expiresIn,
      });
    }
    if (expired) {
      return t('topAdBar.postUpsell.textv2Expired', { plan: planName });
    }
    if (planName) return t('topAdBar.postUpsell.text', { plan: planName });
    return t('topAdBar.postUpsell.textNoPlanName');
  };

  const getAppearance = () => {
    if (expired) return 'danger';
    return 'primary';
  };

  const getColor = () => {
    if (expired) return 'danger-textLow';
    return 'primary-textLow';
  };

  return (
    <AdBar appearence={getAppearance()}>
      <Text fontWeight="bold" color={getColor()}>
        {getAdBarText()}
      </Text>
      <Link onClick={handleClick} appearance="primary" textDecoration="none">
        {t('topAdBar.postUpsell.link')}
      </Link>
    </AdBar>
  );
}
