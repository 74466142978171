import { Fragment, useState } from 'react';
import { Text } from '@nimbus-ds/components';
import { AuthenticationFactorType } from '@tiendanube/common';
import { AuthenticationFactorTypes } from '@tiendanube/common/src/domains/account/authenticationFactorTypes';
import { BaseCard, Title, InterfaceNameValue } from '@tiendanube/components';
import { Input } from 'commons/components';
import CancelAndSaveButtons from 'commons/components/CancelAndSaveButtons';
import Link from 'commons/components/LegacyLink';
import Stack from 'commons/components/Stack';
import { useForm } from 'commons/hooks';
import { removeNonNumericCharacters } from 'domains/Auth/pages/VerificationCodePage/utils';
import { validationSchema } from './validationSchema';
import { useTranslationAuthenticationFactor } from '../../hooks';

export type OnSubmitValues = {
  type: AuthenticationFactorType;
} & VerifyCodeValues;

export interface VerifyCodeValues {
  code: string;
}

const initialValues: VerifyCodeValues = {
  code: '',
};

interface AuthenticationFactorVerifyCodeCardProps {
  title?: string;
  description?: string;
  submitText?: string;
  card?: boolean;
  isLoading: boolean;
  isError: boolean;
  onCancel: () => void;
  onSubmit: (data: OnSubmitValues) => Promise<boolean>;
  onAfterVerify?: (isValid: boolean) => Promise<void>;
  allowToUseRecoveryCodes?: boolean;
}

function AuthenticationFactorVerifyCodeCard({
  title: providerTitle,
  description: providedDescription,
  submitText,
  card,
  isLoading,
  isError,
  onCancel,
  onSubmit,
  onAfterVerify,
  allowToUseRecoveryCodes,
}: AuthenticationFactorVerifyCodeCardProps) {
  const t = useTranslationAuthenticationFactor(
    'authenticationFactorVerifyCodeCard',
  );

  const [authenticationFactor, setAuthenticationFactor] =
    useState<AuthenticationFactorType>(AuthenticationFactorTypes.TOTP);

  const codeTranslationBase =
    authenticationFactor === AuthenticationFactorTypes.TOTP
      ? 'totpCode'
      : 'recoveryCode';

  const {
    errors,
    values,
    isValidating,
    isDirty,
    handleOnChange,
    handleOnSubmit,
    handleOnBlur,
  } = useForm<VerifyCodeValues, VerifyCodeValues>({
    initialValues,
    validationSchema,
    onSubmit: async (data) => {
      const isValid = await onSubmit({
        code: data.code,
        type: authenticationFactor,
      });
      if (!isValid) handleOnChange({ name: 'code', value: '' });
      if (onAfterVerify) return onAfterVerify(isValid);
    },
    validateOnBlur: ['code'],
  });

  const maxLength =
    authenticationFactor === AuthenticationFactorTypes.TOTP ? 6 : 19;

  const buildHelpText = (): string => {
    if (isError) {
      return t(`${codeTranslationBase}.errors.invalid`);
    } else {
      return errors.code ? t(`${codeTranslationBase}.errors.required`) : '';
    }
  };

  const [title, description] = allowToUseRecoveryCodes
    ? [t('recoveryCode.title'), t('recoveryCode.description')]
    : [providerTitle, providedDescription];

  const handleChange = (data: InterfaceNameValue) => {
    const filteredValue =
      authenticationFactor === AuthenticationFactorTypes.TOTP
        ? removeNonNumericCharacters(data.value)
        : data.value;
    handleOnChange({ ...data, value: filteredValue });
  };

  const BaseComponent = card ? BaseCard : Fragment;

  return (
    <Stack column justify="space-between" align="stretch">
      <BaseComponent>
        <BaseCard.Header>
          <Stack column justify="space-between" align="stretch">
            {!!title && <Title type="h3">{title}</Title>}
            <Text fontSize="highlight">{description || t('description')}</Text>
          </Stack>
        </BaseCard.Header>
        <BaseCard.Body>
          <Input
            onChange={handleChange}
            value={values.code}
            name="code"
            type="text"
            required
            maxLength={maxLength}
            appearance={errors.code || isError ? 'danger' : undefined}
            label={t(`${codeTranslationBase}.label`)}
            onBlur={handleOnBlur}
            helpText={buildHelpText()}
          />

          {allowToUseRecoveryCodes &&
            authenticationFactor === AuthenticationFactorTypes.TOTP && (
              <Link
                appearance="primary"
                onClick={() =>
                  setAuthenticationFactor(
                    AuthenticationFactorTypes.RECOVERY_CODE,
                  )
                }
              >
                {t('recoveryCodesLink')}
              </Link>
            )}
        </BaseCard.Body>
      </BaseComponent>
      <CancelAndSaveButtons
        saveText={submitText || t('buttons.submit')}
        onSave={handleOnSubmit}
        onCancel={onCancel}
        isDisabled={!isDirty || isValidating || isLoading}
        isLoading={isLoading}
      />
    </Stack>
  );
}

export default AuthenticationFactorVerifyCodeCard;
