import { Button, Pagination } from '@nimbus-ds/components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Pagination as PaginationOld,
  Stack,
  Text,
} from '@tiendanube/components';
import { usePaginationStratus } from 'commons/hooks';
import './PaginationStratus.scss';

interface PaginationStratusProps {
  itemName: string;
  page: number;
  totalItems: number;
  perPage: number;
  currentPage: number;
  pageLimit?: number;
  onPageSelect: (page: number) => void;
  urlToBuild?: string;
}

function PaginationStratus({
  itemName,
  totalItems,
  page,
  perPage,
  currentPage,
  pageLimit,
  onPageSelect,
  // Note: This property is used so that the buttons on the page are links, for this the url to which they will be redirected is passed.
  urlToBuild,
}: PaginationStratusProps): JSX.Element {
  const { t } = useTranslation('common');
  const {
    firstItem: firstRecordPage,
    lastItem: lastRecordPage,
    totalPages,
  } = usePaginationStratus({
    perPage,
    totalItems,
    currentPage,
  });

  const isLastPage = pageLimit && page === pageLimit;

  const className = classNames('stratus--pagination', {
    'stratus--pagination-with-page-limit': isLastPage,
  });

  const pageTotal = pageLimit
    ? Math.min(isLastPage ? pageLimit + 1 : pageLimit, totalPages)
    : totalPages;

  return (
    <div className={className}>
      <Stack justify="space-between">
        <Stack.Item>
          <Text>
            {t('pagination.total', {
              firstRecordPage,
              lastRecordPage,
              totalItems,
              itemName,
            })}
          </Text>
        </Stack.Item>
        <Stack.Item>
          {totalItems > perPage &&
            (urlToBuild ? (
              <Pagination
                activePage={page}
                pageCount={totalPages}
                onPageChange={onPageSelect}
                renderItem={({ isCurrent, pageNumber }) => (
                  <Button
                    as="a"
                    href={
                      '/admin/v2/' +
                      urlToBuild?.replace(
                        '%7BpageNumber%7D',
                        String(pageNumber),
                      )
                    }
                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                      if (e.ctrlKey || e.metaKey) return;
                      e.preventDefault();
                      onPageSelect(+pageNumber);
                    }}
                    data-testid={`button-pagination-page-${pageNumber}`}
                    appearance={isCurrent ? 'primary' : 'transparent'}
                  >
                    {pageNumber}
                  </Button>
                )}
              />
            ) : (
              <PaginationOld
                pageSelected={page}
                pageTotal={pageTotal}
                onPageSelect={onPageSelect}
              />
            ))}
        </Stack.Item>
      </Stack>
    </div>
  );
}

export default PaginationStratus;
