import { Link, Button, LinkProps, ButtonProps } from '@nimbus-ds/components';
import { Link as LinkRoute } from 'react-router-dom';
import { PATH } from 'App/components/Menu/components/utils';
import { useIsInAppBrowser } from 'App/hooks';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import useHasMigratedToBillingEngine from 'domains/Billing/hooks/useHasMigratedToBillingEngine';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface GoToPayPlanLinkProps {
  as?: 'link' | 'button';
  text?: string;
  appearance?: LinkProps['appearance'] & ButtonProps['appearance'];
  onClick?: () => void;
  hideAdBars?: boolean;
}

const hideAdBarsParams = '?hideAdBars=true';

function GoToPayPlanLink({
  as = 'link',
  text,
  appearance = 'neutral',
  onClick,
  hideAdBars = false,
}: Readonly<GoToPayPlanLinkProps>) {
  const t = useTranslationBilling('checkout.paymentStatusAlert');
  const isMobileDevice = useIsMobileDevice();
  const isInAppBrowserOpen = useIsInAppBrowser();
  const { isEnabled, isSuccess } = useHasMigratedToBillingEngine();

  if (isMobileDevice || isInAppBrowserOpen || !isSuccess) return null;

  const oldAdminPaymentAndSuscription =
    '/admin' + PATH.paymentsAndSubscriptions;
  const to = `${CHECKOUT_ROUTES.plansAndSubscriptions}${
    hideAdBars ? hideAdBarsParams : ''
  }`;

  if (as === 'button')
    return (
      <Button
        as={isEnabled ? LinkRoute : 'a'}
        to={to}
        href={oldAdminPaymentAndSuscription}
        appearance={appearance}
        onClick={onClick}
      >
        {text || t('goToPayPlan')}
      </Button>
    );

  return (
    <Link
      as={isEnabled ? LinkRoute : 'a'}
      to={to}
      href={oldAdminPaymentAndSuscription}
      appearance={appearance}
      onClick={onClick}
    >
      {text || t('goToPayPlan')}
    </Link>
  );
}

export default GoToPayPlanLink;
