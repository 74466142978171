import { ClockIcon } from '@tiendanube/icons';
import Link from 'commons/components/LegacyLink';
import { dateFormat, Format } from 'commons/utils/date';
import { useTranslationStatistics } from 'domains/Statistics/hooks';

interface LastUpdateProps {
  lastUpdate: string;
}

function LastUpdate({ lastUpdate }: LastUpdateProps) {
  const t = useTranslationStatistics();
  return (
    <Link icon={ClockIcon} appearance="default">
      {t('statistics.lastUpdate', {
        date: dateFormat(lastUpdate, Format.DD_MM_YYYY),
        time: dateFormat(lastUpdate, Format.HOUR_MINUTE_XS),
      })}
    </Link>
  );
}

export default LastUpdate;
