import { useEffect, useMemo, useState } from 'react';
import { Alert, Box, Text } from '@nimbus-ds/components';
import { Layout } from '@nimbus-ds/patterns';
import { flushSync } from 'react-dom';
import { Prompt } from 'react-router';
import { useParams } from 'react-router-dom';
import { ErrorState } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import {
  CancelAndConfirmButtons,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import { trackingOrderEditSaveClick } from 'domains/Orders/tracking';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import {
  EditOrderHelpLink,
  EditOrderReason,
  EditOrderSummary,
} from './components';
import { EditOrderProducts } from './components/Products';
import { useEditOrder, useShippingCosts } from './hooks';
import { OrderEditPageHeader } from './OrderEditPageHeader';
import { OrderEditPageSkeleton } from './OrderEditPageSkeleton';

function OrderEditPage() {
  const { id } = useParams<{ id: string }>();
  const { goBack, goTo } = useNavegate();
  const t = useTranslationOrders();
  const [hasSucceeded, setHasSucceeded] = useState(false);
  const {
    isLoading,
    isError,
    editedOrderProducts,
    setEditedOrderProducts,
    nonDeletedEditedProducts,
    orderDetails,
    isOrderEdited,
    setIsOrderEdited,
    isValidEdition,
    editReason,
    setEditReason,
    editOrder,
    editStatus,
    isLoadingEdit,
    cleanEditState,
    notifyCustomer,
    setNotifyCustomer,
  } = useEditOrder(id);

  useToastStatus({
    status: editStatus,
    success: t('editOrders.toast.success'),
    progress: t('editOrders.toast.loading'),
    error: t('editOrders.toast.error'),
  });

  useEffect(() => {
    if (!orderDetails) {
      return;
    }

    if (!orderDetails.isEditable) {
      goTo(`/orders/${id}`);
    }
  }, [orderDetails, goTo, id]);

  const handleNotifyCustomer = () => {
    setNotifyCustomer(!notifyCustomer);
  };

  const { isLoadingAny: isLoadingShippingCosts } = useShippingCosts(undefined);

  const isSubmitDisabled = useMemo(
    () =>
      !(isOrderEdited && isValidEdition) ||
      isLoadingEdit ||
      isLoadingShippingCosts,
    [isOrderEdited, isValidEdition, isLoadingEdit, isLoadingShippingCosts],
  );

  const handleSubmit = async () => {
    try {
      trackingOrderEditSaveClick(editReason, notifyCustomer);
      await editOrder(editedOrderProducts, editReason, notifyCustomer);
      /**
       *  We need to wrap this setState into flushSync after update React to v18.
       *  Using flushSync is uncommon and can hurt the performance of the app.
       *  It must be used only when there is no other alternative.
       *  Please, contact new-admin team if you consider its use is necessary.
       *  Docs: https://react.dev/reference/react-dom/flushSync
       **/
      flushSync(() => {
        setHasSucceeded(true);
      });
      goTo(`/orders/${id}`);
    } finally {
      cleanEditState();
    }
  };

  const alertSubtitle = useMemo(() => t('editOrders.notice.subtitleApps'), [t]);

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={{ onClick: goBack }} />}
      headerContent={
        <OrderEditPageHeader isError={isError} orderDetails={orderDetails} />
      }
      width="medium"
    >
      <Prompt
        when={isOrderEdited && !hasSucceeded}
        message={t('common:exitEdit.info')}
      />
      {isError && <ErrorState title={t('errorPage.message')} />}
      {isLoading && <OrderEditPageSkeleton />}
      {!isLoading && !isError && orderDetails && (
        <Box>
          <Alert appearance="warning">
            <Text color="warning-textLow">{alertSubtitle}</Text>
          </Alert>
          <Box paddingY="4">
            <Layout columns="2 - asymmetric">
              <Layout.Section>
                <Box display="block" width="100%">
                  {orderDetails?.fulfillmentOrders &&
                  orderDetails?.fulfillmentOrders.length > 0 ? (
                    <>
                      {orderDetails.fulfillmentOrders.map(
                        (fulfillmentOrder) => (
                          <EditOrderProducts
                            key={fulfillmentOrder.number}
                            orderDetails={orderDetails}
                            products={editedOrderProducts}
                            currency={orderDetails.currency}
                            setEditedOrderProducts={setEditedOrderProducts}
                            isOrderEdited={isOrderEdited}
                            setIsOrderEdited={setIsOrderEdited}
                            fulfillmentOrder={fulfillmentOrder}
                          />
                        ),
                      )}
                    </>
                  ) : (
                    <EditOrderProducts
                      orderDetails={orderDetails}
                      products={editedOrderProducts}
                      currency={orderDetails.currency}
                      setEditedOrderProducts={setEditedOrderProducts}
                      isOrderEdited={isOrderEdited}
                      setIsOrderEdited={setIsOrderEdited}
                    />
                  )}
                  <EditOrderReason
                    reason={editReason}
                    disabled={!isOrderEdited}
                    onChange={setEditReason}
                  />
                </Box>
              </Layout.Section>
              <Layout.Section>
                <Box display="flex" flexDirection="column" width="100%">
                  <Box marginBottom="4">
                    <EditOrderSummary
                      orderAmounts={orderDetails.amounts}
                      products={nonDeletedEditedProducts}
                      currency={orderDetails.currency}
                      paymentMethod={orderDetails.payment.method}
                      isOrderEdited={isOrderEdited}
                      notifyCustomer={notifyCustomer}
                      handleNotifyCustomer={handleNotifyCustomer}
                      fulfillments={orderDetails.fulfillmentOrders}
                    />
                  </Box>
                  <CancelAndConfirmButtons
                    onCancel={goBack}
                    onConfirm={handleSubmit}
                    isConfirmDisabled={isSubmitDisabled}
                    isLoading={isLoading}
                  />
                </Box>
              </Layout.Section>
            </Layout>
          </Box>
          <EditOrderHelpLink />
        </Box>
      )}
    </IonPageStratus>
  );
}

export default OrderEditPage;
