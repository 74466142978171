import { useEffect } from 'react';
import {
  CurrencyType,
  FulfillmentOrdersResponseDto,
  OrderDetailsAmountsDto,
  RefundAttemptDto,
} from '@tiendanube/common';
import { OrderDetailsTaxesResponseDto } from '@tiendanube/common/src/domains/orders/orders/OrderDetailsResponse';
import { DataList, Text } from '@tiendanube/components';
import FeatureFlag from 'App/components/FeatureFlag';
import { TOTAL_PAID_ORDER_DETAIL } from 'App/featuresFlags';
import { CurrencyPrice } from 'commons/components';
import { useBusinessData } from 'domains/Account/hooks';
import { useIsEditOrdersEnabled } from 'domains/Orders/Orders/hooks';
import { useOrderDiscounts } from 'domains/Orders/Orders/pages/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import ShippingCosts from './components/ShippingCosts';
import { OrderTotalAmountRow } from '../../../../../../components';

interface OrderSummaryAmountsProps {
  amounts: OrderDetailsAmountsDto;
  taxes?: OrderDetailsTaxesResponseDto;
  billingAddressCountry?: string;
  refundAttempts: RefundAttemptDto[];
  currency: CurrencyType;
  paymentMethod: string;
  fulfillmentOrders?: FulfillmentOrdersResponseDto[];
  totalProducts: number;
  isEdited?: boolean;
}

function CardPaymentAmounts({
  amounts,
  paymentMethod,
  taxes,
  refundAttempts,
  currency,
  billingAddressCountry,
  fulfillmentOrders,
  totalProducts,
  isEdited,
}: OrderSummaryAmountsProps): JSX.Element {
  const {
    subtotal,
    total,
    shipping,
    previousShippingOwner,
    shippingDetail,
    totalPaid,
    otherCosts,
    paidByCustomer,
    totalDiffPending,
  } = amounts;

  const t = useTranslationOrders();
  const hasEditOrders = useIsEditOrdersEnabled();
  const { fetchBusinessData, businessDataValues } = useBusinessData();

  const discounts = useOrderDiscounts(amounts, paymentMethod);

  useEffect(() => {
    fetchBusinessData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isTotalUpdated = () =>
    (isEdited && total !== paidByCustomer && paidByCustomer > 0) || false;

  const normalizedShipping = shipping ?? 0;
  return (
    <>
      <DataList.Row grayed id="subtotal">
        <DataList.Cell width={70}>
          <Text>{`${t('orderSummaryCard.tableAmounts.subtotal', {
            count: totalProducts,
          })}`}</Text>
        </DataList.Cell>
        <DataList.Cell width={30}>
          <CurrencyPrice
            price={subtotal}
            currency={currency}
            block
            textAlign="right"
          />
        </DataList.Cell>
      </DataList.Row>
      {(normalizedShipping >= 0 || !!fulfillmentOrders) && (
        <ShippingCosts
          shippingDetail={shippingDetail}
          shipping={normalizedShipping}
          previousShipping={previousShippingOwner}
          currency={currency}
          fulfillmentOrders={fulfillmentOrders}
        />
      )}

      {discounts.map((discount) => (
        <DataList.Row id={discount.id} key={discount.id}>
          <DataList.Cell width={70}>
            <Text>{discount.name}</Text>
          </DataList.Cell>
          <DataList.Cell width={30}>
            <CurrencyPrice
              price={discount.value}
              currency={currency}
              block
              textAlign="right"
              preFix={discount.prefix}
              appearance={discount.appearance}
            />
          </DataList.Cell>
        </DataList.Row>
      ))}

      <OrderTotalAmountRow
        isEdited={isEdited || false}
        isTotalUpdated={isTotalUpdated()}
        total={total}
        refundAttempts={refundAttempts}
        currency={currency}
        billingAddressCountry={billingAddressCountry}
        businessDataValues={businessDataValues}
        taxes={taxes}
      />

      {hasEditOrders && isEdited && (
        <>
          <DataList.Row id="paidBycustomer">
            <DataList.Cell width={70}>
              <Text>{t('orderSummaryCard.tableAmounts.paidBycustomer')}</Text>
            </DataList.Cell>
            <DataList.Cell width={30}>
              <CurrencyPrice
                price={paidByCustomer}
                currency={currency}
                block
                textAlign="right"
              />
            </DataList.Cell>
          </DataList.Row>

          <DataList.Row id="totalDiff" grayed>
            <DataList.Cell width={70}>
              <Text bold>
                {totalDiffPending < 0
                  ? t('orderSummaryCard.tableAmounts.feeToPay')
                  : t('orderSummaryCard.tableAmounts.feeToCharge')}
              </Text>
            </DataList.Cell>
            <DataList.Cell width={30}>
              <CurrencyPrice
                price={Math.abs(totalDiffPending)}
                currency={currency}
                block
                textAlign="right"
                bold
              />
            </DataList.Cell>
          </DataList.Row>
        </>
      )}

      <FeatureFlag flag={TOTAL_PAID_ORDER_DETAIL}>
        {!!totalPaid && !!otherCosts && (
          <>
            <DataList.Row id="otherCosts">
              <DataList.Cell width={70}>
                <Text>{`${t(
                  'orderSummaryCard.tableAmounts.otherCosts',
                )}`}</Text>
              </DataList.Cell>
              <DataList.Cell width={30}>
                <CurrencyPrice
                  price={otherCosts}
                  currency={currency}
                  block
                  textAlign="right"
                />
              </DataList.Cell>
            </DataList.Row>

            <DataList.Row id="totalPaid" grayed>
              <DataList.Cell width={70}>
                <Text bold>{`${t(
                  'orderSummaryCard.tableAmounts.totalPaid',
                )}`}</Text>
              </DataList.Cell>
              <DataList.Cell width={30}>
                <CurrencyPrice
                  price={totalPaid}
                  currency={currency}
                  block
                  textAlign="right"
                  bold
                />
              </DataList.Cell>
            </DataList.Row>
          </>
        )}
      </FeatureFlag>
    </>
  );
}
export default CardPaymentAmounts;
