import { Text, Button } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { AlertSessionStorage } from 'App/components';
import { CATALOG_INVENTORY } from 'App/featuresFlags';
import { useHasTags, useNavegate } from 'App/hooks';

function AlertStockInventory() {
  const { t } = useTranslation([Domain.CATALOG]);
  const { goTo } = useNavegate();

  const title = t('products.alertStockInventory.title');
  const description = t('products.alertStockInventory.description');
  const goToInventory = () => goTo('/inventory/');
  const button = t('products.alertStockInventory.button');
  const [show] = useHasTags(CATALOG_INVENTORY);

  return (
    <AlertSessionStorage
      show={show}
      appearance="primary"
      title={title}
      keyName="showInventoryManagementAlert"
    >
      <Text>{description}</Text>
      <Button onClick={goToInventory}>{button}</Button>
    </AlertSessionStorage>
  );
}
export default AlertStockInventory;
