import { useNavegate } from 'App/hooks';
import { HeaderContent, HeaderTop, IonPageStratus } from 'commons/components';
import Stack from 'commons/components/Stack';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import LinkCard from './LinkCard';
import { DELIVERY_METHODS_ROUTES } from '../../shippingRoutes';

function ConfigureCorreiosPage(): JSX.Element {
  const { goBack, goTo } = useNavegate();
  const t = useTranslationShipping();

  const backNavigation = {
    children: t('correios.headerBack'),
    onClick: goBack,
  };

  const handleGoToCorreiosShippingModalities = () =>
    goTo(DELIVERY_METHODS_ROUTES.correiosModalities);

  const handleGoToCorreiosContract = () =>
    goTo(DELIVERY_METHODS_ROUTES.correiosContract);

  return (
    <IonPageStratus
      width="small"
      headerTop={<HeaderTop navigation={backNavigation} />}
      headerContent={<HeaderContent title={t('correios.headerTitle')} />}
    >
      <Stack column>
        <LinkCard
          title={t('correios.shippingModalitiesCard.title')}
          text={t('correios.shippingModalitiesCard.text')}
          linkText={t('correios.shippingModalitiesCard.linkText')}
          onLinkPressed={handleGoToCorreiosShippingModalities}
        />

        <LinkCard
          title={t('correios.contractCard.title')}
          text={t('correios.contractCard.text')}
          linkText={t('correios.contractCard.linkText')}
          onLinkPressed={handleGoToCorreiosContract}
        />
      </Stack>
    </IonPageStratus>
  );
}

export default ConfigureCorreiosPage;
