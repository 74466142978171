import { ChangeEvent } from 'react';
import { Card, Checkbox, Radio, Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { Stack, InputNumberNimbus } from 'commons/components';
import { useAutoFocus } from 'commons/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import Skeleton from './Skeleton';
import { DeliveryCostType } from '../../DeliveryMethodsPage/components/CustomShippingForm/CustomShippingForm';

interface ShippingInformationCardProps {
  name: string;
  deliveryCost?: DeliveryCostType;
  cost?: string;
  showAsPickUpPoint?: boolean;
  allowedForFreeShipping?: boolean;
  error?: string;
  isShowAsPickUpPoint?: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function ShippingInformationCard({
  name,
  deliveryCost,
  cost = '',
  showAsPickUpPoint = false,
  allowedForFreeShipping = false,
  error,
  onChange,
  isShowAsPickUpPoint = true,
}: ShippingInformationCardProps): JSX.Element {
  const t = useTranslationShipping();
  const { ref } = useAutoFocus();

  const options = [
    {
      label: t(
        'deliveryMethods.customShipping.informationCard.withdrawal.optionWithCost',
      ),
      value: 'with-cost',
    },
    {
      label: t(
        'deliveryMethods.customShipping.informationCard.withdrawal.optionFree',
      ),
      value: 'free',
    },
    {
      label: t(
        'deliveryMethods.customShipping.informationCard.withdrawal.optionToCombine',
      ),
      value: 'to-be-arranged',
    },
  ];

  const optionDeliveryFree =
    deliveryCost === 'to-be-arranged' || deliveryCost === 'with-cost';

  return (
    <Card>
      <Card.Header
        title={t('deliveryMethods.customShipping.informationCard.title')}
      />
      <Card.Body>
        <Stack column align="stretch" innerRef={ref}>
          <Stack.Item>
            <FormField.Input
              label={t(
                'deliveryMethods.customShipping.informationCard.shippingData.name',
              )}
              name="name"
              value={name}
              onChange={onChange}
              showHelpText={!!error && !name}
              helpText={error ? t(error) : undefined}
              appearance={!!error && !name ? 'danger' : 'none'}
            />
          </Stack.Item>
          <Stack.Item>
            <FormField
              label={t(
                'deliveryMethods.customShipping.informationCard.withdrawal.label',
              )}
            >
              <Stack spacing="tight">
                {options.map((option) => (
                  <Radio
                    key={option.value}
                    id={option.value}
                    label={option.label}
                    name="deliveryCost"
                    value={option.value}
                    onChange={onChange}
                    as="button"
                    checked={deliveryCost === option.value}
                  />
                ))}
              </Stack>
            </FormField>
          </Stack.Item>
          {deliveryCost === 'with-cost' && (
            <Stack.Item>
              <FormField
                label={t(
                  'deliveryMethods.customShipping.informationCard.defineValue.label',
                )}
              >
                <InputNumberNimbus
                  type="float"
                  name="cost"
                  value={cost}
                  onChange={onChange}
                  append={t(
                    'deliveryMethods.customShipping.informationCard.defineValue.currency',
                  )}
                />
              </FormField>
            </Stack.Item>
          )}
          {optionDeliveryFree && (
            <Stack.Item>
              <Checkbox
                checked={allowedForFreeShipping}
                name="allowedForFreeShipping"
                label={t(
                  'deliveryMethods.customShipping.informationCard.productShippingFree.description',
                )}
                onChange={onChange}
              />
            </Stack.Item>
          )}
          {isShowAsPickUpPoint && (
            <>
              <Checkbox
                checked={showAsPickUpPoint}
                name="showAsPickUpPoint"
                label={t(
                  'deliveryMethods.customShipping.informationCard.markStartingPoint.description',
                )}
                onChange={onChange}
              />
              <Text fontSize="caption">
                {t(
                  'deliveryMethods.customShipping.informationCard.StartingPointAlert.description',
                )}
              </Text>
            </>
          )}
        </Stack>
      </Card.Body>
    </Card>
  );
}

ShippingInformationCard.Skeleton = Skeleton;

export default ShippingInformationCard;
