import { ReactNode } from 'react';
import { Link } from '@tiendanube/components';
import { CopyIcon } from '@tiendanube/icons';
import { useClipboard } from 'commons/hooks';
import { trackingOrdersCopyShippingLinkButtonClick } from 'domains/Orders/tracking';

interface CopyLinkProps {
  value: string;
  message: string;
  text: string | ReactNode;
  iconPosition?: 'start' | 'end';
  size?: 'base' | 'small';
}

function CopyLink({
  value,
  message,
  text,
  iconPosition = 'end',
  size,
}: CopyLinkProps): JSX.Element {
  const copyToClipboard = useClipboard();

  const handleClickCopy = () => {
    copyToClipboard(value, message);
    trackingOrdersCopyShippingLinkButtonClick();
  };
  return (
    <Link
      onClick={handleClickCopy}
      icon={CopyIcon}
      iconPosition={iconPosition}
      appearance="primary"
      size={size}
    >
      {text}
    </Link>
  );
}

export default CopyLink;
