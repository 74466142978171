import { Icon, Link } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { HelpLink } from '@nimbus-ds/patterns';
import { CAN_LINK_ABOUT_ORDERS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

export function OrderHelpLink() {
  const t = useTranslationOrders();
  const language = useTranslationLanguage();

  return (
    <HelpLink>
      <Link
        as="a"
        href={CAN_LINK_ABOUT_ORDERS[language]}
        target="_blank"
        appearance="primary"
        textDecoration="none"
      >
        {t('helpText')}
        <Icon source={<ExternalLinkIcon />} color="currentColor" />
      </Link>
    </HelpLink>
  );
}
