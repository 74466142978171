import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { Stack } from '@tiendanube/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { ScopeSelector } from './ScopeSelector';
import { PromotionFormProps } from '../PromotionForm';

type ApplyToCardProps = Pick<
  PromotionFormProps,
  | 'mode'
  | 'values'
  | 'errors'
  | 'onChange'
  | 'onChangeCategories'
  | 'onChangeProducts'
>;

export function ApplyToCard({
  mode,
  values,
  errors,
  onChange,
  onChangeCategories,
  onChangeProducts,
}: Readonly<ApplyToCardProps>) {
  const t = useTranslationMarketing('promotions.settingsPage.applyTo');
  return (
    <Card>
      <Card.Header>
        <Stack spacing="none" justify="space-between" align="center">
          <Stack spacing="tight">
            <Title as="h4">{t('title')}</Title>
            <Text>{t('optional')}</Text>
          </Stack>
        </Stack>
      </Card.Header>
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="4">
          <ScopeSelector
            disabled={mode === 'edit'}
            values={values}
            errors={errors}
            onChange={onChange}
            onChangeCategories={onChangeCategories}
            onChangeProducts={onChangeProducts}
          />
        </Box>
      </Card.Body>
    </Card>
  );
}

export default ApplyToCard;
