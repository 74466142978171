import { useCallback } from 'react';
import {
  EditIcon,
  ForbiddenIcon,
  MailIcon,
  MoneyIcon,
  UndoIcon,
} from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import {
  LogEntryResponseDto,
  FulfillmentResponseDto,
  FulfillmentOrdersResponseDto,
} from '@tiendanube/common';
import {
  Domain as DomainEnum,
  FulfillmentPreferenceType as FulfillmentPreferenceTypeEnum,
  Log as LogEnum,
  LogType,
  Status,
} from '@tiendanube/common/src/enums';
import { InterfaceTimelineItem } from '@tiendanube/components';
import {
  ArchiveIcon,
  BoxPackedIcon,
  StoreIcon,
  TruckIcon,
} from '@tiendanube/icons';
import { AppearanceColorEnum } from 'commons/enums';
import { dateFormat, Format } from 'commons/utils/date';
import { useFormatCurrency } from 'domains/Billing/hooks';
import getIcon from 'domains/Orders/Orders/pages/getIcon';

interface CardTimelineProps {
  fulfillment: FulfillmentResponseDto;
  fulfillmentOrders?: FulfillmentOrdersResponseDto[];
  isShippablePickup: boolean;
}

function useCardTimeline({
  fulfillment,
  fulfillmentOrders,
  isShippablePickup,
}: CardTimelineProps) {
  const { t } = useTranslation([DomainEnum.ORDERS]);
  const formatCurrency = useFormatCurrency();

  // TODO: Add user_id and app_id to the logEntries
  const timelineProps = useCallback(
    ({
      data2: action,
      data3: actionable,
      action: extraAction,
      meta,
      happenedAt,
      userName,
      type,
    }: LogEntryResponseDto): InterfaceTimelineItem => {
      const date = dateFormat(happenedAt, Format.DAY_MONTH);
      const time = dateFormat(happenedAt, Format.HOUR_MINUTE_XS);

      const fulfillmentOrder =
        Array.isArray(fulfillmentOrders) && actionable
          ? fulfillmentOrders.find((ffo) => String(ffo.number) === actionable)
          : undefined;
      const isDigital = fulfillmentOrder?.shipping?.type === 'non-shippable';

      const isPickupNoShippable =
        fulfillment.preference.type === FulfillmentPreferenceTypeEnum.PICKUP &&
        !isShippablePickup;

      const getTitle = (actionType: string, packageNumber?: string) =>
        t(`cardTimeline.fulfillment.tracking_info.${actionType}`, {
          packageNumber: packageNumber?.padStart(2, '0'),
        });

      if (type === LogType.EDIT) {
        return {
          icon: EditIcon,
          appearance: AppearanceColorEnum.PRIMARY,
          title: t('cardTimeline.edit.title'),
          description: userName
            ? t('cardTimeline.byUserName', { userName })
            : undefined,
          date,
          time,
        };
      }

      if (type === LogType.FULFILLMENT_TRACKING_INFO_ADD) {
        const actionEvent = isDigital ? 'digital-add' : 'add';
        return {
          title: getTitle(actionEvent, actionable),
          description: userName
            ? t('cardTimeline.byUserName', { userName })
            : undefined,
          date,
          time,
        };
      }

      if (type === LogType.FULFILLMENT_TRACKING_INFO_EDIT) {
        const actionEvent = isDigital ? 'digital-edit' : 'edit';
        return {
          title: getTitle(actionEvent, actionable),
          description: userName
            ? t('cardTimeline.byUserName', { userName })
            : undefined,
          date,
          time,
        };
      }

      if (type === LogType.TRANSACTION) {
        return {
          icon: action === 'refunded' ? MoneyIcon : undefined,
          title: t(`cardTimeline.transaction.${action}`),
          description: userName
            ? t('cardTimeline.byUserName', { userName })
            : undefined,
          date,
          time,
        };
      }

      switch (action) {
        case LogEnum.PAID:
          return {
            icon: MoneyIcon,
            appearance: AppearanceColorEnum.SUCCESS,
            title: t(`cardTimeline.log.${action}`),
            description: userName
              ? t('cardTimeline.byUserName', { userName })
              : undefined,
            date,
            time,
          };
        case LogEnum.VOIDED:
          return {
            icon: MoneyIcon,
            appearance: AppearanceColorEnum.DANGER,
            title: t(`cardTimeline.log.${action}`),
            description: userName
              ? t('cardTimeline.byUserName', { userName })
              : undefined,
            date,
            time,
          };
        case LogEnum.UNFULFILLED:
          return {
            icon: BoxPackedIcon,
            appearance: AppearanceColorEnum.PRIMARY,
            title: t(`cardTimeline.log.${action}`),
            description: userName
              ? t('cardTimeline.byUserName', { userName })
              : undefined,
            date,
            time,
          };
        case LogEnum.FULFILLED:
          return {
            icon:
              fulfillment.preference.type ===
              FulfillmentPreferenceTypeEnum.PICKUP
                ? StoreIcon
                : TruckIcon,
            appearance: AppearanceColorEnum.SUCCESS,
            title: `${t(
              `cardTimeline.log.${action}-${
                isShippablePickup ? 'delivery' : fulfillment.preference.type
              }`,
            )}`,
            description: userName
              ? t('cardTimeline.byUserName', { userName })
              : undefined,
            date,
            time,
          };
        case LogEnum.CLOSED:
          return {
            icon: ArchiveIcon,
            appearance: AppearanceColorEnum.DEFAULT,
            title: t(`cardTimeline.log.${action}`),
            description: userName
              ? t('cardTimeline.byUserName', { userName })
              : undefined,
            date,
            time,
          };
        case LogEnum.CANCELLED:
          return {
            icon: ForbiddenIcon,
            appearance: AppearanceColorEnum.DEFAULT,
            title: t(`cardTimeline.log.${action}`),
            description: userName
              ? t('cardTimeline.byUserName', { userName })
              : undefined,
            date,
            time,
          };
        case LogEnum.MARKED_AS_FULFILLED:
        case LogEnum.DISPATCHED:
          return {
            icon: getIcon(
              action as unknown as Status,
              fulfillment.preference.type,
            ),
            appearance: AppearanceColorEnum.SUCCESS,
            title: t(
              `cardTimeline.fulfillment.${action}-${fulfillment.preference.type}`,
              {
                packageNumber: actionable?.padStart(2, '0'),
              },
            ),
            description: userName
              ? t('cardTimeline.byUserName', { userName })
              : undefined,
            date,
            time,
          };
        case LogEnum.DELIVERED: {
          const packageNumber = actionable?.padStart(2, '0');
          const description = userName
            ? t('cardTimeline.byUserName', { userName })
            : undefined;

          if (isDigital) {
            return {
              icon: MailIcon,
              appearance: AppearanceColorEnum.SUCCESS,
              title: t(
                `cardTimeline.fulfillment.${action}-${fulfillment.preference.type}-non-shippable`,
                {
                  packageNumber,
                },
              ),
              description,
              date,
              time,
            };
          }

          const isPickup =
            fulfillment.preference.type ===
            FulfillmentPreferenceTypeEnum.PICKUP;
          const asignIcon =
            isPickup && !isShippablePickup
              ? StoreIcon
              : getIcon(
                  action as unknown as Status,
                  fulfillment.preference.type,
                );

          return {
            icon: asignIcon,
            appearance: AppearanceColorEnum.SUCCESS,
            title: t(
              `cardTimeline.fulfillment.${action}-${fulfillment.preference.type}`,
              { packageNumber },
            ),
            description,
            date,
            time,
          };
        }
        case LogEnum.READY_FOR_PICKUP: {
          const titleAction = isPickupNoShippable ? 'packed' : action;
          return {
            icon: isPickupNoShippable ? BoxPackedIcon : undefined,
            appearance: AppearanceColorEnum.PRIMARY,
            title: t(`cardTimeline.fulfillment.${titleAction}`, {
              packageNumber: actionable?.padStart(2, '0'),
            }),
            description: userName
              ? t('cardTimeline.byUserName', { userName })
              : undefined,
            date,
            time,
          };
        }
        case LogEnum.PACKED:
          return {
            icon: BoxPackedIcon,
            appearance: AppearanceColorEnum.PRIMARY,
            title: t(`cardTimeline.fulfillment.${action}`, {
              packageNumber: actionable?.padStart(2, '0'),
            }),
            description: userName
              ? t('cardTimeline.byUserName', { userName })
              : undefined,
            date,
            time,
          };
        case LogEnum.CREATED:
          return {
            title: t(`cardTimeline.log.${action}`),
            description: userName
              ? t('cardTimeline.byUserName', { userName }) +
                ' ' +
                t(`cardTimeline.storeFront.${actionable}`)
              : undefined,
            date,
            time,
          };
        case LogEnum.OPEN:
        case LogEnum.REFUNDED:
        case LogEnum.READ:
        case LogEnum.NOTE_CREATED:
        case LogEnum.UPDATED:
          return {
            title: t(`cardTimeline.log.${action}`),
            description: userName
              ? t('cardTimeline.byUserName', { userName })
              : undefined,
            date,
            time,
          };
        case LogEnum.REASON:
          return {
            title: actionable
              ? t(`cardTimeline.edit.${action}`, { reason: actionable })
              : '',
            description: t(`cardTimeline.edit.reason_title`),
            date: '',
            time: '',
          };
        case LogEnum.NOTIFICATION:
          return {
            title: actionable
              ? t('cardTimeline.edit.notification', {
                  notification: actionable,
                })
              : '',
            date: '',
            time: '',
          };
        case LogEnum.ADD:
        case LogEnum.REMOVE:
        case LogEnum.REMOVE_STOCK:
          return {
            title: actionable ?? '',
            description: t(`cardTimeline.edit.${action}`, {
              count: Number(extraAction),
            }),
            date: '',
            time: '',
          };
        case LogEnum.SHIPPING_COST: {
          const costs = extraAction.split('|');
          return {
            title: t(`cardTimeline.edit.${action}`, {
              previousCost: formatCurrency(Number(costs[0])),
              actualCost: formatCurrency(Number(costs[1])),
              number: actionable,
            }),
            description: userName
              ? t('cardTimeline.byUserName', { userName })
              : undefined,
            date: '',
            time: '',
          };
        }
        case LogEnum.TRANSACTION_INFO:
          return {
            icon: actionable === 'return' ? UndoIcon : MoneyIcon,
            appearance:
              actionable === 'return'
                ? AppearanceColorEnum.DEFAULT
                : AppearanceColorEnum.SUCCESS,
            title: t(
              `cardTimeline.edit.${action}.${actionable}.title${
                !meta?.gatewayMethod ? 'NoMethod' : ''
              }`,
              {
                amount: extraAction,
                gatewayMethod: meta?.gatewayMethod,
              },
            ),
            description: userName
              ? t(`cardTimeline.edit.${action}.description`, {
                  userName,
                })
              : undefined,
            date,
            time,
          };
        case LogEnum.CF_ASSOCIATED:
          return {
            title: actionable
              ? t(`cardTimeline.log.metafield_updated`, {
                  customFieldName: JSON.parse(actionable).custom_field_name,
                })
              : '',
            description: userName
              ? t('cardTimeline.byUserName', { userName })
              : undefined,
            date,
            time,
          };
        case LogEnum.SHIPPING_ADDRESS_EDIT:
          return {
            title: t(`cardTimeline.log.${type}`),
            description: userName
              ? t('cardTimeline.byUserName', { userName })
              : undefined,
            date,
            time,
          };
        default:
          return {
            title: action,
            description: userName
              ? t('cardTimeline.byUserName', { userName })
              : undefined,
            date,
            time,
          };
      }
    },
    [
      fulfillment.preference.type,
      fulfillmentOrders,
      isShippablePickup,
      t,
      formatCurrency,
    ],
  );

  return timelineProps;
}

export default useCardTimeline;
