import { BaseCard } from '@tiendanube/components';
import { CheckItem } from 'commons/components';
import Stack from 'commons/components/Stack';
import { MainFeaturesPlanCard, PlanCardProps } from '../../types';

type PlanCardBodyProps = Pick<PlanCardProps, 'mainFeatures'>;

function PlanCardBody({ mainFeatures }: PlanCardBodyProps) {
  return (
    <BaseCard.Body>
      <Stack column align="flex-start">
        <div className="nimbus--stack-item stratus--card-body-mainFeatures">
          <Stack column align="flex-start">
            {mainFeatures.map((item: MainFeaturesPlanCard, i: number) => (
              <CheckItem key={i} checked={item.active}>
                {item.name}
              </CheckItem>
            ))}
          </Stack>
        </div>
      </Stack>
    </BaseCard.Body>
  );
}

export default PlanCardBody;
