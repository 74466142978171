import { Select, Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

interface TaxRuleSelectProps {
  readonly hasTaxNote: boolean;
}
function TaxRuleSelect({ hasTaxNote }: TaxRuleSelectProps) {
  const t = useTranslationCatalog('products.detail.invoice');
  const data = [{ taxRuleId: '1', taxRuleName: 'Regra general' }];

  const handleSelectChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLSelectElement>) => {
    //TODO: service data then delete.
    console.log('Seleccionado', name, value);
  };

  return (
    <Stack.Item fill>
      <Stack column align="flex-start" gap="1">
        <FormField.Select
          id="main-taxRule"
          label={t('titleTaxRule')}
          name="taxRule"
          onChange={handleSelectChange}
          placeholder={t('titleTaxRule')}
          value=""
        >
          <Select.Option
            value=""
            label={t('selectPreview')}
            disabled
            selected={!hasTaxNote}
          />
          {hasTaxNote &&
            data?.map(({ taxRuleId, taxRuleName }) => (
              <Select.Option
                key={`currency-${taxRuleId}`}
                value={taxRuleId}
                label={taxRuleName}
              />
            ))}
        </FormField.Select>
        {hasTaxNote ? (
          <Text fontSize="caption">{t('helpTextTaxRule')}</Text>
        ) : (
          <Text fontSize="caption">{t('helpTextCreateTaxRule')}</Text>
        )}
      </Stack>
    </Stack.Item>
  );
}

export default TaxRuleSelect;
