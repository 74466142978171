import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CATALOG_UPLOAD_VIDEOS } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import getImageMimeType, {
  imageMimeTypes,
  videoMimeTypes,
} from 'commons/utils/getImageMimeType/getImageMimeType';
import { useGetLanguage } from 'domains/Auth/hooks';
import { ImageGalleryState } from '../../components/ImageGallery/ImageGallery';

const ONE_MB_IN_BYTES = 1024 * 1024;
const IMAGE_LIMIT = 10 * ONE_MB_IN_BYTES;
const VIDEO_LIMIT = 200 * ONE_MB_IN_BYTES;
const VIDEO_PRODUCT_LIMIT = 1;

interface UseImageLoaderProps {
  onUploadImages: (images: ImageGalleryState[]) => void;
  videoQuantity: number;
}

function useImageLoader({
  onUploadImages,
  videoQuantity: currentVideoQuantity,
}: UseImageLoaderProps) {
  const language = useGetLanguage();
  const [imagesWithBadType, setImagesWithBadType] = useState<string[]>([]);
  const [imagesExceededSize, setImagesExceededSize] = useState<string[]>([]);
  const [videosExceededSize, setVideosExceededSize] = useState<string[]>([]);
  const [videosExceededQuantity, setVideosExceededQuantity] =
    useState<boolean>(false);

  const [hasUploadVideos] = useHasTags([CATALOG_UPLOAD_VIDEOS]);

  const ACCEPT_TYPE_FILE_ARRAY = hasUploadVideos
    ? [...imageMimeTypes, ...videoMimeTypes]
    : [...imageMimeTypes];

  const handleOnchange = async (files: File[]) => {
    const newImages: ImageGalleryState[] = await Promise.all(
      Object.values(files).map(async (file) => {
        const mimeType = await getImageMimeType(file);
        return {
          alt: { [language]: file.name },
          isLoading: true,
          id: uuidv4(),
          file,
          src: URL.createObjectURL(file),
          isError: false,
          name: file.name,
          size: file.size,
          type: file.type,
          mimeType,
          isEdited: false,
          mediaType: mimeType.includes('video') ? 'video' : 'image',
        };
      }),
    );
    const imagesWithBadType = newImages
      .filter(
        (file) =>
          !ACCEPT_TYPE_FILE_ARRAY.includes(file.type) ||
          !ACCEPT_TYPE_FILE_ARRAY.includes(file.mimeType),
      )
      .map((file) => file.name);

    const countVideos = newImages.filter(
      ({ mediaType }) => mediaType === 'video',
    ).length;

    const imagesExceededSize = newImages
      .filter(
        ({ size, mediaType }) => mediaType === 'image' && size >= IMAGE_LIMIT,
      )
      .map((file) => file.name);

    const videosExceededSize = newImages
      .filter(
        ({ size, mediaType }) => mediaType === 'video' && size >= VIDEO_LIMIT,
      )
      .map((file) => file.name);

    const hasVideosExceededQuantity =
      currentVideoQuantity + countVideos > VIDEO_PRODUCT_LIMIT;

    const validMediaFiles = newImages.filter(
      (file) =>
        ((hasVideosExceededQuantity && file.mediaType !== 'video') ||
          !hasVideosExceededQuantity) &&
        !imagesWithBadType.includes(file.name) &&
        !videosExceededSize.includes(file.name) &&
        !imagesExceededSize.includes(file.name),
    );

    setVideosExceededQuantity(hasVideosExceededQuantity);
    setImagesWithBadType(imagesWithBadType);
    setImagesExceededSize(
      imagesExceededSize.filter(
        (element) => !imagesWithBadType.includes(element),
      ),
    );
    setVideosExceededSize(
      videosExceededSize.filter(
        (element) => !imagesWithBadType.includes(element),
      ),
    );
    onUploadImages(validMediaFiles);
  };

  return {
    imagesWithBadType,
    imagesExceededSize,
    videosExceededSize,
    videosExceededQuantity,
    acceptTypeFile: ACCEPT_TYPE_FILE_ARRAY.join(','),
    setImagesWithBadType,
    setImagesExceededSize,
    setVideosExceededSize,
    setVideosExceededQuantity,
    handleOnchange,
  };
}

export default useImageLoader;
