import { useNavegate } from 'App/hooks';
import {
  HeaderContent,
  HeaderTop,
  ImportInProcessModal,
  IonPageStratus,
} from 'commons/components';
import { useTranslationCustomers } from 'domains/Customers/hooks';
import { ImportCustomersCard } from './components';

interface CustomersImportPageProps {
  showUpdateOptions?: boolean;
}

export function CustomersImportPage({
  showUpdateOptions = false,
}: CustomersImportPageProps) {
  const t = useTranslationCustomers();
  const { goBack } = useNavegate();

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={{ onClick: goBack }} />}
      headerContent={
        <HeaderContent
          title={t('customerImportPage.title')}
          subtitle={t('customerImportPage.subtitle')}
        />
      }
      width="small"
    >
      <ImportInProcessModal>
        <ImportCustomersCard showUpdateOptions={showUpdateOptions} />
      </ImportInProcessModal>
    </IonPageStratus>
  );
}
