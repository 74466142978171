import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  fetchExternalInvoiceHistoryByPagination as fetchExternalInvoiceHistoryAction,
  getExternalInvoiceHistory,
  getExternalInvoiceHistoryStatus,
} from 'domains/Billing/Invoices/invoicesSlice';
import { ExternalInvoiceMerchantService } from '../../types';

function useExternalInvoiceHistoryWeb(
  merchantService: ExternalInvoiceMerchantService,
) {
  const dispatch = useAppDispatch();
  const externalInvoiceHistory = useSelector(getExternalInvoiceHistory);
  const status = useSelector(getExternalInvoiceHistoryStatus);

  const fetchExternalInvoiceHistory = useCallback(
    (page: number) => {
      if (status !== 'loading') {
        dispatch(fetchExternalInvoiceHistoryAction({ page, merchantService }));
      }
    },
    [dispatch, merchantService, status],
  );

  const fetchExternalInvoiceHistoryInit = useCallback(() => {
    status === 'idle' && fetchExternalInvoiceHistory(1);
  }, [fetchExternalInvoiceHistory, status]);

  return {
    status,
    externalInvoiceHistory,
    fetchExternalInvoiceHistory,
    fetchExternalInvoiceHistoryInit,
  };
}

export default useExternalInvoiceHistoryWeb;
