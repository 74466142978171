/* eslint-disable max-statements */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { OrderFulfillmentsDto } from '@tiendanube/common';
import { Action } from '@tiendanube/common/src/enums';
import { NEW_ADMIN_MULTIPLOS_ENVIOS } from 'App/featuresFlags';
import { Responsive } from 'commons/components';
import { useModal } from 'commons/hooks';
import { openWindow } from 'commons/utils/window';
import { useGetTags } from 'domains/Auth/hooks';
import { useBulkActions } from 'domains/Catalog/Products/pages/ProductListPage/components';
import { DocumentType } from 'domains/FulfillmentOrders/pages/PrintDocumentPage/constants';
import { useGoToPrintPage } from 'domains/FulfillmentOrders/pages/PrintDocumentPage/hooks';
import ModalOrdersAssignMetafields from 'domains/Metafields/components/ModalOrdersAssignMetafields';
import { trackingOrderBulkMetafieldsCancel } from 'domains/Metafields/components/ModalOrdersAssignMetafields/tracking';
import { useOrdersList } from 'domains/Orders/Orders/hooks';
import useSelectBulkOrders from 'domains/Orders/Orders/hooks/useSelectBulkOrders';
import { ActionType } from 'domains/Orders/Orders/ordersService';
import {
  getFulfillmentOrders,
  getOrdersShippingMethods,
} from 'domains/Orders/Orders/ordersSlice';
import { ORDERS_PER_PAGE } from 'domains/Orders/Orders/pages/constants';
import {
  fulfillmentOrdersFromSelectedOrderIds,
  hasOca,
  shippingMethodFromOrdersBySelectedOrderIds,
} from 'domains/Orders/Orders/utils/OrderListFilter/utils';
import { useAppsLinks } from 'domains/PartnersApps/hooks';
import { OrderResultsDesktop, OrderResultsMobile } from './components';
import { useBulkMetafieldsAssignment } from '../../../hooks';
import FulfillOrdersModal from '../FulfillOrdersModal';
import OrderCancelModal from '../OrderCancelModal';

interface OrderListResultsProps {
  isEditMode: boolean;
  hasOrder: boolean;
  isLoadingHasOrders: boolean;
  onToggleEditMode: () => void;
  onFilterLinkClick: () => void;
}

function OrderListResults({
  isEditMode,
  hasOrder,
  isLoadingHasOrders,
  onToggleEditMode,
  onFilterLinkClick,
}: OrderListResultsProps): JSX.Element {
  const { filters, updateOrders } = useOrdersList();
  const { generateBulkAppLink } = useAppsLinks();
  const [showCancelModal, openCancelModal, closeCancelModal] = useModal();
  const [showFulfillModal, openFulfillModal, closeFulfillModal] = useModal();
  const [
    showBulkMetafieldsModal,
    openBulkMetafieldsModal,
    closeBulkMetafieldsModal,
  ] = useModal();
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [selectedOrderIds, setSelectedOrderIds] = useState<string[]>([]);
  const { setDisabled } = useBulkActions();
  const {
    handleDeselect,
    missingTrackingNumberCount,
    cleanOrdersMissingTrackingNumber,
    hasFulfillments,
  } = useSelectBulkOrders(filters, setDisabled);
  const fulfillmentOrders = useSelector(getFulfillmentOrders);
  const ordersShippingMethods = useSelector(getOrdersShippingMethods);
  const [packagesCount, setPackagesCount] = useState(0);
  const [fulfillmentsForModal, setFulfillmentsForModal] = useState<
    OrderFulfillmentsDto[]
  >([]);
  const tags = useGetTags();
  const hasMultipleFulfillments = tags.includes(NEW_ADMIN_MULTIPLOS_ENVIOS);
  const { isLoading: isBulkAssignMetafieldsLoading } =
    useBulkMetafieldsAssignment();
  const { goToPrintPage } = useGoToPrintPage();

  function getPackagesCount(ordersWithFulfillments: OrderFulfillmentsDto[]) {
    return ordersWithFulfillments.reduce(
      (acc, { fulfillments }) => acc + (fulfillments.length || 1),
      0,
    );
  }
  const filterNonShippableFulfillments = (
    fulfillmentOrders: OrderFulfillmentsDto[],
  ) =>
    fulfillmentOrders.filter(({ fulfillments }) =>
      fulfillments.every(
        ({ shippingType }) => shippingType !== 'non-shippable',
      ),
    );
  const handleBulkAction = async (ids: string[], value: Action) => {
    if ([Action.PACK, Action.ARCHIVE, Action.PAY].includes(value)) {
      onToggleEditMode();
      const isPackWithMultiCd =
        value === Action.PACK && hasMultipleFulfillments;
      const payload = {
        actionType: value,
        orderIds: ids,
        action: isPackWithMultiCd
          ? filterNonShippableFulfillments(
              fulfillmentOrdersFromSelectedOrderIds(ids, fulfillmentOrders),
            )
          : [],
      };
      await updateOrders(payload);
      onToggleEditMode();
      if (ids.length > ORDERS_PER_PAGE) handleDeselect();
    } else if (value === Action.FULFILL) {
      setSelectedOrderIds(ids);
      openFulfillModal();
      if (ids.length > ORDERS_PER_PAGE) {
        setPackagesCount(getPackagesCount(fulfillmentOrders));
        setFulfillmentsForModal(fulfillmentOrders);
        handleDeselect();
      }
    } else if (value === Action.CANCEL) {
      setSelectedOrderIds(ids);
      openCancelModal();
      if (ids.length > ORDERS_PER_PAGE) handleDeselect();
    } else if (value === Action.PRINT_RECEIPT) {
      goToPrintPage({ ids, type: DocumentType.RECEIPT });
    } else if (value === Action.PRINT_PACKING_LABEL) {
      const ordersShippingMethodsFromSelectedOrders =
        shippingMethodFromOrdersBySelectedOrderIds(ids, ordersShippingMethods);

      goToPrintPage({
        ids: ids,
        type: DocumentType.LABEL,
        hasOca: ordersShippingMethodsFromSelectedOrders.some(hasOca),
        hasOcaAndNotGeneratedTag: true,
      });
    } else if (value === Action.PRINT_CONTENT_DECLARATION) {
      goToPrintPage({ ids, type: DocumentType.CONTENT_DECLARATION });
    } else if (value === Action.ASSIGN_METAFIELDS) {
      setSelectedOrderIds(ids);
      openBulkMetafieldsModal();
    } else {
      openWindow(generateBulkAppLink(value, ids), true);
    }
  };

  const onCloseFulfillModal = () => {
    closeFulfillModal();
    cleanOrdersMissingTrackingNumber();
  };

  const onCloseBulkMetafields = () => {
    closeBulkMetafieldsModal();
    trackingOrderBulkMetafieldsCancel(selectedOrderIds);
  };

  const handleOnFulfill = async (action: ActionType) => {
    setIsActionLoading(true);
    await updateOrders({
      actionType: Action.FULFILL,
      orderIds: selectedOrderIds,
      action,
    });
    onToggleEditMode();
    setIsActionLoading(false);
    onCloseFulfillModal();
    setFulfillmentsForModal([]);
  };

  const handleOnCancel = async (action: ActionType) => {
    setIsActionLoading(true);
    await updateOrders({
      actionType: Action.CANCEL,
      orderIds: selectedOrderIds,
      action,
    });
    onToggleEditMode();
    setIsActionLoading(false);
    closeCancelModal();
  };

  return (
    <>
      <Responsive
        mobile={
          <OrderResultsMobile
            hasOrder={hasOrder}
            isLoadingHasOrders={isLoadingHasOrders}
            isEditMode={isEditMode}
            onToggleEditMode={onToggleEditMode}
            onHandleBulkAction={handleBulkAction}
          />
        }
        desktop={
          <OrderResultsDesktop
            hasOrder={hasOrder}
            isLoadingHasOrders={isLoadingHasOrders}
            onHandleBulkAction={handleBulkAction}
            onFilterLinkClick={onFilterLinkClick}
          />
        }
      />
      <OrderCancelModal
        show={showCancelModal}
        hideModal={closeCancelModal}
        orderIds={selectedOrderIds}
        appliedAction={handleOnCancel}
        isLoading={isActionLoading}
      />
      {showFulfillModal && (
        <FulfillOrdersModal
          show={showFulfillModal}
          orderIds={selectedOrderIds}
          isLoading={isActionLoading}
          onConfirm={handleOnFulfill}
          onClose={onCloseFulfillModal}
          ordersMissingTracking={missingTrackingNumberCount}
          packagesCount={packagesCount}
          hasFulfillments={hasFulfillments}
          fulfillmentsForModal={fulfillmentsForModal}
          isSingleOrder={false}
        />
      )}
      <ModalOrdersAssignMetafields
        handleOnClose={onCloseBulkMetafields}
        isLoading={isBulkAssignMetafieldsLoading}
        selectedRowsId={selectedOrderIds}
        show={showBulkMetafieldsModal}
        onToggleEditMode={onToggleEditMode}
      />
    </>
  );
}

export default OrderListResults;
