import { Box, Title } from '@nimbus-ds/components';
import { NotificationsListItemResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import { CONFIGURATIONS_ROUTES } from 'domains/Configurations/configurationsRoutes';
import {
  SectionAppCard,
  SectionAppCardDomains,
} from 'domains/PartnersApps/components';
import EmailItemCard from './EmailItemCard';

interface EmailsSectionProps {
  title: string;
  emails: NotificationsListItemResponseDto[];
  showApp?: boolean;
}

function EmailsSection({
  title,
  emails,
  showApp,
}: Readonly<EmailsSectionProps>) {
  return (
    <Stack column align="flex-start" spacing="tight">
      <Title as="h3">{title}</Title>
      <Box
        display="grid"
        gridTemplateColumns={{
          lg: '1fr 1fr 1fr 1fr',
          md: '1fr',
          xs: '1fr',
        }}
        gridGap="4"
      >
        {emails.map((email) => (
          <EmailItemCard key={email.id} email={email} />
        ))}
        {showApp && (
          <SectionAppCard
            source={CONFIGURATIONS_ROUTES.notifications}
            appCategory="accounting"
            domain={SectionAppCardDomains.EMAILS}
            height="100%"
          />
        )}
      </Box>
    </Stack>
  );
}

export default EmailsSection;
