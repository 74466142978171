import { Box, IconButton, Tooltip } from '@nimbus-ds/components';
import { CashierIcon, ExternalLinkIcon } from '@nimbus-ds/icons';
import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useHandleMenuClick } from 'App/hooks';
import { useMenu } from 'App/settings/menu/hooks';
import { openWindow } from 'commons/utils/window';
import useGetStoreInfo from 'domains/Auth/hooks/useGetStoreInfo';
import useOnboardingTasks from 'domains/Dashboard/pages/DashboardPage/components/OnboardingTasks/useOnboardingTasks';
import dashboardService from 'domains/Dashboard/services';

const POS_URL_PATH = '/pos';

function PosMenu() {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem } = useHandleMenuClick();
  const { menu } = useMenu();
  const { getOnboarding, hasCompleted } = useOnboardingTasks();
  const { id } = useGetStoreInfo();

  const isPos = menu
    ? !!menu?.Main?.SalesChannel?.options?.Pos?.externalLink
    : false;

  const handleMenuPosVisitClick = (fromIcon: boolean) => async () => {
    try {
      if (!hasCompleted) {
        await dashboardService.updateOnboardingTaskSetupTestingSale();
        getOnboarding();
      }

      if (fromIcon) {
        openWindow('https://pdv.tiendanube.com/sign-in?storeId=' + id, true);
      } else {
        handleClickItem();
      }
    } catch (error) {
      console.error('Error updating onboarding task:', error);
    }
  };

  return (
    <Box position="relative" data-style="customize-wrapper">
      <MenuButton
        style={{ width: '263px' }}
        as={Link}
        to={POS_URL_PATH}
        startIcon={CashierIcon}
        onClick={handleMenuPosVisitClick(false)}
        active={pathname === POS_URL_PATH}
        label={`${t('menu.salesChannels.pos')}`}
      />
      <Box
        position="absolute"
        top="0"
        right="0"
        px="2"
        py="1"
        zIndex="100"
        backgroundColor="transparent"
      >
        {isPos && (
          <Tooltip
            content={`${t('menu.salesChannels.tooltipPos')}`}
            position="right"
          >
            <IconButton
              size="1.125rem"
              source={<ExternalLinkIcon size={10} />}
              onClick={handleMenuPosVisitClick(true)}
            />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}

export default PosMenu;
