import { memo } from 'react';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { useNavegate } from 'App/hooks';
import { useResponsive } from 'commons/components';
import { useModal } from 'commons/hooks';
import { useGetTags } from 'domains/Auth/hooks';
import { MainMenu, AccountMenu, SettingsMenu, HelpMenu } from './components';
import { useMenuOptionsVisibility } from './hooks';
import './Menu.scss';

interface MenuProps {
  device: 'mobile' | 'desktop';
}

const PATH = {
  settings: '/settings',
  account: '/account',
};

function Menu({ device }: MenuProps): JSX.Element {
  const { pathname } = useNavegate();
  const { isMobile: isMobileResponsive } = useResponsive();
  const [isVisibleSupport, showVisibleSupport, hiddenVisibleSupport] =
    useModal();

  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);

  const {
    isVisibleAccount: isVisibleAccountRevamp,
    isVisibleSettings: isVisibleSettingsRevamp,
  } = useMenuOptionsVisibility();

  const isVisibleSettings = isRevampMenu
    ? isVisibleSettingsRevamp
    : pathname.startsWith(PATH.settings);
  const isVisibleAccount = isRevampMenu
    ? isVisibleAccountRevamp
    : isMobileResponsive && pathname.startsWith(PATH.account);

  return (
    <>
      <MainMenu device={device} onClickSupport={showVisibleSupport} />
      <HelpMenu onClose={hiddenVisibleSupport} visible={isVisibleSupport} />
      <AccountMenu visible={isVisibleAccount} device={device} />
      <SettingsMenu visible={isVisibleSettings} />
    </>
  );
}

export default memo(Menu);
