import { ChangeEvent } from 'react';
import { Button, Tabs } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { ShowByTag } from 'App/components';
import { EXTERNAL_TRACKING_CODE } from 'App/featuresFlags';
import { CardWithTitle, ExternalLink, Stack } from 'commons/components';
import { useModal } from 'commons/hooks';
import { useGetTags } from 'domains/Auth/hooks';
import { useGetExternalCodesQuery } from 'domains/Configurations/ExternalCodes/externalCodesApi';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import RequestCheckoutScriptApprovalModal from './RequestCheckoutScriptApprovalModal';
import { SplitSection } from '../SplitSection';

interface ExternalCodesConversionCodesCardProps {
  thankYouCheckoutConversion: string;
  purchaseEventOnPaidOrder: string;
  confirmationCheckoutConversion: string;
  assortedJs: string;
  script: string;
  onChangeTextArea: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export function ExternalCodesConversionCodesCard({
  onChangeTextArea,
  thankYouCheckoutConversion,
  assortedJs,
  script,
  confirmationCheckoutConversion,
}: Readonly<ExternalCodesConversionCodesCardProps>) {
  const { data } = useGetExternalCodesQuery();
  const canEditScript = data?.canEditScript ?? false;
  const t = useTranslationConfigurations('externalCodes.conversionCodes');
  const tags = useGetTags();
  const isEnabledTrackingCode = tags.includes(EXTERNAL_TRACKING_CODE);

  const [
    showRequestCheckoutScriptApprovalModal,
    openRequestCheckoutScriptApprovalModal,
    closeRequestCheckoutScriptApprovalModal,
  ] = useModal();

  return (
    <>
      <CardWithTitle
        title={isEnabledTrackingCode ? t('title') : t('titleWithoutTracking')}
      >
        <Stack column spacing="loose" align="stretch">
          <SplitSection
            title={t('conversionCodesTabs.title')}
            description={t('conversionCodesTabs.description')}
          >
            <Tabs>
              <Tabs.Item
                label={t('conversionCodesTabs.completionPageTab.title')}
              >
                <FormField.Textarea
                  label={t('conversionCodesTabs.completionPageTab.label')}
                  onChange={onChangeTextArea}
                  value={confirmationCheckoutConversion}
                  name="confirmationCheckoutConversion"
                  id="confirmationCheckoutConversion"
                  placeholder={t('placeholder')}
                  lines={6}
                />
              </Tabs.Item>
              <Tabs.Item
                label={t('conversionCodesTabs.confirmationPageTab.title')}
              >
                <FormField.Textarea
                  label={t('conversionCodesTabs.confirmationPageTab.label')}
                  onChange={onChangeTextArea}
                  value={thankYouCheckoutConversion}
                  name="thankYouCheckoutConversion"
                  id="thankYouCheckoutConversion"
                  placeholder={t('placeholder')}
                  lines={6}
                />
              </Tabs.Item>
            </Tabs>
            <ExternalLink
              href={t('conversionCodesTabs.href')}
              appearance="primary"
              textDecoration="none"
            >
              {t('conversionCodesTabs.link')}
            </ExternalLink>
          </SplitSection>
          <ShowByTag tag={EXTERNAL_TRACKING_CODE}>
            <SplitSection
              title={t('trackingCodesTabs.title')}
              description={t('trackingCodesTabs.description')}
            >
              <Tabs>
                <Tabs.Item label={t('trackingCodesTabs.forStore.title')}>
                  <FormField.Textarea
                    label={t('trackingCodesTabs.forStore.label')}
                    onChange={onChangeTextArea}
                    value={assortedJs}
                    name="assortedJs"
                    id="assortedJs"
                    placeholder={t('placeholder')}
                    lines={6}
                  />
                </Tabs.Item>
                <Tabs.Item label={t('trackingCodesTabs.forCheckout.title')}>
                  <Stack column spacing="tight" align="flex-start">
                    <FormField.Textarea
                      label={t('trackingCodesTabs.forCheckout.label')}
                      onChange={onChangeTextArea}
                      value={script}
                      disabled={!canEditScript}
                      name="script"
                      id="script"
                      placeholder={t('placeholder')}
                      lines={6}
                    />
                    {!canEditScript && (
                      <Button
                        appearance="primary"
                        onClick={openRequestCheckoutScriptApprovalModal}
                      >
                        {t('trackingCodesTabs.forCheckout.requestButtonLabel')}
                      </Button>
                    )}
                  </Stack>
                </Tabs.Item>
              </Tabs>
              <ExternalLink
                href={t('trackingCodesTabs.href')}
                appearance="primary"
                textDecoration="none"
              >
                {t('trackingCodesTabs.link')}
              </ExternalLink>
            </SplitSection>
          </ShowByTag>
        </Stack>
      </CardWithTitle>
      {showRequestCheckoutScriptApprovalModal && (
        <RequestCheckoutScriptApprovalModal
          show={showRequestCheckoutScriptApprovalModal}
          onClose={closeRequestCheckoutScriptApprovalModal}
          previousScript={script}
        />
      )}
    </>
  );
}
