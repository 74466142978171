import { Text } from '@nimbus-ds/components';
import { DeliveryCostType } from 'domains/Shipping/DeliveryMethods/pages/DeliveryMethodsPage/components/CustomShippingForm/CustomShippingForm';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface DeliveryCostInfoProps {
  cost?: string;
  deliveryCost: DeliveryCostType;
}

function DeliveryCostInfo({
  cost,
  deliveryCost,
}: Readonly<DeliveryCostInfoProps>) {
  const t = useTranslationShipping();

  return (
    <Text>
      {(deliveryCost === 'free' ||
        (Number(cost) === 0 && deliveryCost === 'with-cost')) &&
        t('deliveryMethods.newInformationShipping.freeCost')}
      {Number(cost) > 0 &&
        deliveryCost === 'with-cost' &&
        t('deliveryMethods.newInformationShipping.valueCost', {
          cost,
        })}
      {deliveryCost === 'to-be-arranged' &&
        t('deliveryMethods.newInformationShipping.toBeArranged')}
    </Text>
  );
}

export default DeliveryCostInfo;
