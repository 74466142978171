import { useCallback, useEffect } from 'react';
import useMetafieldsFormContext from 'domains/Metafields/hooks/useMetafieldsFormContext';

export default function useMergeMetafieldsEvents(
  inputEvents,
  formFieldConfigEvents,
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { applyFormActions } = useMetafieldsFormContext();

  useEffect(() => {
    applyFormActions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mergeEvents = useCallback(() => {
    const mergedEvents = { ...inputEvents };

    if (formFieldConfigEvents) {
      const eventsName = Object.keys(formFieldConfigEvents);

      for (const eventName of eventsName) {
        const formFieldEvent = () =>
          eval(formFieldConfigEvents[eventName] || '');

        if (inputEvents[eventName]) {
          const event = inputEvents[eventName];

          mergedEvents[eventName] = (e) => {
            event(e);
            formFieldEvent();
          };
        } else {
          mergedEvents[eventName] = () => formFieldEvent();
        }
      }
    }

    return mergedEvents;
  }, [inputEvents, formFieldConfigEvents]);

  return mergeEvents;
}
