import usePaymentsHistory from './usePaymentsHistory';
import useTransactionFeePaid from './useTransactionFeePaid';
import useTransactionFeeToPay from './useTransactionFeeToPay';

function useIsTransactionFeeEmpty() {
  const { paymentsHistory, isSuccess: paymentsHistorySuccess } =
    usePaymentsHistory();
  const { transactionFeePaid, isSuccess: transactionFeePaidSuccess } =
    useTransactionFeePaid();
  const {
    transactionFeeToPay,
    isSuccess: transactionFeeToPaySuccess,
    thereAreOrdersForCurrentMonth,
  } = useTransactionFeeToPay();

  const isSuccess =
    paymentsHistorySuccess &&
    transactionFeePaidSuccess &&
    transactionFeeToPaySuccess;

  const isEmpty =
    isSuccess &&
    !transactionFeeToPay?.recurrentPaymentActivated &&
    (!paymentsHistory || paymentsHistory.length === 0) &&
    (!transactionFeePaid ||
      transactionFeePaid.isEmpty ||
      !transactionFeePaid.hasCurrentMonthSplitPaidOrders) &&
    (!transactionFeeToPay ||
      transactionFeeToPay.isEmpty ||
      (!transactionFeeToPay.paymentAvailable &&
        !thereAreOrdersForCurrentMonth));

  return isEmpty;
}

export default useIsTransactionFeeEmpty;
