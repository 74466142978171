import { BaseCard, Title } from '@tiendanube/components';
import { EditIcon } from '@tiendanube/icons';
import Link from 'commons/components/LegacyLink';
import Text from 'commons/components/LegacyText';

interface LinkCardProps {
  title: string;
  text: string;
  linkText: string;
  onLinkPressed: () => void;
}

function LinkCard({
  title,
  text,
  linkText,
  onLinkPressed,
}: LinkCardProps): JSX.Element {
  return (
    <BaseCard>
      <BaseCard.Header>
        <Title type="h3">{title}</Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Text>{text}</Text>
      </BaseCard.Body>
      <BaseCard.Footer>
        <Link
          appearance="primary"
          iconPosition="start"
          icon={EditIcon}
          onClick={onLinkPressed}
        >
          {linkText}
        </Link>
      </BaseCard.Footer>
    </BaseCard>
  );
}

export default LinkCard;
