import { ChangeEvent, useState } from 'react';
import { Box, Input } from '@nimbus-ds/components';
import { SearchFilterAppliedFilters } from 'commons/components/SearchFilterAppliedFilters/SearchFilterAppliedFilters';
import { SearchFilterInterfaceIdLabel } from 'commons/types';
import { SavedSearches } from 'domains/Orders/components/SavedSearches';
import { FiltersParamsType } from 'domains/Orders/Orders/ordersService';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { FilterButton } from '../FilterButton';
import './SearchFilter.scss';

interface SearchFilterProps {
  resultCount?: string;
  filters: FiltersParamsType;
  appliedFilters: SearchFilterInterfaceIdLabel[];
  isLoading?: boolean;
  value: string;
  onClick: () => void;
  onDismiss?: (key: string) => any;
  onSubmit: (text: string) => void;
  onChange: (value: string) => void;
  handleChangeSavedSearch: (newFilters: FiltersParamsType) => void;
  onClearFilterButtonClick: () => void;
}

export function SearchFilter({
  resultCount,
  filters,
  appliedFilters,
  isLoading = false,
  value,
  onSubmit,
  onDismiss,
  onClick,
  onChange,
  handleChangeSavedSearch,
  onClearFilterButtonClick,
}: Readonly<SearchFilterProps>) {
  const t = useTranslationOrders();

  const [areSavedSearchesVisible, setAreSavedSearchesVisible] =
    useState<boolean>(true);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleChange = ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onSubmit(value);
    }
  };

  const onFocus = () => {
    setAreSavedSearchesVisible(false);
    setIsInputFocused(true);
  };

  const onBlur = () => {
    setAreSavedSearchesVisible(true);
    setIsInputFocused(false);
  };

  return (
    <div className="stratus--search-filter-header">
      <Box
        display="flex"
        flexDirection="column"
        gap="2"
        backgroundColor="neutral-background"
        borderStyle="solid"
        padding="2"
      >
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box
            display="flex"
            flexGrow={isInputFocused ? '1' : ''}
            maxWidth={!isInputFocused ? '210px' : 'auto'}
            minWidth="160px"
            marginRight="2"
            height="32px"
          >
            <Input.Search
              type="search"
              name="search"
              aria-label={t('filter.inputAriaLabel')}
              placeholder={
                isInputFocused
                  ? t('filter.inputLabelDesktop')
                  : t('filter.inputLabel')
              }
              value={value}
              maxLength={150}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <SavedSearches
              appliedFilter={filters}
              onSavedSearchClick={handleChangeSavedSearch}
              visible={areSavedSearchesVisible}
            />
            <FilterButton onClick={onClick} />
          </Box>
        </Box>
        {appliedFilters.length > 0 && (
          <>
            <hr />
            <SearchFilterAppliedFilters
              resultCount={resultCount}
              appliedFilters={appliedFilters}
              desktopWrap="wrap"
              onChange={onChange}
              onDismiss={onDismiss}
              isLoading={isLoading}
              showClearFiltersButton
              onClearFilterButtonClick={onClearFilterButtonClick}
            />
          </>
        )}
      </Box>
    </div>
  );
}
