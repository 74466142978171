import { Box, Icon, Link, Text } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { useTranslationCustomers } from 'domains/Customers/hooks';

interface PriceTableAddStateProps {
  openModal: () => void;
}

export default function PriceTablesAddState({
  openModal,
}: Readonly<PriceTableAddStateProps>) {
  const t = useTranslationCustomers();
  const tPath = 'customerForm.priceTablesInformation';
  return (
    <Box gap="4" display="flex" flexDirection="column">
      <Text>{t(`${tPath}.description`)}</Text>

      <Link
        appearance="primary"
        as="button"
        textDecoration="none"
        type="button"
        onClick={openModal}
      >
        <Icon color="primary-interactive" source={<PlusCircleIcon />} />
        {t(`${tPath}.buttonAddLabel`)}
      </Link>
    </Box>
  );
}
