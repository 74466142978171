import { useEffect } from 'react';
import { Stack } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import { FEATURE_HIDE_CATEGORIES } from 'App/features';
import { LanguageInterface } from 'App/i18n';
import { CancelAndSaveButtons } from 'commons/components';
import { useForm } from 'commons/hooks';
import { CATALOG_EDIT_CATEGORIES } from 'config/upsellFlowSources';
import { useGetAllLanguages } from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { Visibility } from 'domains/Catalog/Categories/components';
import { useCategories } from 'domains/Catalog/Categories/hooks';
import {
  isCategoryVisible,
  VisibilityCategoryType,
} from 'domains/Catalog/Products/utils';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { categorySchema } from './categorySchema';
import GoogleShoppingCategories from '../GoogleShoppingCategories';
import InformationCategoryCard from '../InformationCategoryCard/InformationCategoryCard';
import SeoCategories from '../SeoCategories';

export interface NewCategoryInterface {
  name: string;
  parent: string;
  googleShoppingCategory: string;
  seoTitle: LanguageInterface;
  seoDescription: LanguageInterface;
  handle: LanguageInterface;
  visibility: VisibilityCategoryType;
}

interface ModalConfirmationCreateCategoryProps {
  isShow: boolean;
  onClose: () => void;
  onCreateCategory: (newCategory: NewCategoryInterface) => void;
}

const initialState: NewCategoryInterface = {
  name: '',
  parent: '',
  googleShoppingCategory: '',
  seoTitle: {},
  seoDescription: {},
  handle: {},
  visibility: 'visible',
};

function ModalConfirmationCreateCategory({
  isShow,
  onClose,
  onCreateCategory,
}: ModalConfirmationCreateCategoryProps): JSX.Element {
  const languages = useGetAllLanguages();

  const { categories } = useCategories();

  const {
    values,
    errors,
    handleOnChange,
    handleOnSubmit,
    setFieldValue,
    resetValues,
  } = useForm<NewCategoryInterface, Record<string, string>>({
    initialValues: initialState,
    validationSchema: categorySchema(languages),
    onSubmit: (values) => {
      const newCategoryPayload: NewCategoryInterface = {
        name: values.name.trim(),
        googleShoppingCategory: values.googleShoppingCategory,
        parent: values.parent,
        seoTitle: values.seoTitle,
        seoDescription: values.seoDescription,
        handle: values.handle,
        visibility: values.visibility,
      };
      onCreateCategory(newCategoryPayload);
      handleOnClose();
    },
  });

  const t = useTranslationCatalog();

  const handleOnHideVisibility = useUpsellFlow({
    title: t('categories.detail.visibility.upsell'),
    featureKey: FEATURE_HIDE_CATEGORIES,
    trackingSource: CATALOG_EDIT_CATEGORIES,
    callback: ({ name, value }) => setFieldValue(name, value),
    asAside: true,
  });

  const handleOnChangeVisibility = ({ name, value }) =>
    value === 'hidden'
      ? handleOnHideVisibility({ name, value })
      : setFieldValue(name, value);

  const handleOnClose = () => {
    resetValues();
    onClose();
  };

  const handleOnChangeGoogleShoppingAndSeoCategories = ({
    name,
    value,
  }: {
    name: string;
    value: string | LanguageInterface;
  }) => {
    setFieldValue(name, value);
  };

  const parent = categories.find((cuurrent) => cuurrent.id === values.parent);
  const hasParentHidden = parent ? !isCategoryVisible(parent) : false;

  useEffect(() => {
    setFieldValue('visibility', hasParentHidden ? 'soft-hidden' : 'visible');
  }, [hasParentHidden, setFieldValue]);

  return (
    <ModalAside
      isOpen={isShow}
      onDidDismiss={handleOnClose}
      headerContent={t('products.detail.newCategory')}
    >
      <Stack column align="stretch" spacing="loose">
        <InformationCategoryCard
          values={values}
          errors={errors}
          handleOnChange={handleOnChange}
        />
        <GoogleShoppingCategories
          googleShoppingCategory={values.googleShoppingCategory}
          handleChange={handleOnChangeGoogleShoppingAndSeoCategories}
          errors={errors}
        />
        <SeoCategories
          seoTitle={values.seoTitle}
          seoDescription={values.seoDescription}
          handle={values.handle}
          handleChange={handleOnChangeGoogleShoppingAndSeoCategories}
          errors={errors}
        />
        <Visibility
          visibility={values.visibility}
          disabled={hasParentHidden}
          onChange={handleOnChangeVisibility}
        />
        <CancelAndSaveButtons
          saveText={t('products.detail.createCategory')}
          cancelText={t('products.detail.cancel')}
          onCancel={handleOnClose}
          onSave={handleOnSubmit}
        />
      </Stack>
    </ModalAside>
  );
}

export default ModalConfirmationCreateCategory;
