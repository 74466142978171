import { useEffect } from 'react';
import { Checkbox, Label, Select } from '@nimbus-ds/components';
import { FormField, Layout } from '@nimbus-ds/patterns';
import { LocationResponseDto } from '@tiendanube/common';
import { ExternalLink } from 'commons/components';
import { ExtraOptionsProps } from 'commons/components/ImportCsvCard/components';
import { useTranslationCommon } from 'commons/hooks';
import { useHasCatalogInventory } from 'domains/Auth/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { useLocations } from 'domains/Shipping/Locations/hooks';

export function ImportProductsExtraOptions({
  disabled,
  options,
  onChange,
}: ExtraOptionsProps) {
  const tCommons = useTranslationCommon('importCsv.import');
  const tCatalog = useTranslationCatalog();

  const {
    locations,
    isSuccess: isSuccessLocations,
    isLoading: isLoadingLocations,
    fetchLocations,
  } = useLocations();
  const hasCatalogInventory = useHasCatalogInventory();
  const hasMultipleLocations = locations.length > 1;
  const locationName = (location: LocationResponseDto) =>
    location.main
      ? `${location.name} (${tCatalog('inventory.mainLocation')})`
      : location.name;

  useEffect(() => {
    if (!isSuccessLocations && !isLoadingLocations) {
      fetchLocations();
    }
  }, [isSuccessLocations, isLoadingLocations, fetchLocations]);

  return (
    <Layout columns="1">
      {hasCatalogInventory && (
        <Layout.Section>
          <Checkbox
            label={tCommons('updateStock.checkText')}
            name="importCardStockCheckbox"
            checked={options.updateStock}
            disabled={disabled || !options.updateExisting}
            onChange={(e) => onChange('updateStock', e.target.checked)}
          />
        </Layout.Section>
      )}
      {hasCatalogInventory && !isLoadingLocations && hasMultipleLocations && (
        <Layout.Section>
          <FormField
            id="select-id"
            label={tCommons('updateStock.locations')}
            placeholder="Placeholder"
          >
            <Select
              disabled={
                disabled || !options.updateExisting || !options.updateStock
              }
              name="location-select"
              id="location-select"
              onChange={(e) => onChange('selectedLocation', e.target.value)}
            >
              <Select.Option
                label={tCommons('updateStock.allLocations')}
                value="all"
                selected={options.selectedLocation === 'all'}
              />
              {locations.map((location) => (
                <Select.Option
                  key={location.id}
                  label={locationName(location)}
                  value={location.id}
                  selected={options.selectedLocation === location.id}
                />
              ))}
            </Select>
          </FormField>
        </Layout.Section>
      )}
      {hasCatalogInventory && isLoadingLocations && (
        <Layout.Section>
          <FormField label={<Label.Skeleton />}>
            <Select.Skeleton width="100%" />
          </FormField>
        </Layout.Section>
      )}
      {hasCatalogInventory && (
        <Layout.Section>
          <ExternalLink href={tCommons('updateStock.helpLinkUrl')}>
            {tCommons('updateStock.helpLink')}
          </ExternalLink>
        </Layout.Section>
      )}
    </Layout>
  );
}
