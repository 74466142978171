import { Box } from '@nimbus-ds/components';
import { DataList } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';

const { Cell } = DataList;

interface DataTableRowProps {
  month: string;
  description: string;
}

function LeftCell({ month, description }: DataTableRowProps): JSX.Element {
  return (
    <Cell>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        gap="4"
      >
        <Text appearance="secondary" bold>
          {month}
        </Text>
        <Text>{description}</Text>
      </Box>
    </Cell>
  );
}

export default LeftCell;
