import { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Card,
  Text,
  Title,
  Radio,
  Label,
  Alert,
} from '@nimbus-ds/components';
import { Reward } from '@tiendanube/common';
import { Stack } from 'commons/components';
import { Categories, Products } from 'domains/Marketing/components';
import { Scope } from 'domains/Marketing/enums';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import {
  CROSS_SELLING_DEFAULT_TRIGGER,
  CROSS_SELLING_DEFAULT_REWARD,
} from 'domains/Marketing/Promotions/pages/constants';
import { PromotionFormProps } from '../../PromotionForm';

export function TriggerCard({
  values,
  errors,
  onChangeRewards,
}: Readonly<
  Pick<PromotionFormProps, 'values' | 'errors' | 'onChangeRewards'>
>) {
  const t = useTranslationMarketing(
    'promotions.settingsPage.crossSelling.trigger',
  );
  const tScope = useTranslationMarketing('promotions.settingsPage.applyTo');

  const currentReward: Reward =
    values.rewards?.[0] ?? CROSS_SELLING_DEFAULT_REWARD;

  const initialTrigger = useMemo(
    () => values.rewards?.[0]?.triggers?.[0] ?? CROSS_SELLING_DEFAULT_TRIGGER,
    [values.rewards],
  );

  const [triggerScopeType, setTriggerScopeType] = useState(
    initialTrigger.scope_type,
  );
  const [triggerScopeValue, setTriggerScopeValue] = useState(
    initialTrigger.scope_value,
  );

  const triggerScopeValueError =
    errors['rewards[0].triggers[0].scope_value'] || '';

  const [isWarningVisible, setIsWarningVisible] = useState(false);

  useEffect(() => {
    if (triggerScopeType !== initialTrigger.scope_type) {
      setTriggerScopeValue(triggerScopeType === Scope.ALL ? null : []);
      setIsWarningVisible(false);
    }
  }, [triggerScopeType, initialTrigger.scope_type]);

  useEffect(() => {
    setIsWarningVisible(triggerScopeValueError !== '');
  }, [triggerScopeValueError]);

  useEffect(() => {
    const updatedTrigger = {
      ...initialTrigger,
      scope_type: triggerScopeType,
      scope_value: triggerScopeValue,
    };

    const updatedReward: Reward = {
      ...currentReward,
      scope_type: currentReward.scope_type || Scope.ALL,
      triggers: [updatedTrigger],
    };

    const updatedRewards: Reward[] = [updatedReward];

    if (JSON.stringify(values.rewards) !== JSON.stringify(updatedRewards)) {
      onChangeRewards(updatedRewards);
    }
  }, [
    triggerScopeType,
    triggerScopeValue,
    initialTrigger,
    currentReward,
    onChangeRewards,
    values.rewards,
  ]);

  const rewardSelectedProductIds = currentReward.scope_value.map(
    (product) => product.id,
  );

  return (
    <Card>
      <Card.Header>
        <Stack spacing="none" justify="space-between" align="center">
          <Stack spacing="tight">
            <Title as="h4">{t('title')}</Title>
          </Stack>
        </Stack>
      </Card.Header>
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="6" marginBottom="1">
          <Box display="flex" flexDirection="column" gap="2">
            <Text>{t('scope.info')}</Text>
            <Stack spacing="tight">
              <Radio
                id="trigger-scope-all"
                name="triggerScopeType"
                as="button"
                value={Scope.ALL}
                checked={triggerScopeType === Scope.ALL}
                label={tScope('all.title')}
                onChange={() => setTriggerScopeType(Scope.ALL)}
              />
              <Radio
                id="trigger-scope-categories"
                name="triggerScopeType"
                as="button"
                value={Scope.CATEGORIES}
                checked={triggerScopeType === Scope.CATEGORIES}
                label={tScope('categories.title')}
                onChange={() => setTriggerScopeType(Scope.CATEGORIES)}
              />
              <Radio
                id="trigger-scope-products"
                name="triggerScopeType"
                as="button"
                value={Scope.PRODUCTS}
                checked={triggerScopeType === Scope.PRODUCTS}
                label={tScope('products.title')}
                onChange={() => setTriggerScopeType(Scope.PRODUCTS)}
              />
            </Stack>
            {triggerScopeValue !== null && (
              <>
                {triggerScopeType === Scope.CATEGORIES && (
                  <Stack column align="stretch" gap="3">
                    <Stack.Item>
                      <Label>{t('scope.categories.info')}</Label>
                    </Stack.Item>
                    <Stack.Item>
                      <Categories
                        categories={triggerScopeValue}
                        requiredText=""
                        title={tScope('categories.title')}
                        subtitle={t('scope.categories.selectionInfo')}
                        emptyTitle={tScope('categories.empty.title')}
                        emptyText={tScope('categories.empty.text')}
                        ctaText={tScope('categories.empty.ctaText')}
                        onChange={setTriggerScopeValue}
                        showTitle={false}
                        selectLinkText={t('scope.actions.select')}
                        editLinkText={t('scope.actions.edit')}
                      />
                    </Stack.Item>
                  </Stack>
                )}
                {triggerScopeType === Scope.PRODUCTS && (
                  <Box display="flex" flexDirection="column" gap="3">
                    <Label>{t('scope.products.info')}</Label>
                    <Products
                      products={triggerScopeValue}
                      requiredText=""
                      title={tScope('products.title')}
                      showTitle={false}
                      subtitle={t('scope.products.selectionInfo')}
                      emptyTitle={tScope('products.empty.title')}
                      emptyText={tScope('products.empty.text')}
                      ctaText={tScope('products.empty.ctaText')}
                      onChange={setTriggerScopeValue}
                      selectLinkText={t('scope.actions.select')}
                      editLinkText={t('scope.actions.edit')}
                      excludedProductIds={rewardSelectedProductIds}
                    />
                  </Box>
                )}
                {isWarningVisible && (
                  <Box marginY="1">
                    <Alert appearance="warning">
                      {tScope(triggerScopeValueError)}
                    </Alert>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      </Card.Body>
    </Card>
  );
}
