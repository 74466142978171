import { InterfaceNameValue, Select } from '@tiendanube/components';
import useScrollToError from 'App/components/AppLayout/useScrollToError';
import { CardWithTitle, InputNumber } from 'commons/components';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import Skeleton from './Skeleton';

interface AgenciesInterface {
  code: number;
  description: string;
}

export interface OperationalValuesInterface {
  door: string;
  store: string;
  storeToDoor: string;
  storeToStore: string;
  agency: {
    code: string;
    address: string;
  };
  defaultOrigin: string;
}

interface OperationalCardProps {
  values: OperationalValuesInterface;
  errors: Partial<Record<string, string>>;
  agencies: AgenciesInterface[];
  door: string;
  onChange: (group: string, data: InterfaceNameValue) => void;
  onChangeAgency: (code: string) => void;
}

function OperationalCard({
  values,
  errors,
  agencies,
  door,
  onChange,
  onChangeAgency,
}: OperationalCardProps): JSX.Element {
  const t = useTranslationShipping();

  useScrollToError(errors);

  const selectAgencies = agencies.map((agency) => ({
    label: agency.description,
    value: `${agency.code}`,
  }));

  const selectDefaultOrigin = [
    {
      label: door,
      value: 'door',
    },
    {
      label:
        agencies.find((current) => current.code === Number(values.agency.code))
          ?.description || '',
      value: 'store',
    },
  ];

  const handleChange = (event: InterfaceNameValue) => {
    onChange('operational', event);
  };

  const handleChangeAgency = (event: InterfaceNameValue) => {
    onChangeAgency(event.value);
  };

  const error = {
    door: errors['operational.door'] || '',
    store: errors['operational.store'] || '',
    storeToDoor: errors['operational.storeToDoor'] || '',
    storeToStore: errors['operational.storeToStore'] || '',
  };

  return (
    <CardWithTitle title={t('oca.config.operational.title')}>
      <Stack column align="stretch">
        <InputNumber
          name="door"
          type="integer"
          label={t('oca.config.operational.door')}
          value={values.door}
          appearance={error.door ? 'validation_error' : 'default'}
          helpText={t(error.door)}
          onChange={handleChange}
        />

        <InputNumber
          name="store"
          type="integer"
          label={t('oca.config.operational.store')}
          value={values.store}
          appearance={error.store ? 'validation_error' : 'default'}
          helpText={t(error.store)}
          onChange={handleChange}
        />

        <InputNumber
          name="storeToDoor"
          type="integer"
          label={t('oca.config.operational.storeToDoor')}
          value={values.storeToDoor}
          appearance={error.storeToDoor ? 'validation_error' : 'default'}
          helpText={t(error.storeToDoor)}
          onChange={handleChange}
        />

        <InputNumber
          name="storeToStore"
          type="integer"
          label={t('oca.config.operational.storeToStore')}
          value={values.storeToStore}
          appearance={error.storeToStore ? 'validation_error' : 'default'}
          helpText={t(error.storeToStore)}
          onChange={handleChange}
        />

        <Select
          name="agency"
          label={t('oca.config.operational.agency')}
          value={values.agency.code}
          options={selectAgencies}
          onChange={handleChangeAgency}
        />

        <Text size="caption">{t('oca.config.operational.agencySubline')}</Text>
        <Select
          label={t('oca.config.operational.defaultOrigin')}
          name="defaultOrigin"
          value={values.defaultOrigin}
          options={selectDefaultOrigin}
          onChange={handleChange}
        />
      </Stack>
    </CardWithTitle>
  );
}

OperationalCard.Skeleton = Skeleton;

export default OperationalCard;
