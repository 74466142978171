/* eslint-disable max-statements */
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Domain, Status } from '@tiendanube/common/src/enums';
import { FEATURE_SEARCH_FILTER } from 'App/features';
import { ORDERS_ALLOW_PRODUCT_NAME_FILTER } from 'App/featuresFlags';
import {
  useResponsive,
  SearchFilter as SearchFilterOld,
} from 'commons/components';
import { SearchFilterInterfaceIdLabel } from 'commons/types';
import { dateFormat, Format, ninetyDaysAgo } from 'commons/utils/date';
import { ORDERS_SEARCH_ORDERS } from 'config/upsellFlowSources';
import { useGetTags } from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import formatDateFilterChips from 'domains/Metafields/utils/formatDateFilterChips';
import { METAFIELD_FILTER_PREFIX } from 'domains/Orders/constants';
import { useOrdersList } from 'domains/Orders/Orders/hooks';
import useGetOrdersFilters from 'domains/Orders/Orders/hooks/useGetOrdersFilters/useGetOrdersFilters';
import { useIsSavedSearchesEnabled } from 'domains/Orders/Orders/hooks/useIsSavedSearchesEnabled';
import {
  defaultFilters,
  FiltersParamsType,
  FilterStatusEnum,
  fulfillmentStatusFilter,
  paymentStatusFilter,
} from 'domains/Orders/Orders/ordersService';
import { getOrdersAggregations } from 'domains/Orders/Orders/ordersSlice';
import { useOrdersMetafields } from 'domains/Orders/Orders/pages/hooks';
import { SearchFilter } from 'domains/Orders/Orders/pages/OrderListPage/components/OrderListFilter/SearchFilter';
import { checkCustomPaymentProvider } from 'domains/Orders/Orders/utils/OrderListFilter/utils';
import {
  trackingOrderSearch,
  trackingOrderListFilterClick,
  trackingFilterChipClose,
  trackingClearFilters,
} from 'domains/Orders/tracking';
import { useGetShippingMethods } from 'domains/Shipping/DeliveryMethods/hooks';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations/useLocations';
import FilterModal from './FilterModal';
import { useFilterSelectedProducts } from '../../hooks/useFilterSelectedProducts';

interface OrderListFilterProps {
  showModal: boolean;
  filters: FiltersParamsType;
  ordersCount: number;
  isLoading: boolean;
  onChange: (filters: FiltersParamsType) => void;
  onOpenModal: () => void;
  onCloseModal: () => void;
  handleChangeSavedSearch: (newFilters: FiltersParamsType) => void;
}

function OrderListFilter({
  showModal,
  filters,
  ordersCount,
  isLoading,
  onChange,
  onOpenModal,
  onCloseModal,
  handleChangeSavedSearch,
}: OrderListFilterProps): JSX.Element | null {
  const { t } = useTranslation([Domain.ORDERS]);
  const { shippingMethods, fetchShippingMethods } = useGetShippingMethods();
  const { locations, fetchLocations } = useLocations();
  const {
    paymentProvidersOrdersFilters,
    applicationOrdersFilters,
    fetchOrdersFilters,
  } = useGetOrdersFilters();
  const { statusSuccess } = useOrdersList();
  const { isDesktop } = useResponsive();
  const tags = useGetTags();
  const { fetchMetafields } = useOrdersMetafields();

  const aggregations = useSelector(getOrdersAggregations);
  const [query, setQuery] = useState(filters.q || '');
  const handleChangeQuery = (value: string) => {
    setQuery(value);
  };

  const {
    selectedProducts,
    setSelectedProducts,
    serializeProducts,
    deserializeProducts,
  } = useFilterSelectedProducts(filters.products, aggregations);

  const isSavedSearchesEnabled = useIsSavedSearchesEnabled();

  const handleOpenModal = () => {
    onOpenModal();
    trackingOrderListFilterClick();
  };

  const handleChangeText = useUpsellFlow({
    title: t('filter.upsell.search'),
    featureKey: FEATURE_SEARCH_FILTER,
    trackingSource: ORDERS_SEARCH_ORDERS,
    callback: (text: string) => {
      onChange({ ...filters, status: FilterStatusEnum.ALL, q: text });
      trackingOrderSearch(text);
    },
  });

  const handleClearFilterButtonClick = () => {
    trackingClearFilters();
    onChange(defaultFilters);
  };

  const handleDismiss = (key: string) => {
    const hasFiltersWithDateRestrictionsApplied =
      filters.paymentMethods !== '' ||
      filters.paymentProvider !== '' ||
      filters.appId !== '';

    const dateAndPaymentsFilter =
      hasFiltersWithDateRestrictionsApplied && key === 'dateFrom';
    const dateAfterNinetyDays = filters.dateFrom > ninetyDaysAgo;

    if (dateAndPaymentsFilter && !dateAfterNinetyDays) return;

    let newValue: string;

    if (key.startsWith('variant-')) {
      const variantId = key.split('-')[1];
      newValue = serializeProducts(
        selectedProducts.filter((p) => p.variantId !== variantId),
      );
      setSelectedProducts((prev) =>
        prev.filter((p) => p.variantId !== variantId),
      );
      key = 'products';
    } else if (
      key.startsWith(`${paymentStatusFilter}-`) ||
      key.startsWith(`${fulfillmentStatusFilter}-`)
    ) {
      const [filterKey, filterValue] = key.split('-');
      newValue = filters[filterKey]
        .split(',')
        .filter((val) => val !== filterValue)
        .join();
      key = filterKey;
    } else {
      newValue =
        dateAndPaymentsFilter && dateAfterNinetyDays ? ninetyDaysAgo : '';
    }
    trackingFilterChipClose();
    onChange({ ...filters, [key]: newValue });
  };
  const withoutResult = statusSuccess && ordersCount === 0;

  const appliedFilters: SearchFilterInterfaceIdLabel[] = useMemo(
    () =>
      Object.entries(filters)
        .filter(
          ([key, value]) =>
            (value !== '' &&
              key !== 'page' &&
              key !== 'perPage' &&
              value !== FilterStatusEnum.ALL &&
              value !== 'open') ||
            (key === 'status' && value === FilterStatusEnum.ALL),
        )
        .flatMap(
          ([key, value]):
            | SearchFilterInterfaceIdLabel
            | SearchFilterInterfaceIdLabel[]
            | undefined => {
            if (key === 'shippingMethod') {
              const label = Object.values(shippingMethods)
                .flat()
                .find(({ code }) => code === value)?.name;
              return { id: key, label: label };
            }
            if (key === 'location') {
              const label = Object.values(locations)
                .flat()
                .find(({ id }) => id === value)?.name;
              return { id: key, label: label ?? '' };
            }
            if (checkCustomPaymentProvider(key, value)) {
              const label = t(`filters.paymentProvider.${value}`);
              return { id: key, label: label };
            }
            if (key === 'paymentProvider' && paymentProvidersOrdersFilters) {
              const label =
                paymentProvidersOrdersFilters &&
                Object.values(paymentProvidersOrdersFilters)
                  .flat()
                  .find(({ id }) => id === value)?.name;
              return { id: key, label: label ?? '' };
            }
            if (key === 'dateFrom') {
              const label = t('appliedFilters.dateFrom', {
                dateFrom: dateFormat(
                  value as string,
                  Format.DAY_MONTH_YEAR_NUMBER,
                ),
              });
              return { id: key, label: label };
            }
            if (key === 'dateTo') {
              const label = t('appliedFilters.dateTo', {
                dateTo: dateFormat(
                  value as string,
                  Format.DAY_MONTH_YEAR_NUMBER,
                ),
              });
              return { id: key, label: label };
            }
            if (key === 'status' && (value === '' || value === Status.OPEN)) {
              const label = t(`filters.status.${Status.OPEN}`);
              return { id: key, label: label };
            }
            if (key === 'paymentMethods') {
              return {
                id: key,
                label: t(`filters.paymentMethod.${value}`),
              };
            }
            if (key === 'appId' && applicationOrdersFilters) {
              const label = Object.values(applicationOrdersFilters)
                .flat()
                .find(({ id }) => id === value)?.name;
              return { id: key, label: label ?? '' };
            }
            if (key === 'productsCount') {
              return {
                id: key,
                label: `${t(`filter.productsCount`)}: ${value}`,
              };
            }
            if (key === 'products') {
              const deserializedProducts = deserializeProducts(value);
              return aggregations?.['variants']
                ?.map((product) => {
                  if (
                    deserializedProducts.find(
                      (dp) => dp.variantId === product.key.toString(),
                    )
                  ) {
                    return product;
                  }
                  return undefined;
                })
                .filter((p) => !!p && !!p.label)
                .map((product): SearchFilterInterfaceIdLabel => {
                  // Product should always be set but typescript check doesn't realize it
                  if (!product) {
                    return { id: '', label: '' };
                  }
                  return {
                    id: `variant-${product.key}`,
                    label: product.label,
                    badgeCount: product.total,
                  };
                });
            }

            if (
              key === paymentStatusFilter ||
              key === fulfillmentStatusFilter
            ) {
              return value.split(',').map((val) => ({
                id: `${key}-${val}`,
                label: t(`appliedFilters.${val}`, val as string),
              }));
            }

            return {
              id: key,
              label: t(`appliedFilters.${value}`, value as string),
            };
          },
        )
        .filter((f) => !!f)
        .map((filter) => {
          // Will never happen because of the previous filter but typescript check bugs with this case
          if (!filter) {
            return { id: '', label: '' };
          }

          const isMetafieldFilter = filter.id.startsWith(
            METAFIELD_FILTER_PREFIX,
          );
          const isRemovable =
            filter.id !== 'dateFrom' ||
            filters.dateFrom > ninetyDaysAgo ||
            (filters.paymentMethods === '' &&
              filters.paymentProvider === '' &&
              filters.appId === '');

          return {
            ...filter,
            label: isMetafieldFilter
              ? formatDateFilterChips(filter.label)
              : filter.label,
            removable: isRemovable,
          };
        }),
    [
      aggregations,
      deserializeProducts,
      applicationOrdersFilters,
      filters,
      locations,
      paymentProvidersOrdersFilters,
      shippingMethods,
      t,
    ],
  );

  const desktopPlaceholder = tags.includes(ORDERS_ALLOW_PRODUCT_NAME_FILTER)
    ? t('filter.inputLabelDesktopProductName')
    : t('filter.inputLabelDesktop');

  const placeholder = isDesktop ? desktopPlaceholder : t('filter.inputLabel');

  function buildResultCount(): string {
    if (isSavedSearchesEnabled) {
      return `${t('filter.result', { count: ordersCount })} ${t(
        'filter.filteringBy',
      )}`;
    } else {
      return appliedFilters.length > 0
        ? t('filter.result', { count: ordersCount })
        : t('filter.resultOpened', { count: ordersCount });
    }
  }

  const resultCount = isLoading ? '' : buildResultCount();
  const resultCountLabel = withoutResult ? '' : resultCount;
  const label = isDesktop ? t('filter.inputAriaLabel') : '';

  useEffect(() => {
    fetchMetafields();
  }, [fetchMetafields]);

  useEffect(() => {
    fetchShippingMethods();
  }, [fetchShippingMethods]);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  useEffect(() => {
    fetchOrdersFilters();
  }, [fetchOrdersFilters]);

  useEffect(() => {
    setQuery(filters.q ?? '');
  }, [filters.q]);

  return (
    <>
      {isSavedSearchesEnabled ? (
        <SearchFilter
          resultCount={resultCountLabel}
          isLoading={isLoading}
          filters={filters}
          appliedFilters={appliedFilters}
          value={query}
          handleChangeSavedSearch={handleChangeSavedSearch}
          onClearFilterButtonClick={handleClearFilterButtonClick}
          onSubmit={handleChangeText}
          onClick={handleOpenModal}
          onDismiss={handleDismiss}
          onChange={handleChangeQuery}
        />
      ) : (
        <SearchFilterOld
          placeholder={placeholder}
          onSubmit={handleChangeText}
          onClick={handleOpenModal}
          onDismiss={handleDismiss}
          resultCount={resultCountLabel}
          isLoading={isLoading}
          appliedFilters={appliedFilters}
          ariaLabel={t('filter.inputAriaLabel')}
          label={label}
          onChange={handleChangeQuery}
          desktopWrap="wrap"
          value={query}
        />
      )}
      <FilterModal
        show={showModal}
        hideModal={onCloseModal}
        filter={filters}
        addParam={onChange}
        selectedProducts={selectedProducts}
        addSelectedProductParam={setSelectedProducts}
        serializeProducts={serializeProducts}
      />
    </>
  );
}

export default OrderListFilter;
