import { FC } from 'react';
import { CheckIcon, IconProps, InfoCircleIcon } from '@nimbus-ds/icons';
import { KeyPrefix } from 'i18next';
import { Domain } from '@tiendanube/common/dist/src/enums';

type TraitType = 'feature' | 'info' | 'warning';

export const TRAIT_TYPE_ICON: Record<TraitType, FC<IconProps>> = {
  feature: CheckIcon,
  info: InfoCircleIcon,
  warning: InfoCircleIcon,
};

type TranslationKey = KeyPrefix<typeof Domain.ONLINE>;
type ThemeTrait = {
  type: TraitType;
  description: TranslationKey;
  descriptionValues?: Record<string, string>;
  showAsAlert?: boolean;
};

export const THEMES_TRAITS: Record<string, ThemeTrait[]> = {
  patagonia: [
    {
      type: 'feature',
      description: 'themesPage.themesTraits.benefits.faster',
    },
    {
      type: 'feature',
      description: 'themesPage.themesTraits.style.minimalist',
    },
    {
      type: 'feature',
      description: 'themesPage.themesTraits.benefits.ease',
    },
    {
      type: 'feature',
      description: 'themesPage.themesTraits.benefits.flexibility',
    },
    {
      type: 'warning',
      description: 'themesPage.themesTraits.appSupport.reduced',
      showAsAlert: true,
    },
  ],
};
