import { useGetSessionsQuery } from 'domains/Auth/authApi';
import { useIsLogged } from 'domains/Auth/hooks';

function useValidateCurrentUserSession(): boolean | null {
  const { isLogin } = useIsLogged();
  const { data: result } = useGetSessionsQuery(undefined, {
    skip: !isLogin,
  });
  if (result === undefined) {
    return true;
  }
  return result;
}

export default useValidateCurrentUserSession;
