import { Text } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { PromotionsItemListResponseDto } from '@tiendanube/common';
import { InternalNavLink, Stack } from 'commons/components';
import {
  StatusTagItem,
  Actions,
  DiscountType,
} from 'domains/Marketing/Promotions/components';
import { Limits } from './Limits';

interface PromotionsListMobileRowProps {
  promotion: PromotionsItemListResponseDto;
  index: number;
}

export function PromotionsListMobileRow({
  promotion: {
    id,
    name,
    discountType,
    discountTypeInfo,
    discountTypeInfoHeader,
    status,
    scope,
    scopeType,
    scopeVisibility,
    validity,
  },
  index,
}: Readonly<PromotionsListMobileRowProps>) {
  return (
    <DataList.Row backgroundColor="transparent" topDivider={index !== 0}>
      <Stack column align="stretch" gap="3">
        <Stack justify="space-between" align="center">
          <Stack.Item>
            <InternalNavLink to={`/promotions/edit/${id}`}>
              <Text color="primary-interactive" fontWeight="regular">
                {name}
              </Text>
            </InternalNavLink>

            <DiscountType
              discountType={discountType}
              discountTypeInfo={discountTypeInfo}
              discountTypeInfoHeader={discountTypeInfoHeader}
            />
          </Stack.Item>
          <Stack justify="flex-end" spacing="tight">
            <Actions status={status} id={id} />
          </Stack>
        </Stack>
        <StatusTagItem status={status} />
        <Limits
          scope={scope}
          scopeType={scopeType}
          validity={validity}
          scopeVisibility={scopeVisibility}
        />
      </Stack>
    </DataList.Row>
  );
}
