import { useCallback } from 'react';
import { generatePath } from 'react-router';
import { ChargeDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { useAppDispatch } from 'App/store';
import { combineStatus } from 'commons/utils/combineStatus';
import { useGetIsFreePlan, useStoreStatus } from 'domains/Auth/hooks';
import {
  checkoutApi,
  usePatchUpdatePlanChargeMutation,
  usePostAdvancedChargeMutation,
  usePostCreatePayOrderMutation,
} from 'domains/Billing/Checkout/CheckoutApi';
import { checkoutTags } from 'domains/Billing/Checkout/CheckoutApi/config';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import {
  usePaymentMethods,
  GO_TO_CHECKOUT_AFTER_PAYMENT,
  useAfterPaymentRedirection,
  useChargesToPay,
} from 'domains/Billing/Checkout/hooks';

export function useChargeCreation() {
  const { storeStatus } = useStoreStatus();
  const isChurned = storeStatus === 'store_down';
  const { isTrial } = useGetIsFreePlan();
  const { goTo } = useNavegate();

  const dispatch = useAppDispatch();

  const [advancePlanCharge, { status: advancePlanChargeStatus }] =
    usePostAdvancedChargeMutation();
  const [createPayOrder, { status: createPayOrderStatus }] =
    usePostCreatePayOrderMutation();
  const [updatePlanCharge, { status: updatePlanChargeStatus }] =
    usePatchUpdatePlanChargeMutation();

  const { paymentMethods } = usePaymentMethods();

  const { isLoading, isError, status } = combineStatus(
    advancePlanChargeStatus,
    createPayOrderStatus,
    updatePlanChargeStatus,
  );

  const { addAfterPayActionToPath } = useAfterPaymentRedirection();

  const { chargeTotalWithoutTaxes } = useChargesToPay(false);

  const onContinue = useCallback(
    async (charges: ChargeDto[]) => {
      if (!charges.length) return;
      let createdCharge: ChargeDto | undefined = undefined;
      const virtualPlanCharge = charges?.find(
        ({ id }) => id === 'virtual-charge',
      );
      const extraCharges = charges.filter(
        (charge) => charge !== virtualPlanCharge,
      );
      if (virtualPlanCharge && isChurned) {
        const method = paymentMethods?.[0].method;
        const option = paymentMethods?.[0].recurrencyOptions[0];

        if (!method || !option) return;

        createdCharge = await updatePlanCharge({
          method,
          recurringFrequency: option.recurringFrequency,
          recurringInterval: option.recurringInterval,
        }).unwrap();
      } else if (virtualPlanCharge && isTrial) {
        createdCharge = await advancePlanCharge().unwrap();
      }
      const chargesIds = extraCharges?.map(({ id }) => id) || [];
      const payOrder = await createPayOrder([
        ...(createdCharge ? [createdCharge.id] : []),
        ...chargesIds,
      ])
        .unwrap()
        .finally(() => {
          dispatch(
            checkoutApi.util.invalidateTags([
              checkoutTags.pendingCharges,
              checkoutTags.payOrderToPay,
            ]),
          );
        });
      if (!payOrder) return;
      goTo(
        addAfterPayActionToPath(
          generatePath(CHECKOUT_ROUTES.choosePaymentMethod, {
            payOrderId: payOrder.id,
          }),
          GO_TO_CHECKOUT_AFTER_PAYMENT,
        ),
      );
    },
    [
      isChurned,
      isTrial,
      advancePlanCharge,
      createPayOrder,
      goTo,
      paymentMethods,
      updatePlanCharge,
      dispatch,
      addAfterPayActionToPath,
    ],
  );

  return { onContinue, isLoading, isError, status, chargeTotalWithoutTaxes };
}
