import { useMemo } from 'react';
import { UserSessionDto } from '@tiendanube/common';
import { useListMobile } from 'App/hooks';
import { RESULTS_PER_PAGE_MOBILE } from '../settings';
import { UserSessionEndpoints } from '../userSessionsApi';

export function useGetUserSessionsListMobile() {
  const result = useListMobile<UserSessionDto>({
    filters: {
      perPage: RESULTS_PER_PAGE_MOBILE,
    },
    endpoint: UserSessionEndpoints.getUserSessionsInf,
  });

  const ids = useMemo(
    () => result.data.map((current) => current.id),
    [result.data],
  );

  return { ...result, ids };
}
