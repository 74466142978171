import { Box, Link, LinkBaseProps, Spinner } from '@nimbus-ds/components';
import { InterfaceLink } from '@tiendanube/components';
import { appearanceMap, sizeMap, spinnerColorMap } from './mappings';

/**
 * @deprecated Used to replace old nimbus \<Link /> (from tiendanube/components). It renders an updated \<Link /> from the new nimbus-ds.
 *
 * **For new implementations, it is mandatory to use nimbus-ds/components**
 */
function LegacyLink({
  children,
  appearance = 'default',
  size = 'base',
  underline,
  spinner,
  icon: Icon,
  iconPosition = 'start',
  iconSize,
  disabled,
  onClick,
  target = '_blank',
  ...rest
}: InterfaceLink) {
  // Common props for both anchor and button type links
  const baseProps: Omit<LinkBaseProps, 'children'> & {
    target: InterfaceLink['target'];
  } = {
    fontSize: sizeMap[size],
    appearance: appearanceMap[appearance],
    textDecoration: underline ? 'underline' : 'none',
    target,
    onClick,
  };

  // Content of the link, wrapped in a Box to be able to align icons and to add the required 1px padding (as border)
  const linkContent = (
    <Box
      display="flex"
      gap="1"
      flexDirection={iconPosition === 'start' ? 'row' : 'row-reverse'}
      alignItems="center"
      // 1px border to align exactly with the @tiendanube/components version
      borderWidth="1"
      borderColor="transparent"
      borderStyle="solid"
    >
      {spinner && <Spinner color={spinnerColorMap[appearance]} size="small" />}
      {Icon ? <Icon size={iconSize} /> : null}
      {children}
    </Box>
  );

  // If disabled, we render a button type link, with the disabled attribute
  if (disabled) {
    return (
      <Link as="button" disabled {...baseProps}>
        {linkContent}
      </Link>
    );
  }

  // Render the link as usual
  return (
    <Link {...baseProps} {...rest}>
      {linkContent}
    </Link>
  );
}

/**
 * @deprecated Used to replace old nimbus \<Link.Skeleton /> (from tiendanube/components). It renders an updated \<Link.Skeleton /> from the new nimbus-ds.
 *
 * **For new implementations, it is mandatory to use nimbus-ds/components**
 */
LegacyLink.Skeleton = Link.Skeleton;

export default LegacyLink;
