import { Box } from '@nimbus-ds/components';
import { HistoryIcon } from '@nimbus-ds/icons';
import { VariantResponseDto } from '@tiendanube/common';
import { DataTable, Text, InterfaceNameValue } from '@tiendanube/components';
import { ActionIconButton } from 'commons/components';
import { useToast } from 'commons/hooks';
import { useHasPermission } from 'domains/Auth/hooks';
import { HiddenVariantTag } from 'domains/Catalog/Categories/components';
import {
  useInventory,
  useInventoryItem,
  useVariantsRange,
} from 'domains/Catalog/Inventory/pages/hooks';
import { joinVariantValues } from 'domains/Catalog/Inventory/utils';
import { EditStockPopover } from 'domains/Catalog/Products/pages/components/Stock';
import {
  trackingProductStockChangeOnInventoryList,
  trackingProductStockInventoryFromInventory,
} from 'domains/Catalog/Products/tracking';
import { stockForLocation } from 'domains/Catalog/Products/utils';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { InputAsyncStock, ProductCell, VariantCell } from './components';
import useInventoryProductActions from '../../../../hooks/useInventoryProductActions';
import { ShowHideVariantsLink } from '../../../ShowHideVariantsLink';
import {
  actionsColBasis,
  inputColBasis,
  inputColGrow,
  editColBasis,
  productColGrow,
  inputColVariant,
} from '../../constants';
import './ResultRowDesktop.scss';

interface ResultRowDesktopProps {
  id: string;
  locationId?: string;
}

const { Row, Cell } = DataTable;
const maxEntries = 10;

function ResultRowDesktop({
  id,
  locationId,
}: ResultRowDesktopProps): JSX.Element {
  const t = useTranslationCatalog();

  const canEditStock = useHasPermission('edit_stock');

  const { product, language } = useInventoryItem(id);
  const { editStock } = useInventory();
  const { addToast } = useToast();

  const range = useVariantsRange(product, maxEntries);
  const variants = range.variants;

  const handleEditStock =
    (id: string, locationId?: string) =>
    async ({ value }: InterfaceNameValue) => {
      trackingProductStockChangeOnInventoryList();
      await editStock({
        productId: product.id,
        action: 'replace',
        value: value !== '' ? Number(value) : null,
        variantId: id,
        locationId,
      });
    };

  const onErrorStock = () => {
    addToast({
      appearance: 'danger',
      label: t('products.toast.errorUpdateStock'),
    });
  };

  const { showAccessToStockHistory, handleOpenStockHistoryModal } =
    useInventoryProductActions(id, locationId);

  const handleViewStockHistory = (variant: VariantResponseDto) => {
    trackingProductStockInventoryFromInventory();
    handleOpenStockHistoryModal(variant);
  };

  return (
    <div className="stratus--product-result-desktop-result-row-desktop">
      <Row id={id} align="center">
        <Cell grow={productColGrow}>
          <ProductCell product={product} maxTagsAndMaxTags={maxEntries} />
        </Cell>
        <Cell grow={inputColGrow} basis={inputColBasis}>
          <Box display="flex" flexDirection="column" gap="2">
            {variants.map((variant) => (
              <InputAsyncStock
                asyncFunc={handleEditStock(variant.id, locationId)}
                key={variant.id}
                defaultValue={stockForLocation(variant, locationId)}
                onError={onErrorStock}
                disabled={!canEditStock}
              />
            ))}
            <ShowHideVariantsLink {...range} tracking />
          </Box>
        </Cell>

        <Cell grow={0} basis={editColBasis}>
          <Box display="flex" flexDirection="column" gap="2">
            {variants.map(
              (variant) =>
                canEditStock && (
                  <VariantCell key={variant.id}>
                    <EditStockPopover
                      key={variant.id}
                      productId={variant.product_id}
                      variantId={variant.id}
                      displayApplyToAll={variant.values.length > 0}
                      currentStock={stockForLocation(variant, locationId)}
                      locationId={locationId}
                    />
                  </VariantCell>
                ),
            )}
          </Box>
        </Cell>

        <Cell grow={productColGrow}>
          <Box display="flex" flexDirection="column" gap="2">
            {variants.map((variant) => (
              <VariantCell key={variant.id}>
                <Text key={variant.id} block trimText trimLines={1}>
                  {joinVariantValues(variant, language)}
                </Text>
                <HiddenVariantTag visible={variant.visible ?? true} />
              </VariantCell>
            ))}
          </Box>
        </Cell>
        <Cell grow={inputColGrow} basis={inputColVariant}>
          <Box display="flex" flexDirection="column" gap="2">
            {variants.map((variant) => (
              <VariantCell key={variant.id}>
                <Text key={variant.id} block trimText trimLines={1}>
                  {variant.sku
                    ? variant.sku
                    : t('inventory.table.row.emptySku')}
                </Text>
              </VariantCell>
            ))}
          </Box>
        </Cell>
        <Cell grow={inputColGrow} basis={actionsColBasis}>
          <Box display="flex" flexDirection="column" gap="2">
            {variants.map((variant) => (
              <VariantCell key={variant.id}>
                {showAccessToStockHistory && (
                  <ActionIconButton
                    content={t('inventory.actions.viewStockHistory')}
                    source={<HistoryIcon />}
                    onClick={() => handleViewStockHistory(variant)}
                  />
                )}
              </VariantCell>
            ))}
          </Box>
        </Cell>
      </Row>
    </div>
  );
}

export default ResultRowDesktop;
