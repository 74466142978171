import { DataList } from '@tiendanube/components';
import { Repeater } from 'commons/components';
import PagoNubeInvoiceHistoryDataTableRowSkeleton from './components/PagoNubeInvoiceHistoryDataTableRowSkeleton';

function PagoNubeInvoiceHistoryDataTableSkeleton(): JSX.Element {
  return (
    <DataList>
      <Repeater times={7} item={PagoNubeInvoiceHistoryDataTableRowSkeleton} />
    </DataList>
  );
}

export default PagoNubeInvoiceHistoryDataTableSkeleton;
