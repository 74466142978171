import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ImageUploadResponseDto,
  ProductDetailsResponseDto,
  ProductCreateRequestDto,
  VariantResponseDto,
  ResultPaginationResponseDto,
  SearchProductListResponseDto,
  ProductsTokenResponseDto,
  UpdateStockResponseDto,
  AssignProductsToCategoriesRequestDto,
  ProductCloneResponseDto,
  StockHistoryResponseDto,
} from '@tiendanube/common';
import { logout } from 'domains/Auth/authSlice';
import { INITIAL_LOAD_VARIANTS, initialState } from './constants';
import { getSelectProductListPaginationSelector } from './productSelectors';
import {
  statusType,
  ProductEntitiesType,
  ProductsAndFiltersInterface,
  ThunkStateType,
  MetafieldsPersistsStatusType,
  ProductsInterface,
  statusSuccessType,
  SearchParamsInterface,
  getProductsActionRequestType,
} from './types';
import { updateVariantStock } from './utils';
import { getErrorsFromSectionCodes } from '../pages/EditProductPage/digests';
import productsServices, {
  UpdateProductsParamsInterface,
  UpdateProductParamsInterface,
  CloneProductToParamsInterface,
  EditVariantParamsInterface,
  ProductsFiltersType,
  EditStockParams,
  GetStockHistoryParams,
  FixesAttributesType,
  GetProductVariantsParams,
  GetProductByIdParams,
} from '../productsServices';
import {
  trackingProductVariantMetafieldFilterError,
  trackingProductVariantMetafieldSaveError,
} from '../tracking';

const getProductsInvoke = async ({ filters, type, version }) => {
  if (type === 'normal') {
    const { products, total, status, sortBy } =
      await productsServices.getProducts({ filters, version });
    return { products, filters, total, status, sortBy };
  }

  const maxVariants =
    type === 'advancedLazyVariants' ? INITIAL_LOAD_VARIANTS : undefined;

  const { products, total, status, sortBy } =
    await productsServices.getProductsAdvanced({
      filters,
      maxVariants,
      version,
    });
  return { products, filters, total, status, sortBy };
};

export const getProducts = createAsyncThunk<
  ProductsAndFiltersInterface,
  getProductsActionRequestType,
  ThunkStateType
>('catalog/products/getProducts', getProductsInvoke);

export const getMoreProducts = createAsyncThunk<
  ProductsAndFiltersInterface,
  getProductsActionRequestType,
  ThunkStateType
>('catalog/products/getMoreProducts', getProductsInvoke);

export const getProductVariants = createAsyncThunk<
  VariantResponseDto[],
  GetProductVariantsParams
>('catalog/products/getProductsVariants', async (params) => {
  const variants = await productsServices.getProductVariants(params);
  return variants;
});

export const updateProducts = createAsyncThunk<
  ProductDetailsResponseDto[],
  UpdateProductsParamsInterface
>('catalog/products/updateProducts', async (params) => {
  const productsUpdated = await productsServices.updateProducts(params);
  return productsUpdated;
});

export const addImage = createAsyncThunk<ImageUploadResponseDto, File | string>(
  'catalog/products/uploadImage',
  async (params) => {
    const response = await productsServices.uploadImage(params);
    return response;
  },
);

export const updateProduct = createAsyncThunk<
  ProductDetailsResponseDto,
  UpdateProductParamsInterface
>('catalog/products/updateProduct', async (params) => {
  const productUpdated = await productsServices.updateProduct(params);
  return productUpdated;
});

export const getProductById = createAsyncThunk<
  ProductDetailsResponseDto,
  GetProductByIdParams
>('catalog/products/getProductById', async (params) => {
  const product = await productsServices.getProductById(params);
  return product;
});

export const deleteProducts = createAsyncThunk<void, string[]>(
  'catalog/products/deleteProducts',
  async (ids) => {
    await productsServices.deleteProducts(ids);
  },
);

export const deleteProduct = createAsyncThunk<void, string>(
  'catalog/products/deleteProduct',
  async (id) => {
    await productsServices.deleteProduct(id);
  },
);

export const addProduct = createAsyncThunk<
  ProductDetailsResponseDto,
  { product: ProductCreateRequestDto }
>('catalog/products/addProduct', async ({ product }) => {
  const productResponse = productsServices.addProduct(product);
  return productResponse;
});

export const cloneProduct = createAsyncThunk<
  ProductCloneResponseDto,
  CloneProductToParamsInterface
>('catalog/products/cloneProduct', async (payload) => {
  const productResponse = await productsServices.cloneProduct(payload);
  return productResponse;
});

export const editPricesProduct = createAsyncThunk(
  'catalog/products/editPricesProduct',
  productsServices.editPricesProduct,
);

export const assignCategories = createAsyncThunk<
  void,
  AssignProductsToCategoriesRequestDto
>('catalog/products/assignCategories', async (payload) => {
  await productsServices.assignCategories(payload);
});

export const getStandardVariantsAction = createAsyncThunk(
  'catalog/products/getStandardVariantsAction',
  productsServices.getStandardVariants,
);

export const editVariants = createAsyncThunk<
  VariantResponseDto,
  EditVariantParamsInterface
>('catalog/products/editVariants', async (params) => {
  const editedVariant = await productsServices.updateVariant(params);
  return editedVariant;
});

export const editStock = createAsyncThunk<
  { productId: string; response: UpdateStockResponseDto[] },
  EditStockParams
>('catalog/products/editStock', async (params) => {
  const response = await productsServices.updateStock(params);
  return { productId: params.productId, response };
});

export const existsProductsWithoutWeightAndDimensions = createAsyncThunk<any>(
  'catalog/products/existsProductsWithoutWeightAndDimensions',
  async () => await productsServices.existsProductsWithoutWeightAndDimensions(),
);

export const getSelectProductList = createAsyncThunk<
  ResultPaginationResponseDto<SearchProductListResponseDto[]>,
  SearchParamsInterface
>('catalog/products/getSelectProductList', async (search) =>
  productsServices.getSelectProductList(search),
);

export const getMoreSelectProductList = createAsyncThunk<
  ResultPaginationResponseDto<SearchProductListResponseDto[]>,
  SearchParamsInterface,
  ThunkStateType
>('catalog/products/getMoreSelectProductList', async (search, thunkApi) => {
  const state = thunkApi.getState();
  const pagination = getSelectProductListPaginationSelector(state);
  if (!pagination?.nextPage) {
    throw new Error('no valid fetch');
  }
  return await productsServices.getSelectProductList({
    ...search,
    page: pagination.nextPage,
  });
});

export const fetchTotalProducts = createAsyncThunk<
  { total: number; filters: ProductsFiltersType },
  ProductsFiltersType
>('catalog/products/fetchTotalProducts', async (filters) => {
  const total = await productsServices.fetchTotalProducts(filters);
  return { total, filters };
});

export const exportProducts = createAsyncThunk<
  ProductsTokenResponseDto,
  ProductsFiltersType
>('catalog/products/exportProducts', async (filters) => {
  const data = await productsServices.exportProducts(filters);
  return data;
});

export const updateInventoryLevels = createAsyncThunk(
  'catalog/products/updateInventoryLevels',
  productsServices.updateInventoryLevels,
);

export const getImportMaxLines = createAsyncThunk(
  'catalog/products/import/getImportMaxLines',
  productsServices.getImportMaxLines,
);

export const getStockHistory = createAsyncThunk<
  StockHistoryResponseDto[],
  GetStockHistoryParams
>('catalog/products/getStockHistory', async (params) =>
  productsServices.getStockHistory(params),
);

export const generateProductDescription = createAsyncThunk(
  'product/generateDescription',
  productsServices.generateProductDescription,
);

export const generateProductSeo = createAsyncThunk(
  'product/generateSeo',
  productsServices.generateProductSeo,
);

export const generateProductAltText = createAsyncThunk(
  'product/generateProductAltText',
  productsServices.generateProductAltText,
);

const applyMetafieldPersistStatus = (
  state: ProductsInterface,
  id: string,
  variants: VariantResponseDto[],
  errorOnEvent: MetafieldsPersistsStatusType,
) => {
  const isError = variants.some((variant) => variant.metafields === 'error');
  state.metafieldsPersistsStatus[id] = isError ? errorOnEvent : 'success';
  isError && trackingProductVariantMetafieldSaveError(id, errorOnEvent);
};

const applyStatusSuccess = (
  state: ProductsInterface,
  statusSuccess: statusSuccessType,
) => {
  state.statusSuccess = statusSuccess;
  statusSuccess === 'errorMetafield' &&
    trackingProductVariantMetafieldFilterError();
};

export const fixesAttributesAction = createAsyncThunk<
  void,
  FixesAttributesType
>('catalog/products/fix-attributes', async (payload) =>
  productsServices.fixesAttributesVariants(payload),
);

export const tagsByStoreAction = createAsyncThunk(
  'catalog/products/tags',
  productsServices.fetchTotalTags,
);

const products = createSlice({
  name: 'products',
  initialState,
  reducers: {
    init(state) {
      state.status = statusType.loading;
    },
    cleanMetafieldsPersistsStatus(state, action) {
      delete state.metafieldsPersistsStatus[action.payload.id];
    },
    cleanStatusVariants(state, action) {
      delete state.statusVariants[action.payload.id];
    },
    cleanCreateHighlightProductStatus(state) {
      state.createHighlightProductStatus =
        initialState.createHighlightProductStatus;
    },
    cleanUpdateInventoryLevels(state) {
      state.statusInventoryLevels = initialState.statusInventoryLevels;
    },
    cleanExportProducts(state) {
      state.statusExportProducts = 'idle';
    },
    cleanGetStockHistory(state) {
      state.statusStockHistory = initialState.statusStockHistory;
    },
    cleanFixAttributes(state) {
      state.fixesAttributes.status = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState;
      return state;
    });

    builder.addCase(getProducts.pending, (state, action) => {
      state.status = statusType.loading;
      state.statusSuccess = statusType.idle;
      state.entities = {};
      state.variantStock = {};
      state.summaries = {};
      state.ids = [];
      state.statusVariants = {};
      state.currentRequestID = action.meta.requestId;
      state.totalProducts = 0;
      state.appliedFilters = {
        ...action.meta.arg.filters,
        sortBy: state.appliedFilters.sortBy,
      };
      return state;
    });

    builder.addCase(getProducts.fulfilled, (state, action) => {
      if (state.currentRequestID === action.meta.requestId) {
        const ids: string[] = [];
        state.entities = action.payload.products.reduce((acc, product) => {
          acc[product.id.toString()] = product;
          ids.push(product.id.toString());
          product.variants.forEach((variant) => {
            state.variantStock[variant.id] = variant.stock;
          });
          if (product.summary) {
            state.summaries[product.id] = product.summary;
          }
          return acc;
        }, {} as ProductEntitiesType);
        state.status = statusType.success;
        applyStatusSuccess(state, action.payload.status);
        state.ids = ids;
        state.appliedFilters = {
          ...action.payload.filters,
          sortBy: action.payload.sortBy,
        };
        state.totalProducts = action.payload.total;
        return state;
      }
    });

    builder.addCase(getProducts.rejected, (state) => {
      state.status = statusType.error;
      return state;
    });

    builder.addCase(getMoreProducts.pending, (state) => {
      state.status = statusType.loading;
      state.statusSuccess = statusType.idle;
      return state;
    });

    builder.addCase(getMoreProducts.fulfilled, (state, action) => {
      const ids: string[] = [];
      action.payload.products.forEach((product) => {
        state.entities[product.id] = product;
        ids.push(product.id.toString());
        product.variants.forEach((variant) => {
          state.variantStock[variant.id] = variant.stock;
        });
        if (product.summary) {
          state.summaries[product.id] = product.summary;
        }
      });
      state.status = statusType.success;
      applyStatusSuccess(state, action.payload.status);
      state.ids = state.ids.concat(ids);
      state.appliedFilters = action.payload.filters;
      state.totalProducts = action.payload.total;

      return state;
    });

    builder.addCase(getMoreProducts.rejected, (state) => {
      state.status = statusType.error;
      return state;
    });

    builder.addCase(getProductVariants.pending, (state, { meta }) => {
      state.statusVariants[meta.arg.productId] = statusType.loading;
      return state;
    });
    builder.addCase(
      getProductVariants.fulfilled,
      (state, { meta, payload }) => {
        state.statusVariants[meta.arg.productId] = statusType.success;
        state.entities[meta.arg.productId].variants = payload;
        payload.forEach((variant) => {
          state.variantStock[variant.id] = variant.stock;
        });
        return state;
      },
    );
    builder.addCase(getProductVariants.rejected, (state, { meta }) => {
      state.statusVariants[meta.arg.productId] = statusType.error;
      return state;
    });

    builder.addCase(updateProducts.fulfilled, (state, action) => {
      action.payload.forEach((product) => {
        state.entities[product.id] = product;
      });
      return state;
    });

    builder.addCase(getProductById.pending, (state, action) => {
      state.statusDetails = statusType.loading;
      state.currentDetailsRequestID = action.meta.requestId;
      return state;
    });

    builder.addCase(getProductById.fulfilled, (state, action) => {
      const variants = action.payload.variants;
      if (state.currentDetailsRequestID === action.meta.requestId) {
        state.statusDetails = statusType.success;
        state.entities[action.payload.id] = action.payload;
        state.variantStock = {
          ...state.variantStock,
          ...updateVariantStock(variants),
        };
      }
    });

    builder.addCase(getProductById.rejected, (state) => {
      state.statusDetails = statusType.error;
      return state;
    });

    builder.addCase(updateProduct.fulfilled, (state, action) => {
      const { id, variants } = action.payload;
      state.entities[id] = action.payload;

      state.variantStock = {
        ...state.variantStock,
        ...updateVariantStock(variants),
      };
      if (state.summaries[action.payload.id]) {
        // Since the update service does not return the updated summary field,
        // it is then removed from the storage so that the mobile list retrieves
        // the totals based on the product variants.
        delete state.summaries[action.payload.id];
      }
      applyMetafieldPersistStatus(state, id, variants, 'errorOnUpdate');
      products.caseReducers.cleanCreateHighlightProductStatus(state);
      return state;
    });

    builder.addCase(deleteProduct.fulfilled, (state, action) => {
      delete state.entities[action.meta.arg];
      state.ids = state.ids.filter((id) => id !== action.meta.arg);
      return state;
    });
    builder.addCase(deleteProducts.fulfilled, (state, action) => {
      action.meta.arg.forEach((ProductId) => {
        delete state.entities[ProductId];
        state.ids = state.ids.filter((id) => id !== ProductId);
      });
      return state;
    });

    builder.addCase(addProduct.fulfilled, (state, action) => {
      const { id, variants } = action.payload;
      state.entities[id] = action.payload;
      state.ids = [id, ...state.ids];
      applyMetafieldPersistStatus(state, id, variants, 'errorOnCreate');
      state.createHighlightProductStatus = getErrorsFromSectionCodes(
        action.payload.sectionCodes,
      );
    });

    builder.addCase(cloneProduct.fulfilled, (state, action) => {
      const { product } = action.payload;
      const { id, variants } = product;
      state.entities[id] = product;
      state.ids = [id, ...state.ids];
      applyMetafieldPersistStatus(state, id, variants, 'errorOnClone');
    });

    builder.addCase(editVariants.fulfilled, (state, action) => {
      state.entities[action.payload.product_id].variants = state.entities[
        action.payload.product_id
      ].variants.map((variant) => {
        if (variant.id === action.payload.id) {
          return action.payload;
        }
        return variant;
      });
    });

    builder.addCase(editStock.fulfilled, (state, { meta, payload }) => {
      payload.response.forEach((variant) => {
        state.variantStock[variant.variantId] = variant.newStock;
        if (variant.summary) {
          state.summaries[meta.arg.productId] = variant.summary;
        }
      });
    });

    builder.addCase(getStandardVariantsAction.rejected, (state) => {
      state.standardVariants.status = statusType.error;
    });

    builder.addCase(getStandardVariantsAction.pending, (state) => {
      state.standardVariants.status = statusType.loading;
    });

    builder.addCase(getStandardVariantsAction.fulfilled, (state, action) => {
      state.standardVariants.status = statusType.success;
      state.standardVariants.data = action.payload;
    });

    builder.addCase(getSelectProductList.fulfilled, (state, action) => {
      state.selectProductsList.data = action.payload.results;
      state.selectProductsList.pagination = action.payload.pagination;
      state.selectProductsList.status = 'success';
    });

    builder.addCase(getSelectProductList.pending, (state) => {
      state.selectProductsList.data = null;
      state.selectProductsList.status = 'loading';
    });

    builder.addCase(getSelectProductList.rejected, (state, action) => {
      state.selectProductsList.data = null;
      state.selectProductsList.status = 'error';
      state.selectProductsList.statusCode = action.error.message || '';
    });

    builder.addCase(getMoreSelectProductList.fulfilled, (state, action) => {
      const { results, pagination } = action.payload;
      state.selectProductsList.data =
        state.selectProductsList.data &&
        state.selectProductsList.data.concat(results);
      state.selectProductsList.pagination = pagination;
      state.selectProductsList.refreshStatus = 'success';
    });

    builder.addCase(getMoreSelectProductList.rejected, (state) => {
      state.selectProductsList.refreshStatus = 'error';
    });

    builder.addCase(getMoreSelectProductList.pending, (state) => {
      state.selectProductsList.refreshStatus = 'loading';
    });

    builder
      .addCase(fetchTotalProducts.pending, (state, action) => {
        state.currentRequestID = action.meta.requestId;
        state.export.status = 'loading';
      })
      .addCase(fetchTotalProducts.rejected, (state) => {
        state.export.status = 'error';
      })
      .addCase(fetchTotalProducts.fulfilled, (state, action) => {
        if (state.currentRequestID === action.meta.requestId) {
          state.export.totalResults = Number(action.payload.total);
          state.export.status = 'success';
        }
      });

    builder
      .addCase(updateInventoryLevels.pending, (state) => {
        state.statusInventoryLevels = 'loading';
      })
      .addCase(updateInventoryLevels.rejected, (state) => {
        state.statusInventoryLevels = 'error';
      })
      .addCase(updateInventoryLevels.fulfilled, (state) => {
        state.statusInventoryLevels = 'success';
      });

    builder
      .addCase(exportProducts.pending, (state) => {
        state.statusExportProducts = 'loading';
      })
      .addCase(exportProducts.rejected, (state) => {
        state.statusExportProducts = 'error';
      })
      .addCase(exportProducts.fulfilled, (state) => {
        state.statusExportProducts = 'success';
      });

    builder
      .addCase(getImportMaxLines.pending, (state) => {
        state.importMaxLines.status = 'loading';
      })
      .addCase(getImportMaxLines.rejected, (state) => {
        state.importMaxLines.status = 'error';
      })
      .addCase(getImportMaxLines.fulfilled, (state, action) => {
        if (action.payload.maxLines !== null) {
          state.importMaxLines.max = action.payload.maxLines;
        }
        state.importMaxLines.status = 'success';
      });

    builder
      .addCase(getStockHistory.pending, (state) => {
        state.stockHistory = [];
        state.statusStockHistory = 'loading';
      })
      .addCase(getStockHistory.rejected, (state) => {
        state.statusStockHistory = 'error';
      })
      .addCase(getStockHistory.fulfilled, (state, action) => {
        state.stockHistory = action.payload;
        state.statusStockHistory = 'success';
      });

    builder
      .addCase(fixesAttributesAction.pending, (state) => {
        state.fixesAttributes.status = 'loading';
      })
      .addCase(fixesAttributesAction.fulfilled, (state) => {
        state.fixesAttributes.status = 'success';
      })
      .addCase(fixesAttributesAction.rejected, (state) => {
        state.fixesAttributes.status = 'error';
      });

    builder
      .addCase(tagsByStoreAction.pending, (state) => {
        state.standardTags.status = statusType.loading;
      })
      .addCase(tagsByStoreAction.fulfilled, (state, action) => {
        state.standardTags.status = statusType.success;
        state.standardTags.data = action.payload;
      })
      .addCase(tagsByStoreAction.rejected, (state) => {
        state.standardTags.status = statusType.error;
      });
  },
});

export const { reducer } = products;

export const {
  cleanMetafieldsPersistsStatus,
  cleanStatusVariants,
  cleanCreateHighlightProductStatus,
  cleanUpdateInventoryLevels,
  cleanExportProducts,
  cleanGetStockHistory,
  cleanFixAttributes,
} = products.actions;
