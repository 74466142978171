export const ORDERS_PER_PAGE = 50;
export const APP_ID_CUSTOM_PAYMENTS = '5315';
export const ORDERS_LIST_TIMEOUT_ERROR = 'timeoutError';
export const CUSTOM_GATEWAY = 'custom';
export const PAYMENT_METHOD_OTHER = 'other';
export const LOG_TYPE_ORDER_EDIT = 'order-edit';
const ES_RESULT_LIMIT = 10_000;
export const ORDERS_PAGE_LIMIT = ES_RESULT_LIMIT / ORDERS_PER_PAGE;
export const SAVED_SEARCHES_COUNT_LIMIT = 999;

export const appearances = {
  secondary: 'neutral',
  transparent: 'neutral',
  primary: 'primary',
  success: 'success',
  default: 'neutral',
  warning: 'warning',
  danger: 'danger',
};
