import { Card } from '@nimbus-ds/components';
import { InteractiveList } from '@nimbus-ds/patterns';
import { MethodItem } from 'commons/components';
import Stack from 'commons/components/Stack';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping/useTranslationShipping';

function EnabledShippingMethodsSkeleton() {
  const t = useTranslationShipping();
  const title = t(
    'deliveryMethods.activeShippingMethods.modal.title.allAvailable',
  );

  return (
    <Card padding="none">
      <Card.Header title={title} padding="base" />
      <Card.Body>
        <Stack column>
          <InteractiveList bottomDivider={false}>
            <InteractiveList.ButtonItemSkeleton title="">
              <MethodItem.Skeleton />
            </InteractiveList.ButtonItemSkeleton>
            <InteractiveList.ButtonItemSkeleton title="">
              <MethodItem.Skeleton />
            </InteractiveList.ButtonItemSkeleton>
            <InteractiveList.ButtonItemSkeleton title="">
              <MethodItem.Skeleton />
            </InteractiveList.ButtonItemSkeleton>
            <InteractiveList.ButtonItemSkeleton title="">
              <MethodItem.Skeleton />
            </InteractiveList.ButtonItemSkeleton>
          </InteractiveList>
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default EnabledShippingMethodsSkeleton;
