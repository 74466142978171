import { useEffect, useState } from 'react';
import { Alert, Text } from '@nimbus-ds/components';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import useOnboardingTasks from 'domains/Dashboard/pages/DashboardPage/components/OnboardingTasks/useOnboardingTasks';

function OnboardingCompletedAlert() {
  const t = useTranslationDashboard();
  const { isPos } = useOnboardingTasks();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const alertShown = localStorage.getItem('onboarding-202411-completed');
    if (!alertShown) {
      setShow(true);
    }
  }, []);

  const handleRemove = () => {
    localStorage.setItem('onboarding-202411-completed', 'true');
    setShow(false);
  };

  let title = 'onboardingCompleteAlert.title';
  let description = 'onboardingCompleteAlert.description';

  if (isPos) {
    title = 'posOnboardingCompleteAlert.title';
    description = 'posOnboardingCompleteAlert.description';
  }

  return (
    <Alert
      appearance="success"
      show={show}
      title={t(`${title}`)}
      onRemove={handleRemove}
    >
      <Text>{t(`${description}`)}</Text>
    </Alert>
  );
}

export default OnboardingCompletedAlert;
