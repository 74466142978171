import { ChangeEvent } from 'react';
import { Stack } from 'commons/components';
import Skeleton from './Skeleton';
import {
  ShippingConditionsCard,
  ShippingInformationCard,
  ShippingTermCard,
} from '../../../components';
import { DeliveryCostType } from '../CustomShippingForm/CustomShippingForm';

export interface ValuesInterface {
  name: string;
  cost?: string;
  deliveryCost: DeliveryCostType;
  cartWeightMin?: string;
  cartWeightMax?: string;
  cartValueMin?: string;
  cartValueMax?: string;
  deliveryTimeMin?: string;
  deliveryTimeMax?: string;
  showAsPickUpPoint?: boolean;
  allowedForFreeShipping?: boolean;
}

interface InternationalShippingFormProps {
  values: ValuesInterface;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  errors?: Partial<Record<string, string>>;
}
function InternationalShippingForm({
  values,
  handleChange,
  errors,
}: InternationalShippingFormProps) {
  return (
    <Stack column>
      <ShippingInformationCard
        name={values.name}
        deliveryCost={values.deliveryCost}
        cost={values.cost}
        onChange={handleChange}
        allowedForFreeShipping={values.allowedForFreeShipping}
        error={errors && errors.name}
        isShowAsPickUpPoint={false}
      />
      <ShippingConditionsCard
        onChange={handleChange}
        weightMin={values.cartWeightMin}
        weightMax={values.cartWeightMax}
        valueMin={values.cartValueMin}
        valueMax={values.cartValueMax}
        error={errors}
      />
      <ShippingTermCard
        timeMin={values.deliveryTimeMin}
        timeMax={values.deliveryTimeMax}
        onChange={handleChange}
        error={errors}
      />
    </Stack>
  );
}

InternationalShippingForm.Skeleton = Skeleton;

export default InternationalShippingForm;
