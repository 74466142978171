import { ReactNode } from 'react';
import { Box, Icon, Text } from '@nimbus-ds/components';
import { CheckCircleIcon, PlusCircleIcon } from '@nimbus-ds/icons';

interface UpsellBulletsListItemProps {
  children: ReactNode;
  completed: boolean;
}

function UpsellBulletsListItem({
  children,
  completed,
}: Readonly<UpsellBulletsListItemProps>) {
  return (
    <Box display="flex" gap="1">
      <Box
        display="flex"
        alignItems="center"
        borderRadius="1"
        padding="0-5"
        backgroundColor={completed ? 'success-surface' : undefined}
        marginBottom="0-5"
      >
        <Icon
          color={completed ? 'success-interactive' : 'neutral-textLow'}
          source={
            completed ? (
              <CheckCircleIcon size={14} />
            ) : (
              <PlusCircleIcon size={14} />
            )
          }
        />
      </Box>
      <Text
        fontWeight={completed ? 'bold' : 'regular'}
        fontSize="caption"
        color="neutral-textLow"
      >
        {children}
      </Text>
    </Box>
  );
}

interface UpsellBulletsProps {
  title?: string;
  progress?: number;
  bullets: string[];
}

export function UpsellBullets({
  title,
  bullets,
  progress = 0,
}: Readonly<UpsellBulletsProps>) {
  const makeBullet = (text: string, index: number) => (
    <UpsellBulletsListItem completed={progress > index} key={index}>
      {text}
    </UpsellBulletsListItem>
  );

  return (
    <>
      {!!title && (
        <Box marginBottom="2">
          <Text color="neutral-textHigh">{title}</Text>
        </Box>
      )}
      {bullets.map(makeBullet)}
    </>
  );
}
