import { ChangeEvent } from 'react';
import { Box, Checkbox, Title } from '@nimbus-ds/components';
import { CreateUserRequestDto, EmailTypeDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import CardCollapsible from 'commons/components/CardCollapsible';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface EmailTypesCardProps {
  emailTypes: EmailTypeDto[] | undefined;
  values: CreateUserRequestDto;
  onChangeEmailType: (name: string, value: string[]) => void;
}

function EmailTypesCard({
  emailTypes,
  values,
  onChangeEmailType,
}: Readonly<EmailTypesCardProps>) {
  const t = useTranslationConfigurations('userFormPage');

  const handleChangeEmailTypes = (event: ChangeEvent<HTMLInputElement>) => {
    const emailTypeCode = event.target.name;
    let emailTypes = values.emailTypes ? values.emailTypes : [];

    if (event.target.checked) {
      emailTypes = [...emailTypes, emailTypeCode];
    } else {
      emailTypes = emailTypes.filter((code) => code !== emailTypeCode);
    }

    onChangeEmailType('emailTypes', emailTypes);
  };

  return (
    <CardCollapsible
      title={
        <Stack spacing="tight">
          <Title as="h3">{t('emailTypesCard.title')}</Title>
        </Stack>
      }
      active={false}
      cursorEnable
    >
      <Stack>
        <Stack.Item fill>
          <Box display="flex" flexDirection="column" flexGrow="1" gap="6">
            {emailTypes?.map((emailType) => (
              <Checkbox
                key={`emailType-${emailType.code}`}
                label={t(
                  'emailTypesCard.optionTypes.' + emailType.code.toLowerCase(),
                )}
                name={emailType.code}
                checked={values.emailTypes?.includes(emailType.code)}
                onChange={(e) => handleChangeEmailTypes(e)}
              />
            ))}
          </Box>
        </Stack.Item>
      </Stack>
    </CardCollapsible>
  );
}

export default EmailTypesCard;
