import { ChangeEvent } from 'react';
import { FormField } from '@nimbus-ds/patterns';
import { CreateUserRequestDto, UpdateUserRequestDto } from '@tiendanube/common';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';

interface PasswordInputsProps {
  values: CreateUserRequestDto & UpdateUserRequestDto;
  errors: Partial<Record<string, string>>;
  requestErrors?: Partial<Record<string, any>>;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function PasswordInputs({
  values,
  errors,
  requestErrors,
  onChange,
}: Readonly<PasswordInputsProps>) {
  const t = useTranslationConfigurations('userFormPage');

  const getCurrentPasswordError = () => {
    if (errors.currentPassword) return t(`errors.${errors.currentPassword}`);
    if (requestErrors?.current_password)
      return t(`errors.currentPasswordInvalid`);
    return undefined;
  };

  const getNewPasswordError = () => {
    if (errors.password) return t(`errors.${errors.password}`);
    if (requestErrors?.new_password || requestErrors?.password)
      return t('errors.newPasswordLeaked');
    return undefined;
  };

  return (
    <>
      {values.currentPassword !== undefined && (
        <FormField.Input
          name="currentPassword"
          type="password"
          label={t('passwordCard.currentPassword')}
          value={values.currentPassword}
          onChange={onChange}
          showHelpText={!!getCurrentPasswordError()}
          helpText={getCurrentPasswordError()}
          appearance={getCurrentPasswordError() ? 'danger' : undefined}
        />
      )}
      <FormField.Input
        name="password"
        type="password"
        label={t('passwordCard.password')}
        value={values.password}
        onChange={onChange}
        showHelpText={!!getNewPasswordError()}
        helpText={getNewPasswordError()}
        appearance={getNewPasswordError() ? 'danger' : undefined}
      />
      <FormField.Input
        name="confirmPassword"
        type="password"
        label={t('passwordCard.confirmPassword')}
        value={values.confirmPassword}
        onChange={onChange}
        showHelpText={!!errors.confirmPassword}
        helpText={
          errors.confirmPassword
            ? t(`errors.${errors.confirmPassword}`)
            : undefined
        }
        appearance={errors.confirmPassword ? 'danger' : undefined}
      />
    </>
  );
}

export default PasswordInputs;
