import { DataTable } from '@tiendanube/components';
import { PagoNubeInvoiceHistoryDataTable } from './components';

function PagoNubeInvoiceHistoryWebSkeleton(): JSX.Element {
  return (
    <div className="invoice-history-web">
      <DataTable>
        <PagoNubeInvoiceHistoryDataTable.Skeleton />
      </DataTable>
    </div>
  );
}
export default PagoNubeInvoiceHistoryWebSkeleton;
