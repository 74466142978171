import { EmptyState } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import useTranslationOnline from 'domains/Online/useTranslationOnline';
import EmptyPagesList from './emptyPagesList.png';

function PagesListEmpty({ openModal }) {
  const t = useTranslationOnline('contentPages.pageList.emptyState');

  return (
    <EmptyState
      image={EmptyPagesList}
      title={t('title')}
      primaryActionLabel={t('action')}
      onClickPrimary={openModal}
    >
      <Text>{`${t('description')}`}</Text>
    </EmptyState>
  );
}

export default PagesListEmpty;
