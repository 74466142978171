export interface useNuvemPagoRedirectResponse {
  isNuvemPagoPayment: () => boolean;
  refundReceiptURL: (id: string) => string;
  partialRefundURL: (id: string) => string;
}

function useNuvemPagoRedirect(gateway: string): useNuvemPagoRedirectResponse {
  return {
    isNuvemPagoPayment: () =>
      ['Pago Nube', 'Nuvem Pago'].includes(gateway || ''),
    refundReceiptURL: (id: string) => {
      if (gateway === 'Pago Nube') {
        return `/nuvempago/#/refund-receipt/${id}`;
      } else if (gateway === 'Nuvem Pago') {
        return `/nuvempago#/transactions/${id}/refund-receipt?v=2`;
      } else {
        return '';
      }
    },
    partialRefundURL: (orderId: string) => {
      if (gateway === 'Pago Nube') {
        return `/admin/v2/nuvempago/#/refund/${orderId}`;
      } else if (gateway === 'Nuvem Pago') {
        return `/admin/v2/nuvempago#/order-refund/${orderId}?v=2`;
      } else {
        return '';
      }
    },
  };
}

export default useNuvemPagoRedirect;
