import { ErrorScreen } from 'commons/components';
import { AppsListMobileRow, AppsListMobileSkeleton } from './components';

function AppsListMobile({ apps, isLoading, isError, refetch }) {
  if (isLoading) return <AppsListMobileSkeleton />;
  if (isError || !apps) return <ErrorScreen onRetry={refetch} />;

  return (
    <>
      {apps.map((app, index) => (
        <AppsListMobileRow
          app={app}
          index={index}
          key={`apps-list-item-${app.id}`}
        />
      ))}
    </>
  );
}

export default AppsListMobile;
