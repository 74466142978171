import { VariantResponseDto } from '@tiendanube/common';
import { DataTable, Text, Stack } from '@tiendanube/components';
import { HiddenVariantTag } from 'domains/Catalog/Categories/components';
import { useVariantsRange } from 'domains/Catalog/Inventory/pages/hooks';
import { InputAsyncStock } from 'domains/Catalog/Inventory/pages/InventoryListPage/components/InventoryResultsDesktop/components/ResultRowDesktop/components';
import { ShowHideVariantsLink } from 'domains/Catalog/Inventory/pages/InventoryListPage/components/ShowHideVariantsLink';
import { useEditVariantsValues } from 'domains/Catalog/Products/pages/hooks';
import useProductItemList from 'domains/Catalog/Products/pages/hooks/useProductItemList';
import { ActionRowProducts } from 'domains/Catalog/Products/pages/ProductListPage/components';
import { InputAsync, ProductCell, LabelStock } from './components';
import {
  ACTIONS_COL_BASIC,
  INPUT_COL_BASIC,
  INPUT_COL_GROW,
  MAX_ENTRIES,
  PRODUCT_COL_GROW,
  VARIANTS_COL_BASIC,
} from '../utils';

interface ResultRowDesktopProps {
  id: string;
  hasVariations: boolean;
}

const { Row, Cell } = DataTable;
function ResultRowDesktop({
  id,
  hasVariations,
}: ResultRowDesktopProps): JSX.Element {
  const {
    hasCatalogInventory,
    variantsStock,
    enableEditStock,
    withDuplicateAttributesMainLanguage,
    enableEditPrice,
    jobStatus,
    handleEditVariant,
    onErrorStock,
    onErrorPrice,
  } = useEditVariantsValues(id);

  const { product, language } = useProductItemList(id);
  const range = useVariantsRange(product, MAX_ENTRIES);
  const variants = range.variants;

  const maxTagsAndMaxTags = 10;

  const variantValuesString = (variant: VariantResponseDto) =>
    variant.values.map((v) => v[language]).join(' / ');

  return (
    <div className="stratus--product-result-desktop-result-row-desktop">
      <Row id={id} align="center">
        <Cell grow={PRODUCT_COL_GROW}>
          <ProductCell
            product={product}
            maxTagsAndMaxTags={maxTagsAndMaxTags}
          />
        </Cell>
        <Cell grow={INPUT_COL_GROW} basis={INPUT_COL_BASIC}>
          <Stack column align="stretch" spacing="tight">
            {variants.map((variant) =>
              hasCatalogInventory ? (
                <LabelStock key={variant.id} defaultValue={{ variant }} />
              ) : (
                <InputAsyncStock
                  asyncFunc={handleEditVariant(variant.id)}
                  key={variant.id}
                  defaultValue={variantsStock[variant.id] ?? null}
                  onError={onErrorStock}
                  disabled={
                    !enableEditStock || withDuplicateAttributesMainLanguage
                  }
                />
              ),
            )}
            <ShowHideVariantsLink {...range} tracking={false} />
          </Stack>
        </Cell>
        <Cell grow={INPUT_COL_GROW} basis={INPUT_COL_BASIC}>
          <Stack column align="stretch" spacing="tight">
            {variants.map((variant) => {
              const hasPromotionalPrice =
                variant.promotional_price !== null &&
                variant.promotional_price > 0;
              return (
                <InputAsync
                  asyncFunc={handleEditVariant(variant.id)}
                  key={variant.id}
                  defaultValue={variant.price || 0}
                  name="price"
                  onError={onErrorPrice}
                  lineThrough={hasPromotionalPrice}
                  disabled={
                    !enableEditPrice ||
                    jobStatus === 'pending' ||
                    withDuplicateAttributesMainLanguage
                  }
                />
              );
            })}
          </Stack>
        </Cell>
        <Cell grow={INPUT_COL_GROW} basis={INPUT_COL_BASIC}>
          <Stack column align="stretch" spacing="tight">
            {variants.map((variant) => (
              <InputAsync
                asyncFunc={handleEditVariant(variant.id)}
                key={variant.id}
                defaultValue={variant.promotional_price || 0}
                name="promotional_price"
                onError={onErrorPrice}
                disabled={
                  !enableEditPrice ||
                  jobStatus === 'pending' ||
                  withDuplicateAttributesMainLanguage
                }
              />
            ))}
          </Stack>
        </Cell>
        <Cell grow={INPUT_COL_GROW} basis={VARIANTS_COL_BASIC}>
          {hasVariations && variants.length >= 1 && (
            <div className="stratus--product-result-desktop-result-row-desktop__variants">
              <Stack column align="stretch" spacing="tight">
                {variants.map((variant) => (
                  <Stack key={variant.id} spacing="tight">
                    <Text block trimText trimLines={1}>
                      {variantValuesString(variant)}
                    </Text>
                    <HiddenVariantTag visible={variant.visible ?? true} />
                  </Stack>
                ))}
              </Stack>
            </div>
          )}
        </Cell>
        <Cell grow={INPUT_COL_GROW} basis={ACTIONS_COL_BASIC}>
          <ActionRowProducts id={product.id} />
        </Cell>
      </Row>
    </div>
  );
}

export default ResultRowDesktop;
