import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import {
  ShippingAppOptionsResponseDto,
  ShippingAppResponseDto,
} from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { ErrorScreen } from 'commons/components';
import { useForm, useToastStatus } from 'commons/hooks';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import { trackingShippingModalities } from 'domains/Shipping/tracking';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import NuvemEnvioModals from './NuvemEnvioModals';
import useGetDetailActiveApp from './useGetDetailActiveApp';
import useUpdateDetailActiveApp from './useUpdateDetailActiveApp';
import CarriersForm from '../components/CarriersForm';
import ShippingPage from '../components/ShippingPage';

const mock = {};

function EditCarriersPage() {
  const t = useTranslationShipping();
  const { goTo, getQueryParam, goBack } = useNavegate();
  const { id } = useParams<{ id: string }>();
  const { id: storeId } = useStoreInfo();
  const { detailActiveApp, fetchDetailActiveApp, isError, isLoading } =
    useGetDetailActiveApp();

  const { updateDetailActive, status } = useUpdateDetailActiveApp();

  const sortNuvemEnvio = (
    response: ShippingAppResponseDto,
  ): Record<string, ShippingAppOptionsResponseDto> => {
    if (response.name !== 'Nuvem Envio') return response.options;

    const {
      'ne-loggi-economic': loggiEconomic,
      'ne-loggi-express': loggiExpress,
      'ne-jadlog-package': jadlogPackage,
      'ne-jadlog-com': jadlogCom,
      ...otherOptions
    } = response.options;

    return {
      ...(loggiEconomic && { 'ne-loggi-economic': loggiEconomic }),
      ...(loggiExpress && { 'ne-loggi-express': loggiExpress }),
      ...(jadlogPackage && { 'ne-jadlog-package': jadlogPackage }),
      ...(jadlogCom && { 'ne-jadlog-com': jadlogCom }),
      ...otherOptions,
    };
  };

  const data = useMemo(() => {
    if (detailActiveApp?.options) {
      return sortNuvemEnvio(detailActiveApp);
    }
    return mock;
  }, [detailActiveApp]);

  const { values, handleOnSubmit, handleChangeGroup } = useForm({
    initialValues: data,
    onSubmit: async (data) => {
      if (data) {
        await updateDetailActive(id, data);

        if (detailActiveApp?.name) {
          const options = Object.values(data).map((option) => {
            const typedOption = option as ShippingAppOptionsResponseDto;

            return {
              code: typedOption.code,
              isEnabled: typedOption.isEnabled,
            };
          });

          trackingShippingModalities(
            storeId,
            detailActiveApp.name,
            JSON.stringify(options),
          );
        }

        goBack();
      }
    },
  });

  useToastStatus({
    status,
    success: t('carriers.successToast'),
    error: t('carriers.errorToast'),
    onSuccess: () => {
      const fromOnboarding = getQueryParam('from') === 'onBoarding';
      const shippingMethodsList = '/settings/shipping-methods';
      return fromOnboarding ? goTo(shippingMethodsList) : undefined;
    },
  });

  const handleOnFetchDetailActiveApp = useCallback(() => {
    fetchDetailActiveApp(id);
  }, [fetchDetailActiveApp, id]);

  useEffect(() => {
    handleOnFetchDetailActiveApp();
  }, [handleOnFetchDetailActiveApp]);

  return (
    <ShippingPage
      isError={isError}
      handleSave={handleOnSubmit}
      title={isLoading ? 'skeleton' : detailActiveApp?.name ?? ''}
      isLoading={isLoading}
      isUpdating={status === 'loading'}
    >
      {isError && (
        <ErrorScreen
          description={t('genericError.text')}
          onRetry={handleOnFetchDetailActiveApp}
        />
      )}
      {isLoading && <CarriersForm.Skeleton />}
      {detailActiveApp && !isLoading && !isError && (
        <CarriersForm
          values={values}
          handleOnChangeGroup={handleChangeGroup}
          isCompatibleWithMultiCD={detailActiveApp.isMultiCDCompatible}
        />
      )}

      {detailActiveApp?.name === 'Nuvem Envio' && !isLoading && (
        <NuvemEnvioModals detailActiveApp={detailActiveApp} />
      )}
    </ShippingPage>
  );
}

export default EditCarriersPage;
