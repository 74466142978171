import { BusinessHoursResponseDto } from '@tiendanube/common';

export const WEEKDAYS_OPTIONS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const START_DEFAULT = '09:00';
export const END_DEFAULT = '18:00';

export const formatBusinessHourErrors = (
  errors: Partial<Record<string, string>>,
): Record<string, string> => {
  const formattedErrors: Record<string, string> = {};
  const regex = /businessHours\[(\d+)\]\.(\w+)/;

  Object.keys(errors).forEach((key) => {
    if (key.startsWith('businessHours')) {
      const match = regex.exec(key);
      if (match && errors[key]) {
        const index = parseInt(match[1], 10);
        const field = match[2];
        formattedErrors[`${index}.${field}`] = errors[key] ?? '';
      }
    }
  });

  return formattedErrors;
};

export const sortBusinessHours = (businessHours: BusinessHoursResponseDto[]) =>
  [...businessHours].sort((a, b) => {
    const indexA = WEEKDAYS_OPTIONS.indexOf(a.day);
    const indexB = WEEKDAYS_OPTIONS.indexOf(b.day);
    return indexA - indexB;
  });
