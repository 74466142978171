import { Link } from '@nimbus-ds/components';
import { ExclamationTriangleIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface PickupFormErrorProps {
  onRetryError: () => void;
}

function PickupFormError({
  onRetryError,
}: Readonly<PickupFormErrorProps>): JSX.Element {
  const t = useTranslationShipping('deliveryMethods.pickupPoints.form.error');

  return (
    <EmptyMessage
      title={t('errorOnLoad')}
      icon={
        <ExclamationTriangleIcon
          size={32}
          style={{
            marginTop: '3rem',
          }}
        />
      }
      actions={
        <Link appearance="neutral" onClick={onRetryError}>
          {`${t('retry')}`}
        </Link>
      }
    />
  );
}

export default PickupFormError;
