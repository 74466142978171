import { useTranslation } from 'react-i18next';
import {
  BaseCard,
  Button,
  Checkbox,
  IconButton,
  Stack,
} from '@tiendanube/components';
import { DownloadIcon, SlidersIcon } from '@tiendanube/icons';
import { FilterChips, FilterTotal } from './components';

export interface FilterChip {
  id: string;
  label: string;
  notRemovable?: boolean;
}

interface ExportCardProps {
  filters: FilterChip[];
  totalLabel: string;
  total: number;
  loadingTotal: boolean;
  checkboxLabel?: string;
  checkboxValue?: boolean;
  exporting?: boolean;
  disabled?: boolean;
  showClearFiltersButton?: boolean;
  onCheckboxChange?: (value: boolean) => void;
  onRemoveFilter: (id: string) => void;
  onOpenFilters: () => void;
  onConfirm: () => void;
  onClearFilterButtonClick?: () => void;
  alert?: React.ReactNode;
}

function ExportCard({
  filters,
  totalLabel,
  total,
  loadingTotal,
  checkboxLabel,
  checkboxValue,
  exporting,
  disabled,
  alert,
  showClearFiltersButton = false,
  onCheckboxChange,
  onRemoveFilter,
  onOpenFilters,
  onConfirm,
  onClearFilterButtonClick,
}: ExportCardProps): JSX.Element {
  const { t } = useTranslation('common');

  return (
    <BaseCard>
      <BaseCard.Body padding="base">
        <Stack align="stretch" column>
          {!!alert && alert}
          <Stack align="stretch">
            <Stack.Item fill>
              <Stack column align="stretch" spacing="loose">
                {filters.length > 0 && (
                  <Stack.Item>
                    <FilterChips
                      filters={filters}
                      showClearFiltersButton={showClearFiltersButton}
                      onClearFilterButtonClick={onClearFilterButtonClick}
                      onRemoveFilter={onRemoveFilter}
                      onOpenFilters={onOpenFilters}
                    />
                  </Stack.Item>
                )}
                <Stack.Item>
                  <FilterTotal
                    label={totalLabel}
                    total={total}
                    loading={loadingTotal}
                  />
                </Stack.Item>
                {!!checkboxLabel &&
                  checkboxValue !== undefined &&
                  onCheckboxChange && (
                    <Stack.Item>
                      <Checkbox
                        label={checkboxLabel}
                        name="exportCardCheckbox"
                        checked={checkboxValue}
                        onChange={(e) => onCheckboxChange(e.checked)}
                      />
                    </Stack.Item>
                  )}
              </Stack>
            </Stack.Item>
            <Stack.Item>
              <IconButton
                icon={SlidersIcon}
                ariaLabel={t('exportCard.applyFilters')}
                onClick={onOpenFilters}
              />
            </Stack.Item>
          </Stack>
        </Stack>
      </BaseCard.Body>
      {total !== 0 && (
        <BaseCard.Footer>
          <Button
            appearance="primary"
            icon={DownloadIcon}
            spinner={exporting}
            disabled={disabled || exporting}
            onClick={onConfirm}
          >
            {`${t('exportCard.export')}`}
          </Button>
        </BaseCard.Footer>
      )}
    </BaseCard>
  );
}

export default ExportCard;
