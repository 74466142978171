import { logEvent } from 'commons/utils/tracking';
import {
  CNAME_CONFIG_CAN_CLICK,
  MENU_SECONDARY_EDIT_USER_CLICK,
  MENU_SECONDARY_USERS_PERMISSIONS_CLICK,
} from 'config/trackingEvents';
import { MENU_SECONDARY_NOTIFICATIONS_CLICK } from '../../config/trackingEvents';

export const trackingMenuSecondaryUsersPermissionsClick = (): void => {
  logEvent(MENU_SECONDARY_USERS_PERMISSIONS_CLICK, {});
};

export const trackingMenuSecondaryNotificationsClick = (): void => {
  logEvent(MENU_SECONDARY_NOTIFICATIONS_CLICK, {});
};

export const trackingCnameConfigCanClick = (): void => {
  logEvent(CNAME_CONFIG_CAN_CLICK, {});
};

export const trackingEditUserClick = (from: 'settings' | 'account'): void => {
  logEvent(MENU_SECONDARY_EDIT_USER_CLICK, { from });
};
