import { Box, Card, Link, Tag, Title } from '@nimbus-ds/components';
import { useIsInAppBrowser } from 'App/hooks';
import { useGoToPlans } from 'commons/hooks/useGoToPlans';
import {
  useGetIsFreePlan,
  useGetPlan,
  useIsMobileDevice,
} from 'domains/Auth/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

function FreePlanCard() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.freePlanCard',
  );
  const { isFreePlan } = useGetIsFreePlan();
  const { handleGoToPlans: goToPlans } = useGoToPlans();
  const { commercialName } = useGetPlan();

  const isMobileDevice = useIsMobileDevice();
  const isInAppBrowserOpen = useIsInAppBrowser();
  const isWebVersion = !isMobileDevice && !isInAppBrowserOpen;

  if (!isFreePlan) return null;

  return (
    <Card>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Title as="h5">{`${t('plan')} ${commercialName}`}</Title>
          {isWebVersion && (
            <Link
              textDecoration="none"
              appearance="primary"
              onClick={goToPlans}
            >
              {t('changePlan')}
            </Link>
          )}
        </Box>
        <Tag appearance="success">{t('tag')}</Tag>
      </Box>
    </Card>
  );
}

export default FreePlanCard;
