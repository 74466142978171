import { useEffect, useState } from 'react';
import { Alert } from '@nimbus-ds/components';
import { PlansListResponseDto } from '@tiendanube/common';
import { Modal } from '@tiendanube/components';
import { FEATURE_ONLY_LAYOUT_DEFAULT } from 'App/features';
import { NUVEMPAGO_ENABLED } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { CheckItem } from 'commons/components';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
import { useHasLimitingFeature } from 'domains/Auth/hooks';
import { useTranslationBilling } from 'domains/Billing';
import { trackingBillingDowngradeFreePlan } from 'domains/Billing/tracking';
import { useKycStatus } from 'domains/NuvemPagoCommons/hooks/useKycStatus';
import { ChangeType } from '../../BillingPlansPage';

interface ChangePlanModalProps {
  show: boolean;
  changeType?: ChangeType;
  onConfirm: () => void;
  onCancel: () => void;
  currentPlan?: PlansListResponseDto;
  newPlan?: string;
}

function ChangePlanModal({
  show,
  changeType,
  onConfirm,
  onCancel,
  currentPlan,
  newPlan = '',
}: ChangePlanModalProps): JSX.Element {
  const t = useTranslationBilling();
  const [disableConfirm, setDisableConfirm] = useState(false);
  const hasFeature = useHasLimitingFeature(FEATURE_ONLY_LAYOUT_DEFAULT);
  const [hasNuvemPagoEnabled] = useHasTags(NUVEMPAGO_ENABLED);
  const { isKycRejected } = useKycStatus();

  const isUpgrade = changeType === 'upgrade';
  const isDowngrade = changeType === 'downgrade';
  const isBasic = changeType === 'basic';

  const title = isBasic
    ? t('billingPlansPage.changePlanModal.basicTitle')
    : isUpgrade
    ? t('billingPlansPage.changePlanModal.upgradeTitle')
    : t('billingPlansPage.changePlanModal.downgradeTitle');
  const confirmText = isBasic
    ? t('billingPlansPage.changePlanModal.basicConfirm')
    : isUpgrade
    ? t('billingPlansPage.changePlanModal.upgradeConfirm')
    : t('billingPlansPage.changePlanModal.downgradeConfirm');

  const handleOnConfirm = () => {
    if (isDowngrade || isBasic)
      trackingBillingDowngradeFreePlan({
        currentPlan: currentPlan?.name || '',
        newPlan,
      });
    setDisableConfirm(true);
    onConfirm();
  };

  useEffect(() => {
    if (show) setDisableConfirm(false);
  }, [show]);

  return (
    <Modal
      title={title}
      show={show}
      onDismiss={onCancel}
      primaryAction={{
        children: confirmText,
        appearance: 'primary',
        disabled: disableConfirm,
        onClick: handleOnConfirm,
      }}
      secondaryAction={{
        children: `${t('billingPlansPage.changePlanModal.cancel')}`,
        onClick: onCancel,
      }}
    >
      <Alert
        title={t('billingPlansPage.changePlanModal.alert.title', {
          planName: newPlan,
        })}
        appearance="danger"
        show={hasFeature && isBasic}
      >
        {t('billingPlansPage.changePlanModal.alert.description')}
      </Alert>
      {!isBasic && isUpgrade && (
        <Text>{t('billingPlansPage.changePlanModal.upgradeMessage')}</Text>
      )}
      {!isBasic && isDowngrade && (
        <Text>{t('billingPlansPage.changePlanModal.downgradeMessage')}</Text>
      )}
      {isBasic && (
        <Stack align="center" column>
          <Stack.Item>
            <Text>{t('billingPlansPage.changePlanModal.basicSubtitle')}</Text>
          </Stack.Item>
          <div className="nimbus--stack-item stratus--card-body-mainFeatures">
            <Stack column align="flex-start">
              <CheckItem checked>
                {t('billingPlansPage.changePlanModal.basicFeature1')}
              </CheckItem>
              <CheckItem checked>
                {!hasNuvemPagoEnabled || isKycRejected
                  ? t('billingPlansPage.changePlanModal.basicFeature3')
                  : t('billingPlansPage.changePlanModal.basicFeature2')}
              </CheckItem>
              {hasFeature && isBasic && (
                <CheckItem>
                  {t('billingPlansPage.changePlanModal.moreThemes')}
                </CheckItem>
              )}
              <CheckItem>
                {t('billingPlansPage.changePlanModal.basicFeature4')}
              </CheckItem>
              <CheckItem>
                {t('billingPlansPage.changePlanModal.basicFeature5')}
              </CheckItem>
              <CheckItem>
                {t('billingPlansPage.changePlanModal.basicFeature6')}
              </CheckItem>
              <CheckItem>
                {t('billingPlansPage.changePlanModal.basicFeature7')}
              </CheckItem>
              <CheckItem>
                {t('billingPlansPage.changePlanModal.basicFeature8')}
              </CheckItem>
              {!!currentPlan?.posExtraInfo && (
                <CheckItem>
                  {t('billingPlansPage.changePlanModal.posFeature')}
                </CheckItem>
              )}
            </Stack>
          </div>
        </Stack>
      )}
    </Modal>
  );
}

export default ChangePlanModal;
