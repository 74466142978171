import { PlansListResponseDto } from '@tiendanube/common';
import { Grid } from '@tiendanube/components';
import { useTranslationBilling } from 'domains/Billing';
import { useGetLastPayment } from 'domains/Billing/Checkout/hooks';
import PlanCard from '../PlanCard';
import { SelectedPlan } from '../PlanCard/types';

interface ComparisonPlansCardsDesktopProps {
  plansList: PlansListResponseDto[];
  currentPlanId: number;
  currentPlanLevel: number;
  onSelectPlan: (plan: SelectedPlan) => void;
}

function ComparisonPlansCardsDesktop({
  plansList,
  currentPlanId,
  currentPlanLevel,
  onSelectPlan,
}: ComparisonPlansCardsDesktopProps): JSX.Element {
  const t = useTranslationBilling();
  useGetLastPayment('plan-cost');

  return (
    <Grid fluid>
      <Grid.Row>
        {plansList.map((plan) => (
          <Grid.Column key={plan.id}>
            <PlanCard
              currentPlan={plan.id === currentPlanId}
              title={plan.name}
              description={plan.description}
              price={plan.price}
              frequencyPrice={t('billingPlansPage.month')}
              mainFeatures={plan.mainFeatures}
              posExtraInfo={plan.posExtraInfo}
              isNextPlan={plan.isNextPlan}
              isFree={plan.isFree}
              plan={plan}
              currentPlanLevel={currentPlanLevel}
              onSelectPlan={onSelectPlan}
              currentPlanId={currentPlanId}
            />
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
}

export default ComparisonPlansCardsDesktop;
