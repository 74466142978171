import { Tag, Text } from '@nimbus-ds/components';
import { EyeOffIcon } from '@nimbus-ds/icons';
import { v4 as uuidv4 } from 'uuid';
import { VisibilityCategoryType } from '@tiendanube/common/src/domains/catalog/interfaces';
import { ShowMoreContent } from 'commons/components';

interface ShowMoreCategoriesProps {
  onClick?: () => void;
  categories: string[];
  categoriesVisibility: VisibilityCategoryType[];
}

const uniqueId = uuidv4();

export function ShowMoreCategories({
  categories,
  categoriesVisibility,
  onClick,
}: Readonly<ShowMoreCategoriesProps>) {
  return (
    <ShowMoreContent onClick={onClick}>
      {categories.map((category, index) => (
        <Tag key={`${uniqueId}-${category}`}>
          {categoriesVisibility[index] !== 'visible' && <EyeOffIcon />}
          <Text lineClamp={1}>{category}</Text>
        </Tag>
      ))}
    </ShowMoreContent>
  );
}
