import { useEffect } from 'react';
import { ChoosePaymentMethod } from './components';
import { usePayorderToPay } from '../../hooks';
import {
  trackingBillingViewSelectPaymentMethodForPlan,
  useCheckoutTracking,
} from '../../tracking';
import { ChoosePaymentMethodWithRecurrencyLayout } from '../components';

export default function ChoosePaymentMethodPage() {
  const logEvent = useCheckoutTracking();
  const { mainConcept } = usePayorderToPay();
  useEffect(
    () => trackingBillingViewSelectPaymentMethodForPlan(logEvent, mainConcept),
    [logEvent, mainConcept],
  );

  return (
    <ChoosePaymentMethodWithRecurrencyLayout
      mainContent={<ChoosePaymentMethod />}
    />
  );
}
