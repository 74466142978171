import { useEffect, useState } from 'react';
import { Icon, Modal, Tag, Text } from '@nimbus-ds/components';
import { StarIcon } from '@nimbus-ds/icons';
import { InteractiveList } from '@nimbus-ds/patterns';
import { LocationResponseDto } from '@tiendanube/common';
import { CancelAndConfirmButtons, Stack } from 'commons/components';
import { useInventoryLevelsByLocation } from 'domains/Shipping/Locations/hooks';
import useLocationById from 'domains/Shipping/Locations/hooks/useLocation/useLocation';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  DeleteLocationModalError,
  DeleteLocationModalSkeleton,
} from './components';

interface TransferStockModalProps {
  locationId: string;
  locationsList: LocationResponseDto[];
  isLoadingLocations: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

function TransferStockModal({
  locationId,
  locationsList,
  isLoadingLocations,
  onClose,
  onConfirm,
}: Readonly<TransferStockModalProps>) {
  const [acceptedTransfer, setAcceptedTransfer] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const t = useTranslationShipping();
  const { location } = useLocationById(locationId);

  const { fetchInventoryLevels, isError, isLoading, isSuccess, total } =
    useInventoryLevelsByLocation(locationId);

  const handleConfirm = () => (total ? setAcceptedTransfer(true) : onConfirm());

  useEffect(() => {
    fetchInventoryLevels();
  }, [fetchInventoryLevels]);

  const getDestination = () => {
    if (locationsList.length === 2) {
      const destination = locationsList.find((loc) => loc.id !== locationId);
      return destination?.name;
    }
  };

  const getFullDescription = () => (
    <>
      {t('locations.transfer.descriptionWithInventory', {
        name: location.name,
      })}
      <br />
      <br />
      {t('locations.transfer.caption')}
      <br />
      {t('locations.transfer.transferDescription', {
        name: location.name,
        destination: getDestination(),
      })}
    </>
  );

  const getRadioItemsFromLocations = () =>
    locationsList
      .filter((location) => location.id !== locationId)
      .map((location) => (
        <InteractiveList.RadioItem
          radio={{
            name: location.name,
            value: location.name,
            checked: selectedOption === location.name,
          }}
          key={location.id}
          title={location.name}
          showTitle={false}
        >
          <Stack spacing="tight" align="flex-start">
            <Text fontWeight="bold" color="neutral-textHigh">
              {location.name}
            </Text>
            {location.main && (
              <Tag appearance="primary">
                <Icon source={<StarIcon />} />
                {t('locations.card.label')}
              </Tag>
            )}
          </Stack>
        </InteractiveList.RadioItem>
      ));

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const shouldDisableConfirm =
    isLoadingLocations ||
    (!selectedOption && total > 0 && locationsList.length > 2);

  return (
    <Modal open onDismiss={onClose}>
      {isLoading && <DeleteLocationModalSkeleton />}
      {isError && (
        <DeleteLocationModalError
          onRetryError={fetchInventoryLevels}
          onClose={onClose}
        />
      )}
      {!isLoading && isSuccess && (
        <>
          <Modal.Header
            title={
              total > 0 && !acceptedTransfer
                ? t('locations.transfer.titleWithInventory')
                : t('locations.transfer.title', { name: location?.name })
            }
          />
          <Modal.Body padding="none">
            {total === 0 ? (
              <Text>{t('locations.delete.description')}</Text>
            ) : locationsList.length === 2 ? (
              <Text>
                {acceptedTransfer
                  ? t('locations.transfer.description')
                  : getFullDescription()}
              </Text>
            ) : acceptedTransfer ? (
              <Text>{t('locations.transfer.description')}</Text>
            ) : (
              <InteractiveList onChange={handleChange}>
                {getRadioItemsFromLocations()}
              </InteractiveList>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Stack spacing="base" justify="flex-end">
              <CancelAndConfirmButtons
                appearance={acceptedTransfer ? 'danger' : 'primary'}
                onCancel={onClose}
                onConfirm={handleConfirm}
                isConfirmDisabled={shouldDisableConfirm}
                isCancelDisabled={isLoadingLocations}
                confirmText={
                  total > 0
                    ? t('locations.transfer.confirm')
                    : t('locations.delete.confirm')
                }
              />
            </Stack>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}
export default TransferStockModal;
