import { useEffect, useState } from 'react';
import { Alert, Box, Card, Icon, Link, Text } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';
import { generatePath } from 'react-router-dom';
import { useNavegate } from 'App/hooks';
import { ErrorScreen, Stack } from 'commons/components';
import { useModal, useToastStatus } from 'commons/hooks';
import { useHasNewPickupPoints } from 'domains/Auth/hooks';
import { DELIVERY_METHODS_ROUTES } from 'domains/Shipping/DeliveryMethods/shippingRoutes';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { PhysicalLocationsRow } from './components';
import PhysicalLocationsSkeleton from './Skeleton';
import usePhysicalLocations from './usePhysicalLocations';
import { DeleteShippingModal } from '../../../components';

function PhysicalLocations() {
  const t = useTranslationShipping();
  const hasPickupPoints = useHasNewPickupPoints();

  const [deleteId, setDeleteId] = useState();
  const [isOpenConfirm, openConfirm, closeConfirm] = useModal();
  const { goTo } = useNavegate();

  const {
    fetchPhysicalLocations,
    physicalLocations,
    getStatus,
    deleteStatus,
    deletePhysicalLocation,
  } = usePhysicalLocations();

  useEffect(fetchPhysicalLocations, [fetchPhysicalLocations]);

  useToastStatus({
    error: t('physicalLocations.deleteLocation.error'),
    success: t('physicalLocations.deleteLocation.success'),
    status: deleteStatus,
    onSuccess: closeConfirm,
    onError: closeConfirm,
  });

  const goToAddPhysicalLocations = () =>
    goTo(DELIVERY_METHODS_ROUTES.physicalLocation);

  const goToNewPhysicalLocations = () =>
    goTo(`${DELIVERY_METHODS_ROUTES.physicalLocation}?new`);

  const goToEditPhysicalLocations = (id) => () =>
    goTo(generatePath(DELIVERY_METHODS_ROUTES.physicalLocationDetail, { id }));

  const handleDeletePhysicalLocations = (id) => () => {
    setDeleteId(id);
    openConfirm();
  };

  const handleConfirmDeletePhysicalLocations = () => {
    if (deleteId) {
      deletePhysicalLocation(deleteId);
    }
  };

  const isLoading = getStatus === 'loading';
  const isError = getStatus === 'error';

  return (
    <Stack column spacing="loose">
      {hasPickupPoints && (
        <Alert appearance="danger" title={t('physicalLocations.alert.title')}>
          <Text>{t('physicalLocations.alert.description')}</Text>
        </Alert>
      )}
      <Card padding="none">
        <DeleteShippingModal
          title={t('physicalLocations.modalDelete.title')}
          description={t('physicalLocations.modalDelete.description')}
          isOpen={isOpenConfirm}
          onClose={closeConfirm}
          isLoading={deleteStatus === 'loading'}
          onConfirmDelete={handleConfirmDeletePhysicalLocations}
        />
        <Card.Header padding="base" title={t('physicalLocations.title')} />
        <Card.Body padding="base">
          <Stack align="flex-start" column>
            {!isError && <Text>{t('physicalLocations.subTitle')}</Text>}
            {!isError && !isLoading && !hasPickupPoints && (
              <Link
                appearance="primary"
                textDecoration="none"
                onClick={
                  !physicalLocations?.length
                    ? goToNewPhysicalLocations
                    : goToAddPhysicalLocations
                }
              >
                <Icon color="currentColor" source={<PlusCircleIcon />} />
                {t('physicalLocations.addButton')}
              </Link>
            )}
            {isError && (
              <ErrorScreen
                description={t(
                  'deliveryMethods.activeShippingMethods.error.description',
                )}
                onRetry={fetchPhysicalLocations}
              />
            )}
          </Stack>
        </Card.Body>
        {!isError && (
          <Card.Footer padding="none">
            <Box padding="none" paddingBottom="1-5" width="100%">
              {isLoading && <PhysicalLocationsSkeleton />}
              {physicalLocations && physicalLocations.length > 0 && (
                <DataList bottomDivider={false}>
                  {physicalLocations.map(({ id, name, extra }) => (
                    <PhysicalLocationsRow
                      key={id}
                      id={id}
                      name={name}
                      extra={extra}
                      onDelete={handleDeletePhysicalLocations}
                      onEdit={goToEditPhysicalLocations}
                    />
                  ))}
                </DataList>
              )}
            </Box>
          </Card.Footer>
        )}
      </Card>
    </Stack>
  );
}

export default PhysicalLocations;
