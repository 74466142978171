import { ChangeEvent } from 'react';
import { Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { InputNumberNimbus, Stack } from 'commons/components';
import { useGetCurrency } from 'domains/Auth/hooks';
import { useTranslationMarketing } from 'domains/Marketing/hook';

interface PurchaseValueProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export function PurchaseValue({
  onChange,
  value,
}: Readonly<PurchaseValueProps>) {
  const t = useTranslationMarketing('freeShipping.settingsPage.conditions');
  const { symbol } = useGetCurrency();

  return (
    <Stack column spacing="tight" align="flex-start">
      <Text fontWeight="bold">{t('purchaseValue')}</Text>
      <FormField label={t('greaterThan')}>
        <InputNumberNimbus
          name="priceMinimum"
          type="float"
          append={symbol}
          appendPosition="start"
          value={value || '0'}
          onChange={onChange}
        />
      </FormField>
    </Stack>
  );
}
