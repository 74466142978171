import { Box, BoxProperties } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { NavigationMenuItemResponseDto } from '@tiendanube/common';
import { DraggableDataList, Stack } from 'commons/components';
import Link from 'commons/components/LegacyLink';
import { useGetLanguage } from 'domains/Auth/hooks';
import { useTranslationOnline } from 'domains/Online';
import MenuItemNode from '../MenuItemNode';
import './MenuItemDataList.scss';

interface FormattedMenuItem extends NavigationMenuItemResponseDto {
  previewName: string;
}

interface MenuItemDataListProps {
  items: NavigationMenuItemResponseDto[];
  addItemLeftPadding?: BoxProperties['paddingLeft'];
  onOpenMenuItemModal: () => void;
  onDeleteMenuItem: (id: string) => void;
  onDropMenuItem: (data: NavigationMenuItemResponseDto[]) => void;
  onEditMenuItem: (id: string) => void;
}

function MenuItemDataList({
  items,
  addItemLeftPadding = '4',
  onOpenMenuItemModal,
  onDeleteMenuItem,
  onDropMenuItem,
  onEditMenuItem,
}: MenuItemDataListProps) {
  const t = useTranslationOnline('menus.list.menuCard');
  const language = useGetLanguage();

  const formattedItems: FormattedMenuItem[] = items.map((item) => ({
    previewName: item.name[language] ?? '',
    ...item,
  }));

  const handleDrop = (menuItems) => onDropMenuItem(menuItems);

  return (
    <div className="stratus--menu-item-data-list">
      <Stack column align="stretch" spacing="tight">
        <DraggableDataList
          data={formattedItems}
          onDrop={handleDrop}
          previewDataKey="previewName"
          renderItem={(node) => (
            <MenuItemNode
              node={node}
              onDelete={onDeleteMenuItem}
              onEdit={onEditMenuItem}
            />
          )}
        />

        <Box paddingLeft={addItemLeftPadding}>
          <Link
            appearance="primary"
            icon={PlusCircleIcon}
            iconPosition="start"
            onClick={onOpenMenuItemModal}
          >
            {t('addMenuItem')}
          </Link>
        </Box>
      </Stack>
    </div>
  );
}

export default MenuItemDataList;
