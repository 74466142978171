import { CombinedState } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { CustomerTablePricesInterface } from '../types';

export const getCustomerTablePricesState = (
  state: RootStateType,
): CombinedState<CustomerTablePricesInterface> =>
  state.customers.customerTablePrices;

export const getCustomerTablePricesData = createSimpleSelector(
  [getCustomerTablePricesState],
  (state) => state.tablePrices.data,
);

export const getCustomerTablePricesStatus = createSimpleSelector(
  [getCustomerTablePricesState],
  (state) => convertStatusTypeToObject(state.tablePrices.status),
);
