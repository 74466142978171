import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  FulfillmentOrderResponseDto,
  OrderCancelRequestDto,
  OrderFulfillRequestDto,
  OrderResponseDto,
} from '@tiendanube/common';
import { Country } from '@tiendanube/common/src/enums';
import { NEW_ADMIN_MULTIPLOS_ENVIOS } from 'App/featuresFlags';
import { useNavegate } from 'App/hooks';
import { useAppDispatch } from 'App/store';
import { useGetCountry, useGetTags } from 'domains/Auth/hooks';
import { partnersAppsRoutes } from 'domains/PartnersApps';
import { trackPrintNuvemEnvioLabel } from './tracking';
import { cancelOrderById, getUpdateOrdersStatus } from '../../ordersSlice';
import {
  archiveOrder as archiveOrderById,
  cleanUpdateOrderStatus,
  fulfillOrderById,
  openOrderById,
  packOrderById,
  paidOrderById,
} from '../../ordersSlice/orderSlice';

function useQuickAction() {
  const dispatch = useAppDispatch();
  const status = useSelector(getUpdateOrdersStatus);
  const tags = useGetTags();
  const hasMultipleFulfillments = tags.includes(NEW_ADMIN_MULTIPLOS_ENVIOS);
  const { goTo } = useNavegate();
  const storeCountry = useGetCountry();

  const archiveOrder = useCallback(
    async (id: string) => {
      const payload = {
        isActionFromOrdersList: false,
      };
      await dispatch(archiveOrderById({ id, payload }));
      dispatch(cleanUpdateOrderStatus());
    },
    [dispatch],
  );

  const paidOrder = useCallback(
    async (id: string) => {
      await dispatch(paidOrderById({ id, isActionFromOrdersList: false }));
      dispatch(cleanUpdateOrderStatus());
    },
    [dispatch],
  );

  const openOrder = useCallback(
    async (id: string) => {
      await dispatch(openOrderById({ id, isActionFromOrdersList: false }));
      dispatch(cleanUpdateOrderStatus());
    },
    [dispatch],
  );

  const printNuvemEnvio = (externalSaleId: string) => {
    const path = `#/dashboard?&externalSaleIds=${externalSaleId}`;
    trackPrintNuvemEnvioLabel(externalSaleId);

    if (storeCountry === Country.AR) {
      goTo(`${partnersAppsRoutes.envionubear}${path}`);
    }

    if (storeCountry === Country.BR) {
      goTo(`${partnersAppsRoutes.nuvemenvio}${path}`);
    }

    if (storeCountry === Country.MX) {
      goTo(`${partnersAppsRoutes.envionubemx}${path}`);
    }
  };

  const packOrder = useCallback(
    async (orderId: string, fulfillments: FulfillmentOrderResponseDto[]) => {
      await dispatch(
        packOrderById({
          orderId,
          fulfillments: hasMultipleFulfillments
            ? fulfillments.map((ffo) => ({
                fulfillmentId: ffo.id,
                status: ffo.status,
                shippingType: ffo.shipping_info.shipping_type,
              }))
            : [],
          isActionFromOrdersList: false,
        }),
      );
      dispatch(cleanUpdateOrderStatus());
    },
    [dispatch, hasMultipleFulfillments],
  );

  const fulfillOrder = useCallback(
    async (order: OrderResponseDto, action: OrderFulfillRequestDto) => {
      await dispatch(
        fulfillOrderById({
          orderId: order.id,
          action: hasMultipleFulfillments
            ? {
                ...action,
                isActionFromOrdersList: false,
              }
            : {
                ...action,
                fulfillments: [],
                isActionFromOrdersList: false,
              },
        }),
      );
      dispatch(cleanUpdateOrderStatus());
    },
    [dispatch, hasMultipleFulfillments],
  );

  const cancelOrder = useCallback(
    async (id: string, action: OrderCancelRequestDto) => {
      action.isActionFromOrdersList = true;
      await dispatch(cancelOrderById({ id, action }));
      dispatch(cleanUpdateOrderStatus());
    },
    [dispatch],
  );

  return {
    archiveOrder,
    paidOrder,
    openOrder,
    packOrder,
    fulfillOrder,
    printNuvemEnvio,
    cancelOrder,
    status,
  };
}

export default useQuickAction;
