import { useImportMaxLines } from 'commons/hooks';
import {
  getImportMaxLines,
  importMaxLines,
  importMaxLinesStatus,
} from 'domains/Customers/Customers/customersSlice';

export function useImportMaxLinesCustomers() {
  return useImportMaxLines(
    getImportMaxLines,
    importMaxLines,
    importMaxLinesStatus,
  );
}
