import { Box, Text } from '@nimbus-ds/components';
import {
  BrowserSearchIcon,
  ChatDotsIcon,
  TelephoneIcon,
  WhatsappIcon,
  UniversityIcon,
  LightbulbIcon,
} from '@nimbus-ds/icons';
import tokens from '@nimbus-ds/tokens/dist/js/tokens';
import useTopBar from 'App/components/Topbar/useTopBar';
import { FEATURE_CS_PHONE_CALL, FEATURE_CS_WHATSAPP } from 'App/features';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { openWindow } from 'commons/utils/window';
import { SUPPORT_PHONECALL, SUPPORT_WHATSAPP } from 'config/upsellFlowSources';
import { useTranslationAccount } from 'domains/Account/hooks';
import { useGetTags, useStatusFeatureByKey } from 'domains/Auth/hooks';
import { useTranslationBilling } from 'domains/Billing';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useStoreInfo } from 'domains/PartnersApps/hooks';
import {
  canCountryLink,
  phoneCallLink,
  whatsappCountryLink,
  SupportCardEntry,
} from './components';
import { menuHirePartnerClick } from '../../tracking';

interface SupportCardBodyProps {
  onOpenChat: () => void;
  onClose: () => void;
}

export function SupportCard({
  onOpenChat,
  onClose,
}: Readonly<SupportCardBodyProps>) {
  const { noReadMessagesCount } = useTopBar();
  const t = useTranslationBilling();
  const tAccount = useTranslationAccount();
  const { country } = useStoreInfo();
  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);
  const { isAvailable: isAvailableWhatsapp } =
    useStatusFeatureByKey(FEATURE_CS_WHATSAPP);
  const { isAvailable: isAvailablePhoneCall } = useStatusFeatureByKey(
    FEATURE_CS_PHONE_CALL,
  );

  const openInNewTab = (url: string) => {
    onClose();
    openWindow(url, true);
  };

  const onClickWhatsapp = useUpsellFlow({
    title: t('supportChanels.types.whatsapp.upsell'),
    featureKey: FEATURE_CS_WHATSAPP,
    trackingSource: SUPPORT_WHATSAPP,
    callback: () => {
      openInNewTab(whatsappCountryLink[country] || whatsappCountryLink.default);
    },
  });

  const onClickPhoneCall = useUpsellFlow({
    title: t('supportChanels.types.call.upsell'),
    featureKey: FEATURE_CS_PHONE_CALL,
    trackingSource: SUPPORT_PHONECALL,
    callback: () => {
      openInNewTab(phoneCallLink[country]);
    },
  });

  return (
    <Box>
      <Box padding="4" paddingBottom="none">
        <Text
          color="neutral-textHigh"
          fontWeight="bold"
          id="suport-card-header"
        >
          {tAccount('supportChanels.title')}
        </Text>
      </Box>
      <Box padding="2" display="flex" flexDirection="column" gap="0-5">
        <SupportCardEntry
          title={t('supportChanels.types.centerAttention.title')}
          icon={BrowserSearchIcon}
          onClick={() => {
            openInNewTab(canCountryLink[country] || canCountryLink.default);
          }}
        />
        <SupportCardEntry
          title={t('supportChanels.types.chat.title')}
          icon={ChatDotsIcon}
          count={noReadMessagesCount}
          onClick={onOpenChat}
        />
        <SupportCardEntry
          title={t('supportChanels.types.whatsapp.title')}
          icon={WhatsappIcon}
          showUpsell={!isAvailableWhatsapp}
          onClick={onClickWhatsapp}
        />
        {!!phoneCallLink[country] && (
          <SupportCardEntry
            title={t('supportChanels.types.call.title')}
            icon={TelephoneIcon}
            showUpsell={!isAvailablePhoneCall}
            onClick={onClickPhoneCall}
          />
        )}
      </Box>
      <hr
        style={{
          border: 'none',
          borderTop: `1px solid ${tokens.color.light.neutral['surface-highlight'].value}`,
        }}
      />
      {isRevampMenu && (
        <Box
          padding="2"
          paddingBottom="4"
          display="flex"
          flexDirection="column"
          gap="0-5"
        >
          {(country === 'BR' || country === 'MX') && (
            <SupportCardEntry
              title={t('supportChanels.types.ecommerceCourse.title')}
              icon={UniversityIcon}
              onClick={() => {
                openInNewTab(t('supportChanels.types.ecommerceCourse.link'));
              }}
            />
          )}

          <SupportCardEntry
            title={t('supportChanels.types.hireSpecialist.title')}
            icon={LightbulbIcon}
            onClick={() => {
              menuHirePartnerClick();
              openInNewTab(t('supportChanels.types.hireSpecialist.link'));
            }}
          />
        </Box>
      )}
    </Box>
  );
}
