import { useEffect, useMemo, useState } from 'react';
import { Alert, Tag, Text } from '@nimbus-ds/components';
import { useParams } from 'react-router-dom';
import { useNavegate } from 'App/hooks';
import { HeaderContent, HeaderTop, IonPageStratus } from 'commons/components';
import { useForm, useModal } from 'commons/hooks';
import {
  RefundOrderPageSkeleton,
  RefundOrderPageConfirmationModal,
} from './components';
import CurrentPage from './components/CurrentPage';
import { useSteps } from './hooks';
import UseTranslationOrders from '../../../useTranslationOrders';
import { useOrderDetails } from '../../hooks';
import { useCanBeRefunded, useRefundOrder } from '../hooks';
import {
  RefundAttributes,
  RefundAttributesTypes,
} from '../hooks/useRefundOrder/useRefundOrder';

function RefundOrderPage(): JSX.Element | null {
  const { id } = useParams<{ id: string }>();
  const t = UseTranslationOrders();
  const { orderDetails, isLoading: isOrderDetailsLoading } =
    useOrderDetails(id);
  const {
    isLoading: isRefundOrderLoading,
    isSuccess: isRefundOrderSuccess,
    isError,
    refund,
    refundOrderErrorCode,
  } = useRefundOrder(id);
  const { goBack, goTo } = useNavegate();
  const { canBeRefundedThroughRefundForm } = useCanBeRefunded(orderDetails);
  const [closeAlert, setCloseAlert] = useState<boolean>(false);
  const [isShow, openModal, closeModal] = useModal();
  const useStepsResponse = useSteps(goBack);

  const orderTotal = orderDetails?.amounts.total.toString() ?? '0.00';

  const initialValues = useMemo(
    () => ({
      type: RefundAttributesTypes.TOTAL,
      value: orderTotal,
      returnsStock: false,
    }),
    [orderTotal],
  );

  const { values, handleOnChange, handleOnSubmit, isDirty } = useForm<
    RefundAttributes,
    RefundAttributes
  >({
    initialValues,
    onSubmit: async (values) => {
      if (!orderDetails) return;
      return refund(values, orderDetails);
    },
  });

  const disableButton = () => isRefundOrderLoading && !isDirty;

  useEffect(() => {
    if (isError || isRefundOrderSuccess) {
      closeModal();
    }
    if (isRefundOrderSuccess) {
      goTo(`/orders/${id}`);
    }
  }, [isError, isRefundOrderSuccess, goBack, closeModal, goTo, id]);

  const showAlert = isError && !closeAlert;

  if (!canBeRefundedThroughRefundForm()) {
    return null;
  }

  return (
    <IonPageStratus
      width="small"
      headerTop={<HeaderTop navigation={{ onClick: goBack }} />}
      headerContent={
        <HeaderContent
          title={t('refund.title', { orderId: orderDetails?.number })}
        />
      }
    >
      <>
        {isOrderDetailsLoading && <RefundOrderPageSkeleton />}
        {!isOrderDetailsLoading && orderDetails && (
          <>
            <Tag appearance="neutral">
              {t('refund.stepsInfo', {
                currentStep: useStepsResponse.currentStep,
                totalSteps: useStepsResponse.totalSteps,
              })}
            </Tag>
            {isError && (
              <Alert
                onRemove={() => setCloseAlert(true)}
                appearance="danger"
                title={t(`refund.errors.${refundOrderErrorCode}.title`)}
                show={showAlert}
              >
                <Text>
                  {t(`refund.errors.${refundOrderErrorCode}.description`)}
                </Text>
              </Alert>
            )}
            <CurrentPage
              orderDetails={orderDetails}
              values={values}
              onDetailsChange={handleOnChange}
              onConfirm={openModal}
              useSteps={useStepsResponse}
              disableButton={disableButton}
            />
            <RefundOrderPageConfirmationModal
              onConfirm={handleOnSubmit}
              closeModal={closeModal}
              open={isShow}
              isLoading={isRefundOrderLoading}
            />
          </>
        )}
      </>
    </IonPageStratus>
  );
}

export default RefundOrderPage;
