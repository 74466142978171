import { ColorMap, SizeMap, SkeletonSizeMap } from './types';

// Maps old nimbus interface color values with their current representation in the new nimbus
const textColorMap: ColorMap = {
  // textHigh corresponds exactly with old 'default'
  default: { text: 'neutral-textHigh', background: 'neutral-surface' },
  primary: { text: 'primary-textHigh', background: 'primary-surface' },
  secondary: { text: 'primary-textLow', background: 'neutral-surface' },
  success: { text: 'success-textLow', background: 'success-surface' },
  warning: { text: 'warning-textLow', background: 'warning-surface' },
  danger: { text: 'danger-textLow', background: 'danger-surface' },
  light: { text: 'neutral-textLow', background: 'neutral-interactive' },
};

// Maps the old nimbus interface size values to the new nimbus. They are almost the same except the featured one, which
// is no longer available.
const textSizeMap: SizeMap = {
  base: 'base',
  caption: 'caption',
  highlight: 'highlight',

  // Featured is 2rem but is not currently supported on new Text. It should never reach here as if it's featured, the LegacyText
  // will render a <Title /> instead.
  featured: 'highlight',
};

/**
 * Maps correct values from old inputs to expected values, or returns the input width if it's a specific pixel value.
 * @param width The input width. It could match some of the mapping keys or a specific pixel value
 * @returns
 */
const getSkeletonWidth = (width: string): string =>
  ({
    fill: '100%',
    small: '40px',
    medium: '80px',
    large: '120px',
  }[width] ?? width);

const skeletonSizeMap: SkeletonSizeMap = {
  base: {
    width: '80px',
    height: '1rem',
    marginY: '1',
  },
  caption: {
    width: '80px',
    height: '0.75rem',
    marginY: '1',
  },
  featured: {
    width: '80px',
    height: '2rem',
    marginY: '2',
  },
  highlight: {
    width: '80px',
    height: '2rem',
    marginY: '2',
  },
};

export { skeletonSizeMap, textColorMap, textSizeMap, getSkeletonWidth };
