import { Alert, Text } from '@nimbus-ds/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

function AllVariantsDisabledAlert() {
  const t = useTranslationCatalog();

  return (
    <Alert
      title={t(`products.detail.alertAllVariantsDisabled.title`)}
      appearance="danger"
      data-block-submit-error="true"
    >
      <Text>{t(`products.detail.alertAllVariantsDisabled.body`)}</Text>
    </Alert>
  );
}

export default AllVariantsDisabledAlert;
