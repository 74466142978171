import { Box, Icon, Button, Text } from '@nimbus-ds/components';
import { CogIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { Trans } from 'react-i18next';
import { ABANDONED_CART_ROUTES } from 'domains/Orders/AbandonedCart/abandonedCartRoutes';
import useTranslationOrders from 'domains/Orders/useTranslationOrders/useTranslationOrders';
import {
  SectionAppCardDomains,
  SectionAppCard,
} from 'domains/PartnersApps/components';
import emptyAbandonedCartsImage from './empty-abandoned-carts.png';

interface AbandonedCartsListEmptyProps {
  onClickConfig: () => void;
}

function AbandonedCartsListEmpty({
  onClickConfig,
}: AbandonedCartsListEmptyProps) {
  const t = useTranslationOrders();
  return (
    <EmptyMessage
      title={t('abandonedCarts.empty.title')}
      actions={
        <Box display="flex" flex="1" flexDirection="column" width="100%">
          <Text>
            <Trans
              t={t}
              i18nKey="abandonedCarts.empty.description"
              components={{
                br: <br />,
              }}
            />
          </Text>
          <Box paddingTop="3" display="flex" gap="4" flexWrap="wrap">
            <Button appearance="primary" onClick={onClickConfig}>
              <Icon color="currentColor" source={<CogIcon />} />
              {t('abandonedCarts.empty.button')}
            </Button>
          </Box>
          <Box paddingTop="8">
            <SectionAppCard
              source={ABANDONED_CART_ROUTES.abandonedCartList}
              appCategory="communication"
              domain={SectionAppCardDomains.ABANDONED_CARTS}
            />
          </Box>
        </Box>
      }
      illustration={<img width="100%" alt="" src={emptyAbandonedCartsImage} />}
    />
  );
}

export default AbandonedCartsListEmpty;
