import { Card, Text, Box } from '@nimbus-ds/components';
import { ChevronRightIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { useIsInAppBrowser, useNavegate } from 'App/hooks';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { BILLING_ROUTES } from 'domains/Billing/billingRoutes';

function ApplyDiscount() {
  const { t } = useTranslation('common');
  const { goTo } = useNavegate();

  const isInAppBrowserOpen = useIsInAppBrowser();
  const isMobileDevice = useIsMobileDevice();

  const handleClick = () => {
    goTo(BILLING_ROUTES.coupons);
  };

  if (isMobileDevice || isInAppBrowserOpen) return null;

  return (
    <div style={{ cursor: 'pointer' }}>
      <Card onClick={handleClick}>
        <Card.Body>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text color="neutral-textHigh">
              {t('accountMenu.payments.creditCoupon')}
            </Text>
            <ChevronRightIcon />
          </Box>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ApplyDiscount;
