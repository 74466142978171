import { useNavegate } from 'App/hooks';
import { CHARGE_PAID_QUERY } from '../../checkoutRoutes';
import PaymentStatusAlert from '../PaymentStatusAlert';

export default function AfterPaymentAlert() {
  const { getQueryParam } = useNavegate();
  const chargeId = getQueryParam(CHARGE_PAID_QUERY) as string | undefined;

  if (!chargeId) return null;

  return <PaymentStatusAlert chargeId={chargeId} showSuccessAlert />;
}
