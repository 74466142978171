import { Icon, Skeleton } from '@nimbus-ds/components';
import { BaseCard, Button } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';

function TransactionFeeToPayCardSkeleton() {
  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack spacing="base" column align="flex-start">
          <Skeleton width="100px" height="20px" />
        </Stack>
      </BaseCard.Header>

      <BaseCard.Body>
        <Stack spacing="base" column align="flex-start">
          <Text.Skeleton width="large" />
          <Text.Skeleton size="featured" />
          <Button.Skeleton />
        </Stack>
      </BaseCard.Body>
      <hr />
      <BaseCard.Body>
        <Stack spacing="base" align="center" justify="flex-start">
          <Icon.Skeleton />
          <Stack spacing="none" column align="flex-start">
            <Text.Skeleton width="large" />
            <Text.Skeleton width="large" />
          </Stack>
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default TransactionFeeToPayCardSkeleton;
