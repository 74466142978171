import { Box, Button, Icon } from '@nimbus-ds/components';
import { ArrowsVerticalIcon } from '@nimbus-ds/icons';
import { ShowByTag } from 'App/components';
import { CHECKOUT_CUSTOM_PAYMENT } from 'App/featuresFlags';
import { HeaderContent } from 'commons/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

const TRANSLATE_PREFIX = 'paymentProviders';

interface PaymentProvidersListPageHeaderContentProps {
  hideSubtitle?: boolean;
  showButtonSortPayments?: boolean;
  showButtonAdvancedSettings?: boolean;
  handleSortPayments?: () => void;
  handleAdvancedConfiguration?: () => void;
}

function PaymentProvidersListPageHeaderContent({
  hideSubtitle,
  showButtonSortPayments,
  showButtonAdvancedSettings,
  handleSortPayments,
  handleAdvancedConfiguration,
}: PaymentProvidersListPageHeaderContentProps) {
  const t = useTranslationPayments(TRANSLATE_PREFIX);
  return (
    <HeaderContent
      title={t('title')}
      subtitle={hideSubtitle ? '' : t('subtitle')}
      actions={
        <Box gap="2" display="flex">
          {showButtonSortPayments && (
            <ShowByTag tag={CHECKOUT_CUSTOM_PAYMENT}>
              <Button appearance="neutral" onClick={handleSortPayments}>
                <Icon color="currentColor" source={<ArrowsVerticalIcon />} />
                {t('sortPaymentsButton')}
              </Button>
            </ShowByTag>
          )}
          {showButtonAdvancedSettings && (
            <Button appearance="primary" onClick={handleAdvancedConfiguration}>
              {t('advancedConfigurationButton')}
            </Button>
          )}
        </Box>
      }
    />
  );
}

export default PaymentProvidersListPageHeaderContent;
