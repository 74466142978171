import { BaseCard, IconButton } from '@tiendanube/components';
import { useResponsive } from 'commons/components';
import Link from 'commons/components/LegacyLink';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';

function TaxConditionSkeleton(): JSX.Element {
  const { isMobile } = useResponsive();

  return (
    <BaseCard>
      <BaseCard.Header>
        <Stack justify="space-between">
          <Text.Skeleton />
          {isMobile && <IconButton.Skeleton />}
        </Stack>
      </BaseCard.Header>
      <BaseCard.Body>
        <Text.Skeleton />
      </BaseCard.Body>
      {!isMobile && (
        <BaseCard.Footer>
          <Link.Skeleton />
        </BaseCard.Footer>
      )}
    </BaseCard>
  );
}

export default TaxConditionSkeleton;
