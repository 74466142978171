import { Input } from '@tiendanube/components';
import { CardWithTitle } from 'commons/components';
import Stack from 'commons/components/Stack';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function Skeleton(): JSX.Element {
  const t = useTranslationShipping();
  return (
    <CardWithTitle
      title={t('oca.config.originAddress.title')}
      subtitle={t('oca.config.originAddress.subtitle')}
    >
      <Stack column align="stretch">
        <Input.Skeleton />
        <Stack justify="space-between" align="center" spacing="tight">
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
        </Stack>
        <Input.Skeleton />
        <Input.Skeleton />
      </Stack>
    </CardWithTitle>
  );
}

export default Skeleton;
