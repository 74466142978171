import { useEffect, useState } from 'react';
import { Tabs } from '@nimbus-ds/components';
import { AiFeature } from '@tiendanube/common/src/enums';
import { FEATURE_AI_TIER } from 'App/features';
import { AiFeedbackModal } from 'commons/components';
import { useToast } from 'commons/hooks';
import useAiFeedbackFlow from 'commons/hooks/useAiFeedbackFlow';
import { useAiUsage } from 'domains/Ai/hooks';
import useChangeImageBackground from 'domains/Ai/hooks/useChangeImageBackground';
import {
  trackingAiEditImageReplaceBgWithColorUseClick,
  trackingAiEditImageReplaceBgWithColorPickClick,
  trackingAiEditImageReplaceBgWithImagePickClick,
  trackingAiEditImageReplaceBgWithImageUseClick,
} from 'domains/Ai/tracking';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import {
  BackgroundGallery,
  BackgroundGalleryElement,
  ColorPicker,
} from './components';
import { CustomBackgroundImage } from './components/BackgroundGallery/BackgroundGallery';
import { ImageGalleryState } from '../../../../../ImageGallery';
import EditPhotoBaseModal from '../../../EditImageBaseModal';
import { EditedImage } from '../../../EditImageModal';
import BackgroudRemoveUncertaintyAlert from '../Alerts/BackgroudRemoveUncertaintyAlert';

interface ChangeBackgroundModalProps {
  currentImage: ImageGalleryState;
  onSaveImage: (image: EditedImage) => void;
  isShow?: boolean;
  onClose?: () => void;
}

function ChangeBackgroundModal({
  currentImage,
  onSaveImage,
  isShow = false,
  onClose,
}: Readonly<ChangeBackgroundModalProps>) {
  const { addToast } = useToast();
  const t = useTranslationCatalog('products');

  const {
    errorOnBackgroundChange,
    unSavedChanges,
    displayedImage,
    handleBackgroundColorSelected,
    handleBackgroundImageSelected,
    handleBackgroundImageUploaded,
    changingBackground,
    editedImageUncertain,
  } = useChangeImageBackground(currentImage);

  const [hasSelectedOption, setHasSelectedOption] = useState(false);

  const [currentColor, setCurrentColor] = useState<string | undefined>(
    undefined,
  );

  const [selectedBackground, setSelectedBackground] = useState<
    BackgroundGalleryElement | CustomBackgroundImage | undefined
  >();

  const { refetchAiUses, requiredTier, aiUsesUpsellTitle } = useAiUsage();

  const newColorIsSelected = async (color: string) => {
    setHasSelectedOption(true);
    setCurrentColor(color);
    setSelectedBackground(undefined);
    trackingAiEditImageReplaceBgWithColorPickClick({ color });
    await handleBackgroundColorSelected(color);
    refetchAiUses();
  };

  const handleNewColorIsSelected = useUpsellFlow({
    title: aiUsesUpsellTitle,
    featureKey: FEATURE_AI_TIER,
    trackingSource: AiFeature.AI_IMAGES_REPLACE_BG_COLOR,
    callback: newColorIsSelected,
    minValue: requiredTier,
    asAside: true,
  });

  const onSelectBackground = async (
    background: BackgroundGalleryElement | CustomBackgroundImage,
  ) => {
    setHasSelectedOption(true);
    setSelectedBackground(background);
    setCurrentColor(undefined);
    trackingAiEditImageReplaceBgWithImagePickClick({
      selectedImageId: background.id,
    });

    if ('base64' in background) {
      await handleBackgroundImageUploaded(background.base64);
    } else {
      await handleBackgroundImageSelected(background);
    }

    refetchAiUses();
  };

  const handleOnSelectBackground = useUpsellFlow({
    title: aiUsesUpsellTitle,
    featureKey: FEATURE_AI_TIER,
    trackingSource: AiFeature.AI_IMAGES_REPLACE_BG_IMAGE,
    callback: onSelectBackground,
    minValue: requiredTier,
    asAside: true,
  });

  const handleOnSubmit = () => {
    if (unSavedChanges && displayedImage.base64) {
      onSaveImage({ base64: displayedImage.base64 });
      if (currentColor) {
        trackingAiEditImageReplaceBgWithColorUseClick({ color: currentColor });
      }
      if (selectedBackground) {
        trackingAiEditImageReplaceBgWithImageUseClick({
          selectedImageId: selectedBackground.id,
        });
      }
    }
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (errorOnBackgroundChange) {
      addToast({
        appearance: 'danger',
        label: t('modal.editPhoto.toasts.error.label'),
      });
    }
  }, [errorOnBackgroundChange, addToast, t]);

  const handleOnClose = () => {
    if (onClose) {
      setHasSelectedOption(false);
      onClose();
    }
  };

  const handleOnCloseWithFeedback = () => {
    if (hasSelectedOption) {
      openAiGenerationFeedbackModal();
    } else {
      handleOnClose();
    }
  };

  const [
    showAiGenerationFeedbackModal,
    openAiGenerationFeedbackModal,
    closeAiGenerationFeedbackModal,
  ] = useAiFeedbackFlow(AiFeature.AI_IMAGES_REPLACE_BG_COLOR, handleOnClose);

  return (
    <>
      <EditPhotoBaseModal
        aiFeatureTrackingSource={AiFeature.AI_IMAGES_REPLACE_BG_COLOR}
        isShow={isShow}
        loadingImage={changingBackground}
        displayImageSource={displayedImage}
        onClose={handleOnCloseWithFeedback}
        translationPrefix="products.modal.editPhoto.actions.changeBackground.modal"
        beforeImageChildren={
          editedImageUncertain ? <BackgroudRemoveUncertaintyAlert /> : undefined
        }
        afterImageChildren={
          <Tabs>
            <Tabs.Item
              label={t(
                'modal.editPhoto.actions.changeBackground.modal.colorBg.tabName',
              )}
            >
              <ColorPicker
                selectedColor={currentColor}
                onSelect={handleNewColorIsSelected}
              />
            </Tabs.Item>
            <Tabs.Item
              label={t(
                'modal.editPhoto.actions.changeBackground.modal.imageBg.tabName',
              )}
            >
              <BackgroundGallery
                selectedBackground={selectedBackground}
                onSelect={handleOnSelectBackground}
              />
            </Tabs.Item>
          </Tabs>
        }
        handleOnSave={handleOnSubmit}
        showAiUsage
        enableSaveButton={
          unSavedChanges && !changingBackground && !errorOnBackgroundChange
        }
      />
      <AiFeedbackModal
        asAside
        show={showAiGenerationFeedbackModal}
        title={t(
          'modal.editPhoto.actions.changeBackground.modal.feedback.title',
        )}
        text={t('modal.editPhoto.actions.changeBackground.modal.feedback.text')}
        feature={AiFeature.AI_IMAGES_REPLACE_BG_COLOR}
        feedbackParams={{
          imageEditing: {
            oldImageUrl: currentImage.publicUrl!,
            newImageBase64: displayedImage.base64!,
          },
        }}
        onDismiss={() => {
          closeAiGenerationFeedbackModal();
          handleOnClose();
        }}
      />
    </>
  );
}

export default ChangeBackgroundModal;
