import { useCallback } from 'react';
import { generatePath } from 'react-router';
import { CheckoutPaymentMethod, RecurrencyOptionDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { useAppDispatch } from 'App/store';
import { combineStatus } from 'commons/utils/combineStatus';
import { checkoutApi } from 'domains/Billing/Checkout/CheckoutApi';
import { checkoutTags } from 'domains/Billing/Checkout/CheckoutApi/config';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import {
  usePayorderToPay,
  useUpdateCharge,
  usePaymentSetup,
  useAfterPaymentRedirection,
} from 'domains/Billing/Checkout/hooks';
import { paymentMethodIsMercadoPago } from '../../utils';

function useContinuePayment() {
  const dispatch = useAppDispatch();
  const { payPayOrder: payPayOrderPerMethod, extraStatus } = usePaymentSetup();
  const { updateCharge, status: updateSubscriptionsStatus } = useUpdateCharge();
  const { goTo } = useNavegate();
  const { passOnActionToPath } = useAfterPaymentRedirection();

  const { payorderToPay, isForPlan } = usePayorderToPay();

  const statusObject = combineStatus(updateSubscriptionsStatus, extraStatus);

  const onPay = useCallback(
    async (
      paymentMethod: CheckoutPaymentMethod,
      recurrencyOption?: RecurrencyOptionDto,
    ) => {
      if (!payorderToPay || statusObject.isLoading) return;
      if (isForPlan && recurrencyOption) {
        await updateCharge({
          method: paymentMethodIsMercadoPago(paymentMethod)
            ? 'ticket'
            : paymentMethod,
          recurringFrequency: recurrencyOption.recurringFrequency,
          recurringInterval: recurrencyOption.recurringInterval,
        })
          .unwrap()
          .finally(() => {
            dispatch(
              checkoutApi.util.invalidateTags([
                checkoutTags.pendingCharges,
                checkoutTags.payOrderToPay,
              ]),
            );
          });
      }
      const payPayOrder = payPayOrderPerMethod[paymentMethod];
      payPayOrder(paymentMethod, payorderToPay.id);
    },
    [
      payPayOrderPerMethod,
      payorderToPay,
      isForPlan,
      updateCharge,
      statusObject,
      dispatch,
    ],
  );

  const goToRecurrencies = useCallback(
    (payOrderId: string, method: CheckoutPaymentMethod) => {
      goTo(
        passOnActionToPath(
          `${generatePath(CHECKOUT_ROUTES.choosePaymentRecurrency, {
            payOrderId,
          })}?method=${method}`,
        ),
      );
    },
    [goTo, passOnActionToPath],
  );

  return {
    ...statusObject,
    onPay,
    goToRecurrencies,
  };
}

export default useContinuePayment;
