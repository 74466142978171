import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { InterfaceNameValue, Popover } from '@tiendanube/components';
import { TrashIcon } from '@tiendanube/icons';
import ModalAside from 'App/components/lab/ModalAside';
import { LanguagesType } from 'App/i18n';
import {
  CancelAndConfirmButtons,
  HeaderContent,
  HeaderTop,
} from 'commons/components';
import TabsMultiLanguage from 'commons/components/TabsMultiLanguage';
import { useStandardVariants } from 'domains/Catalog/Products/pages/hooks';
import { attributeHasValues } from 'domains/Catalog/Products/utils';
import AttributeValues from './AttributeValues';
import AttributeValuesModalSkeleton from './AttributeValuesModalSkeleton';
import { getLocaleStandardName } from './constants';
import { useAttributeForm } from './hooks';
import {
  getInitialSelectedVariantType,
  hasSomeAttributeOption,
  fillDefaultLanguageAttribute,
} from './utils';
import { Attribute, AttributeType, Attributes } from '../../types';

interface AttributeValuesModalProps {
  isShow: boolean;
  attribute?: Attribute;
  attributes?: Attributes;
  language: LanguagesType;
  onChange: (attribute: Attribute, prevAttribute?: Attribute) => void;
  onRemove: (attribute: Attribute) => void;
  onClose: () => void;
}

function AttributeValuesModal({
  isShow,
  attribute,
  attributes,
  language,
  onChange,
  onRemove,
  onClose,
}: AttributeValuesModalProps): JSX.Element {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation(Domain.CATALOG);
  const { isLoading, isSuccess, standardVariants, fetchStandardVariants } =
    useStandardVariants();
  const [confirmPressed, setConfirmPressed] = useState(false);
  const [emptyValuesError, setEmptyValuesError] = useState(false);
  const [selectedVariantType, setSelectedVariantType] =
    useState<AttributeType | null>(null);

  const {
    name,
    attributeOptions,
    handleChangeName,
    handleAddOption,
    handleRemoveOption,
    handleChangeOption,
    handleCheckOption,
    handleBlurOption,
    handleSortOptions,
    clearValues,
  } = useAttributeForm(
    standardVariants,
    selectedVariantType,
    locale,
    attribute,
  );

  useEffect(() => {
    setSelectedVariantType(
      attribute
        ? getInitialSelectedVariantType(
            attribute?.name[language] || '',
            language,
            locale,
            standardVariants,
          )
        : null,
    );
  }, [attribute, language, locale, standardVariants]);

  useEffect(() => {
    if (isShow) {
      fetchStandardVariants();
    }
  }, [isShow, fetchStandardVariants]);

  const handleClose = () => {
    setSelectedVariantType(null);
    clearValues();
    onClose();
  };

  const handleConfirm = () => {
    setConfirmPressed(true);

    if (!getIsConfirmEnabled()) {
      setEmptyValuesError(true);
      return;
    }
    setEmptyValuesError(false);

    const isStandardVariant =
      selectedVariantType && ['color', 'size'].includes(selectedVariantType);

    const attributeName = isStandardVariant
      ? getLocaleStandardName(locale)[selectedVariantType]
      : selectedVariantType
      ? standardVariants?.[selectedVariantType]?.name ?? name
      : name;

    const newAttribute = fillDefaultLanguageAttribute(
      {
        name: attributeName,
        values: attributeOptions,
      },
      language,
    );
    const prevAttribute = attribute;
    onChange(newAttribute, prevAttribute);
    setConfirmPressed(false);
    handleClose();
  };

  const handleRemove = () => {
    if (attribute) onRemove(attribute);
    handleClose();
  };

  const handleSelectVariant = (
    language: string,
    { value: attribute }: InterfaceNameValue,
  ) => {
    clearValues();
    setSelectedVariantType(attribute);
    if (attribute !== 'new_attribute') {
      handleChangeName(
        language,
        getLocaleStandardName(locale)[attribute]?.[language] || attribute,
      );
    } else {
      handleChangeName(language, '');
    }
    setEmptyValuesError(false);
  };

  const getIsConfirmEnabled = () => {
    if (selectedVariantType === 'new_attribute') {
      return (
        !!name &&
        Object.values(name).some((v) => v.trim() !== '') &&
        hasSomeAttributeOption(attributeOptions)
      );
    }
    return !!selectedVariantType && hasSomeAttributeOption(attributeOptions);
  };

  const asignedAttributes = Object.values(attributes ?? {})
    .filter(attributeHasValues)
    .map(({ name }) =>
      getInitialSelectedVariantType(
        name?.[language] ?? '',
        language,
        locale,
        standardVariants,
      ),
    );

  const ignoredAttributes = asignedAttributes.filter(
    (attribute) =>
      attribute !== selectedVariantType && attribute !== 'new_attribute',
  );

  return (
    <ModalAside
      isOpen={isShow}
      onDidDismiss={handleClose}
      headerTop={
        <HeaderTop
          navigation={{
            onClick: handleClose,
          }}
          actions={
            attribute && (
              <Popover
                menu={[
                  {
                    appearance: 'danger',
                    onClick: handleRemove,
                    icon: TrashIcon,
                    children: t('products.variants.delete'),
                  },
                ]}
                name="dropdownMenu"
                position="right"
              />
            )
          }
          mainAction={
            isSuccess
              ? {
                  children: attribute
                    ? `${t('products.variants.save')}`
                    : `${t('products.variants.create')}`,
                  appearance: 'secondary',
                  onClick: handleConfirm,
                }
              : undefined
          }
        />
      }
      headerContent={
        <HeaderContent
          title={
            attribute
              ? t('products.variants.editProperty')
              : t('products.variants.newProperty')
          }
        />
      }
    >
      {isLoading && <AttributeValuesModalSkeleton />}
      {isSuccess && (
        <>
          <TabsMultiLanguage>
            <AttributeValues
              name={name}
              attributeOptions={attributeOptions}
              standardVariants={standardVariants}
              selectedVariantType={selectedVariantType}
              ignoredVariantTypes={ignoredAttributes}
              emptyValuesError={emptyValuesError}
              confirmPressed={confirmPressed}
              onChangeName={handleChangeName}
              onAddOption={handleAddOption}
              onChangeOption={handleChangeOption}
              onRemoveOption={handleRemoveOption}
              onSelectVariant={handleSelectVariant}
              onCheckOption={handleCheckOption}
              onBlurOption={handleBlurOption}
              onSortOptions={handleSortOptions}
            />
          </TabsMultiLanguage>
          {!!selectedVariantType && (
            <CancelAndConfirmButtons
              onCancel={handleClose}
              onConfirm={handleConfirm}
              cancelText={t('products.variants.cancel')}
              confirmText={
                attribute
                  ? `${t('products.variants.save')}`
                  : `${t('products.variants.create')}`
              }
              appearance="primary"
            />
          )}
        </>
      )}
    </ModalAside>
  );
}

export default AttributeValuesModal;
