import { useEffect } from 'react';
import { ErrorScreen } from 'commons/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { PagoNubeInvoiceHistoryDataTable } from './components';
import PagoNubeInvoiceHistoryWebSkeleton from './PagoNubeInvoiceHistoryWebSkeleton';
import useExternalInvoiceHistoryWeb from './useExternalInvoiceHistoryWeb';
import { ExternalInvoiceMerchantService } from '../../types';

function PagoNubeInvoiceHistoryWeb(): JSX.Element {
  const t = useTranslationBilling();

  const {
    fetchExternalInvoiceHistory,
    fetchExternalInvoiceHistoryInit,
    externalInvoiceHistory,
    status,
  } = useExternalInvoiceHistoryWeb(ExternalInvoiceMerchantService.PAGO_NUBE);

  const retryGetExternalInvoiceHistory = () => fetchExternalInvoiceHistory(1);

  useEffect(() => {
    fetchExternalInvoiceHistoryInit();
  }, [fetchExternalInvoiceHistoryInit]);

  const handlePageSelect = (page) => {
    fetchExternalInvoiceHistory(page);
  };

  if (status === 'error')
    return (
      <ErrorScreen
        description={t('invoiceHistory.errors.load')}
        onRetry={retryGetExternalInvoiceHistory}
      />
    );

  return (
    <div className="invoice-history-web">
      <PagoNubeInvoiceHistoryDataTable
        onPageSelect={handlePageSelect}
        pagination={externalInvoiceHistory.pagination}
        data={externalInvoiceHistory.results}
        status={status}
      />
    </div>
  );
}

PagoNubeInvoiceHistoryWeb.Skeleton = PagoNubeInvoiceHistoryWebSkeleton;

export default PagoNubeInvoiceHistoryWeb;
