import { Input } from '@nimbus-ds/components';
import { ItemProps } from 'domains/Metafields/components/MetafieldInput/types';
import useMergeMetafieldsEvents from 'domains/Metafields/hooks/useMergeMetafieldsEvents';

function DateItem({
  metafield,
  metafieldKey,
  disabled,
  value,
  onChange,
  onUpdate,
  placeholder,
}: ItemProps) {
  const handleChange = ({ target: { value } }) => {
    onChange?.(value);
    onUpdate?.(value || null);
  };

  const inputEvents = {
    onChange: handleChange,
  };

  const mergeEvents = useMergeMetafieldsEvents(
    inputEvents,
    metafield.formFieldConfig?.events,
  );
  const mergedEvents = mergeEvents();

  return (
    <Input
      type="date"
      id={metafieldKey}
      disabled={disabled !== undefined ? disabled : metafield.disabled}
      name={metafield.name}
      value={value}
      placeholder={placeholder}
      {...mergedEvents}
    />
  );
}

export default DateItem;
