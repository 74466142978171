import { ReactNode } from 'react';
import { Box } from '@nimbus-ds/components';
import { useHistory } from 'react-router-dom';
import { HeaderTop, IonPageStratus, Layout } from 'commons/components';
import PayOrderSummary from '../PayOrderSummary';

interface ChoosePaymentMethodWithRecurrencyLayoutProps {
  mainContent: ReactNode;
}

export default function ChoosePaymentMethodWithRecurrencyLayout({
  mainContent,
}: Readonly<ChoosePaymentMethodWithRecurrencyLayoutProps>) {
  const { goBack } = useHistory();
  const backNavigation = {
    onClick: goBack,
  };

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={backNavigation} />}
      width="large"
    >
      <Layout
        mainContent
        left={mainContent}
        right={
          <Box display="flex" flexDirection="column" flexGrow="1">
            <PayOrderSummary showTaxes />
          </Box>
        }
        orderMobile="reverse"
      />
    </IonPageStratus>
  );
}
