import { Box, Text } from '@nimbus-ds/components';
import { ErrorState } from '@tiendanube/components';
import { Format, dateFormat } from 'commons/utils/date';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { useNoChargesMessage } from '../../hooks';
import SelectChargesToPaySkeleton from '../../Skeleton';

function NoCharges() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.paymentsTabs.toPay',
  );

  const { nextExecutionDate, isLoading, isError, retryLoad } =
    useNoChargesMessage();

  if (isLoading) return <SelectChargesToPaySkeleton />;
  if (isError)
    return (
      <ErrorState
        title={t('error.title')}
        action={{ children: t('error.retry'), onClick: retryLoad }}
      />
    );

  return (
    <Box display="flex" flexDirection="column" padding="4" gap="2">
      <Text fontSize="highlight" fontWeight="bold">
        {t('noCharges.title')}
      </Text>
      {!!nextExecutionDate && (
        <Text>
          {t('noCharges.dateDescription', {
            date: dateFormat(nextExecutionDate, Format.DD_MM_YYYY),
          })}
        </Text>
      )}
    </Box>
  );
}

export default NoCharges;
