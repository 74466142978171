import {
  Alert,
  Box,
  Button,
  Card,
  Icon,
  Spinner,
  Text,
  Title,
} from '@nimbus-ds/components';
import { CheckCircleIcon } from '@nimbus-ds/icons';
import { useSelector } from 'react-redux';
import { OwnerResourceType } from '@tiendanube/common';
import ModalAside from 'App/components/lab/ModalAside';
import Stack from 'commons/components/Stack';
import useModalAssignMetafields from 'domains/Catalog/Products/pages/hooks/useModalAssignMetafields';
import { getTotalProducts } from 'domains/Catalog/Products/productsSlice/productSelectors';
import { useGetProductsAndVariantsMetafields } from 'domains/Metafields/hooks';
import useTranslationMetafields from 'domains/Metafields/hooks/useTranslationsMetafields';
import { SourceType } from 'domains/Metafields/types';
import { MetafieldItem } from '../../MetafieldInput';

const MAX_METAFIELDS_WITHOUT_SCROLL = 3;

interface ModalAssignMetafieldsProps {
  selectedRowsId: string[];
  selectAll: boolean;
  ownerResource: OwnerResourceType;
}

export default function ModalAssignMetafields({
  selectedRowsId,
  selectAll,
  ownerResource,
}: Readonly<ModalAssignMetafieldsProps>) {
  const t = useTranslationMetafields();
  const productsCount = useSelector(getTotalProducts);
  const {
    values,
    showModal,
    handleOnClose,
    handleClick,
    handleChange,
    isLoading,
  } = useModalAssignMetafields(ownerResource);
  const { metafields, apiMetafields, hasInternals, hasExternals } =
    useGetProductsAndVariantsMetafields(ownerResource);

  const showAssignButton =
    hasInternals && hasExternals
      ? true
      : Number(
          (hasInternals && metafields?.length) ||
            (hasExternals && apiMetafields?.length),
        ) > MAX_METAFIELDS_WITHOUT_SCROLL;

  const titleCount = selectAll ? productsCount : selectedRowsId.length;

  const tModal = `assign${
    ownerResource === 'product_variant' ? 'ProductVariants' : 'Products'
  }MetafieldsModal`;

  return (
    <ModalAside
      isOpen={showModal}
      onDidDismiss={handleOnClose}
      headerContent={t(`${tModal}.title`)}
    >
      {!!showAssignButton && (
        <Box position="absolute" top="8px" right="16px">
          <Button appearance="transparent" onClick={handleClick}>
            <Icon source={<CheckCircleIcon />} />
            <Text fontWeight="regular">{t(`${tModal}.buttonTopAssign`)}</Text>
          </Button>
        </Box>
      )}

      <Text fontSize="highlight">
        {titleCount} {t(`${tModal}.selectedProducts`)}
      </Text>

      <Alert appearance="neutral">{t(`${tModal}.neutralAlert`)}</Alert>

      {hasInternals && (
        <Card>
          {hasExternals && (
            <Card.Header>
              <Title as="h3">{t(`${tModal}.internalsSubtitle`)}</Title>
            </Card.Header>
          )}
          {metafields?.map((metafield) => {
            const found = values.metafields.find((m) => m.id === metafield.id);
            const value = found?.value || '';
            return (
              <MetafieldItem
                key={metafield.id}
                metafield={metafield}
                value={value}
                source={SourceType.ADMIN}
                onChange={handleChange}
              />
            );
          })}
        </Card>
      )}

      {hasExternals && (
        <Card>
          <Card.Header>
            <Title as="h3">{t(`${tModal}.fromApiSubtitle`)}</Title>
          </Card.Header>
          {apiMetafields?.map((metafield) => {
            const found = values.metafields.find((m) => m.id === metafield.id);
            const value = found?.value || '';
            return (
              <MetafieldItem
                key={metafield.id}
                metafield={metafield}
                value={value}
                source={SourceType.APP}
                onChange={handleChange}
              />
            );
          })}
        </Card>
      )}
      <Stack spacing="base" justify="flex-end">
        <Stack.Item>
          <Button onClick={handleOnClose} disabled={isLoading}>
            {t(`${tModal}.cancel`)}
          </Button>
        </Stack.Item>
        <Stack.Item>
          <Button
            onClick={handleClick}
            disabled={isLoading}
            appearance="primary"
          >
            {isLoading && <Spinner color="neutral-interactive" size="small" />}
            {t(`${tModal}.save`)}
          </Button>
        </Stack.Item>
      </Stack>
    </ModalAside>
  );
}
