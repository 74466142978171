import { TFunction } from 'i18next';
import { Modal, Text } from '@tiendanube/components';

interface ConfirmIgnoreModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  t: TFunction;
}

function ConfirmIgnoreModal({
  show,
  onClose,
  onConfirm,
  t,
}: ConfirmIgnoreModalProps) {
  return (
    <Modal
      title={t('modalConfirmIgnore.title')}
      show={show}
      onDismiss={onClose}
      secondaryAction={{
        children: `${t('cancel')}`,
        onClick: onClose,
      }}
      primaryAction={{
        children: `${t('confirm')}`,
        appearance: 'primary',
        onClick: onConfirm,
      }}
    >
      <Text>{t('modalConfirmIgnore.message')}</Text>
    </Modal>
  );
}

export default ConfirmIgnoreModal;
