import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToastProgress } from 'App/components/ToastProgressContext';
import {
  CATALOG_HIDING_VARIANTS,
  CATALOG_UPLOAD_VIDEOS,
} from 'App/featuresFlags';
import { useHasTags, useNavegate } from 'App/hooks';
import { useCloneProductJobs } from 'App/settings/jobs/hooks';
import { ActionProp, useResponsive } from 'commons/components';
import { useClipboard, useModal, useToast } from 'commons/hooks';
import { openWindow } from 'commons/utils/window';
import { useGetLanguage, useHasShippingMultiCD } from 'domains/Auth/hooks';
import { ProductToCloneInterface } from 'domains/Catalog/Products/productsServices';
import { productHasVariants } from 'domains/Catalog/Products/utils';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import AlertCreateProductsAndVariantsMetafields from 'domains/Metafields/components/Alerts/AlertCreateProductsAndVariantsMetafields';
import { trackRelatedProduct } from 'domains/Online/tracking';
import {
  useAppsActionOpenProducts,
  useAppsLinks,
} from 'domains/PartnersApps/hooks';
import {
  AlertPersistsMetafields,
  AlertPersistsSectionCodes,
  FormProduct,
  ProductPage,
} from '../../../components';
import GoToInventoryModal from '../../../components/GoToInventoryModal';
import { useGoToInventory } from '../../../components/GoToInventoryModal/GoToInventoryProvider';
import ModalConfirmationCloneProduct from '../../../components/ModalConfirmationCloneProduct';
import ModalConfirmationRemoveProduct from '../../../components/ModalConfirmationRemoveproduct';
import {
  useProductForm,
  useFetchProductVariantsMetafields,
} from '../../../hooks';
import { ProductFormState } from '../../../hooks/useProductForm';
import { updateProductDigest, cloneProductDigest } from '../../digests';
import { useEditProduct } from '../../hooks';
import Actions from '../Actions';

function ProductDetailsPage(): JSX.Element {
  const hasShippingMultiCD = useHasShippingMultiCD();
  const [hasUploadVideos, hasHidingVariants] = useHasTags([
    CATALOG_UPLOAD_VIDEOS,
    CATALOG_HIDING_VARIANTS,
  ]);
  const { goBack, replace } = useHistory();
  const { isDesktop } = useResponsive();
  const { id } = useParams<{ id: string }>();
  const { hasSearchParam } = useNavegate();
  const showCloneModal = hasSearchParam('duplicate');

  const {
    product,
    metafieldsPersistsStatus,
    updateProduct,
    deleteProduct,
    cloneProduct,
    cleanMetafieldsPersistsStatus,
    cleanCreateHighlightProductStatus,
    updateHighlightProductError,
  } = useEditProduct(id);
  const { setJob } = useCloneProductJobs();
  const { selectFirstOptionMetafields } = useFetchProductVariantsMetafields();
  const { getAppsLinks } = useAppsLinks();
  const t = useTranslationCatalog();
  const copyToClipboard = useClipboard();
  const language = useGetLanguage();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [
    isShowCloneProductModal,
    openCloneProductModal,
    closeCloneProductModal,
  ] = useModal(showCloneModal);
  const { addToastProgress, closeToastProgress } = useToastProgress();
  const [
    isShowRemoveProductModal,
    openRemoveProductModal,
    closeRemoveProductModal,
  ] = useModal();
  const appsActionOpen = useAppsActionOpenProducts(id);
  const onUpdateProduct = async (data: Partial<ProductFormState>) => {
    addToastProgress({
      label: t('products.updatingProduct'),
    });

    const params = updateProductDigest({
      updateProduct: data,
      initialProduct: product,
      hasShippingMultiCD,
      hasMedia: hasUploadVideos,
      onlyVisibleVariants: !hasHidingVariants,
    });
    trackRelatedProduct({
      initialValue: product?.relatedProducts?.alternative,
      currentValue: data?.relatedProducts?.alternative,
      type: 'ALTERNATIVE',
    });
    trackRelatedProduct({
      initialValue: product?.relatedProducts?.complementary,
      currentValue: data?.relatedProducts?.complementary,
      type: 'COMPLEMENTARY',
    });
    await updateProduct(params);
  };

  const {
    goToInventory,
    handleSaveAndGoInventory,
    handleDismissGoToInventory,
    isShowGotoInventory,
    setDirty,
    cleanGoToInventory,
  } = useGoToInventory();

  const onSuccess = () => {
    closeToastProgress();
    addToast({
      label: t('products.toast.updated'),
      appearance: 'success',
    });
    goToInventory();
  };

  const onError = () => {
    closeToastProgress();
    addToast({
      label: t('products.toast.updatedError'),
      appearance: 'danger',
    });
    cleanGoToInventory();
  };

  const {
    values,
    errors,
    isDirty,
    isSaving,
    showModalNullStockVariant,
    handleCloseModalNullStockVariant,
    handleConfirmModalNullVaritantStock,
    handleChange,
    handleChangeCategories,
    handleChangeImages,
    handleChangeVariants,
    handleChangeAttributes,
    handleSave,
    handleChangeToAllVariants,
    handleChangeSectionCodes,
    handleChangeToAllMetafieldsVariants,
    handleChangeRelatedProducts,
  } = useProductForm({
    initialValues: product,
    selectFirstOptionMetafields,
    onSave: onUpdateProduct,
    onError,
    onSuccess,
  });

  setDirty(isDirty);

  const handleCloneProduct = async (data: ProductToCloneInterface) => {
    setIsLoading(true);
    const params = cloneProductDigest(data, id, hasHidingVariants ? '3' : '2');
    const { product, token } = await cloneProduct(params);
    if (token) {
      setJob(product.id, token);
    }
    setIsLoading(false);
    replace(product.id);
  };

  const handleCloneProductError = () => setIsLoading(false);

  const handleDeleteProduct = async () => {
    await deleteProduct(id);
  };

  const productTitle = product.name[language] || '';

  const hasVariants = productHasVariants(values.variants);

  const firstVariantId = values.variants[0].id;

  const handleCopyVariantId = () => {
    copyToClipboard(firstVariantId, t('products.detail.copyVariantId.success'));
  };

  const actions = Actions({
    productTitle,
    productCanonicalUrl: product.canonicalUrl,
    ...(!hasVariants ? { variantId: firstVariantId } : {}),
    viewProductText: t('products.detail.header.viewProduct'),
    shareText: t('products.detail.header.share'),
    duplicateText: t('products.detail.header.duplicate'),
    deleteText: t('products.detail.header.delete'),
    saveText: t('products.detail.save'),
    copyVariantIdText: t('products.detail.copyVariantId.label'),
    appsActionOpen,
    isDesktop,
    isLoading: isSaving || isLoading,
    openCloneProductModal,
    openRemoveProductModal,
    handleCopyVariantId,
    handleSave,
  });

  const applicationsDesktop: ActionProp[] = getAppsLinks(
    'products_single',
    'default',
  ).map((app) => ({
    children: app.text,
    onClick: () => openWindow(`${app.url}&id=${id}`, true),
  }));

  return (
    <ProductPage
      isSaving={isSaving || isLoading}
      handleSave={handleSave}
      actions={actions}
      appLinks={applicationsDesktop}
      title={productTitle}
      isDirty={isDirty}
      showModalNullStockVariant={showModalNullStockVariant}
      handleCloseModalNullStockVariant={handleCloseModalNullStockVariant}
      handleConfirmModalNullVaritantStock={handleConfirmModalNullVaritantStock}
      isEdit
    >
      <GoToInventoryModal
        show={isShowGotoInventory}
        onDismiss={handleDismissGoToInventory}
        onSave={() => handleSaveAndGoInventory(handleSave)}
      />
      <AlertCreateProductsAndVariantsMetafields isEdit />
      <AlertPersistsMetafields
        status={metafieldsPersistsStatus}
        onClose={cleanMetafieldsPersistsStatus}
      />
      <AlertPersistsSectionCodes
        show={updateHighlightProductError}
        onClose={cleanCreateHighlightProductStatus}
      />
      <FormProduct
        initialStock={product.variants[0].stock}
        values={values}
        errors={errors}
        handleChange={handleChange}
        handleChangeCategories={handleChangeCategories}
        handleChangeImages={handleChangeImages}
        handleChangeVariants={handleChangeVariants}
        handleChangeAttributes={handleChangeAttributes}
        handleChangeToAllVariants={handleChangeToAllVariants}
        handleChangeSectionCodes={handleChangeSectionCodes}
        handleChangeRelatedProducts={handleChangeRelatedProducts}
        handleChangeToAllMetafieldsVariants={
          handleChangeToAllMetafieldsVariants
        }
      />
      {isShowCloneProductModal && (
        <ModalConfirmationCloneProduct
          onCloneProduct={handleCloneProduct}
          onClose={closeCloneProductModal}
          hasImages={values.images.length > 0}
          productName={values.name}
          onError={handleCloneProductError}
          variants={values.variants}
        />
      )}
      {isShowRemoveProductModal && (
        <ModalConfirmationRemoveProduct
          onClose={closeRemoveProductModal}
          onRemoveProduct={handleDeleteProduct}
          goBack={goBack}
        />
      )}
    </ProductPage>
  );
}

export default ProductDetailsPage;
