import { useEffect } from 'react';
import { Box, Card, Checkbox, Text } from '@nimbus-ds/components';
import { InteractiveList } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';
import { useLocations } from 'domains/Shipping/Locations/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import LocationListForPickupSkeleton from './Skeleton';

interface LocationListForPickupProps {
  ids: string[];
  errors: Partial<Record<string, string>>;
  setFieldValue: (name: string, value: any) => void;
}

function LocationListForPickup({
  ids,
  errors,
  setFieldValue,
}: Readonly<LocationListForPickupProps>) {
  const t = useTranslationShipping(
    'deliveryMethods.pickupPoints.form.firstStep',
  );
  const { fetchLocations, locations, isLoading } = useLocations();
  const isSelectedAllLocations = locations.every((location) =>
    ids.includes(location.id),
  );

  const onSelectLocation = (selected: string, checked: boolean) => {
    setFieldValue(
      'locations',
      checked ? [...ids, selected] : ids.filter((id) => id !== selected),
    );
  };

  const handleSelectAllLocations = (checked: boolean) => {
    setFieldValue(
      'locations',
      checked ? locations.map((location) => location.id) : [],
    );
  };

  useEffect(() => {
    if (!isLoading && locations.length === 0) {
      fetchLocations();
    }
  }, [fetchLocations, isLoading, locations.length]);

  return (
    <Card padding="none">
      <Card.Header padding="base" title={t('location')} />
      <Card.Body padding="base">
        <Stack align="flex-start" spacing="loose" column>
          <Text>{t('locationSubtitle')}</Text>
          {locations.length >= 3 && (
            <Checkbox
              name="selectAll"
              checked={isSelectedAllLocations}
              label={t('textSelectAll')}
              onChange={(event) =>
                handleSelectAllLocations(event.target.checked)
              }
            />
          )}
        </Stack>
      </Card.Body>
      <Card.Footer>
        <Stack
          spacing="none"
          column
          flex="0 100%"
          align="flex-start"
          justify="space-around"
        >
          {locations && locations.length > 0 && (
            <InteractiveList>
              {locations.map(({ id, name, address }) => (
                <InteractiveList.CheckboxItem
                  key={id}
                  title={name}
                  description={address}
                  checkbox={{
                    name: id,
                    checked: ids.includes(id),
                    onChange: (e) => onSelectLocation(id, e.target.checked),
                  }}
                />
              ))}
            </InteractiveList>
          )}
          {!!errors.locations && (
            <Box padding="4">
              <Text fontSize="caption" color="danger-textLow">
                {t(errors.locations)}
              </Text>
            </Box>
          )}
        </Stack>
      </Card.Footer>
    </Card>
  );
}

LocationListForPickup.Skeleton = LocationListForPickupSkeleton;

export default LocationListForPickup;
