import { Box } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { EmptyState, Stack, Text } from '@tiendanube/components';
import { PlusCircleIcon } from '@tiendanube/icons';
import { LinkButton } from 'App/components';
import { DRAFT_ORDERS_ROUTES } from 'domains/Orders/DraftOrders/draftOrdersRoutes';
import emptyDraftOrdersImage from 'domains/Orders/images/empty-orders.png';
import {
  SectionAppCard,
  SectionAppCardDomains,
} from 'domains/PartnersApps/components';

function DraftOrdersListEmpty(): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);
  return (
    <EmptyState
      image={emptyDraftOrdersImage}
      title={t('draftOrders.empty.title')}
    >
      <Stack column align="flex-start">
        <Stack.Item>
          <Text>{t('draftOrders.empty.description.line1')}</Text>
        </Stack.Item>
        <Stack.Item>
          <Text>{t('draftOrders.empty.description.line2')}</Text>
        </Stack.Item>
        <Stack.Item>
          <LinkButton
            to="/draft-orders/new"
            text={t('draftOrders.empty.action')}
            icon={PlusCircleIcon}
            appearance="primary"
            iconPosition="start"
          />
          <Box marginTop="6">
            <SectionAppCard
              source={DRAFT_ORDERS_ROUTES.draftOrderList}
              appCategory="accounting"
              domain={SectionAppCardDomains.DRAFT_ORDERS}
            />
          </Box>
        </Stack.Item>
      </Stack>
    </EmptyState>
  );
}

export default DraftOrdersListEmpty;
