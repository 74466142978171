import { Badge, Box, Chip, Text } from '@nimbus-ds/components';
import { SearchFilterInterfaceIdLabel } from 'commons/types';
import { ClearFiltersButton } from './ClearFiltersButton';
import useResponsive from '../Responsive/useResponsive';

interface SearchFilterAppliedFiltersProps {
  appliedFilters: SearchFilterInterfaceIdLabel[];
  resultCount?: string;
  rightElement?: React.ReactNode;
  desktopWrap?: 'wrap' | 'nowrap';
  isLoading?: boolean;
  showClearFiltersButton?: boolean;
  onDismiss?: (key: string) => any;
  onChange: (value: string) => void;
  onClearFilterButtonClick?: () => void;
}

export function SearchFilterAppliedFilters({
  appliedFilters,
  resultCount,
  isLoading = false,
  rightElement,
  desktopWrap = 'nowrap',
  showClearFiltersButton = false,
  onDismiss,
  onChange,
  onClearFilterButtonClick,
}: Readonly<SearchFilterAppliedFiltersProps>) {
  const { isMobile } = useResponsive();
  const handleClick = (id: string) => {
    onDismiss?.(id);
    onChange('');
  };

  const handleClearFilterButtonClick = () => {
    onClearFilterButtonClick?.();
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      flexWrap={isMobile ? 'wrap' : desktopWrap}
      gap="2"
    >
      <Box
        alignItems="center"
        display="flex"
        gap="2"
        flexWrap={isMobile ? 'wrap' : desktopWrap}
      >
        {!!resultCount && <Text>{resultCount}</Text>}
        {isLoading && <Text.Skeleton width="122px" />}
        {!isLoading &&
          appliedFilters?.map(({ id, label, removable, badgeCount }) => (
            <Chip
              key={id}
              id={id}
              text={label}
              removable={removable ?? true}
              onClick={() => handleClick(id)}
              icon={
                badgeCount !== undefined && (
                  <Badge appearance="neutral" count={badgeCount} />
                )
              }
            />
          ))}
        {!isLoading && showClearFiltersButton && appliedFilters.length > 1 && (
          <ClearFiltersButton onClick={handleClearFilterButtonClick} />
        )}
      </Box>
      <Box>{rightElement}</Box>
    </Box>
  );
}
