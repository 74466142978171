import { logEvent } from 'commons/utils/tracking';
import {
  CUSTOMER_FILTER,
  CUSTOMER_FILTER_MODAL_OPEN,
  CUSTOMER_ALERT_CLOSE,
  CUSTOMER_ALERT_PLANS,
  CUSTOMER_SAVE_METAFIELDS,
  CUSTOMER_TRY_EXPORT,
  CUSTOMER_LIST_PAGE_LOAD,
  CUSTOMER_NEW_BUTTON_CLICK,
  CUSTOMER_LIST_CLICK,
  CUSTOMER_FILTER_CUSTOM_FIELD_CHANGE,
  CUSTOMER_NEW_PAGE_LOAD,
  CUSTOMER_NEW_SAVE,
  CUSTOMER_NEW_CUSTOM_FIELD_CHANGE,
  CUSTOMER_EDIT_PAGE_LOAD,
  CUSTOMER_EDIT_SAVE,
  CUSTOMER_EDIT_CUSTOM_FIELD_CHANGE,
  CUSTOMER_DETAILS_PAGE_LOAD,
  CUSTOMER_EDIT_BUTTON_CLICK,
} from 'config/trackingEvents';

export const trackingCustomerListPageLoad = (
  page: number,
  error: boolean,
): void => {
  logEvent(CUSTOMER_LIST_PAGE_LOAD, { page: `${page}`, error: `${error}` });
};

export const trackingCustomerNewButtonClick = (): void => {
  logEvent(CUSTOMER_NEW_BUTTON_CLICK, {});
};

export const trackingCustomerListClick = (customerId: number): void => {
  logEvent(CUSTOMER_LIST_CLICK, { customer_id: `${customerId}` });
};

export const trackingCustomerFilterCustomFieldChange = (
  customFieldId,
  name,
  value,
): void => {
  logEvent(CUSTOMER_FILTER_CUSTOM_FIELD_CHANGE, {
    custom_field_id: customFieldId,
    name: name,
    value: value,
  });
};

export const trackingCustomerNewPageLoad = (error: boolean): void => {
  logEvent(CUSTOMER_NEW_PAGE_LOAD, { error: `${error}` });
};

export const trackingCustomerNewSave = (
  customerId: string,
  error: boolean,
): void => {
  logEvent(CUSTOMER_NEW_SAVE, { customer_id: customerId, error: `${error}` });
};

export const trackingCustomerNewCustomFieldChange = (
  customFieldId,
  name,
  value,
): void => {
  logEvent(CUSTOMER_NEW_CUSTOM_FIELD_CHANGE, {
    custom_field_id: customFieldId,
    name: name,
    value: value,
  });
};

export const trackingCustomerDetailsPageLoad = (
  customerId: string,
  error: boolean,
) => {
  logEvent(CUSTOMER_DETAILS_PAGE_LOAD, {
    customer_id: customerId,
    error: `${error}`,
  });
};

export const trackingCustomerEditButtonClick = (customerId: string) => {
  logEvent(CUSTOMER_EDIT_BUTTON_CLICK, { customer_id: customerId });
};

export const trackingCustomerEditPageLoad = (
  customerId: string,
  error: boolean,
  errorCustomFields: boolean,
): void => {
  logEvent(CUSTOMER_EDIT_PAGE_LOAD, {
    customer_id: customerId,
    error: `${error}`,
    error_custom_fields: `${errorCustomFields}`,
  });
};

export const trackingCustomerEditSave = (
  customerId: string,
  error: boolean,
): void => {
  logEvent(CUSTOMER_EDIT_SAVE, { customer_id: customerId, error: `${error}` });
};

export const trackingCustomerEditCustomFieldChange = (
  customFieldId,
  name,
  value,
): void => {
  logEvent(CUSTOMER_EDIT_CUSTOM_FIELD_CHANGE, {
    custom_field_id: customFieldId,
    name: name,
    value: value,
  });
};

export const trackingCustomerTryExport = (): void => {
  logEvent(CUSTOMER_TRY_EXPORT, {});
};

export const trackingCustomerFilterModalOpen = (): void => {
  logEvent(CUSTOMER_FILTER_MODAL_OPEN, {});
};

export const trackingCustomerFilter = (
  customersAmount: number,
  error: boolean,
): void => {
  logEvent(CUSTOMER_FILTER, {
    customers_amount: `${customersAmount}`,
    error: `${error}`,
  });
};

export const trackingCustomerSaveMetafields = (page: string): void => {
  logEvent(CUSTOMER_SAVE_METAFIELDS, { page });
};

export const trackingCustomerAlertPlans = (page: string): void => {
  logEvent(CUSTOMER_ALERT_PLANS, { page });
};

export const trackingCustomerAlertClose = (page: string): void => {
  logEvent(CUSTOMER_ALERT_CLOSE, { page });
};
