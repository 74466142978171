import { useTranslation } from 'react-i18next';
import {
  CustomersOrdersResponseDto,
  OrderResponseDto,
} from '@tiendanube/common';
import {
  Domain as DomainEnum,
  Status as StatusEnum,
} from '@tiendanube/common/src/enums';
import { Label, Stack, Text } from '@tiendanube/components';
import { useResponsive } from 'commons/components';
import { payment, order as orderStatus } from '../status';

interface ColumnPaymentStatusProps {
  order: OrderResponseDto | CustomersOrdersResponseDto;
}

function ColumnPaymentStatus({ order }: ColumnPaymentStatusProps): JSX.Element {
  const { t } = useTranslation([DomainEnum.ORDERS]);
  const { isDesktop } = useResponsive();

  const { icon, status, appearance } =
    order.status !== StatusEnum.OPEN
      ? orderStatus(order.status as StatusEnum)
      : payment(order.payment.status as StatusEnum);
  return (
    <Stack column spacing="tight" align="flex-start">
      <Label
        id={status}
        label={`${t(`status.${status}`)}`}
        icon={icon}
        appearance={appearance}
      />
      {isDesktop && !!order.payment.gateway && (
        <Text block size="caption" trimText trimLines={1}>
          {`${t(`gateway.${order.payment.gateway}`, order.payment.gateway)}`}
        </Text>
      )}
    </Stack>
  );
}

export default ColumnPaymentStatus;
