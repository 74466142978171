import {
  CreateUserRequestDto,
  EmailTypeDtoResponse,
  ResultPaginationResponseDto,
  RoleResponseDto,
  RolesV2ResponseDto,
  UpdateUserRequestDto,
  UserResponseDto,
  UsersListItemResponseDto,
} from '@tiendanube/common';
import { MOBILE_BREAKPOINT } from 'App/constants/breakpoints';
import { cirrusApi, infinityBehavior, tableBehavior } from 'App/rtk';
import { GenericParamsType } from 'commons/types';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import { UserRolesType, UsersEndpoints, UsersTags } from './types';
import { BASE_USERS, EMAIL_TYPES, ROLES_V2, USER_BY_ID } from './urls';

export const usersApi = cirrusApi
  .enhanceEndpoints({
    addTagTypes: [UsersTags.usersList],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      [UsersEndpoints.getUsersList]: builder.query<
        ResultPaginationResponseDto<UsersListItemResponseDto[]>,
        GenericParamsType
      >({
        query: (params) => ({
          method: 'GET',
          url: BASE_USERS,
          params,
        }),
        ...tableBehavior(UsersEndpoints.getUsersList),
        providesTags: [UsersTags.usersList],
      }),
      [UsersEndpoints.getUsersListInf]: builder.query<
        ResultPaginationResponseDto<UsersListItemResponseDto[]>,
        GenericParamsType
      >({
        query: (params) => ({
          method: 'GET',
          url: BASE_USERS,
          params,
        }),
        ...infinityBehavior(UsersEndpoints.getUsersListInf),
        providesTags: [UsersTags.usersList],
      }),
      [UsersEndpoints.getUser]: builder.query<UpdateUserRequestDto, string>({
        query: (id) => ({
          method: 'GET',
          url: getUrlWithParams(USER_BY_ID, { id }),
        }),
        transformResponse: (user: UserResponseDto): UpdateUserRequestDto => ({
          ...user,
          roles: user.rolesWithoutFilters,
          emailTypes: user.emailTypes,
          currentPassword: '',
          password: '',
          confirmPassword: '',
        }),
      }),
      [UsersEndpoints.getEmailTypes]: builder.query<EmailTypeDtoResponse, void>(
        {
          query: () => ({
            method: 'GET',
            url: EMAIL_TYPES,
          }),
        },
      ),
      [UsersEndpoints.getRoles]: builder.query<UserRolesType, void>({
        query: () => ({
          method: 'GET',
          url: ROLES_V2,
        }),
        transformResponse: (response: RolesV2ResponseDto) => {
          const keys = Object.keys(response).reduce(
            (acc, section) => {
              if (section === 'externalApps') {
                acc.appRoles = response[section].reduce((acc, role) => {
                  acc[role.id] = role.shortName;
                  return acc;
                }, {});
              } else {
                const roles = response[section]
                  .flatMap((role: RoleResponseDto) => {
                    const roleEntries = [[role.id, role.shortName]];
                    if (role.children && role.children.length > 0) {
                      roleEntries.push(
                        ...role.children.map((child) => [child.id, child.name]),
                      );
                    }
                    return roleEntries;
                  })
                  .reduce((acc: Record<string, string>, [id, name]) => {
                    acc[id] = name;
                    return acc;
                  }, {});

                acc.roles = { ...acc.roles, ...roles };
              }

              return acc;
            },
            { roles: {}, appRoles: {} },
          );

          return {
            sections: response,
            roles: keys.roles,
            appRoles: keys.appRoles,
          };
        },
      }),
      [UsersEndpoints.createUser]: builder.mutation<void, CreateUserRequestDto>(
        {
          query: (payload) => ({
            method: 'POST',
            url: BASE_USERS,
            data: payload,
          }),
        },
      ),
      [UsersEndpoints.updateUser]: builder.mutation<
        void,
        { id: string; payload: UpdateUserRequestDto }
      >({
        query: ({ id, payload }) => ({
          method: 'PUT',
          url: getUrlWithParams(USER_BY_ID, { id }),
          data: payload,
        }),
      }),
      [UsersEndpoints.deleteUser]: builder.mutation<void, string>({
        query: (id) => ({
          method: 'DELETE',
          url: getUrlWithParams(USER_BY_ID, { id }),
        }),
        onQueryStarted(id, { dispatch, queryFulfilled }) {
          const endpoint =
            window.innerWidth > MOBILE_BREAKPOINT
              ? UsersEndpoints.getUsersList
              : UsersEndpoints.getUsersListInf;

          queryFulfilled.then(() => {
            dispatch(
              cirrusApi.util.updateQueryData(
                endpoint as never,
                undefined as never,
                (
                  draft: ResultPaginationResponseDto<
                    UsersListItemResponseDto[]
                  >,
                ) => {
                  draft.results = draft.results.filter(
                    ({ id: currentId }) => currentId !== id,
                  );
                },
              ),
            );
          });
        },
      }),
    }),
  });

export const {
  useGetUserQuery,
  useGetRolesQuery,
  useGetEmailTypesQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = usersApi;
