import { PaymentProviderDto } from '@tiendanube/common';
import {
  PaymentProvidersCode,
  muvemPagoOrPagoNubeIds,
} from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { useNavegate } from 'App/hooks';
import goToAdmin from 'commons/utils/gotToAdmin';
import { openWindow } from 'commons/utils/window';
import { PAYMENTS_ROUTES } from 'domains/Payments/paymentsRoutes';
import { invokeEventPayments } from 'domains/Payments/tracking';
import { OnPaymentProviderActionType } from '../../pages/PaymentProvidersListPage/components/PaymentProviderActionModal';
import useGetPaymentProviderSettingsRoute from '../useGetPaymentProviderSettingsRoute';

interface UseProvidersActionProps {
  paymentProvider: PaymentProviderDto;
  onAction: OnPaymentProviderActionType;
}

export function useProvidersAction({
  paymentProvider: {
    id,
    officialSiteUrl,
    preferencesUrl,
    supportUrl,
    mustCompleteInstallation,
    wasInstalled,
  },
  onAction,
}: Readonly<UseProvidersActionProps>) {
  const { goToSettingsPage } = useGetPaymentProviderSettingsRoute(id);
  const { goTo } = useNavegate();

  const handleCancelInstallation = () => {
    onAction(id, 'uninstall');
  };

  const handleGoToMainPage = () => {
    invokeEventPayments('menuAccount', id);
    if (id === PaymentProvidersCode.pagoNube) {
      goTo('/nuvempago');
    } else if (id === PaymentProvidersCode.nuvemPago) {
      goToAdmin('/nuvempago/dashboard')();
    } else if (officialSiteUrl) {
      openWindow(officialSiteUrl, true);
    }
  };

  const handleEnable = () => {
    invokeEventPayments('install', id);
    if (
      muvemPagoOrPagoNubeIds.includes(id) &&
      !mustCompleteInstallation &&
      !wasInstalled
    ) {
      return goTo(PAYMENTS_ROUTES.nuvempagoInitSettings);
    }
    enableAction();
  };

  const handleDisable = () => {
    invokeEventPayments('menuDisabled', id);
    onAction(id, 'disable');
  };

  const handleGoToSettingPage = () => {
    invokeEventPayments('menuSettings', id);
    goToSettingsPage();
  };

  const handleGoToCompleteInstallation = () => {
    openWindow(preferencesUrl, true);
  };

  const handleGoToSupport = () => {
    if (supportUrl) openWindow(supportUrl, true);
  };

  const enableAction = () => {
    invokeEventPayments('menuEnable', id);
    onAction(id, 'enable');
  };

  return {
    handleGoToMainPage,
    handleGoToSettingPage,
    handleEnable,
    handleGoToCompleteInstallation,
    handleCancelInstallation,
    handleDisable,
    handleGoToSupport,
  };
}
