import { Label, Link } from '@tiendanube/components';
import { EyeOffIcon } from '@tiendanube/icons';
import { ImageItemStratus, Stack } from 'commons/components';
import { Variant } from 'domains/Catalog/Products/pages/components/Variants/types';
import { useShowNoWeightAndDimension } from 'domains/Catalog/Products/pages/hooks';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { VariantListItemInterface } from '../../../../../CardVariants';
import VariantMissingDataTag from '../../../VariantMissingDataTag';

export interface VisibleVariantInfoDesktopProps {
  variant: VariantListItemInterface;
  index: number;
  isDigital: boolean;
  onClickImage: (index: number) => void;
  onClickVariant: (index: number) => void;
  onChange: (i: number, v: Partial<Variant>) => void;
}

function VisibleVariantInfoDesktop({
  variant: {
    id: variantId,
    weight,
    depth,
    width,
    height,
    name,
    imageUrl,
    visible,
  },
  index,
  isDigital,
  onClickImage,
  onClickVariant,
  onChange,
}: VisibleVariantInfoDesktopProps) {
  const t = useTranslationCatalog();
  const showNoWeightAndDimension = useShowNoWeightAndDimension({
    variantId,
    weight,
    depth,
    width,
    height,
  });
  const handleShowVariant = () => onChange(index, { visible: true });
  return (
    <ImageItemStratus
      title={name}
      id={variantId}
      imageUrl={imageUrl}
      onClickImage={() => onClickImage(index)}
      onClickTitle={() => onClickVariant(index)}
    >
      {!visible && (
        <Stack column align="flex-start" spacing="tight">
          <Label
            id={variantId}
            label={`${t('products.productHiddenAndWithoutStock.hidden')}`}
            appearance="warning"
            icon={EyeOffIcon}
          />
          <Link appearance="primary" onClick={handleShowVariant}>
            {t('products.detail.showVariant')}
          </Link>
        </Stack>
      )}
      {visible && showNoWeightAndDimension && !isDigital && (
        <VariantMissingDataTag />
      )}
    </ImageItemStratus>
  );
}

export default VisibleVariantInfoDesktop;
