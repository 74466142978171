import { Text } from '@nimbus-ds/components';
import { CustomShippingRangeDetailsDto } from '@tiendanube/common';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface ShippingCustomRangeInfoProps {
  range: CustomShippingRangeDetailsDto;
}

function ShippingCustomRangeInfo({
  range,
}: Readonly<ShippingCustomRangeInfoProps>) {
  const t = useTranslationShipping(
    'deliveryMethods.customShipping.rangeOptionsCard.byRanges.list',
  );

  return (
    <Text>
      {Number(range.initialRange) === 0 &&
        t('descriptions.justFinalRange', {
          finalRange: range.finalRange,
        })}

      {Number(range.initialRange) > 0 &&
        t('descriptions.ranged', {
          initialRange: range.initialRange,
          finalRange: range.finalRange,
        })}

      {`: `}

      {Number(range.cost) === 0 && t('descriptions.free')}

      {Number(range.cost) > 0 &&
        t('descriptions.cost', {
          cost: range.cost,
        })}

      {` | `}
      {Number(range.deliveryTimeMin) === 0 &&
        Number(range.deliveryTimeMax) === 0 &&
        t('descriptions.sameDay')}

      {Number(range.deliveryTimeMin) === Number(range.deliveryTimeMax) &&
        Number(range.deliveryTimeMin) > 0 &&
        t('descriptions.sameDeliveryTime', {
          deliveryTimeMin: range.deliveryTimeMin,
          day:
            Number(range.deliveryTimeMin) === 1
              ? t('descriptions.day')
              : t('descriptions.days'),
        })}

      {Number(range.deliveryTimeMin) === 0 &&
        Number(range.deliveryTimeMax) > 0 &&
        t('descriptions.justFinalDeliveryTime', {
          deliveryTimeMax: range.deliveryTimeMax,
          day:
            Number(range.deliveryTimeMax) === 1
              ? t('descriptions.day')
              : t('descriptions.days'),
        })}

      {Number(range.deliveryTimeMin) > 0 &&
        Number(range.deliveryTimeMin) !== Number(range.deliveryTimeMax) &&
        t('descriptions.deliveryTime', {
          deliveryTimeMin: range.deliveryTimeMin,
          deliveryTimeMax: range.deliveryTimeMax,
        })}
    </Text>
  );
}

export default ShippingCustomRangeInfo;
