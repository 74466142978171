import { ConceptCode } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { CHECKOUT_ROUTES } from '../checkoutRoutes';
import { VIEW_DETAILS_ORIGIN } from '../tracking';

export function useGoToConceptDetails(
  origin: VIEW_DETAILS_ORIGIN,
  conceptCode: ConceptCode,
  externalReference?: string,
  chargeId?: string,
) {
  const { goTo } = useNavegate();

  return () => {
    const externalReferenceQuery =
      (conceptCode === 'app-cost' || conceptCode === 'app-extra-fee') &&
      externalReference
        ? `&externalReference=${externalReference}`
        : '';
    const chargeIdQuery = chargeId ? `&chargeId=${chargeId}` : '';
    goTo(
      `${CHECKOUT_ROUTES.conceptDetails}?concept=${conceptCode}${externalReferenceQuery}${chargeIdQuery}&origin=${origin}`,
    );
  };
}
