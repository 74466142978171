import { Box, Icon, Tag, Text } from '@nimbus-ds/components';
import { TFunction } from 'i18next';
import { CheckoutPaymentOptionDto } from '@tiendanube/common/src/domains/checkout/CheckoutPaymentsResponseDto';
import { toCamelCase } from 'commons/utils';
import { PAYMENT_METHOD_CHECKOUT_TYPE_ICONS } from 'domains/Payments/PaymentProviders/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import { TRANSLATE_PREFIX } from '../CheckoutPaymentOptionsSorterModal';

interface SortCheckoutPaymentListItemProps {
  item: CheckoutPaymentOptionDto;
  opacity?: number;
}

function SortCheckoutPaymentListItem({
  item,
  opacity = 1,
}: SortCheckoutPaymentListItemProps) {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  const isRedirectOrModal =
    item.integrationType === 'redirect' || item.integrationType === 'modal';

  const MethodIcon =
    PAYMENT_METHOD_CHECKOUT_TYPE_ICONS[
      toCamelCase(isRedirectOrModal ? 'redirect' : item.method || 'other')
    ];

  const getDisplayName = (item: CheckoutPaymentOptionDto, t: TFunction) => {
    if (isRedirectOrModal || item.id.includes('custom')) {
      return item.name;
    }
    return t(`methods.${item.method}`, { name: item.name });
  };

  const hasDiscount = item.discount > 0;

  return (
    <div style={{ opacity }}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" gap="2">
          <Icon color="neutral-textHigh" source={<MethodIcon size={16} />} />
          <Text lineClamp={1}>{getDisplayName(item, t)}</Text>
        </Box>
        {hasDiscount && (
          <Tag appearance="neutral">
            {t('discount', { discount: item.discount })}
          </Tag>
        )}
        {!hasDiscount && item.maxInstallmentsWithoutInterest > 1 && (
          <Tag appearance="neutral">
            {t('maxInstallmentsWithoutInterest', {
              maxInstallmentsWithoutInterest:
                item.maxInstallmentsWithoutInterest,
            })}
          </Tag>
        )}
      </Box>
    </div>
  );
}

export default SortCheckoutPaymentListItem;
