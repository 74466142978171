import { Box } from '@nimbus-ds/components';
import HelpLink from 'App/HelpLink';
import {
  AppItemCard,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { HELP_LINKS_APPS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useAppStoreTracker } from 'domains/PartnersApps/hooks';
import useTranslationSalesChannels from 'domains/SalesChannels/useTranslationSalesChannels';
import {
  SalesChannelsAppsPageError,
  SalesChannelsAppsPageSkeleton,
} from './components';
import useSalesChannelsApps from './useSalesChannelsApps';

function SalesChannelsAppsPage() {
  const t = useTranslationSalesChannels('salesChannelsApps');
  const language = useTranslationLanguage();
  const {
    isLoading,
    isSuccess,
    isError,
    salesChannelsApps,
    getSalesChannelsApps,
  } = useSalesChannelsApps();
  const { clickToAppStoreAppDetails } = useAppStoreTracker();

  const handleClick = (name: string, index: number) => {
    clickToAppStoreAppDetails('admin/v2/sales-channels/apps', {
      name: name,
      category: 'sales channels',
      position: index + 1,
    });
  };

  return (
    <IonPageStratus
      width="medium"
      headerTop={<HeaderTop />}
      headerContent={
        <HeaderContent title={t('title')} subtitle={t('subtitle')} />
      }
    >
      {isLoading && <SalesChannelsAppsPageSkeleton />}
      {isError && (
        <SalesChannelsAppsPageError
          getSalesChannelsApps={getSalesChannelsApps}
        />
      )}
      {isSuccess && salesChannelsApps.length > 0 && (
        <Box
          display="grid"
          gridTemplateColumns={{
            lg: '1fr 1fr 1fr',
            md: '1fr',
            xs: '1fr',
          }}
          gridGap="4"
        >
          {salesChannelsApps.map((salesChannelsApp, index) => (
            <AppItemCard
              key={salesChannelsApp.name}
              app={salesChannelsApp}
              onClick={() => handleClick(salesChannelsApp.name, index)}
            />
          ))}
        </Box>
      )}
      <HelpLink
        title={t('helpLink.title')}
        previousValue=""
        currentViewTracking={t('helpLink.amplitudeName')}
        linkURL={HELP_LINKS_APPS[language]}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default SalesChannelsAppsPage;
