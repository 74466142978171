import { Table, Text } from '@nimbus-ds/components';
import { EditIcon, TrashIcon } from '@nimbus-ds/icons';
import { CustomShippingRangeDetailsDto } from '@tiendanube/common';
import { ActionIconButton, Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface CustomShippingRangeListProps {
  ranges: CustomShippingRangeDetailsDto[];
  handleEdit: (range: CustomShippingRangeDetailsDto) => void;
  handleDelete: (range: CustomShippingRangeDetailsDto) => void;
}

function CustomShippingRangeList({
  ranges,
  handleEdit,
  handleDelete,
}: Readonly<CustomShippingRangeListProps>): JSX.Element {
  const t = useTranslationShipping(
    'deliveryMethods.customShipping.rangeOptionsCard.byRanges.list',
  );

  const sortedRanges = [...ranges].sort(
    (a, b) => Number(a.finalRange) - Number(b.finalRange),
  );

  const firstRange = sortedRanges.at(0);
  const lastRange = sortedRanges.at(-1);

  return (
    <Table>
      <Table.Head>
        <Table.Cell backgroundColor="neutral-surface">{t('range')}</Table.Cell>
        <Table.Cell backgroundColor="neutral-surface">{t('cost')}</Table.Cell>
        <Table.Cell backgroundColor="neutral-surface">
          {t('deliveryTime')}
        </Table.Cell>
        <Table.Cell backgroundColor="neutral-surface">{t('action')}</Table.Cell>
      </Table.Head>
      <Table.Body>
        {sortedRanges.map((range) => (
          <Table.Row key={range.id}>
            <Table.Cell padding="base">
              <Text color="neutral-textLow">
                {Number(range.initialRange) === 0 &&
                  t('descriptions.justFinalRange', {
                    finalRange: range.finalRange,
                  })}

                {Number(range.initialRange) > 0 &&
                  t('descriptions.ranged', {
                    initialRange: range.initialRange,
                    finalRange: range.finalRange,
                  })}
              </Text>
            </Table.Cell>
            <Table.Cell padding="base">
              <Text color="neutral-textLow">
                {Number(range.cost) === 0 && t('descriptions.free')}

                {Number(range.cost) > 0 &&
                  t('descriptions.cost', {
                    cost: range.cost,
                  })}
              </Text>
            </Table.Cell>
            <Table.Cell padding="base">
              <Text color="neutral-textLow">
                {Number(range.deliveryTimeMin) === 0 &&
                  Number(range.deliveryTimeMax) === 0 &&
                  t('descriptions.sameDay')}

                {Number(range.deliveryTimeMin) ===
                  Number(range.deliveryTimeMax) &&
                  Number(range.deliveryTimeMin) > 0 &&
                  t('descriptions.sameDeliveryTime', {
                    deliveryTimeMin: range.deliveryTimeMin,
                    day:
                      Number(range.deliveryTimeMin) === 1
                        ? t('descriptions.day')
                        : t('descriptions.days'),
                  })}

                {Number(range.deliveryTimeMin) === 0 &&
                  Number(range.deliveryTimeMax) > 0 &&
                  t('descriptions.justFinalDeliveryTime', {
                    deliveryTimeMax: range.deliveryTimeMax,
                    day:
                      Number(range.deliveryTimeMax) === 1
                        ? t('descriptions.day')
                        : t('descriptions.days'),
                  })}

                {Number(range.deliveryTimeMin) > 0 &&
                  Number(range.deliveryTimeMin) !==
                    Number(range.deliveryTimeMax) &&
                  t('descriptions.deliveryTime', {
                    deliveryTimeMin: range.deliveryTimeMin,
                    deliveryTimeMax: range.deliveryTimeMax,
                  })}
              </Text>
            </Table.Cell>
            <Table.Cell padding="small">
              <Stack spacing="tight" justify="flex-start">
                <ActionIconButton
                  source={<EditIcon />}
                  content={t('edit')}
                  onClick={() => handleEdit(range)}
                />
                {range.finalRange === lastRange?.finalRange &&
                  range.initialRange !== firstRange?.initialRange && (
                    <ActionIconButton
                      source={<TrashIcon />}
                      content={t('delete')}
                      onClick={() => handleDelete(range)}
                    />
                  )}
              </Stack>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

export default CustomShippingRangeList;
