import { Switch } from 'react-router-dom';
import { NUVEM_CHAT_MENU_ENABLED } from 'App/featuresFlags';
import { useGetTags } from 'domains/Auth/hooks';
import NuvemAppPage from 'domains/PartnersApps/pages/NuvemAppPage';
import { useNuvemApps } from 'domains/PartnersApps/pages/NuvemAppPage/hooks';
import { NuvemChatPage } from './pages/NuvemChatPage';

function NuvemChat() {
  const { nuvemApps } = useNuvemApps();
  const tags = useGetTags();
  const hasNuvemChatEnabled = tags.includes(NUVEM_CHAT_MENU_ENABLED);

  return (
    <Switch>
      {nuvemApps.nuvemchat && <NuvemAppPage code="nuvemchat" />}
      {hasNuvemChatEnabled && !nuvemApps.nuvemchat && <NuvemChatPage />}
    </Switch>
  );
}

export default NuvemChat;
