import { useCallback } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import isEqual from 'lodash.isequal';
import { useSelector } from 'react-redux';
import { OrdersExportResponseDto } from '@tiendanube/common';
import Status from '@tiendanube/common/src/enums/Status';
import { useAppDispatch } from 'App/store';
import { useListFilters } from 'commons/hooks';
import {
  dateFormat,
  Format,
  getTodayIsoString,
  ninetyDaysAgo,
  subtractDays,
} from 'commons/utils/date';
import { useIsSavedSearchesEnabled } from 'domains/Orders/Orders/hooks/useIsSavedSearchesEnabled';
import {
  ExportFiltersType,
  FilterStatusEnum,
  FiltersType,
  fulfillmentStatusFilter,
  paymentStatusFilter,
} from 'domains/Orders/Orders/ordersService';
import {
  fetchTotalOrdersExport,
  exportOrders as exportOrdersAction,
} from 'domains/Orders/Orders/ordersSlice';
import {
  getOrdersExportStatus,
  getOrdersExportTotal,
} from 'domains/Orders/Orders/ordersSlice/ordersSelectors';
import { validateDateLimit } from 'domains/Orders/Orders/utils/OrderListFilter';
import { transformReadyToArchiveFilter } from 'domains/Orders/Orders/utils/OrderListFilter/utils';

interface UseOrdersExportResult {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  filters: ExportFiltersType;
  totalResults: number;
  getTotalOrders: (newFilters: ExportFiltersType) => Promise<void>;
  refreshTotalOrders: () => Promise<void>;
  removeFilter: (id: string) => Promise<void>;
  exportOrders: () => Promise<OrdersExportResponseDto>;
  clearFilters: () => Promise<void>;
}

export const defaultFilters: ExportFiltersType = {
  q: '',
  dateFrom: subtractDays(getTodayIsoString(), 30),
  dateTo: getTodayIsoString(),
  status: FilterStatusEnum.EMPTY,
  paymentStatus: FilterStatusEnum.EMPTY,
  fulfillmentStatus: FilterStatusEnum.EMPTY,
  paymentMethods: '',
  paymentProvider: '',
  shippingMethod: '',
  origin: '',
  appId: '',
  products: '',
  productsCount: '',
};

function useOrdersExport(): UseOrdersExportResult {
  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess } = useSelector(getOrdersExportStatus);
  const { filters, changeFilters } = useListFilters(
    'orders/export',
    defaultFilters,
  );
  const totalResults = useSelector(getOrdersExportTotal);
  const isSavedSearchesEnabled = useIsSavedSearchesEnabled();

  const getTotalOrders = useCallback(
    async (newFilters: ExportFiltersType) => {
      let filtersToApply = newFilters;
      if (newFilters.status === Status.CLOSED && isSavedSearchesEnabled) {
        filtersToApply = {
          ...newFilters,
          ...transformReadyToArchiveFilter(newFilters),
        } as FiltersType;
      }
      if (!isEqual(filters, filtersToApply)) {
        changeFilters(filtersToApply);
        await dispatch(fetchTotalOrdersExport(newFilters));
      }
    },
    [changeFilters, dispatch, filters, isSavedSearchesEnabled],
  );

  const refreshTotalOrders = useCallback(async () => {
    await dispatch(fetchTotalOrdersExport(validateDateLimit(filters)));
  }, [dispatch, filters]);

  const removeFilter = useCallback(
    async (id: string) => {
      const hasFiltersWithDateRestrictionsApplied =
        filters.paymentMethods !== '' ||
        filters.paymentProvider !== '' ||
        filters.appId !== '';

      const dateAndPaymentsFilter =
        hasFiltersWithDateRestrictionsApplied && id === 'dateFrom';
      const dateAfterNinetyDays = filters.dateFrom > ninetyDaysAgo;

      if (dateAndPaymentsFilter && !dateAfterNinetyDays) return;

      const hasStatusesFilters =
        id.startsWith(`${paymentStatusFilter}-`) ||
        id.startsWith(`${fulfillmentStatusFilter}-`);

      const hasProductsFilter = id.startsWith('variant-');

      let newId = hasStatusesFilters ? id.split('-')[0] : id;
      newId = hasProductsFilter ? 'products' : newId;

      let newValue =
        dateAndPaymentsFilter && dateAfterNinetyDays ? ninetyDaysAgo : '';

      newValue = hasStatusesFilters
        ? filters[newId]
            .split(',')
            .filter((status) => status !== id.split('-')[1])
            .join(',')
        : newValue;

      newValue = hasProductsFilter
        ? filters.products
            .split(';')
            .filter((variantId) => variantId !== id.split('-')[1])
            .join(';')
        : newValue;

      const newFilters = { ...filters, [newId]: newValue };
      changeFilters(newFilters);
      await getTotalOrders(newFilters);
    },
    [changeFilters, filters, getTotalOrders],
  );

  const clearFilters = useCallback(async () => {
    const defaultFiltersWithoutDates = {
      ...defaultFilters,
      dateTo: '',
      dateFrom: '',
    };
    changeFilters(defaultFiltersWithoutDates);
    await getTotalOrders(defaultFiltersWithoutDates);
  }, [changeFilters, getTotalOrders]);

  const exportOrders = useCallback(async () => {
    const dateFrom = filters.dateFrom;
    const dateTo = filters.dateTo;
    const formattedDateFrom =
      dateFrom === '' ? dateFrom : dateFormat(dateFrom, Format.DD_MM_YYYY);
    const formattedDateTo =
      dateTo === '' ? dateTo : dateFormat(dateTo, Format.DD_MM_YYYY);
    const result = await dispatch(
      exportOrdersAction({
        ...filters,
        dateFrom: formattedDateFrom,
        dateTo: formattedDateTo,
      }),
    );
    return unwrapResult(result);
  }, [dispatch, filters]);

  return {
    isLoading,
    isSuccess,
    isError,
    filters,
    totalResults,
    refreshTotalOrders,
    getTotalOrders,
    removeFilter,
    exportOrders,
    clearFilters,
  };
}

export default useOrdersExport;
