import { useEffect } from 'react';
import { getIsPageWithCodeParam } from 'App/components/PrivateRoute/utils';
import { useAppDispatch } from 'App/store';
import { getCurrentAuth } from 'domains/Auth/authSlice';

function getParamsFromQuery() {
  // If we need to get the 'code' param from the query
  if (getIsPageWithCodeParam(window.location.pathname)) {
    return { code: '' };
  }
  const searchParams = new URLSearchParams(window.location.search);
  return {
    code: searchParams.get('code') || '',
    sessionId: searchParams.get('sessionId') || '',
  };
}

function useGetInitialState(): void {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const { code, sessionId } = getParamsFromQuery();
    dispatch(getCurrentAuth({ code, sessionId }));
  }, [dispatch]);
}

export default useGetInitialState;
