import { SETTING_BASE_ROUTE } from 'App/constants/settingsRoute';

export const SHIPPING_METHODS_BASE_ROUTE = `${SETTING_BASE_ROUTE}/shipping-methods`;

export const DELIVERY_METHODS_ROUTES = {
  international: `${SHIPPING_METHODS_BASE_ROUTE}/international-shipping`,
  internationalDetail: `${SHIPPING_METHODS_BASE_ROUTE}/international-shipping/:id`,
  national: `${SHIPPING_METHODS_BASE_ROUTE}/custom-shipping`,
  nationalDetail: `${SHIPPING_METHODS_BASE_ROUTE}/custom-shipping/:id`,
  oca: `${SHIPPING_METHODS_BASE_ROUTE}/oca`,
  ocaEditSelector: `${SHIPPING_METHODS_BASE_ROUTE}/oca-edit-selector`,
  ocaEditOptionals: `${SHIPPING_METHODS_BASE_ROUTE}/oca-edit-optionals`,
  requestOcaRegistration: `${SHIPPING_METHODS_BASE_ROUTE}/request-oca-registration`,
  requestOcaRegistrationSuccess: `${SHIPPING_METHODS_BASE_ROUTE}/request-oca-registration/success`,
  physicalLocation: `${SHIPPING_METHODS_BASE_ROUTE}/physical-locations`,
  physicalLocationDetail: `${SHIPPING_METHODS_BASE_ROUTE}/physical-locations/:id`,
  correios: `${SHIPPING_METHODS_BASE_ROUTE}/correios`,
  correiosModalities: `${SHIPPING_METHODS_BASE_ROUTE}/correios/shipping-modalities`,
  correiosContract: `${SHIPPING_METHODS_BASE_ROUTE}/correios/contract`,
  carriers: `${SHIPPING_METHODS_BASE_ROUTE}/carriers`,
  carriersDetail: `${SHIPPING_METHODS_BASE_ROUTE}/carriers/:id`,
  newPickupPoint: `${SHIPPING_METHODS_BASE_ROUTE}/pickup-point`,
  editPickupPoint: `${SHIPPING_METHODS_BASE_ROUTE}/pickup-point/:id`,
  locationAsPickupPoint: `${SHIPPING_METHODS_BASE_ROUTE}/location-as-pickup-point/:location`,
  editLocationAsPickupPoint: `${SHIPPING_METHODS_BASE_ROUTE}/location-as-pickup-point/:id/:location`,
  SHIPPING_METHODS_BASE_ROUTE,
};
