import { useEffect, useState } from 'react';
import { Box, Tooltip, IconButton } from '@nimbus-ds/components';
import { NotificationIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { trackingMenuPrimaryAccountClick } from 'App/tracking';
import { BackNavigation } from 'commons/components';
import { openWindow } from 'commons/utils/window';
import { useHeaderMenuData, useIsLogged } from 'domains/Auth/hooks';
import { ProfileMenu } from './components';
import useTopBar from './useTopBar';
import ButtonBackPreviousVersion from '../Migrations/ButtonBackPreviousVersion';
import { SupportChanels } from '../SupportChanels';
import './Topbar.scss';

function TopBar(): JSX.Element {
  const { t } = useTranslation('common');
  const { headerNavigation, isShowMenu } = useTopBar();
  const { name } = useHeaderMenuData();
  const { isLogin } = useIsLogged();
  const [showPopover, setShowPopover] = useState(false);
  const location = useLocation();

  const handleAccountClick = () => {
    if (!showPopover) {
      trackingMenuPrimaryAccountClick();
    }
    return setShowPopover(!showPopover);
  };

  const handleClickNews = () => {
    openWindow(t('topbar.whatsNewLink'), true);
  };

  useEffect(() => {
    setShowPopover(false);
  }, [location]);

  const handleShowPopover = (status: boolean) => {
    setShowPopover(status);
  };

  return (
    <div className="stratus--top-bar">
      <Box display="flex" justifyContent="space-between">
        <Box>
          {headerNavigation && (
            <BackNavigation onClick={headerNavigation.onClick}>
              {headerNavigation.children}
            </BackNavigation>
          )}
        </Box>
        {isShowMenu && (
          <Box
            display="flex"
            justifyContent="flex-end"
            gap={{ xs: '1', md: '2' }}
          >
            <Tooltip content={t('topbar.whatsNew')} arrow={false}>
              <IconButton
                size="2.125rem"
                source={<NotificationIcon size={18} />}
                backgroundColor="transparent"
                borderColor={{
                  xs: 'transparent',
                  hover: 'neutral-interactiveHover',
                }}
                data-style="icon-button-override"
                onClick={handleClickNews}
              />
            </Tooltip>
            {isLogin && <SupportChanels />}
            <ProfileMenu
              name={name}
              showPopover={showPopover}
              onShowPopover={handleShowPopover}
              onAccountClick={handleAccountClick}
            />
            <ButtonBackPreviousVersion />
          </Box>
        )}
      </Box>
    </div>
  );
}

export default TopBar;
