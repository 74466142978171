import { DataTable } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

const { Header, Cell } = DataTable;

function ResultHeaderDesktop(): JSX.Element {
  const t = useTranslationBilling();

  return (
    <Header>
      <Cell basis={100}>
        <Text>{t('transactionFeesList.tableHeader.order')}</Text>
      </Cell>
      <Cell basis={200} grow={0}>
        <Text>{t('transactionFeesList.tableHeader.total')}</Text>
      </Cell>
      <Cell basis={200} grow={0}>
        <Text>{t('transactionFeesList.tableHeader.cost')}</Text>
      </Cell>
      <Cell basis={100}>
        <Text>{t('transactionFeesList.tableHeader.date')}</Text>
      </Cell>
    </Header>
  );
}

export default ResultHeaderDesktop;
