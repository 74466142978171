import { useMemo } from 'react';
import isEqual from 'lodash.isequal';
import { CheckoutPaymentsResponseDto } from '@tiendanube/common';

function useHasCheckoutPaymentOrderChanged(
  value?: CheckoutPaymentsResponseDto,
  other?: CheckoutPaymentsResponseDto,
): boolean {
  return useMemo(() => {
    if (value && other) {
      return isEqual(
        [other.sortType, other.custom, other.default],
        [value.sortType, value.custom, value.default],
      );
    }
    return false;
  }, [other, value]);
}

export default useHasCheckoutPaymentOrderChanged;
