import { useEffect, useState } from 'react';
import { DataTable } from '@nimbus-ds/patterns';
import {
  LocationPriorityRequestDto,
  LocationResponseDto,
} from '@tiendanube/common';
import { CATALOG_STOCK_TRANSFER } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import {
  CancelAndConfirmButtons,
  Responsive,
  SortableDataList,
} from 'commons/components';
import { useAsyncFunc, useModal } from 'commons/hooks';
import useLocationListToast from 'domains/Shipping/Locations/hooks/useLocationListToast';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import DeleteLocationModal from './components/DeleteLocationModal';
import TransferStockModal from './components/DeleteLocationModal/TransferStockModal';
import SetLocationMainModal from './components/SetLocationMainModal';
import LocationItem from '../LocationItem';
import LocationsListDesktop from '../LocationsListDesktop';
import LocationsListMobile from '../LocationsListMobile';
import NotAllowedMultipleLocationCard from '../NotAllowedMultipleLocationCard';

interface LocationsListProps {
  hasAllowedMultipleLocation: boolean;
  isEmpty: boolean;
  isLoading: boolean;
  isSorting: boolean;
  handleIsSorting: () => void;
}

function LocationsList({
  hasAllowedMultipleLocation,
  isEmpty,
  isLoading,
  isSorting,
  handleIsSorting,
}: Readonly<LocationsListProps>): JSX.Element {
  const {
    locations,
    updateLocationsOrdering,
    updateMainLocation,
    deleteLocation,
  } = useLocations();
  const { onLoading, onSuccess, onError } = useLocationListToast();
  const [locationsList, setLocationsList] = useState<LocationResponseDto[]>([]);
  const [isOpenSetMainModal, openSetMainModal, closeSetMainModal] = useModal();
  const [isOpenDeleteModal, openDeleteModal, closeDeleteModal] = useModal();
  const [selectedLocation, setSelectedLocation] = useState<string>();
  const [hasTransferStock] = useHasTags([CATALOG_STOCK_TRANSFER]);

  const [handleUpdateLocationsOrdering, isSavingOrdering] = useAsyncFunc(
    async () => {
      const newLocationsOrdering = locationsList.map(
        ({ id, priority }) =>
          ({
            locationId: id,
            priority: priority,
          } as LocationPriorityRequestDto),
      );
      await updateLocationsOrdering(newLocationsOrdering);
    },
    () => {
      handleIsSorting();
      onSuccess('sort');
    },
    () => onError('sort'),
  );

  const [handleSaveMainLocation, isSavingMainLocation] = useAsyncFunc(
    async () => {
      if (selectedLocation) await updateMainLocation(selectedLocation);
    },
    () => {
      closeSetMainModal();
      onSuccess('setAsMain');
    },
    () => onError('setAsMain'),
  );

  const [handleDeleteLocation, isDeletingLocation] = useAsyncFunc(
    async () => {
      if (selectedLocation) await deleteLocation(selectedLocation);
    },
    () => {
      closeDeleteModal();
      onSuccess('delete');
    },
    () => onError('delete'),
  );

  const handleOnChange = (newOrder) => {
    const newLocationsList = newOrder.map((location, index) => ({
      ...location,
      priority: index,
      main: index === 0,
    }));
    setLocationsList(newLocationsList);
  };

  const handleOpenSetMainModal = (locationId) => {
    setSelectedLocation(locationId);
    openSetMainModal();
  };

  const handleOpenDeleteModal = (locationId) => {
    setSelectedLocation(locationId);
    openDeleteModal();
  };

  const handleCloseSorting = () => {
    handleIsSorting();
    setLocationsList([]);
  };

  const getDeleteLocationModal = (locationId: string) => {
    if (hasTransferStock) {
      return (
        <TransferStockModal
          isLoadingLocations={isLoading}
          onClose={closeDeleteModal}
          locationsList={locationsList}
          locationId={locationId}
          onConfirm={handleDeleteLocation}
        />
      );
    }
    return (
      <DeleteLocationModal
        locationId={locationId}
        isLoadingLocations={isLoading}
        onClose={closeDeleteModal}
        onConfirm={handleDeleteLocation}
      />
    );
  };

  useEffect(() => {
    setLocationsList(locations);
  }, [locations]);

  useEffect(() => {
    if (isSavingMainLocation) onLoading('setAsMain');
    if (isSavingOrdering) onLoading('sort');
    if (isDeletingLocation) onLoading('delete');
  }, [isDeletingLocation, isSavingMainLocation, isSavingOrdering, onLoading]);

  return (
    <>
      {((locations.length > 0 && !isLoading) || isLoading) && !isSorting && (
        <Responsive
          mobile={
            <LocationsListMobile
              openSetMainModal={handleOpenSetMainModal}
              openDeleteModal={handleOpenDeleteModal}
            />
          }
          desktop={
            <LocationsListDesktop
              openSetMainModal={handleOpenSetMainModal}
              openDeleteModal={handleOpenDeleteModal}
            />
          }
        />
      )}

      {!hasAllowedMultipleLocation && !isLoading && !isEmpty && !isSorting && (
        <NotAllowedMultipleLocationCard />
      )}

      {locationsList.length > 0 && isSorting && (
        <>
          <DataTable header>
            <SortableDataList<LocationResponseDto>
              items={locationsList}
              onChange={handleOnChange}
              renderItem={(location) => (
                <LocationItem location={location} isDragging />
              )}
            />
          </DataTable>
          <CancelAndConfirmButtons
            isConfirmDisabled={locationsList === locations}
            onCancel={handleCloseSorting}
            onConfirm={handleUpdateLocationsOrdering}
          />
        </>
      )}
      {!!selectedLocation && isOpenSetMainModal && (
        <SetLocationMainModal
          locationId={selectedLocation}
          isLoading={isLoading}
          onClose={closeSetMainModal}
          onConfirm={handleSaveMainLocation}
        />
      )}
      {!!selectedLocation &&
        isOpenDeleteModal &&
        getDeleteLocationModal(selectedLocation)}
    </>
  );
}

export default LocationsList;
