import { useMemo } from 'react';
import { ShippingAppResponseDto } from '@tiendanube/common';
import NuvemEnvioJadlogModal from '../DeliveryMethodsPage/components/NuvemEnvioJadlogModal';
import NuvemEnvioLoggiModal from '../DeliveryMethodsPage/components/NuvemEnvioLoggiModal';

interface NuvemEnvioModalsProps {
  detailActiveApp: ShippingAppResponseDto;
}

function NuvemEnvioModals({ detailActiveApp }: NuvemEnvioModalsProps) {
  const NeJadlogComExists =
    detailActiveApp?.options?.['ne-jadlog-com']?.isEnabled;
  const NeJadlogPackageExists =
    detailActiveApp?.options?.['ne-jadlog-package']?.isEnabled;
  const NeLoggiExpressExists =
    detailActiveApp?.options?.['ne-loggi-express']?.isEnabled;
  const NeLoggiEconomicExists =
    detailActiveApp?.options?.['ne-loggi-economic']?.isEnabled;

  const existsJadlog = useMemo(
    () => NeJadlogComExists || NeJadlogPackageExists,
    [NeJadlogComExists, NeJadlogPackageExists],
  );

  const existsLoggi = useMemo(
    () => NeLoggiExpressExists || NeLoggiEconomicExists,
    [NeLoggiEconomicExists, NeLoggiExpressExists],
  );

  return (
    <>
      {existsJadlog && <NuvemEnvioJadlogModal />}
      {existsLoggi && <NuvemEnvioLoggiModal />}
    </>
  );
}

export default NuvemEnvioModals;
