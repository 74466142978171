import { logEvent } from 'commons/utils/tracking';
import {
  MENU_SECONDARY_DOMAINS_CLICK,
  MENU_SECONDARY_USERS_PERMISSIONS_CLICK,
  PAYMENT_PAGE_VIEW,
} from 'config/trackingEvents';

export const menuPermissionsClick = () => {
  logEvent(MENU_SECONDARY_USERS_PERMISSIONS_CLICK, {});
};

export const menuSecondaryDomainsClick = () => {
  logEvent(MENU_SECONDARY_DOMAINS_CLICK, {});
};

export const menuPaymentsClick = () => {
  logEvent(PAYMENT_PAGE_VIEW, {});
};
