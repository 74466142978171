import { PickupPointRequestDto } from '@tiendanube/common';
import { CostType } from '../components/SecondStepForm/components/PickupPointCost/PickupPointCost';

export const COUNTRIES_WITH_RANGE = new Set(['AR', 'BR', 'MX']);

export const initialValues: PickupPointRequestDto = {
  isLocation: false,
  name: '',
  country: '',
  zipcode: '',
  province: '',
  city: '',
  locality: '',
  street: '',
  number: '',
  floor: '',
  range: '10',
  locations: [],
  status: true,
  businessHours: [
    {
      day: 'monday',
      start: '09:00',
      end: '18:00',
    },
    {
      day: 'thursday',
      start: '09:00',
      end: '18:00',
    },
    {
      day: 'tuesday',
      start: '09:00',
      end: '18:00',
    },
    {
      day: 'wednesday',
      start: '09:00',
      end: '18:00',
    },
    {
      day: 'friday',
      start: '09:00',
      end: '18:00',
    },
  ],
  estimateDeliveryTime: {
    minDays: 0,
    maxDays: 0,
  },
  cost: {
    type: CostType.FIXED,
    value: '0',
  },
};
