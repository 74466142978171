import { BaseCard, Input } from '@tiendanube/components';
import { CancelAndSaveButtons } from 'commons/components';
import Stack from 'commons/components/Stack';

interface ChileanFiscalDataFormSkeletonProps {
  onCancel: () => void;
}

function ChileanFiscalDataFormSkeleton({
  onCancel,
}: ChileanFiscalDataFormSkeletonProps): JSX.Element {
  return (
    <>
      <BaseCard>
        <BaseCard.Body>
          <Stack column align="stretch">
            <Input.Skeleton />
            <Input.Skeleton />
            <Input.Skeleton />
          </Stack>
        </BaseCard.Body>
      </BaseCard>
      <CancelAndSaveButtons
        onCancel={onCancel}
        onSave={() => null}
        isDisabled
      />
    </>
  );
}

export default ChileanFiscalDataFormSkeleton;
