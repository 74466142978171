import { useEffect } from 'react';
import { Box } from '@nimbus-ds/components';
import { BoxPackedIcon, MoneyIcon, TruckIcon } from '@nimbus-ds/icons';
import { FEATURE_SEARCH_FILTER } from 'App/features';
import {
  MIGRATION_ENABLE_ALL_MIGRATED_PAGES,
  MIGRATION_ORDERS,
} from 'App/featuresFlags';
import { BlockWithTitle } from 'commons/components';
import {
  useGetTags,
  useIsMobileDevice,
  useStatusFeatureByKey,
} from 'domains/Auth/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { ordersRoutes } from 'domains/Orders';
import { DefaultSavedSearchesCode } from 'domains/Orders/components/SavedSearches/defaultSavedSearches';
import useSavedSearches from 'domains/Orders/components/SavedSearches/useSavedSearches';
import {
  useGetPendingOrders,
  useOrdersList,
} from 'domains/Orders/Orders/hooks';
import { useIsSavedSearchesEnabled } from 'domains/Orders/Orders/hooks/useIsSavedSearchesEnabled';
import PendingOrdersCard from './PendingOrdersCard';
import {
  ORDER_FILTERS,
  getEmptyPreffix,
  handleClick,
} from './pendingOrdersUtils';

export type PendingOrderType =
  | 'pendingPayment'
  | 'pendingShipping'
  | 'pendingPacked';

function PendingOrders() {
  const t = useTranslationDashboard('pendingOrders');
  const { pendingOrders, isLoading, isError } = useGetPendingOrders();
  const { fetchSavedSearchesCount } = useOrdersList();
  const { isAvailable: isFiltersAllowed } = useStatusFeatureByKey(
    FEATURE_SEARCH_FILTER,
  );
  const isMobileDevice = useIsMobileDevice();
  const tags = useGetTags();
  const {
    buildQueryParamsFromSavedSearch,
    savedSearchesCount,
    savedSearchesCountStatus,
  } = useSavedSearches();
  const isSavedSearchesEnabled = useIsSavedSearchesEnabled();

  useEffect(() => {
    fetchSavedSearchesCount();
  }, [fetchSavedSearchesCount]);

  if (isError || (isSavedSearchesEnabled && savedSearchesCountStatus.isError))
    return null;

  const hasOrdersAccess =
    tags.includes(MIGRATION_ORDERS) ||
    tags.includes(MIGRATION_ENABLE_ALL_MIGRATED_PAGES);
  const shouldGoToOldAdmin = !hasOrdersAccess && !isMobileDevice;

  const getFilterPath = (code: DefaultSavedSearchesCode) => {
    if (shouldGoToOldAdmin) {
      return `/admin${ORDER_FILTERS.old[code]}`;
    }
    if (!isSavedSearchesEnabled) {
      return `${ordersRoutes.orderList}${
        isFiltersAllowed ? ORDER_FILTERS.new[code] : ''
      }`;
    }
    return `${ordersRoutes.orderList}${
      isFiltersAllowed ? `?page=1&${buildQueryParamsFromSavedSearch(code)}` : ''
    }`;
  };

  const paymentPendingCount = isSavedSearchesEnabled
    ? savedSearchesCount?.[DefaultSavedSearchesCode.PAYMENT_PENDING]?.count
    : pendingOrders?.payment;
  const readyToPackCount = isSavedSearchesEnabled
    ? savedSearchesCount?.[DefaultSavedSearchesCode.READY_TO_PACK]?.count
    : pendingOrders?.unpacked;
  const readyToSendCount = isSavedSearchesEnabled
    ? savedSearchesCount?.[DefaultSavedSearchesCode.READY_TO_SEND]?.count
    : pendingOrders?.shipping;

  const emptyPayment = paymentPendingCount === 0;
  const emptyUnpacked = readyToPackCount === 0;
  const emptyShipping = readyToSendCount === 0;

  const isLoadingCounts =
    isLoading || (isSavedSearchesEnabled && savedSearchesCountStatus.isLoading);

  const getTitle = (isEmpty: boolean, count?: number) =>
    isEmpty ? '' : t('orders', { count });

  return (
    <BlockWithTitle title={t('title')}>
      <Box
        display="flex"
        flexDirection={{
          lg: 'row',
          md: 'column',
          xs: 'column',
        }}
        justifyContent="space-between"
        alignItems="flex-start"
        gap="4"
      >
        <PendingOrdersCard
          appearance="warning-surface"
          icon={<MoneyIcon size="medium" />}
          subtitle={t(`pendingPayment${getEmptyPreffix(emptyPayment)}`)}
          title={getTitle(
            emptyPayment,
            shouldGoToOldAdmin
              ? pendingOrders?.paymentOldAdminResult
              : paymentPendingCount,
          )}
          skeleton={isLoadingCounts}
          to={getFilterPath(DefaultSavedSearchesCode.PAYMENT_PENDING)}
          onClick={handleClick(
            DefaultSavedSearchesCode.PAYMENT_PENDING,
            isSavedSearchesEnabled,
          )}
          hideIcon={!isFiltersAllowed || emptyPayment}
          shouldGoToOldAdmin={shouldGoToOldAdmin}
        />
        <PendingOrdersCard
          icon={<BoxPackedIcon size="medium" />}
          subtitle={t(`pendingPacked${getEmptyPreffix(emptyUnpacked)}`)}
          title={getTitle(
            emptyUnpacked,
            shouldGoToOldAdmin
              ? pendingOrders?.unpackedOldAdminResult
              : readyToPackCount,
          )}
          skeleton={isLoadingCounts}
          to={getFilterPath(DefaultSavedSearchesCode.READY_TO_PACK)}
          onClick={handleClick(
            DefaultSavedSearchesCode.READY_TO_PACK,
            isSavedSearchesEnabled,
          )}
          hideIcon={!isFiltersAllowed || emptyUnpacked}
          shouldGoToOldAdmin={shouldGoToOldAdmin}
        />
        <PendingOrdersCard
          appearance="primary-surface"
          icon={<TruckIcon size="medium" />}
          subtitle={
            shouldGoToOldAdmin
              ? t(`oldPendingShipping${getEmptyPreffix(emptyShipping)}`)
              : t(`pendingShipping${getEmptyPreffix(emptyShipping)}`)
          }
          title={getTitle(
            emptyShipping,
            shouldGoToOldAdmin
              ? pendingOrders?.shippingOldAdminResult
              : readyToSendCount,
          )}
          skeleton={isLoadingCounts}
          to={getFilterPath(DefaultSavedSearchesCode.READY_TO_SEND)}
          onClick={handleClick(
            DefaultSavedSearchesCode.READY_TO_SEND,
            isSavedSearchesEnabled,
          )}
          hideIcon={!isFiltersAllowed || emptyShipping}
          shouldGoToOldAdmin={shouldGoToOldAdmin}
        />
      </Box>
    </BlockWithTitle>
  );
}

export default PendingOrders;
