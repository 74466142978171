import {
  ChangeEvent,
  FocusEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';
import isEqual from 'lodash.isequal';
import * as Yup from 'yup';
import { InterfaceNameValue } from '@tiendanube/components';
import { InterfaceNameBooleanValue } from 'commons/types';
import { UseFormProps, UseFormResult } from './types';
import useYupValidation from '../useYupValidation';

/*  
    This hook is used to validate the form.
    The handlers returned are used to update the form values and validate the form. Must be nimbus component handlers.
*/

/**
 * @param initialValues
 * @param validationSchema
 * @param validateOnBlur
 * @param onSubmit
 * We recommend avoiding the use of the spread operator on the initialState property so as not to generate the error
 * "Maximum call stack size exceeded causing infinite loop".
 * It is possible that, sometimes, we have to memoize the data received in this property
 */

function useForm<TValues = unknown, TErrors = Record<string, string>>({
  initialValues = {} as TValues,
  validationSchema = Yup.object(),
  onSubmit,
  validateOnBlur,
}: UseFormProps<TValues>): UseFormResult<TValues, TErrors> {
  const [isDirty, setIsDirty] = useState(false);
  const [values, setValues] = useState<TValues>(initialValues);
  const { errors, validate, validateField, isValidating, cleanErrors } =
    useYupValidation<TValues, TErrors>(validationSchema);

  /**
   * @deprecated This callback is deprecated. To use Nimbis 2 input, please use handleChange
   */
  const handleOnChange = ({
    value,
    name,
  }: InterfaceNameValue | InterfaceNameBooleanValue) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  /**
   * @deprecated This callback is deprecated. To use Nimbus 2 input, please use handleChangeGroup instead.
   */
  const handleOnChangeGroup = (
    group: string,
    data: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => {
    setFieldValue(group, { ...values[group], [data.name]: data.value });
  };

  const handleOnSubmit = (
    e?: React.SyntheticEvent<HTMLFormElement | HTMLButtonElement>,
  ) => {
    e?.preventDefault();
    validate(values, () => {
      setIsDirty(false);
      onSubmit(values);
    });
  };

  const handleValidations = async (onValid: () => void) =>
    validate(values, onValid);

  /**
   * @deprecated This callback is deprecated. To use Nimbis 2 input, please use handleBlur
   */
  const handleOnBlur = (data) => {
    const validationSchemaIncludesField = Object.keys(
      validationSchema.describe().fields,
    ).includes(data.name);
    const validateOnBlurIncludesField = validateOnBlur?.includes(data.name);

    if (validationSchemaIncludesField && validateOnBlurIncludesField) {
      validateField(data.name, values);
    }
  };

  const setFieldValue = useCallback((name: string, value: any) => {
    setValues((prev) => ({ ...prev, [name]: value }));
  }, []);

  const setMultipleFieldValues = useCallback((values: Record<string, any>) => {
    setValues((prev) => ({
      ...prev,
      ...values,
    }));
  }, []);

  const resetValues = useCallback(
    () => setValues(initialValues),
    [initialValues],
  );

  const handleChange = ({
    target: { name, value, type, checked },
  }: ChangeEvent<HTMLInputElement>) => {
    setValues((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleChangeTextArea = ({
    target: { name, value },
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeSelect = ({
    target: { name, value },
  }: ChangeEvent<HTMLSelectElement>) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeGroup = (
    group: string,
    { target: { name, value, type, checked } }: ChangeEvent<HTMLInputElement>,
  ) => {
    setFieldValue(group, {
      ...values[group],
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = ({
    target: { name, value },
  }) => {
    handleOnBlur({ name, value });
  };

  const handleOnChangeObj = (name: string, diffFn: (prev: TValues) => any) => {
    setValues((prev) => {
      const diff = diffFn(prev);
      return { ...prev, [name]: diff };
    });
  };

  useEffect(() => {
    setIsDirty(!isEqual(initialValues, values));
  }, [initialValues, values]);

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  return {
    values,
    errors,
    isDirty,
    isValidating,
    handleOnChange,
    handleOnChangeGroup,
    handleOnSubmit,
    handleValidations,
    handleOnBlur,
    setFieldValue,
    resetValues,
    setMultipleFieldValues,
    cleanErrors,
    handleChange,
    handleChangeSelect,
    handleChangeGroup,
    handleBlur,
    handleChangeTextArea,
    handleOnChangeObj,
  };
}

export default useForm;
