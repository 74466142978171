import { Tag } from '@nimbus-ds/components';
import { EyeOffIcon } from '@nimbus-ds/icons';
import { useHiddenCategories } from 'domains/Catalog/hooks';

function HiddenCategoryTag(): JSX.Element | null {
  const { hiddenCategoriesText } = useHiddenCategories();
  return (
    <Tag appearance="warning">
      <EyeOffIcon size="small" />
      {hiddenCategoriesText}
    </Tag>
  );
}

export default HiddenCategoryTag;
