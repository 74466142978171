export type RangeType = 'all-country' | 'zones' | 'ranges';

export const RANGE_TYPE_OPTIONS = [
  {
    label: 'options.allCountry',
    value: 'all-country' as RangeType,
  },
  {
    label: 'options.byZones',
    value: 'zones' as RangeType,
  },
  {
    label: 'options.byRanges',
    value: 'ranges' as RangeType,
  },
];
