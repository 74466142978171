import { OnChangeValue, ActionMeta } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { multiSelectColourStyles } from './multiSelectStyleConfig';
import { OptionType } from '../PredictMultiSelect/styleConfig';

interface MultiSelectProps {
  defaultValue: OptionType[] | undefined;
  options: OptionType[];
  placeholder: string;
  onChange: (
    newValue: OnChangeValue<OptionType, true>,
    action: ActionMeta<OptionType>,
  ) => void;
}

function MultiSelect({
  defaultValue,
  options,
  placeholder,
  onChange,
}: Readonly<MultiSelectProps>) {
  return (
    <CreatableSelect
      isMulti
      options={options}
      value={defaultValue}
      styles={multiSelectColourStyles}
      placeholder={placeholder}
      onChange={onChange}
      isValidNewOption={() => false}
    />
  );
}
export default MultiSelect;
