import { NewCustomShippingParamsInterface } from '../../deliveryMethodsSlice';

export const initialValues: NewCustomShippingParamsInterface = {
  id: '',
  name: '',
  cartValueMax: '',
  cartWeightMin: '',
  cartValueMin: '',
  cartWeightMax: '',
  deliveryTimeMin: '',
  deliveryTimeMax: '',
  deliveryCost: 'with-cost',
  cost: '',
  showAsPickUpPoint: false,
  allowedForFreeShipping: false,
  deliveryZones: [],
  locationId: '',
  ranges: [],
  rangeType: 'all-country',
};
