import { Icon, Link } from '@nimbus-ds/components';
import { TrashIcon } from '@nimbus-ds/icons';
import { useTranslationCommon } from 'commons/hooks';

interface ClearFiltersButtonProps {
  onClick: () => void;
}
export function ClearFiltersButton({
  onClick,
}: Readonly<ClearFiltersButtonProps>) {
  const t = useTranslationCommon();
  return (
    <Link
      appearance="primary"
      textDecoration="none"
      fontSize="caption"
      lineHeight="caption"
      onClick={onClick}
    >
      <Icon color="primary-interactive" source={<TrashIcon size={14} />} />
      {t('clearFilters')}
    </Link>
  );
}
