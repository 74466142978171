import { AxiosError } from 'axios';
import {
  DraftOrderConfirmResponseDto,
  DraftOrderDetailsResponseDto,
  DraftOrdersListResponseDto,
  DraftOrdersZipcodeResponse,
} from '@tiendanube/common';
import axios from 'App/axios';
import { STOCK_ERROR } from '../constants';
import {
  ConfirmDraftOrderValuesInterface,
  NewDraftOrdersValuesInterface,
} from '../pages/NewDraftOrderPage/types';

interface DraftOrdersFiltersParams {
  page?: number;
  perPage?: number;
  q?: string;
}

export const fetchDraftOrdersList = async (
  params?: DraftOrdersFiltersParams,
): Promise<DraftOrdersListResponseDto> => {
  const response = await axios.get(
    `v1/draft-orders`,
    params && {
      params,
    },
  );
  return response.data;
};

export const fetchDraftOrderById = async (
  id: string,
): Promise<DraftOrderDetailsResponseDto> => {
  const response = await axios.get(`v1/draft-orders/${id}`);
  return response.data;
};

export const addDraftOrder = async (
  draftOrder: NewDraftOrdersValuesInterface,
): Promise<DraftOrderDetailsResponseDto> => {
  const products = draftOrder.products.map((product) => ({
    id: product.variantId,
    quantity: product.quantity,
    priceNumber: product.price,
  }));
  try {
    const response = await axios.post(`/v1/draft-orders`, {
      paymentStatus: draftOrder.paymentStatus,
      products: products,
      customer: {
        name: draftOrder.firstName,
        lastname: draftOrder.lastName,
        email: draftOrder.email,
        identification: draftOrder.identification,
        phoneNumber: draftOrder.phone,
      },
      delivery: {
        zipcode: draftOrder.zipcode,
        street: draftOrder.street,
        number: draftOrder.number,
        floor: draftOrder.complement,
        city: draftOrder.city,
        province: draftOrder.province,
        country: '',
        locality: draftOrder.neighborhood,
      },
      amounts: {
        shipping: draftOrder.shipping,
        discount: draftOrder.discount,
        discountType: draftOrder.discountType,
      },
      salesChannel: draftOrder.salesChannel,
      remarks: draftOrder.remarks,
    });

    if (response.data.code === 500 || response.data.name === 'Error') {
      throw Error('error');
    }
    return response.data;
  } catch (error) {
    if (
      (error as AxiosError).response?.data.response.description === STOCK_ERROR
    ) {
      throw new Error(
        `${STOCK_ERROR}${error.response?.data.response.variant_ids.join(',')}`,
      );
    }
    throw error;
  }
};

export const confirmDraftOrder = async (
  data: ConfirmDraftOrderValuesInterface,
): Promise<DraftOrderConfirmResponseDto> => {
  const response = await axios.post(`v1/draft-orders/confirm`, {
    id: data.id,
    validateStock: data.validateStock,
  });
  return response.data;
};

export const removeDraftOrder = async (
  id: string,
): Promise<DraftOrderConfirmResponseDto> => {
  const response = await axios.delete(`v1/draft-orders/${id}`);
  return response.data;
};

export const validateZipCode = async (zipcode: string): Promise<boolean> => {
  const response = await axios.get<DraftOrdersZipcodeResponse>(
    `v1/draft-orders/zipcode/validate/${zipcode}`,
  );
  return response.data.success;
};
