import amplitude from 'amplitude-js';
import { NEW_ONBOARDING_B } from 'App/featuresFlags';
import { getAuthStorage } from 'domains/Auth/authService/authService';
import isNative from '../isNative';

function logEvent(
  eventName: string,
  eventProperties: Record<string, string | string[]>,
  instanceName: string | undefined = undefined,
): void {
  eventProperties['new_admin'] = 'true';
  eventProperties['device'] = isNative() ? 'mobile' : 'desktop';
  getAuthStorage().then((data) => {
    const allTags = data?.tags;
    eventProperties['onboarding202411'] = String(
      allTags?.includes(NEW_ONBOARDING_B),
    );
    amplitude.getInstance(instanceName).logEvent(eventName, eventProperties);
  });
}

export default logEvent;
