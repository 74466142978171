import { OrderDetailsResponseDto } from '@tiendanube/common';
import useTimelineSection from './useTimelineSection';
import CardTimeline from '../CardTimeline';

interface TimelineSectionProps {
  order: OrderDetailsResponseDto;
}

function TimelineSection({
  order: { fulfillment, fulfillmentOrders, id, isShippablePickup, payment },
}: TimelineSectionProps) {
  const { logEntries, status, fetchTimelineById } = useTimelineSection({
    orderId: id,
  });

  const handleRetry = () => {
    fetchTimelineById();
  };

  if (status.isLoading) return <CardTimeline.Skeleton />;
  if (status.isError) return <CardTimeline.ErrorState onReTry={handleRetry} />;
  return (
    <CardTimeline
      fulfillment={fulfillment}
      fulfillmentOrders={fulfillmentOrders ?? []}
      logEntries={logEntries}
      isShippablePickup={isShippablePickup}
      isManualPayment={payment.isManualPayment}
    />
  );
}

export default TimelineSection;
