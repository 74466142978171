import { CombinedState, createSelector } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { InterfaceDraftOrdersSchema } from '../draftOrdersSlice';

const getDraftOrdersState = (
  state: RootStateType,
): CombinedState<InterfaceDraftOrdersSchema> => state.orders.draftOrders;

const getEntityById = (_: RootStateType, id: string) => id;

export const getDraftOrdersStatus = createSelector(
  getDraftOrdersState,
  (draftOrdersState) => ({
    isLoading: draftOrdersState.status === 'loading',
    isError: draftOrdersState.status === 'error',
    isSuccess: draftOrdersState.status === 'success',
  }),
);

export const getDraftOrdersRefreshStatus = createSelector(
  getDraftOrdersState,
  (draftOrdersState) => ({
    isRefreshError: draftOrdersState.refreshStatus === 'error',
    isRefreshSuccess: draftOrdersState.refreshStatus === 'success',
    isRefreshing: draftOrdersState.refreshStatus === 'refreshing',
  }),
);

export const getDraftOrdersEntities = createSelector(
  getDraftOrdersState,
  (getDraftOrdersState) => getDraftOrdersState.entities,
);

export const getDraftOrdersIds = createSelector(
  getDraftOrdersState,
  (getDraftOrdersState) => getDraftOrdersState.ids,
);

export const getDraftOrdersListItemById = createSelector(
  getDraftOrdersEntities,
  getEntityById,
  (draftOrdersEntities, id) => draftOrdersEntities[id],
);

export const getDraftOrdersPagination = createSelector(
  getDraftOrdersState,
  (draftOrdersState) => draftOrdersState.pagination,
);

// Draft order details
export const getDraftOrderDetailsStatus = createSelector(
  getDraftOrdersState,
  (draftOrderDetailsState) => ({
    isLoading: draftOrderDetailsState.entityDetails.status === 'loading',
    isError: draftOrderDetailsState.entityDetails.status === 'error',
    isSuccess: draftOrderDetailsState.entityDetails.status === 'success',
  }),
);
export const getDraftOrderDetails = createSelector(
  getDraftOrdersState,
  (draftOrdersState) => draftOrdersState.entityDetails.data,
);
export const getDraftOrdersListPagination = createSelector(
  getDraftOrdersState,
  (draftOrdersState) => draftOrdersState.pagination,
);
