import { useMemo } from 'react';
import { DataList } from '@nimbus-ds/patterns';
import { CheckoutPaymentOptionDto } from '@tiendanube/common/src/domains/checkout/CheckoutPaymentsResponseDto';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import { SortCheckoutPaymentsCard } from '../..';
import { TRANSLATE_PREFIX } from '../CheckoutPaymentOptionsSorterModal';
import SortCheckoutPaymentListItem from '../SortCheckoutPaymentListItem';

interface SortCheckoutPaymentsListDefaultProps {
  data: Array<CheckoutPaymentOptionDto>;
}

function SortCheckoutPaymentsListDefault({
  data,
}: SortCheckoutPaymentsListDefaultProps) {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  const hasFixedPriorityKey = (item: CheckoutPaymentOptionDto) =>
    'fixedPriority' in item;

  const fixedPayments = useMemo(
    () => data.filter((item) => hasFixedPriorityKey(item)),
    [data],
  );

  const otherPayments = useMemo(
    () => data.filter((item) => hasFixedPriorityKey(item) === false),
    [data],
  );

  return (
    <>
      {fixedPayments.length > 0 && (
        <SortCheckoutPaymentsCard title={t('titleFixedPaymentMethod')}>
          <DataList bottomDivider={false}>
            {fixedPayments.map((item) => (
              <DataList.Row borderBottomWidth="none" key={item.id} id={item.id}>
                <SortCheckoutPaymentListItem item={item} />
              </DataList.Row>
            ))}
          </DataList>
        </SortCheckoutPaymentsCard>
      )}
      {otherPayments.length > 0 && (
        <SortCheckoutPaymentsCard
          title={
            fixedPayments.length ? t('titleOtherWays') : t('titlePaymentMethod')
          }
        >
          <DataList bottomDivider={false}>
            {otherPayments.map((item) => (
              <DataList.Row borderBottomWidth="none" key={item.id} id={item.id}>
                <SortCheckoutPaymentListItem item={item} />
              </DataList.Row>
            ))}
          </DataList>
        </SortCheckoutPaymentsCard>
      )}
    </>
  );
}

export default SortCheckoutPaymentsListDefault;
