import * as Yup from 'yup';
import { MAX_RANGE, MIN_RANGE } from './constants';

export const shippingCustomRangeSchema = () =>
  Yup.object().shape({
    cost: Yup.string().required('errors.withoutValue'),
    deliveryTimeMax: Yup.string()
      .required('errors.withoutValue')
      .test(
        'timeMaxIsLowerThanMin',
        'errors.incompatibleDeliveryTime',
        (value, { parent: { deliveryTimeMin } }) => {
          if (deliveryTimeMin) {
            return Number(deliveryTimeMin) <= Number(value);
          }
          return true;
        },
      ),
    deliveryTimeMin: Yup.string().required('errors.withoutValue'),
    finalRange: Yup.string()
      .required('errors.withoutValue')
      .test(
        'finalRangeIncompatibleValue',
        'errors.incompatibleDistance',
        (value, { parent: { initialRange } }) => {
          if (initialRange) {
            return Number(initialRange) < Number(value);
          }
          return true;
        },
      )
      .test(
        'finalRange',
        'errors.finalRange',
        (value) =>
          Number(value) >= Number(MIN_RANGE) &&
          Number(value) <= Number(MAX_RANGE),
      ),
  });
