import React from 'react';
import { Tag } from '@nimbus-ds/components';
import { MenuButton, ProductUpdates } from '@nimbus-ds/patterns';
import { Link } from 'react-router-dom';
import { useResponsive } from 'commons/components';
import { useStorage } from 'commons/hooks';

interface PriceTablesMenuProps {
  item: {
    children: string;
    onClick: () => void;
    active: boolean;
    href: string;
    tag: {
      label: React.ReactNode;
    };
    productUpdates: {
      title: string;
      description: string;
    };
  };
}

export default function PriceTablesMenu({
  item,
}: Readonly<PriceTablesMenuProps>) {
  const [show, setShow, loaded] = useStorage('price-tables-tooltip', true);
  const { isDesktop } = useResponsive();

  const handleOnClose = () => {
    setShow(false);
  };

  return (
    <MenuButton
      as={Link}
      label={item.children}
      onClick={item.onClick}
      to={item.href ?? ''}
      active={item.active}
    >
      {isDesktop ? (
        <ProductUpdates
          width="272px"
          title={item.productUpdates.title}
          text={item.productUpdates.description}
          visible={loaded && show}
          onVisibility={handleOnClose}
          onClick={(e) => e.stopPropagation()}
        >
          <Tag appearance="primary">{item.tag.label}</Tag>
        </ProductUpdates>
      ) : (
        <Tag appearance="primary">{item.tag.label}</Tag>
      )}
    </MenuButton>
  );
}
