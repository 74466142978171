import { UserSessionDto } from '@tiendanube/common';
import { useListDesktop } from 'App/hooks';
import { useListFilters } from 'commons/hooks';
import { RESULTS_PER_PAGE_DESKTOP, BASE_URL } from '../settings';
import { UserSessionEndpoints } from '../userSessionsApi';

const DEFAULT_FILTERS = { page: 1 };

export function useGetUserSessionsListDesktop() {
  const { filters, changeFilters } = useListFilters(BASE_URL, DEFAULT_FILTERS);

  const changePage = (page: number) => {
    changeFilters({ ...filters, page });
  };

  const result = useListDesktop<UserSessionDto>({
    filters: {
      ...filters,
      perPage: RESULTS_PER_PAGE_DESKTOP,
    },
    endpoint: UserSessionEndpoints.getUserSessions,
  });

  return { ...result, changePage, filters };
}
