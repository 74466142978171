import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ProductDetailsResponseDto } from '@tiendanube/common';
import { CATALOG_HIDING_VARIANTS } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { useAppDispatch, RootStateType } from 'App/store';
import { getProductById as getProductByIdAction } from 'domains/Catalog/Products/productsSlice';
import {
  getProductDetailsFetchStatus,
  createGetProductById,
} from 'domains/Catalog/Products/productsSlice/productSelectors';

export interface UseProductDetailsInterface {
  loading: boolean;
  error: boolean;
  fetchProduct: () => void;
  product: ProductDetailsResponseDto;
}

function useProductDetails(id: string): UseProductDetailsInterface {
  const dispatch = useAppDispatch();
  const getProductById = useMemo(createGetProductById, []);
  const product = useSelector((state: RootStateType) =>
    getProductById(state, id),
  );
  const { loading, error } = useSelector(getProductDetailsFetchStatus);
  const [hasHidingVariants] = useHasTags([CATALOG_HIDING_VARIANTS]);

  const getProductByIdVersion = useMemo(
    () => ({ version: hasHidingVariants ? '3' : '2' }),
    [hasHidingVariants],
  );

  const fetchProduct = useCallback(() => {
    dispatch(getProductByIdAction({ id, ...getProductByIdVersion }));
  }, [dispatch, id, getProductByIdVersion]);

  return useMemo(
    () => ({
      loading,
      error,
      product,
      fetchProduct,
    }),
    [loading, error, product, fetchProduct],
  );
}

export default useProductDetails;
