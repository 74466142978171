import { useCallback, useState } from 'react';

export enum Steps {
  SetUpRefundDetails,
  ConfirmRefundDetails,
}

export interface UseRefundStepsResult {
  step: Steps;
  currentStep: number;
  totalSteps: number;
  nextStep: () => void;
  backStep: () => void;
}

function useSteps(goBack: () => void): UseRefundStepsResult {
  const [step, setStep] = useState(Steps.SetUpRefundDetails);
  const nextStep = useCallback(() => {
    if (step === Steps.SetUpRefundDetails) {
      setStep(Steps.ConfirmRefundDetails);
    } else if (step === Steps.ConfirmRefundDetails) {
      setStep(Steps.SetUpRefundDetails);
    }
  }, [step]);

  const backStep = () => {
    if (step === Steps.ConfirmRefundDetails) {
      setStep(Steps.SetUpRefundDetails);
    } else if (step === Steps.SetUpRefundDetails) {
      goBack();
    }
  };

  return {
    step,
    currentStep: step + 1,
    totalSteps: Object.values(Steps).filter(
      (value) => typeof value === 'string',
    ).length,
    nextStep,
    backStep,
  };
}

export default useSteps;
