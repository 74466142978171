import { FEATURE_MONTHLY_CLIENTS_EXPORT } from 'App/features';
import { ImportCsvCard } from 'commons/components';
import { useTranslationCommon } from 'commons/hooks';
import { CUSTOMERS_IMPORT_CUSTOMERS } from 'config/upsellFlowSources';
import { useStatusFeatureByKey } from 'domains/Auth/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import customersServices from 'domains/Customers/Customers/customersServices';
import { ImportCustomersExtraOptions } from './ImportCustomersExtraOptions';
import { useImportCustomers, useImportMaxLinesCustomers } from '../hooks';

interface ImportCustomersCardProps {
  showUpdateOptions?: boolean;
}

export function ImportCustomersCard({
  showUpdateOptions = false,
}: ImportCustomersCardProps) {
  const t = useTranslationCommon('importCsv.import');
  const { isAvailable } = useStatusFeatureByKey(FEATURE_MONTHLY_CLIENTS_EXPORT);
  const entitiesName = t('entities-customers');

  const handleUpsellCallout = useUpsellFlow({
    title: t('upsell.modalTitle', { entities: entitiesName }),
    featureKey: FEATURE_MONTHLY_CLIENTS_EXPORT,
    trackingSource: CUSTOMERS_IMPORT_CUSTOMERS,
    callback: () => {
      // Just re-render the component
    },
  });

  return (
    <ImportCsvCard
      entity="customers"
      entitiesName={entitiesName}
      fetchTemplateCsvHandler={customersServices.fetchTemplateCsv}
      useImportCsvWrapper={useImportCustomers}
      useImportMaxLinesWrapper={useImportMaxLinesCustomers}
      showUpdateOptions={showUpdateOptions}
      isAvailable={isAvailable}
      handleUpsellCallout={handleUpsellCallout}
      extraOptions={ImportCustomersExtraOptions}
    />
  );
}
