import { Box, Button, Icon } from '@nimbus-ds/components';
import { SlidersIcon } from '@nimbus-ds/icons';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import './FilterButton.scss';

interface FilterButtonProps {
  onClick: () => void;
}

export function FilterButton({ onClick }: Readonly<FilterButtonProps>) {
  const t = useTranslationOrders();

  return (
    <div className="stratus--filter-button">
      <Button onClick={onClick} appearance="transparent">
        <Box
          flexDirection="row"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Icon source={<SlidersIcon />} />
          <Box fontSize="3" fontWeight="regular" lineHeight="4" paddingLeft="1">
            {t('filter.inputAriaLabel')}
          </Box>
        </Box>
      </Button>
    </div>
  );
}
