import { useEffect } from 'react';
import useCustomerForm from 'domains/Customers/Customers/pages/CustomerFormPage/useCustomerForm';
import {
  trackingCustomerEditPageLoad,
  trackingCustomerNewPageLoad,
} from 'domains/Customers/tracking';

function useCustomerFormPageLoadTracking(
  id: string,
  isMetafieldsSuccess: boolean,
  isMetafieldsError: boolean,
) {
  const {
    customer,
    isError: isCustomerError,
    customerMetafieldsStatus,
  } = useCustomerForm(id);
  const {
    isSuccess: isOwnerMetafieldsSuccess,
    isError: isOwnerMetafieldsError,
  } = customerMetafieldsStatus;

  useEffect(() => {
    if (!id) {
      trackingCustomerNewPageLoad(isCustomerError);
    } else {
      const isDataFetched = id === customer.id;
      if (
        isOwnerMetafieldsSuccess !== isOwnerMetafieldsError &&
        isMetafieldsSuccess !== isMetafieldsError &&
        isCustomerError !== isDataFetched
      ) {
        trackingCustomerEditPageLoad(
          id,
          isCustomerError,
          isOwnerMetafieldsError || isMetafieldsError,
        );
      }
    }
  }, [
    id,
    customer.id,
    isCustomerError,
    isOwnerMetafieldsSuccess,
    isOwnerMetafieldsError,
    isMetafieldsSuccess,
    isMetafieldsError,
  ]);
}

export default useCustomerFormPageLoadTracking;
