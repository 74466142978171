import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import objectToUrlParams from 'commons/utils/objectToUrlParams';
import { getSortByProducts } from 'domains/Catalog/Products/productsSlice/productSelectors';
import { ORDERS_PER_PAGE } from 'domains/Orders/Orders/pages/constants';

interface UseListFiltersResult<
  T extends Record<string, string | number | boolean>,
> {
  filters: T;
  hasFilters: boolean;
  changeFilters: (newFilters: T) => void;
  updateFilters: (newFilters: Partial<T>) => void;
  removeMetafieldsFilters: () => void;
  urlToBuild: string;
}

function useListFilters<T extends Record<string, string | number | boolean>>(
  url: string,
  defaultFilters: T,
  hasSortBy = false,
): UseListFiltersResult<T> {
  const { search } = useLocation();
  const { replace } = useHistory();
  const sortByProducts = useSelector(getSortByProducts);

  const getFiltersFromUrl = useCallback(() => {
    const searchParams = new URLSearchParams(search);
    const keysDefault = Object.keys(defaultFilters);
    const keysMetafields = Array.from(searchParams.keys()).filter((key) =>
      key.startsWith('mf-'),
    );
    const allKeys = [...keysDefault, ...keysMetafields];

    const filtersDigest = allKeys.reduce((filters, key) => {
      const value = searchParams.get(key) || '';
      if (key === 'page') {
        return { ...filters, page: Number(value) || 1 };
      }
      if (key === 'perPage') {
        return { ...filters, perPage: Number(value) || ORDERS_PER_PAGE };
      }
      if (key === 'sortBy' && !value) {
        return filters;
      }
      return { ...filters, [key]: value };
    }, {} as T);

    return {
      ...filtersDigest,
      ...(!filtersDigest.sortBy &&
        hasSortBy &&
        sortByProducts && { sortBy: sortByProducts }),
    };
  }, [defaultFilters, hasSortBy, search, sortByProducts]);

  const [filters, setFilters] = useState<T>(getFiltersFromUrl);

  useEffect(() => setFilters(getFiltersFromUrl()), [getFiltersFromUrl, url]);

  const updateUrl = useCallback(
    (newFilters: T) => {
      replace(`/${url}?${objectToUrlParams(newFilters)}`);
    },
    [replace, url],
  );

  const changeFilters = useCallback(
    (newFilters: T) => {
      setFilters(newFilters);
      updateUrl(newFilters);
    },
    [updateUrl],
  );

  const updateFilters = useCallback(
    (newFilters: Partial<T>) => {
      setFilters((previousFilters) => {
        const updatedFilter = { ...previousFilters, ...newFilters };
        updateUrl(updatedFilter);
        return updatedFilter;
      });
    },
    [updateUrl],
  );

  const removeMetafieldsFilters = useCallback(() => {
    const keysNotMetefields = Object.entries(filters).filter(
      ([key]) => !key.startsWith('mf-'),
    );
    const filtersNotMetefields = Object.fromEntries(keysNotMetefields);
    replace(`/${url}?${objectToUrlParams(filtersNotMetefields)}`);
  }, [filters, replace, url]);

  const hasFilters =
    Object.keys(filters).filter(
      (key) => filters[key] && !(key === 'page' && Number(filters[key]) === 1),
    ).length > 0;

  const filterToBuild = { ...filters, page: '{pageNumber}' };
  const urlToBuild = `${url}?${objectToUrlParams(filterToBuild)}`;

  return {
    urlToBuild,
    filters,
    hasFilters,
    changeFilters,
    updateFilters,
    removeMetafieldsFilters,
  };
}

export default useListFilters;
