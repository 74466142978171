import { TablePricesDto } from '@tiendanube/common';
import axios from 'App/axios';
import { GetTablePricesType } from 'domains/Customers/TablePrices/tablePricesService/types';

const getTablePrices: GetTablePricesType = async () => {
  const { data } = await axios.get<TablePricesDto[]>('/price-tables');
  return data;
};

const tablePricesService: {
  getTablePrices: GetTablePricesType;
} = {
  getTablePrices,
};

export default tablePricesService;
