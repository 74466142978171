import { useCallback, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { CheckoutSavePaymentOrdersDto } from '@tiendanube/common';
import { useAppDispatch } from 'App/store';
import { StatusType } from 'commons/types';
import { saveCheckoutPaymentOptionsAction } from '../../checkoutPaymentsSlice';

interface UseSaveCheckoutPaymentOptionsOrder {
  isLoading: boolean;
  mutation: (data: CheckoutSavePaymentOrdersDto) => void;
  data: StatusType;
}

function useSaveCheckoutPaymentOptionsOrder(): UseSaveCheckoutPaymentOptionsOrder {
  const [data, setData] = useState<StatusType>('idle');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const mutation = useCallback(
    async (payload: CheckoutSavePaymentOrdersDto) => {
      setIsLoading(true);
      try {
        const data = await dispatch(saveCheckoutPaymentOptionsAction(payload));
        setIsLoading(false);
        setData('success');
        return unwrapResult(data);
      } catch (error) {
        setIsLoading(false);
        setData('error');
      }
    },
    [dispatch],
  );

  return {
    data,
    isLoading,
    mutation,
  };
}

export default useSaveCheckoutPaymentOptionsOrder;
