import { useMemo } from 'react';
import { Box, Checkbox, Tag, Text } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { TablePricesDto } from '@tiendanube/common';
import ModalAside from 'App/components/lab/ModalAside';
import { HeaderContent } from 'commons/components';
import { PriceTableInCard } from 'domains/Customers/Customers/pages/CustomerFormPage/components/CustomerPriceTablesCard/CustomerPriceTablesCard';
import { useTranslationCustomers } from 'domains/Customers/hooks';

interface PriceTablesModalProps {
  isOpen: boolean;
  close: () => void;
  priceTables: TablePricesDto[];
  selectedPriceTables: PriceTableInCard[];
  addPriceTable: (priceTable: PriceTableInCard) => void;
  removePriceTable: (id: string) => void;
}

export default function PriceTablesModal({
  isOpen,
  close,
  priceTables,
  selectedPriceTables,
  addPriceTable,
  removePriceTable,
}: Readonly<PriceTablesModalProps>) {
  const t = useTranslationCustomers();
  const tPath = 'customerForm.priceTablesInformation';

  const selectedPriceTablesObj = useMemo(() => {
    const obj: Record<string, PriceTableInCard> = {};
    for (const priceTable of selectedPriceTables) {
      obj[priceTable.id] = priceTable;
    }
    return obj;
  }, [selectedPriceTables]);

  return (
    <ModalAside
      isOpen={isOpen}
      onDidDismiss={close}
      headerContent={<HeaderContent title={t(`${tPath}.title`)} />}
    >
      <DataList>
        {priceTables.map((priceTable) => (
          <DataList.Row key={priceTable.id}>
            <Box display="flex" gap="2">
              <div>
                <Checkbox
                  name={`priceTables-${priceTable.id}}`}
                  onChange={(e) =>
                    e.target.checked
                      ? addPriceTable(priceTable)
                      : removePriceTable(priceTable.id)
                  }
                  checked={!!selectedPriceTablesObj[priceTable.id]}
                />
              </div>
              <Box display="flex" gap="1" flexDirection="column">
                <Text color="neutral-textHigh">{priceTable.name}</Text>
                <Text fontSize="caption">
                  {priceTable.defaultValue}
                  {t(`${tPath}.modal.defaultValueInformation`)}
                </Text>
                <Tag>{t(`${tPath}.modal.allProducts`)}</Tag>
              </Box>
            </Box>
          </DataList.Row>
        ))}
      </DataList>
    </ModalAside>
  );
}
