import { Route, Switch } from 'react-router';
import ToggleMigratedOldDomain from 'App/components/Migrations/ToggleMigratedOldDomain';
import { accountRoutes } from 'domains/Account';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { UserFormPage, UsersListPage } from './pages';

function Users(): JSX.Element {
  const isMobileDevice = useIsMobileDevice();

  if (isMobileDevice)
    return (
      <Switch>
        <Route path={accountRoutes.users} exact>
          <UsersListPage />
        </Route>
        <Route path={accountRoutes.userForm}>
          <UserFormPage />
        </Route>
      </Switch>
    );

  return (
    <Switch>
      <Route path={accountRoutes.users} exact>
        <ToggleMigratedOldDomain domain="users">
          <UsersListPage />
        </ToggleMigratedOldDomain>
      </Route>

      <Route path={accountRoutes.userForm}>
        <ToggleMigratedOldDomain domain="users">
          <UserFormPage />
        </ToggleMigratedOldDomain>
      </Route>
    </Switch>
  );
}

export default Users;
