import { Card, Table } from '@nimbus-ds/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import BusinessHourItem from './BusinessHourItem';
import { WEEKDAYS_OPTIONS } from './utils';
import './PickupBusinessHours.scss';

function PickupBusinessHoursSkeleton(): JSX.Element {
  const t = useTranslationShipping(
    'deliveryMethods.pickupPoints.form.secondStep.businessHours',
  );

  return (
    <Card padding="none">
      <Card.Header padding="base" title={t('title')} />
      <Card.Body>
        <div className="stratus--custom-table">
          <Table>
            <Table.Head>
              <Table.Cell backgroundColor="neutral-surface">
                {t('day')}
              </Table.Cell>
              <Table.Cell backgroundColor="neutral-surface">
                {t('start')}
              </Table.Cell>
              <Table.Cell backgroundColor="neutral-surface">
                {t('end')}
              </Table.Cell>
              <Table.Cell backgroundColor="neutral-surface">
                {t('action')}
              </Table.Cell>
            </Table.Head>
            <Table.Body>
              {WEEKDAYS_OPTIONS.map((weekday) => (
                <BusinessHourItem.Skeleton key={weekday} />
              ))}
            </Table.Body>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
}

export default PickupBusinessHoursSkeleton;
