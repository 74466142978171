import { useEffect } from 'react';
import { ConceptCode } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { FROM_PAYMENT_QUERY, CONCEPT_CODE_PAID_QUERY } from '../checkoutRoutes';
import { trackingBillingPayOrderCheckoutFinish } from '../tracking';

export default function useTrackBillingPayOrderCheckoutFinish() {
  const { hasSearchParam, getQueryParam } = useNavegate();
  const fromPayment = hasSearchParam(FROM_PAYMENT_QUERY);
  const conceptPaid = getQueryParam(CONCEPT_CODE_PAID_QUERY) as ConceptCode;

  useEffect(() => {
    if (fromPayment) {
      trackingBillingPayOrderCheckoutFinish(conceptPaid);
    }
  }, [fromPayment, conceptPaid]);
}
