/* eslint-disable max-statements */
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getFlatDataFromTree,
  getTreeFromFlatData,
  TreeItem,
} from '@nosferatu500/react-sortable-tree';
import isEqual from 'lodash.isequal';
import { CategorySyncTreeRequestDto } from '@tiendanube/common';
import { NEW_ADMIN_EVENT_CATEGORIES } from 'App/featuresFlags';
import {
  useAsyncFunc,
  useModal,
  useToast,
  WrappedFuncType,
} from 'commons/hooks';
import { StatusSaveType } from 'commons/types';
import { useGetTags } from 'domains/Auth/hooks';
import categoriesServices from 'domains/Catalog/Categories/categoriesServices';
import { trackingCategoriesTree } from 'domains/Catalog/Products/tracking';
import { VisibilityCategoryType } from 'domains/Catalog/Products/utils';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { CategoryNodeType } from '../../types';

export interface TreeData extends TreeItem {
  id: string;
  parent: string;
  droppable: boolean;
  expanded: boolean;
  text: string;
  children?: TreeData[];
  visibility: VisibilityCategoryType;
}

interface UseCategoriesListResult {
  tree: TreeData[];
  treeFlatten: CategorySyncTreeRequestDto[];
  isSuccess: boolean;
  isLoading: boolean;
  isError: boolean;
  isDirty: boolean;
  showEmpty: boolean;
  showEmptyCategories: boolean;
  statusSave: StatusSaveType;
  closeEmpty: () => void;
  fetchTree: WrappedFuncType<void, Promise<void>>;
  setTree: (newTree: TreeData[]) => void;
  retry: () => void;
  restoreSavedTree: () => void;
  save: () => void;
}

// this is for internal dnd library state
const treeDataFromFlatDigest = (treeData: CategoryNodeType[]) =>
  getTreeFromFlatData({
    flatData: treeData,
    getKey: (node) => node.id,
    getParentKey: (node) => node.parent,
  });

const treeDataToFlatDigest = (
  treeData: CategoryNodeType[],
): CategorySyncTreeRequestDto[] =>
  getFlatDataFromTree({
    treeData,
    getNodeKey: ({ treeIndex }) => treeIndex,
    ignoreCollapsed: false,
  }).map(({ node, parentNode }) => ({
    id: node.id,
    text: node.text,
    parent: parentNode?.id ?? '0',
    visibility: node.visibility,
  }));

function trackingCategoriesTreeSend(
  action: 'beforeSave' | 'afterSave',
  treeDataFlatten: CategorySyncTreeRequestDto[],
  hasTagEventCategories: boolean,
) {
  if (!hasTagEventCategories) {
    return;
  }
  let treeDataString = JSON.stringify(
    treeDataFlatten.map((currentCategory) => [
      currentCategory.id,
      currentCategory.parent,
      currentCategory.text,
    ]),
  );
  const maxLength = 1021;
  if (treeDataString.length > maxLength) {
    // Truncate JSON string if exceeds limit
    treeDataString = treeDataString.substring(0, maxLength) + '...';
  }
  trackingCategoriesTree(action, treeDataString);
}

// TODO: use redux reducers instead of async func
function useCategoriesTreeNew(): UseCategoriesListResult {
  const [showEmpty, openEmpty, closeEmpty] = useModal();
  const [isSuccess, setIsSuccess] = useState(false);
  const [treeData, setTreeData] = useState<TreeData[]>([]);
  const [treeDataSaved, setTreeDataSaved] = useState<TreeData[]>([]);
  const [statusSave, setStatusSave] = useState<StatusSaveType>('idle');
  const [showEmptyCategories, setShowEmptyCategories] = useState(false);

  const { addToast } = useToast();
  const t = useTranslationCatalog();
  const tags = useGetTags();
  const [fetchTree, isLoading, isError, clear] = useAsyncFunc<
    void,
    Promise<void>
  >(
    useCallback(async () => {
      setShowEmptyCategories(false);
      setIsSuccess(false);
      const newData = await categoriesServices.getTree();
      setTreeData(treeDataFromFlatDigest(newData));
      setTreeDataSaved(treeDataFromFlatDigest(newData));
      setIsSuccess(true);
    }, []),
  );

  const retry = () => {
    clear();
    fetchTree();
  };

  const restoreSavedTree = () => {
    setTreeData(treeDataFromFlatDigest(treeDataSaved));
  };

  const hasEmptyFields = (treeData: TreeData[]) =>
    getFlatDataFromTree({
      treeData,
      getNodeKey: ({ treeIndex }) => treeIndex,
      ignoreCollapsed: false,
    }).some(
      ({ node }) => node?.id.startsWith('new-') && node?.text?.trim() === '',
    );

  const save = async () => {
    const treeDataFlatten = treeDataToFlatDigest(treeData).filter(
      (node) => node.text?.trim() !== '' || !node.id.startsWith('new-'),
    );
    if (hasEmptyFields(treeData)) {
      setShowEmptyCategories(true);
      return;
    }
    setShowEmptyCategories(false);
    setStatusSave('saving');
    try {
      const newData = await categoriesServices.syncTree(treeDataFlatten);
      addToast({
        label: t('categories.toast.success'),
        appearance: 'success',
      });
      setTreeData([]); // First force empty to avoid show new nodes closed
      setTreeData(treeDataFromFlatDigest(newData));
      setTreeDataSaved(treeDataFromFlatDigest(newData));
      setStatusSave('success');
      trackingCategoriesTreeSend(
        'beforeSave',
        treeDataToFlatDigest(treeDataSaved),
        tags.includes(NEW_ADMIN_EVENT_CATEGORIES),
      );
      trackingCategoriesTreeSend(
        'afterSave',
        treeDataFlatten,
        tags.includes(NEW_ADMIN_EVENT_CATEGORIES),
      );
    } catch (e) {
      setStatusSave('error');
      addToast({
        label: t('categories.toast.error'),
        appearance: 'danger',
      });
    }
  };

  const setTree = (newTree: TreeData[]) => {
    setStatusSave('idle');
    setTreeData(newTree);
  };

  useEffect(() => {
    if (treeDataFromFlatDigest(treeData).every(({ text }) => text !== '')) {
      setShowEmptyCategories(false);
    }
  }, [treeData]);

  useEffect(() => {
    if (treeData.length === 0 && (isSuccess || statusSave === 'success')) {
      setShowEmptyCategories(false);
      openEmpty();
    } else {
      closeEmpty();
    }
  }, [closeEmpty, isSuccess, openEmpty, statusSave, treeData.length]);

  const isDirty = useMemo(
    () =>
      !isEqual(
        treeDataToFlatDigest(treeData),
        treeDataToFlatDigest(treeDataSaved),
      ),
    [treeData, treeDataSaved],
  );

  return {
    tree: treeData,
    treeFlatten: treeDataToFlatDigest(treeData),
    isLoading,
    isError,
    isSuccess,
    isDirty,
    showEmpty,
    showEmptyCategories,
    statusSave,
    closeEmpty,
    fetchTree,
    setTree,
    retry,
    save,
    restoreSavedTree,
  };
}

export default useCategoriesTreeNew;
