import { Alert } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { useCoupons } from '../hooks';

interface CouponsAlertProps {
  show: boolean;
  onDismiss: () => void;
}
function CouponsAlertSuccess({ show, onDismiss }: CouponsAlertProps) {
  const t = useTranslationBilling();
  const { data: { coupon: { type, value } = {} } = {} } = useCoupons();

  const discountText =
    type === 'free_days'
      ? t('couponsPage.couponsAlert.daysDiscountText', { value })
      : t('couponsPage.couponsAlert.rateDiscountText', { value });

  return (
    <Alert
      title={t('couponsPage.couponsAlert.successTitle')}
      appearance="success"
      show={show}
      onDismiss={onDismiss}
    >
      <Text>{discountText}</Text>
    </Alert>
  );
}

export default CouponsAlertSuccess;
