import { Text, Box, Modal, List, Title, Button } from '@nimbus-ds/components';
import { OrderUpdateLineItem } from '@tiendanube/common';
import { useAsyncFunc } from '@tiendanube/stratus/src/commons/hooks';
import { useNavegate } from 'App/hooks';
import { CancelAndConfirmButtons } from 'commons/components';
import {
  trackingDraftOrderCancelWithIssuesButtonClick,
  trackingDraftOrderConfirmWithIssuesButtonClick,
  trackingDraftOrderWithIssuesUndestoodButtonClick,
} from 'domains/Orders/tracking';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

const productListLimit = 10;

interface UpdateMetadata {
  stockNeeded: number;
  stockAvailable: number;
  lineItems: OrderUpdateLineItem[];
}
interface InsufficientStockModalProps {
  open: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
  updateMetadata: UpdateMetadata;
  toastSuccess: () => void;
  onError: () => void;
  draftOrderId: string;
}

function InsufficientStockModal({
  onConfirm,
  onDismiss,
  open,
  updateMetadata: { stockNeeded, stockAvailable, lineItems },
  onError,
  draftOrderId,
}: Readonly<InsufficientStockModalProps>) {
  const t = useTranslationOrders();
  const { goTo } = useNavegate();

  const confirmDraftOrder = () => {
    trackingDraftOrderConfirmWithIssuesButtonClick(),
      onDismiss(),
      goTo(`/orders/${draftOrderId}`);
  };

  const [handleConfirm] = useAsyncFunc(
    async () => onConfirm(),
    confirmDraftOrder,
    onError,
  );
  const handleDismiss = () => {
    trackingDraftOrderCancelWithIssuesButtonClick();
    onDismiss();
  };
  const handleUndestood = () => {
    trackingDraftOrderWithIssuesUndestoodButtonClick();
    onDismiss();
  };

  const showItemsAsList = lineItems.length <= productListLimit;
  const draftOrderWithoutLineItems = stockAvailable === 0;

  const hasDeleted = lineItems.every(
    (lineItem: OrderUpdateLineItem) => lineItem.deleted === true,
  );

  return (
    <Modal open={open} onDismiss={onDismiss}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        marginLeft="2"
      >
        {!draftOrderWithoutLineItems && showItemsAsList && (
          <>
            <Title as="h4">
              {t('draftOrders.insufficientStock.stockChange')}
            </Title>
            <Title as="h4">{t('draftOrders.insufficientStock.action')}</Title>

            <Box marginTop="4">
              <Text>
                {t('draftOrders.insufficientStock.body1', {
                  count: stockAvailable,
                  stockNeeded: stockNeeded,
                })}{' '}
                {t('draftOrders.insufficientStock.body2')}
              </Text>
            </Box>
            <Box marginTop="4" marginBottom="4">
              <Text>
                {t('draftOrders.insufficientStock.insufficientStock')}
              </Text>
              <Box display="flex" flexWrap="wrap" alignItems="center">
                <List>
                  {lineItems.map((lineItem: OrderUpdateLineItem) => (
                    <List.Item key={lineItem.id}>
                      {lineItem.deleted ? (
                        <Text>
                          {lineItem.name} {' - '}
                          {t('detail.insufficientStock.deleted')}
                        </Text>
                      ) : (
                        <Text>
                          {lineItem.name} {' - '}
                          {t('detail.insufficientStock.issues', {
                            count:
                              lineItem.stockNeeded - lineItem.stockAvailable,
                          })}
                        </Text>
                      )}
                    </List.Item>
                  ))}
                </List>
              </Box>
            </Box>
          </>
        )}

        {!draftOrderWithoutLineItems && !showItemsAsList && (
          <>
            <Title as="h4">
              {t('draftOrders.insufficientStock.stockChange')}
            </Title>
            <Title as="h4">{t('draftOrders.insufficientStock.action')}</Title>
            <Box marginTop="4">
              <Text>
                {t('draftOrders.insufficientStock.body1', {
                  stockNeeded,
                  count: stockAvailable,
                })}
              </Text>
            </Box>
            <Box marginTop="4">
              <Text>{t('draftOrders.insufficientStock.body2')}</Text>
            </Box>
          </>
        )}

        {draftOrderWithoutLineItems && (
          <>
            {hasDeleted ? (
              <>
                <Title as="h4">
                  {t('draftOrders.insufficientStock.titleStockChanged')}
                </Title>
                <Box marginTop="4" marginBottom="4">
                  <Text>
                    {t('draftOrders.insufficientStock.bodyItemDeleted', {
                      count: lineItems.length,
                    })}
                  </Text>
                </Box>
              </>
            ) : (
              <>
                <Title as="h4">
                  {t('draftOrders.insufficientStock.titleReStock')}
                </Title>

                <Box marginTop="4" marginBottom="4">
                  <Text>
                    {t('draftOrders.insufficientStock.bodyNoStock', {
                      count: lineItems.length,
                    })}
                  </Text>
                  <Text>
                    {t('draftOrders.insufficientStock.bodyRetry', {
                      count: lineItems.length,
                    })}
                  </Text>
                </Box>
              </>
            )}

            <Box marginBottom="4">
              <List>
                {lineItems.map((lineItem: OrderUpdateLineItem) => (
                  <List.Item key={lineItem.id}>
                    {lineItem.deleted ? (
                      <Text>
                        {lineItem.name} {' - '}
                        {t('detail.insufficientStock.deleted')}
                      </Text>
                    ) : (
                      <Text>
                        {lineItem.name} {' - '}
                        {t('detail.insufficientStock.issues', {
                          count: lineItem.stockNeeded - lineItem.stockAvailable,
                        })}
                      </Text>
                    )}
                  </List.Item>
                ))}
              </List>
            </Box>

            <Box display="flex" alignItems="flex-end" alignSelf="flex-end">
              <Button appearance="primary" onClick={() => handleUndestood()}>
                {t('draftOrders.insufficientStock.understood')}
              </Button>
            </Box>
          </>
        )}

        {!draftOrderWithoutLineItems && (
          <Box display="flex" alignItems="flex-end" alignSelf="flex-end">
            <CancelAndConfirmButtons
              confirmText={t('draftOrders.insufficientStock.confirm')}
              cancelText={t('draftOrders.insufficientStock.cancel')}
              onConfirm={handleConfirm}
              onCancel={handleDismiss}
            />
          </Box>
        )}
      </Box>
    </Modal>
  );
}

export default InsufficientStockModal;
