import { Select } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

function OriginSelect() {
  const t = useTranslationCatalog('products.detail.invoice');
  const data = [
    {
      originId: '0',
      originName: t('selectPreview'),
    },
    {
      originId: '1',
      originName: t('nationalOption'),
    },
    {
      originId: '2',
      originName: t('foreignOption'),
    },
    {
      originId: '3',
      originName: t('noMarketForeignOption'),
    },
    {
      originId: '4',
      originName: t('natSuperiorImportOption'),
    },
    {
      originId: '5',
      originName: t('natReturnedProductOption'),
    },
    {
      originId: '6',
      originName: t('natLowerImportOption'),
    },
    {
      originId: '7',
      originName: t('foreignDirectImportOption'),
    },
  ];

  const handleSelectChange = ({
    target: { name, value },
  }: React.ChangeEvent<HTMLSelectElement>) => {
    console.log('Seleccionado', name, value);
  };
  return (
    <Stack.Item fill>
      <FormField.Select
        id="id-origin"
        label={t('titleOrigin')}
        name="origin"
        onChange={handleSelectChange}
      >
        {data?.map(({ originId, originName }) => (
          <Select.Option
            key={`origin-${originId}`}
            value={originId}
            label={originName}
          />
        ))}
      </FormField.Select>
    </Stack.Item>
  );
}

export default OriginSelect;
