import { useMemo } from 'react';
import { Box, Checkbox, Link, Spinner, Text } from '@nimbus-ds/components';
import { ChargeDto } from '@tiendanube/common';
import { useGetIsFreePlan, useStoreStatus } from 'domains/Auth/hooks';
import {
  useAppName,
  useChargesToPay,
  useExpirationDate,
} from 'domains/Billing/Checkout/hooks';
import { useGoToConceptDetails } from 'domains/Billing/Checkout/hooks/useGoToConceptDetails';
import {
  PaymentStatusTag,
  ChargePeriodDetail,
} from 'domains/Billing/Checkout/pages/PlansAndSubscriptions/components';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { TrialStatusTag } from './components';
import ChargeToPaySkeleton from './Skeleton';
import { useSelectCharges } from '../../hooks';

interface ChargeToPayProps {
  charge: ChargeDto;
  isVirtualCharge?: boolean;
}

function ChargeToPay({ charge, isVirtualCharge = false }: ChargeToPayProps) {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.paymentsTabs.toPay',
  );
  const formatCurrency = useFormatCurrency();
  const { chargeTotalWithoutTaxes } = useChargesToPay(false);
  const { isTrial } = useGetIsFreePlan();
  const { selectCharge, selectedCharges } = useSelectCharges();

  const { appName, isLoading } = useAppName(
    charge.conceptCode,
    charge.metadata?.appId,
  );

  const { storeStatus } = useStoreStatus();
  const isPlan = charge.conceptCode === 'plan-cost';
  const forceSelection =
    charge.status === 'IN_PROCESS' ||
    (isPlan && (storeStatus === 'admin_down' || storeStatus === 'store_down'));

  const { expiresIn } = useExpirationDate(
    charge.conceptCode,
    charge.metadata?.appId,
  );

  const isSelected = useMemo(
    () => selectedCharges.includes(charge),
    [selectedCharges, charge],
  );

  const goToDetails = useGoToConceptDetails(
    'chargesToPay',
    charge.conceptCode,
    charge.metadata?.appId,
    !isVirtualCharge ? charge.id : undefined,
  );

  const shouldHideDetails =
    (charge.conceptCode === 'plan-cost' && storeStatus === 'store_down') ||
    (charge.conceptCode === 'app-cost' && !charge.subscription);

  return (
    <Box
      display="flex"
      flexDirection="row"
      padding="4"
      justifyContent="space-between"
      alignItems="baseline"
    >
      <Box display="flex" flex="1 1 5%">
        <Box>
          <Checkbox
            name={`checkbox-charge-${charge.id}`}
            checked={isSelected}
            onChange={(e) => selectCharge(e.target.checked, charge)}
            disabled={forceSelection}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" flex="1 1 75%" gap="1-5">
        {isLoading ? (
          <Spinner size="small" />
        ) : (
          <Text fontWeight="bold">
            {charge.conceptCode === 'app-extra-fee'
              ? `${appName} - ${t('extraFee')}`
              : charge.description}
          </Text>
        )}
        {!isVirtualCharge && <ChargePeriodDetail {...charge} />}
        {isPlan && isTrial ? (
          <TrialStatusTag paymentStatus={charge.status} />
        ) : (
          <PaymentStatusTag paymentStatus={charge.status} />
        )}
        {!shouldHideDetails && (
          <Link fontSize="caption" appearance="primary" onClick={goToDetails}>
            {t('chargeDetails')}
          </Link>
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        flex="1 1 20%"
        alignItems="flex-end"
        gap="2"
      >
        <Text>
          {formatCurrency(
            chargeTotalWithoutTaxes(charge),
            charge.amountCurrency,
          )}
        </Text>
        {charge.conceptCode === 'plan-cost' && storeStatus === 'store_down' && (
          <Text fontSize="caption" color="neutral-textLow">
            {t('churned.expiredPlan')}
          </Text>
        )}
        {!isTrial &&
          storeStatus !== 'store_down' &&
          !!expiresIn &&
          expiresIn > 0 && (
            <Text fontSize="caption" color="neutral-textLow">
              {t('expiresIn', { expiresIn })}
            </Text>
          )}
      </Box>
    </Box>
  );
}

ChargeToPay.Skeleton = ChargeToPaySkeleton;

export default ChargeToPay;
