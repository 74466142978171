import { useEffect, useState } from 'react';
import { InteractiveList } from '@tiendanube/components';
import ModalAside from 'App/components/lab/ModalAside';
import { useModal } from 'commons/hooks';
import { useGetLanguage } from 'domains/Auth/hooks';
import { attributeHasValues } from 'domains/Catalog/Products/utils';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { Attribute, Attributes } from '../../types';
import AttributeValuesModal from '../AttributeValuesModal';

interface AttributesModalProps {
  attributes: Attributes;
  isShow: boolean;
  onClose: () => void;
  onChange: (attributes: Attributes) => void;
}

function AttributesModal({
  attributes,
  isShow,
  onClose,
  onChange,
}: AttributesModalProps): JSX.Element {
  const t = useTranslationCatalog();
  const language = useGetLanguage();
  const [attributeSelected, setAttributeSelected] = useState<
    Attribute | undefined
  >(undefined);
  const [isShowAttributeValues, openAttributeValues, closeAttributeValues] =
    useModal();

  const createInteractiveOptions = (attributes: Attributes) =>
    Object.values(attributes)
      .filter((attr) => attributeHasValues(attr))
      .map((attr) => ({
        title: attr.name?.[language] || '',
        name: attr.name?.[language] || '',
        labels: attr.values.map((option) => ({
          id: option?.[language] || '',
          label: option?.[language] || '',
          appearance: !option ? ('danger' as const) : ('default' as const),
        })),
      }));

  const disabledAdd = createInteractiveOptions(attributes).length === 3;

  const handleOnClickAttribute = ([newAttributeSelected]: string[]) => {
    setAttributeSelected(attributes[newAttributeSelected]);
    openAttributeValues();
  };

  const handleOnCloseAttributeValues = () => {
    setAttributeSelected(undefined);
    closeAttributeValues();
  };

  const handleOnChangeAttribute = (
    attribute: Attribute,
    prevAttribute?: Attribute,
  ) => {
    const attributeName = attribute.name[language] || '';
    const prevAttributeName = prevAttribute?.name[language] || '';

    if (prevAttribute && attributeName !== prevAttributeName) {
      const newAttributes = { ...attributes };
      delete newAttributes[prevAttributeName];
      newAttributes[attributeName] = attribute;
      return onChange(newAttributes);
    }

    onChange({
      ...attributes,
      [attributeName]: attribute,
    });
  };

  const handleOnRemoveAttribute = (attribute: Attribute) => {
    onRemoveAttribute(attribute);
    setAttributeSelected(undefined);
  };

  const onRemoveAttribute = (attribute: Attribute) => {
    const newAttributes = Object.keys(attributes).reduce((acc, key) => {
      if (key !== attribute.name[language]) {
        acc[key] = attributes[key];
        return acc;
      }
      return acc;
    }, {});

    onChange(newAttributes);
  };

  useEffect(() => {
    const hasVariantWithAttribute = Object.keys(attributes).length > 0;
    if (!hasVariantWithAttribute && isShow) {
      openAttributeValues();
    }
  }, [attributes, openAttributeValues, isShow]);

  return (
    <ModalAside
      isOpen={isShow}
      onDidDismiss={onClose}
      headerContent={t('products.variants.title')}
    >
      <InteractiveList
        addItemLabel={
          !disabledAdd ? t('products.variants.addProperty') : undefined
        }
        onClickAddItem={!disabledAdd ? openAttributeValues : () => null}
        onChange={handleOnClickAttribute}
        options={createInteractiveOptions(attributes)}
        mode="action"
      />
      <AttributeValuesModal
        isShow={isShowAttributeValues}
        attribute={attributeSelected}
        attributes={attributes}
        language={language}
        onChange={handleOnChangeAttribute}
        onRemove={handleOnRemoveAttribute}
        onClose={handleOnCloseAttributeValues}
      />
    </ModalAside>
  );
}

export default AttributesModal;
