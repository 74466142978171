/* eslint-disable max-statements */
import { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router';
import { PickupPointRequestDto } from '@tiendanube/common';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import { useToastProgress } from 'App/components/ToastProgressContext';
import { useNavegate } from 'App/hooks';
import { useAsyncFunc, useForm, useToast } from 'commons/hooks';
import { useLocations } from 'domains/Shipping/Locations/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { PickupForm } from '../../components';
import { validationSchemaFirsStep } from '../../components/PickupForm/validationSchemaFirstStep';
import { validationSchemaSecondStep } from '../../components/PickupForm/validationSchemaSecondStep';
import usePickupPoints from '../../hooks/usePickupPoints';

function EditPickupPointPage(): JSX.Element {
  const { scrollToTop } = useLayoutScroll();
  const t = useTranslationShipping(
    'deliveryMethods.pickupPoints.pickup.toast.edit',
  );
  const { id } = useParams<{ id: string }>();
  const [step, setStep] = useState(1);
  const {
    fetchPickupPoints,
    otherPickupPoints,
    updatePickupPoint,
    pickupPointDetails,
    fetchPickupPoint,
    isErrorList,
    isErrorDetail,
  } = usePickupPoints();
  const { fetchLocations, isError, locations } = useLocations();
  const { goBack } = useNavegate();

  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();

  const handleChangeStep = (step: number) => {
    setStep(step);
    scrollToTop();
  };

  const [handleUpdatePickupPoint, isSaving] = useAsyncFunc<
    PickupPointRequestDto,
    Promise<void>
  >(
    async (data) => {
      if (data) {
        addToastProgress({
          label: t('loading'),
        });
        await updatePickupPoint({
          id: id,
          payload: data,
        });
      }
    },
    () => {
      closeToastProgress();
      addToast({
        label: t('success'),
        appearance: 'success',
      });
      goBack();
    },
    () => {
      closeToastProgress();
      addToast({
        label: t('error'),
        appearance: 'danger',
      });
    },
  );

  const initialValuesForForm = useMemo(
    () => ({
      ...pickupPointDetails,
      status:
        pickupPointDetails.locations.length === 0
          ? true
          : pickupPointDetails.status,
    }),
    [pickupPointDetails],
  );

  const _validationSchemaFirsStep = useMemo(
    () => validationSchemaFirsStep([...otherPickupPoints, ...locations]),
    [locations, otherPickupPoints],
  );

  const onRetryError = () => {
    fetchLocations();
    fetchPickupPoints();
    fetchPickupPoint(id);
  };

  useEffect(() => {
    fetchLocations();
    fetchPickupPoints();
    fetchPickupPoint(id);
  }, [fetchLocations, fetchPickupPoint, fetchPickupPoints, id]);

  const {
    errors,
    values,
    isDirty,
    handleChange,
    handleOnSubmit,
    setFieldValue,
  } = useForm<PickupPointRequestDto>({
    initialValues: initialValuesForForm,
    validationSchema:
      step === 1 ? _validationSchemaFirsStep : validationSchemaSecondStep,
    onSubmit: step === 1 ? () => handleChangeStep(2) : handleUpdatePickupPoint,
  });

  return (
    <PickupForm
      step={step}
      type="pickup"
      isSaving={isSaving}
      errors={errors}
      values={values}
      isDirty={isDirty}
      isError={isError || isErrorDetail || isErrorList}
      handleSave={handleOnSubmit}
      handleChange={handleChange}
      handleChangeStep={handleChangeStep}
      setFieldValue={setFieldValue}
      onRetryError={onRetryError}
    />
  );
}

export default EditPickupPointPage;
