import { Box, Button, Popover, Text } from '@nimbus-ds/components';
import {
  AccountMenuContent,
  NewAccountMenuContent,
} from 'App/components/Menu/components/AccountMenu/components';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { useGetTags } from 'domains/Auth/hooks';
import Avatar from '../Avatar';

interface ProfileMenuProps {
  name: string;
  showPopover: boolean;
  onShowPopover: (status: boolean) => void;
  onAccountClick: () => void;
}

function ProfileMenu({
  name,
  showPopover,
  onAccountClick,
  onShowPopover,
}: ProfileMenuProps) {
  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);
  return (
    <Popover
      content={
        <Box display="flex" flexDirection="column" width="100%">
          {isRevampMenu ? <NewAccountMenuContent /> : <AccountMenuContent />}
        </Box>
      }
      arrow={false}
      padding="none"
      width="18rem"
      position="bottom-end"
      visible={showPopover}
      onVisibility={onShowPopover}
    >
      <Button
        appearance="transparent"
        data-style="button-override"
        onClick={onAccountClick}
      >
        <Avatar name={name} />
        <Box maxWidth="270px">
          <Text
            fontWeight="medium"
            color="neutral-textHigh"
            lineClamp={1}
            wordBreak="break-all"
          >
            {name}
          </Text>
        </Box>
      </Button>
    </Popover>
  );
}

export default ProfileMenu;
