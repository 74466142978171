import { Checkbox, Input, Link, Table } from '@nimbus-ds/components';

function BusinessHourItemSkeleton(): JSX.Element {
  return (
    <Table.Row backgroundColor="neutral-background">
      <Table.Cell>
        <Checkbox.Skeleton />
      </Table.Cell>
      <Table.Cell>
        <Input.Skeleton />
      </Table.Cell>
      <Table.Cell>
        <Input.Skeleton />
      </Table.Cell>
      <Table.Cell>
        <Link.Skeleton />
      </Table.Cell>
    </Table.Row>
  );
}

export default BusinessHourItemSkeleton;
