import { Grid } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import FeatureValue from '../../../FeatureValue';
import './ComparisonTableMobileRow.scss';

interface ComparisonTableMobileRowProps {
  feature: string;
  firstValue: string | number | boolean;
  secondValue: string | number | boolean;
  bold?: boolean;
}

function ComparisonTableMobileRow({
  feature,
  firstValue,
  secondValue,
  bold,
}: ComparisonTableMobileRowProps): JSX.Element {
  return (
    <div className="stratus--comparison__plan__row">
      <Grid.Row>
        <Grid.Column sm={3} md={3} lg={3}>
          <Text size="caption">{feature}</Text>
        </Grid.Column>
        <Grid.Column sm={3} md={3} lg={3}>
          <div className="stratus--comparison__plan__row__options">
            <Grid spacing="none">
              <Grid.Row>
                <Grid.Column sm={3} md={3} lg={3}>
                  <FeatureValue value={firstValue} bold={bold} size="caption" />
                </Grid.Column>
                <Grid.Column sm={3} md={3} lg={3}>
                  <FeatureValue
                    value={secondValue}
                    bold={bold}
                    size="caption"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Grid.Column>
      </Grid.Row>
    </div>
  );
}

export default ComparisonTableMobileRow;
