import { TFunction } from 'i18next';
import {
  InterfaceNameValue,
  InterfaceSelectOptionGroup,
  Label,
  Select,
  Stack,
  Text,
} from '@tiendanube/components';

interface AlterationItemProps {
  columnName: string;
  options?: InterfaceSelectOptionGroup[];
  value: string;
  isBadColumn: boolean;
  onChange: ({ name, value }: InterfaceNameValue) => void;
  t: TFunction;
}

function AlterationItem({
  columnName,
  options = [],
  value,
  isBadColumn,
  onChange,
  t,
}: AlterationItemProps): JSX.Element {
  return (
    <Stack column align="stretch">
      <Stack.Item fill>
        <Stack column align="stretch" spacing="none">
          <Stack>
            <Stack.Item fill>
              <Text block size="caption">
                {t('columnLabel')}
              </Text>
            </Stack.Item>
            {isBadColumn && (
              <Label
                id={`label-${columnName}`}
                appearance="warning"
                label={t('checkRequired')}
              />
            )}
          </Stack>
          <Text block>{columnName}</Text>
        </Stack>
      </Stack.Item>
      <Select
        name={columnName}
        label={t('interpretAs')}
        options={options}
        value={value}
        onChange={onChange}
      />
    </Stack>
  );
}

export default AlterationItem;
