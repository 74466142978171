import { useCallback, useMemo } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { CATALOG_HIDING_VARIANTS } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { useAppDispatch } from 'App/store';
import { useResponsive } from 'commons/components';
import { MAX_ITEMS_PER_PAGE } from 'commons/constants';
import {
  InventoryFiltersInterface,
  StockChange,
} from 'domains/Catalog/Inventory/inventoryServices';
import {
  getInventoryProducts,
  getMoreInventoryProducts,
  editStock as editStockAction,
  applyStockChange,
} from 'domains/Catalog/Inventory/inventorySlice';
import {
  getInventory as getInventorySelector,
  getTotalInventoryProducts,
} from 'domains/Catalog/Inventory/inventorySlice/inventorySelectors';
import useInventoryAppliedFilters from '../useInventoryAppliedFilters';

function useInventory() {
  const dispatch = useAppDispatch();
  const {
    isLoading,
    isSuccess,
    isError,
    ids: productIds,
  } = useSelector(getInventorySelector);
  const productCount = useSelector(getTotalInventoryProducts);
  const { filters, hasFilters, applyFilters } = useInventoryAppliedFilters();
  const { isMobile } = useResponsive();

  const [hasHidingVariants] = useHasTags([CATALOG_HIDING_VARIANTS]);

  const getInventoryProductsVersion = useMemo(
    () => ({ version: hasHidingVariants ? '2' : '1' }),
    [hasHidingVariants],
  );

  const loadInventory = useCallback(async () => {
    if (filters.locationId !== undefined) {
      isMobile && filters.page > 1
        ? dispatch(
            getMoreInventoryProducts({
              filters,
              ...getInventoryProductsVersion,
            }),
          )
        : dispatch(
            getInventoryProducts({ filters, ...getInventoryProductsVersion }),
          );
    }
  }, [dispatch, filters, getInventoryProductsVersion, isMobile]);

  const setFilters = useCallback(
    async (newFilters: InventoryFiltersInterface) =>
      applyFilters({ ...newFilters, page: 1 }),
    [applyFilters],
  );

  const setLocation = useCallback(
    async (locationId: string) =>
      applyFilters({ ...filters, locationId, page: 1 }),
    [filters, applyFilters],
  );

  const setPage = useCallback(
    async (page: number) => applyFilters({ ...filters, page }),
    [filters, applyFilters],
  );

  const setNextPage = useCallback(async () => {
    if (productIds.length !== filters.page * MAX_ITEMS_PER_PAGE) return;
    setPage(filters.page + 1);
  }, [filters.page, productIds.length, setPage]);

  const resetPage = useCallback(async () => setPage(1), [setPage]);

  const editStock = useCallback(
    async (params) => {
      const editedStock = await dispatch(editStockAction(params));
      return unwrapResult(editedStock);
    },
    [dispatch],
  );

  const setStock = useCallback(
    async (stockChange: StockChange) => dispatch(applyStockChange(stockChange)),
    [dispatch],
  );

  return {
    isLoading,
    isSuccess,
    isError,
    productCount,
    productIds,
    filters,
    hasFilters,
    loadInventory,
    setFilters,
    setLocation,
    setPage,
    setNextPage,
    resetPage,
    editStock,
    setStock,
  };
}

export default useInventory;
