import { Card, Checkbox, Input } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function PickupReadyTimeSkeleton(): JSX.Element {
  const t = useTranslationShipping(
    'deliveryMethods.pickupPoints.form.secondStep.estimatedDeliveryTime',
  );

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <Stack column align="stretch">
          <Stack align="stretch">
            <FormField label={t('from')}>
              <Input.Skeleton />
            </FormField>
            <FormField label={t('to')}>
              <Input.Skeleton />
            </FormField>
          </Stack>
          <Checkbox.Skeleton />
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default PickupReadyTimeSkeleton;
