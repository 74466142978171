import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import ShowByRole from 'App/components/ShowByRole';
import { useHandleMenuClick } from 'App/hooks';
import { accountRoutes } from 'domains/Account';
import { trackingMenuSecondaryUsersPermissionsClick } from '../tracking';

export function UsersMenu() {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem } = useHandleMenuClick();

  const handleClickNewAdmin = () => {
    trackingMenuSecondaryUsersPermissionsClick();
    handleClickItem();
  };

  return (
    <ShowByRole includeRoles={['full']}>
      <MenuButton
        as={Link}
        to={accountRoutes.users}
        onClick={handleClickNewAdmin}
        active={pathname.startsWith(accountRoutes.users)}
        label={t('settingsMenu.general.grants_and_notifications')}
      />
    </ShowByRole>
  );
}
