import { TelephoneIcon } from '@nimbus-ds/icons';
import { MenuButton } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { useLocation, Link } from 'react-router-dom';
import { useHandleMenuClick } from 'App/hooks';
import { THEMES_ROUTES } from '../../themesRoutes';

const path = THEMES_ROUTES.contactInfo;

export function ContactInfoMenu() {
  const { t } = useTranslation('common');
  const { pathname } = useLocation();
  const { handleClickItem } = useHandleMenuClick();

  return (
    <MenuButton
      as={Link}
      to={path}
      startIcon={TelephoneIcon}
      onClick={handleClickItem}
      active={pathname.startsWith(path)}
      label={t('settingsMenu.communication.infoContact')}
    />
  );
}
