import { useEffect, useState } from 'react';
import { Box, Card, Icon, Link } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { generatePath } from 'react-router-dom';
import { LocalShippingListResponseDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { ErrorScreen } from 'commons/components';
import { useModal, useToastStatus } from 'commons/hooks';
import { PARTNER_APPS_ROUTES } from 'domains/PartnersApps/partnersAppsRoutes';
import useDeleteActiveShipping from 'domains/Shipping/DeliveryMethods/hooks/useDeleteActiveShipping';
import { DELIVERY_METHODS_ROUTES } from 'domains/Shipping/DeliveryMethods/shippingRoutes';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  ActiveShippingMethodsList,
  AddActiveShippingMethodsModal,
} from './components';
import { ActiveShippingMethodsInterface } from './components/ActiveShippingMethodsList/ActiveShippingMethodsList';
import { NATIVE_SHIPPING_METHODS_EDIT_LINKS } from './constants';
import useActiveShippingMethods from './useActiveShippingMethods';
import useGetNuvemEnvioConfigs from '../../../hooks/useGetNuvemEnvioConfigs';
import OnboardingNuvemEnvioModal from '../../DeliveryMethodsPage/components/OnboardingNuvemEnvioModal';
import DeleteShippingModal from '../DeleteShippingModal';

function ActiveShippingMethodsCard() {
  const t = useTranslationShipping();
  const { goTo } = useNavegate();
  const {
    activeShippingMethods,
    isLoading,
    getActiveShippingMethods,
    isError,
  } = useActiveShippingMethods();
  const { status, deleteActiveShipping } = useDeleteActiveShipping();
  const { getQueryParam } = useNavegate();
  const isFromOnboarding =
    getQueryParam('from') === 'onboarding' && !!activeShippingMethods?.length;

  const [
    isShowAddActiveShippingMethodsModal,
    openAddActiveShippingMethodsModal,
    closeAddActiveShippingMethodsModal,
  ] = useModal(isFromOnboarding);

  const [isOpen, openModal, closeModal] = useModal();

  const { fetchNuvemEnvioConfigs, nuvemEnvioConfigs } =
    useGetNuvemEnvioConfigs();

  const [methodToDelete, setMethodToDelete] = useState<Pick<
    ActiveShippingMethodsInterface,
    'id' | 'isNative'
  > | null>(null);

  const handleOnDelete = (
    method: Pick<ActiveShippingMethodsInterface, 'isNative' | 'id'>,
  ) => {
    openModal();
    setMethodToDelete(method);
  };

  const handleConfirmDelete = () => {
    if (methodToDelete) {
      deleteActiveShipping(methodToDelete);
      setMethodToDelete(null);
    }
  };

  const handleOnEdit = (method: LocalShippingListResponseDto) => {
    const nativeEditLink = NATIVE_SHIPPING_METHODS_EDIT_LINKS[method.code];
    if (nativeEditLink) goTo(nativeEditLink);
    else {
      if (
        nuvemEnvioConfigs.newModalitiesScreenEnabled &&
        method.code === 'nuvem-envio'
      ) {
        goTo(
          generatePath(
            `${PARTNER_APPS_ROUTES.nuvemenvioConfigurations}/carrier`,
          ),
        );
      } else {
        goTo(
          generatePath(DELIVERY_METHODS_ROUTES.carriersDetail, {
            id: method.id,
          }),
        );
      }
    }
  };

  useEffect(() => {
    if (!activeShippingMethods) getActiveShippingMethods();

    if (activeShippingMethods?.length) {
      if (
        activeShippingMethods.some((method) =>
          method.code.includes('nuvem-envio'),
        )
      ) {
        fetchNuvemEnvioConfigs();
      }
    }
  }, [activeShippingMethods, getActiveShippingMethods, fetchNuvemEnvioConfigs]);

  useToastStatus({
    status,
    success: `${t(
      'deliveryMethods.activeShippingMethods.toast.successDelete',
    )}`,
    error: `${t('deliveryMethods.customShipping.toastError.elimination')}`,
    onSuccess: closeModal,
    onError: closeModal,
  });

  const isLoadingDelete = status === 'loading';

  return (
    <>
      <Card padding="none">
        <Card.Header
          padding="base"
          title={t('deliveryMethods.activeShippingMethods.title')}
        />
        <Card.Body padding="base">
          {isLoading && <Link.Skeleton />}
          {isError && !isLoading && (
            <ErrorScreen
              description={t(
                'deliveryMethods.activeShippingMethods.error.description',
              )}
              onRetry={getActiveShippingMethods}
            />
          )}
          {!isError && !isLoading && (
            <Link
              appearance="primary"
              textDecoration="none"
              onClick={openAddActiveShippingMethodsModal}
            >
              <Icon color="currentColor" source={<PlusCircleIcon />} />
              {t('deliveryMethods.activeShippingMethods.addShippingMdium')}
            </Link>
          )}
        </Card.Body>
        <Card.Footer>
          <Box padding="none" paddingBottom="1-5" width="100%">
            {isLoading && <ActiveShippingMethodsList.Skeleton />}
            {activeShippingMethods && activeShippingMethods.length > 0 && (
              <ActiveShippingMethodsList
                activeShippingMethods={activeShippingMethods}
                onEdit={handleOnEdit}
                onRemove={handleOnDelete}
              />
            )}
          </Box>
        </Card.Footer>
      </Card>
      {isShowAddActiveShippingMethodsModal && (
        <AddActiveShippingMethodsModal
          onClose={closeAddActiveShippingMethodsModal}
        />
      )}
      <DeleteShippingModal
        title={t('deliveryMethods.deleteShippingModal.title')}
        description={t('deliveryMethods.deleteShippingModal.message')}
        isOpen={isOpen}
        isLoading={isLoadingDelete}
        onClose={closeModal}
        onConfirmDelete={handleConfirmDelete}
      />
      {nuvemEnvioConfigs.showNuvemEnvioModal && <OnboardingNuvemEnvioModal />}
    </>
  );
}

export default ActiveShippingMethodsCard;
