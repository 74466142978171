import { Menu } from '@nimbus-ds/patterns';
import { QuickActionItemInterface } from '../types';

interface QuickActionsPopoverActionItemProps {
  quickAction: QuickActionItemInterface;
}
export function QuickActionsPopoverActionItem({
  quickAction,
}: Readonly<QuickActionsPopoverActionItemProps>) {
  return (
    <Menu.Button
      label={quickAction.children}
      onClick={quickAction.onClick}
      startIcon={quickAction.icon}
    />
  );
}
