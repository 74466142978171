import { Box, Link, Text } from '@nimbus-ds/components';
import { useNavegate } from 'App/hooks';
import { CurrencyPrice } from 'commons/components';
import { dateFormat, Format } from 'commons/utils/date';
import useGetStorePaymentDiscount from 'domains/Auth/hooks/useGetStorePaymentDiscount';
import { billingRoutes } from 'domains/Billing';
import { useFrequencyTranslation } from 'domains/Billing/Checkout/hooks';
import { ChargePeriodDetail } from 'domains/Billing/Checkout/pages/PlansAndSubscriptions/components';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { ChargeDisplayInterface } from '../../PaymentDataContent';

interface ChargeDataRowsProps {
  charge: ChargeDisplayInterface;
}

function ChargeDataRows({
  charge: {
    recurringFrequency,
    recurringInterval,
    explanation,
    description,
    amountCurrency,
    amountValue,
    amountBaseValue,
    appliedDiscounts,
    fromDate,
    toDate,
    conceptCode,
    isProportional,
  },
}: ChargeDataRowsProps) {
  const t = useTranslationBilling('checkout.paymentDataContent');
  const storePaymentDiscount = useGetStorePaymentDiscount();
  const { getFrequencyDescriptor } = useFrequencyTranslation();
  const { pathname } = useNavegate();

  const shouldShowDiscount =
    conceptCode === 'plan-cost' &&
    !!storePaymentDiscount &&
    pathname === billingRoutes.plansAndSubscriptions;

  const subscriptionText =
    recurringInterval && recurringFrequency
      ? `${t('subscription')} ${getFrequencyDescriptor({
          recurringFrequency,
          recurringInterval,
        })}`
      : undefined;

  const getDiscountDescription = (discount) =>
    discount.description ?? t('discountByRate', { rate: discount.rate });

  const isProportionalPeriod = isProportional && toDate && fromDate;

  return (
    <Box display="flex" flexDirection="column" gap="4">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" flexDirection="column" gap="1">
          <Text color="primary-textHigh">{description}</Text>
          {isProportionalPeriod ? (
            <ChargePeriodDetail
              isProportional
              fromDate={fromDate}
              toDate={toDate}
            />
          ) : (
            !!subscriptionText &&
            appliedDiscounts?.length === 0 && (
              <Text fontSize="caption">{subscriptionText}</Text>
            )
          )}
          {!!explanation && (
            <Link
              as="a"
              target="_blank"
              href={explanation.link}
              fontSize="caption"
            >
              {explanation.message}
            </Link>
          )}
          {!!fromDate && !!toDate && (
            <Text fontSize="caption">
              {t('periodDescription', {
                fromDate: dateFormat(fromDate, Format.DD_MM_YY),
                toDate: dateFormat(toDate, Format.DD_MM_YY),
              })}
            </Text>
          )}
        </Box>
        <CurrencyPrice
          price={amountBaseValue ?? amountValue}
          currency={amountCurrency}
        />
      </Box>
      {(appliedDiscounts ?? []).map((discount, index) => (
        <Box
          key={index}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box>
            <Text color="success-textLow">
              {getDiscountDescription(discount)}
            </Text>
            {!!subscriptionText && (
              <Text fontSize="caption">
                {discount.type === 'store_discount'
                  ? t('recurringFirstPayment')
                  : subscriptionText}
              </Text>
            )}
          </Box>
          <CurrencyPrice
            preFix="-"
            price={discount.amountValue}
            currency={discount.amountCurrency}
            appearance="success"
          />
        </Box>
      ))}
      {shouldShowDiscount && !appliedDiscounts?.length && (
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Text color="success-textLow">
            {t('discount', { discount: storePaymentDiscount.percentage })}
          </Text>
          <CurrencyPrice
            preFix="-"
            price={(amountValue * storePaymentDiscount.percentage) / 100}
            currency={amountCurrency}
            appearance="success"
          />
        </Box>
      )}
    </Box>
  );
}

export default ChargeDataRows;
