import { DataTable, IconButton } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';

const { Cell, Row } = DataTable;

function PagoNubeDataTableRowSkeleton(): JSX.Element {
  return (
    <Row id="1" align="flex-start">
      <Cell grow={4}>
        <Text.Skeleton />
      </Cell>
      <Cell grow={1}>
        <IconButton.Skeleton />
      </Cell>
    </Row>
  );
}

export default PagoNubeDataTableRowSkeleton;
