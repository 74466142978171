import { Box, Table } from '@nimbus-ds/components';
import { ErrorScreen } from 'commons/components';
import { AppsListDesktopHeader, AppsListDesktopRow } from './components';
import AppsListDesktopSkeleton from './components/AppsListDesktopSkeleton';
import './AppsListDesktop.scss';

function AppsListDesktop({ apps, isLoading, isError, refetch }) {
  if (isLoading) return <AppsListDesktopSkeleton />;
  if (isError || !apps) return <ErrorScreen onRetry={refetch} />;

  return (
    <Box flexDirection="column" gap="2" display="flex">
      <div className="stratus--apps-list-page-table">
        <Table>
          <AppsListDesktopHeader />
          <Table.Body>
            {apps.map((app, index) => (
              <AppsListDesktopRow
                app={app}
                index={index}
                key={`apps-list-item-${app.id}`}
              />
            ))}
          </Table.Body>
        </Table>
      </div>
    </Box>
  );
}

export default AppsListDesktop;
