import { BaseCard, Button, Title } from '@tiendanube/components';
import { CheckIcon, ExternalLinkIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import { ListItem } from 'commons/components';
import Link from 'commons/components/LegacyLink';
import Stack from 'commons/components/Stack';
import { OCA_HOW_IT_WORKS_LINK } from 'commons/constants';
import { SHIPPING_METHODS_BASE_ROUTE } from 'domains/Shipping/DeliveryMethods/shippingRoutes';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function SuccessCard(): JSX.Element {
  const t = useTranslationShipping();
  const { goTo } = useNavegate();

  const goToDeliveryMethods = () => goTo(SHIPPING_METHODS_BASE_ROUTE);

  const title = t('oca.config.succesCard.title');
  const body = t('oca.config.succesCard.body');
  const link = t('oca.config.succesCard.link');

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title type="h3">{title}</Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch">
          <ListItem icon={CheckIcon} content={body} />
          <Link
            appearance="primary"
            icon={ExternalLinkIcon}
            iconPosition="end"
            href={OCA_HOW_IT_WORKS_LINK}
          >
            {link}
          </Link>
        </Stack>
      </BaseCard.Body>
      <BaseCard.Footer>
        <Button appearance="primary" onClick={goToDeliveryMethods}>
          Entendí
        </Button>
      </BaseCard.Footer>
    </BaseCard>
  );
}

export default SuccessCard;
