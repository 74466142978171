import { useNavegate } from 'App/hooks';
import { useResponsive } from 'commons/components';
import { ACCOUNT_ROUTES } from 'domains/Account/accountRoutes';
import { useRoles } from 'domains/Auth/authSlice/useRoles';
import { BILLING_ROUTES } from 'domains/Billing/billingRoutes';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import { INVOICES_ROUTES } from 'domains/Billing/Invoices';
import { TRANSACTION_FEES_ROUTES } from 'domains/Billing/TransactionFees/transactionFeesRoutes';
import { THEMES_ROUTES } from 'domains/Online/Themes/themesRoutes';
import { rolesForSettingsRevamp } from '../components/MainMenu/MainMenu';

const PATH = {
  settings: {
    base: '/settings',
    exceptions: [
      ACCOUNT_ROUTES.users,
      ACCOUNT_ROUTES.businessData,
      BILLING_ROUTES.plans,
      CHECKOUT_ROUTES.plansAndSubscriptions,
      THEMES_ROUTES.contactInfo,
      INVOICES_ROUTES.invoicesList,
      TRANSACTION_FEES_ROUTES.transactionFees,
      BILLING_ROUTES.coupons,
    ],
  },
  account: {
    base: '/account',
    exceptions: [],
  },
};

const exceptionsMap = new Map();
Object.entries(PATH).forEach(([_, { exceptions, base }]) => {
  exceptions.forEach((exception) => {
    exceptionsMap.set(exception, base);
  });
});

export function useMenuOptionsVisibility() {
  const { pathname } = useNavegate();
  const { isMobile: isMobileResponsive } = useResponsive();
  const { validateRoles } = useRoles();

  const isPathInSection = (section) => {
    const { base } = section;

    const exceptionKey = Array.from(exceptionsMap.keys()).find(
      (exception) =>
        pathname === exception || pathname.startsWith(`${exception}/`),
    );
    if (exceptionKey) {
      return exceptionsMap.get(exceptionKey) === section.base;
    }

    return pathname.startsWith(base) || pathname.startsWith(`${base}/`);
  };

  const isVisibleSettings = isPathInSection(PATH.settings);
  const isVisibleAccount = isPathInSection(PATH.account);

  return {
    isVisibleAccount: isVisibleAccount && isMobileResponsive,
    isVisibleSettings:
      isVisibleSettings && validateRoles(rolesForSettingsRevamp),
  };
}
