import { Icon, Button } from '@nimbus-ds/components';
import { InterfaceButton } from '@tiendanube/components';
import { ChevronLeftIcon } from '@tiendanube/icons';

import './BackNavigation.scss';

export type BackNavigationProps = Pick<InterfaceButton, 'children' | 'onClick'>;

function BackNavigation({
  children,
  onClick,
}: BackNavigationProps): JSX.Element {
  return (
    <div className="stratus--page-navbar__back">
      <Button
        key={`backNavigation-${0}`}
        onClick={onClick}
        appearance="transparent"
      >
        <Icon source={<ChevronLeftIcon />} color="neutral-textLow" />
        {children}
      </Button>
    </div>
  );
}

export default BackNavigation;
