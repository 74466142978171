import { CombinedState } from '@reduxjs/toolkit';
import { RootStateType } from 'App/store';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { TablePricesInterface } from '../types';

export const getTablePricesState = (
  state: RootStateType,
): CombinedState<TablePricesInterface> => state.customers.tablePrices;

export const getTablePricesData = createSimpleSelector(
  [getTablePricesState],
  (state) => state.tablePrices.data,
);

export const getTablePricesStatus = createSimpleSelector(
  [getTablePricesState],
  (state) => convertStatusTypeToObject(state.tablePrices.status),
);
