import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { dateFormat, Format } from 'commons/utils/date';
import { useGetChargeDetailsQuery } from 'domains/Billing/Checkout/CheckoutApi';
import { useExpirationDate } from 'domains/Billing/Checkout/hooks';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';

interface ExtraFeeDetailProps {
  chargeId: string;
}

export default function ExtraFeeDetail({ chargeId }: ExtraFeeDetailProps) {
  const { data: charge } = useGetChargeDetailsQuery(chargeId);
  const formatCurrency = useFormatCurrency();
  const t = useTranslationBilling(
    'checkout.conceptDetailContent.extraFeeDetail',
  );
  const { expirationDate } = useExpirationDate(
    charge?.conceptCode,
    charge?.metadata?.appId,
  );

  if (!charge) return null;

  function Row({ children }) {
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        {children}
      </Box>
    );
  }

  return (
    <Card>
      <Box display="flex" flexDirection="column" gap="3">
        <Title as="h3">{t('title')}</Title>
        <Text>{charge.description}</Text>
        <Box display="flex" flexDirection="column" gap="2">
          <Row>
            <Text fontSize="highlight" fontWeight="bold">
              {t('cost')}
            </Text>
            <Text fontSize="highlight" fontWeight="bold">
              {formatCurrency(charge.amountBaseValue, charge.amountCurrency)}
            </Text>
          </Row>
          <Row>
            <Text>{t('period')}</Text>
            <Text>
              {`${dateFormat(charge.fromDate, Format.DD_MM_YY)} - ${dateFormat(
                charge.toDate,
                Format.DD_MM_YY,
              )}`}
            </Text>
          </Row>
          {!!expirationDate && (
            <Row>
              <Text>{t('expirationDate')}</Text>
              <Text>
                {dateFormat(
                  expirationDate.toISOString(),
                  Format.DD_MM_YY,
                  'UTC',
                )}
              </Text>
            </Row>
          )}
        </Box>
      </Box>
    </Card>
  );
}
