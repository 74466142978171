import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import {
  clearCustomerTablePrices,
  getCustomerTablePrices,
} from 'domains/Customers/CustomerTablePrices/customerTablePricesSlice';
import {
  getCustomerTablePricesData,
  getCustomerTablePricesStatus,
} from 'domains/Customers/CustomerTablePrices/customerTablePricesSlice/customerTablePricesSelectors';

export default function useCustomerTablePrices(id?: string) {
  const dispatch = useAppDispatch();

  const customerTablePricesData = useSelector(getCustomerTablePricesData);
  const customerTablePricesStatus = useSelector(getCustomerTablePricesStatus);

  const fetchCustomerTablePrices = useCallback(() => {
    if (id) {
      dispatch(getCustomerTablePrices(id));
    }
  }, [dispatch, id]);

  const resetCustomerTablePrices = useCallback(() => {
    dispatch(clearCustomerTablePrices());
  }, [dispatch]);

  return {
    customerTablePricesData,
    customerTablePricesStatus,
    fetchCustomerTablePrices,
    resetCustomerTablePrices,
  };
}
