import { ExternalInvoicesResponseDto } from '@tiendanube/common';
import { DataList } from '@tiendanube/components';
import LeftCell from './LeftCell';
import RightCell from './RightCell';

const { Row } = DataList;

type PagoNubeInvoiceHistoryDataTableRowProps = ExternalInvoicesResponseDto;

function PagoNubeInvoiceHistoryDataTableRow({
  id,
  description,
  month,
}: PagoNubeInvoiceHistoryDataTableRowProps) {
  return (
    <Row id={id} key={id}>
      <LeftCell month={month} description={description} />
      <RightCell invoiceId={id} />
    </Row>
  );
}

export default PagoNubeInvoiceHistoryDataTableRow;
