import { Box, Button, Card, Spinner, Title } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { CheckoutPaymentMethod } from '@tiendanube/common';
import { ErrorScreen } from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import { trackingBillingPayOrderCheckoutContinueClick } from 'domains/Billing/Checkout/tracking';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { PaymentOption } from './components';
import { usePaymentSelectionForPayOrder } from './hooks';
import PaymentSelectionForPayOrderSkeleton from './Skeleton';
import { getIconForPaymentMethod } from '../../../utils';

interface PaymentSelectionForPayOrderProps {
  paymentMethodSelected: CheckoutPaymentMethod;
  setPaymentMethodSelected: (method: CheckoutPaymentMethod) => void;
}

function PaymentSelectionForPayOrder({
  setPaymentMethodSelected,
  paymentMethodSelected,
}: Readonly<PaymentSelectionForPayOrderProps>) {
  const t = useTranslationBilling(
    'checkout.choosePaymentMethodForPayOrder.paymentSelection',
  );

  const {
    payWithMethod,
    availablePaymentMethods,
    isLoading,
    isError,
    preferenceStatus,
    preferenceLoading,
    onFinish,
    mainConcept,
    retryLoad,
  } = usePaymentSelectionForPayOrder();

  useToastStatus({
    status: preferenceStatus,
    error: t('error.paymentError'),
    onSuccess: onFinish,
    onError: onFinish,
  });

  const onContinue = () => {
    trackingBillingPayOrderCheckoutContinueClick(
      paymentMethodSelected,
      mainConcept,
    );
    payWithMethod(paymentMethodSelected);
  };

  if (isLoading) return <PaymentSelectionForPayOrderSkeleton />;
  if (isError)
    return <ErrorScreen description={t('error.title')} onRetry={retryLoad} />;

  return (
    <Card padding="none">
      <Box padding="4" paddingBottom="none">
        <Title as="h3">{t('title')}</Title>
      </Box>
      <DataList>
        {availablePaymentMethods?.map((method) => (
          <DataList.Row key={method}>
            <PaymentOption
              method={method}
              paymentMethodSelected={paymentMethodSelected}
              setPaymentMethodSelected={setPaymentMethodSelected}
              icon={getIconForPaymentMethod(method)}
              isLoading={preferenceLoading}
            />
          </DataList.Row>
        ))}
      </DataList>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        paddingLeft="3"
        paddingBottom="3"
      >
        <Button
          appearance="primary"
          onClick={onContinue}
          disabled={preferenceLoading}
        >
          {t('continue')}
          {preferenceLoading && <Spinner size="small" />}
        </Button>
      </Box>
    </Card>
  );
}

export default PaymentSelectionForPayOrder;
