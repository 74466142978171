import { ChangeEvent } from 'react';
import { Card, Input, Text } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface ShippingTermCardProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  timeMin?: string;
  timeMax?: string;
  error?: Partial<Record<string, string>>;
}
function ShippingTermCard({
  error,
  onChange,
  timeMin = '',
  timeMax = '',
}: ShippingTermCardProps) {
  const t = useTranslationShipping();
  const messageTimeMax = error?.deliveryTimeMax
    ? t(error.deliveryTimeMax)
    : t('deliveryMethods.customShipping.shippingTermCard.to.businessDays');

  const messageTimeMin = error?.deliveryTimeMin
    ? t(error.deliveryTimeMin)
    : t('deliveryMethods.customShipping.shippingTermCard.to.businessDays');

  return (
    <Card>
      <Card.Header
        title={t('deliveryMethods.customShipping.shippingTermCard.title')}
      />
      <Card.Body>
        <Stack column align="stretch">
          <Text>
            {t('deliveryMethods.customShipping.shippingTermCard.description')}
          </Text>
          <Stack wrap align="stretch">
            <Stack.Item fill>
              <FormField
                label={t(
                  'deliveryMethods.customShipping.shippingTermCard.from.title',
                )}
                helpText={messageTimeMin}
                showHelpText
              >
                <Input
                  type="number"
                  onChange={onChange}
                  value={timeMin}
                  name="deliveryTimeMin"
                  appearance={error?.deliveryTimeMin ? 'danger' : 'neutral'}
                />
              </FormField>
            </Stack.Item>
            <Stack.Item fill>
              <FormField
                label={t(
                  'deliveryMethods.customShipping.shippingTermCard.to.title',
                )}
                helpText={messageTimeMax}
                showHelpText
              >
                <Input
                  type="number"
                  onChange={onChange}
                  value={timeMax}
                  name="deliveryTimeMax"
                  appearance={error?.deliveryTimeMax ? 'danger' : 'neutral'}
                />
              </FormField>
            </Stack.Item>
          </Stack>
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default ShippingTermCard;
