import { useEffect, useMemo, useState } from 'react';
import { Country } from '@tiendanube/common/src/enums';
import { useGetCountry } from 'domains/Auth/hooks';
import {
  useGetRecurrentPaymentConcepts,
  usePayorderToPay,
} from 'domains/Billing/Checkout/hooks';

const COUNTRIES_WITH_TRANSACTION_FEES: Country[] = [Country.AR, Country.BR];

export function useIncludeTransactionFees() {
  const { conceptsToPay } = usePayorderToPay();
  const { isSuccess, recurrentPaymentForTransactionFeesActivated } =
    useGetRecurrentPaymentConcepts();

  const country = useGetCountry();

  const shouldShowCard = useMemo(() => {
    const isPayingPlan =
      conceptsToPay &&
      conceptsToPay.has('plan-cost') &&
      !conceptsToPay.has('transaction-fee');
    return (
      isSuccess &&
      !recurrentPaymentForTransactionFeesActivated &&
      isPayingPlan &&
      COUNTRIES_WITH_TRANSACTION_FEES.includes(country)
    );
  }, [
    country,
    isSuccess,
    recurrentPaymentForTransactionFeesActivated,
    conceptsToPay,
  ]);

  const [includeTransactionFee, setIncludeTransactionFee] = useState(true);

  useEffect(() => {
    shouldShowCard !== undefined && setIncludeTransactionFee(shouldShowCard);
  }, [shouldShowCard, setIncludeTransactionFee]);

  return {
    includeTransactionFee,
    setIncludeTransactionFee,
    shouldShowCard,
  };
}
