import { Redirect, Route, Switch } from 'react-router-dom';
import { Country } from '@tiendanube/common/src/enums';
import RouteByCountry from 'App/components/RouteByCountry';
import AddPhysicalLocationPage from './pages/AddPhysicalLocationPage';
import ConfigureCorreiosPage from './pages/ConfigureCorreiosPage';
import ConfigureOcaPage from './pages/ConfigureOcaPage';
import ContractCorreiosPage from './pages/ContractCorreiosPage';
import DeliveryMethodsPage from './pages/DeliveryMethodsPage';
import AddLocationAsPickupPage from './pages/DeliveryMethodsPage/components/PickupPoints/pages/AddLocationAsPickupPage';
import EditLocationAsPickupPage from './pages/DeliveryMethodsPage/components/PickupPoints/pages/EditLocationAsPickupPage';
import EditPickupPointPage from './pages/DeliveryMethodsPage/components/PickupPoints/pages/EditPickupPointPage';
import NewPickupPointPage from './pages/DeliveryMethodsPage/components/PickupPoints/pages/NewPickupPointPage';
import EditCarriersPage from './pages/EditCarriersPage';
import EditCustomShippingPage from './pages/EditCustomShippingPage';
import EditInternationalShippingPage from './pages/EditInternationalShippingPage';
import EditPhysicalLocations from './pages/EditPhysicalLocationPage';
import EditSelectorOcaPage from './pages/EditSelectorOcaPage';
import NewCustomShippingPage from './pages/NewCustomShippingPage';
import NewInternationalShippingPage from './pages/NewInternationalShippingPage';
import RequestOcaRegistrationPage from './pages/RequestOcaRegistrationPage';
import RequestOcaRegistrationSuccessPage from './pages/RequestOcaRegistrationSuccessPage';
import ShippingModalitiesCorreiosPage from './pages/ShippingModalitiesCorreiosPage';
import {
  SHIPPING_METHODS_BASE_ROUTE,
  DELIVERY_METHODS_ROUTES,
} from './shippingRoutes';

function DeliveryMethods(): JSX.Element {
  return (
    <Switch>
      <Route path={SHIPPING_METHODS_BASE_ROUTE} exact>
        <DeliveryMethodsPage />
      </Route>
      <Route path={DELIVERY_METHODS_ROUTES.physicalLocation} exact>
        <AddPhysicalLocationPage />
      </Route>
      <Route path={DELIVERY_METHODS_ROUTES.physicalLocationDetail} exact>
        <EditPhysicalLocations />
      </Route>
      <Route path={DELIVERY_METHODS_ROUTES.national} exact>
        <NewCustomShippingPage />
      </Route>
      <Route path={DELIVERY_METHODS_ROUTES.international} exact>
        <NewInternationalShippingPage />
      </Route>
      <Route path={DELIVERY_METHODS_ROUTES.nationalDetail} exact>
        <EditCustomShippingPage />
      </Route>
      <Route path={DELIVERY_METHODS_ROUTES.internationalDetail} exact>
        <EditInternationalShippingPage />
      </Route>
      <Route path={DELIVERY_METHODS_ROUTES.oca}>
        <ConfigureOcaPage />
      </Route>
      <Route path={DELIVERY_METHODS_ROUTES.ocaEditSelector}>
        <EditSelectorOcaPage />
      </Route>
      <Route path={DELIVERY_METHODS_ROUTES.ocaEditOptionals}>
        <ConfigureOcaPage optionals />
      </Route>
      <Route path={DELIVERY_METHODS_ROUTES.requestOcaRegistration} exact>
        <RequestOcaRegistrationPage />
      </Route>
      <Route path={DELIVERY_METHODS_ROUTES.requestOcaRegistrationSuccess} exact>
        <RequestOcaRegistrationSuccessPage />
      </Route>
      <Route path={DELIVERY_METHODS_ROUTES.carriersDetail} exact>
        <EditCarriersPage />
      </Route>
      <Route path={DELIVERY_METHODS_ROUTES.newPickupPoint} exact>
        <NewPickupPointPage />
      </Route>
      <Route path={DELIVERY_METHODS_ROUTES.editPickupPoint}>
        <EditPickupPointPage />
      </Route>
      <Route path={DELIVERY_METHODS_ROUTES.editLocationAsPickupPoint}>
        <EditLocationAsPickupPage />
      </Route>
      <Route path={DELIVERY_METHODS_ROUTES.locationAsPickupPoint}>
        <AddLocationAsPickupPage />
      </Route>
      <RouteByCountry
        allowedCountries={[Country.BR]}
        path={DELIVERY_METHODS_ROUTES.correios}
        exact
      >
        <ConfigureCorreiosPage />
      </RouteByCountry>
      <RouteByCountry
        allowedCountries={[Country.BR]}
        path={DELIVERY_METHODS_ROUTES.correiosModalities}
        exact
      >
        <ShippingModalitiesCorreiosPage />
      </RouteByCountry>
      <RouteByCountry
        allowedCountries={[Country.BR]}
        path={DELIVERY_METHODS_ROUTES.correiosContract}
        exact
      >
        <ContractCorreiosPage />
      </RouteByCountry>
      <Redirect from="*" to={SHIPPING_METHODS_BASE_ROUTE} />
    </Switch>
  );
}

export default DeliveryMethods;
