import { OrderDetailsAmountsDto } from '@tiendanube/common';
import { getDiscountText } from 'domains/Marketing/Promotions/utils/promotionUtils';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

const DRAFT_ORDER_DISCOUNT_COUPON_PREFIX = 'DRAFT-ORDER';
const POS_ORDER_DISCOUNT_COUPON_PREFIX = 'POS-ORDER-';

interface OrderDiscountsInterface {
  id: string;
  name: string;
  value: number;
  prefix?: string;
  appearance?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'danger'
    | 'light';
}

function useOrderDiscounts(
  amounts: OrderDetailsAmountsDto,
  paymentMethod?: string,
): OrderDiscountsInterface[] {
  const {
    discount,
    discountDetail,
    gatewayDiscount,
    couponDiscount,
    otherDiscounts,
    freeShippingDiscount,
  } = amounts;
  const t = useTranslationOrders();
  const symbolDiscountPrefix = t(
    'orderSummaryCard.tableAmounts.symbolDiscount',
  );

  const discounts: OrderDiscountsInterface[] = [];

  if (!!discount && discount > 0) {
    discounts.push({
      id: 'discount',
      name: freeShippingDiscount
        ? t('orderSummaryCard.tableAmounts.freeShippingDiscount')
        : discountDetail ?? t('orderSummaryCard.tableAmounts.discount'),
      value: discount,
      prefix: symbolDiscountPrefix,
      appearance: 'danger',
    });
  }

  if (!!discount && !freeShippingDiscount && discount < 0) {
    discounts.push({
      id: 'additionalShippingCost',
      name: t('orderSummaryCard.tableAmounts.aditionalCost'),
      value: -discount,
    });
  }

  if (!!gatewayDiscount && gatewayDiscount > 0) {
    discounts.push({
      id: 'gatewayDiscount',
      name: paymentMethod
        ? t('orderSummaryCard.tableAmounts.gatewayDiscount', {
            paymentMethod,
          })
        : t('orderSummaryCard.tableAmounts.gatewayDiscountDefault'),
      value: gatewayDiscount,
      prefix: symbolDiscountPrefix,
      appearance: 'danger',
    });
  }

  if (!!otherDiscounts && otherDiscounts.length > 0) {
    otherDiscounts.forEach((otherDiscount) => {
      discounts.push({
        id: otherDiscount.name,
        name: getDiscountText(otherDiscount, t),
        value: otherDiscount.amount,
        prefix: symbolDiscountPrefix,
        appearance: 'danger',
      });
    });
  }

  if (!!couponDiscount?.amount && couponDiscount.amount > 0) {
    const couponDiscountPOS = couponDiscount?.codes?.some((discountCode) =>
      discountCode.startsWith(POS_ORDER_DISCOUNT_COUPON_PREFIX),
    );
    const couponDiscountDraft = couponDiscount?.codes?.some((discountCode) =>
      discountCode.includes(DRAFT_ORDER_DISCOUNT_COUPON_PREFIX),
    );

    discounts.push({
      id: 'couponDiscount',
      name: couponDiscountPOS
        ? t('orderSummaryCard.tableAmounts.posCouponDiscount')
        : t('orderSummaryCard.tableAmounts.couponDiscount', {
            coupons: couponDiscountDraft
              ? t('orderSummaryCard.tableAmounts.couponDiscountDraft')
              : couponDiscount.codes?.join(' / '),
          }),
      value: couponDiscount.amount,
      prefix: symbolDiscountPrefix,
      appearance: 'danger',
    });
  }

  return discounts;
}

export default useOrderDiscounts;
