import { useCallback } from 'react';
import {
  Accordion,
  Box,
  Button,
  Card,
  Spinner,
  Title,
} from '@nimbus-ds/components';
import { useIsInAppBrowser } from 'App/hooks';
import { useToastStatus } from 'commons/hooks';
import { combineStatus } from 'commons/utils/combineStatus';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import useGetStorePaymentDiscount from 'domains/Auth/hooks/useGetStorePaymentDiscount';
import {
  useChargesToPay,
  usePaymentMethods,
} from 'domains/Billing/Checkout/hooks';
import { PaymentDataContent } from 'domains/Billing/Checkout/pages/components';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { useAdvancePlanCharge } from '../../hooks';
import SummaryError from '../../SummaryError';
import SummarySkeleton from '../../SummarySkeleton';

function SummaryForAdvancingCharge() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.summary.advanceCharge',
  );
  const {
    status: paymentMethodsStatus,
    refreshPaymentMethods,
    firstRecurrencyOptionAsCharge,
  } = usePaymentMethods();
  const formatCurrency = useFormatCurrency();
  const storePaymentDiscount = useGetStorePaymentDiscount();
  const { chargeTotalWithoutTaxes } = useChargesToPay(false);

  const isMobileDevice = useIsMobileDevice();
  const isInAppBrowserOpen = useIsInAppBrowser();
  const isWebVersion = !isMobileDevice && !isInAppBrowserOpen;

  const {
    handlePayAdvancedPlanCharge,
    isLoading: isCreatingAdvancedCharge,
    createStatus,
    setupStatus: pendingChargesStatus,
    retrySetup,
  } = useAdvancePlanCharge();

  const retryLoad = useCallback(() => {
    retrySetup();
    refreshPaymentMethods();
  }, [retrySetup, refreshPaymentMethods]);

  const { isLoading, isError } = combineStatus(
    paymentMethodsStatus,
    pendingChargesStatus,
  );

  useToastStatus({ status: createStatus, error: t('errorCreatingCharge') });

  if (isLoading) return <SummarySkeleton />;

  if (isError) return <SummaryError onClick={retryLoad} />;

  if (!firstRecurrencyOptionAsCharge?.amountBaseValue) return null;

  return (
    <Card padding="none">
      <Box gap="2" display="flex" flexDirection="column">
        <Accordion selectedDefault={storePaymentDiscount ? '0' : undefined}>
          <Accordion.Item index="0">
            <Accordion.Header borderTop="none">
              <Box width="100%">
                <Title as="h3" textAlign="left">
                  {`${t('title')}: ${formatCurrency(
                    chargeTotalWithoutTaxes(firstRecurrencyOptionAsCharge),
                    firstRecurrencyOptionAsCharge.amountCurrency,
                  )}`}
                </Title>
              </Box>
            </Accordion.Header>

            <Accordion.Body>
              <Box display="flex" flexDirection="column" gap="4" flexGrow="1">
                <PaymentDataContent charges={[firstRecurrencyOptionAsCharge]} />
              </Box>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {isWebVersion && (
          <Box paddingRight="4" paddingBottom="4" paddingLeft="4">
            <Card.Footer>
              <Button
                appearance="primary"
                disabled={isCreatingAdvancedCharge}
                onClick={handlePayAdvancedPlanCharge}
              >
                {isCreatingAdvancedCharge && (
                  <Spinner color="currentColor" size="small" />
                )}
                {t('goToPay')}
              </Button>
            </Card.Footer>
          </Box>
        )}
      </Box>
    </Card>
  );
}

export default SummaryForAdvancingCharge;
