import { FulfillmentPreferenceType as FulfillmentPreferenceTypeEnum } from '@tiendanube/common/src/enums';

export default function isShippingPickupPoint(
  fulfillmentPreferenceType: FulfillmentPreferenceTypeEnum,
  isShippablePickup?: boolean,
): boolean {
  return (
    fulfillmentPreferenceType === FulfillmentPreferenceTypeEnum.PICKUP &&
    isShippablePickup !== undefined &&
    !isShippablePickup
  );
}
