import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CustomersDetailsResponseDto,
  CustomersListResponseDto,
  CustomersOrdersResponseDto,
  ResultPaginationResponseDto,
  CustomersMessagesResponseDto,
} from '@tiendanube/common';
import { RootStateType } from 'App/store';
import { logout } from 'domains/Auth/authSlice';
import {
  getCustomersPagination,
  getCustomersFilters,
  getCustomerOrdersPagination,
} from './customersSelectors';
import {
  statusType,
  CustomersInterface,
  CustomerEntitiesType,
  CustomerEntityDetails,
  CustomerEntityMessages,
} from './types';
import customersServices, { CustomersFiltersType } from '../customersServices';
import { CustomerFormValuesInterface } from '../pages/CustomerFormPage/types';

const paginationDefault = {
  currentPage: 1,
  totalPages: 1,
  totalResults: 0,
  perPage: 20,
  nextPage: null,
};
const entityDetailsDefault: CustomerEntityDetails = {
  status: statusType.idle,
  data: {
    id: '0',
    name: '',
    email: '',
    phone: '',
    identification: '',
    note: '',
    removable: false,
    firstInteraction: '',
    active: false,
    isAnonymized: false,
    startedAnonymized: false,
    anonymizationRequestedAt: '',
    anonymizationAt: '',
    defaultAddress: {
      address: '',
      city: '',
      country: '',
      floor: '',
      locality: '',
      number: '',
      province: '',
      zipcode: '',
    },
    actions: [],
    businessName: '',
    tradeName: '',
    stateRegistration: '',
    fiscalRegime: '',
    businessActivity: '',
  },
};

const customerOrdersDefault = {
  status: statusType.idle,
  data: {
    pagination: paginationDefault,
    results: [],
  },
  ids: [],
};

const customerMessagesDefault: CustomerEntityMessages = {
  status: statusType.idle,
  entities: {},
  ids: [],
};

const importMaxLinesDefault = {
  status: statusType.idle,
  max: 20000,
};

const initialState: CustomersInterface = {
  status: statusType.idle,
  refreshStatus: statusType.idle,
  exportStatus: statusType.idle,
  exportToken: '',
  currentRequestID: '',
  ids: [],
  entities: {},
  entityDetails: entityDetailsDefault,
  customerOrders: customerOrdersDefault,
  customerMessages: customerMessagesDefault,
  importMaxLines: importMaxLinesDefault,
  pagination: paginationDefault,
  filters: {
    page: 1,
    q: '',
    sort: '',
  },
  sendEmail: statusType.idle,
  isEdited: false,
};

type ThunkStateType = { state: RootStateType };

export const getCustomersList = createAsyncThunk<
  { response: CustomersListResponseDto; filters: CustomersFiltersType },
  CustomersFiltersType,
  ThunkStateType
>(
  'customers/getCustomersList',
  async (filters) => {
    const response = await customersServices.getCustomers(filters);
    return { response, filters };
  },
  {
    condition: (filters, { getState }) => {
      const state = getState();
      const currentFilters = getCustomersFilters(state);
      if (
        filters.q === currentFilters.q &&
        state.customers.customers.status !== statusType.idle
      ) {
        return false;
      }
    },
  },
);

export const getMoreCustomersList = createAsyncThunk<
  {
    response: CustomersListResponseDto;
    filters: CustomersFiltersType;
  },
  undefined,
  ThunkStateType
>('customers/getMoreCustomersList', async (_, thunkApi) => {
  const state = thunkApi.getState();
  const pagination = getCustomersPagination(state);
  const filters = getCustomersFilters(state);

  if (!pagination.nextPage) {
    throw new Error('No valid fetch');
  }

  const newFilters: CustomersFiltersType = {
    ...filters,
    page: filters.page + 1,
  };
  const response = await customersServices.getCustomers(newFilters);

  return { response, filters: newFilters };
});

export const refreshCustomersList = createAsyncThunk<
  { response: CustomersListResponseDto; filters: CustomersFiltersType },
  CustomersFiltersType,
  ThunkStateType
>('customers/refreshCustomersList', async (filters) => {
  const response = await customersServices.getCustomers(filters);
  return { response, filters };
});

export const advancedSearchCustomers = createAsyncThunk<
  {
    response: CustomersListResponseDto;
    filters: CustomersFiltersType;
  },
  CustomersFiltersType,
  ThunkStateType
>('customers/advancedSearchCustomers', async (filters) => {
  const response = await customersServices.advancedSearch(filters);
  return { response, filters };
});

export const exportCustomers = createAsyncThunk(
  'customers/exportCustomers',
  customersServices.exportCustomers,
);

export const getCustomerById = createAsyncThunk<
  {
    customer: CustomersDetailsResponseDto;
    customerMessages: CustomersMessagesResponseDto[];
    customerOrders: ResultPaginationResponseDto<CustomersOrdersResponseDto[]>;
  },
  string
>('customers/getCustomerById', async (customerId) => {
  const [customerResponse, customerMessagesResponse, customerOrdersResponse] =
    await Promise.all([
      customersServices.getCustomerById(customerId),
      customersServices.getCustomerMessages(customerId),
      customersServices.getCustomerOrders(customerId),
    ]);

  return {
    customer: customerResponse,
    customerMessages: customerMessagesResponse,
    customerOrders: customerOrdersResponse,
  };
});

export const getCustomerOrdersList = createAsyncThunk<
  ResultPaginationResponseDto<CustomersOrdersResponseDto[]>,
  string,
  ThunkStateType
>(
  'customers/getCustomerOrdersList',
  async (customerId) => {
    const response = await customersServices.getCustomerOrders(customerId);
    return response;
  },
  {
    condition: (_, { getState }) => {
      const state = getState();
      if (state.customers.customers.customerOrders.status !== statusType.idle) {
        return false;
      }
    },
  },
);

export const getCustomerFormValues = createAsyncThunk<
  CustomerFormValuesInterface,
  string
>('customers/getCustomerFormValues', async (customerId) => {
  const getCustomerFormValuesResponse =
    await customersServices.getCustomerFormValues(customerId);
  return getCustomerFormValuesResponse;
});

export const addCustomer = createAsyncThunk<
  CustomersDetailsResponseDto,
  CustomerFormValuesInterface
>('customers/addCustomer', async (customerValues) => {
  const addCustomerResponse = await customersServices.addCustomer(
    customerValues,
  );
  return addCustomerResponse;
});

export const updateCustomer = createAsyncThunk<
  CustomersDetailsResponseDto,
  CustomerFormValuesInterface
>('customers/updateCustomer', async (customerValues) => {
  const updateCustomerResponse = await customersServices.updateCustomer(
    customerValues,
  );
  return updateCustomerResponse;
});

export const sendActivationMail = createAsyncThunk<void, string>(
  'customers/sendActivationMail',
  async (id) => {
    await customersServices.sendActivationMail(id);
  },
);

export const sendResetPasswordMail = createAsyncThunk<void, string>(
  'customers/sendResetPasswordMail',
  async (id) => {
    await customersServices.sendResetPasswordMail(id);
  },
);

export const exportCustomerData = createAsyncThunk<void, string>(
  'customers/exportCustomerData',
  async (id) => {
    await customersServices.exportCustomerData(id);
  },
);

export const deleteCustomer = createAsyncThunk<void, string>(
  'customers/deleteCustomer',
  async (id) => {
    await customersServices.deleteCustomer(id);
  },
);

export const anonymizeCustomer = createAsyncThunk<void, string>(
  'customers/anonymizeCustomer',
  async (id) => {
    await customersServices.anonymizeCustomer(id);
  },
);

export const updateCustomerNote = createAsyncThunk<
  CustomersDetailsResponseDto,
  {
    customerId: string;
    note: string;
  },
  ThunkStateType
>('customers/updateNote', async ({ customerId, note }) => {
  const productUpdated = await customersServices.updateCustomerNote(
    customerId,
    note,
  );

  return productUpdated;
});

export const getMoreCustomerOrdersList = createAsyncThunk<
  ResultPaginationResponseDto<CustomersOrdersResponseDto[]>,
  undefined,
  ThunkStateType
>(
  'customers/getMoreCustomerOrdersList',
  async (_, thunkApi) => {
    const state = thunkApi.getState();
    const pagination = getCustomerOrdersPagination(state);

    if (!pagination?.nextPage) {
      throw new Error('No valid fetch');
    }

    const response = await customersServices.getCustomerOrders(
      `${state.customers.customers.entityDetails.data.id}`,
      pagination.nextPage,
    );

    return response;
  },
  {
    condition: (_, { getState }) => {
      const state = getState();
      if (
        state.customers.customers.customerOrders.status === statusType.error ||
        state.customers.customers.customerOrders.status === statusType.loading
      ) {
        return false;
      }
    },
  },
);

export const getCustomerMessagesList = createAsyncThunk<
  CustomersMessagesResponseDto[],
  string,
  ThunkStateType
>(
  'customers/getCustomerMessages',
  async (customerId) => {
    const response = await customersServices.getCustomerMessages(customerId);
    return response;
  },
  {
    condition: (_, { getState }) => {
      const state = getState();
      if (state.customers.customers.customerOrders.status !== statusType.idle) {
        return false;
      }
    },
  },
);

export const getImportMaxLines = createAsyncThunk(
  'customers/import/getImportMaxLines',
  customersServices.getImportMaxLines,
);

export const refreshCustomerMessagesList = createAsyncThunk<
  CustomersMessagesResponseDto[],
  string,
  ThunkStateType
>(
  'customers/refreshCustomerMessages',
  async (id) => await customersServices.getCustomerMessages(id),
);

export const removeCustomerMessage = createAsyncThunk<void, string>(
  'customers/removeMessage',
  async (id) => {
    await customersServices.removeMessage(id);
  },
);

export const readCustomerMessage = createAsyncThunk<void, string>(
  'customers/readMessage',
  async (id) => {
    await customersServices.readMessage(id);
  },
);

export const unreadCustomerMessage = createAsyncThunk<void, string>(
  'customers/unreadMessage',
  async (id) => {
    await customersServices.unreadMessage(id);
  },
);

export const spamCustomerMessage = createAsyncThunk<void, string>(
  'customers/spamMessage',
  async (id) => {
    await customersServices.spamMessage(id);
  },
);

export const sendEmailAction = createAsyncThunk(
  'customers/sendEmail',
  customersServices.sendEmail,
);

const customers = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    init(state) {
      state.status = statusType.loading;
    },
    clearCustomerRefreshStatus(state) {
      state.refreshStatus = initialState.refreshStatus;
    },
    clearSendEmailActionStatus(state) {
      state.sendEmail = initialState.sendEmail;
    },
    clearExportStatus(state) {
      state.exportStatus = initialState.exportStatus;
    },
    clearCustomerIsEdited(state) {
      state.isEdited = false;
    },
    clearCustomerDetails(state) {
      state.entityDetails = initialState.entityDetails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout.fulfilled, (state) => {
      state = initialState;
      return state;
    });

    builder
      .addCase(getCustomersList.pending, (state, action) => {
        state.status = statusType.loading;
        state.ids = [];
        state.entities = {};
        state.currentRequestID = action.meta.requestId;
        return state;
      })
      .addCase(getCustomersList.fulfilled, (state, action) => {
        if (state.currentRequestID === action.meta.requestId) {
          const ids: string[] = [];
          state.entities = action.payload.response.results.reduce(
            (acc, customer) => {
              acc[customer.id.toString()] = customer;
              ids.push(customer.id.toString());
              return acc;
            },
            {} as CustomerEntitiesType,
          );
          state.ids = ids;
          state.pagination = action.payload.response.pagination;
          state.filters = action.payload.filters;
          state.status = statusType.success;
          return state;
        }
      })
      .addCase(getCustomersList.rejected, (state, action) => {
        state.status = statusType.error;
        state.filters = action.meta.arg;
        return state;
      });

    builder
      .addCase(getMoreCustomersList.pending, (state) => {
        state.status = statusType.loading;
        return state;
      })
      .addCase(getMoreCustomersList.fulfilled, (state, action) => {
        const ids: string[] = [];
        action.payload.response.results.forEach((customer) => {
          state.entities[customer.id] = customer;
          ids.push(customer.id.toString());
        });

        state.ids = state.ids.concat(ids);
        state.pagination = action.payload.response.pagination;
        state.filters = action.payload.filters;
        state.status = statusType.success;
        return state;
      })
      .addCase(getMoreCustomersList.rejected, (state) => {
        state.status = statusType.error;
        return state;
      });

    builder
      .addCase(refreshCustomersList.pending, (state, action) => {
        state.refreshStatus = statusType.loading;
        state.currentRequestID = action.meta.requestId;
        return state;
      })
      .addCase(refreshCustomersList.fulfilled, (state, action) => {
        if (state.currentRequestID === action.meta.requestId) {
          const ids: string[] = [];
          state.entities = action.payload.response.results.reduce(
            (acc, customer) => {
              acc[customer.id.toString()] = customer;
              ids.push(customer.id.toString());
              return acc;
            },
            {} as CustomerEntitiesType,
          );
          state.ids = ids;
          state.pagination = action.payload.response.pagination;
          state.filters = action.payload.filters;
          state.refreshStatus = statusType.success;
          return state;
        }
      })
      .addCase(refreshCustomersList.rejected, (state) => {
        state.refreshStatus = statusType.error;
        return state;
      });

    builder
      .addCase(advancedSearchCustomers.pending, (state, action) => {
        state.refreshStatus = statusType.loading;
        state.currentRequestID = action.meta.requestId;
        return state;
      })
      .addCase(advancedSearchCustomers.fulfilled, (state, action) => {
        if (state.currentRequestID === action.meta.requestId) {
          const ids: string[] = [];
          state.entities = action.payload.response.results.reduce(
            (acc, customer) => {
              acc[customer.id.toString()] = customer;
              ids.push(customer.id.toString());
              return acc;
            },
            {} as CustomerEntitiesType,
          );
          state.ids = ids;
          state.pagination = action.payload.response.pagination;
          state.filters = action.payload.filters;
          state.refreshStatus = statusType.success;
          return state;
        }
      })
      .addCase(advancedSearchCustomers.rejected, (state) => {
        state.refreshStatus = statusType.error;
        return state;
      });

    builder
      .addCase(exportCustomers.pending, (state, action) => {
        state.exportStatus = statusType.loading;
        state.currentRequestID = action.meta.requestId;
        return state;
      })
      .addCase(exportCustomers.fulfilled, (state, action) => {
        if (state.currentRequestID === action.meta.requestId) {
          state.exportStatus = statusType.success;
          state.exportToken = action.payload.token;
          return state;
        }
      })
      .addCase(exportCustomers.rejected, (state) => {
        state.exportStatus = statusType.error;
        return state;
      });

    builder
      .addCase(getCustomerById.pending, (state, action) => {
        state.entityDetails.status = statusType.loading;
        state.entityDetails.data = entityDetailsDefault.data;
        state.customerMessages = {
          status: statusType.loading,
          entities: {},
          ids: [],
        };
        state.customerOrders = {
          status: statusType.loading,
          data: {
            results: [],
            pagination: paginationDefault,
          },
          ids: [],
        };
        state.currentRequestID = action.meta.requestId;
        return state;
      })
      .addCase(getCustomerById.fulfilled, (state, action) => {
        if (state.currentRequestID === action.meta.requestId) {
          state.entityDetails.data = action.payload.customer;
          state.customerMessages = {
            status: statusType.success,
            entities: action.payload.customerMessages.reduce((acc, message) => {
              acc[message.id] = message;
              return acc;
            }, {}),
            ids: action.payload.customerMessages.map((message) => message.id),
          };
          state.customerOrders = {
            status: statusType.success,
            data: action.payload.customerOrders,
            ids: action.payload.customerOrders.results.map((order) => order.id),
          };
          state.entityDetails.status = statusType.success;
          return state;
        }
      })
      .addCase(getCustomerById.rejected, (state) => {
        state.entityDetails.status = statusType.error;
        state.customerOrders.status = statusType.error;
        return state;
      });

    builder
      .addCase(updateCustomer.pending, (state, action) => {
        state.currentRequestID = action.meta.requestId;
        return state;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        if (state.currentRequestID === action.meta.requestId) {
          const customerData = state.entityDetails.data;
          state.isEdited =
            customerData.name !== action.payload.name ||
            customerData.email !== action.payload.email;
          state.entityDetails.data = action.payload;
          return state;
        }
      });

    builder
      .addCase(getCustomerOrdersList.pending, (state, action) => {
        state.customerOrders = {
          status: statusType.loading,
          data: {
            results: [],
            pagination: paginationDefault,
          },
          ids: [],
        };
        state.currentRequestID = action.meta.requestId;
        return state;
      })
      .addCase(getCustomerOrdersList.fulfilled, (state, action) => {
        if (state.currentRequestID === action.meta.requestId) {
          state.customerOrders = {
            status: statusType.success,
            data: action.payload,
            ids: action.payload.results.map((order) => order.id),
          };
          return state;
        }
      })
      .addCase(getMoreCustomerOrdersList.rejected, (state) => {
        state.customerOrders.status = statusType.error;
        return state;
      });

    builder
      .addCase(getMoreCustomerOrdersList.pending, (state, action) => {
        state.customerOrders.status = statusType.loading;
        state.currentRequestID = action.meta.requestId;
        return state;
      })
      .addCase(getMoreCustomerOrdersList.fulfilled, (state, action) => {
        if (state.currentRequestID === action.meta.requestId) {
          state.customerOrders = {
            status: statusType.success,
            data: {
              results: [
                ...state.customerOrders.data.results,
                ...action.payload.results,
              ],
              pagination: action.payload.pagination,
            },
            ids: state.customerOrders.ids.concat(
              action.payload.results.map((order) => order.id),
            ),
          };
          return state;
        }
      })
      .addCase(getCustomerOrdersList.rejected, (state) => {
        state.customerOrders.status = statusType.error;
        return state;
      });

    builder
      .addCase(getCustomerMessagesList.fulfilled, (state, action) => {
        const ids: string[] = [];
        state.customerMessages.entities = action.payload.reduce(
          (acc, message) => {
            acc[message.id] = message;
            ids.push(message.id.toString());
            return acc;
          },
          {},
        );
        state.customerMessages.ids = ids;
        state.customerMessages.status = statusType.success;
        return state;
      })
      .addCase(getCustomerMessagesList.pending, (state, action) => {
        state.customerMessages.status = statusType.loading;
        state.currentRequestID = action.meta.requestId;
        return state;
      })
      .addCase(getCustomerMessagesList.rejected, (state) => {
        state.customerMessages.status = statusType.error;
        return state;
      });

    builder
      .addCase(removeCustomerMessage.fulfilled, (state, action) => {
        delete state.customerMessages.entities[action.meta.arg];
        state.customerMessages.ids = state.customerMessages.ids.filter(
          (id) => id !== action.meta.arg,
        );
        return state;
      })
      .addCase(readCustomerMessage.fulfilled, (state, action) => {
        state.customerMessages.entities[action.meta.arg].replied = true;
        return state;
      })
      .addCase(unreadCustomerMessage.fulfilled, (state, action) => {
        state.customerMessages.entities[action.meta.arg].replied = false;
        return state;
      })
      .addCase(spamCustomerMessage.fulfilled, (state, action) => {
        delete state.customerMessages.entities[action.meta.arg];
        state.customerMessages.ids = state.customerMessages.ids.filter(
          (id) => id !== action.meta.arg,
        );
        return state;
      });

    builder.addCase(deleteCustomer.fulfilled, (state, action) => {
      delete state.entities[action.meta.arg];
      state.ids = state.ids.filter((id) => id !== action.meta.arg);
      return state;
    });

    builder
      .addCase(getImportMaxLines.pending, (state) => {
        state.importMaxLines.status = 'loading';
      })
      .addCase(getImportMaxLines.rejected, (state) => {
        state.importMaxLines.status = 'error';
      })
      .addCase(getImportMaxLines.fulfilled, (state, action) => {
        if (action.payload.maxLines !== null) {
          state.importMaxLines.max = action.payload.maxLines;
        }
        state.importMaxLines.status = 'success';
      });

    builder
      .addCase(sendEmailAction.pending, (state) => {
        state.sendEmail = statusType.loading;
      })
      .addCase(sendEmailAction.fulfilled, (state) => {
        state.sendEmail = statusType.success;
      })
      .addCase(sendEmailAction.rejected, (state) => {
        state.sendEmail = statusType.error;
      });
  },
});

export const { reducer } = customers;
export const {
  clearCustomerRefreshStatus,
  clearSendEmailActionStatus,
  clearExportStatus,
  clearCustomerIsEdited,
  clearCustomerDetails,
} = customers.actions;
