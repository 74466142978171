import { InvoiceItemInterface } from '@tiendanube/common';
import Text from 'commons/components/LegacyText';

type InvoiceItemProps = InvoiceItemInterface;

function InvoiceItem({ item, type }: InvoiceItemProps): JSX.Element {
  return (
    <Text>
      <Text.Span bold>{item}</Text.Span>
      {` - ${type}`}
    </Text>
  );
}

export default InvoiceItem;
