import PickupBusinessDays from './components/PickupBusinessHours';
import PickupPointCost from './components/PickupPointCost';
import PickupReadyTime from './components/PickupReadyTime';
import Range from './components/Range';

function SecondStepFormSkeleton(): JSX.Element {
  return (
    <>
      <PickupBusinessDays.Skeleton />
      <PickupReadyTime.Skeleton />
      <PickupPointCost.Skeleton />
      <Range.Skeleton />
    </>
  );
}

export default SecondStepFormSkeleton;
