import { AppearanceMap, SizeMap, SpinnerColorMap } from './types';

const appearanceMap: AppearanceMap = {
  default: 'neutral',
  primary: 'primary',
  // There's no exact mapping from the @tiendanube/components secondary color (#00255a) to nimbus-ds
  secondary: 'neutral',
  danger: 'danger',
};

const spinnerColorMap: SpinnerColorMap = {
  default: 'currentColor',
  danger: 'danger-interactive',
  primary: 'primary-interactive',
  // The right one for secondary is 'primary-textLow', but it's not compatible with 'neutral' in the
  // appearanceMap up there. Until nimbus-ds supports the secondary color (or makes a decision about this), we will
  // stick with neutral
  secondary: 'neutral-interactive',
};

const sizeMap: SizeMap = {
  base: 'base',
  small: 'caption',
};

export { appearanceMap, sizeMap, spinnerColorMap };
