import { Box, Icon, Tag, Text } from '@nimbus-ds/components';
import { StarIcon } from '@nimbus-ds/icons';
import { LocationResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import useHasShippingLocationTag from 'domains/Auth/hooks/useHasShippingLocationTag';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface LocationItemProps {
  location: LocationResponseDto;
  isDragging?: boolean;
}

function LocationItem({
  location,
  isDragging = false,
}: Readonly<LocationItemProps>): JSX.Element | null {
  const t = useTranslationShipping();
  const hasShippingLocationTag = useHasShippingLocationTag();

  return (
    <Box
      marginLeft={isDragging ? '2' : 'none'}
      marginRight={isDragging ? '2' : 'none'}
    >
      <Stack spacing="base" align="center">
        <Text>{location.priority + 1}</Text>
        <Stack column spacing="tight" align="flex-start">
          <Stack spacing="tight" align="flex-start">
            <Text fontWeight="bold" color="neutral-textHigh">
              {location.name}
            </Text>
            {location.main && (
              <Tag appearance="primary">
                <Icon source={<StarIcon />} />
                {t('locations.card.label')}
              </Tag>
            )}
          </Stack>
          <Text>{location.address}</Text>
          {hasShippingLocationTag && location.tags && (
            <Stack spacing="tight" justify="flex-start" align="flex-start" wrap>
              {location.tags?.map((tag) => (
                <Tag appearance="neutral" key={tag}>
                  {t(`locations.card.tag.${tag}`)}
                </Tag>
              ))}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}

export default LocationItem;
