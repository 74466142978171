import { InterfaceDeliveryMethodsSchema } from './types';
import { initialValues } from '../pages/DeliveryMethodsPage/components/PickupPoints/pages/utils';

export const initialState: InterfaceDeliveryMethodsSchema = {
  tab: 0,
  fallback: {
    data: null,
    status: 'idle',
    updateStatus: 'idle',
  },
  activeShippingMethods: {
    list: {
      status: 'idle',
      data: null,
    },
    detail: {
      data: null,
      status: 'idle',
    },
    delete: {
      status: 'idle',
    },
    update: {
      status: 'idle',
    },
  },
  availableApps: {
    list: {
      status: 'idle',
      data: null,
    },
    create: {
      status: 'idle',
    },
  },
  customShipping: {
    list: {
      status: 'idle',
      data: null,
    },
    create: {
      status: 'idle',
    },
    detail: {
      status: 'idle',
      data: null,
    },
    delete: {
      status: 'idle',
    },
    update: {
      status: 'idle',
    },
  },
  internationalShipping: {
    list: {
      status: 'idle',
      data: null,
    },
    delete: {
      status: 'idle',
    },
    create: {
      status: 'idle',
    },
    detail: {
      status: 'idle',
      data: null,
    },
    update: {
      status: 'idle',
    },
  },
  physicalLocations: {
    getStatus: 'idle',
    updateStatus: 'idle',
    createStatus: 'idle',
    deleteStatus: 'idle',
    data: null,
  },
  requestOcaRegistration: {
    status: 'idle',
  },
  ocaAgencies: {
    zipcode: null,
    data: [],
  },
  ocaConfiguration: {
    detail: {
      data: null,
      status: 'idle',
    },
    create: {
      status: 'idle',
    },
    update: {
      status: 'idle',
    },
  },
  correios: {
    contract: {
      detail: {
        status: 'idle',
        data: null,
      },
      list: {
        status: 'idle',
        data: null,
      },
      create: {
        status: 'idle',
      },
    },
    shippingModalities: {
      detail: {
        status: 'idle',
        data: null,
      },
      create: {
        status: 'idle',
      },
    },
  },
  shippingMethods: {
    data: null,
    status: 'idle',
  },
  nuvemEnvioConfigs: {
    data: {
      enableEngagementBanner: false,
      showNuvemEnvioModal: false,
      newModalitiesScreenEnabled: false,
    },
    status: 'idle',
  },
  addressingConfiguration: {
    data: [],
    getStatus: 'idle',
    saveStatus: 'idle',
  },
  pickupPoints: {
    list: {
      data: [],
      status: 'idle',
    },
    delete: 'idle',
    create: 'idle',
    update: 'idle',
    detail: {
      data: {
        id: '',
        ...initialValues,
      },
      status: 'idle',
    },
  },
};
