import { useNavegate } from 'App/hooks';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import {
  GO_TO_CHECKOUT_AFTER_PAYMENT,
  useAfterPaymentRedirection,
} from 'domains/Billing/Checkout/hooks';

export default function useContinueWithPayment() {
  const { goTo } = useNavegate();
  const { addAfterPayActionToPath } = useAfterPaymentRedirection();
  return () =>
    goTo(
      addAfterPayActionToPath(
        CHECKOUT_ROUTES.choosePaymentMethodForPlan,
        GO_TO_CHECKOUT_AFTER_PAYMENT,
      ),
    );
}
