import { DownloadCsvTemplateResponseDto } from '@tiendanube/common';
import { useAsyncFunc, useToast, WrappedFuncType } from 'commons/hooks';

interface UseDownloadTemplateCsvResult {
  isLoading: boolean;
  getTemplateCsv: WrappedFuncType<void, DownloadCsvTemplateResponseDto>;
}
interface useDownloadTemplateCsvProps {
  fetchTemplateCsvHandler: FetchTemplateCsvHandler;
  messageSuccess: string;
  messageError: string;
}

type FetchTemplateCsvHandler = () => Promise<DownloadCsvTemplateResponseDto>;

export function useDownloadTemplateCsv({
  fetchTemplateCsvHandler,
  messageSuccess,
  messageError,
}: useDownloadTemplateCsvProps): UseDownloadTemplateCsvResult {
  const { addToast } = useToast();

  const [getTemplateCsv, isLoading] = useAsyncFunc(async () => {
    try {
      const result = await fetchTemplateCsvHandler();
      addToast({
        label: messageSuccess,
        appearance: 'success',
      });
      return result;
    } catch (error) {
      addToast({
        label: messageError,
        appearance: 'danger',
      });
      return error;
    }
  });

  return {
    isLoading,
    getTemplateCsv,
  };
}
