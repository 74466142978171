import { useSelector } from 'react-redux';
import { useAppDispatch } from 'App/store';
import { useToastStatus } from 'commons/hooks';
import { combineStatus } from 'commons/utils/combineStatus';
import useRefreshInfoStoreUser from 'domains/Auth/hooks/useRefreshInfoToSync';
import { checkoutApi } from 'domains/Billing/Checkout/CheckoutApi';
import { checkoutTags } from 'domains/Billing/Checkout/CheckoutApi/config';
import { postReviveStore, postReviveStoreStatus } from '../../accountSlice';
import useTranslationAccount from '../useTranslationAccount';

function useReviveStore() {
  const t = useTranslationAccount();
  const dispatch = useAppDispatch();
  const { refreshInfo, status: refreshInfoStatus } = useRefreshInfoStoreUser();

  const reviveStoreStatus = useSelector(postReviveStoreStatus);

  const { status, isLoading } = combineStatus(
    refreshInfoStatus,
    reviveStoreStatus,
  );

  const handleReviveStore = async () => {
    await dispatch(postReviveStore()).unwrap();
    await refreshInfo(true);
    dispatch(checkoutApi.util.invalidateTags(Object.values(checkoutTags)));
  };

  useToastStatus({ status, error: t('canceledStorePage.reviveError') });

  return {
    handleReviveStore,
    isLoading,
  };
}

export default useReviveStore;
