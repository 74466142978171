import { Box, Tag, Text, Title } from '@nimbus-ds/components';
import { Trans, useTranslation } from 'react-i18next';
import { CurrencyType } from '@tiendanube/common';
import { BaseCard, Label } from '@tiendanube/components';
import Stack from 'commons/components/Stack';
import Strikethrough from 'commons/components/Strikethrough';
import { formatCurrency } from 'commons/utils';
import { useGetLanguage } from 'domains/Auth/hooks';
import useGetStorePaymentDiscount from 'domains/Auth/hooks/useGetStorePaymentDiscount';
import { useTranslationBilling } from 'domains/Billing';
import LogoNuvemshopNext from './LogoNuvemshopNext.png';
import LogoTiendanubeNext from './LogoTiendanubeNext.png';
import { PlanCardProps } from '../../types';

type PlanCardHeaderProps = Pick<
  PlanCardProps,
  | 'title'
  | 'description'
  | 'currentPlan'
  | 'isNextPlan'
  | 'price'
  | 'frequencyPrice'
  | 'isFree'
>;

function PlanCardHeader({
  title,
  description,
  currentPlan,
  isNextPlan,
  price,
  frequencyPrice,
  isFree,
}: PlanCardHeaderProps) {
  const defaultLanguages = useGetLanguage();
  const t = useTranslationBilling();

  const storePaymentDiscount = useGetStorePaymentDiscount();

  const srcLogoNext =
    defaultLanguages === 'es' ? LogoTiendanubeNext : LogoNuvemshopNext;

  const {
    i18n: { language },
  } = useTranslation();

  const formatPrice = (
    price: number,
    currency: CurrencyType,
    taxMsg: string,
    showDecimals = false,
  ) => {
    const priceDisplay = formatCurrency(
      price,
      currency,
      language,
      !showDecimals,
    );
    return priceDisplay + taxMsg;
  };

  return (
    <BaseCard.Header>
      <Stack column align="flex-start">
        <Stack.Item>
          <Stack align="flex-start" justify="space-between" wrap>
            {isNextPlan ? (
              <Stack.Item>
                <img src={srcLogoNext} width={110} height={30} />
              </Stack.Item>
            ) : (
              <div className="nimbus--stack-item title">
                <Title as="h4">{title}</Title>
              </div>
            )}
            <Stack.Item>
              {currentPlan && (
                <Label
                  id="currentPlan"
                  label={t('billingPlansPage.currentPlan') as string}
                  appearance="primary"
                />
              )}
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          {!isNextPlan && (
            <div className="nimbus--stack-item amount">
              {!isFree ? (
                <>
                  {!storePaymentDiscount && (
                    <Title as="h3">
                      {formatPrice(price.value, price.currency, price.taxMsg)} /
                      ${frequencyPrice}
                    </Title>
                  )}
                  {storePaymentDiscount && (
                    <Stack align="flex-end">
                      <Stack.Item>
                        <Title as="h3">
                          {formatPrice(
                            price.value *
                              (1 - storePaymentDiscount.percentage / 100),
                            price.currency,
                            price.taxMsg,
                            true,
                          )}
                          *
                        </Title>
                      </Stack.Item>
                      <Stack.Item>
                        <Text fontSize="base" color="neutral-textLow">
                          <Strikethrough>
                            {formatPrice(
                              price.value,
                              price.currency,
                              price.taxMsg,
                            )}
                            / ${frequencyPrice}
                          </Strikethrough>
                        </Text>
                      </Stack.Item>
                    </Stack>
                  )}
                </>
              ) : (
                <Title as="h3">{t('billingPlansPage.free')}</Title>
              )}
            </div>
          )}
          {storePaymentDiscount && (
            <Box display="flex" flexDirection="column" gap="1">
              {!isFree && (
                <>
                  <Text color="success-textLow">
                    <Trans
                      t={t}
                      i18nKey="billingPlansPage.discount.subtitle"
                      components={{ bold: <strong /> }}
                      values={{
                        value: formatPrice(
                          (price.value * storePaymentDiscount.percentage) / 100,
                          price.currency,
                          price.taxMsg,
                          true,
                        ),
                      }}
                    />
                  </Text>
                  {!isFree && (
                    <Tag appearance="success">
                      {t('billingPlansPage.discount.percentOff', {
                        percentage: storePaymentDiscount.percentage,
                      })}
                    </Tag>
                  )}
                </>
              )}
              {isFree && <Text>&nbsp;</Text>}
            </Box>
          )}
        </Stack.Item>
        <Stack.Item>
          <Text>{description}</Text>
        </Stack.Item>
      </Stack>
    </BaseCard.Header>
  );
}

export default PlanCardHeader;
