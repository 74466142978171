import { Box, Text } from '@nimbus-ds/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';

interface LocationTextProp {
  street?: string;
  number?: string;
  floor?: string | null;
  zipcode?: string;
  locality?: string;
  city?: string;
  province?: string;
  country?: string;
  betweenStreet?: string;
  reference?: string;
}

function LocationText({
  street,
  number,
  floor,
  zipcode,
  locality,
  city,
  province,
  country,
  betweenStreet,
  reference,
}: Readonly<LocationTextProp>) {
  const t = useTranslationOrders();

  const appendedNumber = number ? `, ${number}` : '';
  let address = '';
  address += street || '';
  address += address ? appendedNumber : number ?? '';

  return (
    <Box>
      {!!address && (
        <Text>{t('cardDeliveryAddress.address', { address })}</Text>
      )}
      {!!floor && <Text>{t('cardDeliveryAddress.floor', { floor })}</Text>}
      {!!betweenStreet && (
        <Text>{t('cardDeliveryAddress.betweenStreet', { betweenStreet })}</Text>
      )}
      {!!reference && (
        <Text>{t('cardDeliveryAddress.reference', { reference })}</Text>
      )}
      {!!locality && (
        <Text>{t('cardDeliveryAddress.locality', { locality })}</Text>
      )}
      {!!zipcode && (
        <Text>{t('cardDeliveryAddress.zipcode', { zipcode })}</Text>
      )}
      {!!city && <Text>{t('cardDeliveryAddress.city', { city })}</Text>}
      {!!province && (
        <Text>{t('cardDeliveryAddress.province', { province })}</Text>
      )}
      {!!country && (
        <Text>{t('cardDeliveryAddress.country', { country })}</Text>
      )}
    </Box>
  );
}

export default LocationText;
