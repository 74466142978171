import { Card, Checkbox, Input } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { Stack } from 'commons/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

function Skeleton() {
  const t = useTranslationShipping();
  return (
    <Card>
      <Card.Header
        title={t('deliveryMethods.customShipping.informationCard.title')}
      />
      <Card.Body>
        <Stack column align="stretch">
          <Stack.Item>
            <FormField
              label={t(
                'deliveryMethods.customShipping.informationCard.shippingData.name',
              )}
            >
              <Input.Skeleton />
            </FormField>
          </Stack.Item>
          <Stack.Item>
            <Checkbox.Skeleton />
          </Stack.Item>
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default Skeleton;
