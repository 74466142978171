import { InterfaceText } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';
import { thousandSeparator } from 'domains/Statistics/utils';

interface ThousandSeparatorTextProps extends Omit<InterfaceText, 'children'> {
  value: number;
  withoutDecimal?: boolean;
}

function ThousandSeparatorText({
  value = 0,
  withoutDecimal = true,
  ...rest
}: ThousandSeparatorTextProps): JSX.Element {
  return <Text {...rest}>{thousandSeparator(value, withoutDecimal)}</Text>;
}

export default ThousandSeparatorText;
