import { useImportCsv } from 'commons/hooks';
import customersServices from 'domains/Customers/Customers/customersServices';

export function useImportCustomers() {
  return useImportCsv(
    'customersCsvImport',
    customersServices.importFile,
    customersServices.fetchImportColumns,
  );
}
