import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrderFulfillRequestDto } from '@tiendanube/common';
import {
  Domain as DomainEnum,
  FulfillmentPreferenceType as FulfillmentPreferenceTypeEnum,
} from '@tiendanube/common/src/enums';
import { Card, Text, InterfaceLabel, Stack } from '@tiendanube/components';
import { useModal } from 'commons/hooks';
import ConfirmTrackingNumModal from 'domains/Orders/components/ComfirmTrackingNumModal';
import useFulfillmentActionButton from '../../hooks/useFulfillmentActionButton';
import { OrderStatusType } from '../commons/useOrderStatus';
import TrackingNumberInfo from '../TrackingNumberInfo';

interface CardFulfillmentProps {
  order: OrderStatusType;
  urlTrackingCode: string | null;
  packOrder: () => void;
  fulfillOrder: (a: OrderFulfillRequestDto) => void;
  isLoading: boolean;
  headerLabel?: InterfaceLabel;
}

function CardFulfillment({
  order: {
    fulfillment: {
      status,
      preference: {
        option,
        weight,
        trackingNumber,
        type,
        shippingMinDays,
        shippingMaxDays,
        deliveryAddress,
      },
      pickupDetail,
    },
    status: orderStatus,
    isShippablePickup,
  },
  isLoading,
  urlTrackingCode,
  packOrder,
  fulfillOrder,
  headerLabel,
}: CardFulfillmentProps): JSX.Element {
  const { t } = useTranslation([DomainEnum.ORDERS]);
  const [isShow, openModal, closeModal] = useModal();
  const [isEditing, setIsEditing] = useState(false);

  const { secondaryButton, handleClickFulfill } = useFulfillmentActionButton({
    fulfillOrder,
    packOrder,
    openModal,
    closeModal,
    status,
    type,
    isLoading,
    orderStatus,
    isShippablePickup,
  });

  const checkShippingDays = (): boolean =>
    shippingMinDays !== undefined &&
    shippingMinDays > 0 &&
    shippingMaxDays !== undefined &&
    shippingMaxDays > 0;

  const hasShippingDays: boolean = checkShippingDays();
  const isShippingDaysRange = shippingMinDays !== shippingMaxDays;
  const isDigital =
    type === FulfillmentPreferenceTypeEnum.NON_SHIPPABLE ||
    type === FulfillmentPreferenceTypeEnum.DELIVERY_DIGITAL;

  const showPickupName =
    type === FulfillmentPreferenceTypeEnum.PICKUP && deliveryAddress?.firstName;

  const showPickUpAdress =
    type === FulfillmentPreferenceTypeEnum.PICKUP && pickupDetail?.address;
  const handleOpenModal = (editing = false) => {
    setIsEditing(editing);
    openModal();
  };

  return (
    <>
      <Card
        title={t('cardFulfillment.title')}
        secondaryButton={secondaryButton}
        headerLabel={headerLabel}
      >
        <Stack column align="flex-start" spacing="extra-tight">
          <Text block bold>
            {option}
          </Text>

          {!!showPickUpAdress && (
            <>
              <Text block>{pickupDetail.name}</Text>
              <Text block>{pickupDetail.address}</Text>
            </>
          )}

          {!!showPickupName && (
            <Text block>{`${t('cardFulfillment.pickerName', {
              picker: `${deliveryAddress?.firstName} ${deliveryAddress?.lastName}`,
            })}`}</Text>
          )}

          {hasShippingDays && isShippingDaysRange && (
            <Text block>{`${t('cardFulfillment.estimatedTimeRange', {
              minDays: shippingMinDays,
              maxDays: shippingMaxDays,
            })}`}</Text>
          )}
          {hasShippingDays && !isShippingDaysRange && (
            <Text block>{`${t('cardFulfillment.estimatedTime', {
              count: shippingMinDays,
            })}`}</Text>
          )}

          {weight ? (
            <Text block>{`${t('cardFulfillment.weight', { weight })}`}</Text>
          ) : null}

          <TrackingNumberInfo
            trackingNumber={trackingNumber}
            urlTrackingCode={urlTrackingCode}
            isDigital={isDigital}
            showEdit={isEditing}
            onClick={() => handleOpenModal(true)}
          />
        </Stack>
      </Card>
      {isShow && (
        <ConfirmTrackingNumModal
          onClose={closeModal}
          onConfirm={handleClickFulfill}
          shippingTrackingNum={trackingNumber || ''}
          isDigital={isDigital}
          isEditing={isEditing}
        />
      )}
    </>
  );
}

export default CardFulfillment;
