import { Box, Card, Link, List, Title } from '@nimbus-ds/components';
import { GiftBoxIcon } from '@nimbus-ds/icons';
import { Trans } from 'react-i18next';
import { Country } from '@tiendanube/common/src/enums';
import { IconBox } from 'commons/components';
import { useGetCountry } from 'domains/Auth/hooks';
import { useTranslationDashboard } from 'domains/Dashboard/hooks';
import { NUVEM_MARKETING_LINKS } from '../../constants';

function GiftCard() {
  const t = useTranslationDashboard();
  const storeCountry = useGetCountry();
  const countryLink =
    NUVEM_MARKETING_LINKS[storeCountry] ?? NUVEM_MARKETING_LINKS[Country.AR];

  return (
    <Card>
      <Box display="flex" flexDirection="row" gap="3">
        <IconBox
          color="primary-textLow"
          backgroundColor="primary-surface"
          height="48px"
          width="48px"
        >
          <GiftBoxIcon size="large" />
        </IconBox>
        <Box>
          <Title as="h5">{t('shivaProject.giftCard.title')}</Title>
          <List as="ul">
            <List.Item>{t('shivaProject.giftCard.firstItem')}</List.Item>
            <List.Item>{t('shivaProject.giftCard.secondItem')}</List.Item>
            <List.Item>
              <Trans
                t={t}
                i18nKey="shivaProject.giftCard.thirdItem"
                components={{
                  Link: (
                    <Link
                      as="a"
                      href={countryLink}
                      textDecoration="none"
                      target="_blank"
                      appearance="primary"
                    >
                      {' '}
                    </Link>
                  ),
                }}
              />
            </List.Item>
          </List>
        </Box>
      </Box>
    </Card>
  );
}

export default GiftCard;
