import { Modal, Text } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import CancelAndSaveButtons from 'commons/components/CancelAndSaveButtons';
import UseTranslationOrders from '../../../../../useTranslationOrders/useTranslationOrders';

interface Attributes {
  onConfirm: () => void;
  closeModal: () => void;
  isLoading: boolean;
  open: boolean;
}

function RefundOrderPageConfirmationModal({
  onConfirm,
  closeModal,
  open,
  isLoading,
}: Attributes): JSX.Element {
  const t = UseTranslationOrders();

  return (
    <Modal open={open} onDismiss={closeModal}>
      <Modal.Header title={t('refund.modal.title')} />
      <Modal.Body padding="none">
        <Stack column align="stretch">
          <Text color="neutral-textLow">{t('refund.modal.description')}</Text>
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <CancelAndSaveButtons
          saveText={t('refund.modal.buttons.save')}
          cancelText={t('refund.modal.buttons.cancel')}
          onSave={onConfirm}
          onCancel={closeModal}
          isDisabled={isLoading}
          isLoading={isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default RefundOrderPageConfirmationModal;
