import {
  Select,
  InterfaceNameValue,
  InterfaceSelectOptionGroup,
  Input,
} from '@tiendanube/components';
import Stack from 'commons/components/Stack';
import { randomizeArrayOrder } from 'commons/utils/randomizeArrayOrder';
import { useTranslationAccount } from 'domains/Account/hooks';

interface ReasonsCardProps {
  onChangeReason: (event: InterfaceNameValue) => void;
  reasonSelected: string | undefined;
  reasons: Array<InterfaceSelectOptionGroup> | undefined;
  onChangeSubReason: (event: InterfaceNameValue) => void;
  subReasonSelected: string | undefined;
  subReasons: Array<InterfaceSelectOptionGroup> | undefined | null;
  subReasonsDefault: string | undefined | null;
  isLoading: boolean | undefined;
}

function ReasonsCard({
  onChangeReason,
  reasonSelected,
  reasons,
  isLoading = false,
  onChangeSubReason,
  subReasonSelected,
  subReasons,
  subReasonsDefault,
}: ReasonsCardProps): JSX.Element {
  const t = useTranslationAccount();
  return (
    <Stack column align="stretch" spacing="loose">
      {isLoading ? (
        <Input.Skeleton />
      ) : (
        <Select
          onChange={onChangeReason}
          value={reasonSelected}
          placeholder={t('shopCancellationPage.stepTwo.selectReason')}
          label={t('shopCancellationPage.stepTwo.knowWhyLeaving')}
          name="select-reason"
          options={randomizeArrayOrder(reasons || [])}
        />
      )}
      {!!subReasonsDefault && (
        <Select
          onChange={onChangeSubReason}
          value={subReasonSelected}
          placeholder={subReasonsDefault}
          name="select-sub-reason"
          options={randomizeArrayOrder(subReasons || [])}
        />
      )}
    </Stack>
  );
}

export default ReasonsCard;
