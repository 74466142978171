import { ReactNode } from 'react';
import { Grid, InterfaceNameValue } from '@tiendanube/components';
import { InputFile, InputNumber } from 'commons/components';
import Text from 'commons/components/LegacyText';
import Stack from 'commons/components/Stack';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';

interface OcaInputFieldProps {
  index: number;
  title: ReactNode;
  subtitle?: ReactNode;
  children: ReactNode;
}

interface OcaFileInputProps extends Omit<OcaInputFieldProps, 'children'> {
  fileName?: string;
  error?: string;
  onChange: (file: File) => void;
  onRemove: () => void;
  accept?: string;
}

interface OcaPhoneNumberInputProps {
  countryCode: string;
  areaCode: string;
  phoneNumber: string;
  onChange: (e: InterfaceNameValue) => void;
  error?: string;
}

function OcaInputField({
  index,
  title,
  subtitle,
  children,
}: OcaInputFieldProps): JSX.Element {
  return (
    <Stack column align="stretch" spacing="base">
      <Stack column align="stretch" spacing="tight">
        <Text bold size="caption">
          {index}. {title}
        </Text>
        {!!subtitle && <Text size="caption">{subtitle}</Text>}
      </Stack>
      {children}
    </Stack>
  );
}

function OcaFileInput({
  fileName,
  index,
  title,
  subtitle,
  error,
  onChange,
  onRemove,
  accept,
}: OcaFileInputProps): JSX.Element {
  const t = useTranslationShipping();

  return (
    <OcaInputField index={index} title={title} subtitle={subtitle}>
      <InputFile
        fileName={fileName}
        helperText={t('oca.requestRegistration.inputFileHelperText')}
        fullWidth
        errorText={error ? t(error) : undefined}
        isError={!!error}
        onChangeFile={onChange}
        onRemoveFile={onRemove}
        accept={accept}
      />
    </OcaInputField>
  );
}

function OcaPhoneNumberInput({
  countryCode,
  areaCode,
  phoneNumber,
  onChange,
  error,
}: OcaPhoneNumberInputProps): JSX.Element {
  const t = useTranslationShipping();

  return (
    <OcaInputField
      index={5}
      title={t('oca.requestRegistration.fields.phone.title')}
    >
      <Stack column spacing="tight" align="stretch">
        <Stack.Item>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <InputNumber
                  name="countryCode"
                  label={t('oca.requestRegistration.fields.phone.countryCode')}
                  type="integer"
                  value={countryCode}
                  onChange={onChange}
                  appearance={error ? 'validation_error' : 'default'}
                />
              </Grid.Column>
              <Grid.Column>
                <InputNumber
                  name="areaCode"
                  label={t('oca.requestRegistration.fields.phone.areaCode')}
                  type="integer"
                  value={areaCode}
                  onChange={onChange}
                  appearance={error ? 'validation_error' : 'default'}
                />
              </Grid.Column>
              <Grid.Column sm={3}>
                <InputNumber
                  name="phoneNumber"
                  label={t('oca.requestRegistration.fields.phone.phoneNumber')}
                  type="integer"
                  value={phoneNumber}
                  onChange={onChange}
                  appearance={error ? 'validation_error' : 'default'}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Stack.Item>
        {!!error && (
          <Text size="caption" appearance="danger">
            {t(error)}
          </Text>
        )}
      </Stack>
    </OcaInputField>
  );
}

export { OcaFileInput, OcaPhoneNumberInput };
