import { ChangeEvent } from 'react';
import { Card, Title } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { CreateUserRequestDto, UpdateUserRequestDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import useTranslationConfigurations from 'domains/Configurations/useTranslationConfigurations';
import PasswordInputs from '../PasswordInputs/PasswordInputs';

interface DataCardProps {
  values: CreateUserRequestDto & UpdateUserRequestDto;
  errors: Partial<Record<string, string>>;
  requestErrors: Partial<Record<string, any>>;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

function DataCard({
  values,
  errors,
  requestErrors,
  onChange,
}: Readonly<DataCardProps>) {
  const t = useTranslationConfigurations('userFormPage');

  const getEmailError = () => {
    if (errors.email) return t(`errors.${errors.email}`);
    if (requestErrors.email) return t('errors.emailAlreadyTaken');
    return undefined;
  };

  return (
    <Card>
      <Card.Header>
        <Title as="h3">{t('dataCard.title_account_info')}</Title>
      </Card.Header>
      <Card.Body>
        <Stack column>
          <FormField.Input
            label={t('dataCard.firstName')}
            name="firstName"
            autoFocus
            value={values.firstName}
            onChange={onChange}
          />
          <FormField.Input
            label={t('dataCard.lastName')}
            name="lastName"
            value={values.lastName}
            onChange={onChange}
          />
          <FormField.Input
            label={t('dataCard.email')}
            name="email"
            value={values.email}
            onChange={onChange}
            showHelpText={!!getEmailError()}
            helpText={getEmailError()}
            appearance={getEmailError() ? 'danger' : undefined}
          />
          <PasswordInputs
            values={values}
            errors={errors}
            requestErrors={requestErrors}
            onChange={onChange}
          />
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default DataCard;
