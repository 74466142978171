import { ReactNode } from 'react';
import { PlusCircleIcon } from '@tiendanube/icons';
import { useNavegate } from 'App/hooks';
import {
  ActionsDesktop,
  ErrorScreen,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import { NewPromoAlert } from './NewPromoAlert';
import { PromotionAlertOpenFTP } from './PromotionAlertOpenFTP';
import { PromotionHelpLink } from './PromotionHelpLink';
import { PromotionsListEmpty } from './PromotionsListEmpty';
import { PromotionType, PromotionTypes } from '../pages/constants';
import { PROMOTIONS_ROUTES } from '../promotionsRoutes';
import {
  promotionsCreationView,
  promotionsOpenAdoptionAlert,
  promotionsCloseAdoptionAlert,
  promotionsShowAdoptionAlert,
} from '../tracking';
import { buildNewPromotionUrl } from '../utils';

interface PromotionsListPageBaseProps {
  skeleton: ReactNode;
  isLoading: boolean;
  isEmpty: boolean;
  isError: boolean;
  children: ReactNode;
  onRetry: () => void;
}

export function PromotionsListPageBase({
  skeleton,
  isLoading,
  isEmpty,
  isError,
  children,
  onRetry,
}: Readonly<PromotionsListPageBaseProps>) {
  const t = useTranslationMarketing('promotions');
  const { goTo } = useNavegate();

  const gotoCreate = (promotionType?: PromotionType) => {
    if (promotionType) {
      promotionsOpenAdoptionAlert(promotionType);
      goTo(buildNewPromotionUrl(promotionType));
    } else {
      promotionsCreationView();
      goTo(PROMOTIONS_ROUTES.newPromotions);
    }
  };

  return (
    <IonPageStratus
      width="medium"
      headerTop={
        <HeaderTop
          mainAction={
            !isEmpty ? { onClick: gotoCreate, icon: PlusCircleIcon } : undefined
          }
        />
      }
      headerContent={
        <HeaderContent
          title={t('title')}
          actions={
            !isEmpty && (
              <ActionsDesktop
                actions={[
                  {
                    icon: PlusCircleIcon,
                    children: t('addPromotion'),
                    appearance: 'primary',
                    onClick: () => gotoCreate(),
                  },
                ]}
              />
            )
          }
        />
      }
    >
      {isLoading && skeleton}
      {isError && <ErrorScreen onRetry={onRetry} />}
      {isEmpty && <PromotionsListEmpty onCreate={() => gotoCreate()} />}
      {!isLoading && !isError && !isEmpty && (
        <>
          <NewPromoAlert
            type={PromotionTypes.CROSS_SELLING}
            onClick={gotoCreate}
            onRemove={promotionsCloseAdoptionAlert}
            onShow={promotionsShowAdoptionAlert}
          />
          <PromotionAlertOpenFTP />
          {children}
        </>
      )}
      {!isError && <PromotionHelpLink />}
    </IonPageStratus>
  );
}
