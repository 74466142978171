import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { InterfaceNameValue } from '@tiendanube/components';
import { Stack } from 'commons/components';
import {
  CategoriesInterface,
  ProductsInterface,
  ScopeSelector,
} from 'domains/Marketing/components';
import { useTranslationMarketing } from 'domains/Marketing/hook';
import Skeleton from './Skeleton';
import { CouponErrorsInterface, CouponValuesInterface } from '../../types';

interface ApplyToProps {
  values: CouponValuesInterface;
  errors: Partial<CouponErrorsInterface>;
  onChange: ({ name, value }: InterfaceNameValue) => void;
  onChangeCategories: (categories: CategoriesInterface[]) => void;
  onChangeProducts: (products: ProductsInterface[]) => void;
  disabled?: boolean;
}

export function ApplyTo({
  values,
  errors,
  onChange,
  onChangeCategories,
  onChangeProducts,
  disabled = false,
}: Readonly<ApplyToProps>) {
  const translationPrefix = 'coupons.couponForm.applyTo';
  const t = useTranslationMarketing(translationPrefix);

  return (
    <Card>
      <Card.Header>
        <Stack spacing="none" justify="space-between" align="center">
          <Stack spacing="tight">
            <Title as="h4">{t('title')}</Title>
            <Text>{t('optional')}</Text>
          </Stack>
        </Stack>
      </Card.Header>
      <Card.Body>
        <Box display="flex" flexDirection="column" gap="4">
          <ScopeSelector
            values={values}
            errors={errors}
            disabled={disabled}
            onChange={onChange}
            onChangeCategories={onChangeCategories}
            onChangeProducts={onChangeProducts}
            translationPrefix={`${translationPrefix}.scope`}
          />
        </Box>
      </Card.Body>
    </Card>
  );
}

ApplyTo.Skeleton = Skeleton;
