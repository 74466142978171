/* eslint-disable max-statements */
import { useEffect, useState } from 'react';
import { Box, Button, Link, Spinner, Text, Title } from '@nimbus-ds/components';
import { SearchIcon } from '@nimbus-ds/icons';
import {
  CheckoutPaymentOptionDto,
  SortTypePaymentOrder,
} from '@tiendanube/common/src/domains/checkout/CheckoutPaymentsResponseDto';
import { CheckoutSavePaymentOrdersCustomDto } from '@tiendanube/common/src/domains/checkout/CheckoutSavePaymentOrdersDto';
import { FEATURE_PAYMENT_METHODS_SORTER } from 'App/features';
import HelpLink from 'App/HelpLink';
import { ErrorScreen } from 'commons/components';
import { SORT_PAYMENT_METHODS_TUTORIAL_LINK } from 'commons/constants';
import { useToastStatus, useTranslationLanguage } from 'commons/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import {
  useFetchCheckoutPaymentOptions,
  useSaveCheckoutPaymentOptionsOrder,
  useHasCheckoutPaymentOrderChanged,
} from 'domains/Payments/PaymentProviders/hooks';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import Skeleton from './Skeleton';
import CheckoutPaymentOrderType from '../CheckoutPaymentOrderType';
import SortCheckoutPaymentsListCustom from '../SortCheckoutPaymentsListCustom';
import SortCheckoutPaymentsListDefault from '../SortCheckoutPaymentsListDefault';

interface SortPaymentsModalContentProps {
  onClose: () => void;
}

const DEFAULT_SORT_TYPE = 'default';
const CUSTOM_SORT_TYPE = 'custom';

function SortPaymentsModalContent({ onClose }: SortPaymentsModalContentProps) {
  const t = useTranslationPayments('sortPaymentsModal');

  const {
    mutation,
    isLoading: isLoadingMutation,
    data: dataMutation,
  } = useSaveCheckoutPaymentOptionsOrder();
  const {
    getCheckoutOptions,
    isLoading: isLoadingFetch,
    isError: isErrorFetch,
    data: dataFetch,
  } = useFetchCheckoutPaymentOptions();
  const language = useTranslationLanguage();
  const [sortType, setSortType] = useState<SortTypePaymentOrder>();
  const [customData, setCustomData] = useState<CheckoutPaymentOptionDto[]>([]);
  const [defaultData, setDefaultData] = useState<CheckoutPaymentOptionDto[]>(
    [],
  );
  const [shouldCloseModal, setShouldCloseModal] = useState(false);
  const [isChangingToDefaultType, setIsChangingToDefaultType] = useState(false);

  const handleApplyWithUpsell = useUpsellFlow({
    title: t('upsellTitle'),
    featureKey: FEATURE_PAYMENT_METHODS_SORTER,
    asAside: true,
    callback: () => {
      setSortType(CUSTOM_SORT_TYPE);
    },
    onCloseModal: (result) => {
      if (result === 'declined' && dataFetch?.sortType === CUSTOM_SORT_TYPE) {
        setShouldCloseModal(false);
        setIsChangingToDefaultType(true);
        setSortType(DEFAULT_SORT_TYPE);
        mutation({
          sortType: DEFAULT_SORT_TYPE,
          custom: [],
        });
      }
    },
  });

  useEffect(() => {
    getCheckoutOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataFetch?.sortType === CUSTOM_SORT_TYPE) {
      handleApplyWithUpsell();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetch]);

  useEffect(() => {
    if (dataFetch) {
      setSortType(dataFetch.sortType);
      setCustomData(dataFetch.custom);
      setDefaultData(dataFetch.default);
    }
  }, [dataFetch]);

  useToastStatus({
    success: t('textSuccess'),
    error: t('textError'),
    status: dataMutation,
  });

  const disabledSaveButton = useHasCheckoutPaymentOrderChanged(dataFetch, {
    sortType: sortType || DEFAULT_SORT_TYPE,
    custom: customData,
    default: defaultData,
  });

  useEffect(() => {
    if (dataMutation === 'success') {
      setIsChangingToDefaultType(false);
      if (shouldCloseModal) {
        onClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataMutation, onClose]);

  const handleSortType = (item) => {
    if (item.value === DEFAULT_SORT_TYPE) {
      setSortType(DEFAULT_SORT_TYPE);
    } else {
      handleApplyWithUpsell();
    }
  };

  const handleCancelButton = () => {
    onClose();
  };

  const handleSaveButton = () => {
    if (!sortType) return;

    let displayPriority = 0;
    const custom: CheckoutSavePaymentOrdersCustomDto[] = [];
    if (sortType === CUSTOM_SORT_TYPE) {
      customData.forEach((item) => {
        if (!('fixedPriority' in item)) {
          custom.push({
            displayPriority: ++displayPriority,
            appId: item.appId,
            paymentOptionId: item.id,
            integrationType: item.integrationType,
            method: item.method,
          });
        }
      });
    }
    setShouldCloseModal(true);
    mutation({
      sortType: sortType,
      custom,
    });
  };

  const handleOnChange = (data: CheckoutPaymentOptionDto[]) => {
    setCustomData(data);
  };

  if (isErrorFetch) {
    return (
      <>
        <Text>{t('textBody')}</Text>
        <ErrorScreen
          description={t('errorText')}
          onRetry={getCheckoutOptions}
        />
      </>
    );
  }

  if (!dataFetch || isLoadingFetch || isChangingToDefaultType) {
    return (
      <>
        <Text>{t('textBody')}</Text>
        <Skeleton />
      </>
    );
  }

  if (dataFetch && !dataFetch.default.length) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap="2"
        alignItems="center"
        alignSelf="center"
        maxWidth="80%"
      >
        <SearchIcon size="large" />
        <Title textAlign="center" as="h4">
          {t('emptyText')}
        </Title>
        <Link onClick={handleCancelButton}>{t('emptyActionText')}</Link>
      </Box>
    );
  }

  if (!dataFetch) return null;

  return (
    <>
      <Text>{t('textBody')}</Text>
      <CheckoutPaymentOrderType value={sortType} onChange={handleSortType} />
      {sortType === DEFAULT_SORT_TYPE && (
        <SortCheckoutPaymentsListDefault data={defaultData} />
      )}
      {sortType === CUSTOM_SORT_TYPE && (
        <SortCheckoutPaymentsListCustom
          onChange={handleOnChange}
          data={customData}
        />
      )}
      <Box display="flex" justifyContent="flex-end" gap="2">
        <Button onClick={handleCancelButton}>{t('cancel')}</Button>
        <Button
          disabled={isLoadingMutation || disabledSaveButton}
          onClick={handleSaveButton}
          appearance="primary"
        >
          {isLoadingMutation && <Spinner color="currentColor" size="small" />}
          {t('save')}
        </Button>
      </Box>
      <HelpLink
        title={t('helpLink.title')}
        previousValue=""
        currentViewTracking=""
        linkURL={SORT_PAYMENT_METHODS_TUTORIAL_LINK[language]}
        showInMobile
        icon="both"
      />
    </>
  );

  return null;
}

export default SortPaymentsModalContent;
