import { Link } from '@nimbus-ds/components';
import { TFunction } from 'i18next';

interface IgnoreAllLinkProps {
  allColumnsAreIgnored: boolean;
  onClickIgnoreAll: () => void;
  onClickUndoIgnoreAll: () => void;
  t: TFunction;
}

function IgnoreAllLink({
  allColumnsAreIgnored,
  onClickIgnoreAll,
  onClickUndoIgnoreAll,
  t,
}: IgnoreAllLinkProps) {
  if (!allColumnsAreIgnored)
    return (
      <Link
        appearance="primary"
        fontSize="caption"
        lineHeight="caption"
        textDecoration="none"
        onClick={onClickIgnoreAll}
      >
        {t('ignoreAll')}
      </Link>
    );
  else
    return (
      <Link
        appearance="primary"
        fontSize="caption"
        lineHeight="caption"
        textDecoration="none"
        onClick={onClickUndoIgnoreAll}
      >
        {t('undoIgnoreAll')}
      </Link>
    );
}

export default IgnoreAllLink;
