import { useEffect } from 'react';
import { Tabs } from '@nimbus-ds/components';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import { useAppDispatch } from 'App/store';
import { HeaderContent } from 'commons/components';
import IonPageStratus from 'commons/components/IonPageStratus/IonPageStratus';
import { useGetTags, useHasNewPickupPoints } from 'domains/Auth/hooks';
import {
  freeShippingApi,
  FreeShippingTags,
} from 'domains/Marketing/FreeShipping/freeShippingApi';
import { useAddressingConfigurationRulesManagement } from 'domains/Shipping/DeliveryMethods/hooks';
import useLocations from 'domains/Shipping/Locations/hooks/useLocations';
import { LocationsListEmpty } from 'domains/Shipping/Locations/pages/LocationListPage/components';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import {
  InternationalShippingMethods,
  NationalDeliveryMethods,
  PhysicalLocations,
} from './components';
import './DeliveryMethodsPage.scss';
import usePhysicalLocations from './components/PhysicalLocations/usePhysicalLocations';
import PickupPoints from './components/PickupPoints';
import useTabToShow from './hooks/useTabToShow';

function DeliveryMethodsPage() {
  const t = useTranslationShipping();
  const hasPickupPoints = useHasNewPickupPoints();
  const { tab, changeTabToShow } = useTabToShow();
  const dispatch = useAppDispatch();
  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);
  const {
    fetchLocations,
    locationMain,
    isSuccess,
    isLoading: isLoadingLocation,
  } = useLocations();
  const { getStatus: rulesStatus, fetchRules } =
    useAddressingConfigurationRulesManagement();

  const { fetchPhysicalLocations, physicalLocations } = usePhysicalLocations();

  useEffect(() => {
    fetchLocations();
    fetchPhysicalLocations();
    fetchRules();
    return () => {
      dispatch(
        freeShippingApi.util.invalidateTags([
          FreeShippingTags.freeShippingsAvailable,
        ]),
      );
    };
  }, [fetchLocations, dispatch, fetchRules, fetchPhysicalLocations]);

  const locationMainIsEmpty = !locationMain && isSuccess;

  const tabs = () => {
    const tabs = [
      <Tabs.Item key={0} label={t('deliveryMethods.tabs.nationals')}>
        <NationalDeliveryMethods
          showConfigurationInfoCard={!isLoadingLocation && !!locationMain}
          isLoadingConfigurationInfoCard={
            rulesStatus === 'loading' && isLoadingLocation
          }
        />
      </Tabs.Item>,
      <Tabs.Item
        key={1}
        itemType="international"
        label={t('deliveryMethods.tabs.international')}
      >
        <InternationalShippingMethods />
      </Tabs.Item>,
    ];

    if (hasPickupPoints) {
      tabs.push(
        <Tabs.Item id="2" key={2} label={t('deliveryMethods.tabs.pickup')}>
          <PickupPoints />
        </Tabs.Item>,
      );
    }

    if (hasPickupPoints && physicalLocations && physicalLocations?.length > 0) {
      tabs.push(
        <Tabs.Item key={3} label={t('deliveryMethods.tabs.phisical')}>
          <PhysicalLocations />
        </Tabs.Item>,
      );
    }

    if (!hasPickupPoints) {
      tabs.push(
        <Tabs.Item id="2" key={2} label={t('deliveryMethods.tabs.phisical')}>
          <PhysicalLocations />
        </Tabs.Item>,
      );
    }

    return tabs;
  };

  return (
    <IonPageStratus
      headerContent={
        <HeaderContent
          title={t(`deliveryMethods.${isRevampMenu ? 'revampTitle' : 'title'}`)}
        />
      }
      width="medium"
    >
      {locationMainIsEmpty && <LocationsListEmpty origin="deliveryMethods" />}
      {!locationMainIsEmpty && (
        <div className="stratus--delivery-methods-tabs">
          <Tabs
            fullWidth
            preSelectedTab={tab}
            onClick={(e) => changeTabToShow(e.target['id'])}
          >
            {tabs()}
          </Tabs>
        </div>
      )}
    </IonPageStratus>
  );
}

export default DeliveryMethodsPage;
