import { useMemo } from 'react';
import {
  InterfaceNameValue,
  InterfaceRadioButtonOption,
  RadioButtonGroup,
} from '@tiendanube/components';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';
import { TRANSLATE_PREFIX } from '../CheckoutPaymentOptionsSorterModal/CheckoutPaymentOptionsSorterModal';

export type CheckoutPaymentOrderTypeProps = {
  value?: string;
  onChange: (value: InterfaceNameValue) => void;
};

function CheckoutPaymentOrderType(props: CheckoutPaymentOrderTypeProps) {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  const labelOptions: InterfaceRadioButtonOption[] = useMemo(() => {
    const options = ['default', 'custom'];

    return options.map((value) => ({
      value,
      label: t(`filters.${value}`),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RadioButtonGroup
      label={t('orderType')}
      name="sortPayments"
      options={labelOptions}
      onChange={props.onChange}
      value={props.value}
    />
  );
}

export default CheckoutPaymentOrderType;
