export const ARGENTINA = {
  THEMES: {
    id: 3286,
    name: '🟣 Landing Page',
    description: 'Creá páginas de alta conversión para tu ecommerce',
    image: '3286-es_AR-3286-pt_BR-small-Gif%20LP.gif',
    urlText: 'Más apps de recursos extra',
    categoryUri: 'recursos-extra',
    handle: 'landing-page1',
  },
  ABANDONED_CARTS: {
    id: 738,
    name: 'Marketing Nube (ex Perfit)',
    description:
      'Vendé más con automatización de marketing de Tiendanube: email marketing, recupero de carritos, asis...',
    image: '738-es_AR-small-share-fb-2.png',
    urlText: 'Más apps de marketing',
    categoryUri: 'marketing',
    handle: 'perfit',
  },
  MESSAGES: {
    id: 3905,
    name: 'WhatsPRO - Vendé más y Atendé Mejor',
    description:
      'WhatsPRO podrá responder a toda hora preguntas frecuentes automáticamente sin demoras y sin qué tu c...',
    image: '3905-es_AR-small-GIF (WhatsPro) 500x500 ok (1).gif',
    urlText: 'Más apps de comunicación',
    categoryUri: 'comunicacion',
    handle: 'flowy-whatspro',
  },
  DISCOUNT: {
    id: 3538,
    name: 'HintUP Promociones',
    description:
      'Promociones con: Descuentos especiales, progresivos, regalos, cupón de primera compra, kits, UTM y m...',
    image: '3538-es_AR-marca_com_r.png',
    urlText: 'Más apps de marketing',
    categoryUri: 'marketing',
    handle: 'hintup-promocoes',
  },
  COUPONS: {
    id: 11660,
    name: 'Magic Coupons | Appsnube',
    description:
      'Magic Coupons permite generar links con cupones de descuento embebidos.',
    image: '11660-es_AR-logo_autocupon.gif',
    urlText: 'Más apps de marketing',
    categoryUri: 'marketing',
    handle: 'coupon-links-appsnube',
  },
  FREE_SHIPPING: undefined,
  PROMOTIONS: {
    id: 7719,
    name: 'Promociones combinadas by TITANPush',
    description:
      'Con Promociones Combinada vas a poder ofrecer un descuento porcentual o fijo + envío gratis a tus us...',
    image: '7719-es_AR-logo%20-%20promociones%20combinadas%20(1).png',
    urlText: 'Más apps de marketing',
    categoryUri: 'marketing',
    handle: 'promociones-combinadas-by-titanpush',
  },
  PRODUCTS: {
    id: 4925,
    name: '🟣Automagico- Registro de productos',
    description:
      'Importá productos en masa utilizando las imágenes, de forma fácil y rápida. Actualizá el catálogo de...',
    image: '4925-es_AR-4925-pt_BR-small-automagico600x600.gif',
    urlText: 'Más apps de recursos extra',
    categoryUri: 'recursos-extra',
    handle: 'automagico-by-empreender',
  },
  CUSTOMERS: {
    id: 4044,
    name: 'HubSpot Integration by HAL',
    image: '4044-es_AR-small-logo 600x600.png',
    handle: 'hubspot-integration-test',
    description:
      'Lleva el control sobre tus negocios y administra los datos de tus clientes, pedidos, productos y carritos.',
    urlText: 'Más apps de canales de venta',
    categoryUri: 'canales-de-venta',
  },
  ORDERS: {
    id: 11716,
    name: 'Envíos Digitales',
    image: '11716-es_AR-icon.png',
    handle: 'envios-digitales',
    description:
      'Envíos Digitales envía automáticamente por email links de descarga a tus productos digitales.',
    urlText: 'Más apps de marketing',
    categoryUri: 'marketing',
  },
  DRAFT_ORDERS: {
    id: 2283,
    name: 'Astroselling',
    image: '2283-es_AR-TN-logo_nuevo 1.gif',
    handle: 'astroselling',
    description:
      'Sincroniza y publica de forma automática en Mercado Libre y más, como Falabella, Ripley, Paris y otros',
    urlText: 'Más apps de canales de venta',
    categoryUri: 'canales-de-venta',
  },
  EMAILS: {
    id: 400,
    name: 'Doppler Email Automation Marketing',
    image: '400-es_AR-small-avatar-doppler (1).png',
    handle: 'doppler',
    description:
      'Diseña y envía Campañas de Email, Automation y SMS para aumentar tus ventas.',
    urlText: 'Más apps de marketing',
    categoryUri: 'marketing',
  },
  INFO_MESSAGES: {
    id: 5642,
    name: 'Tagy',
    image: '5642-es_AR-5642-pt_BR-LOGO---Tagy1.png',
    handle: 'tagy',
    description:
      'Sellos, Etiquetas, Botones de WhatsApp y mucha personalización.',
    urlText: 'Más apps de marketing',
    categoryUri: 'marketing',
  },
};
