import { Checkbox, Title, Card } from '@nimbus-ds/components';
import { Stack } from 'commons/components';
import { InterfaceNameBooleanValue } from 'commons/types';
import useTranslationOrders from '../../../../../useTranslationOrders';
import { RefundAttributes } from '../../../hooks/useRefundOrder/useRefundOrder';

interface Attributes {
  values: RefundAttributes;
  onChange: (data: InterfaceNameBooleanValue) => void;
}

function RefundRestoreStockCard({ values, onChange }: Attributes): JSX.Element {
  const t = useTranslationOrders();

  const setReturnsStock = () =>
    onChange({ name: 'returnsStock', value: !values.returnsStock });

  return (
    <Card>
      <Card.Header>
        <Title as="h3">
          {t('refund.setUpRefundDetails.configurations.title')}
        </Title>
      </Card.Header>
      <Card.Body>
        <Stack column align="stretch">
          <Checkbox
            name="returnsStock"
            label={t(
              'refund.setUpRefundDetails.configurations.returnStockCheckboxLabel',
            )}
            onChange={() => setReturnsStock()}
            checked={values.returnsStock}
          />
        </Stack>
      </Card.Body>
    </Card>
  );
}

export default RefundRestoreStockCard;
