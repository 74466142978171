import {
  MetafieldSelectedDetails,
  MetafieldSelectedsOrErrorListV2Type,
  MetafieldSelectedV2Dto,
} from '@tiendanube/common';
import axios from 'App/axios';

const getCustomerMetafields = async (
  customerId: string,
): Promise<MetafieldSelectedsOrErrorListV2Type> => {
  const { data } = await axios.get(`v1/customers/${customerId}/metafields`);
  return data;
};

const getCustomerMetafieldsDetails = async (
  customerId: string,
): Promise<MetafieldSelectedDetails[]> => {
  const { data } = await axios.get(
    `v1/customers/${customerId}/metafields/plain`,
  );
  return data;
};

const getWholesaleCustomerMetafields = async (
  customerId: string,
): Promise<MetafieldSelectedV2Dto[]> => {
  // TODO: Remove console.log and add new URL const when real data is available;
  console.log(customerId);
  const data = new Promise<MetafieldSelectedV2Dto[]>((resolve) => {
    const metafields = [
      {
        id: '65b1ecd7-112e-45d1-9d70-db2326e4f6d6',
        value: 'Atacadista',
      },
      {
        id: '7db5dbfd-98ff-47d5-8627-ffd7b0f3ad40',
        value: 'Pessoa jurídica',
      },
      {
        id: 'ef866e17-2bb5-4552-a39d-aee8b82f86f5',
        value: '123456789',
      },
    ];

    setTimeout(() => {
      resolve(metafields);
    }, 1000);
  });

  return data;
};

const customerMetafieldsService = {
  getCustomerMetafields,
  getCustomerMetafieldsDetails,
  getWholesaleCustomerMetafields,
};

export default customerMetafieldsService;
