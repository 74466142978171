/* eslint-disable max-statements */
import { ChangeEvent } from 'react';
import { Tag } from '@nimbus-ds/components';
import { Prompt } from 'react-router';
import { PickupPointRequestDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { CancelAndConfirmButtons } from 'commons/components';
import { IonPageStratus } from 'commons/components/IonPageStratus';
import {
  HeaderContent,
  HeaderTop,
} from 'commons/components/IonPageStratus/components';
import {
  useGetCityOptions,
  useGetCountriesOptions,
  useGetProvincesOptions,
} from 'domains/Shipping/Locations/hooks';
import { COUNTRY_WITH_CITIES } from 'domains/Shipping/Locations/pages/constants';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import FirsStepForm from '../FirsStepForm';
import PickupFormError from '../PickupFormError';
import SecondStepForm from '../SecondStepForm';

interface PickupFormProps {
  step: number;
  type: 'pickup' | 'location';
  isNew?: boolean;
  isDirty: boolean;
  isSaving: boolean;
  isError: boolean;
  errors: Partial<Record<string, string>>;
  values: PickupPointRequestDto;
  handleSave: () => void;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangeStep?: (step: number) => void;
  setFieldValue: (name: string, value: any) => void;
  onRetryError: () => void;
}

function PickupForm({
  step,
  type,
  isNew,
  isDirty,
  isSaving,
  isError,
  errors,
  values,
  handleSave,
  handleChange,
  handleChangeStep,
  setFieldValue,
  onRetryError,
}: Readonly<PickupFormProps>): JSX.Element {
  const { goBack } = useNavegate();
  const t = useTranslationShipping();
  const { isError: isErrorProvinces, fetchProvinces } =
    useGetProvincesOptions();
  const { isError: isErrorCities, fetchCities } = useGetCityOptions();
  const { isError: isErrorCountries, fetchCountries } =
    useGetCountriesOptions();

  const secondStepCancelKey = type === 'location' ? 'cancel' : 'back';
  const isNewText = isNew ? 'add' : 'edit';

  const title =
    step === 1
      ? t(
          `deliveryMethods.pickupPoints.form.firstStep.${type}.${isNewText}.title`,
        )
      : t(
          `deliveryMethods.pickupPoints.form.secondStep.${type}.${isNewText}.title`,
          { name: values.name ? values.name : '...' },
        );

  const confirmText =
    step === 1
      ? t('deliveryMethods.pickupPoints.form.firstStep.nextStep')
      : t(
          `deliveryMethods.pickupPoints.form.secondStep.${type}.${isNewText}.action`,
        );

  const cancelText =
    step === 1
      ? t('deliveryMethods.pickupPoints.form.firstStep.cancel')
      : t(
          `deliveryMethods.pickupPoints.form.secondStep.${secondStepCancelKey}`,
        );

  const isErrorOnLoadForm =
    isErrorCountries || isErrorProvinces || isErrorCities || isError;

  const onRetryErrorComplete = () => {
    onRetryError();
    if (type === 'pickup') {
      fetchCountries();
      fetchProvinces(values.country);
      if (values.country in COUNTRY_WITH_CITIES && values.province) {
        fetchCities(values.country, values.province);
      }
    }
  };

  return (
    <IonPageStratus
      headerTop={
        <HeaderTop
          navigation={{
            onClick: goBack,
          }}
        />
      }
      headerContent={<HeaderContent title={title} />}
      width="small"
    >
      <Prompt when={isDirty} message={t('common:exitEdit.info')} />

      {type === 'pickup' && (
        <Tag appearance="primary">
          {t(
            `deliveryMethods.pickupPoints.form.${
              step === 1 ? 'firstStep' : 'secondStep'
            }.description`,
          )}
        </Tag>
      )}

      {isErrorOnLoadForm && (
        <PickupFormError onRetryError={onRetryErrorComplete} />
      )}

      {!isErrorOnLoadForm && step === 1 && (
        <FirsStepForm
          errors={errors}
          values={values}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      )}

      {!isErrorOnLoadForm && step === 2 && (
        <SecondStepForm
          errors={errors}
          values={values}
          setFieldValue={setFieldValue}
        />
      )}

      {!isErrorOnLoadForm && (
        <CancelAndConfirmButtons
          confirmText={confirmText}
          cancelText={cancelText}
          onConfirm={handleSave}
          onCancel={
            step === 2 && type === 'pickup' && handleChangeStep
              ? () => handleChangeStep(1)
              : goBack
          }
          isLoading={isSaving}
          isCancelDisabled={isSaving}
          isConfirmDisabled={isSaving}
        />
      )}
    </IonPageStratus>
  );
}

export default PickupForm;
