import { useCallback, useState } from 'react';
import { Box, Alert } from '@nimbus-ds/components';
import {
  DraftOrderConfirmResponseDto,
  OrderUpdateMetadataResponseDto,
} from '@tiendanube/common';
import { Title } from '@tiendanube/components';
import { useNavegate } from 'App/hooks';
import { Layout, RemarksCard, useResponsive } from 'commons/components';
import { useModal } from 'commons/hooks';
import { useHeaderMenuData } from 'domains/Auth/hooks';
import CustomerDataCard from 'domains/Orders/components/CustomerDataCard';
import DateAndDevice from 'domains/Orders/components/DateAndDevice';
import DetailsHeader from 'domains/Orders/components/DetailsHeader';
import OrderSummaryCard from 'domains/Orders/components/OrderSummaryCard';
import useActionsToast from 'domains/Orders/Orders/pages/OrderDetailsPage/components/OrderDetails/useActionsToast';
import { trackingDraftOrderMarkAsPaidButtonCLick } from 'domains/Orders/tracking';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import InsufficientStockModal from './components/InsufficientStockModal';
import { DraftOrderDetail } from './types';
import DeliveryInfoCard from '../DeliveryInfoCard';
import PaymentCard from '../PaymentCard';
import PaymentLinkCard from '../PaymentLinkCard';

interface DraftOrderDetailsProps {
  draftOrderDetails: DraftOrderDetail;
  confirmDraftOrder: (
    id: string,
    validateStock: boolean,
  ) => Promise<DraftOrderConfirmResponseDto>;
}

function DraftOrderDetails({
  draftOrderDetails,
  confirmDraftOrder,
}: DraftOrderDetailsProps): JSX.Element {
  const t = useTranslationOrders();
  const { isDesktop } = useResponsive();
  const [showConfirmModal, openConfirmModal, handleHideConfirmModal] =
    useModal();
  const { onError, onOpenSuccess } = useActionsToast();
  const [updateMetadata, setUpdateMetadata] =
    useState<OrderUpdateMetadataResponseDto>(
      {} as OrderUpdateMetadataResponseDto,
    );
  const { name } = useHeaderMenuData();
  const subject = t('draftOrders.customerDataCard.mailSubject', {
    storeName: name,
  });

  const body = t('draftOrders.customerDataCard.mailBody', {
    clientName: draftOrderDetails.contactName,
    link: draftOrderDetails.checkoutUrl,
  });

  const waText = t('draftOrders.customerDataCard.waText', {
    clientName: draftOrderDetails.contactName,
    link: draftOrderDetails.checkoutUrl,
    interpolation: { escapeValue: false },
  });

  const orderHasLineItems = draftOrderDetails.products.length > 0;

  const { goTo } = useNavegate();

  const handleConfirmDraftOrder = useCallback(async () => {
    try {
      trackingDraftOrderMarkAsPaidButtonCLick();
      const confirmResponse = await confirmDraftOrder(
        draftOrderDetails.id,
        true,
      );
      const updateMetadata = confirmResponse?.updateMetadata;
      updateMetadata && setUpdateMetadata(updateMetadata);
      updateMetadata?.insufficientStock
        ? openConfirmModal()
        : goTo(`/orders/${confirmResponse.id}`);
    } catch (error) {
      onError();
    }
  }, [
    confirmDraftOrder,
    openConfirmModal,
    goTo,
    draftOrderDetails.id,
    onError,
  ]);

  return (
    <>
      {!orderHasLineItems && (
        <Box marginTop="4">
          <Alert appearance="warning">
            {t('draftOrders.alert.noLineItems')}
          </Alert>
        </Box>
      )}
      <>
        {!orderHasLineItems && (
          <Box marginTop="4">
            <Alert appearance="warning">
              {t('draftOrders.alert.noLineItems')}
            </Alert>
          </Box>
        )}
        <Layout
          mainContent
          left={
            <>
              <DetailsHeader
                title={t('draftOrders.detail.mainTitle')}
                right={
                  <DateAndDevice
                    date={draftOrderDetails.date}
                    origin={draftOrderDetails.origin}
                    orderOrigin={draftOrderDetails.orderOrigin}
                  />
                }
              />
              {orderHasLineItems && (
                <PaymentLinkCard checkoutUrl={draftOrderDetails.checkoutUrl} />
              )}
              <OrderSummaryCard
                amounts={draftOrderDetails.amounts}
                products={draftOrderDetails.products}
                currency={draftOrderDetails.currency}
              />
              <PaymentCard
                onClickButton={handleConfirmDraftOrder}
                orderHasLineItems={orderHasLineItems}
              />
              {!!draftOrderDetails.remarks && (
                <RemarksCard
                  title={t('draftOrders.remarksCard.title')}
                  errorMessage={t('draftOrders.remarksCard.error')}
                  remarks={draftOrderDetails.remarks}
                  readOnly
                />
              )}
              {showConfirmModal && (
                <InsufficientStockModal
                  open={showConfirmModal}
                  onConfirm={() =>
                    confirmDraftOrder(draftOrderDetails.id, false)
                  }
                  onDismiss={handleHideConfirmModal}
                  updateMetadata={updateMetadata}
                  toastSuccess={onOpenSuccess}
                  onError={onError}
                  draftOrderId={draftOrderDetails.id}
                />
              )}
            </>
          }
          right={
            <>
              {isDesktop && (
                <Title type="h2">{`${t(
                  'draftOrders.detail.asideTitle',
                )}`}</Title>
              )}
              <CustomerDataCard
                id={draftOrderDetails.customer.id}
                name={draftOrderDetails.contactName}
                email={{
                  to: draftOrderDetails.contactEmail,
                  subject,
                  body,
                }}
                whatsapp={
                  draftOrderDetails.contactPhone
                    ? {
                        phoneNumber: draftOrderDetails.contactPhone,
                        message: waText,
                      }
                    : undefined
                }
                identification={
                  draftOrderDetails.contactIdentification
                    ? draftOrderDetails.contactIdentification
                    : undefined
                }
              />
              {draftOrderDetails.delivery && (
                <DeliveryInfoCard deliveryData={draftOrderDetails.delivery} />
              )}
            </>
          }
        />
      </>
    </>
  );
}

export default DraftOrderDetails;
