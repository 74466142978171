import { ExclamationCircleIcon } from '@nimbus-ds/icons';
import { FormField } from '@nimbus-ds/patterns';
import { InterfaceInput, InterfaceNameValue } from '@tiendanube/components';
import { InterfaceNameBooleanValue } from 'commons/types';

// Formats the RFC value:
// - Converts to uppercase.
// - Removes any character that is not A-Z or 0-9.
// - Limits the length to 13 characters.
const formatRFC = (value: string) =>
  value
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, '')
    .slice(0, 13);

interface RfcInputProps {
  readonly value: string;
  readonly name: string;
  readonly label: string;
  readonly helpText?: string;
  readonly appearance?: InterfaceInput['appearance'];
  readonly onChange: (
    data: InterfaceNameValue | InterfaceNameBooleanValue,
  ) => void;
}

function RfcInput({
  onChange,
  name,
  label,
  appearance,
  helpText,
}: RfcInputProps) {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const cleanedValue = formatRFC(event.target.value);
    onChange({ value: cleanedValue, name });
  };
  const mapAppearance = (
    appearance?: InterfaceInput['appearance'],
  ): 'warning' | 'danger' | 'success' | 'none' | undefined => {
    switch (appearance) {
      case 'default':
      case undefined:
        return 'none';
      case 'validation_loading':
        return 'warning';
      case 'validation_success':
        return 'success';
      case 'validation_error':
        return 'danger';
      default:
        return appearance as 'warning' | 'danger' | 'success';
    }
  };

  return (
    <FormField.Input
      label={label}
      helpText={helpText}
      id="input-id"
      helpIcon={ExclamationCircleIcon}
      showHelpText
      name={name}
      type="text"
      placeholder="XXXX000101XXX"
      onChange={handleChange}
      appearance={mapAppearance(appearance)}
    />
  );
}

export default RfcInput;
