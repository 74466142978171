import {
  trackingOrdersFrequentAccessSavedSearch,
  trackingOrdersFrequentAccessWaitingPacking,
  trackingOrdersFrequentAccessWaitingPaymentAll,
  trackingOrdersFrequentAccessWaitingShippingAll,
} from 'domains/Dashboard/tracking';
import { DefaultSavedSearchesCode } from 'domains/Orders/components/SavedSearches/defaultSavedSearches';

export const ORDER_FILTERS = {
  old: {
    [DefaultSavedSearchesCode.PAYMENT_PENDING]:
      '/orders/?q=&next_action=waiting_payment_all&date-range=&date-range-from=&date-range-to=&shipping-method=&channel=',
    [DefaultSavedSearchesCode.READY_TO_SEND]:
      '/orders/?q=&next_action=waiting_shipping_all&date-range=&date-range-from=&date-range-to=&shipping-method=&channel=',
    [DefaultSavedSearchesCode.READY_TO_PACK]:
      '/orders/?q=&next_action=waiting_packing&date-range=&date-range-from=&date-range-to=&shipping-method=&channel=',
  },
  new: {
    [DefaultSavedSearchesCode.PAYMENT_PENDING]: '?page=1&paymentStatus=pending',
    [DefaultSavedSearchesCode.READY_TO_SEND]:
      '?page=1&fulfillmentStatus=unfulfilled',
    [DefaultSavedSearchesCode.READY_TO_PACK]:
      '?page=1&fulfillmentStatus=unpacked&paymentStatus=paid',
  },
};

export const TRACKING_EVENTS: Partial<
  Record<DefaultSavedSearchesCode, () => void>
> = {
  [DefaultSavedSearchesCode.PAYMENT_PENDING]:
    trackingOrdersFrequentAccessWaitingPaymentAll,
  [DefaultSavedSearchesCode.READY_TO_PACK]:
    trackingOrdersFrequentAccessWaitingPacking,
  [DefaultSavedSearchesCode.READY_TO_SEND]:
    trackingOrdersFrequentAccessWaitingShippingAll,
};

export const getEmptyPreffix = (isEmpty: boolean) => (isEmpty ? 'Empty' : '');

export const handleClick =
  (code: DefaultSavedSearchesCode, isSavedSearchesEnabled: boolean) => () => {
    if (!isSavedSearchesEnabled) {
      TRACKING_EVENTS[code]?.();
    } else {
      trackingOrdersFrequentAccessSavedSearch(code);
    }
  };
