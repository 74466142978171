import { useEffect, useState } from 'react';
import { Checkbox } from '@nimbus-ds/components';
import { OptionsBusinessInfoResponseDto } from '@tiendanube/common';
import { BaseCard, Grid, Input, Select, Title } from '@tiendanube/components';
import { ShowByTag } from 'App/components';
import { CHECKOUT_ORDER_TAX_ENABLED } from 'App/featuresFlags';
import Stack from 'commons/components/Stack';
import { InterfaceNameValue } from 'commons/types';
import { useTranslationAccount } from 'domains/Account/hooks';

export type onChangeType = (data: {
  name: string;
  value: string | number | boolean;
}) => void;

export type GeneralInformationBusinessDataCardValues = {
  ownerPhoneType: string;
  ownerPhoneCountry: string;
  ownerPhoneArea: string;
  phoneNumber: string;
  timeZone: string;
  brickAndMortars: string;
  businessSize: string;
  type: string;
  orderTaxesEnabled: boolean;
  orderTaxesPercent: number;
};
export interface GeneralInformationBusinessDataCardProps {
  values: GeneralInformationBusinessDataCardValues;
  options: OptionsBusinessInfoResponseDto;
  onChange: onChangeType;
  shouldDisplayTaxesInfo?: boolean;
}

const formatTaxAsPercentageString = (taxRate: number) => {
  const percentageValue = taxRate * 100;
  const roundedPercentage = Math.round(percentageValue * 100) / 100;
  return roundedPercentage.toString();
};

const validateTaxValue = (value) => {
  // accept only numbers and dot
  const rgx = /^[0-9]{1,2}\.?[0-9]{0,2}$/;
  return value.match(rgx) && value <= 100;
};

function GeneralInformationBusinessDataCard({
  values,
  options,
  onChange,
  shouldDisplayTaxesInfo,
}: GeneralInformationBusinessDataCardProps): JSX.Element {
  const t = useTranslationAccount();
  const [taxValue, setTaxValue] = useState('0');

  const {
    ownerPhoneTypeOptions,
    timeZoneOptions,
    brickAndMortarsOptions,
    businessSizeOptions,
    typeOptions,
  } = options;

  useEffect(() => {
    const percentageString = formatTaxAsPercentageString(
      values.orderTaxesPercent,
    );
    setTaxValue(percentageString);
  }, [values.orderTaxesPercent]);

  const handleChangeTaxValue = ({ name, value }: InterfaceNameValue) => {
    if (validateTaxValue(value)) {
      setTaxValue(value);
      const parsedTax = parseFloat(value);
      if (!isNaN(parsedTax)) {
        onChange({
          name,
          value: (parsedTax / 100).toFixed(4),
        });
      }
    }
  };

  const renderTaxesInfo = () => {
    if (!shouldDisplayTaxesInfo) return null;
    return (
      <ShowByTag tag={CHECKOUT_ORDER_TAX_ENABLED}>
        <>
          <Checkbox
            label={t(
              'businessDataPage.generalInformationCard.orderTaxesEnabled.label',
            )}
            name="orderTaxesEnabled"
            checked={values.orderTaxesEnabled}
            onChange={(event) => {
              onChange({
                name: 'orderTaxesEnabled',
                value: event.target.checked,
              });
            }}
          />
          {values.orderTaxesEnabled && (
            <Input
              name="orderTaxesPercent"
              label={t(
                'businessDataPage.generalInformationCard.orderTaxesPercent.label',
              )}
              placeholder={t(
                'businessDataPage.generalInformationCard.orderTaxesPercent.placeholder',
              )}
              onChange={handleChangeTaxValue}
              value={taxValue}
              appendLabel="%"
            />
          )}
        </>
      </ShowByTag>
    );
  };

  return (
    <BaseCard>
      <BaseCard.Header>
        <Title type="h3">
          {t('businessDataPage.generalInformationCard.title') as string}
        </Title>
      </BaseCard.Header>
      <BaseCard.Body>
        <Stack column align="stretch" spacing="loose">
          <Select
            name="ownerPhoneType"
            label={t(
              'businessDataPage.generalInformationCard.ownerPhoneType.label',
            )}
            placeholder={t(
              'businessDataPage.generalInformationCard.ownerPhoneType.placeholder',
            )}
            options={ownerPhoneTypeOptions}
            value={values.ownerPhoneType}
            onChange={onChange}
          />

          <Stack.Item>
            <Grid>
              <Grid.Row>
                <Grid.Column sm={2} md={2} lg={2}>
                  <Input
                    type="number"
                    label={t(
                      'businessDataPage.generalInformationCard.ownerPhoneCountry.label',
                    )}
                    placeholder={t(
                      'businessDataPage.generalInformationCard.ownerPhoneCountry.placeholder',
                    )}
                    onChange={onChange}
                    name="ownerPhoneCountry"
                    value={values.ownerPhoneCountry}
                  />
                </Grid.Column>

                <Grid.Column sm={2} md={2} lg={2}>
                  <Input
                    type="number"
                    label={t(
                      'businessDataPage.generalInformationCard.ownerPhoneArea.label',
                    )}
                    placeholder={t(
                      'businessDataPage.generalInformationCard.ownerPhoneArea.placeholder',
                    )}
                    onChange={onChange}
                    name="ownerPhoneArea"
                    value={values.ownerPhoneArea}
                  />
                </Grid.Column>

                <Grid.Column sm={2} md={2} lg={2}>
                  <Input
                    type="number"
                    label={t(
                      'businessDataPage.generalInformationCard.phoneNumber.label',
                    )}
                    placeholder={t(
                      'businessDataPage.generalInformationCard.phoneNumber.placeholder',
                    )}
                    onChange={onChange}
                    name="phoneNumber"
                    value={values.phoneNumber}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Stack.Item>

          <Select
            name="timeZone"
            label={t('businessDataPage.generalInformationCard.timeZone.label')}
            placeholder={t(
              'businessDataPage.generalInformationCard.timeZone.placeholder',
            )}
            options={timeZoneOptions}
            value={values.timeZone}
            onChange={onChange}
          />

          <Select
            name="brickAndMortars"
            label={t(
              'businessDataPage.generalInformationCard.brickAndMortars.label',
            )}
            placeholder={t(
              'businessDataPage.generalInformationCard.brickAndMortars.placeholder',
            )}
            options={brickAndMortarsOptions}
            value={values.brickAndMortars}
            onChange={onChange}
          />

          <Select
            name="businessSize"
            label={t(
              'businessDataPage.generalInformationCard.businessSize.label',
            )}
            placeholder={t(
              'businessDataPage.generalInformationCard.businessSize.placeholder',
            )}
            options={businessSizeOptions}
            value={values.businessSize}
            onChange={onChange}
          />

          <Select
            name="type"
            label={t('businessDataPage.generalInformationCard.type.label')}
            placeholder={t(
              'businessDataPage.generalInformationCard.type.placeholder',
            )}
            options={typeOptions}
            value={values.type}
            onChange={onChange}
          />
          {renderTaxesInfo()}
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default GeneralInformationBusinessDataCard;
