import { Box, Text, Input, Tooltip, Icon, Link } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import { Trans } from 'react-i18next';
import { Stack } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

function NcmAndCest() {
  const t = useTranslationCatalog('products.detail.invoice');

  return (
    <Stack.Item>
      <Box display="grid" gridTemplateColumns="1fr 1fr" gridGap="4">
        <Stack column align="flex-start" gap="1">
          <Stack gap="0-5">
            <Text fontSize="caption">{t('titleNcm')}</Text>
          </Stack>
          <Input value="" name="margen" />
          <Box>
            <Text fontSize="caption">
              <Trans
                t={t}
                i18nKey="helpTextNcm"
                components={{
                  helpLinkNcm: (
                    <Link
                      as="a"
                      appearance="neutral"
                      href={t('helpLinkNcm')}
                      target="_blank"
                      textDecoration="underline"
                    >
                      {' '}
                    </Link>
                  ),
                }}
              />
            </Text>
          </Box>
        </Stack>
        <Stack column align="flex-start" gap="1">
          <Stack gap="0-5">
            <Text fontSize="caption">{t('titleCest')}</Text>
            <Tooltip content={t('tooltipCest')} position="right">
              <Icon
                color="currentColor"
                source={<InfoCircleIcon size={12} />}
              />
            </Tooltip>
          </Stack>
          <Input value="" name="margen" />
        </Stack>
      </Box>
    </Stack.Item>
  );
}

export default NcmAndCest;
