import {
  CustomersExportCsvResponseDto,
  CustomersListResponseDto,
  CustomersOrdersResponseDto,
  CustomersDetailsResponseDto,
  ResultPaginationResponseDto,
  CustomersMessagesResponseDto,
  ImportMaxLinesResponse,
  ImportFileRequestDto,
  ImportFileResponseDto,
  ImportColumnsResponseDto,
  DownloadCsvTemplateResponseDto,
} from '@tiendanube/common';
import axios from 'App/axios';
import {
  buildQueryParamsUrlWithSnakeCase,
  removeEmptyValues,
} from 'commons/utils';
import getUrlWithParams from 'commons/utils/getUrlWithParams';
import { getCustomerAdvancedDigest } from './digest';
import {
  CustomersFiltersType,
  ExportCustomersType,
  GetCustomerFormValuesType,
  GetCustomersType,
  CustomerRequestType,
  GetCustomerByIdType,
  GetCustomerOrdersType,
  GetCustomerMessagesType,
  UpdateCustomerNoteType,
  UpdateCustomerMessagesType,
  RemoveMessageType,
  SpamMessageType,
  UnreadMessageType,
  ReadMessageType,
  AnonymizeCustomerType,
  DeleteCustomerType,
  ExportCustomerDataType,
  SendResetPasswordMailType,
  SendActivationMailType,
  SendEMailType,
  GetCheckEmailExistsType,
  AdvancedSearchCustomersType,
  ImportCustomersType,
  GetImportCustomersMaxLines,
  FetchImportColumnsType,
  FetchCustomerCsvTemplateType,
} from './types';
import { CONTACT_MESSAGE_URL } from './urls';
import { CustomerFormValuesInterface } from '../pages/CustomerFormPage/types';

const getCustomers: GetCustomersType = async (filters) => {
  const params = buildQueryParamsUrlWithSnakeCase(
    removeEmptyValues<CustomersFiltersType>(filters),
  );
  const { data } = await axios.get<CustomersListResponseDto>(
    `v1/customers/list?&per_page=20&${params}`,
  );
  return data;
};

const advancedSearch: AdvancedSearchCustomersType = async (filters) => {
  const params = removeEmptyValues<CustomersFiltersType>(filters);
  const { data } = await axios.post<CustomersListResponseDto>(
    `v1/customers/advanced-search`,
    getCustomerAdvancedDigest(params),
  );
  return data;
};

const exportCustomers: ExportCustomersType = async () => {
  const { data } = await axios.get<CustomersExportCsvResponseDto>(
    `v1/customers/export/csv`,
  );
  return data;
};

const getCustomerById: GetCustomerByIdType = async (customerId) => {
  const { data } = await axios.get<CustomersDetailsResponseDto>(
    `v1/customers/${customerId}`,
  );
  return data;
};

const getCustomerOrders: GetCustomerOrdersType = async (customerId, page?) => {
  const { data } = await axios.get<
    ResultPaginationResponseDto<CustomersOrdersResponseDto[]>
  >(`v1/customers/${customerId}/orders`, { params: { page: page ? page : 1 } });
  return data;
};

const getCustomerMessages: GetCustomerMessagesType = async (customerId) => {
  const { data } = await axios.get<CustomersMessagesResponseDto[]>(
    `v1/customers/${customerId}/messages`,
  );
  return data;
};

const getCustomerFormValues: GetCustomerFormValuesType = async (customerId) => {
  const { data } = await axios.get<CustomerFormValuesInterface>(
    `v1/customers/${customerId}/form`,
  );
  return data;
};

const addCustomer: CustomerRequestType = async (
  customerValues: CustomerFormValuesInterface,
) => {
  const { data } = await axios.post<CustomersDetailsResponseDto>(
    `v1/customers`,
    customerValues,
  );
  return data;
};

const updateCustomer: CustomerRequestType = async (
  customerValues: CustomerFormValuesInterface,
) => {
  const { data } = await axios.put<CustomersDetailsResponseDto>(
    `v1/customers/${customerValues.id}`,
    customerValues,
  );
  return data;
};

const sendActivationMail: SendActivationMailType = async (id) => {
  const { data } = await axios.post(`v1/customers/${id}/activation-email`);
  return data;
};

const sendResetPasswordMail: SendResetPasswordMailType = async (id) => {
  const { data } = await axios.post(`v1/customers/${id}/reset-password`);
  return data;
};

const exportCustomerData: ExportCustomerDataType = async (id) => {
  const { data } = await axios.post(`v1/customers/${id}/export`);
  return data;
};

const fetchTemplateCsv = async (): Promise<DownloadCsvTemplateResponseDto> => {
  const { data } = await axios.get<DownloadCsvTemplateResponseDto>(
    `/v1/import/template/customers`,
  );
  return data;
};

const deleteCustomer: DeleteCustomerType = async (id) => {
  const { data } = await axios.delete(`v1/customers/${id}`);
  return data;
};

const anonymizeCustomer: AnonymizeCustomerType = async (customerId) => {
  const { data } = await axios.post(`v1/customers/${customerId}/anonymize`);
  return data;
};

const getImportMaxLines: GetImportCustomersMaxLines = async () => {
  const { data } = await axios.get<ImportMaxLinesResponse>(
    'v1/import/max-lines/customers',
  );
  return data;
};

const fetchImportColumns = async (): Promise<ImportColumnsResponseDto> => {
  const { data } = await axios.get<ImportColumnsResponseDto>(
    `v1/import/columns/customers`,
  );
  return data;
};

const updateCustomerMessages: UpdateCustomerMessagesType = async (
  messageId,
  action,
) => {
  const { data } = await axios.patch<CustomersMessagesResponseDto>(
    `v1/customers/messages/${messageId}`,
    { action },
  );
  return data;
};

const updateCustomerNote: UpdateCustomerNoteType = async (id, note) => {
  const { data } = await axios.put<CustomersDetailsResponseDto>(
    `v1/customers/${id}`,
    { note },
  );
  return data;
};

const importFile = async ({
  file,
  replace,
  columns,
  columnsChanged,
  sendEntityEmail,
}: ImportFileRequestDto): Promise<ImportFileResponseDto> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('replace', replace ? 'true' : 'false');
  formData.append('columns', columns as string);
  formData.append('columnsChanged', columnsChanged ? 'true' : 'false');
  formData.append('sendEntityEmail', sendEntityEmail ? 'true' : 'false');

  const { data } = await axios.post<ImportFileResponseDto>(
    `/v1/import/file/customers`,
    formData,
  );
  return data;
};

const removeMessage: ReadMessageType = async (id: string) => {
  const { data } = await axios.delete(`v1/messages/${id}`);
  return data;
};

const readMessage: ReadMessageType = async (id: string) => {
  const { data } = await axios.post(`v1/messages/${id}/read`);
  return data;
};

const unreadMessage: UnreadMessageType = async (id: string) => {
  const { data } = await axios.post(`v1/messages/${id}/unread`);
  return data;
};

const spamMessage: SpamMessageType = async (id: string) => {
  const { data } = await axios.post(`v1/messages/${id}/spam`);
  return data;
};

const sendEmail: SendEMailType = async ({ customerId, emailData }) => {
  const { data } = await axios.post(
    getUrlWithParams(CONTACT_MESSAGE_URL, { customerId }),
    emailData,
  );
  return data;
};

export const getCheckEmailExists: GetCheckEmailExistsType = async (email) => {
  const { data } = await axios.get(`v1/customers/check-email-exists/${email}`);
  return data;
};

const customersServices: {
  getCustomers: GetCustomersType;
  advancedSearch: AdvancedSearchCustomersType;
  exportCustomers: ExportCustomersType;
  getCustomerById: GetCustomerByIdType;
  getCustomerOrders: GetCustomerOrdersType;
  getCustomerMessages: GetCustomerMessagesType;
  getCustomerFormValues: GetCustomerFormValuesType;
  addCustomer: CustomerRequestType;
  updateCustomer: CustomerRequestType;
  sendActivationMail: SendActivationMailType;
  sendResetPasswordMail: SendResetPasswordMailType;
  exportCustomerData: ExportCustomerDataType;
  fetchTemplateCsv: FetchCustomerCsvTemplateType;
  deleteCustomer: DeleteCustomerType;
  importFile: ImportCustomersType;
  fetchImportColumns: FetchImportColumnsType;
  anonymizeCustomer: AnonymizeCustomerType;
  getImportMaxLines: GetImportCustomersMaxLines;
  updateCustomerNote: UpdateCustomerNoteType;
  updateCustomerMessages: UpdateCustomerMessagesType;
  removeMessage: RemoveMessageType;
  readMessage: ReadMessageType;
  unreadMessage: UnreadMessageType;
  spamMessage: SpamMessageType;
  sendEmail: SendEMailType;
  getCheckEmailExists: GetCheckEmailExistsType;
} = {
  getCustomers,
  advancedSearch,
  exportCustomers,
  getCustomerById,
  getCustomerOrders,
  getCustomerMessages,
  getCustomerFormValues,
  addCustomer,
  fetchTemplateCsv,
  updateCustomer,
  sendActivationMail,
  fetchImportColumns,
  sendResetPasswordMail,
  exportCustomerData,
  deleteCustomer,
  importFile,
  anonymizeCustomer,
  updateCustomerNote,
  updateCustomerMessages,
  removeMessage,
  getImportMaxLines,
  readMessage,
  unreadMessage,
  spamMessage,
  sendEmail,
  getCheckEmailExists,
};

export default customersServices;
