import { ChangeEvent, useCallback, useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import { PickupPointRequestDto } from '@tiendanube/common';
import useLayoutScroll from 'App/components/AppLayout/useLayoutScroll';
import {
  useGetCityOptions,
  useGetCountriesOptions,
  useGetProvincesOptions,
  useLocations,
} from 'domains/Shipping/Locations/hooks';
import { AddressCard } from 'domains/Shipping/Locations/pages/components';
import LocationListForPickup from './components/LocationListForPickup';
import PickupName from './components/PickupName';
import FirsStepFormSkeleton from './Skeleton';
import usePickupPoints from '../../hooks/usePickupPoints';

interface FirsStepFormProps {
  errors: Partial<Record<string, string>>;
  values: PickupPointRequestDto;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setFieldValue: (name: string, value: any) => void;
}

function FirsStepForm({
  values,
  errors,
  handleChange,
  setFieldValue,
}: Readonly<FirsStepFormProps>): JSX.Element {
  const { scrollToError } = useLayoutScroll();
  const { isLoadingList } = usePickupPoints();
  const { isLoading: isLoadingLocations } = useLocations();
  const {
    provinces,
    isLoading: isLoadingProvinces,
    fetchProvinces,
  } = useGetProvincesOptions();
  const {
    cities,
    isLoading: isLoadingCities,
    fetchCities,
  } = useGetCityOptions();
  const {
    countries,
    isLoading: isLoadingCountries,
    fetchCountries,
  } = useGetCountriesOptions();

  useEffect(() => {
    if (!isEmpty(errors)) {
      scrollToError();
    }
  }, [errors, scrollToError]);

  const clearFields = useCallback(() => {
    setFieldValue('province', '');
    setFieldValue('city', '');
    setFieldValue('locality', '');
    setFieldValue('street', '');
    setFieldValue('number', '');
    setFieldValue('floor', '');
    setFieldValue('complement', '');
    setFieldValue('zipcode', '');
  }, [setFieldValue]);

  const handleOnChange = (e) => {
    handleChange(e);
  };

  const isLoading = isLoadingLocations || isLoadingList;

  return (
    <>
      {isLoading && <FirsStepFormSkeleton />}
      {!isLoading && (
        <>
          <PickupName
            onChange={handleChange}
            name={values.name}
            errors={errors}
          />
          <AddressCard
            onChange={handleOnChange}
            values={values}
            errors={errors}
            countries={countries}
            provinces={provinces}
            cities={cities}
            loadingCountries={isLoadingCountries}
            loadingProvinces={isLoadingProvinces}
            loadingCities={isLoadingCities}
            fetchCountries={fetchCountries}
            fetchProvinces={fetchProvinces}
            fetchCities={fetchCities}
            setFieldValue={setFieldValue}
            clearFields={clearFields}
          />
          <LocationListForPickup
            errors={errors}
            ids={values.locations}
            setFieldValue={setFieldValue}
          />
        </>
      )}
    </>
  );
}

export default FirsStepForm;
