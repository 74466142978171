import { logEvent } from 'commons/utils/tracking';
import {
  AUTHENTICATION_FACTOR_ACTIVATED,
  AUTHENTICATION_FACTOR_DEACTIVATED,
  AUTHENTICATION_FACTOR_RECOVERY_CODES_COPIED,
  AUTHENTICATION_FACTOR_RECOVERY_CODES_DOWNLOADED,
  AUTHENTICATION_FACTOR_RECOVERY_CODES_START_ACTIVATION,
  AUTHENTICATION_FACTOR_RECOVERY_CODES_UPDATE,
  BILLING_REACTIVATE_STORE_CLICK,
  SESSION_MANAGEMENT_CLOSE_ALL_SESSIONS_BUTTON_CLICK,
  SESSION_MANAGEMENT_CLOSE_SESSION_BUTTON_CLICK,
  SESSION_MANAGEMENT_MENU_ITEM_CLICK,
  USER_NOTIFICATION_DISABLED,
  USER_NOTIFICATION_ENABLED,
} from 'config/trackingEvents';

const eventPropertiesMap = {
  store_newsletter: 'newsletter',
  store_contact: 'new_message',
  transaction_seller: 'new_sale',
};

export const trackingAuthenticationFactorConfiguration = (
  currentActivationStatus: boolean,
): void => {
  logEvent(
    currentActivationStatus
      ? AUTHENTICATION_FACTOR_DEACTIVATED
      : AUTHENTICATION_FACTOR_ACTIVATED,
    {},
  );
};

export const trackingEmailNotificationConfiguration = (
  currentActivationStatus: boolean,
  emailType: string,
): void => {
  currentActivationStatus
    ? logEvent(USER_NOTIFICATION_ENABLED, {
        [eventPropertiesMap[emailType.toLowerCase()]]: 'true',
      })
    : logEvent(USER_NOTIFICATION_DISABLED, {
        [eventPropertiesMap[emailType.toLowerCase()]]: 'false',
      });
};

export const trackingRecoveryCodesCopied = (): void => {
  logEvent(AUTHENTICATION_FACTOR_RECOVERY_CODES_COPIED, {});
};

export const trackingRecoveryCodesDownloaded = (): void => {
  logEvent(AUTHENTICATION_FACTOR_RECOVERY_CODES_DOWNLOADED, {});
};

export const trackingRecoveryCodesStartActivation = (): void => {
  logEvent(AUTHENTICATION_FACTOR_RECOVERY_CODES_START_ACTIVATION, {});
};

export const trackingRecoveryCodesUpdate = (): void => {
  logEvent(AUTHENTICATION_FACTOR_RECOVERY_CODES_UPDATE, {});
};

export const trackingSessionManagementMenuItemClick = (): void => {
  logEvent(SESSION_MANAGEMENT_MENU_ITEM_CLICK, {});
};

export const trackingSessionManagementCloseSessionButtonClick = (): void => {
  logEvent(SESSION_MANAGEMENT_CLOSE_SESSION_BUTTON_CLICK, {});
};

export const trackingSessionManagementCloseAllSessionsButtonClick =
  (): void => {
    logEvent(SESSION_MANAGEMENT_CLOSE_ALL_SESSIONS_BUTTON_CLICK, {});
  };

export const trackingBillingReactivateClick = (route: string): void => {
  logEvent(BILLING_REACTIVATE_STORE_CLICK, { route });
};
