import { Box, Link, Text } from '@nimbus-ds/components';
import { ChargeDto } from '@tiendanube/common';
import { useGoToConceptDetails } from 'domains/Billing/Checkout/hooks/useGoToConceptDetails';
import { PaymentStatusTag } from 'domains/Billing/Checkout/pages/PlansAndSubscriptions/components';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { HistoricChargeSkeleton } from './Skeleton';

interface HistoricChargeProps {
  charge: ChargeDto;
}

export function HistoricCharge({ charge }: Readonly<HistoricChargeProps>) {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.paymentsTabs.lastPayments',
  );
  const formatCurrency = useFormatCurrency();

  const goToDetails = useGoToConceptDetails(
    'lastPayments',
    charge.conceptCode,
    charge.metadata?.appId,
    charge.id,
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      padding="4"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" flex="1 1 90%" gap="1-5">
        <Text>{charge.description}</Text>
        <PaymentStatusTag paymentStatus={charge.status} />
        <Link fontSize="caption" appearance="primary" onClick={goToDetails}>
          {t('chargeDetails')}
        </Link>
      </Box>
      <Box display="flex" flexDirection="column" flex="1 1 5%">
        <Text>{formatCurrency(charge.amountValue, charge.amountCurrency)}</Text>
      </Box>
    </Box>
  );
}

HistoricCharge.Skeleton = HistoricChargeSkeleton;
