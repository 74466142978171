import { Box, Button } from '@nimbus-ds/components';
import { PlansListResponseDto } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { handleClickExternal } from 'commons/utils/dom';
import goToAdmin from 'commons/utils/gotToAdmin';
import { useGetIsFreePlan } from 'domains/Auth/hooks';
import useGetStorePaymentDiscount from 'domains/Auth/hooks/useGetStorePaymentDiscount';
import { billingRoutes, useTranslationBilling } from 'domains/Billing';
import { SelectedPlan } from 'domains/Billing/BillingPlans/pages/BillingPlansPage/components/ComparisonPlansCards/components/PlanCard/types';
import { useGetLastPayment } from 'domains/Billing/Checkout/hooks';
import useHasMigratedToBillingEngine from 'domains/Billing/hooks/useHasMigratedToBillingEngine';

interface ButtonPlanProps {
  plan: PlansListResponseDto;
  currentPlanId: number;
  currentPlanLevel: number;
  onSelectPlan: (plan: SelectedPlan) => void;
}

function ButtonPlan({
  plan,
  currentPlanId,
  currentPlanLevel,
  onSelectPlan,
}: ButtonPlanProps): JSX.Element | null {
  const t = useTranslationBilling();
  const { isEnabled } = useHasMigratedToBillingEngine();
  const { isPending: planPending } = useGetLastPayment('plan-cost');
  const storePaymentDiscount = useGetStorePaymentDiscount();
  const { goTo } = useNavegate();
  const { isTrial } = useGetIsFreePlan();

  const shouldHideButton =
    plan.id === currentPlanId &&
    ((isEnabled && !planPending && !storePaymentDiscount && !isTrial) ||
      plan.isFree);

  if (shouldHideButton) {
    return <Box height="34px" />;
  }

  const handleClickButton = () => {
    if (plan.isNextPlan) {
      handleClickExternal(plan.url)();
    } else if (plan.id === currentPlanId) {
      if (storePaymentDiscount) {
        goTo(billingRoutes.plansAndSubscriptions);
      } else {
        goToAdmin(`account/checkout/start/`)();
      }
    } else {
      onSelectPlan(plan);
    }
  };

  const getTextButton = () => {
    if (plan.isNextPlan) {
      return t('billingPlansPage.nextReadMore');
    }
    if (plan.id === currentPlanId && !storePaymentDiscount) {
      return t('billingPlansPage.payPlan');
    }
    if (currentPlanLevel > plan.level) {
      return t('billingPlansPage.downgradePlan');
    }
    if (storePaymentDiscount) {
      return t('billingPlansPage.discount.cta');
    }
    return t('billingPlansPage.upgradePlan');
  };

  const getAppearance = () => {
    if (
      (storePaymentDiscount && currentPlanLevel <= plan.level) ||
      plan.id === currentPlanId
    ) {
      return 'primary';
    }
    return 'neutral';
  };

  return (
    <Button appearance={getAppearance()} onClick={handleClickButton}>
      {getTextButton()}
    </Button>
  );
}

export default ButtonPlan;
