import { Box, Button, Icon, Text } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { REVAMP_MENU_V1 } from 'App/featuresFlags';
import HelpLink from 'App/HelpLink';
import { HeaderContent, IonPageStratus, Responsive } from 'commons/components';
import { HELP_LINKS_APPS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useGetTags } from 'domains/Auth/hooks';
import { useTranslationPartnerApps } from 'domains/PartnersApps/hooks';
import { useGetAppsListQuery } from 'domains/PartnersApps/partnersAppsApi';
import { AppsListDesktop, AppsListMobile, EmptyAppList } from './components';
import UninstallAppModal from './components/UninstallAppModal';

function AppsListPage() {
  const { t } = useTranslationPartnerApps('appsListPage');
  const language = useTranslationLanguage();

  const { data: apps, isLoading, isError, refetch } = useGetAppsListQuery();

  const isRevampMenu = useGetTags().includes(REVAMP_MENU_V1);

  const isAppListEmpty = apps?.length === 0;

  return (
    <>
      <IonPageStratus
        width="small"
        headerContent={
          isAppListEmpty ? (
            <Text>{t(isRevampMenu ? 'revampTitle' : 'title')}</Text>
          ) : (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box>
                <HeaderContent
                  title={t(isRevampMenu ? 'revampTitle' : 'title')}
                />
              </Box>
              <Box>
                <Button as="a" href={t('empty.appStoreUrl')} target="_blank">
                  <Icon source={<ExternalLinkIcon />} />
                  {t('appStoreButton')}
                </Button>
              </Box>
            </Box>
          )
        }
      >
        {isAppListEmpty ? (
          <EmptyAppList />
        ) : (
          <Responsive
            mobile={
              <AppsListMobile
                apps={apps}
                isLoading={isLoading}
                isError={isError}
                refetch={refetch}
              />
            }
            desktop={
              <AppsListDesktop
                apps={apps}
                isLoading={isLoading}
                isError={isError}
                refetch={refetch}
              />
            }
          />
        )}
        <HelpLink
          title={t('helpLink.title')}
          previousValue=""
          currentViewTracking={t('helpLink.amplitudeName')}
          linkURL={HELP_LINKS_APPS[language]}
          hasDisclaimer={false}
          icon="both"
        />
      </IonPageStratus>
      <UninstallAppModal />
    </>
  );
}

export default AppsListPage;
