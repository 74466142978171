import { BaseCard, Input } from '@tiendanube/components';
import Stack from 'commons/components/Stack';

function Skeleton(): JSX.Element {
  return (
    <BaseCard>
      <BaseCard.Body>
        <Stack column align="stretch" spacing="loose">
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
          <Input.Skeleton />
        </Stack>
      </BaseCard.Body>
    </BaseCard>
  );
}

export default Skeleton;
