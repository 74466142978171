import { useNavegate } from 'App/hooks';

export function useSectionActive() {
  const { pathname } = useNavegate();

  const isSectionActive = (url) =>
    pathname === url || pathname.startsWith(`${url}/`);

  return isSectionActive;
}
