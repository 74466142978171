import {
  GetDocumentSettingsResponseDto,
  InformationForContentDeclarationResponseDto,
  InformationForDocumentResponseDto,
} from '@tiendanube/common';
import { DocumentType } from 'domains/FulfillmentOrders/pages/PrintDocumentPage/constants';
import DocumentPreview from '../DocumentPreview';

interface BoxReceiptDocumentPreviewProps {
  information?:
    | InformationForContentDeclarationResponseDto[]
    | InformationForDocumentResponseDto[];
  isLoading: boolean;
  settings: GetDocumentSettingsResponseDto;
  locationId: string;
}

function BoxReceiptDocumentPreview({
  information,
  isLoading,
  settings,
  locationId,
}: Readonly<BoxReceiptDocumentPreviewProps>) {
  return (
    <div
      id={`print-container-${DocumentType.RECEIPT}`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2%',
        width: '100%',
      }}
    >
      {!isLoading && information && (
        <DocumentPreview
          type={DocumentType.RECEIPT}
          information={information as InformationForDocumentResponseDto[]}
          settings={settings}
          locationId={locationId}
        />
      )}
    </div>
  );
}

export default BoxReceiptDocumentPreview;
