import { ChangeEvent } from 'react';
import { Box, Radio, Tag, Text, Card, Link } from '@nimbus-ds/components';
import { FormField } from '@nimbus-ds/patterns';
import { Trans } from 'react-i18next';
import { OrderDetailsResponseDto } from '@tiendanube/common';
import { CurrencyPrice, Stack } from 'commons/components';
import { InterfaceNameBooleanValue, InterfaceNameValue } from 'commons/types';
import useTranslationOrders from '../../../../../useTranslationOrders';
import { useNuvemPagoRedirect } from '../../../hooks/useNuvemPagoRedirect';
import {
  RefundAttributes,
  RefundAttributesTypes,
} from '../../../hooks/useRefundOrder/useRefundOrder';

interface Attributes {
  order: OrderDetailsResponseDto;
  onChange: (data: InterfaceNameValue | InterfaceNameBooleanValue) => void;
  values: RefundAttributes;
}

function RefundAmountSelectorCard({
  order,
  values,
  onChange,
}: Attributes): JSX.Element {
  const t = useTranslationOrders();
  const setType = (type: string) => {
    onChange({ name: 'type', value: type });
  };

  const setValue = (value: string) => {
    onChange({ name: 'value', value: value });
  };

  const gateway = order.payment?.gateway ?? '';
  const nuvemPagoRedirect = useNuvemPagoRedirect(gateway);

  return (
    <Card>
      <Box display="flex" gap="4" justifyContent="space-between">
        <Stack align="stretch" justify="flex-start" spacing="tight">
          <Radio
            id="total"
            onChange={() => {
              setType(RefundAttributesTypes.TOTAL);
              setValue(order.payment.capturedAmount.value);
            }}
            name="total"
            checked={values.type === 'total'}
          />
          <Stack column gap="1">
            <Stack alignSelf="flex-start">
              <Text color="primary-textHigh">
                {t('refund.setUpRefundDetails.amountSelectorCard.total.title')}
              </Text>
            </Stack>
            <Stack alignSelf="flex-start">
              <Text color="neutral-textLow">
                {t(
                  'refund.setUpRefundDetails.amountSelectorCard.total.description',
                )}
              </Text>
            </Stack>
          </Stack>
        </Stack>
        <Box justifyContent="flex-start">
          <CurrencyPrice
            price={Number(order.payment.capturedAmount.value)}
            currency={order.currency}
            block
            textAlign="right"
          />
        </Box>
      </Box>

      <Box
        id="divider"
        backgroundColor="neutral-surfaceHighlight"
        height="1px"
      />

      <Box display="flex" gap="4" justifyContent="space-between">
        <Stack spacing="tight" justify="flex-start" align="flex-start">
          <Radio
            disabled
            onChange={() => setType(RefundAttributesTypes.PARTIAL_BY_VALUE)}
            name="partial_by_value"
            checked={values.type === 'partialByValue'}
          />
          <Text color="primary-textHigh">
            {t(
              'refund.setUpRefundDetails.amountSelectorCard.partialByValue.title',
            )}
          </Text>
          <Box minWidth="fit-content">
            <Tag appearance="primary">
              <Text lineClamp={1}>
                {t('refund.setUpRefundDetails.amountSelectorCard.tagText')}
              </Text>
            </Tag>
          </Box>
        </Stack>
        <Box justifyContent="flex-start" flex="0 0 30%">
          <FormField.Input
            disabled
            min="0"
            value={values.type === 'partialByValue' ? values.value : ''}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setValue(event.target.value)
            }
            append={<Text color="neutral-textLow">$</Text>}
            appendPosition="start"
            appearance="none"
          />
        </Box>
      </Box>
      {nuvemPagoRedirect.isNuvemPagoPayment() && (
        <Text color="neutral-textLow">
          <Trans
            t={t}
            i18nKey="refund.setUpRefundDetails.amountSelectorCard.partialByValue.nuvemPagoLink"
            components={{
              ExternalLink: (
                <Link
                  as="a"
                  appearance="primary"
                  textDecoration="none"
                  href={nuvemPagoRedirect.partialRefundURL(order.id)}
                  target="_blank"
                >
                  {' '}
                </Link>
              ),
            }}
          />
        </Text>
      )}
    </Card>
  );
}

export default RefundAmountSelectorCard;
