import { createSelector } from '@reduxjs/toolkit';
import { Country, Currency, PlanLojinha } from '@tiendanube/common/src/enums';
import { LanguagesType } from 'App/i18n';
import { RootStateType } from 'App/store';
import createSimpleSelector from 'commons/utils/createSimpleSelector';
import { InterfaceAuthSchema } from 'domains/Auth/authService/types';

export const getAuth = (state: RootStateType): InterfaceAuthSchema =>
  state.auth;

export const getAuthorizeData = createSimpleSelector(
  [getAuth],
  (auth) => auth.authorize.data,
);

export const getAuthorizeStatus = createSimpleSelector(
  [getAuth],
  (auth) => auth.authorize.status,
);

export const getAuthorizeStatusCode = createSimpleSelector(
  [getAuth],
  (auth) => auth.authorize.statusCode,
);

export const getAuthData = createSimpleSelector([getAuth], (auth) => auth.data);

export const getAuthSyncStatus = createSimpleSelector(
  [getAuth],
  (auth) => auth.statusSync,
);

export const getLimitingFeatures = createSimpleSelector(
  [getAuthData],
  (data) => data?.plan?.limitingFeatures,
);

export const getLimitingFeatureByKey = createSelector(
  getAuthData,
  (_, key: string) => key,
  (data, key) => data?.plan?.limitingFeatures[key] || 1,
);

export const getStatusFeatureByKey = createSelector(
  getAuthData,
  (_, key: string) => key,
  (data, key) => {
    const limitingFeature = data?.plan?.limitingFeatures?.[key] || 0;
    const isAvailable = limitingFeature !== 0;
    const unlimited = limitingFeature === -1;
    const maxUse = limitingFeature;
    return { isAvailable, unlimited, maxUse };
  },
);

export const getLimitingFeaturesKeys = createSelector(
  getLimitingFeatures,
  (limitingFeatures = {}) => Object.keys(limitingFeatures),
);

export const getStatusFeatureByKeyWithDefaultValue = createSelector(
  getAuthData,
  (_, key: string) => key,
  (_, key: string, defaultValue: number) => defaultValue,
  (data, key, defaultValue) => {
    const limitingFeature = data?.plan?.limitingFeatures?.[key];
    const limitingFeatureValue =
      limitingFeature !== undefined ? limitingFeature : defaultValue;
    const isAvailable = limitingFeatureValue !== 0;
    const unlimited = limitingFeatureValue === -1;
    const maxUse = limitingFeatureValue;
    return { isAvailable, unlimited, maxUse, defaultValue };
  },
);

export const isUserLogin = createSelector(
  getAuthData,
  (data) => !!data?.accessToken,
);
export const getAccessToken = createSelector(
  getAuthData,
  (data) => data?.accessToken,
);

export const getLoginStatus = createSelector(
  getAuth,
  isUserLogin,
  (auth, isLogin) => ({
    isLogin,
    isError: auth.status === 'error',
    isLoading: auth.status === 'loading',
    statusCode: auth.statusCode,
  }),
);

export const getInfoStore = createSelector(getAuthData, (data) => ({
  id: data?.storeId || '',
  name: data?.name || '',
  url: data?.url || '',
  language: data?.defaultLanguage || '',
  languages: data?.languages || [],
  currency: data?.defaultCurrency || '',
  country: data?.country || '',
  createdAt: data?.createdAt ? new Date(data.createdAt).toISOString() : '',
  partnerId: data?.partnerId,
  hasFtp: !!data?.hasFtp,
  npsElegible: !!data?.npsElegible,
  isTrial: !!data?.isTrial,
  currentTheme: data?.currentTheme || '',
  currentSegment: data?.currentSegment || '',
  successPortfolio: !!data?.successPortfolio,
}));

export const getUserAdmin = createSelector(getAuthData, (data) => ({
  id: data?.user.id || '',
  name: data?.user.name || '',
  roles: data?.roles || [],
}));

export const isAuthInit = createSelector(getAuth, (auth) => auth.isAuthInit);
export const isAuthCodeError = createSelector(
  getAuth,
  (auth) => auth.statusAuthCode === 'error',
);

export const getStoreName = createSelector(
  getAuthData,
  (data) => data?.name || '',
);

export const getStoreURL = (state: RootStateType) =>
  getAuthData(state)?.url || '';

export const getAdminURL = (state: RootStateType) =>
  getAuthData(state)?.tiendanubeAdminUrl || '';

export const getStoreURLReduced = createSelector(getStoreURL, (url) => {
  const urlNoPrefix = url.substring(8); // url without "https://"
  const urlCutted = urlNoPrefix.substring(urlNoPrefix.length - 15);
  const urlReduced =
    urlCutted.length < urlNoPrefix.length ? `...${urlCutted}/` : url;
  return urlReduced;
});

export const getCountry = createSelector(
  getAuthData,
  (data) => data?.country || Country.AR,
);

export const getUserInfo = createSelector(getAuthData, (data) => ({
  id: data?.user?.id || '',
  email: data?.user?.email || '',
  name: data?.user?.name || '',
  firstName: data?.user?.firstName || '',
  lastName: data?.user?.lastName || '',
  createdAtStore: data?.createdAt || 0,
  userConfirmed: data?.user?.userConfirmed,
  isEmployee: data?.user?.isEmployee || false,
  isMain: data?.user?.isMain || false,
}));

export const getI18n = createSelector(getAuthData, (data) => ({
  language: data?.defaultLanguage,
  country: data?.country,
}));

export const getDefaultLanguage = createSelector(
  getAuthData,
  (data) => data?.defaultLanguage || 'es',
);

export const getTags = createSelector(getAuthData, (data) => data?.tags || []);

export const getFeatures = createSelector(
  getAuthData,
  (data) => data?.plan?.features || [],
);

export const getPlanName = createSelector(
  getAuthData,
  (data) => data?.plan?.name || '',
);

export const getPlanNiceName = createSelector(
  getAuthData,
  (data) => data?.plan?.niceName || '',
);

export const getPlanCommercialName = createSelector(
  getAuthData,
  (data) => data?.plan?.commercialName || '',
);

export const getPlanTransactionFee = createSelector(
  getAuthData,
  (data) => data?.plan?.transactionFee || 0,
);

export const getPlanId = createSelector(
  getAuthData,
  (data) => data?.plan?.id || '',
);

export const getHasTransactionFeesForMX = createSelector(
  getAuthData,
  (data) => data?.plan?.hasTransactionFees || false,
);

export const getIsNext = createSelector(
  getAuthData,
  (data) => data?.plan?.isNext || false,
);

export const getIsEvolucion = createSelector(
  getAuthData,
  (data) => data?.plan?.isEvolucion || false,
);

export const getIsFreemium = createSelector(
  getAuthData,
  (data) => data?.plan?.isFreemium || false,
);

export const getPlanContext = createSelector(
  getAuthData,
  (data) => data?.plan?.context || '',
);

export const getIsLojinha = createSelector(getAuthData, (data) => {
  const niceName = data?.plan?.niceName;

  if (!niceName) {
    return false;
  }

  const lojinhaSubplans: string[] = [
    PlanLojinha.PLAN_BASICO,
    PlanLojinha.PLANO_BASICO,
  ];
  return lojinhaSubplans.includes(niceName);
});

export const getDefaultCurrency = createSelector(
  getAuthData,
  (data) => data?.defaultCurrency || Currency.ARS,
);

export const getRoles = createSelector(
  getAuthData,
  (data) => data?.roles || [],
);

export const getRolesWithoutFilters = createSelector(
  getAuthData,
  (data) => data?.rolesWithoutFilter || data?.roles || [],
);

export const getCompatibilityVersion = createSelector(
  getAuthData,
  (data) => data?.compatibilityVersion || '',
);

export const isMobile = createSelector(getAuth, (data) => data.isMobile);

export const getAllLanguages = createSelector(
  getAuthData,
  (authData) => authData?.languages || (['es'] as LanguagesType[]),
);

export const getAdminDisabled = createSelector(
  getAuthData,
  (authData) => authData?.adminDisabled || false,
);

export const getAdminDisabledReason = createSelector(
  getAuthData,
  (authData) => authData?.adminDisabledReason,
);

export const getTransactionFeeAdminDisabled = createSelector(
  getAuthData,
  (authData) => authData?.transactionFeeAdminDisabled || false,
);

export const getNeedsCloudflareMigration = createSelector(
  getAuthData,
  (authData) => authData?.needsCloudflareMigration || false,
);

export const getCloudflareBlocked = createSelector(
  getAuthData,
  (authData) => authData?.cloudflareBlocked || false,
);

export const getCloudflareHardBlocked = createSelector(
  getAuthData,
  (authData) => authData?.cloudflareHardBlocked || false,
);

export const getCanChangePlanForFree = createSelector(
  getAuthData,
  (authData) => authData?.canChangePlanForFree,
);

export const getIsSeller = createSelector(
  getAuthData,
  (authData) => authData?.isSeller || false,
);

export const getPhone = createSelector(
  getAuthData,
  (data) => data?.phone || '',
);

export const getIsFreePlan = createSelector(
  getAuthData,
  (data) => data?.plan?.isFreePlan || false,
);

export const getPlanExpiresIn = createSimpleSelector(
  [getAuthData],
  (data) => data?.plan?.expiresIn,
);

export const getVisitedAfterPaying = createSelector(
  getAuthData,
  (data) => data?.visitedAfterPaying || false,
);

export const getQualityLeadShouldNotify = createSimpleSelector(
  [getAuthData],
  (data) => data?.qualityLeadShouldNotify,
);

export const getStoreStatus = createSimpleSelector(
  [getAuthData],
  (data) => data?.status,
);

export const getAdminDisableDate = createSimpleSelector(
  [getAuthData],
  (data) => data?.adminDisableDate,
);

export const getStorefrontDisableDate = createSimpleSelector(
  [getAuthData],
  (data) => data?.storefrontDisableDate,
);

export const getHasFtp = createSimpleSelector(
  [getAuthData],
  (data) => data?.hasFtp || false,
);

export const getRemainingDaysToExpiration = createSimpleSelector(
  [getAuthData],
  (data) => data?.remainingDaysToExpiration,
);

export const getMainUserId = createSimpleSelector(
  [getAuthData],
  (data) => data?.mainUserId,
);

export const getCanEnterAdmin = createSimpleSelector(
  [getAuthData],
  (data) => data?.canEnterAdmin,
);

export const getShowExpirationBanner = createSimpleSelector(
  [getAuthData],
  (data) => data?.showExpirationBanner ?? false,
);

export const getIsAlmostExpired = createSimpleSelector(
  [getAuthData],
  (data) => data?.isAlmostExpired || false,
);

export const getIsEmbeddedApp = createSimpleSelector(
  [getAuth],
  (data) => data.isEmbeddedApp,
);

export const getStorePaymentDiscount = createSelector(
  getAuthData,
  (authData) => authData?.storePaymentDiscount || null,
);
