import { useMemo } from 'react';
import { Box, Card, Checkbox, Text, Title } from '@nimbus-ds/components';
import {
  CurrencyType,
  FulfillmentOrdersResponseDto,
  OrderDetailsAmountsDto,
} from '@tiendanube/common';
import { FulfillmentPreferenceType } from '@tiendanube/common/src/enums';
import { CurrencyPrice } from 'commons/components';
import useTranslationOrders from 'domains/Orders/useTranslationOrders';
import { SummaryMessage } from './SummaryMessage';
import { SummaryBreakdown } from './SummarySubtotal';
import { useShippingCosts } from '../../hooks';
import { FulfillmentsSummaryItem } from '../FulfillmentsSummary/FulfillmentsSummaryItem';
import { OrderEditProduct } from '../Products';

interface EditOrderSummaryProps {
  readonly orderAmounts: OrderDetailsAmountsDto;
  readonly products: OrderEditProduct[];
  readonly currency: CurrencyType;
  readonly paymentMethod: string;
  readonly isInsufficientStock?: boolean;
  readonly isOrderEdited?: boolean;
  readonly notifyCustomer: boolean;
  readonly handleNotifyCustomer: () => void;
  readonly fulfillments?: FulfillmentOrdersResponseDto[];
}

function EditOrderSummary({
  orderAmounts,
  products,
  currency,
  paymentMethod,
  isInsufficientStock,
  isOrderEdited,
  notifyCustomer,
  handleNotifyCustomer,
  fulfillments,
}: EditOrderSummaryProps) {
  const t = useTranslationOrders();
  const {
    allFulfillmentsShippingCosts: shippingCosts,
    allFulfillmentsShippingCostsStatus: shippingCostsStatus,
  } = useShippingCosts(undefined);
  const firstFailedShippingCost = useMemo(
    () => shippingCostsStatus.find((sc) => sc.status === 'error')?.ffooId,
    [shippingCostsStatus],
  );

  const fulfillmentsProducts = useMemo(
    () =>
      fulfillments?.map((ffoo) => ({
        ffooId: ffoo.id,
        ffooName: ffoo.assignedLocation.name,
        ffooProducts: products.filter(
          (product) => product.fulfillmentOrderId === ffoo.id,
        ),
      })) ?? [],
    [products, fulfillments],
  );

  const fulfillmentWithoutProducts = useMemo(
    () =>
      fulfillmentsProducts.find((f) => !f.ffooProducts.length)?.ffooName ??
      (!products.length ? '' : undefined),
    [products, fulfillmentsProducts],
  );

  const shippableFulfillments = useMemo(
    () =>
      fulfillments?.filter(
        (ffoo) =>
          ![
            FulfillmentPreferenceType.DELIVERY_DIGITAL,
            FulfillmentPreferenceType.NON_SHIPPABLE,
          ].includes(ffoo.shipping.type),
      ),
    [fulfillments],
  );

  const failedFulfillmentCotization = useMemo(
    () =>
      fulfillments?.find((f) => f.id === firstFailedShippingCost)
        ?.assignedLocation.name ?? firstFailedShippingCost,
    [firstFailedShippingCost, fulfillments],
  );

  const newSubtotal = useMemo(
    () => products.reduce((acc, product) => acc + product.totalPrice, 0),
    [products],
  );

  const totalProducts = useMemo(
    () => products.reduce((acc, product) => acc + product.quantity, 0),
    [products],
  );

  const totalPaid = orderAmounts.paidByCustomer;

  const newShippingCost = useMemo(() => {
    if (!fulfillments) return orderAmounts.shipping ?? 0;
    return fulfillments?.reduce((acc, ffo) => {
      const newFFOCost = shippingCosts.find(
        (sc) => sc.fulfillmentOrderId === ffo.id,
      );
      const costValue =
        newFFOCost?.costs.merchantCost.amount ??
        ffo.shipping.merchantCost.value;
      acc += costValue;
      return acc;
    }, 0);
  }, [fulfillments, shippingCosts, orderAmounts]);

  const totalDiscount = useMemo(
    () =>
      (orderAmounts.discount ?? 0) +
      (orderAmounts.couponDiscount?.amount ?? 0) +
      (orderAmounts.otherDiscounts?.reduce(
        (acc, discount) => acc + discount.amount,
        0,
      ) ?? 0) +
      (orderAmounts.gatewayDiscount ?? 0),
    [orderAmounts],
  );

  const newTotal = useMemo(() => {
    const newTotalWithoutDiscounts = newSubtotal + newShippingCost;
    return newTotalWithoutDiscounts > totalDiscount
      ? newTotalWithoutDiscounts - totalDiscount
      : newTotalWithoutDiscounts;
  }, [newShippingCost, totalDiscount, newSubtotal]);

  return (
    <Card padding="none">
      <Box paddingX="4" paddingY="2">
        <Box paddingX="2" paddingBottom="2">
          <Title fontSize="5">{t('editOrders.summary.title')}</Title>
          <Box
            display="flex"
            flexDirection="row"
            paddingTop="1"
            paddingBottom="1"
            justifyContent="space-between"
          >
            <Box flex="2" alignItems="center" justifyContent="space-between">
              <Text color="neutral-textHigh">
                {t(`editOrders.summary.subtotal`, { count: totalProducts })}
              </Text>
            </Box>
            <CurrencyPrice price={newTotal} currency={currency} size="base" />
          </Box>

          {shippableFulfillments ? (
            shippableFulfillments.map((ffo: FulfillmentOrdersResponseDto) => (
              <FulfillmentsSummaryItem
                key={ffo.id}
                fulfillmentOrder={ffo}
                fulfillmentProducts={
                  fulfillmentsProducts.find((f) => f.ffooId === ffo.id)
                    ?.ffooProducts ?? []
                }
                totalShippingCost={orderAmounts.shipping ?? 0}
                currency={currency}
              />
            ))
          ) : (
            <FulfillmentsSummaryItem
              totalShippingCost={orderAmounts.shipping ?? 0}
              fulfillmentProducts={products}
              currency={currency}
            />
          )}
        </Box>

        <SummaryBreakdown
          amounts={orderAmounts}
          currency={currency}
          paymentMethod={paymentMethod}
        />

        <Box
          paddingTop="2"
          paddingBottom="2"
          borderTopWidth="1"
          borderStyle="solid"
          borderColor="neutral-surfaceHighlight"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            paddingY="1"
            paddingX="2"
          >
            <Text color="neutral-textHigh" fontWeight="bold">
              {newTotal === orderAmounts.total
                ? t('editOrders.summary.total')
                : t('editOrders.summary.updatedTotal')}
            </Text>
            <CurrencyPrice price={newTotal} currency={currency} bold />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            paddingTop="1"
            paddingX="2"
          >
            <Text color="neutral-textHigh">{t('editOrders.summary.paid')}</Text>
            <CurrencyPrice price={totalPaid} currency={currency} />
          </Box>
          {isOrderEdited && (
            <Box paddingTop="2">
              <SummaryMessage
                previousTotal={totalPaid}
                newTotal={newTotal}
                currency={currency}
                isInsufficientStock={isInsufficientStock}
                fulfillmentWithoutProducts={fulfillmentWithoutProducts}
                failedFulfillmentCotization={failedFulfillmentCotization}
              />
            </Box>
          )}
          {isOrderEdited && (
            <Box paddingTop="4" paddingX="2">
              <Checkbox
                label={t('editOrders.sendNotification')}
                name="sendNotification"
                checked={notifyCustomer}
                onChange={handleNotifyCustomer}
                disabled={!isOrderEdited}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Card>
  );
}

export default EditOrderSummary;
