import { Card, Text, Link, Stack } from '@tiendanube/components';
import { EditIcon, PlusCircleIcon } from '@tiendanube/icons';
import { CATALOG_HIDING_VARIANTS } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { productHasVariants } from 'domains/Catalog/Products/utils';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { AllVariantsDisabledAlert, BrokenVariantsAlert } from './components';
import DuplicateAttributesAlert from './components/DuplicateAttributesAlert';
import useDuplicateAttributes from '../../hooks/useDuplicateAttributes';
import {
  Variant,
  OnChangeToAllVariants,
  BrokenVariantsAlertType,
  Attributes,
  InventoryLevelsInterface,
} from '../../types';
import AttributesDuplicatePreview from '../AttributesDuplicatePreview';
import AttributesPreview from '../AttributesPreview';
import VariantsPreview from '../VariantsPreview';

export interface VariantListItemInterface {
  id: string;
  imageUrl: string;
  name: string;
  stock: string | null;
  price: string;
  weight: string;
  depth: string;
  width: string;
  height: string;
  isInfinite: boolean;
  productId: string;
  locationId?: string;
  inventoryLevels?: InventoryLevelsInterface[];
  visible: boolean;
}

interface CardVariantProps {
  variants: VariantListItemInterface[];
  attributes: Attributes;
  isEdit?: boolean;
  onChangeAttributes: (attributes: Attributes) => void;
  onClickVariant: (index: number) => void;
  onClickImage: (index: number) => void;
  onClickAttributes: () => void;
  onChange: (i: number, v: Partial<Variant>) => void;
  onChangeToAllVariants: OnChangeToAllVariants;
  brokenVariantsAlert?: BrokenVariantsAlertType;
  isDigital?: boolean;
}

function CardVariants({
  attributes,
  variants,
  isEdit,
  onClickVariant,
  onClickImage,
  onClickAttributes,
  onChange,
  onChangeAttributes,
  onChangeToAllVariants,
  brokenVariantsAlert,
  isDigital = false,
}: CardVariantProps): JSX.Element {
  const t = useTranslationCatalog();

  const hasVariants = productHasVariants(variants);
  const [hasHidingVariants] = useHasTags([CATALOG_HIDING_VARIANTS]);

  const productId = variants[0].productId;

  const {
    withDuplicateAttributesMainLanguage,
    withDuplicateAttributesSecondaryLanguage,
  } = useDuplicateAttributes(productId);
  const hasVariantWithError =
    hasVariants && withDuplicateAttributesMainLanguage;
  const withDuplicateAttributes =
    withDuplicateAttributesMainLanguage ||
    withDuplicateAttributesSecondaryLanguage;

  const allVariantsDisabledAlert =
    hasHidingVariants &&
    variants.filter((variant) => variant.visible).length === 0;

  return (
    <Card title={t('products.detail.variants')}>
      <Stack column align="stretch">
        {allVariantsDisabledAlert && <AllVariantsDisabledAlert />}
        {withDuplicateAttributes && <DuplicateAttributesAlert id={productId} />}
        {!!brokenVariantsAlert && (
          <BrokenVariantsAlert alertType={brokenVariantsAlert} />
        )}
        {hasVariants && !withDuplicateAttributesMainLanguage && (
          <AttributesPreview
            attributes={attributes}
            onChangeAttributes={onChangeAttributes}
          />
        )}
        {withDuplicateAttributesMainLanguage && (
          <AttributesDuplicatePreview id={productId} />
        )}
        <Stack.Item>
          {hasVariants ? (
            !withDuplicateAttributesMainLanguage && (
              <VariantsPreview
                onChange={onChange}
                variants={variants}
                isEdit={isEdit}
                onClickImage={onClickImage}
                onClickVariant={onClickVariant}
                onChangeToAllVariants={onChangeToAllVariants}
                isDigital={isDigital}
              />
            )
          ) : (
            <Text>{`${t('products.detail.infoVariants')}`}</Text>
          )}
        </Stack.Item>
        {!hasVariantWithError && (
          <Link
            icon={hasVariants ? EditIcon : PlusCircleIcon}
            iconPosition="start"
            appearance="primary"
            onClick={onClickAttributes}
          >
            {hasVariants
              ? t('products.detail.editVariants')
              : t('products.detail.addVariants')}
          </Link>
        )}
      </Stack>
    </Card>
  );
}

export default CardVariants;
