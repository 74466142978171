import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Domain } from '@tiendanube/common/src/enums';
import { Popover } from '@tiendanube/components';
import { TrashIcon } from '@tiendanube/icons';
import {
  ActionProp,
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components/IonPageStratus';
import { useModal } from 'commons/hooks';
import {
  DraftOrderDetails,
  DraftOrderDetailsSkeleton,
  DraftOrderDetailsError,
  ConfirmationRemoveDraftOrderModal,
} from './components';
import { useDraftOrderDetails } from '../../hooks';

function DraftOrderDetailsPage(): JSX.Element {
  const { t } = useTranslation(Domain.ORDERS);
  const history = useHistory();
  const id = useParams<{ id: string }>().id;
  const {
    draftOrderDetails,
    isLoading,
    isError,
    isSuccess,
    confirmDraftOrder,
    fetchDraftOrderDetails,
    removeDraftOrder,
  } = useDraftOrderDetails(id);
  const [showRemove, openRemove, closeRemove] = useModal();

  const headerNavigation = {
    onClick: history.goBack,
  };

  const actionsMobile: ActionProp[] = [
    {
      children: t('draftOrders.delete'),
      onClick: openRemove,
      appearance: 'danger',
      icon: TrashIcon,
    },
  ];

  const handleRemoveDraftOrder = async () => {
    closeRemove();
    if (draftOrderDetails) await removeDraftOrder(draftOrderDetails.id);
    history.replace('/draft-orders');
  };

  const renderTitle = isLoading
    ? 'skeleton'
    : isError
    ? ''
    : `#${draftOrderDetails?.id}`;

  const renderMenuMobile = !(isLoading || isError) && (
    <Popover menu={actionsMobile} name="dropdownMenu" position="right" />
  );

  return (
    <IonPageStratus
      width="medium"
      headerTop={
        <HeaderTop navigation={headerNavigation} actions={renderMenuMobile} />
      }
      headerContent={
        <HeaderContent
          title={renderTitle}
          documentTitle={t('detail.subTitleMain')}
        />
      }
    >
      {isLoading && <DraftOrderDetailsSkeleton />}
      {isError && (
        <DraftOrderDetailsError onRetryError={fetchDraftOrderDetails} />
      )}
      {isSuccess && draftOrderDetails && (
        <DraftOrderDetails
          draftOrderDetails={draftOrderDetails}
          confirmDraftOrder={confirmDraftOrder}
        />
      )}

      {showRemove && (
        <ConfirmationRemoveDraftOrderModal
          onClose={closeRemove}
          onRemoveDraftOrder={handleRemoveDraftOrder}
        />
      )}
    </IonPageStratus>
  );
}

export default DraftOrderDetailsPage;
