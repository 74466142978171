import { DataTable } from '@tiendanube/components';
import Text from 'commons/components/LegacyText';

const { Row, Cell } = DataTable;

function ResultRowDesktopSkeleton(): JSX.Element {
  return (
    <Row id="skeleton-row" align="center">
      <Cell basis={100}>
        <div className="nimbus--data-table__cell-vertical-centered">
          <Text.Skeleton />
        </div>
      </Cell>
      <Cell basis={200} grow={0}>
        <div className="nimbus--data-table__cell-vertical-centered">
          <Text.Skeleton />
        </div>
      </Cell>
      <Cell basis={200} grow={0}>
        <div className="nimbus--data-table__cell-vertical-centered">
          <Text.Skeleton />
        </div>
      </Cell>
      <Cell basis={100}>
        <div className="nimbus--data-table__cell-vertical-centered">
          <Text.Skeleton />
        </div>
      </Cell>
    </Row>
  );
}

export default ResultRowDesktopSkeleton;
