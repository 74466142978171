import { useCallback } from 'react';
import { convertStatusTypeToObject } from 'commons/utils/convertStatusTypeToObject';
import { usePostAdvancedChargeMutation } from 'domains/Billing/Checkout/CheckoutApi';
import { CHECKOUT_ROUTES } from 'domains/Billing/Checkout/checkoutRoutes';
import {
  useGetLastPayment,
  useRedirectOnSimilarCharge,
} from 'domains/Billing/Checkout/hooks';
import {
  trackingBillingStartPayment,
  useCheckoutTracking,
} from 'domains/Billing/Checkout/tracking';
import useContinueWithPayment from './useContinueWithPayment';

function useAdvancePlanCharge() {
  const [createAdvancedPlanCharge] = usePostAdvancedChargeMutation();
  const continueWithPayment = useContinueWithPayment();
  const { status, handleFunctionBehaviour } = useRedirectOnSimilarCharge(
    () => createAdvancedPlanCharge().unwrap(),
    CHECKOUT_ROUTES.plansAndSubscriptions,
  );

  const {
    isPending: planPending,
    status: setupStatus,
    refreshLastPayment: retrySetup,
  } = useGetLastPayment('plan-cost');
  const logEvent = useCheckoutTracking();

  const handlePayAdvancedPlanCharge = useCallback(() => {
    trackingBillingStartPayment(logEvent, false, 'plan-cost');
    if (planPending) {
      continueWithPayment();
    } else {
      handleFunctionBehaviour().then(() => {
        continueWithPayment();
      });
    }
  }, [continueWithPayment, planPending, logEvent, handleFunctionBehaviour]);

  return {
    createAdvancedPlanCharge,
    handlePayAdvancedPlanCharge,
    ...convertStatusTypeToObject(status),
    createStatus: status,
    setupStatus,
    retrySetup,
  };
}

export default useAdvancePlanCharge;
