import { TypeFeaturesPlansListResponseDto } from '@tiendanube/common';
import {
  TIER_FREE,
  TIER_1,
  TIER_2,
  TIER_3,
  TIER_TOP,
  TIER_DISABLED,
  TIER_LEGACY_1,
  TIER_LEGACY_2,
  TIER_LEGACY_3,
  TIER_LEGACY_TOP,
} from 'App/features';

function useCompareFeatureValues() {
  const tiers = [TIER_DISABLED, TIER_FREE, TIER_1, TIER_2, TIER_3, TIER_TOP];
  const tiersLegacy = [
    TIER_DISABLED,
    TIER_LEGACY_1,
    TIER_LEGACY_2,
    TIER_LEGACY_3,
    TIER_LEGACY_TOP,
  ];

  const meetsRequirement = (
    value: TypeFeaturesPlansListResponseDto,
    minValue: TypeFeaturesPlansListResponseDto,
  ) => {
    // -1 means infinite
    if (value === -1) {
      return true;
    }

    // Compare as numbers
    if (typeof value === 'number' && typeof minValue === 'number') {
      return value >= minValue;
    }

    // Compare as tiers
    if (typeof value === 'string' && typeof minValue === 'string') {
      // Remove after all features are migrated to the new tier system
      if (value.indexOf('legacy') !== -1) {
        const legacyMinValue = minValue.replace('-', '-legacy-');
        return (
          tiersLegacy.indexOf(value) >= tiersLegacy.indexOf(legacyMinValue)
        );
      }

      // For case when value is disabled and minValue is legacy
      if (minValue.indexOf('legacy') !== -1) {
        return tiersLegacy.indexOf(value) >= tiersLegacy.indexOf(minValue);
      }

      return tiers.indexOf(value) >= tiers.indexOf(minValue);
    }

    // Can't compare if the types don't match
    return false;
  };

  const negatableMeetsRequirement = (
    value: TypeFeaturesPlansListResponseDto,
    minValue: TypeFeaturesPlansListResponseDto,
    negate: boolean,
  ) => {
    const result = meetsRequirement(value, minValue);

    return negate ? !result : result;
  };

  return {
    meetsRequirement,
    negatableMeetsRequirement,
  };
}

export default useCompareFeatureValues;
