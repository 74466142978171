import { OrderFulfillmentsDto } from '@tiendanube/common';
import { OrderShippingMethod } from '@tiendanube/common/src/domains/orders/orders/OrderBulkResponseDto';
import Status from '@tiendanube/common/src/enums/Status';
import { getTodayIsoString, ninetyDaysAgo } from 'commons/utils/date';
import {
  defaultSavedSearches,
  DefaultSavedSearchesCode,
} from 'domains/Orders/components/SavedSearches/defaultSavedSearches';
import { SHIPPING_METHOD_CODE_OCA } from 'domains/Shipping/ShippingMethods/constants';
import {
  ExportFiltersType,
  FiltersType,
  fulfillmentStatusFilter,
  FulfillmentStatusType,
  paymentStatusFilter,
  StatusType,
} from '../../ordersService';

export const shouldShowMultiCDFullfillmentStatus = (
  statusType: FulfillmentStatusType,
  hasMultiCD: boolean,
) => {
  const multicdStatuses: FulfillmentStatusType[] = [
    Status.PARTIALLY_PACKED,
    Status.PARTIALLY_FULFILLED,
  ];
  return hasMultiCD || !multicdStatuses.includes(statusType);
};

export const fulfillmentOrdersFromSelectedOrderIds = (
  selectedOrderIds: string[],
  fulfillmentOrders: OrderFulfillmentsDto[],
) =>
  fulfillmentOrders.filter(({ orderId }) => selectedOrderIds.includes(orderId));

export const fulfillmentOrdersIdsFromSelectedOrderIds = (
  selectedOrderIds: string[],
  fulfillmentOrders: OrderFulfillmentsDto[],
) =>
  fulfillmentOrders.flatMap(({ orderId, fulfillmentIds }) =>
    selectedOrderIds.includes(orderId) ? fulfillmentIds : [],
  );

export const ordersWithoutFulfillmentOrders = (
  selectedOrderIds: string[],
  fulfillmentOrders: OrderFulfillmentsDto[],
) => {
  const filteredOrders = fulfillmentOrdersFromSelectedOrderIds(
    selectedOrderIds,
    fulfillmentOrders,
  );
  return filteredOrders.filter(
    ({ fulfillmentIds }) => fulfillmentIds.length === 0,
  );
};

export const shippingMethodFromOrdersBySelectedOrderIds = (
  selectedOrdersIds: string[],
  ordersShippingMethods: OrderShippingMethod[],
) =>
  ordersShippingMethods.filter(({ orderId }) =>
    selectedOrdersIds.includes(orderId),
  );

export const validateDateLimit = (values) => {
  const hasFiltersWithDateRestrictionsApplied =
    values.paymentMethods !== '' ||
    values.paymentProvider !== '' ||
    values.appId !== '';

  const dateFromIn90DaysRange =
    values.dateFrom === '' || values.dateFrom < ninetyDaysAgo;
  const dateToIn90DaysRange =
    values.dateTo === '' || values.dateTo < ninetyDaysAgo;

  const newDateFrom =
    dateFromIn90DaysRange && hasFiltersWithDateRestrictionsApplied
      ? ninetyDaysAgo
      : values.dateFrom;
  const newDateTo =
    dateToIn90DaysRange && hasFiltersWithDateRestrictionsApplied
      ? getTodayIsoString()
      : values.dateTo;

  return {
    ...values,
    dateFrom: newDateFrom,
    dateTo: newDateTo,
  };
};

export const checkCustomPaymentProvider = (key, value) =>
  key === 'paymentProvider' && value === 'custom';

export const hasOca = (orderShippingMethod: OrderShippingMethod) =>
  orderShippingMethod.shipping === SHIPPING_METHOD_CODE_OCA;

export const areFiltersEquals = (filters1, filters2) => {
  const keys = Object.keys(filters1);

  const filtersWithMultipleValues = [
    paymentStatusFilter,
    fulfillmentStatusFilter,
  ];

  for (const key of keys) {
    const val1 = filters1[key];
    const val2 = filters2[key];

    if (val1 === val2) {
      continue;
    }

    if (val1 === undefined || val2 === undefined) {
      return false;
    }

    if (key === 'status') {
      if (
        (val1 === '' && val2 === Status.OPEN) ||
        (val1 === Status.OPEN && val2 === '')
      ) {
        continue;
      }
      return false;
    }

    // Split comma-separated strings, trim whitespace, and sort them for comparison
    if (filtersWithMultipleValues.includes(key)) {
      const val1Set = val1
        .split(',')
        .map((v) => v.trim())
        .sort();
      const val2Set = val2
        .split(',')
        .map((v) => v.trim())
        .sort();

      if (
        val1Set.length !== val2Set.length ||
        !val1Set.every((value, index) => value === val2Set[index])
      ) {
        return false;
      }
    }
    return false;
  }

  return true;
};

export const transformReadyToArchiveFilter = (
  filters: FiltersType | ExportFiltersType,
) => {
  const savedSearch = defaultSavedSearches.filter(
    (savedSearch) =>
      savedSearch.code === DefaultSavedSearchesCode.READY_TO_ARCHIVE,
  )[0];

  const mergedPaymentStatusFilterValues = [
    // avoid duplicated values in case there are other filters already applied
    ...new Set([
      ...filters.paymentStatus.split(','),
      ...(savedSearch.filters.paymentStatus?.split(',') ?? []),
    ]),
  ]
    .filter((val) => !!val)
    .join(',');

  const mergedFulfillmentStatusFilterValues = [
    ...new Set([
      ...filters.fulfillmentStatus.split(','),
      ...(savedSearch.filters.fulfillmentStatus?.split(',') ?? []),
    ]),
  ]
    .filter((val) => !!val)
    .join(',');

  return {
    status: savedSearch.filters.status as StatusType,
    [paymentStatusFilter]: mergedPaymentStatusFilterValues,
    [fulfillmentStatusFilter]: mergedFulfillmentStatusFilterValues,
  };
};
