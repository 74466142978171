import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import {
  HeaderContent,
  HeaderTop,
  ImportInProcessModal,
  IonPageStratus,
} from 'commons/components';
import { HELP_LINKS_TO_IMPORT_AND_EXPORT_PRODUCTS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { ImportProductsCard } from './components';

interface ImportProductsPageProps {
  showUpdateOptions?: boolean;
}

function ImportProductsPage({
  showUpdateOptions = false,
}: ImportProductsPageProps) {
  const t = useTranslationCatalog();
  const language = useTranslationLanguage();
  const { goBack } = useNavegate();

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={{ onClick: goBack }} />}
      headerContent={
        <HeaderContent
          title={t('products.importExportPage.import.import')}
          subtitle={t('products.importExportPage.subtitle')}
        />
      }
      width="small"
    >
      <ImportInProcessModal>
        <ImportProductsCard showUpdateOptions={showUpdateOptions} />
      </ImportInProcessModal>
      <HelpLink
        title={t('products.importExportPage.helpLinkTitle')}
        previousValue=""
        currentViewTracking={t('products.importExportPage.helpLinkTracking')}
        linkURL={HELP_LINKS_TO_IMPORT_AND_EXPORT_PRODUCTS[language]}
        hasDisclaimer={false}
        icon="both"
      />
    </IonPageStratus>
  );
}

export default ImportProductsPage;
