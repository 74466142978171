import { useCallback } from 'react';
import { Box } from '@nimbus-ds/components';
import { EyeOffIcon } from '@nimbus-ds/icons';
import { LocationResponseDto } from '@tiendanube/common';
import { Label, Link, Popover } from '@tiendanube/components';
import { EditIcon, HistoryIcon } from '@tiendanube/icons';
import { ImageItemStratus, Stack } from 'commons/components';
import { useHasPermission } from 'domains/Auth/hooks';
import {
  useHasMultipleLocations,
  useTranslationCatalog,
} from 'domains/Catalog/hooks';
import { LinkStock } from 'domains/Catalog/Products/pages/components/Stock/components';
import { useStockHistoryContext } from 'domains/Catalog/Products/pages/components/StockHistoryModal/hooks';
import { Variant } from 'domains/Catalog/Products/pages/components/Variants/types';
import { useShowNoWeightAndDimension } from 'domains/Catalog/Products/pages/hooks';
import { VariantListItemInterface } from '../../../../../CardVariants';
import VariantLocationTag from '../../../../../VariantLocationTag';
import VariantMissingDataTag from '../../../VariantMissingDataTag';

export interface VisibleVariantInfoMobileProps {
  variant: VariantListItemInterface;
  locations: LocationResponseDto[];
  index: number;
  isDigital: boolean;
  onChange: (i: number, v: Partial<Variant>) => void;
  onClickImage: (index: number) => void;
  onClickVariant: (index: number) => void;
  onOpenVariantStock: (variant: VariantListItemInterface) => void;
}

function VisibleVariantInfoMobile({
  variant,
  locations,
  index,
  isDigital,
  onChange,
  onClickImage,
  onClickVariant,
  onOpenVariantStock,
}: VisibleVariantInfoMobileProps) {
  const {
    id: variantId,
    weight,
    depth,
    width,
    height,
    name,
    imageUrl,
    visible,
  } = variant;
  const showNoWeightAndDimension = useShowNoWeightAndDimension({
    variantId,
    weight: weight,
    depth: depth,
    width: width,
    height: height,
  });

  const t = useTranslationCatalog();
  const hasMultipleLocations = useHasMultipleLocations(locations);
  const { openStockHistoryModal, showAccessToStockHistory } =
    useStockHistoryContext();
  const canEditStock = useHasPermission('edit_stock');

  const rightPopoverItem = useCallback(
    (
      onClickVariant: (index: number) => void,
      variant: VariantListItemInterface,
      index: number,
    ) => {
      const menu = [
        {
          children: t('products.variants.editVariant'),
          icon: EditIcon,
          onClick: () => onClickVariant(index),
        },
      ];
      if (showAccessToStockHistory && variant.id) {
        menu.push({
          children: t('products.detail.goToStockHistory'),
          icon: HistoryIcon,
          onClick: () =>
            openStockHistoryModal({
              variantId: variant.id,
              variantName: variant.name,
              productId: variant.productId,
            }),
        });
      }
      return menu;
    },
    [t, showAccessToStockHistory, openStockHistoryModal],
  );

  const handleShowVariant = () => onChange(index, { visible: true });

  return (
    <ImageItemStratus
      id={variantId}
      title={name}
      imageUrl={imageUrl}
      altText={name}
      onClickImage={() => onClickImage(index)}
      onClickTitle={() => onClickVariant(index)}
      headerRight={
        <Stack>
          <Stack.Item>
            <Popover
              name="menuVariants"
              position="right"
              menu={rightPopoverItem(onClickVariant, variant, index)}
            />
          </Stack.Item>
        </Stack>
      }
    >
      <Stack column spacing="tight" align="flex-start">
        {visible && (
          <LinkStock
            variant={variant}
            onEditStock={() => onOpenVariantStock(variant)}
            canEditStock={canEditStock}
          />
        )}
        <Box display="flex" flexDirection="row" gap="1">
          {!visible && (
            <Stack column align="flex-start" spacing="tight">
              <Label
                id={variantId}
                label={`${t('products.productHiddenAndWithoutStock.hidden')}`}
                appearance="warning"
                icon={EyeOffIcon}
              />
              <Link appearance="primary" onClick={handleShowVariant}>
                {t('products.detail.showVariant')}
              </Link>
            </Stack>
          )}
          {visible && hasMultipleLocations && (
            <Stack align="stretch" spacing="none">
              <VariantLocationTag variant={variant} locations={locations} />
            </Stack>
          )}
          {visible && showNoWeightAndDimension && !isDigital && (
            <VariantMissingDataTag />
          )}
        </Box>
      </Stack>
    </ImageItemStratus>
  );
}

export default VisibleVariantInfoMobile;
