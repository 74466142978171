import { ChargeDto, ConceptCode } from '@tiendanube/common';

const MAIN_CONCEPTS: ConceptCode[] = [
  'plan-cost',
  'app-cost',
  'app-extra-fee',
  'plan-offline-cost',
  'transaction-fee',
  'shipping-fee',
  'shipping-cost',
  'plan-cost-upgrade',
];

export function getConceptsFromCharges(charges: ChargeDto[]): Set<ConceptCode> {
  return new Set(charges.map(({ conceptCode }) => conceptCode));
}

export function getMainConceptFromCharges(charges: ChargeDto[]) {
  const conceptsToPay = Array.from(getConceptsFromCharges(charges));
  return (
    MAIN_CONCEPTS.find((concept) => conceptsToPay.includes(concept)) ||
    conceptsToPay[0]
  );
}
