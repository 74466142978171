import { Tooltip, IconButton } from '@nimbus-ds/components';
import { EditIcon, EllipsisIcon, TrashIcon } from '@nimbus-ds/icons';
import { Popover } from '@tiendanube/components';
import { ModalConfirmationBase } from 'App/components';
import { useResponsive } from 'commons/components';
import Stack from 'commons/components/Stack';
import { useModal } from 'commons/hooks';
import { useTranslationOnline } from 'domains/Online';

interface ActionsProps {
  onDelete: () => void;
  onEdit: () => void;
}

function Actions({ onDelete, onEdit }: ActionsProps) {
  const t = useTranslationOnline('menus.list.menuCard.actions');
  const { isMobile } = useResponsive();
  const [showDeleteModal, openDeleteModal, closeDeleteModal] = useModal();

  const handleEdit = () => onEdit();

  const handleDelete = () => openDeleteModal();

  const menu = [
    {
      children: t('edit'),
      onClick: handleEdit,
      icon: EditIcon,
    },
    {
      children: t('delete'),
      onClick: handleDelete,
      icon: TrashIcon,
    },
  ];

  return (
    <>
      <Stack spacing="tight">
        {isMobile ? (
          <Popover
            name="Menu"
            menu={menu}
            position="right"
            renderInitiator={(onClick) => (
              <Tooltip content={t('options')}>
                <IconButton
                  size="2rem"
                  source={<EllipsisIcon />}
                  onClick={onClick}
                />
              </Tooltip>
            )}
          />
        ) : (
          <>
            <Tooltip content={t('edit')}>
              <IconButton
                size="2rem"
                source={<EditIcon />}
                onClick={handleEdit}
              />
            </Tooltip>
            <Tooltip content={t('delete')}>
              <IconButton
                size="2rem"
                source={<TrashIcon />}
                onClick={handleDelete}
              />
            </Tooltip>
          </>
        )}
      </Stack>
      <ModalConfirmationBase
        show={showDeleteModal}
        title={t('deleteMenuItemModal.title')}
        text={t('deleteMenuItemModal.info')}
        label={t('deleteMenuItemModal.confirmButton')}
        labelCancel={t('deleteMenuItemModal.cancelButton')}
        onConfirm={onDelete}
        onCancel={closeDeleteModal}
      />
    </>
  );
}

export default Actions;
