import { useMemo } from 'react';
import { FEATURE_ORDER_PRODUCTS_FILTER } from 'App/features';
import { useStatusFeatureByKeyWithDefaultValue } from 'domains/Auth/hooks';

function useIsProductsFilterEnabled() {
  const { isAvailable } = useStatusFeatureByKeyWithDefaultValue(
    FEATURE_ORDER_PRODUCTS_FILTER,
    0,
  );

  const isEnabled = useMemo(() => isAvailable, [isAvailable]);

  return isEnabled;
}

export default useIsProductsFilterEnabled;
