import { useEffect, useState } from 'react';
import { Input, Textarea } from '@nimbus-ds/components';
import { CATALOG_MF_OPEN_AS_TEXTAREA } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { ItemProps } from 'domains/Metafields/components/MetafieldInput/types';
import { useTranslationsMetafields } from 'domains/Metafields/hooks';
import useMergeMetafieldsEvents from 'domains/Metafields/hooks/useMergeMetafieldsEvents';

function TextItem({
  metafield,
  metafieldKey,
  disabled,
  value,
  onChange,
  onUpdate,
  placeholder,
}: ItemProps) {
  const [internalValue, setInternalValue] = useState(value);
  const [hasTextArea] = useHasTags([CATALOG_MF_OPEN_AS_TEXTAREA]);
  const t = useTranslationsMetafields();
  const inputPlaceholder =
    placeholder || t('assignMetafieldsForm.placeholders.textOrNumber');

  const handleChange = ({ target: { value } }) => {
    setInternalValue(value);
    onChange?.(value);
  };

  const handleBlur = ({ target: { value } }) => {
    setInternalValue(value);
    onUpdate?.(value || null);
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const inputEvents = {
    onChange: handleChange,
    onBlur: handleBlur,
  };

  const mergeEvents = useMergeMetafieldsEvents(
    inputEvents,
    metafield.formFieldConfig?.events,
  );
  const mergedEvents = mergeEvents();
  const Component = hasTextArea ? Textarea : Input;
  return (
    <Component
      disabled={disabled !== undefined ? disabled : metafield.disabled}
      id={metafieldKey}
      name={metafield.name}
      value={internalValue}
      placeholder={inputPlaceholder}
      lines={(internalValue ?? '').split('\n').length}
      maxLength={250}
      {...mergedEvents}
    />
  );
}

export default TextItem;
