import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, Text, Title, Box, Icon, Button } from '@nimbus-ds/components';
import { PencilIcon } from '@nimbus-ds/icons';
import { Country } from '@tiendanube/common/src/enums';
import ModalAside from 'App/components/lab/ModalAside';
import ShowByCountry from 'App/components/ShowByCountry';
import { FEATURE_METAFIELDS_CUSTOMER } from 'App/features';
import HelpLink from 'App/HelpLink';
import { useNavegate } from 'App/hooks';
import { Stack } from 'commons/components';
import { HELP_LINKS_CUSTOMER_FILTERS } from 'commons/constants';
import { useTranslationLanguage } from 'commons/hooks';
import { CUSTOMERS_SHOW_FILTERS } from 'config/upsellFlowSources';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import {
  CustomersFiltersType,
  defaultFilters,
} from 'domains/Customers/Customers/customersServices';
import { useTranslationCustomers } from 'domains/Customers/hooks';
import { trackingCustomerFilter } from 'domains/Customers/tracking';
import { MetafieldsFilters } from 'domains/Metafields/components';
import { useGetCustomerMetafields } from 'domains/Metafields/hooks';
import { useAdvancedCustomerFilter, useCustomerResults } from '../../hooks';

interface FilterModalProps {
  show: boolean;
  closeModal: () => void;
  filters: CustomersFiltersType;
  getCustomers: (customFilters: CustomersFiltersType) => void;
  advancedSearchCustomers: (customFilters: CustomersFiltersType) => void;
}

export default function FilterModal({
  show,
  closeModal,
  filters,
  getCustomers,
  advancedSearchCustomers,
}: Readonly<FilterModalProps>) {
  const [hasCalledApplyFilters, setHasCalledApplyFilters] =
    useState<boolean>(false);
  const t = useTranslationCustomers();
  const language = useTranslationLanguage();
  const { hasInternals, metafields, apiMetafields, status, fetchMetafields } =
    useGetCustomerMetafields();
  const { values, selecteds, handleChangeMetafields, setAllValues } =
    useAdvancedCustomerFilter(filters);
  const { pagination, isRefreshing, isRefreshError } = useCustomerResults();
  const { goTo } = useNavegate();

  useEffect(() => {
    if (hasCalledApplyFilters && !isRefreshing) {
      trackingCustomerFilter(pagination.totalResults, isRefreshError);
      setHasCalledApplyFilters(false);
    }
  }, [
    hasCalledApplyFilters,
    isRefreshing,
    isRefreshError,
    pagination.totalResults,
  ]);

  const handleCreateMetafield = useUpsellFlow({
    title: t('customerListPage.upsell.filters'),
    featureKey: FEATURE_METAFIELDS_CUSTOMER,
    trackingSource: CUSTOMERS_SHOW_FILTERS,
    asAside: true,
    callback: () => goTo('/settings/metafields'),
  });

  const handleApply = useCallback(() => {
    const hasMetafieldsFilters = Object.keys(values).some((value) =>
      value.startsWith('mf-'),
    );

    setHasCalledApplyFilters(true);
    if (hasMetafieldsFilters) {
      advancedSearchCustomers({
        ...values,
      });
    } else {
      getCustomers({ ...values });
    }
    closeModal();
  }, [
    values,
    closeModal,
    advancedSearchCustomers,
    getCustomers,
    setHasCalledApplyFilters,
  ]);

  const handleApplyWithUpsell = useUpsellFlow({
    title: t('customerListPage.upsell.filters'),
    featureKey: FEATURE_METAFIELDS_CUSTOMER,
    trackingSource: CUSTOMERS_SHOW_FILTERS,
    asAside: true,
    callback: handleApply,
  });

  const handleReset = () => {
    setAllValues(defaultFilters);
  };

  const allMetafields = useMemo(
    () =>
      [...(metafields ?? []), ...(apiMetafields ?? [])].sort((a, b) =>
        a.name.localeCompare(b.name),
      ),
    [metafields, apiMetafields],
  );

  return (
    <ModalAside
      isOpen={show}
      onDidDismiss={closeModal}
      headerContent={t('customerListPage.filterModal.title')}
    >
      {hasInternals || apiMetafields ? (
        <>
          <MetafieldsFilters
            metafields={allMetafields || []}
            status={status}
            selecteds={selecteds}
            onChange={handleChangeMetafields}
            onFetch={fetchMetafields}
          />
          <Stack spacing="base" justify="flex-end">
            <Stack.Item>
              <Button onClick={handleReset}>
                {t('customerListPage.filterModal.cleanFilter')}
              </Button>
            </Stack.Item>
            <Stack.Item>
              <Button onClick={handleApplyWithUpsell} appearance="primary">
                {t('customerListPage.filterModal.filter')}
              </Button>
            </Stack.Item>
          </Stack>
        </>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" gap="2">
          <Box marginBottom="2">
            <Icon source={<PencilIcon size="large" />} />
          </Box>
          <Title as="h4">
            {t('customerListPage.filterModal.emptyState.title')}
          </Title>
          <Text textAlign="center">
            {t('customerListPage.filterModal.emptyState.description')}
          </Text>
          <Link
            appearance="primary"
            textDecoration="none"
            fontSize="highlight"
            onClick={handleCreateMetafield}
          >
            {t('customerListPage.filterModal.emptyState.link')}
          </Link>
        </Box>
      )}
      <ShowByCountry allowedCountries={[Country.BR]}>
        <HelpLink
          title={t('customerListPage.filterModal.helpLink.title')}
          previousValue=""
          currentViewTracking=""
          linkURL={HELP_LINKS_CUSTOMER_FILTERS[language]}
          icon="both"
        />
      </ShowByCountry>
    </ModalAside>
  );
}
