import { useEffect } from 'react';
import { Alert, Button, Link, Text } from '@nimbus-ds/components';
import { muvemPagoOrPagoNubeIds } from '@tiendanube/common/src/domains/payments/providers/PaymentProvidersCode';
import { Country } from '@tiendanube/common/src/enums';
import { SMART_PAYMENT, SMART_PAYMENT_ADMIN } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { useGetCountry } from 'domains/Auth/hooks';
import { NUVEMPAGO_TRANSPARENT_CHECKOUT_IN_ADVANCED_SETTINGS_FEATURE_FLAG } from 'domains/NuvemPagoCommons/constants';
import { useNuvemPagoFeatureFlag } from 'domains/NuvemPagoCommons/hooks/useNuvemPagoFeatureFlag';
import {
  useGetPaymentProviderSettingsRoute,
  useRecentlyEnabled,
} from 'domains/Payments/PaymentProviders/hooks';
import { invokeEventPayments } from 'domains/Payments/tracking';
import useTranslationPayments from 'domains/Payments/useTranslationPayments';

const TRANSLATE_PREFIX = 'paymentProviders.recentlyInstalledAlert';

interface ActivationAlertProps {
  readonly translationKey: string;
  readonly onRemove: () => void;
  readonly onClick: () => void;
  readonly actionElement?: 'button' | 'link';
}

function ActivationAlert({
  translationKey,
  onRemove,
  onClick,
  actionElement = 'button',
}: ActivationAlertProps): JSX.Element {
  const t = useTranslationPayments(TRANSLATE_PREFIX);

  return (
    <Alert
      show
      title={t(`${translationKey}.title`)}
      appearance="success"
      onRemove={onRemove}
    >
      <Text>{t(`${translationKey}.body`)}</Text>
      {actionElement === 'button' ? (
        <Button appearance="primary" onClick={onClick}>
          {t(`${translationKey}.button`)}
        </Button>
      ) : (
        <Link onClick={onClick}>{t(`${translationKey}.button`)}</Link>
      )}
    </Alert>
  );
}

function ActivationWithTransparentCheckoutConfigAlert({
  translationKey,
  onRemove,
  onClick,
}: Readonly<Omit<ActivationAlertProps, 'actionElement'>>): JSX.Element | null {
  const { isFeatureEnabled } = useNuvemPagoFeatureFlag(
    NUVEMPAGO_TRANSPARENT_CHECKOUT_IN_ADVANCED_SETTINGS_FEATURE_FLAG,
  );

  if (isFeatureEnabled === null) return null;

  const newTranslationKey = isFeatureEnabled
    ? `${translationKey}_transparent_checkout`
    : translationKey;
  const actionElement = isFeatureEnabled ? 'link' : 'button';

  return (
    <ActivationAlert
      translationKey={newTranslationKey}
      onRemove={onRemove}
      onClick={onClick}
      actionElement={actionElement}
    />
  );
}

function RecentlyEnabledAlert(): JSX.Element | null {
  const { cleanRecentlyEnabled, recentlyEnabledPaymentProviderId } =
    useRecentlyEnabled();
  const country = useGetCountry();
  const hasSmartPaymentTags = useHasTags([
    SMART_PAYMENT,
    SMART_PAYMENT_ADMIN,
  ]).every(Boolean);

  const id = recentlyEnabledPaymentProviderId || '';

  const { goToSettingsPage } = useGetPaymentProviderSettingsRoute(id);

  // Restrict show Alert to NuvemPago or Pago Nube
  const show = muvemPagoOrPagoNubeIds.includes(id);

  useEffect(
    () => () => {
      cleanRecentlyEnabled();
    },
    [cleanRecentlyEnabled],
  );

  useEffect(() => {
    show && invokeEventPayments('enabledAlert', id);
  }, [show, id]);

  if (!show) return null;

  if (country === Country.BR && hasSmartPaymentTags) {
    return (
      <ActivationWithTransparentCheckoutConfigAlert
        translationKey={id}
        onRemove={cleanRecentlyEnabled}
        onClick={goToSettingsPage}
      />
    );
  }

  return (
    <ActivationAlert
      translationKey={id}
      onRemove={cleanRecentlyEnabled}
      onClick={goToSettingsPage}
    />
  );
}

export default RecentlyEnabledAlert;
