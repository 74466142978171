import { Modal, Text, Button } from '@nimbus-ds/components';
import { useTranslationCommon } from 'commons/hooks';

interface LinesExceededModalProps {
  open: boolean;
  onDismiss: () => void;
  maxLines: number;
}

export function LinesExceededModal({
  open,
  onDismiss,
  maxLines,
}: LinesExceededModalProps) {
  const t = useTranslationCommon();

  return (
    <Modal open={open} onDismiss={onDismiss}>
      <Modal.Header title={t('importCsv.import.linesExceeded.titleModal')} />
      <Modal.Body padding="none">
        <Text>
          {t('importCsv.import.linesExceeded.messageModal', {
            max: maxLines,
          })}
        </Text>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onDismiss} appearance="primary">
          {t('importCsv.import.linesExceeded.confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
